import { d30ToastError, getJurisdictions, LinkButton, Loading, ReactTable, Select } from "@davo/portal-common";
import {
    FilingFrequency,
    hasMidMonthPayment,
    IJurisdiction,
    moneyFromCents,
    Reconcile,
    toDisplayDateString,
    toDisplayDateTimeString,
} from "@davo/types";
import { Button, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, { useMemo, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { LocationAccounting } from "../LocationAccounting";
import { LocationManualTransactionsModal } from "../LocationManualTransactionsModal";
import { getReconciles } from "../services";
import { ReconcileNoteModal } from "./ReconcileNoteModal";

export function ReconcilePane() {
    const [reconcilesEasy, setReconcilesEasy] = useState<Reconcile[]>();
    const [reconcilesHard, setReconcilesHard] = useState<Reconcile[]>();
    const [months, setMonths] = useState<string[]>();
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>();
    const [states, setStates] = useState<IJurisdiction[] | undefined>();
    const [selectedState, setSelectedState] = useState<IJurisdiction | undefined>();
    const [isBusySearching, setIsBusySearching] = useState<boolean>(false);
    const controllerRef = useRef<AbortController | undefined>();

    const _refreshReconciles = () => {
        if (!selectedMonth || !selectedState || isBusySearching) {
            return;
        }

        if (controllerRef?.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();

        setIsBusySearching(true);
        getReconciles(selectedMonth, selectedState?.abbreviatedName, controllerRef.current)
            .then((results) => {
                results.sort((reconcileA, reconcileB) => {
                    if (reconcileA.filedDate && reconcileB.filedDate) {
                        if (reconcileA.filedDate < reconcileB.filedDate) {
                            return -1;
                        }
                        if (reconcileA.filedDate > reconcileB.filedDate) {
                            return 1;
                        }
                    }
                    return 0;
                });

                const _greyButton = (reconcile: Reconcile): boolean => {
                    return (
                        hasMidMonthPayment(reconcile.frequency as FilingFrequency) || reconcile.requiresManualReconcile
                    );
                };

                setReconcilesEasy(results.filter((x) => !_greyButton(x)));
                setReconcilesHard(results.filter((x) => _greyButton(x)));
            })
            .catch((e) => d30ToastError("Problem getting reconciles.", e))
            .finally(() => setIsBusySearching(false));
    };

    useAsyncEffect(async () => {
        const now = DateTime.local();
        const monthValues = [];
        const start = DateTime.fromISO("2019-10-01T12:00"); // Approx D30 start date
        const monthsDiff = now.diff(start, ["months"]);

        for (let x = 0; x < monthsDiff.months; ++x) {
            const monthStr = now.minus({ month: x }).toFormat("yyyy-MM");
            monthValues.push(monthStr);
        }
        setMonths(monthValues);
        setStates(await getJurisdictions());
    }, []);

    const columns = useMemo(() => {
        const _renderFilingName = (data: any) => {
            return (
                <>
                    <LinkButton
                        url={`/filings/${data.row.original.filingId}`}
                        target="_blank"
                        label={data.row.original.name || "Filing"}
                        labelMaxChars={35}
                        align={"left"}
                    />
                </>
            );
        };

        const _dateFormat = (data: any) => {
            return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>}</>;
        };

        return [
            {
                Header: "Note",
                accessor: "note",
                disableSortBy: true,
                Cell: (data: any) => <ReconcileNoteModal reconcile={data.row.original} />,
            },
            {
                Header: "Filed Date",
                accessor: "filedDate",
                Cell: (data: any) => <div>{toDisplayDateTimeString(data.value)}</div>,
            },
            {
                Header: "Legal Name",
                accessor: "filingId",
                disableSortBy: true,
                Cell: _renderFilingName,
            },
            {
                Header: "Location",
                accessor: "locationName",
            },
            {
                Header: "Period",
                accessor: "periodEnd",
                Cell: _dateFormat,
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(-1 * data.value)}</div>,
            },
            {
                Header: "Total Discount",
                accessor: "totalDiscount",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(-1 * data.value)}</div>,
            },
            {
                Header: "State",
                accessor: "jurisdiction",
            },
            {
                Header: "",
                id: "action",
                accessor: "amount",
                disableSortBy: true,
                Cell: (data: any) => {
                    const reconcile: Reconcile = data.row.original;
                    return (
                        <LocationAccounting
                            accountId={reconcile.accountId}
                            locationId={reconcile.locationId}
                            initialPeriodDay={reconcile.periodEnd}
                        />
                    );
                },
            },
            {
                Header: "",
                id: "action2",
                accessor: "amount",
                disableSortBy: true,
                Cell: (data: any) => {
                    const reconcile: Reconcile = data.row.original;
                    return <LocationManualTransactionsModal locationId={reconcile.locationId} />;
                },
            },
            // NOTE: this column is hidden
            {
                Header: "",
                accessor: "name",
            },
        ];
    }, []);

    if (!months || !states) {
        return null;
    }
    return (
        <div>
            <Select<string>
                data-testid={"filedInMonthSelect"}
                title="Filed-in-Month"
                options={months}
                value={selectedMonth}
                onChange={setSelectedMonth}
            />
            <Select<IJurisdiction>
                data-testid={"stateSelect"}
                title="State"
                options={states}
                value={selectedState}
                onChange={setSelectedState}
                label={(s: any) => s?.fullName}
            />
            <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                <Button
                    data-testid={"searchButton"}
                    variant="outlined"
                    color="primary"
                    disabled={!selectedMonth || !selectedState || isBusySearching}
                    onClick={() => {
                        setIsBusySearching(true);
                        _refreshReconciles();
                    }}>
                    {"Search"}
                </Button>
            </div>

            {isNil(reconcilesEasy) && isNil(reconcilesHard) && isBusySearching && <Loading />}

            {!(isNil(reconcilesEasy) && isNil(reconcilesHard)) &&
                (!isEmpty(reconcilesEasy) || !isEmpty(reconcilesHard)) && (
                    <div data-testid={"reconcileTables"}>
                        {reconcilesEasy && !isEmpty(reconcilesEasy) && (
                            <ReactTable
                                title="Easy reconciles"
                                columns={columns}
                                data={reconcilesEasy}
                                refreshData={_refreshReconciles}
                                options={{
                                    pageSize: 10,
                                    hiddenColumns: ["name"],
                                }}
                            />
                        )}
                        {reconcilesHard && !isEmpty(reconcilesHard) && (
                            <ReactTable
                                title="Hard reconciles"
                                columns={columns}
                                data={reconcilesHard}
                                refreshData={_refreshReconciles}
                                options={{
                                    pageSize: 10,
                                    hiddenColumns: ["name"],
                                }}
                            />
                        )}
                    </div>
                )}

            {!(isNil(reconcilesEasy) && isNil(reconcilesHard)) &&
                isEmpty(reconcilesEasy) &&
                isEmpty(reconcilesHard) && (
                    <div data-testid={"noReconcilesFoundMessage"}>
                        <Typography style={{ marginTop: "20px" }} variant="body1">
                            No Reconciles Matching Search Criteria
                        </Typography>
                    </div>
                )}
        </div>
    );
}
