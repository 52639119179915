import { BodyText, d30ToastError } from "@davo/portal-common";
import { AccountLogHistory } from "@davo/types";
import { Alert, useTheme } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { disableAlert, getAccountHistory } from "./services";

export interface IAccountAlerts extends React.HTMLProps<HTMLDivElement> {
    accountId: string;
}

export const AccountAlerts: React.FunctionComponent<IAccountAlerts> = ({ accountId, ...props }) => {
    const [accountLogs, setAccountLogs] = useState<AccountLogHistory[]>();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    const fetchAccountHistory = () => {
        getAccountHistory(accountId)
            .then((logs) => {
                const alerts = logs.filter(
                    (log) => log.event === "location-associated" && log.description && log.alert
                );
                setAccountLogs(alerts);
            })
            .catch((e) => d30ToastError(e));
    };

    const handleDismissAlert = (historyId: string) => {
        disableAlert(historyId).catch((e) => d30ToastError(e));
        fetchAccountHistory();
    };

    useEffect(() => {
        fetchAccountHistory();
    }, []);

    if (isNil(accountLogs) || isEmpty(accountLogs)) {
        return null;
    }

    return (
        <div {...props}>
            {accountLogs.map((alert) => {
                return (
                    <Alert
                        closeText={"Close Partner Note"}
                        variant={isDarkMode ? "outlined" : "standard"}
                        style={{ marginBottom: "6px" }}
                        key={`alert-${alert.historyId}`}
                        onClose={() => {
                            handleDismissAlert(alert.historyId);
                        }}>
                        <BodyText>
                            <strong>Partner Note: </strong>
                            {alert.description}
                        </BodyText>
                    </Alert>
                );
            })}
        </div>
    );
};
