import { FilingPenaltyExtension, OptionalString } from "@davo/types";
import { DateTime } from "luxon";
import { AddNoteModal } from "../AddNoteModal";
import { setFilingPenaltyWellsNote } from "../services";
import { toNoteable } from "./FilingAddWellsNoteModal";

export function FilingPenaltyAddWellsNoteModal({ penalty }: { penalty: FilingPenaltyExtension }) {
    const updater = async (
        id: string,
        note: OptionalString,
        noteBy: OptionalString,
        noteByName: OptionalString,
        noteDate: DateTime | undefined | null
    ) => {
        await setFilingPenaltyWellsNote(id, note);
        penalty.wellsNote = note;
        penalty.wellsNoteBy = noteBy;
        penalty.wellsNoteByName = noteByName;
        penalty.wellsNoteDate = noteDate;
    };
    return AddNoteModal({ noteable: toNoteable(penalty), updater });
}
