import { Donut, INavMenuItem } from "@davo/portal-common";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ACHFailuresPane } from "./ACHFailuresPane";
import { Accounts } from "./Accounts";
import { AmendmentsPane } from "./Amendments";
import { AutofilerStatus } from "./AutofilerStatus";
import { BankFailuresPane } from "./BankFailures";
import { Dashboard } from "./Dashboard";
import { DisconnectsPane } from "./DisconnectsPane";
import { Filings } from "./Filings";
import { JurisdictionFilingTypePane } from "./JurisdictionFilingTypePane";
import { JurisdictionRateTypePane } from "./JurisdictionRateTypePane";
import { LiabilityControlCenterPane } from "./LiabilityControlCenterPane";
import { NewBoardings } from "./NewBoardings";
import { NoAccount } from "./NoAccount";
import { NoticesPane } from "./NoticesPane";
import { PartnersPane } from "./PartnersPane";
import { PenaltiesPane } from "./Penalties";
import { Referrals } from "./Referrals";
import { TaxCredentialsPane } from "./TaxCredentialsPane";
import { TaxProfileRatesUnverifiedPane } from "./TaxProfileRatesUnverifiedPane";
import { Users } from "./Users";
import { ReconcileFilingRemittedPane } from "./reconcile-remitted/ReconcileFilingRemittedPane";
import { ReconcilePane } from "./reconcile/ReconcilePane";
import { hasPermission } from "./util";

const useStyles = makeStyles((theme: any) => ({
    header: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        marginRight: "20px",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "flex-start",
        },
    },
}));

const _opsDonutPrimaryRoutes: INavMenuItem[] = [
    {
        name: "Dashboard",
        slug: "dashboard",
        destination: "dashboard",
        element: <Dashboard />,
    },
    {
        name: "Filings",
        slug: "filings",
        destination: "filings",
        element: <Filings />,
    },
    {
        name: "Accounts",
        slug: "accounts",
        destination: "accounts",
        element: <Accounts />,
    },
    {
        name: "No Account",
        slug: "no-account",
        destination: "no-account",
        element: <NoAccount />,
    },
    {
        name: "Users",
        slug: "users",
        destination: "users",
        element: <Users />,
    },
];

const _opsDonutRoutesSubMenu1: INavMenuItem[] = [
    {
        name: "New",
        slug: "boardings-new",
        destination: "boardings-new",
        element: <NewBoardings newBoardingType={"new"} title={"New Boardings"} key={"new"} />,
    },
    {
        name: "No Account",
        slug: "boardings-no-account",
        destination: "boardings-no-account",
        element: <NewBoardings newBoardingType={"no-account"} title={"No Account"} key={"no-account"} />,
    },
    {
        name: "No Bank",
        slug: "boardings-no-bank",
        destination: "boardings-no-bank",
        element: <NewBoardings newBoardingType={"no-bank"} title={"No Bank"} key={"no-bank"} />,
    },
    {
        name: "No Tax Profile",
        slug: "boardings-no-tax-profile",
        destination: "boardings-no-tax-profile",
        element: <NewBoardings newBoardingType={"no-tp"} title={"No Tax Profile"} key={"no-tp"} />,
    },
];

const _opsDonutRoutesSubMenu2: INavMenuItem[] = [
    {
        name: "Tax Profiles",
        slug: "tax-credentials",
        destination: "tax-credentials",
        element: <TaxCredentialsPane />,
    },
    {
        name: "Tax Rates",
        slug: "tax-rates",
        destination: "tax-rates",
        element: <TaxProfileRatesUnverifiedPane />,
    },
    {
        name: "Disconnects",
        slug: "disconnects",
        destination: "disconnects",
        element: <DisconnectsPane />,
    },
    {
        name: "Amendments",
        slug: "amendments",
        destination: "amendments",
        element: <AmendmentsPane />,
    },
    {
        name: "Notices",
        destination: "notices",
        slug: "notices",
        element: <NoticesPane />,
    },
    {
        name: "Penalties",
        slug: "penalties",
        destination: "penalties",
        element: <PenaltiesPane />,
    },
    {
        name: "Bank Failures",
        slug: "bank-failures",
        destination: "bank-failures",
        element: <BankFailuresPane />,
    },
    {
        name: "ACH Failures",
        slug: "ach-failures",
        destination: "ach-failures",
        element: <ACHFailuresPane />,
    },
];

const _opsDonutRoutesSubMenu3: INavMenuItem[] = [
    {
        name: "Reconcile",
        slug: "reconcile",
        destination: "reconcile",
        element: <ReconcilePane />,
    },
    {
        name: "Reconcile Filing Remitted",
        slug: "reconcile-remitted",
        destination: "reconcile-remitted",
        element: <ReconcileFilingRemittedPane />,
    },
];

const _opsDonutRoutesSubMenu4: INavMenuItem[] = [
    {
        name: "Jurisdiction Filing Types",
        slug: "filingTypes",
        destination: "filingTypes",
        element: <JurisdictionFilingTypePane />,
    },
    {
        name: "Jurisdiction Rate Types",
        slug: "rateTypes",
        destination: "rateTypes",
        element: <JurisdictionRateTypePane />,
    },
];

const _opsDonutRoutesSubMenu5: INavMenuItem[] = [
    {
        name: "Liability Control Center",
        slug: "liability-control-center",
        destination: "liability-control-center",
        element: <LiabilityControlCenterPane />,
    },
    {
        name: "Partners",
        slug: "partners",
        destination: "partners",
        element: <PartnersPane />,
    },
    {
        name: "Referrals",
        slug: "referrals",
        destination: "referrals",
        element: <Referrals />,
    },
];

export function OpsDonut({ primaryRoutes }: { primaryRoutes: INavMenuItem[] }) {
    const classes = useStyles();

    return (
        <Donut
            primary={primaryRoutes}
            subMenus={[
                {
                    name: "Boarding",
                    slug: "boarding",
                    destination: _opsDonutRoutesSubMenu1[0].destination,
                    items: _opsDonutRoutesSubMenu1,
                },
                {
                    name: "Compliance",
                    slug: "compliance",
                    destination: _opsDonutRoutesSubMenu2[0].destination,
                    items: _opsDonutRoutesSubMenu2,
                },
                {
                    name: "Recon",
                    slug: "recon",
                    destination: _opsDonutRoutesSubMenu3[0].destination,
                    items: _opsDonutRoutesSubMenu3,
                },
                {
                    name: "Configuration",
                    slug: "config",
                    destination: _opsDonutRoutesSubMenu4[0].destination,
                    items: _opsDonutRoutesSubMenu4,
                },
                {
                    name: "Other",
                    slug: "other",
                    destination: _opsDonutRoutesSubMenu5[0].destination,
                    items: _opsDonutRoutesSubMenu5,
                },
            ]}
            header={
                <div className={classes.header}>
                    <div style={{ flex: 1 }}>DAVO Operations Portal</div>
                    <AutofilerStatus />
                </div>
            }
        />
    );
}

export const getOpsPrimaryRoutes = ({ permissionsInfo }: { permissionsInfo: string[] | undefined }) => {
    const routes: INavMenuItem[] = [];
    routes.push({
        name: "Dashboard",
        slug: "dashboard",
        destination: "dashboard",
        element: <Dashboard />,
    });

    if (hasPermission(permissionsInfo, "can_file")) {
        routes.push({
            name: "Filings",
            slug: "filings",
            destination: "filings",
            element: <Filings />,
        });
    }

    routes.push(
        {
            name: "Accounts",
            slug: "accounts",
            destination: "accounts",
            element: <Accounts />,
        },
        {
            name: "No Account",
            slug: "no-account",
            destination: "no-account",
            element: <NoAccount />,
        },
        {
            name: "Users",
            slug: "users",
            destination: "users",
            element: <Users />,
        }
    );
    return routes;
};

export const getOpsRoutes = () => {
    const routes: INavMenuItem[] = [];
    routes.push(..._opsDonutPrimaryRoutes);
    routes.push(..._opsDonutRoutesSubMenu1);
    routes.push(..._opsDonutRoutesSubMenu2);
    routes.push(..._opsDonutRoutesSubMenu3);
    routes.push(..._opsDonutRoutesSubMenu4);
    routes.push(..._opsDonutRoutesSubMenu5);
    return routes;
};
