import { del, get, getArray, post, put } from "@davo/portal-common";
import {
    AccountWithUnverifiedTaxRates,
    AmendmentOpsPaneRecord,
    BankPausedOpsPaneRecord,
    DisconnectData,
    FailedBillingData,
    FilingPenalty,
    FilingPenaltyExtension,
    fromJSON,
    INewBoarding,
    INotice,
    ManualSetAsideType,
    MetaAmendmentOpsPaneRecord,
    MetaBankPausedOpsPaneRecord,
    MetaDisconnectData,
    MetaFailedBillingData,
    MetaManualSetAsideFailure,
    MetaNSFOpsPaneRecord,
    MetaReconcile,
    NewBoardingTypes,
    NoticeStatus,
    NoticeType,
    NSFOpsPaneRecord,
    OptionalString,
    Reconcile,
} from "@davo/types";

export async function getFailedBillings(
    yearMonth: string,
    abortController?: AbortController
): Promise<FailedBillingData[]> {
    return getArray(`api/ops/opspane/billing/${yearMonth}`, true, MetaFailedBillingData, abortController);
}

export async function getNewBoardings(newBoardingType: NewBoardingTypes) {
    return get<INewBoarding[]>(`api/ops/opspane/boardings/${newBoardingType}`, true);
}

export async function getNSFLocations(): Promise<NSFOpsPaneRecord[]> {
    return getArray(`api/ops/opspane/nsf`, true, MetaNSFOpsPaneRecord);
}

export async function getRequiredAmendments(): Promise<AmendmentOpsPaneRecord[]> {
    return getArray(`api/ops/opspane/amendments/required`, true, MetaAmendmentOpsPaneRecord);
}

export async function getCompletedAmendments(yearMonth: string): Promise<AmendmentOpsPaneRecord[]> {
    return getArray(`api/ops/opspane/amendments/completed/month/${yearMonth}`, true, MetaAmendmentOpsPaneRecord);
}

export async function setAmendmentAdditionalNote(filingId: string, note?: OptionalString) {
    await put(`api/ops/opspane/amendments/${filingId}/note`, { note }, true);
}

export async function getAccountsWithUnverifiedTaxRates(
    yearMonth?: string,
    state?: string,
    abortController?: AbortController
): Promise<AccountWithUnverifiedTaxRates[]> {
    return post<AccountWithUnverifiedTaxRates[]>(
        `api/ops/opspane/taxrates/unverified`,
        { yearMonth, state },
        true,
        undefined,
        abortController
    );
}

export async function getDisconnectsAccounts(): Promise<DisconnectData[]> {
    return getArray(`api/ops/opspane/disconnects-actionable`, true, MetaDisconnectData);
}

export async function getDisconnectsWithPendingFunds(): Promise<DisconnectData[]> {
    return getArray(`api/ops/opspane/disconnects-pending`, true, MetaDisconnectData);
}

export async function setDisconnectNote(locationId: string, note: OptionalString) {
    await put(`api/ops/opspane/disconnects/${locationId}/note`, { note }, true);
}

export async function getPausedBankLocations(): Promise<BankPausedOpsPaneRecord[]> {
    return getArray(`api/ops/opspane/paused`, true, MetaBankPausedOpsPaneRecord);
}

export async function getPenaltiesByStatus(
    statuses: string[],
    excludeOldPenalties: boolean,
    excludeWellsMatched: boolean,
    isDavoResponsibleOnly: boolean,
    abortController?: AbortController
) {
    return post<FilingPenaltyExtension[]>(
        `api/ops/opspane/penalties`,
        {
            statuses,
            excludeOldPenalties,
            excludeWellsMatched,
            davoResponsibleOnly: isDavoResponsibleOnly,
        },
        true,
        undefined,
        abortController
    );
}

export async function getPenalty(penaltyId: string) {
    return get<FilingPenaltyExtension>(`api/ops/opspane/penalty/${penaltyId}`, true);
}

export async function getNoticesForFilter(statuses: NoticeStatus[], types: NoticeType[]) {
    return post<
        (INotice & {
            accountId: string;
            accountName: string;
            createdByName: string;
            state: string;
            downloadReady: boolean;
            penalty?: FilingPenalty;
        })[]
    >(
        `api/ops/opspane/notices`,
        {
            statuses,
            types,
        },
        true
    );
}

export async function setNoticeNote(noticeId: string, note: OptionalString) {
    await put(`api/ops/opspane/notices/${noticeId}/note`, { note }, true);
}

export async function setNoticeMerchantNote(noticeId: string, note: OptionalString) {
    await put(`api/ops/opspane/notices/${noticeId}/merchant-note`, { note }, true);
}

export async function setNoticeStatus(noticeId: string, newStatus: NoticeStatus) {
    await post(`api/ops/opspane/notices/${noticeId}/status`, { newStatus }, true);
}

export async function unassociateNotice(noticeId: string) {
    return del(`api/ops/opspane/notices/${noticeId}/unassociate`, true, {});
}

export async function associateNoticeToPenalty(penaltyId: string, noticeId: string) {
    return post(`api/ops/opspane/notices/${noticeId}/associate`, { penaltyId }, true);
}

export async function setFilingPenaltyWellsNote(penaltyId: string, note: OptionalString) {
    await put(`api/ops/opspane/penalties/${penaltyId}/note`, { note }, true);
}

export async function getReconciles(yearMonth: string, state?: string, abortController?: AbortController) {
    const results = await post<Reconcile[]>(
        `api/ops/opspane/reconciles/month/${yearMonth}`,
        { state },
        true,
        undefined,
        abortController
    );
    return results.map((x: Reconcile) => fromJSON(MetaReconcile, x));
}

export async function setReconcileNote(filingId: string, locationId: string, note?: string) {
    await put(`api/ops/opspane/reconciles/${filingId}/${locationId}/note`, { note }, true);
}

export async function getSetAsideFailures(
    yearMonth: string,
    type?: ManualSetAsideType,
    abortController?: AbortController
) {
    return getArray(
        `api/ops/opspane/setaside-failures/${yearMonth}/${type ? type : ""}`,
        true,
        MetaManualSetAsideFailure,
        abortController
    );
}
