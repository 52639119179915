import { d30Toast, getJurisdictions, Select, useModalEditor } from "@davo/portal-common";
import { IJurisdiction, IWellsFargoTransactionInfo, moneyFromCents, toDisplayDateTimeString } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { CSSProperties, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { setWellsTransactionState } from "../services";

export function WellsSetStateModal({
    txn,
    onSubmit,
}: {
    txn: IWellsFargoTransactionInfo;
    onSubmit: (state: string) => void;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [selectedState, setSelectedState] = useState<IJurisdiction | undefined>();

    useAsyncEffect(async () => {
        setStates(await getJurisdictions());
    }, [isDialogOpen]);

    const setTransactionState = async () => {
        if (!selectedState) {
            return;
        }
        setIsBusy(true);
        try {
            await setWellsTransactionState(txn.id, selectedState.abbreviatedName);
            d30Toast("State updated.");
            onSubmit(selectedState.abbreviatedName);
            closeDialog();
        } finally {
            setIsBusy(false);
        }
    };

    const dtStyle: CSSProperties = {
        fontWeight: "bold",
        float: "left",
        marginRight: "0.8em",
        padding: "0.3em",
    };

    const ddStyle: CSSProperties = {
        clear: "right",
        padding: "0.3em",
    };

    return (
        <>
            <Button variant="outlined" onClick={showDialog} size="small" color="primary" style={{ marginTop: "3px" }}>
                Set state
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Select State</DialogTitle>
                    <DialogContent>
                        <Typography variant={"body1"}>
                            <div>Please select a state for this transaction:</div>
                            <dl>
                                <dt style={dtStyle}>Date:</dt>
                                <dd style={ddStyle}>{toDisplayDateTimeString(txn.transactionDatetime)}</dd>

                                <dt style={dtStyle}>Amount:</dt>
                                <dd style={ddStyle}>${moneyFromCents(txn.transactionAmount)}</dd>

                                <dt style={dtStyle}>Description:</dt>
                                <dd style={ddStyle}>{txn.transactionDescription}</dd>
                            </dl>

                            <Select<IJurisdiction>
                                title="State"
                                options={states}
                                value={selectedState}
                                onChange={setSelectedState}
                                label={(s) => s?.fullName}
                            />
                        </Typography>

                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" disabled={isBusy} onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !selectedState}
                            onClick={() => setTransactionState()}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
