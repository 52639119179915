import { darkTheme, lightTheme, LoginContext, LoginContextProvider } from "@davo/portal-common";
import "@davo/portal-common/lib/toast.css";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OpsPortalConfigContextProvider } from "./context";
import { OpsRouter } from "./Routes";

export function Preload() {
    return (
        <OpsPortalConfigContextProvider>
            <LoginContextProvider>
                <LoginContext.Consumer>
                    {(login) => (
                        <ThemeProvider theme={login.isDarkMode ? darkTheme : lightTheme}>
                            <OpsRouter />
                            <ToastContainer />
                        </ThemeProvider>
                    )}
                </LoginContext.Consumer>
            </LoginContextProvider>
        </OpsPortalConfigContextProvider>
    );
}
