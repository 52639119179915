import { get, getArray, post, put } from "@davo/portal-common";
import {
    BUSINESS_DAY,
    BusinessDay,
    INTEGER,
    ManualSetAsideAndACH,
    ManualSetAsideType,
    MetaChangePeriodFundsParams,
    MetaManualSetAsideAndACH,
    MetaManualSetAsideParams,
    MetaOutflowParams,
    OutflowType,
    OutflowWithFirstName,
    UUID,
    toJSON,
} from "@davo/types";

export async function getManualTransactionsForLocation(locationId: string): Promise<ManualSetAsideAndACH[]> {
    return getArray(`api/ops/manual-set-asides/manualTransactions/${locationId}`, true, {
        ...MetaManualSetAsideAndACH,
    });
}

export const getOutflowsForPeriod = async (
    locationId: string,
    periodStart = BusinessDay.today(),
    periodEnd = BusinessDay.today()
) => {
    return get<OutflowWithFirstName[]>(
        `api/ops/manual-set-asides/outflows/${locationId}/${periodStart.toSQLDate()}/${periodEnd.toSQLDate()}`,
        true
    );
};

export const manualSetAside = async (
    isReturn: boolean,
    accountId: string,
    locationId: string,
    amountInPennies: number,
    type: ManualSetAsideType,
    periodDay?: BusinessDay,
    note?: string,
    merchantNote?: string,
    period?: { start: BusinessDay; end: BusinessDay }
) => {
    await post(
        `api/ops/manual-set-asides/manual-set-aside`,
        toJSON(MetaManualSetAsideParams, {
            isReturn,
            locationId,
            amountInPennies,
            type,
            periodDay,
            note,
            merchantNote,
            period,
        }),
        true
    );
};

export const periodTransfer = async (
    locationId: string,
    amountInPennies: number,
    originDay: BusinessDay,
    destinationDay: BusinessDay,
    note?: string
) => {
    await post(
        `api/ops/manual-set-asides/period-transfer`,
        toJSON(MetaChangePeriodFundsParams, { locationId, amountInPennies, originDay, destinationDay, note }),
        true
    );
};

export const markFundsRetrievable = async (locationId: string, amountInPennies: number, periodDay: BusinessDay) => {
    await post(
        `api/ops/manual-set-asides/funds-retrievable`,
        toJSON(
            {
                locationId: UUID(),
                amountInPennies: INTEGER(),
                periodDay: BUSINESS_DAY(),
            },
            { locationId, amountInPennies, periodDay }
        ),
        true
    );
};

export const doOutflow = async (
    locationId: string,
    amountInPennies: number,
    periodDay: BusinessDay,
    type: OutflowType,
    note?: string,
    otherLocations?: { locationId: string; nestedAmountInPennies: number }[],
    checkNumber?: string | undefined
) => {
    await post(
        `api/ops/manual-set-asides/do-outflow`,
        toJSON(MetaOutflowParams, { locationId, amountInPennies, periodDay, type, note, otherLocations, checkNumber }),
        true
    );
};

export const voidManualSetAside = async (manualSetAsideId: string, voidReason: string) => {
    return put(`api/ops/manual-set-asides/${manualSetAsideId}/void`, { voidReason: voidReason }, true);
};
