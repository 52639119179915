import { IMarketplaceFacilitatorJurisdictionJoin } from "@davo/types";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getMarketplaceFacilitatorRules } from "./services";

export const MarketplaceFacilitatorRules = ({
    jurisdictionState,
    jurisdictionId,
}: {
    jurisdictionState: string;
    jurisdictionId: string;
}) => {
    const [mfRules, setMFRules] = useState<IMarketplaceFacilitatorJurisdictionJoin[]>([]);

    useAsyncEffect(async () => {
        if (jurisdictionState) {
            setMFRules(await getMarketplaceFacilitatorRules(jurisdictionId));
        }
    }, [jurisdictionState]);

    const renderRule = (rule: IMarketplaceFacilitatorJurisdictionJoin) => {
        const baseRemitsMessage = `${rule.marketplaceFacilitatorName} remits`;
        if (rule && rule.includesLocal && rule.includesRestaurant) {
            return <li>{baseRemitsMessage}.</li>;
        } else if (rule && rule.includesLocal && !rule.includesRestaurant) {
            return <li>{baseRemitsMessage} but not for restaurants.</li>;
        } else if (rule && !rule.includesLocal && rule.includesRestaurant) {
            return <li>{baseRemitsMessage} but not for local taxes and fees.</li>;
        } else if (rule && !rule.includesLocal && !rule.includesRestaurant) {
            return <li>{baseRemitsMessage} but not for restaurants, local taxes or fees.</li>;
        }
    };

    return (
        <div style={{ fontSize: ".8em", minHeight: "80px" }}>
            Marketplace Facilitator Rules for {jurisdictionState}:
            <ul style={{ marginBottom: "30px", marginTop: "5px" }}>
                {mfRules.length > 0 ? (
                    mfRules.map((rule) => renderRule(rule))
                ) : (
                    <li>All taxes are the responsibility of the merchant.</li>
                )}
            </ul>
        </div>
    );
};
