import { useLoginContext } from "@davo/portal-common";
import { IFilingRow } from "@davo/types";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../util";
import { AutofilerButtons } from "./AutofilerButtons";
import { AutofilerProcessingDetail } from "./AutofilerProcessingDetail";

export interface IRenderFileInfoProps {
    row: IFilingRow;

    historicalFiling(thing?: { filingId: string; accountId: string } | undefined): void;
}

export const RenderFileInfo: React.FunctionComponent<IRenderFileInfoProps> = ({ row, historicalFiling }) => {
    const loginContext = useLoginContext();
    const canFile = hasPermission(loginContext.permissions, "can_file");
    const isCorrectFilingStatus = row.status === "ready" || row.status === "late";
    const navigate = useNavigate();

    const _renderHistoricalButton = () => {
        if (row.historical) {
            return (
                <Button
                    data-testid={"viewHistorical"}
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => historicalFiling({ filingId: row.id, accountId: row.accountId })}>
                    View Historical
                </Button>
            );
        }
        return null;
    };

    const _renderAutofilerDetails = () => {
        if (row.historical) {
            return;
        }

        if (row.details.canAutofile && canFile) {
            return (
                <div data-testid={"autofilerDetails"} style={{ display: "inline-flex" }}>
                    <React.Fragment key={row.details.id}>
                        <AutofilerButtons filing={row.details} />
                        <AutofilerProcessingDetail filing={row.details} />
                    </React.Fragment>
                </div>
            );
        }
        return null;
    };

    const _renderFilingNavigationLink = () => {
        if (row.historical) {
            return;
        }

        if (!row.details.canAutofile || (row.details.canAutofile && !canFile)) {
            return (
                <Button
                    data-testid={"filingNavigation"}
                    variant={isCorrectFilingStatus ? "contained" : "outlined"}
                    color="primary"
                    size="small"
                    disabled={row.status === "clicked"}
                    onClick={() => {
                        row.status = "clicked";
                        navigate(`/filings/${row.id}`);
                    }}>
                    {isCorrectFilingStatus && canFile ? "File" : "View"}
                </Button>
            );
        } else {
            return null;
        }
    };

    return (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
            {_renderHistoricalButton()}
            {_renderAutofilerDetails()}
            {_renderFilingNavigationLink()}
        </div>
    );
};
