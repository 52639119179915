import { d30ToastError, PaperComponent, Select } from "@davo/portal-common";
import { User } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { getAllStaff, getStaffWithFilingPermission } from "./services";

export interface IAssignStaffModal {
    passThroughIds?: string[];
    closeDialog: (target?: string) => void;
    target?: string | null;
    assignStaffUser: (passThroughIds: string[] | undefined, selected: User | undefined) => void;
    shouldFilterByFilers: boolean;
}

export function AssignStaffModal({
    passThroughIds,
    closeDialog,
    assignStaffUser,
    shouldFilterByFilers,
    target,
}: IAssignStaffModal) {
    const [staff, setStaff] = useState<User[] | []>();
    const [selected, setSelected] = useState<User>();
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const allStaffPromise = shouldFilterByFilers ? getStaffWithFilingPermission : getAllStaff;
        allStaffPromise()
            .then((allStaff) => {
                allStaff.sort((x, y) => {
                    if (`${x.firstName} ${x.lastName}` < `${y.firstName} ${y.lastName}`) {
                        return -1;
                    }
                    if (`${x.firstName} ${x.lastName}` > `${y.firstName} ${y.lastName}`) {
                        return 1;
                    }
                    return 0;
                });
                setSelected(allStaff.find((s) => s.id === target));
                setStaff(allStaff);
            })
            .catch((e) => d30ToastError("Problem getting staff.", e));
    }, [shouldFilterByFilers, target]);

    const updateAssignStaffUser = () => {
        assignStaffUser(passThroughIds, selected);
        shutdown();
    };

    const shutdown = (result?: string) => {
        closeDialog(result);
    };

    if (isNil(staff)) {
        return null;
    }

    return (
        <Dialog
            data-testid={"assignStaffModal"}
            fullScreen={makeFullScreen}
            open={true}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            style={{ boxShadow: "none", margin: "5px 2px" }}
            onClose={() => {
                shutdown();
            }}>
            <DialogTitle id="draggable-dialog-title">Select assignee</DialogTitle>
            <DialogContent>
                {staff.length > 0 && (
                    <Select<User>
                        data-testid={"assigneeSelect"}
                        title="Assignee"
                        style={{ width: "300px" }}
                        options={staff}
                        label={(u: User) =>
                            !isNil(u.firstName) && !isNil(u.lastName) ? `${u.firstName} ${u.lastName}` : "No name found"
                        }
                        noneLabel={"Unassign"}
                        value={selected}
                        onChange={setSelected}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => shutdown()}
                    data-testid={"cancelAssignmentBtn"}
                    variant="outlined"
                    color="primary"
                    style={{ width: "100px" }}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        updateAssignStaffUser();
                    }}
                    data-testid={"assignStaffBtn"}
                    variant="contained"
                    color="primary"
                    style={{ width: "150px" }}
                    autoFocus>
                    Assign
                </Button>
            </DialogActions>
        </Dialog>
    );
}
