import { getSelectTheme } from "@davo/portal-common";
import { UserSearch } from "@davo/types";
import { useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { AddOpsUserModal } from "./AddOpsUserModal";
import { getAllUsersForSearchTerm } from "./services";

export function UserSearchRender() {
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const themeMUI = useTheme();

    const formThemeColors = getSelectTheme(themeMUI);

    const loadOptions = async () => {
        if (searchTerm && searchTerm.length > 2) {
            // start searching after the first two characters
            const res = await getAllUsersForSearchTerm(searchTerm);
            if (res.length === 1) {
                handleSelectionChange(res[0]);
            }
            return res;
        } else {
            return [];
        }
    };

    const handleInputChange = (newValue: string) => {
        setSearchTerm(newValue);
    };

    const handleSelectionChange = (selectedOption: any) => {
        navigate(`/users/${selectedOption.id}`);
    };

    const setFormatOptionLabel = (userSearch: UserSearch) => {
        let optionLabel = (userSearch.firstName || "").trim();
        if (optionLabel.length > 0) {
            optionLabel = optionLabel.concat(" ");
        }
        optionLabel = optionLabel.concat(userSearch.lastName || "").trim();
        optionLabel = optionLabel.concat(": ").concat(userSearch.email);
        return optionLabel;
    };

    const MenuList = (props: any) => {
        return (
            <components.MenuList {...props}>
                {props.children}
                {props.options.length <= 8 ? (
                    <div />
                ) : (
                    <div style={{ textAlign: "center" }}>{`Showing first 8 results, narrow search`}</div>
                )}
            </components.MenuList>
        );
    };

    return (
        <div>
            <AddOpsUserModal />
            <div onPaste={(e) => handleInputChange(e.clipboardData.getData("text"))}>
                <AsyncSelect
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...formThemeColors,
                        },
                    })}
                    components={{ MenuList }}
                    onChange={handleSelectionChange}
                    formatOptionLabel={(x: UserSearch) => setFormatOptionLabel(x)}
                    placeholder={"Enter search term..."}
                    defaultOptions={false}
                    cacheOptions={true}
                    autoFocus
                    loadOptions={loadOptions}
                    onInputChange={(x) => handleInputChange(x)}
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => "No results"}
                />
            </div>
        </div>
    );
}
