import { get, getArray, getOne, post, put } from "@davo/portal-common";
import {
    BOOLEAN,
    BUSINESS_DAY,
    BusinessDay,
    DisconnectReasonCodes,
    FilingFrequency,
    IDeactivationStatus,
    ILocationNoAccount,
    ISubscriptionAndType,
    ISubscriptionDiscount,
    ISubscriptionType,
    ITTRSRateInfo,
    LocationWithPartnerId,
    MetaLocationWithPartnerId,
    MetaPeriodReport,
    MetaPOSCredCheck,
    MetaPOSCredentials,
    MetaRelatedLocation,
    NUMBER,
    OBJECT,
    OptionalString,
    PotentialMidTrans,
    RelatedLocation,
} from "@davo/types";

export async function getLocationDetails(locationId: string): Promise<LocationWithPartnerId> {
    return getOne(`api/ops/locations/${locationId}/details`, true, MetaLocationWithPartnerId);
}

export const getRelatedLocations = async (locationId: string): Promise<RelatedLocation[]> => {
    return getArray(`api/ops/locations/${locationId}/related-locations`, true, MetaRelatedLocation);
};

export async function mergeLocationToAccount(locationId: string, targetAccountId: string): Promise<{}> {
    return post(`api/ops/locations/${locationId}/merge/${targetAccountId}`, {}, true);
}

export async function mergeLocationToNewAccount(locationId: string, newAccountName: string) {
    return post<{ accountId: string }>(
        `api/ops/locations/${locationId}/mergeToNew`,
        { accountName: newAccountName },
        true
    );
}

export async function redeemInvitationToAccount(
    invitationId: string,
    newAccountId: string,
    locationIds?: string[]
): Promise<{ message: "OK" }> {
    return post(`api/ops/locations/${invitationId}/redeem/${newAccountId}`, { locationIds }, true);
}

export function redeemInvitationToNewAccount(
    invitationId: string,
    newAccountName: string,
    locationIds?: string[]
): Promise<{ accountId: string }> {
    return post(`api/ops/locations/${invitationId}/redeem`, { accountName: newAccountName, locationIds }, true);
}

export async function getLocationAccounting(locationId: string, periodDay = BusinessDay.today()) {
    return getOne(`api/ops/locations/${locationId}/accounting/${periodDay.toSQLDate()}`, true, MetaPeriodReport);
}

export async function getLocationAccountingForFrequency(
    locationId: string,
    frequency: FilingFrequency,
    periodDay = BusinessDay.today()
) {
    return getOne(
        `api/ops/locations/${locationId}/accounting/${periodDay.toSQLDate()}/frequency/${frequency}`,
        true,
        MetaPeriodReport
    );
}

export async function getLocationAnnualBalancesForThisYearAndLast(locationId: string) {
    return getOne(`api/ops/locations/${locationId}/annual-balances`, true, {
        amountInPenniesThisYear: NUMBER(),
        amountInPenniesLastYear: NUMBER(),
        periodThisYear: OBJECT({
            start: BUSINESS_DAY(),
            end: BUSINESS_DAY(),
        }),
        periodLastYear: OBJECT({
            start: BUSINESS_DAY(),
            end: BUSINESS_DAY(),
        }),
        processed: BOOLEAN(),
    });
}

export async function getOtherLocationsForInterLocationTransferOutflow(
    thisLocationid: string,
    thisLocationBalance: number,
    periodDay: BusinessDay
) {
    return post<{ id?: string; name?: string; balance?: number }[]>(
        `api/ops/locations/${thisLocationid}/otherLocationForTransfer`,
        { thisLocationBalance, periodDay },
        true
    );
}

export async function getLocationSubscriptions(locationId: string) {
    return get<ISubscriptionAndType[]>(`api/ops/locations/${locationId}/subscriptions`, true);
}

export async function getLocationsSubscriptionStartDate(
    locationIds: string[]
): Promise<{ locationId: string; startDate: BusinessDay }[]> {
    return post<{ locationId: string; startDate: BusinessDay }[]>(
        `api/ops/locations/subscription-start`,
        { locationIds: locationIds },
        true
    );
}

export async function addLocationSubscription(locationId: string, subscriptionId: string) {
    return post(`api/ops/locations/${locationId}/subscriptions/add`, { subscriptionId }, true);
}

export async function getLocationNoAccount(locationId: string) {
    return get<ILocationNoAccount>(`api/ops/locations/${locationId}/no-account`, true);
}

export async function updateLocationDisconnectCode(
    locationId: string,
    disconnectReasonCode: DisconnectReasonCodes,
    disconnectReasonNote?: string
) {
    await put(`api/ops/locations/${locationId}/disconnect-code`, { disconnectReasonCode, disconnectReasonNote }, true);
}

export async function addDiscountForSubscriptionFee(
    locationId: string,
    reason: string,
    type: string,
    amount: number,
    numMonths?: number
) {
    return post(
        `api/ops/locations/${locationId}/subscriptions/add-discount`,
        { reason, type, amount, numMonths },
        true
    );
}

export async function removeDiscountForSubscriptionFee(locationId: string) {
    return post(`api/ops/locations/${locationId}/subscriptions/remove-discount`, {}, true);
}

export async function isSubscriptionDiscounted(locationId: string, date: BusinessDay) {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    return get<ISubscriptionDiscount | undefined>(
        `api/ops/locations/${locationId}/subscriptions/is-discounted/${date.toSQLDate()}`,
        true
    );
}

export async function getPOSCredentials(locationId: string) {
    return getOne(`api/ops/locations/${locationId}/pos-creds`, true, MetaPOSCredentials);
}

export async function testPOSCredentials(locationId: string, protocol: string) {
    return getOne(`api/ops/locations/${locationId}/pos-creds/check/${protocol}`, true, MetaPOSCredCheck);
}

export async function refreshLocationAccounting(locationId: string) {
    return put(`api/ops/locations/${locationId}/refreshAccounting`, {}, true);
}

export async function reactivateLocation(locationId: string) {
    return put(`api/ops/locations/${locationId}/reactivate`, {}, true);
}

export async function deactivateLocation(locationId: string, note: string) {
    return put(`api/ops/locations/${locationId}/deactivate`, { note }, true);
}

export async function reconnectLocation(locationId: string) {
    return put(`api/ops/locations/${locationId}/reconnect`, {}, true);
}

export async function setAwaitingDisconnectForLocation(
    locationId: string,
    disconnectReasonCode: DisconnectReasonCodes,
    disconnectReasonNote?: string
) {
    return put(
        `api/ops/locations/${locationId}/awaiting-disconnect`,
        { disconnectReasonCode, disconnectReasonNote },
        true
    );
}

export async function getAllActiveSubscriptionTypes() {
    return get<ISubscriptionType[]>(`api/ops/locations/get-all-active-subscription-types`, true);
}

export async function getAllActiveValidSubscriptionTypes(locationId: string) {
    return get<ISubscriptionType[]>(`api/ops/locations/${locationId}/get-all-active-subscription-types`, true);
}

export interface ICanDeactivateLocationsType {
    [key: string]: IDeactivationStatus;
}

export async function canDeactivateLocations(locationIds: string[]) {
    return post<ICanDeactivateLocationsType>(`api/ops/locations/can-deactivate`, { locations: locationIds }, true);
}

export async function getAllNoAccountLocationsForSearchTerm(searchTerm: string) {
    return post(`api/ops/locations/no-account-search`, { searchTerm }, true);
}

export async function canAddLocationDiscount(locationId: string) {
    return get<boolean>(`api/ops/locations/${locationId}/subscriptions/discount/can-add`, true, {});
}

export async function lookupRatesForLocation(locationId: string) {
    return get<ITTRSRateInfo>(`api/ops/locations/${locationId}/rate-lookup`, true, {});
}

export async function updateOnboardingNote(locationId: string, note: OptionalString) {
    await put(`api/ops/locations/${locationId}/note`, { note }, true);
}

export async function updateOnboardingAssignee(
    locationId: string,
    newAssigneeId: OptionalString,
    onboardingType: OptionalString
) {
    await put(`api/ops/locations/${locationId}/assignee/${newAssigneeId}`, { onboardingType }, true);
}

export async function updateOnboardingCommunicationStatus(
    locationId: string,
    onboardingCommunicationStatus: OptionalString
) {
    await put(`api/ops/locations/${locationId}/communicationStatus`, { onboardingCommunicationStatus }, true);
}

export async function markBoardingReviewed(locationId: string) {
    await put(`api/ops/locations/${locationId}/markReviewed`, {}, true);
}

export async function setMidReplaceBy(replaceeLocationId: string, replacerLocationId?: string) {
    await put(`api/ops/locations/${replaceeLocationId}/mid-change`, { replacedByLocationId: replacerLocationId }, true);
}

export async function getMidTransAccountsByLocationId(locationId: string): Promise<PotentialMidTrans[]> {
    return get<PotentialMidTrans[]>(`api/ops/locations/${locationId}/mid-trans-accounts`, true, {});
}
