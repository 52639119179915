import { ReactTable, useLoginContext, useModalEditor } from "@davo/portal-common";
import {
    ISubscriptionAndType,
    POSType,
    moneyFromCents,
    subscriptionManagedByPOS,
    toDisplayDateString,
    waiveFeeManagedByPOS,
} from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useCallback, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { SubscriptionChange } from "./SubscriptionChange";
import { SubscriptionDiscountInfo } from "./SubscriptionDiscountInfo";
import { getLocationSubscriptions } from "./services";
import { hasPermission } from "./util";

const renderStartDate = (data: any) => {
    return <>{toDisplayDateString(data.value)}</>;
};

const renderChargeAmount = (data: any) => {
    return <>{moneyFromCents(data.value)}</>;
};

const limitNameLength = (name: string) => {
    const maxLength = 35;
    return name.length > maxLength ? `${name.substring(0, maxLength - 3)}...` : name;
};

interface ILocationSubscriptionsModal {
    locationId: string;
    posType: POSType;
    isPartner?: boolean;
    accountId: string;
}

export function LocationSubscriptionsModal({ locationId, posType, isPartner, accountId }: ILocationSubscriptionsModal) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const loginContext = useLoginContext();

    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [subscriptions, setSubscriptions] = useState<ISubscriptionAndType[]>();
    const [currentSubscription, setCurrentSubscription] = useState<ISubscriptionAndType>();
    const [canWaiveOrDiscountFee, setCanWaiveOrDiscountFee] = useState<boolean>(false);
    const [hasCanDiscountPermission, setHasCanDiscountPermission] = useState<boolean>(false);
    const [shouldHideSubscriptionSection, setShouldHideSubscriptionSection] = useState<boolean>(true);

    const loadSubscriptions = useCallback(async () => {
        const subs = await getLocationSubscriptions(locationId);
        const latestSub = subs && subs.length > 0 ? subs[0] : undefined;
        setSubscriptions(subs);
        setCurrentSubscription(latestSub);
        setCanWaiveOrDiscountFee(
            !isPartner &&
                (!subscriptionManagedByPOS.includes(posType) || waiveFeeManagedByPOS.includes(posType)) &&
                latestSub?.type?.description !== "Unsubscribed"
        );
        setHasCanDiscountPermission(hasPermission(loginContext.permissions, "can_discount_subscription"));
    }, [isPartner, locationId, loginContext.permissions, posType]);

    useAsyncEffect(async () => {
        if (!isDialogOpen || isNil(loginContext.permissions)) {
            return;
        }
        await loadSubscriptions();
        setShouldHideSubscriptionSection(
            !hasPermission(loginContext.permissions, "manage_subscriptions") || accountId === "no-account"
        );
    }, [loadSubscriptions, loginContext.permissions, isDialogOpen]);

    const shutdown = () => {
        closeDialog();
    };

    const columns = useMemo(() => {
        return [
            {
                Header: "Description",
                accessor: "type.description",
            },
            {
                Header: "Start date",
                accessor: "startDate",
                Cell: renderStartDate,
            },
            {
                Header: "Charge",
                Cell: renderChargeAmount,
                accessor: "type.charge",
            },
        ];
    }, []);

    return (
        <div data-testid={"subscription-info"}>
            <Button
                data-testid={"subscription-info-button"}
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="primary"
                style={{ marginBottom: "2px", marginTop: "2px" }}>
                {!isNil(currentSubscription) ? limitNameLength(currentSubscription?.type.description) : "Subscriptions"}
            </Button>
            {isDialogOpen && subscriptions && (
                <Dialog
                    data-testid={"subscription-info-dialog"}
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        shutdown();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Subscriptions</DialogTitle>
                    <DialogContent>
                        {!shouldHideSubscriptionSection && (
                            <SubscriptionChange
                                locationId={locationId}
                                currentSubscription={currentSubscription}
                                refreshParent={loadSubscriptions}
                            />
                        )}
                        <SubscriptionDiscountInfo
                            canDiscount={canWaiveOrDiscountFee}
                            canRemoveDiscount={canWaiveOrDiscountFee}
                            hasPermission={hasCanDiscountPermission}
                            locationId={locationId}
                            posType={posType}
                            reload={loadSubscriptions}
                            maxFlat={subscriptions.length > 0 ? subscriptions[0].type.charge : 0}
                        />
                        <ReactTable
                            columns={columns}
                            data={subscriptions}
                            options={{
                                hideToolbar: true,
                                pageSize: 10,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => shutdown()} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
