import {
    d30ToastError,
    getHistoricalFilingsForAccount,
    getJurisdictionByStateCode,
    getJurisdictionFilingTypes,
    Loading,
    ReactTable8,
    Select,
    setGlobal,
    useGlobalOptional,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import {
    AF_BULK_PROCESSING,
    AF_ERRORED,
    AF_ERRORED_VALIDATION,
    AF_OPEN,
    AF_TESTED,
    AF_UNTESTED,
    AutofilerStatusFilterDisplayNames,
    BusinessDay,
    FilingDetails,
    FilingHistoricalWithAttachments,
    FilingWithAttachmentsAndHistory,
    hasMidMonthPayment,
    IFilingRow,
    IJurisdiction,
    IJurisdictionFilingType,
    initialCap,
    TaxProfileFilingMethod,
    TaxProfileFilingMethodKeys,
    TaxProfileFilingMethods,
    User,
} from "@davo/types";
import { Button, FormControlLabel, Typography, useMediaQuery, useTheme } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { AssignStaffModal } from "./AssignStaffModal";
import { HistoricalFilingDetailModal } from "./HistoricalFilingDetailModal";
import {
    getAccountOwnersForOpenFilings,
    getAllFilingsForAccount,
    getAssigneesForOpenFilings,
    getDueDatesForReadyFilings,
    getJurisdictionsForReadyFilings,
    getOpenFilingsForAccount,
    getReadyAndUpcomingFilingsForFilter,
    getRecentFilings,
    updateFilingAssignee,
} from "./services";

import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import useAsyncEffect from "use-async-effect";
import { AutofilerButtonsMulti } from "./filing/AutofilerButtonsMulti";
import { RenderFileInfo } from "./filing/RenderFileInfo";
import { RenderFilingLink } from "./filing/RenderFilingLink";
import { RenderFilingPeriodDueDate } from "./filing/RenderFilingPeriodDueDate";
import { RenderFilingPeriodEnd } from "./filing/RenderFilingPeriodEnd";
import { RenderFilingPeriodStart } from "./filing/RenderFilingPeriodStart";
import { RenderProfile } from "./filing/RenderProfile";
import { RenderRecentlyViewed } from "./filing/RenderRecentlyViewed";
import { RenderReviewedIcon } from "./filing/RenderReviewedIcon";
import { RenderStatus } from "./filing/RenderStatus";
import { RenderUserName } from "./filing/RenderUserName";

const sortFiled = (x: FilingDetails, y: FilingDetails) => {
    const xFiled = x.filedDate?.toSQLDate();
    const yFiled = y.filedDate?.toSQLDate();
    // one is filed
    if (xFiled && !yFiled) {
        return 1;
    } else if (!xFiled && yFiled) {
        return -1;
    } else {
        // both are filed
        if (xFiled && yFiled) {
            if (x.filingDueDate.toSQLDate() < y.filingDueDate.toSQLDate()) {
                return 1;
            } else if (x.filingDueDate.toSQLDate() > y.filingDueDate.toSQLDate()) {
                return -1;
            } else {
                return sortOnName(x, y);
            }
        } else {
            // neither is filed
            // one is unsettled
            if (x.hasUnsettled && !y.hasUnsettled) {
                return 1;
            } else if (!x.hasUnsettled && y.hasUnsettled) {
                return -1;
            } else {
                // both are unsettled or neither are unsettled
                if (x.filingDueDate.toSQLDate() < y.filingDueDate.toSQLDate()) {
                    return -1;
                } else if (x.filingDueDate.toSQLDate() > y.filingDueDate.toSQLDate()) {
                    return 1;
                } else {
                    return sortOnName(x, y);
                }
            }
        }
    }
};

const sortOnName = (x: FilingDetails, y: FilingDetails) => {
    if (x.taxProfile && y.taxProfile) {
        return x.taxProfile.legalName < y.taxProfile.legalName ? 0 : 1;
    } else if (x.filedDate && y.filedDate) {
        return x.filedDate < y.filedDate ? 0 : 1;
    } else {
        return x.created < y.created ? 0 : 1;
    }
};

const sortHistorical = (x: FilingDetails, y: FilingDetails) => {
    if (x.periodEnd.toSQLDate() < y.periodEnd.toSQLDate()) {
        return 1;
    } else if (x.periodEnd.toSQLDate() > y.periodEnd.toSQLDate()) {
        return -1;
    } else {
        return sortOnName(x, y);
    }
};

interface IFilingSearchFilters {
    userFilter: string;
    accountOwnerFilter: string;
    state: string;
    dueDate: string;
    statusFilter: string;
    autofilerStatus: string;
    filingMethod: TaxProfileFilingMethod | undefined;
    jurisdictionFilingTypeId: string;
}

const initialFilters: IFilingSearchFilters = {
    userFilter: "",
    accountOwnerFilter: "",
    state: "",
    dueDate: "",
    statusFilter: "",
    autofilerStatus: "",
    filingMethod: undefined,
    jurisdictionFilingTypeId: "",
};

export function FilingsTable({
    accountId,
    includeFiled,
    showFilters,
    showAssign,
    showRecent,
    showAccountButton,
    showHistorical,
    showAutoFilerMultiEnqueue,
}: {
    accountId?: string;
    includeFiled: boolean;
    showFilters: boolean;
    showAssign: boolean;
    showRecent: boolean;
    showAccountButton: boolean;
    showHistorical: boolean;
    showAutoFilerMultiEnqueue: boolean;
}) {
    const loginContext = useLoginContext();

    const [filters] = useGlobalOptional<IFilingSearchFilters>("filingSearchFilters");
    const [filings, setFilings] = useState<IFilingRow[] | undefined>(undefined);
    const [filingTypes, setFilingTypes] = useState<IJurisdictionFilingType[]>();
    const [assignedStaff, setAssignedStaff] = useState<string[]>([]);
    const [allAccountOwners, setAllAccountOwners] = useState<{ id: string; name: string }[]>([]);
    const [accountOwners, setAccountOwners] = useState<string[]>([]);
    const [states, setStates] = useState<string[]>([]);
    const [dueDates, setDueDates] = useState<string[]>([]);
    const [statuses, setStatuses] = useState<string[]>([]);
    const [canFilterByAutofilerStatus, setCanFilterByAutofilerStatus] = useState<boolean>(false);
    const [canFilterByFilingType, setCanFilterByFilingType] = useState<boolean>(false);
    const theme = useTheme();
    const canHideColumn = useMediaQuery(theme.breakpoints.down("sm"));
    const [recentlyViewed, setRecentlyViewed] = useState<string[]>([]);
    const controllerRef = useRef<AbortController | null>();
    const [updateTicker, setUpdateTicker] = useState<number>(0);
    const [selectedFilingIds, setSelectedFilingIds] = useState<string[]>();
    const toggleAllRowsSelectedHandlerRef = useRef<(value: boolean | undefined) => void>();

    useAsyncEffect(async () => {
        // use saved filters
        let clonedFilters = filters;
        if (!clonedFilters) {
            // else default filters
            clonedFilters = cloneDeep(initialFilters);
        }

        if (showFilters || showAssign || showRecent) {
            // Go get all the values needed to populate the filter controls
            const [readyDates, readyStates, assigneesForOpenFilings, allAccountOwnersValue, recentFilings] =
                await Promise.all([
                    getDueDatesForReadyFilings(),
                    getJurisdictionsForReadyFilings(),
                    getAssigneesForOpenFilings(),
                    getAccountOwnersForOpenFilings(),
                    getRecentFilings(),
                ]);

            const mappedReadyDates = readyDates.map((x) => x.dueDate.toString()).reverse();
            setDueDates(mappedReadyDates);

            const stateCodes = readyStates.map((j) => j.code);
            setStates(stateCodes);

            const results = [];
            results.push("Unassigned");
            results.push(...assigneesForOpenFilings.map((x) => x.name).sort());
            setAssignedStaff(results);

            const results2 = [];
            results2.push("Unassigned");
            results2.push(...allAccountOwnersValue.map((x) => x.name).sort());
            setAllAccountOwners(allAccountOwnersValue);
            setAccountOwners(results2);

            setRecentlyViewed(recentFilings);

            // Check for state param and if set use it, but only if it's in the list above
            const url = window.location.search;
            const params = new URLSearchParams(url);
            const state = params.get("state");
            const dueDateStr = params.get("due-date");

            if (state) {
                clonedFilters.state = stateCodes.includes(state) ? state : "";
            }
            if (dueDateStr) {
                clonedFilters.dueDate = mappedReadyDates.includes(dueDateStr) ? dueDateStr : "";
            }
        }

        const st = ["pending", "ready", "late", "reviewed", "updated", "unsettled", "future"];
        if (includeFiled) {
            st.push(...["filed"]);
        }
        setStatuses(st);
        await updateFilings(clonedFilters);
    }, [updateTicker]);

    useAsyncEffect(async () => {
        if (filters?.state) {
            const jurisdiction: IJurisdiction = await getJurisdictionByStateCode(filters?.state);
            const jurisdictionFilingTypes = await getJurisdictionFilingTypes(jurisdiction.id);
            setFilingTypes(jurisdictionFilingTypes);
        } else {
            setFilingTypes(undefined);
        }
    }, [canFilterByFilingType, filters?.state]);

    const updateFilings = useCallback(
        async (filingSearchFilters?: IFilingSearchFilters) => {
            setFilings(undefined);

            let clonedFilters: IFilingSearchFilters | undefined = cloneDeep(filingSearchFilters);
            if (!clonedFilters) {
                clonedFilters = cloneDeep(filters);
            }
            setGlobal("filingSearchFilters", clonedFilters);
            if (controllerRef.current) {
                controllerRef.current.abort();
            }
            controllerRef.current = new AbortController();

            let freshFilings: any[] = [];
            let historicalFilingsPromise: Promise<FilingHistoricalWithAttachments[]> | undefined;

            if (accountId) {
                if (showHistorical) {
                    historicalFilingsPromise = getHistoricalFilingsForAccount(accountId, controllerRef.current).catch(
                        (e) => {
                            d30ToastError("Problem getting historical filings for account.", e);
                            return [];
                        }
                    );
                }
                if (includeFiled) {
                    freshFilings = await getAllFilingsForAccount(accountId, controllerRef.current).catch((e) => {
                        d30ToastError("Problem getting all filings.", e);
                        return [];
                    });
                } else {
                    freshFilings = await getOpenFilingsForAccount(accountId, controllerRef.current).catch((e) => {
                        d30ToastError("Problem getting open filings.", e);
                        return [];
                    });
                }
                controllerRef.current = null;
            } else {
                if (
                    clonedFilters &&
                    (clonedFilters.state ||
                        clonedFilters.statusFilter ||
                        clonedFilters.filingMethod ||
                        clonedFilters.jurisdictionFilingTypeId ||
                        clonedFilters.autofilerStatus ||
                        clonedFilters.userFilter ||
                        clonedFilters.dueDate ||
                        clonedFilters.accountOwnerFilter)
                ) {
                    // convert owner to id
                    let ownerId;
                    if (clonedFilters?.accountOwnerFilter) {
                        if (clonedFilters.accountOwnerFilter === "Unassigned") {
                            ownerId = "Unassigned";
                        } else {
                            ownerId = allAccountOwners.find((x) => x.name === clonedFilters?.accountOwnerFilter)?.id;
                        }
                    }
                    if (clonedFilters?.state) {
                        setCanFilterByAutofilerStatus(true);
                        setCanFilterByFilingType(true);
                    }
                    freshFilings = await getReadyAndUpcomingFilingsForFilter(
                        {
                            ...clonedFilters,
                            accountOwner: ownerId,
                        },
                        controllerRef.current
                    ).catch((e) => {
                        if (e.name === "AbortError") {
                            console.debug(e.message); // eslint-disable-line no-console
                        } else {
                            d30ToastError("Problem getting ready and upcoming filings.", e);
                        }
                        return [];
                    });
                } else {
                    freshFilings = [];
                }
            }

            const addHistory = (isHistorical: boolean, filing: any): FilingWithAttachmentsAndHistory => {
                return { ...filing, historical: isHistorical };
            };

            let historicalFilings: FilingHistoricalWithAttachments[] = [];

            if (historicalFilingsPromise) {
                historicalFilings = await historicalFilingsPromise;
            }

            freshFilings = [
                ...freshFilings.map((x) => addHistory(false, x)),
                ...historicalFilings.map((x) => addHistory(true, x)),
            ];
            freshFilings.sort((x, y) => {
                if (x.recentlyUpdatedCredentials && !y.recentlyUpdatedCredentials) {
                    return -1;
                } else if (!x.recentlyUpdatedCredentials && y.recentlyUpdatedCredentials) {
                    return 1;
                } else if (x.historical && !y.historical) {
                    return 1;
                } else if (!x.historical && y.historical) {
                    return -1;
                } else if (x.historical && y.historical) {
                    return sortHistorical(x, y);
                } else {
                    return sortFiled(x, y);
                }
            });
            const today = BusinessDay.today("America/New_York");
            setFilings(
                freshFilings
                    .map((row) => {
                        let status = "ready";
                        if (row.historical) {
                            status = "filed";
                        } else if (row.amendmentStatus === "completed") {
                            status = "amended";
                        } else if (row.amendmentStatus && row.amendmentStatus !== "completed") {
                            status = "to amend";
                        } else if (row.filedDate) {
                            status = row.status === "wontfile" ? "won't file" : row.status;
                        } else if (today <= row.periodEnd) {
                            status = "future";
                        } else if (row.hasUnsettled && !hasMidMonthPayment(row.frequency)) {
                            status = "unsettled";
                        } else if ((today <= row.filingDueDate && today.day < 5) || !isNil(row.bulkTaskId)) {
                            status = "pending";
                        } else if (row.reviewed && row.reviewNote) {
                            status = "reviewed";
                        } else if (row.recentlyUpdatedCredentials) {
                            status = "updated";
                        } else if (today > row.filingDueDate) {
                            status = "late";
                        }

                        if (!row.historical) {
                            return {
                                id: row.id,
                                status,
                                accountName: row.taxProfile.account.name,
                                accountId: row.taxProfile.account.id,
                                profileName: row.taxProfile.name,
                                legalName: row.taxProfile.legalName,
                                periodStart: row.periodStart,
                                periodEnd: row.periodEnd,
                                filingDueDate: row.filingDueDate,
                                filedDate: row.filedDate,
                                hasUnsettled: row.hasUnsettled,
                                details: row,
                                historical: false,
                                reviewed: row.reviewed,
                                reviewNote: row.reviewNote,
                            };
                        } else {
                            if (accountId) {
                                return {
                                    id: row.id,
                                    status,
                                    accountName: "Historical",
                                    accountId,
                                    profileName: "Historical",
                                    legalName: "Filed",
                                    periodStart: row.periodStart,
                                    periodEnd: row.periodEnd,
                                    filingDueDate: row.periodEnd,
                                    filedDate: row.filedDate,
                                    hasUnsettled: false,
                                    details: row,
                                    historical: true,
                                    reviewed: row.reviewed,
                                    reviewNote: row.reviewNote,
                                };
                            } else {
                                throw new Error("Account ID not specified!");
                            }
                        }
                    })
                    .filter(
                        (r) =>
                            !showFilters ||
                            (showFilters &&
                                clonedFilters &&
                                (!clonedFilters.statusFilter || r.status === clonedFilters.statusFilter.toLowerCase()))
                    )
            );
            toggleAllRowsSelectedHandlerRef.current?.(false);
        },
        [
            accountId,
            allAccountOwners,
            filters,
            includeFiled,
            showFilters,
            showHistorical,
            toggleAllRowsSelectedHandlerRef,
        ]
    );

    const [showFilingAssigneeModal, filingAssigneeModalProps] = useModalEditor<string>();
    const [showHistoricalFilingModal, historicalFilingModalProps] = useModalEditor<{
        filingId: string;
        accountId: string;
    }>();

    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<IFilingRow>();

        const assignRow = async (ua: IFilingRow) => {
            if (loginContext.user) {
                await updateFilingAssignee(ua.id, loginContext.user.id);
                setUpdateTicker((prevCount) => prevCount + 1);
            }
        };

        return [
            columnHelper.accessor("status", {
                header: "Status",
                cell: (data) => <RenderStatus row={data.cell.row.original} theme={theme} />,
            }),
            columnHelper.accessor("legalName", {
                header: "Legal Name",
                cell: (data) => <RenderProfile ua={data.row.original} showAccountButton={showAccountButton} />,
            }),
            columnHelper.accessor("periodStart", {
                header: "Start",
                cell: (data) => <RenderFilingPeriodStart date={data.getValue()} />,
            }),
            columnHelper.accessor("periodEnd", {
                header: "End",
                cell: (data) => <RenderFilingPeriodEnd date={data.getValue()} />,
            }),
            columnHelper.accessor("filingDueDate", {
                header: "Due",
                cell: (data) => <RenderFilingPeriodDueDate date={data.getValue()} />,
            }),
            columnHelper.accessor("details.assignedUser", {
                header: "Assignee",
                cell: (data) => <RenderUserName ua={data.row.original} assignRow={assignRow} />,
            }),
            columnHelper.accessor("filedDate", {
                header: "",
                enableSorting: false,
                cell: (data) => <RenderFileInfo row={data.row.original} historicalFiling={showHistoricalFilingModal} />,
            }),
            columnHelper.accessor("id", {
                header: "",
                enableSorting: false,
                cell: (data) => <RenderFilingLink row={data.row.original} />,
            }),
            columnHelper.accessor("profileName", {}),
            columnHelper.accessor("details.taxProfile.state", {
                header: "State",
            }),
            columnHelper.accessor("reviewed", {
                header: "Reviewed",
                cell: (data) => <RenderReviewedIcon ua={data.row.original} />,
            }),
        ] as ColumnDef<IFilingRow, any>[];
    }, [loginContext.user, theme, showAccountButton, showHistoricalFilingModal]);

    const getHiddenColumns = useCallback(() => {
        if (!canHideColumn) {
            return ["profileName", "details.taxProfile.state"];
        }
        return ["profileName", "details.taxProfile.state", "periodEnd", "periodStart"];
    }, [canHideColumn]);

    const visibleColumns: { [key: string]: boolean } = {};
    for (const colName of getHiddenColumns()) {
        visibleColumns[colName] = false;
    }

    if (!filters) {
        return <Loading />;
    }

    const handleUserFilterChange = async (val: string | undefined) => {
        await updateFilings({ ...filters, userFilter: val ?? "" });
    };

    const handleAccountOwnerFilterChange = async (val: string | undefined) => {
        await updateFilings({ ...filters, accountOwnerFilter: val ?? "" });
    };

    const handleStateFilterChange = async (val: string | undefined) => {
        setCanFilterByAutofilerStatus(!!val);
        setCanFilterByFilingType(!!val);

        await updateFilings({
            ...filters,
            state: val ?? "",
            autofilerStatus: "",
            jurisdictionFilingTypeId: "",
        });
    };

    const handleDueDateFilterChange = async (val: string | undefined) => {
        await updateFilings({ ...filters, dueDate: val ?? "" });
    };

    const handleStatusFilterChange = async (val: string | undefined) => {
        await updateFilings({ ...filters, statusFilter: val ?? "" });
    };

    const handleFilingMethodFilterChange = async (val: TaxProfileFilingMethod | undefined) => {
        await updateFilings({ ...filters, filingMethod: val ?? undefined });
    };

    const handleFilingTypeFilterChange = async (val: IJurisdictionFilingType | undefined) => {
        await updateFilings({ ...filters, jurisdictionFilingTypeId: val ? val.id : "" });
    };

    const handleAutofilerStatusFilterChange = async (val: string | undefined) => {
        await updateFilings({ ...filters, autofilerStatus: val ?? "" });
    };

    const refreshFilings = async () => {
        await updateFilings(filters);
    };

    const assignFilingAssignee = (passThroughIds: string[] | undefined, selected: User | undefined) => {
        if (passThroughIds) {
            for (const r of passThroughIds) {
                updateFilingAssignee(r, (selected && selected.id) ?? undefined).catch((e) =>
                    d30ToastError("Problem updating filing assignee.", e)
                );
            }
        }
        setUpdateTicker((prevCount) => prevCount + 1);
    };

    return (
        <div>
            {showFilters && (
                <div style={{ marginBottom: "20px" }}>
                    <div>
                        <Typography style={{ marginBottom: "20px" }} variant="body1">
                            Some filters are limited to the first 5,000 open filings.
                        </Typography>
                    </div>
                    <div style={{ alignItems: "center", display: "inline-flex" }}>
                        <Typography style={{ marginRight: "20px" }} variant="body1">
                            Filters:
                        </Typography>
                        <FormControlLabel
                            control={
                                <Select<string>
                                    title="User"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={assignedStaff}
                                    value={filters.userFilter}
                                    onChange={handleUserFilterChange}
                                    label={(x: string) => initialCap(x)}
                                />
                            }
                            label={""}
                        />
                        <FormControlLabel
                            control={
                                <Select<string>
                                    title="Owner"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={accountOwners}
                                    value={filters.accountOwnerFilter}
                                    onChange={handleAccountOwnerFilterChange}
                                    label={(x: string) => initialCap(x)}
                                />
                            }
                            label={""}
                        />
                        <FormControlLabel
                            control={
                                <Select<string>
                                    title="State"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={states}
                                    value={filters.state}
                                    onChange={handleStateFilterChange}
                                />
                            }
                            label={""}
                        />
                        {canFilterByFilingType && filingTypes && filingTypes.length > 1 && (
                            <FormControlLabel
                                control={
                                    <Select<IJurisdictionFilingType>
                                        title="Filing Type"
                                        style={{}}
                                        noneLabel={"None"}
                                        options={filingTypes}
                                        value={filingTypes.find((t) => t.id === filters.jurisdictionFilingTypeId)}
                                        label={(s) => s?.name}
                                        onChange={handleFilingTypeFilterChange}
                                    />
                                }
                                label={""}
                            />
                        )}
                        <FormControlLabel
                            control={
                                <Select<string>
                                    title="Due Date"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={dueDates}
                                    value={filters.dueDate}
                                    onChange={handleDueDateFilterChange}
                                />
                            }
                            label={""}
                        />
                        <FormControlLabel
                            control={
                                <Select<string>
                                    title="Status"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={statuses.map((x) => initialCap(x))}
                                    value={filters.statusFilter}
                                    onChange={handleStatusFilterChange}
                                />
                            }
                            label={""}
                        />
                        <FormControlLabel
                            control={
                                <Select<TaxProfileFilingMethod>
                                    title="Method"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={TaxProfileFilingMethodKeys}
                                    label={(typeId: TaxProfileFilingMethod) => TaxProfileFilingMethods[typeId]}
                                    value={filters.filingMethod}
                                    onChange={handleFilingMethodFilterChange}
                                />
                            }
                            label={""}
                        />
                        {canFilterByAutofilerStatus && (
                            <FormControlLabel
                                control={
                                    <Select<string>
                                        title="Autofiler Status"
                                        style={{}}
                                        noneLabel={"None"}
                                        options={[
                                            AF_UNTESTED,
                                            AF_OPEN,
                                            AF_BULK_PROCESSING,
                                            AF_ERRORED,
                                            AF_ERRORED_VALIDATION,
                                            AF_TESTED,
                                        ]}
                                        value={filters.autofilerStatus}
                                        label={(s) => AutofilerStatusFilterDisplayNames[s]}
                                        onChange={handleAutofilerStatusFilterChange}
                                    />
                                }
                                label={""}
                            />
                        )}
                    </div>
                </div>
            )}
            {(showAssign || showAutoFilerMultiEnqueue) && (
                <div style={{ display: "flex", marginBottom: "20px" }}>
                    {selectedFilingIds && selectedFilingIds.length > 0 && (
                        <div style={{ marginTop: "4px", marginBottom: "8px" }}>
                            {showAssign &&
                                selectedFilingIds.some((i) => {
                                    const details = filings?.filter((f) => f.id === i)[0].details;
                                    return details && !details.assignedUserId && details.status === "open";
                                }) && (
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        style={{ marginRight: "5px" }}
                                        disabled={selectedFilingIds.length === 0}
                                        onClick={() => showFilingAssigneeModal()}>
                                        Assign
                                    </Button>
                                )}
                            {filings && showAutoFilerMultiEnqueue && (
                                <AutofilerButtonsMulti
                                    filings={selectedFilingIds.map(
                                        (id: string) => filings.filter((t) => t.id === id)[0].details
                                    )}
                                    refreshFilings={async () => {
                                        await refreshFilings();
                                        setSelectedFilingIds([]);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}

            {!filings && <Loading />}

            {filings && filings.length > 0 && (
                <>
                    <ReactTable8<IFilingRow>
                        columns={columns}
                        data={filings}
                        refreshData={refreshFilings}
                        options={{
                            hiddenColumns: getHiddenColumns(),
                            showMultiSelection: {
                                onSelectionChange: (data) => {
                                    setSelectedFilingIds(data.map((d) => d.id));
                                },
                                toggleAllRowsSelectedFn: (fn: (value?: boolean) => void) => {
                                    toggleAllRowsSelectedHandlerRef.current = fn;
                                },
                                showSelectedCount: false,
                            },
                        }}
                    />
                    {filingAssigneeModalProps.isDialogOpen && (
                        <AssignStaffModal
                            shouldFilterByFilers={true}
                            passThroughIds={selectedFilingIds}
                            {...filingAssigneeModalProps}
                            assignStaffUser={assignFilingAssignee}
                        />
                    )}
                </>
            )}

            {filters &&
                (filters.state ||
                    filters.statusFilter ||
                    filters.filingMethod ||
                    filters.jurisdictionFilingTypeId ||
                    filters.autofilerStatus ||
                    filters.userFilter ||
                    filters.dueDate ||
                    filters.accountOwnerFilter) &&
                isEmpty(filings) && (
                    <div>
                        <Typography style={{ marginBottom: "20px" }} variant="body1">
                            No Filings Matching Search Criteria
                        </Typography>
                    </div>
                )}

            {showRecent ? (
                recentlyViewed.length > 0 ? (
                    <RenderRecentlyViewed recentlyViewed={recentlyViewed} />
                ) : (
                    ""
                )
            ) : (
                ""
            )}
            {historicalFilingModalProps.isDialogOpen && <HistoricalFilingDetailModal {...historicalFilingModalProps} />}
        </div>
    );
}
