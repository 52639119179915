import { Location, notDavoBilledPOSes } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { AccountDiscountModal } from "./AccountDiscountModal";
import { BillingsTable } from "./BillingsTable";
import { OpsAccountFilingsTable, OpsFilingsFilter } from "./components";
import { OpsFilingsFilterResultsContextProvider } from "./context";
import { HistoryTable } from "./HistoryTable";
import { UsersTable } from "./UsersTable";

export function AccountDetailAccordions({
    accountId,
    partnerId,
    locations,
}: {
    accountId: string;
    partnerId?: string;
    locations?: Location[];
}) {
    const filings = () => {
        return (
            <div>
                <OpsFilingsFilterResultsContextProvider accountId={accountId}>
                    <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                        <AccordionSummary
                            data-testid={"expandAccordionSummary"}
                            expandIcon={<ExpandMoreIcon />}
                            style={{ fontSize: "20px" }}>
                            Filings
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <div style={{ width: "100%" }}>
                                <OpsFilingsFilter />
                                <OpsAccountFilingsTable />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </OpsFilingsFilterResultsContextProvider>
            </div>
        );
    };

    const users = () => {
        return (
            <div style={{ marginTop: "5px" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        Users
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <UsersTable accountId={accountId} showAll={false} />
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };

    const accountHistory = () => {
        return (
            <div style={{ marginTop: "5px" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        History
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <HistoryTable accountId={accountId} />
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };

    const billing = () => {
        const cloverLocations = locations ? locations.filter((x) => x.posType === "clover") : [];
        const poyntLocations = locations ? locations.filter((x) => x.posType === "poynt") : [];
        return (
            <div style={{ marginTop: "5px", width: "100%" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary
                        data-testid={"accordion-summary-expand"}
                        expandIcon={<ExpandMoreIcon />}
                        style={{ fontSize: "20px" }}>
                        Billing
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex", flexFlow: "column" }}>
                        {locations &&
                            !locations.every((location) => {
                                return notDavoBilledPOSes.includes(location.posType);
                            }) && (
                                <div
                                    data-testid={"account-discount-modal"}
                                    style={{ marginLeft: "-5px", marginBottom: "10px" }}>
                                    <AccountDiscountModal accountId={accountId} />
                                </div>
                            )}
                        <BillingsTable
                            accountId={accountId}
                            cloverLocations={cloverLocations}
                            poyntLocations={poyntLocations}
                            isPartner={!!partnerId}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };
    return (
        <div style={{ marginTop: 10 }}>
            {filings()}
            {users()}
            {billing()}
            {accountHistory()}
        </div>
    );
}
