import {
    AddressVerificationContextProvider,
    BankDetailsPausedMessage,
    getBankAccountNachaRestricted,
    useLoginContext,
} from "@davo/portal-common";
import {
    BankAccountNachaRestrictedMessage,
    DiscountReasons,
    IDeactivationStatus,
    initialCap,
    Location,
    LocationRecord,
    Partner,
    PotentialMidTrans,
    RelatedLocation,
    toDisplayDateString,
    toDisplayDateTimeString,
    truncateString,
} from "@davo/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AccountDetailCard } from "./AccountDetailCard";
import { AccountRelatedLocationsModal } from "./AccountRelatedLocationsModal";
import { BankAccountCredentialsButton } from "./BankAccountCredentialsButton";
import { BankAccountPause } from "./BankAccountPause";
import { BillForExtraFilingsModal } from "./BillForExtraFilingsModal";
import { CloverBillingStatusRender } from "./CloverBillingStatusRender";
import { CustomBillingModal } from "./CustomBillingModal";
import { DeactivateLocationButton } from "./DeactivateLocationButton";
import { ExtractLocationButton } from "./ExtractLocationButton";
import { ForceDisconnectButton } from "./ForceDisconnectButton";
import { LocationAccounting } from "./LocationAccounting";
import { LocationAddress } from "./LocationAddress";
import { LocationDailyDetailModal } from "./LocationDailyDetailModal";
import { LocationManualTransactionsModal } from "./LocationManualTransactionsModal";
import { LocationMidChangeModal } from "./LocationMidChangeModal";
import { LocationSubscriptionsModal } from "./LocationSubscriptionsModal";
import { POSCredentialsButton } from "./POSCredentialsButton";
import { ReactivateLocationButton } from "./ReactivateLocationButton";
import { ReconnectLocationButton } from "./ReconnectLocationButton";
import { RefreshLocationAccountingButton } from "./RefreshLocationAccountingButton";
import { RelatedMidTrans } from "./RelatedMidTrans";
import { getMidTransAccountsByLocationId, getRelatedLocations } from "./services";
import { SetAwaitingDisconnectButton } from "./SetAwaitingDisconnectButton";
import { TaxProfileCredentialsButton } from "./TaxProfileCredentialsButton";
import { TaxProfileFilingTypeRender } from "./TaxProfileFilingTypeRender";
import { TaxProfileSettingsButton } from "./TaxProfileSettingsButton";
import { hasPermission } from "./util";

const useStyles = makeStyles({
    card_content: {
        maxWidth: "270px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    card_title_container: {
        display: "flex",
        justifyContent: "space-between",
        gap: 2,
    },
});

export interface ILocationDetailType {
    location: Location;
    onChange?: () => void;
    accountId: string;
    deactivationStatusLoaded?: IDeactivationStatus;
    partner?: Partner;
    allAccountLocations: LocationRecord[] | undefined;
}

export function LocationDetail({
    location,
    onChange,
    accountId,
    deactivationStatusLoaded,
    partner,
    allAccountLocations,
}: ILocationDetailType) {
    const classes = useStyles();
    const loginContext = useLoginContext();

    const [potentialMidTrans, setPotentialMidTrans] = useState<PotentialMidTrans[]>([]);
    const [relatedLocations, setRelatedLocations] = useState<RelatedLocation[]>();
    const [nachaRestricted, setNachaRestricted] = useState<BankAccountNachaRestrictedMessage>();
    const [deactivationStatus, setDeactivationStatus] = useState<IDeactivationStatus>({
        showDeactivate: false,
        canDeactivate: false,
        showSetAwaitingDisconnect: false,
        canSetAwaitingDisconnect: false,
        canDisconnect: false,
    });

    useAsyncEffect(async () => {
        const [related, midTrans] = await Promise.all([
            getRelatedLocations(location.id),
            getMidTransAccountsByLocationId(location.id),
        ]);
        setRelatedLocations(related);
        setPotentialMidTrans(midTrans);
    }, []);

    useEffect(() => {
        if (deactivationStatusLoaded) {
            setDeactivationStatus(deactivationStatusLoaded);
        }
    }, [deactivationStatusLoaded]);

    useAsyncEffect(async () => {
        if (location.bankAccount) {
            setNachaRestricted(await getBankAccountNachaRestricted(accountId, location.bankAccount.id));
        }
    }, [accountId, location]);

    if (!loginContext.user || !relatedLocations) {
        return null;
    }

    const renderDisconnectInfo = () => {
        const discountReasonCode = !isNil(location.disconnectReasonCode)
            ? `${DiscountReasons[location.disconnectReasonCode]}. `
            : "";
        const disconnectReasonNote = !isNil(location.disconnectReasonNote) ? `${location.disconnectReasonNote}. ` : "";
        const disconnectNote = !isNil(location.disconnectNote) ? `${location.disconnectNote}.` : "";
        return discountReasonCode.concat(disconnectReasonNote, disconnectNote);
    };

    return (
        <div key={location.id} style={{ display: "flex", flexWrap: "wrap" }}>
            <AddressVerificationContextProvider
                accountId={accountId}
                location={location}
                refreshLocation={async () => onChange && onChange()}
                shouldAutoPrompt={false}
                showCancelVerify={true}
                showCounty={true}>
                <AccountDetailCard
                    title={
                        <div
                            data-testid="account-detail-location-title-container"
                            className={classes.card_title_container}>
                            <div>
                                <div style={{ color: "red", float: "left", paddingRight: "8px" }}>
                                    {location.beta ? "BETA:" : ""}
                                </div>
                                <div data-testid="location-name-title" style={{ display: "inline-block" }}>
                                    {location.name}
                                </div>
                            </div>
                            <RelatedMidTrans potentialMidTrans={potentialMidTrans} />
                        </div>
                    }
                    subheader={
                        <div className={classes.card_content} style={{ whiteSpace: "wrap" }}>
                            {location.externalLocationId && (
                                <Typography>External Location ID: {location.externalLocationId}</Typography>
                            )}
                            {location.externalPosType && (
                                <Typography>External POS: {location.externalPosType}</Typography>
                            )}
                            <POSCredentialsButton locationId={location.id} locationPOS={location.posType} />
                            {relatedLocations.length > 0 && (
                                <AccountRelatedLocationsModal
                                    relatedLocations={relatedLocations}
                                    displayButton={false}
                                />
                            )}
                            <CloverBillingStatusRender locationId={location.id} locationPOS={location.posType} />
                            {location.disconnected && (
                                <>
                                    <Typography style={{ fontSize: "0.95em" }}>
                                        Disconnected: {toDisplayDateTimeString(location.disconnected)}
                                    </Typography>
                                    {!isNil(location.disconnectReasonCode) && (
                                        <Typography style={{ fontSize: "0.95em" }}>
                                            Disconnect Info: {renderDisconnectInfo()}
                                        </Typography>
                                    )}
                                </>
                            )}
                            <SetAwaitingDisconnectButton
                                locationId={location.id}
                                onClose={onChange}
                                show={deactivationStatus.showSetAwaitingDisconnect}
                                isDisabled={!deactivationStatus.canSetAwaitingDisconnect}
                                isAwaitingDisconnect={location.awaitingDisconnect}
                            />
                            <ReconnectLocationButton
                                location={location}
                                show={!!location.disconnected || location.awaitingDisconnect}
                                onClose={onChange}
                            />
                            <ForceDisconnectButton
                                locationId={location.id}
                                disconnected={location.disconnected}
                                show={deactivationStatus.canDisconnect}
                                onClose={onChange}
                            />
                            <ReactivateLocationButton location={location} onClose={onChange} />
                            <DeactivateLocationButton
                                locationId={location.id}
                                disconnectReasonCode={location.disconnectReasonCode ?? ""}
                                disconnectReasonNote={location.disconnectReasonNote ?? ""}
                                show={deactivationStatus.showDeactivate}
                                isDisabled={!deactivationStatus.canDeactivate}
                                onClose={onChange}
                            />
                            <ExtractLocationButton
                                relatedLocations={relatedLocations}
                                id={{ accountId: accountId }}
                                locationId={location.id}
                                locationName={location.name}
                            />
                            <RefreshLocationAccountingButton locationId={location.id} onClose={onChange} />
                            {location.firstMonthMerchantPay &&
                                location.created &&
                                location.created.startOf("month") >= DateTime.utc().startOf("month") && (
                                    <Typography style={{ fontSize: "0.95em" }}>
                                        First month merchant pay enabled:{" "}
                                        {initialCap(location.created.toLocaleString({ month: "long" }))}
                                    </Typography>
                                )}
                        </div>
                    }
                    content={
                        <div className={classes.card_content}>
                            <LocationAddress location={location} />
                            <br />
                            <LocationSubscriptionsModal
                                locationId={location.id}
                                accountId={accountId}
                                posType={location.posType}
                                isPartner={!!partner}
                            />
                            <LocationAccounting accountId={accountId} locationId={location.id} />
                            <LocationManualTransactionsModal locationId={location.id} />
                            {location.active && <BillForExtraFilingsModal locationId={location.id} />}
                            {hasPermission(loginContext.permissions, "custom_billing") && (
                                <CustomBillingModal locationId={location.id} />
                            )}
                            <LocationMidChangeModal location={location} allAccountLocations={allAccountLocations} />
                        </div>
                    }
                />
                <AccountDetailCard
                    title={
                        location.bankAccount
                            ? `${location.bankAccount.nickname ? location.bankAccount.nickname + " | " : ""}${location.bankAccount.name} | ${location.bankAccount.accountStarred.substr(
                                  location.bankAccount.accountStarred.length - 4
                              )}`
                            : "No bank provided"
                    }
                    subheader={
                        <div className={classes.card_content}>
                            {location.bankAccount && nachaRestricted && (
                                <div>
                                    Routing: {location.bankAccount.routing} <br />
                                    <BankDetailsPausedMessage
                                        bankAccount={location.bankAccount}
                                        nachaRestricted={nachaRestricted}
                                    />
                                    {!nachaRestricted.isNachaRestricted &&
                                        location.bankAccount.paused &&
                                        location.bankAccount.unpauseDate && (
                                            <Typography style={{ color: "red", whiteSpace: "break-spaces" }}>
                                                Unpauses on{" "}
                                                {toDisplayDateString(location.bankAccount.unpauseDate, false)}
                                            </Typography>
                                        )}
                                </div>
                            )}
                        </div>
                    }
                    content={
                        <>
                            {location.bankAccount && (
                                <div className={classes.card_content}>
                                    {location && location.active && (
                                        <BankAccountPause
                                            location={location}
                                            accountId={accountId}
                                            bankAccount={location.bankAccount}
                                            refresh={onChange}
                                            partner={partner}
                                            isNachaRestricted={nachaRestricted?.isNachaRestricted ?? true}
                                        />
                                    )}
                                    <BankAccountCredentialsButton bankAccountId={location.bankAccount?.id} />
                                </div>
                            )}
                        </>
                    }
                />
                <AccountDetailCard
                    subheader={null}
                    content={
                        <>
                            {sortBy(location.taxProfiles, "name").map((taxProfile) => (
                                <div
                                    key={taxProfile.id}
                                    className={classes.card_content}
                                    style={{
                                        border: "1px solid #808080",
                                        marginBottom: "6px",
                                        borderRadius: "4px",
                                        padding: "6px",
                                    }}>
                                    <Typography>Name: {taxProfile.name}</Typography>
                                    {taxProfile.filingMethod !== "take-give-back" && (
                                        <Typography>Legal Name: {taxProfile.legalName}</Typography>
                                    )}
                                    <Typography>State: {taxProfile.state}</Typography>
                                    {taxProfile.filingMethod !== "take-give-back" &&
                                        !isEmpty(taxProfile.stateTaxId) && (
                                            <Typography>State Tax ID: {taxProfile.stateTaxId}</Typography>
                                        )}
                                    <Typography>Frequency: {taxProfile.frequency}</Typography>
                                    {taxProfile.jurisdictionFilingTypeId && (
                                        <TaxProfileFilingTypeRender id={taxProfile.jurisdictionFilingTypeId} />
                                    )}
                                    {taxProfile.filingMethod !== "take-give-back" &&
                                        !isEmpty(taxProfile.federalTaxId) && (
                                            <Typography>Federal Tax ID: {taxProfile.federalTaxId}</Typography>
                                        )}
                                    {taxProfile.filingMethod && (
                                        <Typography>Filing Method: {taxProfile.filingMethod}</Typography>
                                    )}
                                    {taxProfile.filingNote ? (
                                        <Typography title={taxProfile?.filingNote} style={{ whiteSpace: "normal" }}>
                                            Filing Note: {truncateString(taxProfile.filingNote, 50)}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}

                                    {location.active && (
                                        <TaxProfileSettingsButton
                                            taxProfile={taxProfile}
                                            accountId={accountId}
                                            callback={onChange}
                                            partner={partner}
                                            location={location}
                                        />
                                    )}

                                    <TaxProfileCredentialsButton
                                        partner={partner}
                                        isReadOnly={!location.active || taxProfile.filingMethod === "take-give-back"}
                                        taxProfileId={taxProfile.id}
                                        taxProfilePreloaded={taxProfile}
                                        credentialsFailingOverrideStatus={taxProfile.credentialsFailing}
                                        accountId={accountId}
                                        callback={onChange}
                                    />
                                </div>
                            ))}
                            <LocationDailyDetailModal accountId={accountId} locationId={location.id} />
                        </>
                    }
                />
            </AddressVerificationContextProvider>
        </div>
    );
}
