import { IFilingRow } from "@davo/types";
import OpenInBrowserTwoToneIcon from "@mui/icons-material/OpenInBrowserTwoTone";
import { IconButton } from "@mui/material";
import React from "react";

interface IRenderFilingLink {
    row: IFilingRow;
}

export const RenderFilingLink: React.FunctionComponent<IRenderFilingLink> = ({ row }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {!row.historical && (
                <IconButton
                    title={`View filing`}
                    aria-label={`View filing ${row.id}`}
                    onClick={() => {
                        row.status = "clicked";
                        window.open(`/filings/${row.id}`, "_blank");
                    }}>
                    <OpenInBrowserTwoToneIcon color="primary" />
                </IconButton>
            )}
        </div>
    );
};
