import { d30Toast, d30ToastError, NumberField, Select, TextField, useModalEditor } from "@davo/portal-common";
import { IBillingInfo, moneyFromCents, toDollars, toPennies } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { refundBilling } from "./services";

interface IViewBillingRefundModalType {
    accountId: string;
    info: IBillingInfo;
    cb: () => void;
}

export function ViewBillingRefundModal({ accountId, info, cb }: IViewBillingRefundModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [refundAmount, setRefundAmount] = useState<number | undefined>(info.amount);
    const [refundReason, setRefundReason] = useState<string | undefined>();
    const [refundReasonOther, setRefundReasonOther] = useState<string | undefined>();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const refundReasons = [
        "Courtesy Discount",
        "Marketing Promotion",
        "Merchant Cancelled Service",
        "Merchant to File",
        "Other",
    ];

    const shutdown = () => {
        closeDialog();
        setIsBusy(false);
    };

    const validateAmount = (amt: number | undefined, max: number) => {
        if (amt && (amt < 0 || amt * 100 > max)) {
            return `Cannot refund more than max or less than zero`;
        }
        return undefined;
    };

    const doRefund = async (amountArg?: number) => {
        if (amountArg) {
            setIsBusy(true);
            const refundExplanation =
                refundReason === "Other" && refundReasonOther ? `Other: ${refundReasonOther}` : refundReason;
            try {
                if (info.achId) {
                    await refundBilling(accountId, info.achId, amountArg, refundExplanation);
                    cb();
                    d30Toast("Billing refund initiated");
                } else {
                    d30ToastError("No ACH id on billing.");
                }
            } catch (e: any) {
                d30ToastError("Transaction could not be refunded at this time");
            } finally {
                setIsBusy(false);
                shutdown();
            }
        }
    };

    return (
        <>
            <Button variant="outlined" color="secondary" size="small" disabled={!info.canRefund} onClick={showDialog}>
                Refund
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={shutdown}>
                    <DialogTitle id="responsive-dialog-title">Refund Billing</DialogTitle>
                    <DialogContent>
                        <>
                            <Typography variant={"body1"}>{`Enter refund amount up to a max of ${moneyFromCents(
                                info.amount
                            )}.`}</Typography>
                            <Typography>Note: sales tax is not included and will be calculated for you.</Typography>

                            <NumberField
                                label="Amount"
                                value={toDollars(refundAmount)}
                                onChange={(x) => setRefundAmount(toPennies(x))}
                                validate={(y) => validateAmount(y, info.amount)}
                            />
                            <Select<string>
                                noneLabel="Reason for Refund"
                                options={refundReasons}
                                value={refundReason ?? undefined}
                                onChange={setRefundReason}
                                style={{ marginBottom: "20px" }}
                            />
                            {refundReason && refundReason === "Other" && (
                                <TextField
                                    label={"Please add a short explanation"}
                                    value={refundReasonOther ?? ""}
                                    onChange={setRefundReasonOther}
                                    isMultiline={true}
                                    rows={1}
                                />
                            )}
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" disabled={isBusy} onClick={() => shutdown()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                !refundAmount ||
                                isBusy ||
                                !refundReason ||
                                (refundReason === "Other" && !refundReasonOther)
                            }
                            onClick={() => doRefund(refundAmount)}>
                            Refund
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
