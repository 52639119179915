import { get, getArray, getOne, post, put } from "@davo/portal-common";
import {
    CredentialsType,
    FailedTaxProfileRecord,
    IEncryptedStoreHistoryEntry,
    MetaFailedTaxProfileRecord,
    MetaTaxProfileTaxRate,
    MetaTaxProfileWithCredentials,
    SupplementalDataRequirements,
    TaxProfileTaxRate,
    TaxProfileWithCredentials,
    UntestedTaxProfileRecord,
} from "@davo/types";

export async function markTaxProfileCredentialsAsFailing(
    accountId: string,
    taxProfileId: string,
    credsAreFailing: string,
    reason?: string
) {
    await put(`api/ops/tax-profiles/${taxProfileId}/failing`, { credsAreFailing, reason }, true);
}

export async function setTaxProfileTypeVerificationStatus(
    accountId: string,
    taxProfileId: string,
    isVerified: boolean
) {
    await put(`api/ops/tax-profiles/${taxProfileId}/verify-type`, { verified: isVerified }, true);
}

export async function setTaxProfileFilingNote(taxProfileId: string, filingNote?: string) {
    await put(`api/ops/tax-profiles/${taxProfileId}/filing-note`, { filingNote }, true);
}

export async function setCredentialsNote(taxProfileId: string, credentialsNote?: string | null) {
    await put(`api/ops/tax-profiles/${taxProfileId}/credentials-note`, { credentialsNote }, true);
}

export async function setTaxProfileExpandedFields(accountId: string, taxProfileId: string, hasExpanded: boolean) {
    return put(`api/ops/tax-profiles/${taxProfileId}/expanded-fields`, { expandedFields: hasExpanded }, true);
}

export async function setManualFiling(taxProfileId: string, isManualFiling: boolean, reason?: string) {
    await put(`api/ops/tax-profiles/${taxProfileId}/set-manual`, { manualFiling: isManualFiling, reason }, true);
}

export async function setGroupLogin(taxProfileId: string, credentialsType: CredentialsType) {
    await put(`api/ops/tax-profiles/${taxProfileId}/set-group-login`, { credentialsType }, true);
}

export async function updateRequireAdditionalData(
    taxProfileId: string,
    dataRequirements: SupplementalDataRequirements
) {
    await put(`api/ops/tax-profiles/${taxProfileId}/set-require-additional-data`, { ...dataRequirements }, true);
}

export async function setEarlyFilingRequired(taxProfileId: string, isEarlyFilingRequired: boolean) {
    await put(
        `api/ops/tax-profiles/${taxProfileId}/set-early-filing-required`,
        {
            earlyFilingRequired: isEarlyFilingRequired,
        },
        true
    );
}

export async function setManualReconcileRequired(taxProfileId: string, isManualReconcileRequired: boolean) {
    await put(
        `api/ops/tax-profiles/${taxProfileId}/set-manual-reconcile-required`,
        {
            manualReconcileRequired: isManualReconcileRequired,
        },
        true
    );
}

export async function setTaxRateType(taxProfileId: string, taxRateType: string) {
    await put(`api/ops/tax-profiles/${taxProfileId}/set-tax-rate-type`, { taxRateType }, true);
}

export async function getUntestedTaxProfiles(hasFiledBefore: boolean) {
    return get<UntestedTaxProfileRecord[]>(`api/ops/tax-profiles/untested?hasFiledBefore=${hasFiledBefore}`, true);
}

export async function getFailingTaxProfiles(): Promise<FailedTaxProfileRecord[]> {
    return getArray(`api/ops/tax-profiles/failing`, true, MetaFailedTaxProfileRecord);
}

export async function sendTaxProfileCredentialsFailureNotification(taxProfileId: string) {
    await post(`api/ops/tax-profiles/${taxProfileId}/send-failure-notification`, { taxProfileId }, true);
}

export async function getTaxProfileWithCredentials(taxProfileId: string): Promise<TaxProfileWithCredentials> {
    return getOne(`api/ops/tax-profiles/${taxProfileId}`, true, MetaTaxProfileWithCredentials);
}

export async function getTaxProfileTaxRates(taxProfileId: string): Promise<TaxProfileTaxRate[]> {
    return getArray(`api/ops/tax-profiles/${taxProfileId}/tax-rates`, true, MetaTaxProfileTaxRate);
}

export async function getFilingCredentialHistory(taxProfileId: string) {
    return get<IEncryptedStoreHistoryEntry[]>(`api/ops/tax-profiles/credentials/history/${taxProfileId}`, true);
}
