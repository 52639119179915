import {
    d30ToastError,
    getAccount,
    getHistoricalFilingsForAccount,
    getTaxProfiles,
    postGetFilingFilterOptions,
} from "@davo/portal-common";
import {
    AccountRecord,
    BusinessDay,
    FilingHistoricalWithAttachments,
    FilingWithAttachmentsAndHistory,
    hasMidMonthPayment,
    IFilingOptionsFilters,
    IFilingRow,
    PeriodFilterType,
    TaxProfile,
} from "@davo/types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useRef,
    useState,
} from "react";
import useAsyncEffect from "use-async-effect";
import { postGetAllFilingsForAccountWithFilter } from "../services";

export interface IOpsFilingsFilterResultsContext {
    filingFilterResults: IFilingRow[] | undefined;
    historicalFilingResults: IFilingRow[] | undefined;
    filters: IFilingOptionsFilters | undefined;
    periodEndOptions: PeriodFilterType[] | undefined;
    stateOptions: string[] | undefined;
    taxProfileOptions: TaxProfile[] | undefined;
    setFilters: Dispatch<SetStateAction<IFilingOptionsFilters | undefined>>;
    showHistorical: boolean;
    showHistoricalButton: boolean;
    setShowHistorical: Dispatch<SetStateAction<boolean>>;
    account: AccountRecord | undefined;
}

export const OpsFilingsFilterResultsDefaultValue: IOpsFilingsFilterResultsContext = {
    filingFilterResults: [],
    historicalFilingResults: [],
    filters: undefined,
    setFilters: () => undefined,
    showHistorical: false,
    showHistoricalButton: false,
    setShowHistorical: () => undefined,
    stateOptions: undefined,
    taxProfileOptions: undefined,
    periodEndOptions: undefined,
    account: undefined,
};

export const OpsFilingsFilterResultsContext = createContext(OpsFilingsFilterResultsDefaultValue);
export const useOpsFilingFilterResultsContext = () => useContext(OpsFilingsFilterResultsContext);

export interface IOpsFilingsFilterResultsContextProviderProps {
    accountId: string;
}

const sortedOptions = (options: PeriodFilterType[]) => {
    return options.sort((x: PeriodFilterType, y: PeriodFilterType) => {
        const endX = x.end.toString();
        const endY = y.end.toString();
        if (DateTime.fromISO(endX) > DateTime.fromISO(endY)) {
            return -1;
        } else if (DateTime.fromISO(endX) < DateTime.fromISO(endY)) {
            return 1;
        } else if (endX === endY) {
            if (x.label.length < y.label.length) {
                return -1;
            } else if (x.label.length > y.label.length) {
                return 1;
            }
        }
        return 0;
    });
};

export const OpsFilingsFilterResultsContextProvider: FunctionComponent<
    PropsWithChildren<IOpsFilingsFilterResultsContextProviderProps>
> = ({ children, accountId }) => {
    const [stateOptions, setStateOptions] = useState<string[] | undefined>([]);
    const [periodEndOptions, setPeriodEndOptions] = useState<PeriodFilterType[] | undefined>([]);
    const [filters, setFilters] = useState<IFilingOptionsFilters>();
    const [showHistorical, setShowHistorical] = useState<boolean>(false);
    const [showHistoricalButton, setShowHistoricalButton] = useState<boolean>(false);
    const [taxProfilesForFilter, setTaxProfilesForFilter] = useState<TaxProfile[]>([]);
    const [filingFilterResults, setFilingFilterResults] = useState<IFilingRow[] | undefined>(undefined);
    const [historicalFilingResults, setHistoricalFilingResults] = useState<IFilingRow[] | undefined>(undefined);
    const [accountForFilings, setAccountForFilings] = useState<AccountRecord>();
    const controllerRef = useRef<AbortController | null>();

    // Get Filter options
    useAsyncEffect(async () => {
        const accountForFilingFilterOptions: AccountRecord = await getAccount(accountId);
        setAccountForFilings(accountForFilingFilterOptions);
        const tpsForFilter: TaxProfile[] = await getTaxProfiles(accountId);
        setTaxProfilesForFilter(tpsForFilter);
        if (!isEmpty(tpsForFilter)) {
            const filterOptions = await postGetFilingFilterOptions(
                accountForFilingFilterOptions,
                tpsForFilter.map((tp) => tp.id)
            );
            const sortedPeriodEndOptions: PeriodFilterType[] = sortedOptions(filterOptions.periodFilter);
            setPeriodEndOptions(sortedPeriodEndOptions);
            setStateOptions(filterOptions.state);
            setFilters({
                state: undefined,
                taxProfile: undefined,
                periodFilter: sortedPeriodEndOptions[0],
            });
        }
    }, [accountId]);

    // Get Filings
    useAsyncEffect(async () => {
        if (isNil(accountId) || isNil(filters)) {
            return;
        }
        controllerRef.current = new AbortController();
        let freshFilings: any[] = [];
        const historicalFilingsPromise: Promise<FilingHistoricalWithAttachments[]> | undefined =
            getHistoricalFilingsForAccount(accountId, controllerRef.current).catch((e) => {
                d30ToastError("Problem getting historical filings for account.", e);
                return [];
            });
        freshFilings = await postGetAllFilingsForAccountWithFilter(accountId, filters, controllerRef.current).catch(
            (e) => {
                d30ToastError("Problem getting all filings.", e);
                return [];
            }
        );

        controllerRef.current = null;
        const addHistory = (isHistorical: boolean, filing: any): FilingWithAttachmentsAndHistory => {
            return { ...filing, historical: isHistorical };
        };

        let historicalFilings: FilingHistoricalWithAttachments[] = [];

        if (historicalFilingsPromise) {
            historicalFilings = await historicalFilingsPromise;
            if (!isEmpty(historicalFilings)) {
                setShowHistoricalButton(true);
            }
        }

        const historicalFilingsWithHistory = [...historicalFilings.map((x) => addHistory(true, x))];
        freshFilings = [...freshFilings.map((x) => addHistory(false, x))];

        const today = BusinessDay.today("America/New_York");
        const createFilingRow = (filings: any[]): any[] => {
            return filings.map((row) => {
                let status = "ready";
                if (row.historical) {
                    status = "filed";
                } else if (row.amendmentStatus === "completed") {
                    status = "amended";
                } else if (row.amendmentStatus && row.amendmentStatus !== "completed") {
                    status = "to amend";
                } else if (row.filedDate) {
                    status = row.status === "wontfile" ? "won't file" : row.status;
                } else if (today <= row.periodEnd) {
                    status = "future";
                } else if (row.hasUnsettled && !hasMidMonthPayment(row.frequency)) {
                    status = "unsettled";
                } else if ((today <= row.filingDueDate && today.day < 5) || !isNil(row.bulkTaskId)) {
                    status = "pending";
                } else if (row.reviewed && row.reviewNote) {
                    status = "reviewed";
                } else if (row.recentlyUpdatedCredentials) {
                    status = "updated";
                } else if (today > row.filingDueDate) {
                    status = "late";
                }
                if (!row.historical) {
                    return {
                        id: row.id,
                        status,
                        accountName: row.taxProfile.account.name,
                        accountId: row.taxProfile.account.id,
                        profileName: row.taxProfile.name,
                        legalName: row.taxProfile.legalName,
                        periodStart: row.periodStart,
                        periodEnd: row.periodEnd,
                        filingDueDate: row.filingDueDate,
                        filedDate: row.filedDate,
                        hasUnsettled: row.hasUnsettled,
                        details: row,
                        historical: false,
                        reviewed: row.reviewed,
                        reviewNote: row.reviewNote,
                    };
                } else {
                    if (accountId) {
                        return {
                            id: row.id,
                            accountName: "Historical",
                            accountId,
                            profileName: "Historical",
                            legalName: "Filed",
                            periodStart: row.periodStart,
                            periodEnd: row.periodEnd,
                            filingDueDate: row.periodEnd,
                            filedDate: row.filedDate,
                            details: row,
                            reviewed: row.reviewed,
                            reviewNote: row.reviewNote,
                            jurisdiction: row.jurisdiction,
                        };
                    } else {
                        throw new Error("Account ID not specified!");
                    }
                }
            });
        };

        setFilingFilterResults(createFilingRow(freshFilings));
        setHistoricalFilingResults(createFilingRow(historicalFilingsWithHistory));
    }, [accountId, filters]);

    return (
        <OpsFilingsFilterResultsContext.Provider
            value={{
                filingFilterResults: filingFilterResults,
                historicalFilingResults: historicalFilingResults,
                filters: filters,
                setFilters: setFilters,
                showHistoricalButton: showHistoricalButton,
                showHistorical: showHistorical,
                setShowHistorical: setShowHistorical,
                periodEndOptions: periodEndOptions,
                taxProfileOptions: taxProfilesForFilter,
                stateOptions: stateOptions,
                account: accountForFilings,
            }}>
            {children}
        </OpsFilingsFilterResultsContext.Provider>
    );
};
