import { d30Toast, d30ToastError, PaperComponent, Select, TextField } from "@davo/portal-common";
import { DisconnectReasonCodes, DiscountReasonKeys, DiscountReasons } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { updateLocationDisconnectCode } from "./services";

interface IEditDisconnectReasonButtonType {
    disconnectCode: DisconnectReasonCodes;
    disconnectNote: string;
    locationId: string;
    onClose: () => void;
}

export function EditDisconnectReasonButton({
    disconnectNote,
    disconnectCode,
    locationId,
    onClose,
}: IEditDisconnectReasonButtonType) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [disconnectReasonCode, setDisconnectReasonCode] = useState<DisconnectReasonCodes | undefined>(disconnectCode);
    const [disconnectReasonNote, setDisconnectReasonNote] = useState<string | undefined>(disconnectNote);

    const show = () => {
        setIsShowing(true);
    };

    const cancel = () => {
        setIsShowing(false);
        setDisconnectReasonCode(disconnectCode);
        setDisconnectReasonNote(disconnectNote);
    };

    const doSubmit = () => {
        if (!disconnectReasonCode) {
            d30ToastError(`Error: Disconnect Reason required`);
            return;
        }
        updateLocationDisconnectCode(locationId, disconnectReasonCode, disconnectReasonNote)
            .then(() => {
                onClose();
                d30Toast(`Reason set`);
            })
            .catch(() => d30ToastError(`Error setting reason`))
            .finally(cancel);
    };

    return (
        <div style={{ marginTop: "2px", marginRight: "2px" }}>
            <Button variant="outlined" onClick={show} size="small" color="primary" style={{ marginBottom: "3px" }}>
                {DiscountReasons[disconnectCode] ?? "ADD REASON"}
            </Button>

            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={cancel}>
                    <DialogTitle id="draggable-dialog-title">Edit disconnect reason</DialogTitle>

                    <DialogContent style={{ padding: "0px 24px" }}>
                        <Select<DisconnectReasonCodes>
                            title="Reason"
                            value={disconnectReasonCode}
                            onChange={setDisconnectReasonCode}
                            options={DiscountReasonKeys}
                            label={(code) => DiscountReasons[code]}
                        />
                    </DialogContent>
                    {disconnectReasonCode && disconnectReasonCode === "OTHER" && (
                        <DialogContent style={{ padding: "0px 24px" }}>
                            <TextField
                                label={"Reason for disconnecting"}
                                value={disconnectReasonNote ?? ""}
                                onChange={setDisconnectReasonNote}
                                isMultiline={true}
                                rows={2}
                            />
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                !disconnectReasonCode || (disconnectReasonCode === "OTHER" && !disconnectReasonNote)
                            }
                            onClick={doSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
