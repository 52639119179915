import { ReactTable } from "@davo/portal-common";
import { ItemizedDailyDetail, money, toDisplayDateTimeString } from "@davo/types";
import React from "react";
import { CellProps } from "react-table";

interface IItemizedDailyActivityTable {
    itemizedBreakout: ItemizedDailyDetail[];
}

export const ItemizedDailyActivityTable: React.FunctionComponent<IItemizedDailyActivityTable> = ({
    itemizedBreakout,
}) => {
    const itemizedDailyActivityColumnData = [
        {
            Header: "type",
            accessor: "activityType",
        },
        {
            Header: "Order ID",
            accessor: "posActivityGroupId",
        },
        {
            Header: "Payment ID",
            accessor: "posActivityId",
        },
        {
            Header: "Total Sales",
            accessor: "totalSales",
            Cell: ({ value }: CellProps<{}, any>) => <>{money(value / 100)}</>,
        },
        {
            Header: "Taxable Sales",
            accessor: "taxableSales",
            Cell: ({ value }: CellProps<{}, any>) => <>{money(value / 100)}</>,
        },
        {
            Header: "Tax",
            accessor: "tax",
            Cell: ({ value }: CellProps<{}, any>) => <>{money(value / 100)}</>,
        },
        {
            Header: "Tax Name",
            accessor: "taxName",
        },
        {
            Header: "Tax Rate",
            accessor: "taxRate",
            Cell: ({ value }: CellProps<{}, any>) => <>{money(value / 1e5)}</>,
        },
        {
            Header: "Facilitator Remitted",
            accessor: "facilitatorRemitted",
            Cell: (data: any) => {
                return <div style={{ textAlign: "center" }}>{data.value ? "Y" : "N"}</div>;
            },
        },
        {
            Header: "POS Timestamp",
            accessor: "posTimestamp",
            Cell: ({ value }: CellProps<{}, any>) => {
                return <>{toDisplayDateTimeString(value)}</>;
            },
        },
        {
            Header: "Normalized",
            accessor: "normalized",
        },
    ];

    return (
        <ReactTable
            columns={itemizedDailyActivityColumnData}
            data={itemizedBreakout}
            options={{
                pageSize: itemizedBreakout.length,
                hideToolbar: true,
            }}
        />
    );
};
