import { d30ToastError, LinkButton, Loading, ReactTable, sortDate } from "@davo/portal-common";
import { ACHTransactionSimple, toDisplayDateString, toDollars } from "@davo/types";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAchPendingTransactions } from "./services";
import { ToggleACHButton } from "./ToggleACHButton";

const dateFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>}</>;
};

const fieldFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{data.value}</span>}</>;
};

const createdByFormat = (data: any) => {
    return (
        <>
            {data.value && (
                <span data-testid={"createdBy"} style={{ whiteSpace: "nowrap" }}>
                    {!isNil(data.value) ? `${data.value}` : "None"}
                </span>
            )}
        </>
    );
};

const amountFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{`$${toDollars(data.value)}`}</span>}</>;
};

export function LiabilityControlCenterPane() {
    const [transactions, setTransactions] = useState<ACHTransactionSimple[]>();

    const refresh = useCallback(() => {
        setTransactions(undefined);
        getAchPendingTransactions()
            .then((results) => setTransactions(results))
            .catch((e) => d30ToastError("Problem getting pending ACH transactions", e));
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Amount",
                accessor: "amount",
                Cell: (data: any) => {
                    const row: ACHTransactionSimple = data.cell.row.original as ACHTransactionSimple;
                    return (
                        <>
                            {amountFormat(data)}
                            {row.status === "hold" && (
                                <Tooltip title={row.statusNotes}>
                                    <span data-testid="holdNote">
                                        {
                                            <InfoIcon
                                                fontSize="small"
                                                style={{ verticalAlign: "middle", paddingLeft: "5px" }}
                                            />
                                        }
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                Header: "Direction",
                accessor: "direction",
                Cell: fieldFormat,
            },
            {
                Header: "Type",
                accessor: "type",
                Cell: fieldFormat,
            },
            {
                Header: "Location",
                accessor: "firstName",
                Cell: (data: any) => (
                    <LinkButton
                        url={`/accounts/${data.cell.row.original.accountId}`}
                        label={data.cell.row.original.firstName}
                        labelMaxChars={35}
                    />
                ),
            },
            {
                Header: "Created",
                accessor: "created",
                Cell: dateFormat,
                sortType: sortDate("created"),
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                Cell: createdByFormat,
            },
            {
                Header: "Partner",
                accessor: "partnerName",
                Cell: (data: any) => <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{data.value}</span>}</>,
            },
            {
                Header: "Status Updated By",
                accessor: "statusUpdatedBy",
                Cell: createdByFormat,
            },
            {
                Header: "",
                accessor: "id",
                Cell: (data: any) => (
                    <div style={{ marginTop: "2px", marginRight: "2px", marginBottom: "2px", whiteSpace: "nowrap" }}>
                        <ToggleACHButton ach={data.cell.row.original} onClose={refresh}></ToggleACHButton>
                    </div>
                ),
            },
        ];
    }, [refresh]);

    if (!transactions) {
        return <Loading />;
    }
    return (
        <div data-testid="liabilityControlCenterTable">
            <ReactTable columns={columns} data={transactions} refreshData={refresh} />
        </div>
    );
}
