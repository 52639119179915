import {
    ACHStatusLozenge,
    d30ToastError,
    Loading,
    PaperComponent,
    ReactTable,
    sortAmount,
    sortDate,
    useModalEditor,
} from "@davo/portal-common";
import { ManualSetAsideAndACH, money, moneyFromCents, toDisplayDateTimeString } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getManualTransactionsForLocation } from "./services";
import { VoidManualTransactionButton } from "./VoidManualTransactionButton";

const getTooltip = (data: any) => {
    if (isEmpty(data.statusNotes)) {
        return "";
    }

    return (
        <div key={data.achTransactionId}>
            ${moneyFromCents(data.amount)} - {data.statusNotes}
        </div>
    );
};

const renderLozengeWithTooltip = (data: any) => {
    return <Tooltip title={getTooltip(data)}>{ACHStatusLozenge(data.status)}</Tooltip>;
};

export function LocationManualTransactionsModal({ locationId }: { locationId: string }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [manualTransactions, setManualTransactions] = useState<ManualSetAsideAndACH[]>();

    const shutdown = () => {
        closeDialog();
    };

    const refresh = useCallback(() => {
        getManualTransactionsForLocation(locationId)
            .then((returns) => setManualTransactions(returns))
            .catch(() => d30ToastError("Problem getting manual transactions for location"));
    }, [locationId]);

    useEffect(() => {
        if (isDialogOpen) {
            refresh();
        }
    }, [isDialogOpen, refresh]);

    const columns = useMemo(() => {
        const renderVoidManualTransactionButton = (data: any) => {
            return <VoidManualTransactionButton manualSetAside={data.cell.row.original} onClose={refresh} />;
        };

        return [
            {
                Header: "Created Date",
                accessor: "created",
                Cell: (data: any) => <>{toDisplayDateTimeString(data.value)}</>,
            },
            {
                Header: "Period Date",
                accessor: "periodDay",
                Cell: (data: any) => {
                    return <>{DateTime.fromSQL(data.value).toFormat("LLL dd yyyy")}</>;
                },
                sortType: sortDate("periodDay"),
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: (data: any) => <>{money(data.value / 100)}</>,
                sortType: sortAmount("amount"),
            },
            {
                Header: "Status",
                accessor: "status",
                disableSortBy: true,
                Cell: (data: any) => renderLozengeWithTooltip(data.cell.row.original),
            },
            {
                Header: "Internal Note",
                accessor: "note",
            },
            {
                Header: "Merchant Note",
                accessor: "merchantNote",
            },
            {
                Header: "Created By",
                accessor: "createdBy",
            },
            {
                Header: "",
                accessor: "id",
                Cell: renderVoidManualTransactionButton,
            },
        ];
    }, [refresh]);

    return (
        <div>
            <Button
                size="small"
                variant="outlined"
                color="primary"
                style={{ marginTop: "2px", marginBottom: "2px" }}
                onClick={() => {
                    showDialog(true);
                }}>
                Manual Transaction History
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => shutdown()}>
                    <DialogTitle id="draggable-dialog-title">Manual Transaction History</DialogTitle>
                    <DialogContent>
                        <div>
                            {manualTransactions && manualTransactions.length > 0 && (
                                <ReactTable
                                    columns={columns}
                                    data={manualTransactions}
                                    refreshData={refresh}
                                    options={{
                                        pageSize: 10,
                                    }}
                                />
                            )}
                            {manualTransactions && manualTransactions.length < 1 && (
                                <div>No Manual Transactions Found</div>
                            )}
                            {!manualTransactions && <Loading />}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => shutdown()} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
