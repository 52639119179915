import { getJurisdictionFilingType } from "@davo/portal-common";
import { IJurisdictionFilingType } from "@davo/types";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";

export function TaxProfileFilingTypeRender({ id }: { id: string }) {
    const [filingType, setFilingType] = useState<IJurisdictionFilingType | undefined>(undefined);

    const getTruncatedName = () => {
        return filingType ? filingType.name : "";
    };

    useAsyncEffect(async () => {
        setFilingType(await getJurisdictionFilingType(id));
    }, [id]);

    if (!filingType) {
        return null;
    }

    return (
        <Typography>
            Filing type:{" "}
            {getTruncatedName().length > 20 ? `${getTruncatedName().substring(0, 20)}...` : getTruncatedName()}
        </Typography>
    );
}
