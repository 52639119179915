import {
    d30ToastError,
    Loading,
    PaperComponent,
    ReactTable8,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import { IFilingRow, User } from "@davo/types";
import { Button, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { AssignStaffModal } from "../AssignStaffModal";
import { useOpsFilingFilterResultsContext } from "../context";
import { AutofilerButtonsMulti } from "../filing/AutofilerButtonsMulti";
import { RenderFileInfo } from "../filing/RenderFileInfo";
import { RenderFilingLink } from "../filing/RenderFilingLink";
import { RenderFilingPeriodDueDate } from "../filing/RenderFilingPeriodDueDate";
import { RenderFilingPeriodEnd } from "../filing/RenderFilingPeriodEnd";
import { RenderFilingPeriodStart } from "../filing/RenderFilingPeriodStart";
import { RenderProfile } from "../filing/RenderProfile";
import { RenderReviewedIcon } from "../filing/RenderReviewedIcon";
import { RenderStatus } from "../filing/RenderStatus";
import { RenderUserName } from "../filing/RenderUserName";
import { HistoricalFilingDetailModal } from "../HistoricalFilingDetailModal";
import { updateFilingAssignee } from "../services";

export function OpsAccountFilingsTable() {
    const theme = useTheme();
    const canHideColumn = useMediaQuery(theme.breakpoints.down("sm"));
    const loginContext = useLoginContext();
    const { filingFilterResults, showHistorical, historicalFilingResults, setShowHistorical } =
        useOpsFilingFilterResultsContext();
    const [selectedFilingIds, setSelectedFilingIds] = useState<string[]>();
    const toggleAllRowsSelectedHandlerRef = useRef<(value: boolean | undefined) => void>();
    const [showFilingAssigneeModal, filingAssigneeModalProps] = useModalEditor<string>();
    const getHiddenColumns = useCallback(() => {
        if (!canHideColumn) {
            return ["profileName", "details.taxProfile.state"];
        }
        return ["profileName", "details.taxProfile.state", "periodEnd", "periodStart"];
    }, [canHideColumn]);
    const [showHistoricalFilingModal, historicalFilingModalProps] = useModalEditor<{
        filingId: string;
        accountId: string;
    }>();

    const historicalColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IFilingRow & { jurisdiction: string }>();

        return [
            columnHelper.accessor("legalName", {
                header: "Legal Name",
                cell: (data) => (
                    <div data-testid={"legalName"}>
                        <RenderProfile ua={data.row.original} showAccountButton={false} />
                    </div>
                ),
            }),
            columnHelper.accessor("periodStart", {
                header: "Start",
                cell: (data) => (
                    <div data-testid={"startPeriod"}>
                        <RenderFilingPeriodStart date={data.getValue()} />
                    </div>
                ),
            }),
            columnHelper.accessor("periodEnd", {
                header: "End",
                cell: (data) => (
                    <div data-testid={"endPeriod"}>
                        <RenderFilingPeriodEnd date={data.getValue()} />
                    </div>
                ),
            }),
            columnHelper.accessor("filedDate", {
                header: "",
                enableSorting: false,
                cell: (data) => <RenderFileInfo row={data.row.original} historicalFiling={showHistoricalFilingModal} />,
            }),
            columnHelper.accessor("id", {
                header: "",
                enableSorting: false,
                cell: (data) => <RenderFilingLink row={data.row.original} />,
            }),
            columnHelper.accessor("profileName", {}),
            columnHelper.accessor("jurisdiction", {
                header: "State",
            }),
        ] as ColumnDef<IFilingRow, any>[];
    }, [loginContext.user, theme]);

    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<IFilingRow>();

        const assignRow = async (ua: IFilingRow) => {
            if (loginContext.user) {
                await updateFilingAssignee(ua.id, loginContext.user.id);
            }
        };

        return [
            columnHelper.accessor("status", {
                header: "Status",
                cell: (data) => <RenderStatus row={data.cell.row.original} theme={theme} />,
            }),
            columnHelper.accessor("legalName", {
                header: "Legal Name",
                cell: (data) => (
                    <div data-testid={"legalName"}>
                        <RenderProfile ua={data.row.original} showAccountButton={false} />
                    </div>
                ),
            }),
            columnHelper.accessor("periodStart", {
                header: "Start",
                cell: (data) => (
                    <div data-testid={"startPeriod"}>
                        <RenderFilingPeriodStart date={data.getValue()} />
                    </div>
                ),
            }),
            columnHelper.accessor("periodEnd", {
                header: "End",
                cell: (data) => (
                    <div data-testid={"endPeriod"}>
                        <RenderFilingPeriodEnd date={data.getValue()} />
                    </div>
                ),
            }),
            columnHelper.accessor("filingDueDate", {
                header: "Due",
                cell: (data) => (
                    <div data-testid={"filingDueDate"}>
                        <RenderFilingPeriodDueDate date={data.getValue()} />
                    </div>
                ),
            }),
            columnHelper.accessor("details.assignedUser", {
                header: "Assignee",
                cell: (data) => (
                    <div data-testid={"assignee"}>
                        <RenderUserName ua={data.row.original} assignRow={assignRow} />
                    </div>
                ),
            }),
            columnHelper.accessor("filedDate", {
                header: "",
                enableSorting: false,
                cell: (data) => <RenderFileInfo row={data.row.original} historicalFiling={showHistoricalFilingModal} />,
            }),
            columnHelper.accessor("id", {
                header: "",
                enableSorting: false,
                cell: (data) => <RenderFilingLink row={data.row.original} />,
            }),
            columnHelper.accessor("profileName", {}),
            columnHelper.accessor("details.taxProfile.state", {
                header: "State",
            }),
            columnHelper.accessor("reviewed", {
                header: "Reviewed",
                cell: (data) => <RenderReviewedIcon ua={data.row.original} />,
            }),
        ] as ColumnDef<IFilingRow, any>[];
    }, [loginContext.user, theme]);
    const assignFilingAssignee = (passThroughIds: string[] | undefined, selected: User | undefined) => {
        if (passThroughIds) {
            for (const r of passThroughIds) {
                updateFilingAssignee(r, (selected && selected.id) ?? undefined).catch((e) =>
                    d30ToastError("Problem updating filing assignee.", e)
                );
            }
        }
    };
    return (
        <div>
            <div style={{ display: "flex", marginBottom: "20px" }}>
                {selectedFilingIds && selectedFilingIds.length > 0 && (
                    <div style={{ marginTop: "4px", marginBottom: "8px" }}>
                        {selectedFilingIds.some((i) => {
                            const details = filingFilterResults?.filter((f) => f.id === i)[0].details;
                            return details && !details.assignedUserId && details.status === "open";
                        }) && (
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                style={{ marginRight: "5px" }}
                                disabled={selectedFilingIds.length === 0}
                                onClick={() => showFilingAssigneeModal()}>
                                Assign
                            </Button>
                        )}
                        {filingFilterResults && (
                            <AutofilerButtonsMulti
                                filings={selectedFilingIds.map(
                                    (id: string) => filingFilterResults.filter((t) => t.id === id)[0].details
                                )}
                                refreshFilings={async () => {
                                    setSelectedFilingIds([]);
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <div>
                {!filingFilterResults && <Loading />}
                {filingFilterResults && filingFilterResults.length > 0 && (
                    <>
                        <ReactTable8<IFilingRow>
                            columns={columns}
                            data={filingFilterResults}
                            options={{
                                hiddenColumns: getHiddenColumns(),
                                showMultiSelection: {
                                    onSelectionChange: (data) => {
                                        setSelectedFilingIds(data.map((d) => d.id));
                                    },
                                    toggleAllRowsSelectedFn: (fn: (value?: boolean) => void) => {
                                        toggleAllRowsSelectedHandlerRef.current = fn;
                                    },
                                    showSelectedCount: false,
                                },
                            }}
                        />
                        {filingAssigneeModalProps.isDialogOpen && (
                            <AssignStaffModal
                                shouldFilterByFilers={true}
                                passThroughIds={selectedFilingIds}
                                {...filingAssigneeModalProps}
                                assignStaffUser={assignFilingAssignee}
                            />
                        )}
                    </>
                )}
            </div>
            {showHistorical && !isEmpty(historicalFilingResults) && (
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    data-testid={"historicalFilingsModal"}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    fullScreen={false}
                    open={true}
                    onClose={() => {
                        setShowHistorical(!showHistorical);
                    }}>
                    <DialogTitle>
                        <span data-testid={"historicalFilingsModalDialogTitle"} style={{ alignSelf: "center" }}>
                            Historical Filings
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <>
                            <ReactTable8<IFilingRow>
                                columns={historicalColumns}
                                data={historicalFilingResults}
                                options={{
                                    hiddenColumns: getHiddenColumns(),
                                }}
                            />
                        </>
                    </DialogContent>
                </Dialog>
            )}
            {historicalFilingModalProps.isDialogOpen && <HistoricalFilingDetailModal {...historicalFilingModalProps} />}
        </div>
    );
}
