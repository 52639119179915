import { LocationRecord, LocationToTaxProfile } from "@davo/types";
import { Typography } from "@mui/material";
import startCase from "lodash/startCase";
import React from "react";

export function TaxBreakoutLocationDisplay({
    location,
    taxProfileId,
    locationToTaxProfileMapping,
}: {
    location: LocationRecord;
    taxProfileId: string;
    locationToTaxProfileMapping: LocationToTaxProfile[] | undefined;
}) {
    if (!locationToTaxProfileMapping) {
        return null;
    }
    return (
        <>
            {location.address1 && (
                <Typography>{`Address: ${location.address1}, ${location.city} ${location.zip} ${
                    location.county ? `(${startCase(location.county.toLowerCase())})` : ""
                }`}</Typography>
            )}
            <Typography>
                State Location Id:{" "}
                {
                    locationToTaxProfileMapping?.find(
                        (l) => l.accountLocationId === location.id && l.accountTaxProfileId === taxProfileId
                    )?.externalStateLocationId
                }
            </Typography>
        </>
    );
}
