import { d30Toast, NumberField, PaperComponent, TextField, useModalEditor } from "@davo/portal-common";
import { toDollars, toPennies } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { addCustomBillingEvent } from "./services";

export function CustomBillingModal({ locationId }: { locationId: string }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [text, setText] = useState<string | undefined>(undefined);
    const [billingAmount, setBillingAmount] = useState<number | undefined>(undefined);
    const [shouldEnableButton, setShouldEnableButton] = useState<boolean>(false);

    const validateAmount = (amt: number | undefined) => {
        if (amt && amt < 0) {
            return `Cannot record negative amounts`;
        }
        return undefined;
    };

    const validateText = (s: string | undefined) => {
        if (s && s.length < 2) {
            return `Must add a reason `;
        }
        return undefined;
    };

    const doSend = async () => {
        if (billingAmount && text) {
            try {
                setIsBusy(true);
                await addCustomBillingEvent(locationId, billingAmount, text, "MANUAL");
                setText("");
                setBillingAmount(undefined);
                d30Toast(
                    `Success! The merchant will see a charge of $${toDollars(
                        billingAmount
                    )} on their next monthly invoice.`
                );
                closeDialog();
            } finally {
                setIsBusy(false);
            }
        }
    };

    useEffect(() => {
        if (!shouldEnableButton && text && billingAmount) {
            setShouldEnableButton(true);
        }
    }, [text, billingAmount, shouldEnableButton]);

    return (
        <div>
            <Button variant="outlined" onClick={showDialog} size="small" color="primary" style={{ marginTop: "3px" }}>
                Custom Billing Event
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="draggable-dialog-title">Custom Billing Event</DialogTitle>
                    <DialogContent>
                        <Typography variant={"body1"}>
                            <TextField
                                label={"Billing Reason"}
                                value={text ?? ""}
                                onChange={setText}
                                validate={validateText}
                            />
                            <NumberField
                                label="Amount"
                                value={toDollars(billingAmount)}
                                onChange={(x) => setBillingAmount(toPennies(x))}
                                validate={validateAmount}
                            />
                        </Typography>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" disabled={isBusy} onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !shouldEnableButton}
                            onClick={doSend}>
                            Bill
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
