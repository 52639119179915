import { getJurisdictions, LinkButton, Loading, ReactTable, Select } from "@davo/portal-common";
import { AccountWithUnverifiedTaxRates, IJurisdiction, toDisplayDateString } from "@davo/types";
import OpenInBrowserTwoToneIcon from "@mui/icons-material/OpenInBrowserTwoTone";
import { FormControl, IconButton, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getAccountsWithUnverifiedTaxRates, getUnverifiedTaxRateFilingMonths } from "./services";

const anySession = "Any Session";

const _renderAccountName = (data: AccountWithUnverifiedTaxRates) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", flexWrap: "wrap" }}>
            <LinkButton
                url={`/accounts/${data.accountId}`}
                label={`${data.accountName}`}
                labelMaxChars={35}
                style={{
                    margin: "2px 2px",
                    verticalAlign: "top",
                    textAlign: "left",
                }}
                align={"left"}
                rel="noreferrer"
                target="_blank"
            />
        </div>
    );
};

const _renderLegalName = (data: AccountWithUnverifiedTaxRates) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", flexWrap: "wrap" }}>
            {data.profileName} ~ {data.legalName}
        </div>
    );
};

const _renderFilingDate = (data: string) => {
    return <div style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data)}</div>;
};

const _renderState = (data: string) => {
    return <div style={{ whiteSpace: "nowrap" }}>{data}</div>;
};

const _renderFilingLink = (data: AccountWithUnverifiedTaxRates) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
                title={`View filing`}
                aria-label={`View filing ${data.filingId}`}
                onClick={() => {
                    window.open(`/filings/${data.filingId}`, "_blank");
                }}>
                <OpenInBrowserTwoToneIcon color="primary" />
            </IconButton>
        </div>
    );
};

const _monthYearLabel = (period: string) => {
    const month = DateTime.fromFormat(period, "yyyy-MM");
    return `${month.monthLong} ${month.year}`;
};

export function TaxProfileRatesUnverifiedPane() {
    const [filingMonths, setFilingMonths] = useState<string[]>([]);
    const [jurisdictions, setJurisdictions] = useState<IJurisdiction[]>([]);
    const [filingSession, setFilingSession] = useState<string | undefined>();
    const [state, setState] = useState<IJurisdiction | undefined>();
    const [accountsWithUnverified, setAccountsWithUnverified] = useState<AccountWithUnverifiedTaxRates[] | undefined>();
    const [showNoRecordsFound, setShowNoRecordsFound] = useState<boolean>(false);
    const controllerRef = useRef<AbortController | undefined>();

    const _refreshAccounts = useCallback(async () => {
        setShowNoRecordsFound(false);
        setAccountsWithUnverified(undefined);

        if (controllerRef?.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();

        if (filingSession || state) {
            const period = filingSession === anySession ? undefined : filingSession;
            const accounts = await getAccountsWithUnverifiedTaxRates(
                period,
                state?.abbreviatedName,
                controllerRef.current
            );

            setAccountsWithUnverified(accounts);
            setShowNoRecordsFound(isEmpty(accounts));
        }
    }, [filingSession, state]);

    useAsyncEffect(async () => {
        const filingSessionMonths = await getUnverifiedTaxRateFilingMonths(undefined);
        if (!isEmpty(filingSessionMonths)) {
            setFilingMonths([anySession, ...filingSessionMonths]);
        }
        setShowNoRecordsFound(isEmpty(filingSessionMonths));
        setJurisdictions(await getJurisdictions());
    }, []);

    useAsyncEffect(async () => {
        await _refreshAccounts();
    }, [_refreshAccounts]);

    const columns = useMemo(() => {
        return [
            {
                Header: "State",
                accessor: "jurisdiction",
                Cell: (data: any) => {
                    return _renderState(data.value);
                },
            },
            {
                Header: "Account",
                id: "accountName",
                accessor: "accountName" as const,
                Cell: (data: any) => {
                    return _renderAccountName(data.cell.row.original);
                },
            },
            {
                Header: "Legal Name",
                id: "profileName",
                accessor: "profileName" as const,
                Cell: (data: any) => {
                    return _renderLegalName(data.cell.row.original);
                },
            },
            {
                Header: "Start",
                accessor: "periodStart",
                Cell: (data: any) => {
                    return _renderFilingDate(data.value);
                },
            },
            {
                Header: "End",
                accessor: "periodEnd",
                Cell: (data: any) => {
                    return _renderFilingDate(data.value);
                },
            },
            {
                Header: "Due",
                accessor: "filingDueDate",
                Cell: (data: any) => {
                    return _renderFilingDate(data.value);
                },
            },
            {
                Header: "",
                accessor: "filing",
                disableSortBy: true,
                Cell: (data: any) => {
                    return _renderFilingLink(data.cell.row.original);
                },
            },
        ];
    }, []);

    if (isEmpty(filingMonths) && !showNoRecordsFound) {
        return <Loading />;
    }

    return (
        <div>
            <Typography
                style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "20px" }}
                data-testid={"unverifiedTaxRatesHeader"}>
                Unverified Tax Rates
            </Typography>

            <FormControl data-testid={"filingSessionSelector"} style={{ float: "left", marginRight: "5px" }}>
                <Select<string>
                    title="Filing Session"
                    value={filingSession}
                    onChange={setFilingSession}
                    options={filingMonths}
                    label={(val) => (val === anySession ? anySession : _monthYearLabel(val))}
                    showFullWidth={true}
                    noneLabel={"Select One"}
                />
            </FormControl>

            <FormControl data-testid={"stateSelector"}>
                <Select<IJurisdiction>
                    title="State"
                    options={jurisdictions}
                    value={state}
                    onChange={setState}
                    label={(s) => s?.fullName}
                    showFullWidth={true}
                    noneLabel={"Any State"}
                />
            </FormControl>

            {showNoRecordsFound && (
                <div data-testid={"noFilingsMatchSearchCriteriaCopy"}>
                    <Typography style={{ marginBottom: "20px" }} variant="body1">
                        No Filings Matching Search Criteria
                    </Typography>
                </div>
            )}

            {(filingSession || state) && isNil(accountsWithUnverified) && <Loading />}

            {!isEmpty(accountsWithUnverified) && (
                <div data-testid={"unverifiedTaxRatesTable"}>
                    <ReactTable
                        columns={columns}
                        data={accountsWithUnverified ?? []}
                        refreshData={async () => {
                            await _refreshAccounts();
                        }}
                        options={{
                            pageSize: 10,
                            headerRowStyle: { verticalAlign: "top" },
                        }}
                    />
                </div>
            )}
        </div>
    );
}
