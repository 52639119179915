import { Loading, Lozenge, ReactTable, Select } from "@davo/portal-common";
import {
    DailyDetail,
    InternalSummary,
    Location,
    OptionalString,
    SettlementAchInfo,
    moneyFromCents,
    toDisplayDateTimeString,
} from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Tooltip } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { CellProps, ColumnExtended } from "react-table";
import useAsyncEffect from "use-async-effect";
import { ItemizedDailyActivity } from "./ItemizedDailyActivity";
import { getDailyDetailForLocation, getFilingSummary, getLocationsForFiling } from "./services";
import { getLocationLabel } from "./util";

export interface IDailyActivityType {
    filterLocations?: Location[];
    locationInfo?: { locationId: string; accountId?: string; selectedMonth: string };
    filingId?: string;
}

export function DailyActivity({ filterLocations, locationInfo, filingId }: IDailyActivityType) {
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [activity, setActivity] = useState<InternalSummary>();
    const [locationsForFiling, setLocationsForFiling] = useState<Location[]>();
    const [showItemizedDailyActivity, setShowItemizedDailyActivity] = useState<boolean>(false);
    const [identifierItemizedDailyActivity, setIdentifierItemizedDailyActivity] = useState<string>("");
    const [bizdayItemizedDailyActivity, setBizdayItemizedDailyActivity] = useState<string>("");
    const [accountIdItemizedDailyActivity, setAccountIdItemizedDailyActivity] = useState<OptionalString>(undefined);

    useAsyncEffect(async () => {
        if (filingId) {
            const info: { summary: InternalSummary; dailyDetails: DailyDetail[] } = await getFilingSummary(
                filingId,
                selectedLocation?.id
            );
            setLocationsForFiling(await getLocationsForFiling(filingId));
            setActivity(info.summary);
        } else if (locationInfo) {
            const info: { summary: InternalSummary; dailyDetails: DailyDetail[] } = await getDailyDetailForLocation(
                locationInfo.locationId,
                locationInfo.selectedMonth,
                locationInfo.accountId,
                selectedLocation?.id
            );
            setActivity(info.summary);
        } else {
            throw new Error("At least one of locationId and filingId are required.");
        }
    }, [selectedLocation, locationInfo]);

    const getPageSize = () => {
        if (activity && activity.daily) {
            return Math.max(+activity.daily.length + 1, 32);
        }
        return 32;
    };

    const getSettlementTooltip = (info: SettlementAchInfo[]) => {
        if (isEmpty(info)) {
            return null;
        }
        return (
            <>
                {info.map((i) => {
                    return (
                        <div key={i.achTransactionId}>
                            ${moneyFromCents(i.amount)} - {i.statusNotes}
                            <ul style={{ paddingLeft: "10px", margin: 0 }}>
                                <li>Created @ {toDisplayDateTimeString(i.created)}</li>
                                {i.statusUpdated && <li>Updated @ {toDisplayDateTimeString(i.statusUpdated)}</li>}
                            </ul>
                        </div>
                    );
                })}
            </>
        );
    };

    const getLocationName = (locationId: string) => {
        if (!!filingId && !!locationsForFiling) {
            return locationsForFiling.find((location: { id: string }) => {
                return location.id === locationId;
            })?.name;
        } else {
            return "";
        }
    };

    const handleDateClick = (value: string) => {
        setAccountIdItemizedDailyActivity(
            locationInfo ? locationInfo.accountId : selectedLocation ? selectedLocation.accountId : undefined
        );
        setBizdayItemizedDailyActivity(DateTime.fromSQL(value).toFormat("yyyy-MM-dd"));
        setIdentifierItemizedDailyActivity(
            locationInfo ? locationInfo.locationId : selectedLocation ? selectedLocation.id : filingId!
        );
        setShowItemizedDailyActivity(true);
    };

    const tableColumns: ColumnExtended[] = [
        {
            Header: "Date",
            accessor: "date",
            disableSortBy: true,
            Cell: ({ value }: CellProps<{}, any>) => {
                if (value !== "Total") {
                    return (
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleDateClick(value);
                            }}>
                            {DateTime.fromSQL(value).toFormat("LLL dd")}
                        </div>
                    );
                }
                return <>Total</>;
            },
        },

        {
            Header: "Gross Sales",
            id: "netSales",
            accessor: "sales",
            disableSortBy: true,
            Cell: ({ row }: CellProps<any, any>) => (
                <div style={{ textAlign: "right" }}>${moneyFromCents(+row.original.sales + +row.original.returns)}</div>
            ),
        },
        {
            Header: "Taxable Sales",
            id: "taxableSales",
            accessor: "taxableSales",
            disableSortBy: true,
            Cell: ({ row }: CellProps<any, any>) => {
                return <div style={{ textAlign: "right" }}>${moneyFromCents(row.original.taxableSales)}</div>;
            },
        },
        {
            Header: "Tax",
            accessor: "tax",
            disableSortBy: true,
            Cell: ({ value }: CellProps<{}, any>) => {
                return <div style={{ textAlign: "right" }}>${moneyFromCents(value)}</div>;
            },
        },
        {
            Header: "Set Asides",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ row }: CellProps<any, any>) => (
                <>
                    {!!row.original.status.fundedIn && (
                        <Tooltip title={getSettlementTooltip(row.original.status.fundedInfo)}>
                            <div>
                                <Lozenge level="ok">${moneyFromCents(row.original.status.fundedIn)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.status.fundedOut && (
                        <Tooltip title={getSettlementTooltip(row.original.status.fundedInfo)}>
                            <div>
                                <Lozenge level="ok">${moneyFromCents(row.original.status.fundedOut)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.status.pendingIn && (
                        <Tooltip title={getSettlementTooltip(row.original.status.pendingInInfo)}>
                            <div>
                                <Lozenge level="warn">${moneyFromCents(row.original.status.pendingIn)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.status.pendingOut && (
                        <Tooltip title={getSettlementTooltip(row.original.status.pendingOutInfo)}>
                            <div>
                                <Lozenge level="warn">${moneyFromCents(row.original.status.pendingOut)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.status.failed && (
                        <Tooltip title={getSettlementTooltip(row.original.status.failedInfo)}>
                            <div>
                                <Lozenge level="error">${moneyFromCents(row.original.status.failed)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.status.none && (
                        <Tooltip title={getSettlementTooltip(row.original.status.noneInfo)}>
                            <div>
                                <Lozenge level="none">${moneyFromCents(row.original.status.none)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            Header: "Manual SAs",
            accessor: "manualStatus",
            disableSortBy: true,
            Cell: ({ row }: CellProps<any, any>) => (
                <>
                    {!!row.original.manualStatus?.fundedIn && (
                        <Tooltip title={getSettlementTooltip(row.original.manualStatus.fundedInfo)}>
                            <div>
                                <Lozenge level="ok">${moneyFromCents(row.original.manualStatus?.fundedIn)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.manualStatus?.fundedOut && (
                        <Tooltip title={getSettlementTooltip(row.original.manualStatus.fundedInfo)}>
                            <div>
                                <Lozenge level="ok">${moneyFromCents(row.original.manualStatus?.fundedOut)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.manualStatus?.pendingIn && (
                        <Tooltip title={getSettlementTooltip(row.original.manualStatus.pendingInInfo)}>
                            <div>
                                <Lozenge level="warn">${moneyFromCents(row.original.manualStatus?.pendingIn)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.manualStatus?.pendingOut && (
                        <Tooltip title={getSettlementTooltip(row.original.manualStatus.pendingOutInfo)}>
                            <div>
                                <Lozenge level="warn">${moneyFromCents(row.original.manualStatus?.pendingOut)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.manualStatus?.failed && (
                        <Tooltip title={getSettlementTooltip(row.original.manualStatus.failedInfo)}>
                            <div>
                                <Lozenge level="error">${moneyFromCents(row.original.manualStatus?.failed)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.manualStatus.transferred && (
                        <Tooltip title={getSettlementTooltip(row.original.manualStatus.transferredInfo)}>
                            <div>
                                <Lozenge level="none">
                                    ${moneyFromCents(row.original.manualStatus?.transferred)}
                                </Lozenge>
                            </div>
                        </Tooltip>
                    )}
                    {!!row.original.manualStatus?.none && (
                        <Tooltip title={getSettlementTooltip(row.original.manualStatus.noneInfo)}>
                            <div>
                                <Lozenge level="none">${moneyFromCents(row.original.manualStatus?.none)}</Lozenge>
                            </div>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            Header: "Remittances",
            accessor: "remittances",
            disableSortBy: true,
            Cell: ({ value }: CellProps<any, any>) => {
                return (
                    <>
                        {value.map((r: any, i: number) =>
                            !!r.locationId && !!filingId ? (
                                <Lozenge key={`${r.id}${i}`} level="ok">
                                    <Tooltip title={`${getLocationName(r.locationId)}`} placement="top">
                                        <HelpTwoToneIcon
                                            fontSize="small"
                                            color="primary"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </Tooltip>
                                    ${moneyFromCents(r.amount)}
                                </Lozenge>
                            ) : (
                                <Tooltip
                                    key={`${r.id}${i}tooltip`}
                                    title={
                                        <div key={i}>
                                            ${moneyFromCents(r.amount)} - remitted via filing
                                            <ul style={{ paddingLeft: "10px", margin: 0 }}>
                                                <li>Frequency: {r.frequency}</li>
                                                <li>Period end: {r.periodEnd}</li>
                                            </ul>
                                        </div>
                                    }>
                                    <div>
                                        <Lozenge key={`${r.id}${i}`} level="ok">
                                            ${moneyFromCents(r.amount)}
                                        </Lozenge>
                                    </div>
                                </Tooltip>
                            )
                        )}
                    </>
                );
            },
        },
    ];

    if (!activity) {
        return <Loading />;
    }

    // eslint-disable-next-line react/display-name
    const ItemizedDailyActivityMemoized = React.memo(() => {
        return (
            <ItemizedDailyActivity
                accountId={accountIdItemizedDailyActivity}
                bizday={bizdayItemizedDailyActivity}
                identifier={identifierItemizedDailyActivity}
                showSalesBreakout={showItemizedDailyActivity}
                setShowSalesBreakout={setShowItemizedDailyActivity}
            />
        );
    });

    return (
        <div>
            <ItemizedDailyActivityMemoized />
            {filterLocations && filterLocations.length > 1 && (
                <div>
                    <Select<Location>
                        noneLabel={`All ${filterLocations.length} locations`}
                        value={selectedLocation}
                        onChange={setSelectedLocation}
                        options={filterLocations}
                        label={(l) => getLocationLabel(l)}
                    />
                </div>
            )}
            {!!activity && !!activity.daily && (
                <ReactTable
                    columns={tableColumns}
                    data={activity.daily}
                    options={{
                        pageSize: getPageSize(),
                        pageSizeOptions: [getPageSize()],
                        hideToolbar: true,
                        headerRowStyle: { verticalAlign: "top" },
                    }}
                />
            )}
        </div>
    );
}
