import { d30ToastError, ReactTable } from "@davo/portal-common";
import { IWellsFargoTransaction, moneyFromCents, toDisplayDateString } from "@davo/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getWellsTransactionsWithoutState } from "../services";
import { WellsAddDescriptionPatternModal } from "./WellsAddDescriptionPatternModal";
import { WellsAddNoteModal } from "./WellsAddNoteModal";
import { WellsSetStateModal } from "./WellsSetStateModal";

export function WellsTransactionsWithUnknownStates({ txns }: { txns: IWellsFargoTransaction[] }) {
    const [results, setResults] = useState<IWellsFargoTransaction[]>(txns);

    const refresh = useCallback(() => {
        getWellsTransactionsWithoutState()
            .then((data) => {
                const mapped = data.map((t) => {
                    return {
                        ...t,
                        transactionAmountFormatted: moneyFromCents(t.transactionAmount),
                    };
                });
                setResults(mapped);
            })
            .catch((e) => d30ToastError("Problem getting transaction.", e));
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Description",
                accessor: "transactionDescription",
                Cell: (data: any) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                        <span>{data.value}</span>
                        <WellsAddNoteModal txn={data.cell.row.original} />
                    </span>
                ),
            },
            {
                Header: "Amount",
                accessor: "transactionAmountFormatted",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${data.value}</div>,
            },
            {
                Header: "Date",
                accessor: "postingDate",
                Cell: (data: any) => <>{toDisplayDateString(data.value, false)}</>,
            },
            {
                Header: "State",
                accessor: "state",
                Cell: (data: any) => {
                    const value = data.value;

                    return (
                        <>
                            <WellsSetStateModal
                                txn={data.cell.row.original}
                                onSubmit={() => {
                                    refresh();
                                }}
                            />
                            &nbsp;
                            {value && <span>{value} </span>}
                        </>
                    );
                },
            },
        ];
    }, [refresh]);

    return (
        <div>
            <WellsAddDescriptionPatternModal
                onCreate={() => {
                    refresh();
                }}
            />

            <ReactTable
                columns={columns}
                data={results}
                refreshData={refresh}
                options={{
                    pageSize: 10,
                }}
            />
        </div>
    );
}
