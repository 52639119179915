import { Loading } from "@davo/portal-common";
import { AccountRecord, Location, LocationRecord, Partner } from "@davo/types";
import Alert from "@mui/material/Alert";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import { LocationDetail } from "./LocationDetail";
import { ICanDeactivateLocationsType } from "./services";

export interface IAccountLocationsDetailType {
    account?: AccountRecord;
    locations?: Location[];
    canDeactivateByLocation?: ICanDeactivateLocationsType;
    onChange?: () => void;
    partner?: Partner;
    allAccountLocations?: LocationRecord[];
    noneFoundMessage?: string;
}

export function AccountLocationsDetail({
    account,
    locations,
    canDeactivateByLocation,
    onChange,
    partner,
    allAccountLocations,
    noneFoundMessage = "No locations found",
}: IAccountLocationsDetailType) {
    const [sortedLocations, setSortedLocations] = useState<Location[]>();

    useEffect(() => {
        const sortedLocs = sortBy(locations, ["active", "name", "address1", "id"]);
        setSortedLocations(sortedLocs);
    }, [locations]);

    if (!account || !sortedLocations) {
        return <Loading />;
    }

    if (sortedLocations.length === 0) {
        return <Alert severity="warning">{noneFoundMessage}</Alert>;
    }

    const react = [];
    for (const location of sortedLocations) {
        react.push(
            <LocationDetail
                key={location.id}
                location={location}
                accountId={account.id}
                onChange={onChange}
                deactivationStatusLoaded={canDeactivateByLocation ? canDeactivateByLocation[location.id] : undefined}
                partner={partner}
                allAccountLocations={allAccountLocations}
            />
        );
    }

    return <div data-testid="account-locations-detail-container">{react}</div>;
}
