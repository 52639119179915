import { INavMenuItem, LoginPage, Profile, ResetPassword, useLoginContext } from "@davo/portal-common";
import { isProduction } from "@davo/types";
import React, { useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useAutoFilerMap } from "./AutofilerQueue";
import { getOpsPrimaryRoutes, getOpsRoutes, OpsDonut } from "./OpsDonut";
import { SendSampleEmails } from "./SendSampleEmails";
import { useOpsPortalConfigContext } from "./context";

export function OpsRouter() {
    const { opsPortalConfigInfo: configInfo } = useOpsPortalConfigContext();
    const loginContext = useLoginContext();

    const [donutPrimaryRoutes, setDonutPrimaryRoutes] = useState<INavMenuItem[]>([]);
    const [donutRoutes, setDonutRoutes] = useState<INavMenuItem[]>([]);
    const [firstDonutPath, setFirstDonutPath] = useState<string>();

    // this starts firebase websocket, can only have it called once
    useAutoFilerMap();

    useAsyncEffect(async () => {
        if (!loginContext.user) {
            return;
        }
        const primaryRoutes = getOpsPrimaryRoutes({ permissionsInfo: loginContext.permissions });
        setDonutPrimaryRoutes(primaryRoutes);

        const routes = getOpsRoutes();
        setDonutRoutes(routes);

        setFirstDonutPath(primaryRoutes[0].slug);
    }, [loginContext.user, loginContext.permissions]);

    const profileProps = {
        showScreenMode: true,
    };

    return (
        <Router>
            <Routes>
                <Route path="/authenticate/resetPassword" element={<ResetPassword />} />

                <Route path="/index.html" element={<Navigate to="/" replace />} />
                <Route path="/install-no-board" element={<Navigate to="/invitations" replace />} />

                {!isProduction(configInfo.d30Env) && <Route path="/sample-emails" element={<SendSampleEmails />} />}

                {!loginContext.user && (
                    <Route
                        path="*"
                        element={
                            <LoginPage
                                appName="Operations Portal"
                                disableInternalLogin={true}
                                isOpsLoginEnabled={true}
                                appType="opsPortal"
                            />
                        }
                    />
                )}

                {loginContext.user && (
                    <Route path="/" element={<OpsDonut primaryRoutes={donutPrimaryRoutes} />}>
                        {/* Redirect to first donut route if not provided */}
                        {firstDonutPath && <Route path="" element={<Navigate to={firstDonutPath} replace />} />}

                        {donutRoutes.map((item) => (
                            <Route key={item.slug} path={`${item.slug}/*`} element={item.element} />
                        ))}

                        {/* redirect any other path to the first donut route */}
                        {firstDonutPath && <Route path="*" element={<Navigate to={firstDonutPath} replace />} />}

                        <Route path="/profile" element={<Profile {...profileProps} />} />
                    </Route>
                )}
            </Routes>
        </Router>
    );
}
