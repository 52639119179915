import { d30ToastError, setGlobal, useLoginContext } from "@davo/portal-common";
import { AuthCodes, AutofilerQueueEntry, fromJSON, MetaFilingQueueEntry } from "@davo/types";
import * as Sentry from "@sentry/browser";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";

export type AutofilerEntryCallback = (entry: AutofilerQueueEntry) => void;
export type AuthcodeEntryCallback = (key: string | null, code: string) => void;

// this starts firebase websocket, can only have it called once
export function useAutoFilerMap() {
    const loginContext = useLoginContext();
    const [autofilerApp, setAutofilerApp] = useState<firebase.app.App>();
    const [internalMap] = useState<{ [key: string]: AutofilerQueueEntry }>({});
    const [authCodes] = useState<AuthCodes>({});

    useEffect(() => {
        try {
            const app = firebase.initializeApp(
                // we only have D30_ENV available locally for the UI...
                !process.env.D30_ENV
                    ? {
                          apiKey: "AIzaSyD7C8rYp-AMJ-lFAOTP5OhyyE_NB225gUk",
                          authDomain: "filer-prod.firebaseapp.com",
                          databaseURL: "https://filer-prod.firebaseio.com",
                          projectId: "filer-prod",
                          storageBucket: "filer-prod.appspot.com",
                          messagingSenderId: "84754248354",
                          appId: "1:84754248354:web:fe4e98a012da826a8d5dfb",
                      }
                    : {
                          apiKey: "AIzaSyCSyZ7OiwKnoKx6kOqtcZogW-hc86ZrXrA",
                          authDomain: "filer-dev.firebaseapp.com",
                          databaseURL: "https://filer-dev.firebaseio.com",
                          projectId: "filer-dev",
                          storageBucket: "filer-dev.appspot.com",
                          messagingSenderId: "177401561169",
                          appId: "1:177401561169:web:5d1d195c1103ddb34b3870",
                      },
                "AutofilerApp"
            );
            setAutofilerApp(app);
        } catch (e: any) {
            // AuthError (such as timeout, interrupted connection or unreachable host)
            // Just noise...so ignore, user will need to refresh page to try to get a firebase connection
            if (!e.message.includes("AuthError")) {
                Sentry.captureException(e);
            }
        }
    }, []);

    const hydrate = (val: any) => {
        try {
            return fromJSON(MetaFilingQueueEntry, val);
        } catch (e: any) {
            throw new Error(`Error while rehydrating filing queue entry ${val}`);
        }
    };

    function listenToAllOpen(
        add: AutofilerEntryCallback,
        change: AutofilerEntryCallback,
        remove: AutofilerEntryCallback
    ) {
        if (!autofilerApp) {
            return;
        }

        const _query = autofilerApp.database().ref("auto-filings");

        const onChildAdded = _query.on("child_added", (snap) => {
            add(hydrate(snap.val()));
        });
        const onChildChanged = _query.on("child_changed", (snap) => {
            change(hydrate(snap.val()));
        });
        const onChildRemoved = _query.on("child_removed", (snap) => {
            remove(hydrate(snap.val()));
        });
        return () => {
            _query.off("child_added", onChildAdded);
            _query.off("child_changed", onChildChanged);
            _query.off("child_removed", onChildRemoved);
        };
    }

    useAsyncEffect(async () => {
        if (!loginContext.firebaseToken || !autofilerApp) {
            return;
        }

        try {
            await autofilerApp.auth().signInWithCustomToken(loginContext.firebaseToken);
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(`Error signing into firebase: ${e}`);
            // AuthError (such as timeout, interrupted connection or unreachable host)
            // Just noise...so ignore, user will need to refresh page to try to get a firebase connection
            if (!e.message.includes("AuthError")) {
                Sentry.captureException(e);
            }
            return;
        }

        let lastUpdate = DateTime.now();
        let timeout: NodeJS.Timeout | undefined;

        const flush = () => {
            const now = DateTime.now();
            const pushUpdate = () => {
                setGlobal("autoFilerMap", { ...internalMap });
                clearTimeout(timeout);
                timeout = undefined;
                lastUpdate = now;
            };

            if (now.diff(lastUpdate).milliseconds > 1000) {
                pushUpdate();
            } else if (!timeout) {
                timeout = setTimeout(() => {
                    pushUpdate();
                }, 1000);
            }
        };

        const query = autofilerApp.database().ref("auto-filings");
        query
            .once("value")
            .then(async (results) => {
                const data = results.val();
                if (data) {
                    await Promise.all(
                        Object.values(data).map((filing) => {
                            const entry: AutofilerQueueEntry = hydrate(filing);
                            internalMap[entry.filingId] = entry;
                        })
                    );
                }
                setGlobal("autoFilerMap", { ...internalMap });
            })
            .catch((e) => d30ToastError("Problem accessing firebase records.", e));

        const update = (entry: AutofilerQueueEntry) => {
            internalMap[entry.filingId] = entry;
            flush();
        };

        const remove = (entry: AutofilerQueueEntry) => {
            delete internalMap[entry.filingId];
            flush();
        };

        listenToAllOpen(update, update, remove);
    }, [loginContext.firebaseToken, autofilerApp]);

    function listenToAllAuthCodes(
        add: AuthcodeEntryCallback,
        change: AuthcodeEntryCallback,
        remove: AuthcodeEntryCallback
    ) {
        if (!autofilerApp) {
            return;
        }

        const _query = autofilerApp.database().ref("authcodes");

        const onChildAdded = _query.on("child_added", (snap) => {
            add(snap.key, snap.val());
        });
        const onChildChanged = _query.on("child_changed", (snap) => {
            change(snap.key, snap.val());
        });
        const onChildRemoved = _query.on("child_removed", (snap) => {
            remove(snap.key, snap.val());
        });
        return () => {
            _query.off("child_added", onChildAdded);
            _query.off("child_changed", onChildChanged);
            _query.off("child_removed", onChildRemoved);
        };
    }

    useAsyncEffect(async () => {
        if (!loginContext.firebaseToken || !autofilerApp) {
            return;
        }

        try {
            await autofilerApp.auth().signInWithCustomToken(loginContext.firebaseToken);
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(`Error signing into firebase: ${e}`);
            // AuthError (such as timeout, interrupted connection or unreachable host)
            // Just noise...so ignore, user will need to refresh page to try to get a firebase connection
            if (!e.message.includes("AuthError")) {
                Sentry.captureException(e);
            }
            return;
        }

        let lastUpdate = DateTime.now();
        let timeout: NodeJS.Timeout | undefined;

        const flush = () => {
            const now = DateTime.now();
            const pushUpdate = () => {
                setGlobal("authCodeMap", { ...authCodes });
                clearTimeout(timeout);
                timeout = undefined;
                lastUpdate = now;
            };

            if (now.diff(lastUpdate).milliseconds > 1000) {
                pushUpdate();
            } else if (!timeout) {
                timeout = setTimeout(() => {
                    pushUpdate();
                }, 1000);
            }
        };

        const query = autofilerApp.database().ref("authcodes");
        query
            .once("value")
            .then(async (results) => {
                const data = results.val();
                if (data) {
                    await Promise.all(
                        Object.keys(data).map((key: string) => {
                            authCodes[key] = data[key];
                        })
                    );
                }
                setGlobal("authCodeMap", { ...authCodes });
            })
            .catch((e) => d30ToastError("Problem accessing firebase records.", e));

        const update = (key: string | null, code: string) => {
            if (!key) {
                return;
            }
            authCodes[key] = code;
            flush();
        };

        const remove = (key: string | null) => {
            if (!key) {
                return;
            }
            delete authCodes[key];
            flush();
        };

        listenToAllAuthCodes(update, update, remove);
    }, [loginContext.firebaseToken, autofilerApp]);

    useAsyncEffect(async () => {
        if (!autofilerApp) {
            return;
        }

        if (!loginContext.firebaseToken) {
            try {
                await autofilerApp.auth().signOut();
            } catch (e: any) {
                // eslint-disable-next-line no-console
                console.error(`Error signing into firebase: ${e}`);
                // AuthError (such as timeout, interrupted connection or unreachable host)
                // Just noise...so ignore, user will need to refresh page to try to get a firebase connection
                if (!e.message.includes("AuthError")) {
                    Sentry.captureException(e);
                }
            }
        }
    }, [loginContext.firebaseToken]);
}
