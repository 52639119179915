import { d30Toast, d30ToastError, useModalEditor } from "@davo/portal-common";
import {
    BusinessDay,
    IAchTransaction,
    IBilling,
    initialCap,
    moneyFromCents,
    toDisplayDateString,
    toDisplayDateTimeString,
} from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import React, { CSSProperties, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getBillingsForInvoice, rebillInvoice } from "./services";

const thStyle: CSSProperties = {
    display: "table-cell",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgb(224, 224, 224)",
    textAlign: "left",
    padding: "14px 40px 14px 16px",
    fontWeight: "normal",
};

const rowStyle: CSSProperties = {
    display: "table-cell",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgb(224, 224, 224)",
    textAlign: "left",
    color: "rgb(152, 169, 188)",
    padding: "14px 40px 14px 16px",
};

const dtStyle: CSSProperties = {
    width: "80px",
    fontWeight: "bold",
    float: "left",
    padding: "0.3em",
    clear: "both",
};

const ddStyle: CSSProperties = {
    padding: "0.3em",
    textAlign: "left",
    float: "left",
};

export interface IRebillModalButtonProps {
    invoiceId: string;
    invoiceDate: BusinessDay;
    amount: number;
    onRebill: () => void;
    isFullWidth?: boolean;
    isDisabled?: boolean;
}

export function RebillModalButton({
    invoiceId,
    invoiceDate,
    amount,
    onRebill,
    isFullWidth,
    isDisabled = false,
}: IRebillModalButtonProps) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [history, setHistory] = useState<any[]>([]);

    useAsyncEffect(async () => {
        if (isDialogOpen) {
            const hist = await getBillingsForInvoice(invoiceId);
            setHistory(hist);
        }
    }, [isDialogOpen]);

    const rebill = async () => {
        setIsBusy(true);
        try {
            await rebillInvoice(invoiceId);
            d30Toast("Successfully re-billed invoice");
            onRebill();
            closeDialog();
        } catch {
            d30ToastError("Error re-billing invoice");
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <>
            <Button
                data-testid={"rebillDialogButton"}
                disabled={isDisabled}
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="primary"
                fullWidth={isFullWidth}>
                Re-bill
            </Button>

            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Re-bill Failed Invoice</DialogTitle>
                    <DialogContent>
                        <div data-testid={"rebillModalContent"}>
                            <dl>
                                <dt style={dtStyle}>Amount</dt>
                                <dd style={ddStyle}>${moneyFromCents(amount)}</dd>

                                <dt style={dtStyle}>Date</dt>
                                <dd style={ddStyle}>{toDisplayDateString(invoiceDate)}</dd>
                            </dl>

                            <br style={{ clear: "both" }} />

                            <h3>Billing Attempts</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <td style={thStyle}>Type</td>
                                        <td style={thStyle}>Date</td>
                                        <td style={thStyle}>Status</td>
                                        <td style={thStyle}>Note</td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {/* eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents */}
                                    {history.map((billing: IBilling & { achTransaction: IAchTransaction }) => (
                                        <tr key={billing.id}>
                                            <td style={rowStyle}>{initialCap(billing.type)}</td>
                                            <td style={rowStyle}>{toDisplayDateTimeString(billing.created)}</td>
                                            <td style={rowStyle}>{initialCap(billing.achTransaction.status)}</td>
                                            <td style={rowStyle}>{billing.achTransaction.statusNotes}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <br />
                            <br />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-testid={"rebillModalCancelButton"}
                            variant="outlined"
                            color="primary"
                            disabled={isBusy}
                            onClick={closeDialog}>
                            Cancel
                        </Button>
                        <Button
                            data-testid={"rebillModalButton"}
                            variant="contained"
                            color="primary"
                            disabled={isBusy}
                            onClick={rebill}>
                            Re-bill
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
