import { d30Toast, d30ToastError, Select, TextField } from "@davo/portal-common";
import {
    AmendmentReason,
    AmendmentReasonKeys,
    AmendmentReasons,
    AmendmentStatus,
    AmendmentStatuses,
    AmendmentStatusKeys,
    FilingDetails,
    FilingStatus,
    isAmendmentReasonFiledInError,
} from "@davo/types";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import assert from "assert";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { updateFiling, updateFilingAssignee } from "../services";

interface IAmendmentInfoType {
    status: FilingStatus;
    taxDue?: number | null;
    updateReason?: string | null;
    amendmentReason?: AmendmentReason | null;
    amendmentStatus?: AmendmentStatus | null;
    amendmentNote?: string | null;
    davoError?: boolean | null;
    hasNotice?: boolean | null;
}

export interface IFilingAmendmentFormType {
    filing: FilingDetails;
    isAmendingFiling: boolean;
    onUpdate: () => void;
    onCancel: () => void;
}

export function FilingAmendmentForm({ filing, isAmendingFiling, onUpdate, onCancel }: IFilingAmendmentFormType) {
    const [amendmentInfo, setAmendmentInfo] = useState<IAmendmentInfoType>({} as IAmendmentInfoType);

    useEffect(() => {
        if (isAmendingFiling) {
            assert(filing);

            setAmendmentInfo({
                ...amendmentInfo,
                status: filing.status,
                taxDue: filing.taxDue,
                updateReason: undefined,
                amendmentReason: filing.amendmentReason,
                amendmentStatus: filing.amendmentStatus,
                amendmentNote: filing.amendmentNote,
                davoError: filing.davoError,
                hasNotice: filing.hasNotice,
            });
        }
    }, [filing, isAmendingFiling]);

    const handleAmendmentStatusSelection = (amendmentStatus?: AmendmentStatus) => {
        amendmentStatus &&
            setAmendmentInfo({
                ...amendmentInfo,
                amendmentStatus: amendmentStatus,
                ...(amendmentInfo.amendmentStatus !== amendmentStatus
                    ? { amendmentReason: undefined, updateReason: undefined }
                    : {}),
            });
    };

    const handleAmendmentReasonSelection = (amendmentReason?: AmendmentReason) => {
        amendmentReason &&
            setAmendmentInfo({
                ...amendmentInfo,
                amendmentReason: amendmentReason,
                ...(isAmendmentReasonFiledInError(amendmentReason) ? { updateReason: undefined } : {}),
            });
    };

    const canSubmit = () => {
        if (isAmendingFiling) {
            return (
                (amendmentInfo.updateReason && !amendmentInfo.amendmentStatus) ||
                (amendmentInfo.amendmentStatus === "required" && amendmentInfo.amendmentNote) ||
                (amendmentInfo.amendmentStatus === "completed" &&
                    amendmentInfo.amendmentNote &&
                    amendmentInfo.amendmentReason &&
                    (!isAmendmentReasonFiledInError(amendmentInfo.amendmentReason) || amendmentInfo.updateReason))
            );
        }
        return false;
    };

    const updateOrCloseFiling = () => {
        if (canSubmit()) {
            updateFiling(filing.id, {
                status: amendmentInfo.status,
                taxDue: amendmentInfo.taxDue,
                amendmentReason: amendmentInfo.amendmentReason,
                amendmentStatus: amendmentInfo.amendmentStatus,
                amendmentDate: DateTime.utc(),
                amendmentNote: amendmentInfo.amendmentNote,
                updateReason: amendmentInfo.updateReason ?? "",
                davoError: amendmentInfo.davoError,
                hasNotice: amendmentInfo.hasNotice,
            })
                .then(() => {
                    if (amendmentInfo.amendmentStatus === "completed") {
                        return updateFilingAssignee(filing.id, "undefined", true);
                    }
                })
                .then(() => {
                    d30Toast("Filing updated.");
                    onUpdate();
                })
                .catch((e) => d30ToastError(e.message, e));
        }
    };

    return (
        <div data-testid={"filingAmendmentForm"}>
            {isAmendingFiling && (
                <>
                    <Select<AmendmentStatus>
                        data-testid={"amendmentStatus"}
                        title="Amendment Status"
                        value={amendmentInfo.amendmentStatus}
                        onChange={(statusId?: AmendmentStatus) => handleAmendmentStatusSelection(statusId)}
                        options={AmendmentStatusKeys}
                        label={(statusId: AmendmentStatus) => AmendmentStatuses[statusId]}
                    />

                    {amendmentInfo.amendmentStatus && (
                        <>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-testid={"davoErrorCb"}
                                            checked={!!amendmentInfo.davoError}
                                            onChange={(e, isDavoErrorChecked) =>
                                                setAmendmentInfo({
                                                    ...amendmentInfo,
                                                    davoError: isDavoErrorChecked,
                                                })
                                            }
                                        />
                                    }
                                    label="DAVO Error"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-testid={"hasNoticeCb"}
                                            checked={!!amendmentInfo.hasNotice}
                                            onChange={(e, isHasNoticeChecked) =>
                                                setAmendmentInfo({
                                                    ...amendmentInfo,
                                                    hasNotice: isHasNoticeChecked,
                                                })
                                            }
                                        />
                                    }
                                    label="Has Notice"
                                />
                            </FormGroup>
                            <TextField
                                key={"amendmentRequiredNote"}
                                data-testid={"amendmentRequiredNote"}
                                label="Notes why amendment is required?"
                                value={amendmentInfo.amendmentNote ?? ""}
                                onChange={(note?: string) =>
                                    setAmendmentInfo({ ...amendmentInfo, amendmentNote: note })
                                }
                                isMultiline={true}
                                rows={4}
                            />
                            {amendmentInfo.amendmentStatus === "completed" && (
                                <>
                                    <Select<AmendmentReason>
                                        title="Amendment Reason"
                                        data-testid={"amendmentReason"}
                                        value={amendmentInfo.amendmentReason}
                                        onChange={(reasonId?: AmendmentReason) =>
                                            handleAmendmentReasonSelection(reasonId)
                                        }
                                        noneLabel="Select amendment reason"
                                        options={AmendmentReasonKeys}
                                        label={(reasonId: AmendmentReason) => AmendmentReasons[reasonId]}
                                    />
                                    {isAmendmentReasonFiledInError(amendmentInfo.amendmentReason) && (
                                        <TextField
                                            data-testid={"updateReason"}
                                            key={"updateReason"}
                                            label="Why are you amending this filing?"
                                            value={amendmentInfo.updateReason ?? ""}
                                            onChange={(updateReason?: string) =>
                                                setAmendmentInfo({ ...amendmentInfo, updateReason })
                                            }
                                            isMultiline={true}
                                            rows={4}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}

            {amendmentInfo.status && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}>
                    <Button
                        data-testid={"updateOrCloseFilingBtn"}
                        onClick={updateOrCloseFiling}
                        style={{ marginRight: "10px" }}
                        color="primary"
                        variant="contained"
                        disabled={!canSubmit()}>
                        {isAmendingFiling ? "Update Filing" : "Close Filing"}
                    </Button>

                    {isAmendingFiling && (
                        <Button onClick={onCancel} color="secondary" variant="contained">
                            Cancel
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}
