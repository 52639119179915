import { useGlobalOptional } from "@davo/portal-common";
import { AF_BULK_PROCESSING, AF_BULK_QUEUED, AF_OPEN, AF_PROCESSING, AutofilerQueueEntry } from "@davo/types";
import { Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export function AutofilerStatus() {
    const [autoFilerMap] = useGlobalOptional<{ [key: string]: AutofilerQueueEntry }>("autoFilerMap");
    const [toTesting, setToTesting] = useState<number>(0);
    const [toFile, setToFile] = useState<number>(0);
    const [testing, setTesting] = useState<number>(0);
    const [filing, setFiling] = useState<number>(0);
    const [bulk, setBulk] = useState<number>(0);

    useEffect(() => {
        if (autoFilerMap) {
            setToTesting(Object.values(autoFilerMap).filter((i) => i.state === AF_OPEN && i.test === true).length);
            setToFile(Object.values(autoFilerMap).filter((i) => i.state === AF_OPEN && i.test === false).length);
            setTesting(Object.values(autoFilerMap).filter((i) => i.state === AF_PROCESSING && i.test === true).length);
            setFiling(Object.values(autoFilerMap).filter((i) => i.state === AF_PROCESSING && i.test === false).length);
            setBulk(
                Object.values(autoFilerMap).filter((i) => [AF_BULK_PROCESSING, AF_BULK_QUEUED].includes(i.state)).length
            );
        }
    }, [autoFilerMap]);
    return (
        <>
            {toTesting + toFile > 0 && (
                <>
                    <Typography>
                        Queued (
                        <Tooltip title={"To be tested"} placement="top">
                            <span>{toTesting}</span>
                        </Tooltip>
                        ,{" "}
                        <Tooltip title={"To be filed"} placement="top">
                            <span>{toFile}</span>
                        </Tooltip>
                        ){" "}
                    </Typography>
                </>
            )}
            {testing + filing + bulk > 0 && (
                <>
                    <Typography>
                        Running (
                        <Tooltip title={"Testing"} placement="top">
                            <span>{testing}</span>
                        </Tooltip>
                        ,{" "}
                        <Tooltip title={"Filing"} placement="top">
                            <span>{filing}</span>
                        </Tooltip>
                        ,{" "}
                        <Tooltip title={"Bulk"} placement="top">
                            <span>{bulk}</span>
                        </Tooltip>
                        )
                    </Typography>
                </>
            )}
        </>
    );
}
