import { get } from "@davo/portal-common";
import { Partner } from "@davo/types";

export const getAllPartners = async () => {
    return get<Partner[]>(`api/ops/partners`, true);
};

export async function getStatesWithActiveGroupLoginsForPartner(partnerId: string) {
    return get<string[]>(`api/ops/partners/${partnerId}/group-login/states`, true);
}
