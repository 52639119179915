import { Card, CardContent, CardHeader } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles({
    card: {
        minWidth: "350px",
        maxWidth: "350px",
        minHeight: "350px",
        margin: "5px",
    },
});

export function AccountDetailCard({ title, subheader, content }: { title?: any; subheader?: any; content: any }) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            {(title || subheader) && <CardHeader title={title} subheader={subheader} />}
            <CardContent data-testid={"content"}>{content}</CardContent>
        </Card>
    );
}
