import { d30Toast, d30ToastError, useLoginContext, useRefresh } from "@davo/portal-common";
import { AppType, AppTypeDescriptions, IPermission, User } from "@davo/types";
import DeleteTwoToneIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import "./css/sms.css";
import { getPermissionsForUser, removeUserPermissions } from "./services";
import { hasPermission } from "./util";

interface IUserPermissionsPaneType {
    userArg: User;
}

export function UserPermissionsPane({ userArg }: IUserPermissionsPaneType) {
    const loginContext = useLoginContext();

    const [userPermissions, setUserPermissions] = useState<IPermission[]>([]);

    const [refresh] = useRefresh(() => {
        setUserPermissions([]);
        getPermissionsForUser(userArg.id)
            .then((permissions) => {
                permissions.sort((x: IPermission, y: IPermission) => {
                    if (x.description && y.description) {
                        if (x.description > y.description) {
                            return 1;
                        }
                        if (x.description < y.description) {
                            return -1;
                        }
                    }
                    return 0;
                });
                setUserPermissions(permissions);
            })
            .catch((e) => d30ToastError("Problem with retrieving user permissions.", e));
    });

    const doRemovePermission = (permissionTag: string) => {
        removeUserPermissions(userArg.id, permissionTag)
            .then(() => {
                d30Toast("Permission removed");
                refresh();
            })
            .catch((e) => d30ToastError("Problem removing user permissions", e));
    };

    if (isEmpty(userPermissions)) {
        return null;
    }

    return (
        <Accordion
            slotProps={{ transition: { unmountOnExit: true } }}
            style={{ border: "none", boxShadow: "none" }}
            defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                Permissions
            </AccordionSummary>
            <AccordionDetails style={{ display: "flex" }}>
                <div>
                    <div>
                        <List sx={{ width: "100%", maxWidth: 500 }}>
                            {userPermissions.map((permission: IPermission) => (
                                <ListItem
                                    key={permission.id}
                                    secondaryAction={
                                        <>
                                            {userArg.type !== "Superuser" &&
                                                hasPermission(loginContext.permissions, "manage_user_permissions") && (
                                                    <IconButton
                                                        aria-label="remove-permission"
                                                        onClick={() => {
                                                            doRemovePermission(permission.permissionTag);
                                                        }}>
                                                        <DeleteTwoToneIcon color="primary" />
                                                    </IconButton>
                                                )}
                                        </>
                                    }>
                                    <ListItemText
                                        primary={`${AppTypeDescriptions[permission.app as AppType]}: ${
                                            permission.description
                                        }`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        {userPermissions.length === 0 && <Typography>No Custom User Permissions</Typography>}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
