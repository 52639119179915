import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import hsImage from "./resources/hubspot.png";
import { getHubSpotId } from "./services";

export interface IHubSpotLinkType {
    type: "user" | "invitation" | "account" | "location";
    davoId: string;
    hubSpotIdProvided?: string;
}

function getHubSpotURL(hubSpotId: string, type: "user" | "invitation" | "account" | "location") {
    switch (type) {
        case "user":
        case "invitation":
            return `https://app.hubspot.com/contacts/1846071/contact/${hubSpotId}/`;
        case "account":
            return `https://app.hubspot.com/contacts/1846071/company/${hubSpotId}/`;
        case "location":
            return `https://app.hubspot.com/contacts/1846071/deal/${hubSpotId}/`;
    }
}

export function HubSpotLink({ type, davoId, hubSpotIdProvided }: IHubSpotLinkType) {
    const [hubSpotId, setHubSpotId] = useState<string | undefined>(hubSpotIdProvided);
    const [url, setUrl] = useState<string>();

    useAsyncEffect(async () => {
        if (hubSpotIdProvided) {
            return;
        }
        setHubSpotId(await getHubSpotId(davoId));
    }, [davoId, hubSpotIdProvided]);

    useEffect(() => {
        if (!hubSpotId) {
            return;
        }
        setUrl(getHubSpotURL(hubSpotId, type));
    }, [hubSpotId, type]);

    if (!hubSpotId) {
        return null;
    }

    return (
        <div
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "10px",
                marginRight: "10px",
            }}>
            <IconButton
                aria-label="new window"
                onClick={() => {
                    window.open(`${url}`, "_blank");
                }}
                size="large">
                <img src={hsImage} style={{ width: "20px" }} alt={"Hubspot"} data-testid={"hubspot_link"} />
            </IconButton>
        </div>
    );
}
