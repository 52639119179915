import { ConfirmationModal, d30Toast, d30ToastError, DavoLink, useModalEditor } from "@davo/portal-common";
import {
    IWellsFargoTransactionInfo,
    IWellsFargoTransactionMappingInfo,
    moneyFromCents,
    toDisplayDateString,
} from "@davo/types";
import { Button, Icon, Tooltip } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { CSSProperties } from "react";
import { unmatchWellsTransaction } from "../services";

export interface IWellsTransactionMatchesType {
    txn: IWellsFargoTransactionInfo;
    filingId?: string;
    onChange?: () => void;
}

const dtStyle: CSSProperties = {
    fontWeight: "bold",
    float: "left",
    marginRight: "0.8em",
    padding: "0.3em",
};

const ddStyle: CSSProperties = {
    clear: "both",
    padding: "0.3em",
    textAlign: "left",
};

export function WellsTransactionMatches({ txn, filingId, onChange }: IWellsTransactionMatchesType) {
    const [showUnmatchFilingConfirmation, unmatchFilingConfirmationProps] =
        useModalEditor<IWellsFargoTransactionMappingInfo>(async (match?: IWellsFargoTransactionMappingInfo) => {
            if (match && match.matchedFiling) {
                await unmatchWellsTransaction(match.txnId, match.matchedFiling);

                if (onChange) {
                    onChange();
                }

                d30Toast("Un-matched transaction.");
            }
        });

    const [showUnmatchAllConfirmation, unmatchAllConfirmationProps] = useModalEditor<string>((txnId?: string) => {
        if (txnId) {
            unmatchWellsTransaction(txn.id)
                .then(() => {
                    if (onChange) {
                        onChange();
                    }

                    d30Toast("Un-matched transaction.");
                })
                .catch((e) => d30ToastError("Problem with un-matched transaction.", e));
        }
    });

    const getTooltip = (m: IWellsFargoTransactionMappingInfo) => {
        return (
            <dl>
                <dt style={dtStyle}>Type</dt>
                <dd style={ddStyle}>
                    {m.matchedPenalty && <>Penalty</>}
                    {m.matchedFiling && <>Filing</>}
                    {m.matchedOutflow && <>Returned by Check</>}
                </dd>

                <dt style={dtStyle}>Matched Amount</dt>
                <dd style={ddStyle}>
                    <span style={{ verticalAlign: "middle" }}>${moneyFromCents(m.amount)}</span>

                    {m.matchedFiling && (
                        <Tooltip title={"Un-match this transaction from this filing"}>
                            <Icon
                                color={"secondary"}
                                style={{ verticalAlign: "middle", marginLeft: "10px" }}
                                onClick={() => {
                                    showUnmatchFilingConfirmation(m);
                                }}>
                                dangerous_outlined
                            </Icon>
                        </Tooltip>
                    )}
                </dd>
                <dt style={dtStyle}>Matched Date</dt>
                <dd style={ddStyle}>{toDisplayDateString(m.created)}</dd>
                <dt style={dtStyle}>Matched By</dt>
                <dd style={ddStyle}>{m.createdByName ?? "System"}</dd>
            </dl>
        );
    };

    return (
        <>
            <dl>
                {!isEmpty(txn.matchedEntities) && (
                    <>
                        {txn.matchedEntities.map((m) => (
                            <>
                                {m.matchedFiling && (
                                    <Tooltip title={getTooltip(m)} key={"filing-" + m.id} disableInteractive={false}>
                                        <div>
                                            {m.matchedFiling === filingId && <>{`$${moneyFromCents(m.amount)}`}</>}
                                            {m.matchedFiling !== filingId && (
                                                <DavoLink to={`/filings/${m.matchedFiling}`} target={"_blank"}>
                                                    Filing - {`$${moneyFromCents(m.amount)}`}
                                                </DavoLink>
                                            )}
                                        </div>
                                    </Tooltip>
                                )}

                                {m.matchedPenalty && (
                                    <Tooltip title={getTooltip(m)} key={"penalty-" + m.id} disableInteractive={false}>
                                        <div>
                                            <DavoLink to={`/penalties/${m.matchedPenalty}`} target={"_blank"}>
                                                Penalty - {`$${moneyFromCents(m.amount)}`}
                                            </DavoLink>
                                        </div>
                                    </Tooltip>
                                )}

                                {m.matchedOutflow && (
                                    <Tooltip title={getTooltip(m)} key={"check-" + m.id} disableInteractive={false}>
                                        <div>
                                            <DavoLink to={`/accounts/${m.matchedOutflowAccountId}`} target={"_blank"}>
                                                Check - {`$${moneyFromCents(m.amount)}`}
                                            </DavoLink>
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        ))}

                        <div>
                            <Tooltip title={"Un-match all filings and penalties from this transaction"}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => {
                                        showUnmatchAllConfirmation(txn.id);
                                    }}>
                                    Un-match All
                                </Button>
                            </Tooltip>
                        </div>
                    </>
                )}
            </dl>

            {unmatchFilingConfirmationProps.isDialogOpen && (
                <ConfirmationModal
                    message="Are you sure you want to un-match this transaction?"
                    title="Un-match Transaction?"
                    {...unmatchFilingConfirmationProps}
                    zIndex={20000}
                />
            )}

            {unmatchAllConfirmationProps.isDialogOpen && (
                <ConfirmationModal
                    message="Are you sure you want to un-match all filings from this transaction?"
                    title="Un-match Transaction?"
                    {...unmatchAllConfirmationProps}
                    zIndex={20000}
                />
            )}
        </>
    );
}
