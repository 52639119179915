import { PaperComponent } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";

interface IReopenFilingModalProps {
    handleReopen: (reason: string) => void;
    closeDialog: () => void;
}

export function ReopenFilingModal(props: IReopenFilingModalProps) {
    const [message, setMessage] = useState<string | undefined>("message");
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const doSave = () => {
        if (!message) {
            throw new Error("Cannot reopen filing without a reason!");
        }
        setIsBusy(false);
        props.handleReopen(message);
    };

    const doCancel = () => {
        props.closeDialog();
    };

    return (
        <Dialog PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={true} onClose={doCancel}>
            <DialogTitle id="draggable-dialog-title">Why does this filing need to be reopened?</DialogTitle>
            <DialogContent>
                <TextField
                    key={"reason"}
                    label="Add a reason here"
                    variant="outlined"
                    onChange={(v) => {
                        setIsBusy(false);
                        setMessage(`REOPENED: ${v.target.value}`);
                    }}
                    style={{ width: "100%" }}
                    multiline={true}
                    rows={7}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={doCancel}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" disabled={isBusy} onClick={doSave}>
                    Open Filing
                </Button>
            </DialogActions>
        </Dialog>
    );
}
