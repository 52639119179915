import {
    BodyText,
    d30ToastError,
    getAccountLocationsStatus,
    getLocationsEnhancedAll,
    getPartner,
    Loading,
    reduceToSingleAccountLocationStatus,
} from "@davo/portal-common";
import { AccountRecordWithLocationStatus, Location, LocationRecord, Partner, User } from "@davo/types";
import { Alert, useTheme } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { AccountAlerts } from "./AccountAlerts";
import { AccountDetailAccordions } from "./AccountDetailAccordions";
import { AccountDetailFooter } from "./AccountDetailFooter";
import { AccountDetailHeader } from "./AccountDetailHeader";
import { AccountLocationsDetail } from "./AccountLocationsDetail";
import { BillingAlert } from "./components";
import { InactiveLocationsWithLoad } from "./InactiveLocationsWithLoad";
import { canDeactivateLocations, getAccount, getAllPartners, getUser, ICanDeactivateLocationsType } from "./services";

export function AccountDetailPaneFewLocations({ accountId }: { accountId: string }) {
    const navigate = useNavigate();
    const [update, setUpdate] = useState<number>(0);
    const [account, setAccount] = useState<AccountRecordWithLocationStatus>();
    const [allAccountLocations, setAllAccountLocations] = useState<LocationRecord[]>();
    const [activeLocations, setActiveLocations] = useState<Location[]>();
    const [accountOwner, setAccountOwner] = useState<User>();
    const [partner, setPartner] = useState<Partner>();
    const [partners, setPartners] = useState<Partner[]>();
    const [canDeactivateByLocation, setCanDeactivateByLocation] = useState<ICanDeactivateLocationsType>();

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    const refresh = () => {
        setUpdate(() => update + 1);
    };

    useAsyncEffect(async () => {
        // navigating directly from one account to another accesses the state before it's reloaded, forcing a reset on change
        if (accountId !== account?.id) {
            setAllAccountLocations(undefined);
            setActiveLocations(undefined);
            setCanDeactivateByLocation(undefined);
            setPartners(undefined);
        }
        const [acc, accLocationsStatus] = await Promise.all([
            getAccount(accountId),
            getAccountLocationsStatus(accountId),
        ]);

        if (acc.name === "<account issue - try logging in again>") {
            setAccount(undefined);
            d30ToastError("Account not found.");
            navigate("/accounts");
            return;
        }
        // If we're looking at an alias, then redirect to the actual account id
        if (acc.id !== accountId) {
            navigate(`/accounts/${acc.id}`);
            return;
        }

        setAccount({
            ...acc,
            status: reduceToSingleAccountLocationStatus(accLocationsStatus),
        });

        const locations = await getLocationsEnhancedAll(accountId);
        const canDeactivateLocationPromise = canDeactivateLocations(locations.map((l: Location) => l.id));
        setAllAccountLocations(locations);
        setActiveLocations(locations.filter((l: LocationRecord) => l.active));

        const allPartnerPromise = getAllPartners();

        const [canDeactivateLocation, getAllPartnersRes, acctOwnerRes, partnerRes] = await Promise.all([
            canDeactivateLocationPromise,
            allPartnerPromise,
            ...(acc.accountOwnerId ? [getUser(acc.accountOwnerId)] : [undefined]),
            ...(acc.partnerId ? [getPartner(acc.partnerId)] : [undefined]),
        ]);
        setCanDeactivateByLocation(canDeactivateLocation);
        setPartners(getAllPartnersRes);
        setCanDeactivateByLocation(canDeactivateLocation);
        setAccountOwner(acctOwnerRes as User | undefined);
        setPartner(partnerRes as Partner | undefined);
    }, [accountId, update]);

    const renderAccountNote = (accountNote: string) => (
        <div>
            <strong>Account Note:</strong>
            <BodyText>{accountNote}</BodyText>
        </div>
    );

    if (!account) {
        return <Loading />;
    }

    return (
        <>
            <div>
                <AccountDetailHeader
                    account={{
                        ...account,
                    }}
                    partner={partner}
                    accountOwnerFirstName={accountOwner?.firstName}
                />
                <BillingAlert
                    locations={activeLocations}
                    alertCopy={"Please resolve billing issues"}
                    accountId={account?.id}
                />
                {!isNil(account.note) && (
                    <Alert
                        variant={isDarkMode ? "outlined" : "standard"}
                        style={{ marginBottom: "6px" }}
                        severity="info">
                        {renderAccountNote(account.note)}
                    </Alert>
                )}
                <AccountAlerts accountId={accountId} />

                {activeLocations && (
                    <AccountLocationsDetail
                        account={account}
                        locations={activeLocations}
                        onChange={refresh}
                        canDeactivateByLocation={canDeactivateByLocation}
                        partner={partner}
                        allAccountLocations={allAccountLocations}
                        noneFoundMessage={"No active locations found"}
                    />
                )}

                <AccountDetailAccordions accountId={accountId} locations={activeLocations} partnerId={partner?.id} />

                <InactiveLocationsWithLoad accountId={accountId} partner={partner} />
            </div>
            <AccountDetailFooter
                account={account}
                accountId={accountId}
                partner={partner}
                partners={partners}
                refresh={refresh}
                locations={activeLocations ?? []}
            />
        </>
    );
}
