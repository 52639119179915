import { d30Toast, d30ToastError, NumberField, Select } from "@davo/portal-common";
import {
    BusinessDay,
    ManualSetAsideReturnTypeKeys,
    ManualSetAsideType,
    ManualSetAsideTypes,
    money,
    noop,
} from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { manualSetAside } from "./services";

interface IReturnFundsModalType {
    periodDay: BusinessDay;
    accountId: string;
    locationId: string;
    closeDialog: (target?: string) => void;
    balance: number;
    refresh: () => void;
}

export function ReturnFundsModal({
    periodDay,
    accountId,
    locationId,
    closeDialog,
    balance,
    refresh = noop,
}: IReturnFundsModalType) {
    const [amount, setAmount] = useState<number | undefined>(Math.abs(balance / 100));
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [type, setType] = useState<ManualSetAsideType | undefined>(undefined);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const doCancel = () => {
        setType(undefined);
        closeDialog();
    };

    const validateAmount = (amt: number | undefined) => {
        if (amt && Math.abs(Math.round(amt * 100)) > Math.abs(balance)) {
            return `Cannot return more than ${money(Math.abs(balance / 100))}`;
        }
        return undefined;
    };

    const doSetAside = () => {
        if (amount && type) {
            setIsBusy(true);
            manualSetAside(true, accountId, locationId, Math.round(amount * -100), type, periodDay, message)
                .then(() => {
                    d30Toast(`${money(amount)} Return succeeded.`);
                    refresh();
                })
                .catch((e) => d30ToastError("Problem processing manual set-aside.", e))
                .finally(() => {
                    setIsBusy(false);
                    doCancel();
                });
        }
    };

    return (
        <>
            <Dialog open={true} onClose={doCancel}>
                <DialogTitle>Return Funds</DialogTitle>
                <DialogContent>
                    <Typography>Maximum available: {money(Math.abs(balance / 100))}</Typography>
                    <Typography>Note: this can return money for rates that are not included on this filing</Typography>
                    <Select<ManualSetAsideType>
                        title="Type"
                        value={type}
                        onChange={setType}
                        options={ManualSetAsideReturnTypeKeys}
                        label={(typeId: ManualSetAsideType) => ManualSetAsideTypes[typeId]}
                    />
                    <NumberField
                        label={`Amount to return`}
                        value={Math.abs(balance / 100)}
                        onChange={setAmount}
                        validate={validateAmount}
                    />
                    <TextField
                        key={"merchantNote"}
                        label="Merchant note"
                        variant="outlined"
                        onChange={(v) => {
                            setMessage(v.target.value);
                        }}
                        style={{ width: "100%" }}
                        multiline={true}
                        rows={7}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={doCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isBusy || !amount || !type || !!validateAmount(amount)}
                        onClick={doSetAside}>
                        Return Funds
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
