import { d30Toast, d30ToastError, MultiSelect, PaperComponent, Select } from "@davo/portal-common";
import { AppType, AppTypeDescriptions, AppTypes, IPermission, User, UserPermissionAppType } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { createUserPermissions, getPermissions, getPermissionsWithFilter } from "./services";

export interface IAddUserPermissionsModalType {
    user: User;
    setOpen: (open: boolean) => void;
    refresh: () => void;
}

export function AddUserPermissionsModal({ user, setOpen, refresh }: IAddUserPermissionsModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [permissions, setPermissions] = useState<IPermission[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<IPermission[]>([]);
    const [selectedApp, setSelectedApp] = useState<AppTypes | undefined>(undefined);

    const updatePermissions = (permissionsPromise: Promise<IPermission[]>) => {
        permissionsPromise
            .then((perms) => {
                setPermissions(perms);
            })
            .catch((e) => d30ToastError("Problem with retrieving permissions.", e));
    };

    useEffect(() => {
        const permissionsPromise = getPermissions();
        updatePermissions(permissionsPromise);
    }, []);

    useEffect(() => {
        if (!isNil(selectedApp)) {
            const permissionsPromise = getPermissionsWithFilter(selectedApp);
            updatePermissions(permissionsPromise);
        } else {
            const permissionsPromise = getPermissions();
            updatePermissions(permissionsPromise);
        }
    }, [selectedApp]);

    const _shutdown = () => {
        setOpen(false);
        setSelectedPermissions([]);
        setSelectedApp(undefined);
    };

    const addPermissions = () => {
        if (user && !isEmpty(selectedPermissions)) {
            createUserPermissions(
                user.id,
                selectedPermissions.map((permission) => permission.permissionTag)
            )
                .then(() => {
                    d30Toast("Permissions added");
                    refresh();
                    _shutdown();
                })
                .catch((e) => d30ToastError("Problem adding user permissions", e));
        }
    };

    return (
        <Dialog
            PaperComponent={PaperComponent}
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"md"}
            fullWidth={true}
            onClose={(event, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                    _shutdown();
                }
            }}>
            <DialogTitle id="draggable-dialog-title">Add User Permissions</DialogTitle>
            <DialogContent style={{ display: "grid" }}>
                <br />
                <Typography>App:</Typography>
                <div
                    style={{
                        marginBottom: "10px",
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                    }}>
                    <Select<AppTypes>
                        noneLabel={`Select an App...`}
                        options={Object.keys(AppTypes) as AppTypes[]}
                        value={selectedApp}
                        onChange={setSelectedApp}
                        label={(value) => <>{`${UserPermissionAppType[value as AppType]}`}</>}
                    />
                </div>
                <Typography>Permissions:</Typography>
                <div
                    style={{
                        marginBottom: "10px",
                        overflowY: "scroll",
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                    }}>
                    <MultiSelect<IPermission>
                        style={{ textOverflow: "ellipsis", maxHeight: "1000px" }}
                        options={permissions}
                        value={selectedPermissions}
                        showSelectAll={true}
                        label={(permission: IPermission) => (
                            <>{`${AppTypeDescriptions[permission.app as AppType]}: ${permission.description}`}</>
                        )}
                        onChange={(values: IPermission[] | undefined) => {
                            setSelectedPermissions(values ?? []);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={() => _shutdown()}>
                    Cancel
                </Button>
                <Button
                    disabled={isEmpty(selectedPermissions)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        addPermissions();
                    }}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
