import { d30ToastError, useGlobalOptional } from "@davo/portal-common";
import {
    AF_COMPLETED,
    AF_ERRORED,
    AF_ERRORED_VALIDATION,
    AF_FILED,
    AF_OPEN,
    AF_TESTED,
    afTerminalState,
    AutofilerQueueEntry,
    FilingDetails,
} from "@davo/types";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownload";
import { Icon, Link, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { getAutoFilerPDF } from "../services";

const LargeTooltip = withStyles(() => ({
    tooltip: {
        fontSize: "14px",
    },
}))(Tooltip);

export interface IAutofilerProcessingDetailType {
    filing?: FilingDetails;
}

export function AutofilerProcessingDetail({ filing }: IAutofilerProcessingDetailType) {
    const [autoFilerMap] = useGlobalOptional<{ [key: string]: AutofilerQueueEntry }>("autoFilerMap");
    const [autoFilerStatus, setAutoFilerStatus] = useState<AutofilerQueueEntry>();

    useEffect(() => {
        if (filing && autoFilerMap && autoFilerMap[filing.id]) {
            setAutoFilerStatus(autoFilerMap[filing.id]);
        } else {
            setAutoFilerStatus(undefined);
        }
    }, [autoFilerMap]);

    const content = useMemo(() => {
        if (!filing) {
            return undefined;
        }

        let color = "#33cc33"; // green
        let icon;
        const newTooltip = [];
        if (!autoFilerStatus) {
            // orange
            color = "#ff9900";
            newTooltip.push(<div key={`${filing.id}-untested`}>{"untested"}</div>);
        } else if (autoFilerStatus && !afTerminalState(autoFilerStatus.state)) {
            // processing type states
            newTooltip.push(
                <div key={`${filing.id}-enqueued`}>{`${autoFilerStatus.test ? "Test" : "File"}: ${
                    autoFilerStatus.message || autoFilerStatus.state
                }`}</div>
            );
            if (autoFilerStatus.state === AF_OPEN) {
                // queued
                icon = "update";
            } else {
                // processing type states
                icon = "autorenew";
            }
        } else if (autoFilerStatus && autoFilerStatus.result) {
            if ([AF_COMPLETED].includes(autoFilerStatus.state)) {
                icon = "check_circle";
            } else if ([AF_FILED].includes(autoFilerStatus.state)) {
                // orange
                color = "#ff9900";
                icon = "check_circle";
                newTooltip.push([
                    <>
                        <div key={`${filing.id}-filing-error`}>
                            {"Autofiler has completed filing. Currently attempting to close in D30."}
                        </div>
                        <br />
                    </>,
                ]);
            } else if ([AF_ERRORED, AF_ERRORED_VALIDATION].includes(autoFilerStatus.state)) {
                // red
                color = "#ff0000";
                if (autoFilerStatus.state === AF_ERRORED_VALIDATION) {
                    // blue
                    color = "#1AA7EC";
                }
                newTooltip.push(
                    [...(autoFilerStatus.result.warnings || []), ...(autoFilerStatus.result.errors || [])].flatMap(
                        (w) =>
                            w.split("\n").map((x: string) => (
                                <>
                                    <div key={`${filing.id}-warning-error`}>{x}</div>
                                    <br />
                                </>
                            ))
                    )
                );
            } else if (autoFilerStatus.state === AF_TESTED && autoFilerStatus.result.warnings) {
                // yellow
                color = "#ffff00";
                newTooltip.push(
                    [...(autoFilerStatus.result.warnings || [])].map((w) => (
                        <>
                            <div key={`${filing.id}-warning`}>{w}</div>
                            <br />
                        </>
                    ))
                );
            }

            if (afTerminalState(autoFilerStatus.state) && !autoFilerStatus.result.errors) {
                newTooltip.push(
                    <div key={`${filing.id}-filing`}>
                        Tax Due: {autoFilerStatus.result.taxDue?.toFixed(2)}
                        {autoFilerStatus.result.discount && autoFilerStatus.result.discount !== 0 ? (
                            <>
                                <br />
                                Discount: {autoFilerStatus.result.discount?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {autoFilerStatus.result.fees && autoFilerStatus.result.fees !== 0 ? (
                            <>
                                <br />
                                Fees: {autoFilerStatus.result.fees?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {autoFilerStatus.result.remittance ? (
                            <>
                                <br />
                                Remittance: {autoFilerStatus.result.remittance?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {autoFilerStatus.result.remittancePart2 ? (
                            <>
                                <br />
                                Remittance Part 2: {autoFilerStatus.result.remittancePart2?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {autoFilerStatus.result.remittancePart3 ? (
                            <>
                                <br />
                                Remittance Part 3: {autoFilerStatus.result.remittancePart3?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {autoFilerStatus.result.remittancePart4 ? (
                            <>
                                <br />
                                Remittance Part 4: {autoFilerStatus.result.remittancePart4?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {autoFilerStatus.result.remittancePart5 ? (
                            <>
                                <br />
                                Remittance Part 5: {autoFilerStatus.result.remittancePart5?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {autoFilerStatus.result.outstanding && autoFilerStatus.result.outstanding !== 0 ? (
                            <>
                                <br />
                                Outstanding: {autoFilerStatus.result.outstanding?.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            }

            if (autoFilerStatus.result.detail && afTerminalState(autoFilerStatus.state)) {
                newTooltip.push(
                    <pre key={`${filing.id}-detail`} style={{ whiteSpace: "pre-wrap" }}>
                        {`Details: ${JSON.stringify(autoFilerStatus.result.detail, null, "    ")}`}
                    </pre>
                );
            }
        }

        if (autoFilerStatus?.result && autoFilerStatus.result.bulkTaskId && autoFilerStatus.result.bulkTaskStarted) {
            newTooltip.push(
                <div key={`${filing.id}-bulk`}>
                    <br />
                    <div>{`Bulk task started: ${DateTime.fromMillis(autoFilerStatus.result.bulkTaskStarted)
                        .setZone("America/New_York")
                        .toFormat("MM/dd/yyyy HH:mm:ss")}`}</div>
                    <div>{`Bulk task Id: ${autoFilerStatus.result.bulkTaskId}`}</div>
                </div>
            );
        }

        if (autoFilerStatus?.lastModified) {
            newTooltip.push(
                <div key={`${filing.id}-updated`}>
                    <br />
                    <div>{`Updated: ${DateTime.fromMillis(autoFilerStatus.lastModified)
                        .setZone("America/New_York")
                        .toFormat("MM/dd/yyyy HH:mm:ss")}`}</div>
                </div>
            );
        }

        if (filing.taxProfile.filingNote && !isEmpty(filing.taxProfile.filingNote)) {
            newTooltip.push(
                <div key={`${filing.id}-note`}>
                    <br />
                    <div>{`Filing Note: ${filing.taxProfile.filingNote}`}</div>
                </div>
            );
        }
        return { color, icon, tooltip: newTooltip };
    }, [autoFilerStatus]);

    if (!filing || !filing.canAutofile || !content) {
        return null;
    }

    const downloadAutoFilerErrorFile = async (errorKeys?: string[] | null, confirmationKeys?: string[] | null) => {
        if (!errorKeys && !confirmationKeys) {
            return;
        }

        const keysToDownload = [...(errorKeys || []), ...(confirmationKeys || [])];
        for (const key of keysToDownload) {
            try {
                const file = await getAutoFilerPDF(filing.id, key);
                const a = document.createElement("a");
                a.href = URL.createObjectURL(file);
                const dlFilename = `${key}_${filing.id}_${filing.taxProfile.legalName}_${filing.periodEnd}`;
                a.download = dlFilename.replace(/\W/g, "");
                a.click();
                URL.revokeObjectURL(a.href);
            } catch (e) {
                d30ToastError(`${e}`);
                console.log(e); // eslint-disable-line no-console
            }
        }
    };

    return (
        <div style={{ display: "inline-flex" }} key={`autofiler-detail-${filing.id}`}>
            {content.icon && (
                <LargeTooltip title={content.tooltip} placement="bottom-end">
                    <Icon fontSize="small" style={{ color: content.color, fontSize: "25px", marginLeft: "2px" }}>
                        {content.icon}
                    </Icon>
                </LargeTooltip>
            )}
            {!content.icon && (
                <LargeTooltip title={content.tooltip} placement="bottom-end">
                    <div
                        style={{
                            backgroundColor: content.color,
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            margin: "2px",
                            marginLeft: "5px",
                            display: "inline-block",
                        }}
                    />
                </LargeTooltip>
            )}

            {autoFilerStatus &&
                [AF_ERRORED, AF_ERRORED_VALIDATION, AF_TESTED].includes(autoFilerStatus.state) &&
                autoFilerStatus.result &&
                (!isEmpty(autoFilerStatus.result.errorPDFs) || !isEmpty(autoFilerStatus.result.pdfs)) && (
                    <Link
                        download
                        target="_blank"
                        onClick={async () =>
                            downloadAutoFilerErrorFile(
                                autoFilerStatus?.result?.errorPDFs,
                                autoFilerStatus?.result?.pdfs
                            )
                        }>
                        <Typography style={{ cursor: "pointer" }}>
                            <CloudDownloadTwoToneIcon color="primary" />
                        </Typography>
                    </Link>
                )}
        </div>
    );
}

AutofilerProcessingDetail.displayName = "AutofilerProcessingDetail";
