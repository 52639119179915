import { d30Toast, d30ToastError, useGlobalOptional } from "@davo/portal-common";
import { AutofilerQueueEntry, FilingDetails, truncateString } from "@davo/types";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUpload";
import React, { useCallback, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { isAutofilerProcessing } from "./filing/AutofileUtils";
import { addFilingAttachment } from "./services";

export interface IFilingAttachmentUpload {
    filing: FilingDetails;
    isEditingFiling: boolean;
    isAmendingFiling: boolean;
    onUpload: () => void;
}

export function FilingAttachmentUpload({
    filing,
    isEditingFiling,
    isAmendingFiling,
    onUpload,
}: IFilingAttachmentUpload) {
    const [autoFilerMap] = useGlobalOptional<{ [key: string]: AutofilerQueueEntry }>("autoFilerMap");
    const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});

    const uploadFile = useCallback(
        (file: File) => {
            setUploading({ ...uploading, [file.name]: true });
            addFilingAttachment(filing.id, file)
                .then(() => {
                    onUpload();
                    d30Toast("Upload complete: " + file.name);
                })
                .catch((e) => {
                    d30ToastError("Error uploading: " + file.name, e);
                })
                .finally(() => setUploading({ ...uploading, [file.name]: false }));
        },
        [uploading, setUploading, filing.id, onUpload]
    );

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (Array.isArray(acceptedFiles)) {
                acceptedFiles.forEach(uploadFile);
            }
        },
        [uploadFile]
    );

    const maxSize = 25 * 1024 * 1024; // 25 MB
    const acceptedFileTypes = { "application/pdf": [".pdf"] };

    if (
        (autoFilerMap && isAutofilerProcessing(autoFilerMap[filing.id])) ||
        !(filing.status === "open" || isEditingFiling || isAmendingFiling)
    ) {
        return null;
    }

    return (
        <>
            <div style={{ alignItems: "center" }}>
                <Dropzone
                    onDrop={onDrop}
                    maxSize={maxSize}
                    maxFiles={10}
                    accept={acceptedFileTypes}
                    onDropRejected={(files: FileRejection[]) => {
                        const fileNames = files.map((f) => f.file.name).join(", ");
                        d30ToastError(`Unable to upload ${fileNames}. Ensure it's a PDF file and less than 25 MB.`);
                    }}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <section>
                            <div
                                style={{
                                    borderRadius: "50%",
                                    border: "2px dashed blue",
                                    width: "150px",
                                    height: "150px",
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    textAlign: "center",
                                }}
                                {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                    {isDragActive
                                        ? "Drop now!"
                                        : "Drag 'n' drop attachments here, or click to select files"}
                                </p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
            <div style={{ alignItems: "center" }}>
                {Object.keys(uploading).map((f, i) =>
                    uploading[f] ? (
                        <div key={i}>
                            <CloudUploadTwoToneIcon color="primary" style={{ verticalAlign: "middle" }} />
                            {truncateString(f, 30)}
                        </div>
                    ) : null
                )}
            </div>
        </>
    );
}
