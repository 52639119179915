import { d30Toast, d30ToastError, PaperComponent, ReactTable, TextField } from "@davo/portal-common";
import { BankAccountWithAccount, OptionalString } from "@davo/types";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import useAsyncEffect from "use-async-effect";
import { useOpsPortalConfigContext } from "./context";
import { getBankAccountWithAccount, updateBankAccountAchInfo } from "./services";

const trim = (s: string | undefined) => {
    return s ? s.trim() : "";
};

const validateAllFields = (achFirstName: OptionalString, achLastName: OptionalString) => {
    return !!trim(achFirstName ?? "") && !!trim(achLastName ?? "");
};

export function BankAccountCredentialsButton({ bankAccountId }: { bankAccountId?: string }) {
    // Some banks are not allowing us to return funding and/or refund billings to these accounts passing business name in the first/last name fields. It requires the owner first/last name in order to complete. This array is a list of
    // routing number overrides to capture this information.
    // Sutton Bank and Central National Bank & Trust are the two that we know about
    const { opsPortalConfigInfo: configInfo } = useOpsPortalConfigContext();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [showAchInfo, setShowingAchInfo] = useState<boolean>(false);
    const [bankAccount, setBankAccount] = useState<BankAccountWithAccount>();
    const [achFirstName, setAchFirstName] = useState<OptionalString>();
    const [achLastName, setAchLastName] = useState<OptionalString>();

    useAsyncEffect(async () => {
        setBankAccount(undefined);
        setShowingAchInfo(false);
        setIsBusy(false);
        if (bankAccountId && isShowing) {
            const direct = await getBankAccountWithAccount(bankAccountId);
            setBankAccount(direct);
        } else if (!isShowing) {
            return;
        } else {
            throw new Error("Bank account Id is required!");
        }
    }, [isShowing, bankAccountId]);

    useEffect(() => {
        if (bankAccount) {
            setShowingAchInfo(configInfo.achOverrideRoutingNumbers.includes(bankAccount?.routing));
            setAchFirstName(bankAccount.achFirstName);
            setAchLastName(bankAccount.achLastName);
        }
    }, [bankAccount, configInfo]);

    const doUpdate = useCallback(async () => {
        if (!validateAllFields(achFirstName, achLastName)) {
            return;
        }
        setIsBusy(true);
        try {
            if (bankAccount) {
                await updateBankAccountAchInfo(bankAccount.id, achFirstName, achLastName);
                d30Toast("Bank account updated!");
                setIsShowing(false);
            }
        } catch (e: any) {
            d30ToastError(e.message);
            setIsBusy(false);
            throw e;
        }
    }, [achFirstName, achLastName, bankAccount]);

    const columns = useMemo(() => {
        return [
            {
                Header: "",
                accessor: "title",
            },
            {
                Header: "",
                accessor: "value",
                Cell: (data: any) => (
                    <>
                        {data.value}&nbsp;&nbsp;
                        {!!data.value && (
                            <CopyToClipboard text={data.value} onCopy={() => d30Toast("Copied")}>
                                <FileCopyTwoToneIcon
                                    fontSize="small"
                                    color="primary"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </CopyToClipboard>
                        )}
                    </>
                ),
            },
            {
                Header: "",
                accessor: "nickname",
            },
        ];
    }, []);

    return (
        <div style={{ marginRight: "10px", marginTop: "5px" }}>
            <Button variant="outlined" color="primary" size={"small"} onClick={() => setIsShowing(!isShowing)}>
                Banking Info
            </Button>
            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                        setAchFirstName(undefined);
                        setAchLastName(undefined);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Bank Account Credentials</DialogTitle>
                    <DialogContent>
                        <ReactTable
                            columns={columns}
                            data={[
                                { title: "Routing", value: bankAccount?.routing },
                                { title: "Account Number", value: bankAccount?.account },
                                { title: "Nickname", value: bankAccount?.nickname },
                            ]}
                            options={{
                                hideToolbar: true,
                                hideHeader: true,
                                pageSize: 3,
                                pageSizeOptions: [3],
                            }}
                        />

                        {showAchInfo && (
                            <div>
                                <TextField
                                    label="ACH First Name"
                                    value={achFirstName ?? ""}
                                    onChange={setAchFirstName}
                                    isDisabled={isBusy}
                                    isRequired
                                />
                                <TextField
                                    label="ACH Last Name"
                                    value={achLastName ?? ""}
                                    onChange={setAchLastName}
                                    isDisabled={isBusy}
                                    isRequired
                                />
                                {(typeof achFirstName === "undefined" ||
                                    trim(achFirstName ?? "") === "" ||
                                    typeof achLastName === "undefined" ||
                                    trim(achLastName ?? "") === "") && (
                                    <div style={{ width: "100%" }}>
                                        <Tooltip title={`ACH Account Information Required.`}>
                                            <Alert
                                                style={{ marginRight: "10px", marginTop: "5px", width: "100%" }}
                                                severity="error">
                                                A few banks (such as Sutton Bank) are not allowing us to return funding
                                                and/or refund billings to these accounts passing business name in the
                                                first/last name fields. This routing number corresponds to one of those
                                                banks. We have found we need the owner&apos;s first/last name in order
                                                to complete these operations.
                                            </Alert>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <div style={{ textAlign: "right" }}>
                            <Button
                                disabled={isBusy}
                                onClick={() => setIsShowing(!isShowing)}
                                variant="contained"
                                color="primary"
                                style={{ marginRight: "10px" }}>
                                Cancel
                            </Button>
                            {showAchInfo ? (
                                <Button
                                    disabled={isBusy || !validateAllFields(achFirstName, achFirstName)}
                                    onClick={doUpdate}
                                    variant="contained"
                                    color="primary"
                                    autoFocus>
                                    Save
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
