import { Select } from "@davo/portal-common";
import { Filing, FilingFrequency, FrequencyLabels, TaxProfile } from "@davo/types";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getFrequencyOptionsForFilingAccounting } from "./services";

export interface IFilingFrequencySelector {
    onSelect: (filingFrequency: FilingFrequency) => void;
    taxProfiles?: TaxProfile[];
    filing?: Filing;
    defaultFrequency: FilingFrequency;
}

export const FilingFrequencySelector: React.FunctionComponent<IFilingFrequencySelector> = ({
    taxProfiles,
    onSelect,
    filing,
    defaultFrequency,
}) => {
    const [frequency, setFrequency] = useState<FilingFrequency>();
    const [frequencyOptions, setFrequencyOptions] = useState<FilingFrequency[]>(["month"]);

    useAsyncEffect(async () => {
        setFrequency(defaultFrequency);
        if (taxProfiles) {
            const fOptions = Array.from(new Set<FilingFrequency>(taxProfiles.map((x: TaxProfile) => x.frequency)));
            setFrequencyOptions(fOptions);
        } else {
            if (filing) {
                const additionalFrequencyOptions = await getFrequencyOptionsForFilingAccounting(filing.id);
                setFrequencyOptions(additionalFrequencyOptions);
            } else {
                return;
            }
        }
    }, [taxProfiles, filing]);

    const changeFrequency = (filingFrequency: FilingFrequency) => {
        setFrequency(filingFrequency);
        onSelect(filingFrequency);
    };

    if (frequencyOptions && frequencyOptions.length > 1) {
        return (
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <Select<FilingFrequency>
                    data-testid="filingFrequencySelect"
                    title="Filing Frequency"
                    options={frequencyOptions}
                    value={frequency}
                    onChange={(freq) => {
                        if (!isNil(freq)) {
                            changeFrequency(freq);
                        }
                    }}
                    label={(f: string) => {
                        if (f.startsWith("Ops-only: ")) {
                            return "Ops-only: " + FrequencyLabels[f.substring(10)].toString();
                        } else {
                            return FrequencyLabels[f];
                        }
                    }}
                />
            </div>
        );
    } else {
        return null;
    }
};
