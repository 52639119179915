import { getAttachmentHistorical, getTaxProfile, Loading, PaperComponent } from "@davo/portal-common";
import {
    FilingHistoricalAttachment,
    FilingHistoricalWithAttachments,
    money,
    toDisplayDateTimeString,
} from "@davo/types";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownload";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { FieldList } from "./FieldList";
import { getHistoricalFiling } from "./services";

export function HistoricalFilingDetailModal({
    target,
    closeDialog,
}: {
    target?: { filingId: string; accountId: string };
    closeDialog: (target?: { filingId: string; accountId: string }) => void;
}) {
    const [filing, setFiling] = useState<FilingHistoricalWithAttachments>();

    useAsyncEffect(async () => {
        if (target) {
            setFiling(await getHistoricalFiling(target.filingId));
        }
    }, [target]);

    const shutdown = (result?: { filingId: string; accountId: string }) => {
        setFiling(undefined);
        closeDialog(result);
    };

    const downloadFileHistorical = async (attachment: FilingHistoricalAttachment) => {
        if (target && filing) {
            const file = await getAttachmentHistorical(target.accountId, filing.taxProfileId, filing.id, attachment.id);
            const taxProfile = await getTaxProfile(target.accountId, filing.taxProfileId);
            const a = document.createElement("a");
            a.href = URL.createObjectURL(file);
            a.download = `attachment-${filing.filedDate}-${taxProfile.legalName}-${taxProfile.name}-${taxProfile.state}-${filing.periodEnd}`;
            a.click();
            URL.revokeObjectURL(a.href);
        }
    };

    return (
        <Dialog
            fullScreen={false}
            open={true}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            style={{ boxShadow: "none" }}
            onClose={() => {
                shutdown();
            }}>
            <DialogTitle id="draggable-dialog-title">Historical Filing Details</DialogTitle>
            <DialogContent>
                {!filing && <Loading />}
                {filing && (
                    <div>
                        <FieldList
                            object={{
                                filedOn: filing.filedDate ? toDisplayDateTimeString(filing.filedDate) : "",
                                jurisdiction: filing.jurisdiction,
                                amountRemitted: money((filing.remitted || 0) / 100),
                                discount: money((filing.discount || 0) / 100),
                                note: filing.note ?? "",
                                merchantNote: filing.merchantNote ?? "",
                            }}
                        />
                        {filing.attachments.length > 0 && (
                            <div style={{ marginTop: "40px" }}>
                                {"Attachments: "}
                                {filing.attachments
                                    .filter((x) => !!x.filename || !!x.response)
                                    .map((attachment) => (
                                        <div
                                            key={attachment.id}
                                            style={{
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                padding: "5px",
                                            }}>
                                            <Link
                                                download
                                                target="_blank"
                                                onClick={async () => downloadFileHistorical(attachment)}>
                                                <Typography style={{ marginRight: "4px", cursor: "pointer" }}>
                                                    <CloudDownloadTwoToneIcon color="primary" />
                                                </Typography>
                                            </Link>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => shutdown()} variant="outlined" color="primary" style={{ width: "100px" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
