import { d30ToastError, ReactTable } from "@davo/portal-common";
import { moneyFromCents } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useEffect, useMemo, useState } from "react";
import { useFilingDetailPaneContext } from "../context/FilingDetailPaneContext";
import { getTotalNonTaxableByLocation } from "../services";

export function NonTaxableByLocationAccordion() {
    const { locationsIncludedInFiling, filingId, partner } = useFilingDetailPaneContext();

    const [locationsWithNames, setLocationsWithNames] = useState<{
        locationId: string;
        locationName: string;
        sales: number;
        partnerName?: string;
    }>();

    useEffect(() => {
        const locs = locationsIncludedInFiling.map((f) => f.id);
        if (locs.length > 0) {
            getTotalNonTaxableByLocation(locs, filingId)
                .then((nontax) => {
                    if (nontax.length > 0) {
                        const newArr = nontax.map((obj: { sales: number; locationId: string }) => ({
                            ...obj,
                            locationName: locationsIncludedInFiling.find((f) => f.id === obj.locationId)?.name,
                            partnerName: partner?.name,
                        }));
                        setLocationsWithNames(newArr);
                    }
                })
                .catch((e) => d30ToastError("Problem loading data", e));
        }
    }, [filingId, locationsIncludedInFiling, partner]);

    const locationColumns = useMemo(() => {
        return [
            {
                Header: "Location",
                accessor: "locationName",
                Cell: (data: any) => data.value,
            },
            {
                Header: "Partner",
                accessor: "partnerName",
                Cell: (data: any) => data.value,
            },
            {
                Header: "Sales",
                accessor: "sales",
                Cell: (data: any) => <>${moneyFromCents(data.value)}</>,
            },
        ];
    }, []);

    if (isNil(locationsWithNames)) {
        return null;
    }

    return (
        <Accordion
            slotProps={{ transition: { unmountOnExit: true } }}
            style={{ border: "none", boxShadow: "none" }}
            defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                Non-Taxable By Location Breakout
            </AccordionSummary>
            <AccordionDetails style={{ display: "flex", justifyContent: "space-around" }}>
                <ReactTable
                    columns={locationColumns}
                    data={locationsWithNames}
                    options={{
                        hideToolbar: true,
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
}
