import { d30Toast, useGlobalOptional } from "@davo/portal-common";
import { AuthCodes } from "@davo/types";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

export function AuthCode({ jurisdiction }: { jurisdiction: string }) {
    const [authCodeMap] = useGlobalOptional<AuthCodes>("authCodeMap");
    const [authCode, setAuthCode] = useState<string>();

    useEffect(() => {
        if (authCodeMap) {
            setAuthCode(authCodeMap[jurisdiction]);
        }
    }, [jurisdiction, authCodeMap]);

    return (
        authCode && (
            <div style={{ display: "flex", justifyContent: "right", margin: "10px" }}>
                <Typography>
                    <>
                        Auth code: <br />{" "}
                    </>
                    {authCode && (
                        <span data-testid={"authcode"}>
                            {authCode}{" "}
                            <CopyToClipboard text={authCode} onCopy={() => d30Toast("Copied")}>
                                <FileCopyTwoToneIcon
                                    fontSize="small"
                                    color="primary"
                                    style={{ verticalAlign: "top" }}
                                />
                            </CopyToClipboard>
                            <br />
                        </span>
                    )}
                </Typography>
            </div>
        )
    );
}
