import { d30Toast, d30ToastError, getJurisdictions, Select, TextField } from "@davo/portal-common";
import { IGroupLogin, IJurisdiction } from "@davo/types";
import { Button } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getGroupCredentialsByState, getStatesWithActiveGroupLogins, setGroupCredentials } from "./services";

export function GroupCredentials() {
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [selectedState, setSelectedState] = useState<IJurisdiction | undefined>();
    const [originCode, setOriginCode] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [pin, setPin] = useState<string | undefined>(undefined);

    useAsyncEffect(async () => {
        const jurisdictionsPromise = getJurisdictions();
        const statesWithActiveGroupLoginsPromise = getStatesWithActiveGroupLogins();

        const [jurisdictions, statesWithActiveGroupLogins] = await Promise.all([
            jurisdictionsPromise,
            statesWithActiveGroupLoginsPromise,
        ]);
        setStates(jurisdictions.filter((jur) => statesWithActiveGroupLogins.includes(jur.abbreviatedName)));
    }, []);

    useAsyncEffect(() => {
        if (!selectedState) {
            return;
        }

        const groupLoginByState = getGroupCredentialsByState(selectedState.abbreviatedName);
        groupLoginByState
            .then((groupLogin) => {
                setOriginCode(groupLogin?.originCode);
                setUsername(groupLogin?.username);
                setPassword(groupLogin?.password);
                setPin(groupLogin?.pin);
            })
            .catch((e) => d30ToastError(`Problem with retrieving group credentials for ${selectedState.fullName}.`, e));
    }, [selectedState]);

    const doCancel = () => {
        setOriginCode(undefined);
        setSelectedState(undefined);
        setUsername(undefined);
        setPassword(undefined);
        setPin(undefined);
    };

    const updateGroupCredentials = () => {
        if (!originCode) return;

        const updatedCredentials: Omit<IGroupLogin, "id" | "created" | "updated"> = {
            originCode: originCode,
            password: password,
            username: username,
            pin: pin,
        };
        setGroupCredentials(updatedCredentials)
            .then(() => {
                doCancel();
                d30Toast("Group Credentials Update Complete");
            })
            .catch((e) => {
                d30ToastError("Error while updating the group credentials", e);
            });
    };

    return (
        <div style={{ width: "100%" }}>
            <Select<IJurisdiction>
                style={{ marginBottom: "20px" }}
                data-testid={"groupCredentialsState"}
                title="State"
                options={states}
                value={selectedState}
                noneLabel="Select a state..."
                label={(jurisdiction) => jurisdiction.fullName}
                onChange={(s) => {
                    setSelectedState(s);
                }}
                isRequired
            />

            {selectedState && (
                <>
                    <TextField
                        data-testid={"groupCredentialsUserName"}
                        label={"Username"}
                        value={username ?? ""}
                        onChange={setUsername}></TextField>
                    <TextField
                        data-testid={"groupCredentialsPassword"}
                        label={"Password"}
                        value={password ?? ""}
                        onChange={setPassword}></TextField>
                    <TextField
                        data-testid={"groupCredentialsUserPin"}
                        label={"Pin"}
                        value={pin ?? ""}
                        onChange={setPin}></TextField>
                    <br />
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                        <Button
                            data-testid={"groupCredentialsCancelButton"}
                            onClick={doCancel}
                            variant="outlined"
                            color="primary"
                            style={{ width: "100px", marginRight: "5px" }}>
                            Cancel
                        </Button>
                        <Button
                            data-testid={"groupCredentialsSaveButton"}
                            onClick={() => {
                                updateGroupCredentials();
                            }}
                            disabled={!selectedState || isNil(originCode) || !username || !password}
                            variant="contained"
                            color="primary"
                            style={{ width: "150px" }}>
                            Save
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}
