import { d30Toast, d30ToastError, NumberField, TextField } from "@davo/portal-common";
import { BusinessDay, money, noop } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { periodTransfer } from "./services";

export function ChangePeriodFundsButton({
    originDay,
    locationId,
    davoBalance,
    merchantBalance,
    refresh = noop,
}: {
    originDay: BusinessDay;
    locationId: string;
    davoBalance: number;
    merchantBalance: number;
    refresh?: () => void;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [note, setNote] = useState<string>("");
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [destinationDay, setDestinationDay] = useState<DateTime | null>(DateTime.now());

    // watch the signs here - these are asset account balances so negative means a positive balance
    const max = Math.max(-davoBalance / 100, merchantBalance / 100);

    const show = () => {
        setAmount(undefined);
        setNote("");
        setIsShowing(true);
    };

    const validateAmount = (amt: number | undefined) => {
        if (amt && amt > max) {
            return `Cannot move more than ${money(max / 100)}`;
        }
        return undefined;
    };

    const doMove = () => {
        if (amount && originDay && destinationDay) {
            setIsBusy(true);
            periodTransfer(
                locationId,
                Math.round(amount * 100),
                originDay,
                BusinessDay.fromDateTime(destinationDay),
                note
            )
                .then(() => {
                    d30Toast(`${money(amount)} move succeeded.`);
                    refresh();
                    setIsShowing(false);
                })
                .catch((e) => d30ToastError("Problem moving funds.", e))
                .finally(() => setIsBusy(false));
        }
    };

    return (
        <>
            <Button variant="outlined" onClick={show} size="small" color="primary" sx={{ width: "174px" }}>
                Change Period Funds
            </Button>
            {isShowing && (
                <Dialog open={true}>
                    <DialogTitle>Change period funds</DialogTitle>
                    <DialogContent>
                        <Typography>Maximum: {money(max)}</Typography>
                        <NumberField
                            label={`Amount to move`}
                            value={amount}
                            onChange={setAmount}
                            validate={validateAmount}
                        />
                        <TextField label={"Note"} value={note} onChange={setNote} />
                        <Typography>Origin Day: {originDay.toString()}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Destination Date"
                                value={destinationDay?.toJSDate()}
                                closeOnSelect={true}
                                onChange={(x) => {
                                    x && setDestinationDay(DateTime.fromJSDate(x));
                                }}
                                format="MM/dd/yyyy"
                                minDate={DateTime.now().minus({ years: 4 }).toJSDate()}
                                maxDate={DateTime.now().plus({ years: 1 }).toJSDate()}
                                slotProps={{
                                    textField: { style: { width: "100%", margin: "20px 0" } },
                                    toolbar: { hidden: true },
                                }}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !amount || !!validateAmount(amount)}
                            onClick={doMove}>
                            Move Funds
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
