import { apiUrl, del, get, getArray, getOne, post, put } from "@davo/portal-common";
import {
    BUSINESS_DAY,
    BusinessDay,
    DailyDetail,
    DryRunResults,
    FilingCalculation,
    FilingClose,
    FilingDetails,
    FilingFrequency,
    FilingPenalty,
    FilingPenaltyExtension,
    FilingRemittedField,
    FilingStatus,
    FilingSummary,
    FilingTaxProfileTaxRate,
    FilingUpdate,
    FilingUpdateAuditType,
    FilingWithAccount,
    IFilingOptionsFilters,
    IJurisdiction,
    IMarketplaceFacilitatorJurisdictionJoin,
    INotice,
    IWellsFargoTransactionInfo,
    InternalSummary,
    Location,
    MetaFilingClose,
    MetaFilingDetails,
    MetaFilingHistoricalWithAttachments,
    MetaFilingPenaltyExtension,
    MetaFilingTaxProfileTaxRate,
    MetaFilingUpdate,
    MetaLocation,
    OptionalString,
    PeriodReport,
    STRING,
    TaxProfileFilingMethod,
    UUID,
    User,
    fromJSON,
    toJSON,
} from "@davo/types";
import { DateTime } from "luxon";

export async function getFilingHistoryAudit(filingId: string) {
    return get<FilingUpdateAuditType & { user: User }[]>(`api/ops/filings/${filingId}/history/audit`, true);
}

export async function getRecentFilings() {
    return get(`api/ops/filings/recent`, true, {});
}

export async function getReadyAndUpcomingFilingsForFilter(
    filter: {
        userFilter?: string;
        state?: string;
        dueDate?: string;
        accountOwner?: string;
        autofilerStatus?: string | null;
        filingMethod?: TaxProfileFilingMethod;
        jurisdictionFilingTypeId?: string;
    },
    abortController?: AbortController
) {
    const res = await post("api/ops/filings/filter", filter, true, undefined, abortController);
    return res.map((x: FilingDetails) => fromJSON(MetaFilingDetails, x));
}

export async function getDueDatesForReadyFilings() {
    return getArray("api/ops/filings/all/dueDates", true, {
        dueDate: BUSINESS_DAY(),
    });
}

export async function getJurisdictionsForReadyFilings() {
    return getArray("api/ops/filings/all/jurisdictions", true, {
        code: STRING<string>(),
    });
}

export async function getMarketplaceFacilitatorRules(jurisdictionId: string) {
    return get<IMarketplaceFacilitatorJurisdictionJoin[]>(
        `api/common/jurisdictions/${jurisdictionId}/marketplace-facilitator-rules`,
        true
    );
}

export async function getOpenFilingsForAccount(
    accountId: string,
    abortController?: AbortController
): Promise<FilingDetails[]> {
    return getArray(`api/ops/filings/account/${accountId}/open`, true, MetaFilingDetails, abortController);
}

export async function getAllFilingsForAccount(
    accountId: string,
    abortController?: AbortController
): Promise<FilingDetails[]> {
    return getArray(`api/ops/filings/account/${accountId}`, true, MetaFilingDetails, abortController);
}

export async function postGetAllFilingsForAccountWithFilter(
    accountId: string,
    filters?: IFilingOptionsFilters,
    abortController?: AbortController
) {
    return post<FilingDetails[]>(
        `api/ops/filings/account/${accountId}`,
        {
            taxProfileId: filters?.taxProfile,
            state: filters?.state,
            start: filters?.periodFilter?.start,
            end: filters?.periodFilter?.end,
        },
        true,
        abortController
    );
}

export async function getNumberOfOpenFilingsForDisconnectedLocation(locationId: string): Promise<number> {
    return get(`api/ops/filings/location/${locationId}/openForDisconnected`, true);
}

export async function closeFilingsForDisconnectedLocation(locationId: string) {
    return put(`api/ops/filings/location/${locationId}/closeForDisconnected`, {}, true);
}

export async function generateFilingForTaxProfile(taxProfileId: string, periodDay: string) {
    return put(`api/ops/filings/generate/${taxProfileId}`, { periodDay }, true);
}

export async function getFiling(filingId: string) {
    return getOne(`api/ops/filings/${filingId}`, true, MetaFilingDetails);
}

export async function getNestedRemitted(
    filingId: string
): Promise<{ locationId: string; nestedTaxRemitted: number; nestedAdjustment: number; nestedDiscount: number }[]> {
    return get(`api/ops/filings/${filingId}/nested-remitted`, true);
}

export async function getTotalPrepayment(filingId: string) {
    return get(`api/ops/filings/${filingId}/total-prepayment`, true);
}

export async function getFirstFilingInfo(filingId: string) {
    return get<{ first: boolean; prepayment?: boolean }>(`api/ops/filings/${filingId}/first-filing`, true);
}

export async function getStateTaxIdDuplicates(filingId: string) {
    return get(`api/ops/filings/${filingId}/state-tax-id-duplicates`, true);
}

export async function getFEINDuplicates(filingId: string) {
    return get(`api/ops/filings/${filingId}/fein-duplicates`, true);
}

export async function getHistoricalFiling(filingId: string) {
    return getOne(`api/ops/filings/historical/${filingId}`, true, MetaFilingHistoricalWithAttachments);
}

export async function getFilingSummary(
    filingId: string,
    selectedLocation?: string
): Promise<{ dailyDetails: DailyDetail[]; summary: InternalSummary }> {
    return post(`api/ops/filings/${filingId}/summary`, { selectedLocation }, true);
}

export async function resendFilingConfirmation(filingId: string) {
    await post<void>(`api/ops/filings/${filingId}/resendConfirmation`, {}, true);
}

export async function getLocationsForFiling(filingId: string): Promise<Location[]> {
    return getArray(`api/ops/filings/${filingId}/locations`, true, MetaLocation);
}

export async function getPenaltiesForFiling(filingId: string): Promise<FilingPenaltyExtension[]> {
    return getArray(`api/ops/filings/${filingId}/penalties`, true, MetaFilingPenaltyExtension);
}

export async function getOverlappingFilingsForFiling(filingId: string): Promise<
    {
        id: string;
        periodStart: BusinessDay;
        periodEnd: BusinessDay;
        frequency: FilingFrequency;
        status: FilingStatus;
        tpName: string;
        tpState: string;
        jurisdictionFilingTypeId?: string | null;
        jurisdictionFilingTypeName?: string | null;
    }[]
> {
    return getArray(`api/ops/filings/${filingId}/overlapping`, true, {
        id: STRING<string>(),
        periodStart: BUSINESS_DAY(),
        periodEnd: BUSINESS_DAY(),
        frequency: STRING<FilingFrequency>(),
        status: STRING<FilingStatus>(),
        tpName: STRING<string>(),
        tpState: STRING<string>(),
        jurisdictionFilingTypeId: STRING<string>().optional(),
        jurisdictionFilingTypeName: STRING<string>().optional(),
    });
}

export async function getNextMonthFundingForExcessCheck(filingId: string) {
    return get(`api/ops/filings/${filingId}/next-month-excess`, true);
}

export async function markPenaltyReviewed(filingId: string, penaltyId: string) {
    await put(`api/ops/filings/${filingId}/penalties/${penaltyId}/markReviewed`, {}, true);
}

export async function deletePenalty(filingId: string, penaltyId: string) {
    await del(`api/ops/filings/${filingId}/penalties/${penaltyId}/delete`, true, {});
}

export async function addNewPenalty(
    filingId: string,
    data: Partial<Omit<FilingPenalty, "id" | "filingId" | "updatedBy" | "updated" | "createdBy" | "created">>
) {
    await post(`api/ops/filings/${filingId}/penalties`, { ...data }, true);
}

export async function editExistingPenalty(
    filingId: string,
    penaltyId: string,
    data: Partial<Omit<FilingPenalty, "id" | "filingId" | "updatedBy" | "updated" | "createdBy" | "created">>
) {
    await put(`api/ops/filings/${filingId}/penalties/${penaltyId}`, { ...data }, true);
}

export async function getNoticesForPenalty(filingId: string, penaltyId: string) {
    return get<(INotice & { downloadReady: boolean })[]>(
        `api/ops/filings/${filingId}/penalties/${penaltyId}/notices/associated`,
        true
    );
}

export async function getPossibleNoticesForPenalty(filingId: string, penaltyId: string) {
    return get<(INotice & { downloadReady: boolean })[]>(
        `api/ops/filings/${filingId}/penalties/${penaltyId}/notices/possible`,
        true
    );
}

export async function getTaxProfilePriorOpenFilings(filingId: string): Promise<boolean> {
    return get(`api/ops/filings/${filingId}/prior-open-filings`, true);
}

export async function getFilingTaxProfileHasOtherFilingsRequiringAmendment(filingId: string): Promise<FilingSummary[]> {
    return get(`api/ops/filings/${filingId}/other-filings-needing-amendment`, true);
}

export async function getFilingCalculation(filingId: string, isAmendingOrEditing: boolean): Promise<FilingCalculation> {
    return post<FilingCalculation>(`api/ops/filings/${filingId}/calculate`, { isAmendingOrEditing }, true);
}

export async function getFilingTaxProfileTaxRates(filingId: string): Promise<FilingTaxProfileTaxRate[]> {
    return getArray(`api/ops/filings/${filingId}/savedRates`, true, MetaFilingTaxProfileTaxRate);
}

export async function getTotalNonTaxableByLocation(locationIds: string[], filingId: string) {
    return post(`api/ops/filings/${filingId}/nontaxable`, { locationIds }, true);
}

export async function updateFiling(filingId: string, update: FilingUpdate) {
    await put(`api/ops/filings/${filingId}/update`, toJSON(MetaFilingUpdate, update), true);
}

export async function closeFiling(filingId: string, update: FilingClose, force?: boolean) {
    await put(`api/ops/filings/${filingId}/close`, { ...toJSON(MetaFilingClose, update), force }, true);
}

export async function dryRunFilingClose(filingId: string, update: FilingClose, force?: boolean) {
    return put<DryRunResults>(
        `api/ops/filings/${filingId}/dryRunClose`,
        { ...toJSON(MetaFilingClose, update), force },
        true
    );
}

export async function sendBackDiscountForFiling(filingId: string) {
    await put(`api/ops/filings/${filingId}/discount`, {}, true);
}

export async function regenerateFiling(filingId: string) {
    await post(`api/ops/filings/${filingId}/regenerate`, {}, true);
}

export async function reOpenFiling(filingId: string, userId: string, reason: string) {
    await put(`api/ops/filings/${filingId}/reopen`, { filingId, userId, reason }, true);
}

export async function pullBackFundsForUnfundedRemittanceForFiling(filingId: string) {
    await put(`api/ops/filings/${filingId}/pullBackForUnfundedRemittance`, {}, true);
}

export async function instantReconcileFiling(filingId: string) {
    await put(`api/ops/filings/${filingId}/instantReconcile`, {}, true);
}

export async function trueUpFiling(filingId: string) {
    await put(`api/ops/filings/${filingId}/trueUp`, {}, true);
}

export async function deleteFiling(filingId: string) {
    await del(`api/ops/filings/${filingId}/delete`, true, {});
}

export async function getAssigneesForOpenFilings() {
    return getArray(`api/ops/filings/assignees/open`, true, {
        id: UUID(),
        name: STRING<string>(),
    });
}

export async function getAccountOwnersForOpenFilings() {
    return getArray(`api/ops/filings/owners/open`, true, {
        id: UUID(),
        name: STRING<string>(),
    });
}

export async function updateFilingAssignee(
    filingId: string,
    newAssigneeId: string | undefined,
    allowPostClosedOperation?: boolean
) {
    await put(
        `api/ops/filings/${filingId}/assignee/${newAssigneeId}`,
        {
            allowPostClosedOperation,
        },
        true
    );
}

export async function addFilingAttachment(filingId: string, file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (ev) => {
            post(`api/ops/filings/${filingId}/attachments`, Buffer.from(ev.target?.result as ArrayBuffer), true, {
                "content-type": file.type,
            })
                .then(resolve)
                .catch(reject);
        };
        reader.onerror = () => {
            reject("Error reading file!");
        };
        reader.readAsArrayBuffer(file);
    });
}

export async function hasFilingAttachments(filingId: string) {
    return get<boolean>(`api/ops/filings/${filingId}/has-attachments`, true);
}

export async function getAutoFilerPDF(filingId: string, key: string) {
    const url = `${apiUrl}/api/ops/filings/${filingId}/auto-file-pdf/${key}`;
    const result = await fetch(url, { method: "GET", headers: {}, credentials: "include" });
    if (result.ok) {
        return result.blob();
    } else {
        throw new Error("Could not fetch from S3: " + (await result.text()));
    }
}

export async function getFrequencyOptionsForFilingAccounting(filingId: string) {
    return get<FilingFrequency[]>(`api/ops/filings/${filingId}/frequencies`, true);
}

// NOTE: period is in yyyy-MM format
export async function getUnmatchedFilings(period: string | undefined, state: string | undefined) {
    return post<FilingWithAccount[] & { filedByName?: string }>(`api/ops/filings/unmatched`, { period, state }, true);
}

export async function getUnmatchedFilingMonths(state: string | undefined) {
    return post<[string]>(`api/ops/filings/unmatchedMonths`, { state }, true);
}

export async function getUnverifiedTaxRateFilingMonths(state: string | undefined) {
    return post<[string]>(`api/ops/filings/unverifiedTaxRateMonths`, { state }, true);
}

export async function getUnmatchedCredits(state: string | undefined, abortController?: AbortController) {
    return post<(Partial<FilingWithAccount> & { originatingCreditIds: string })[]>(
        `api/ops/filings/unmatchedCredits`,
        {
            state,
        },
        true,
        abortController
    );
}

export async function setFilingWellsNote(filingId: string, note: OptionalString) {
    await put(`api/ops/filings/${filingId}/note`, { note }, true);
}

export async function matchFilingRemittedAmount(filingId: string, field: FilingRemittedField, txnId: string) {
    return post(`api/ops/filings/match-remitted`, { filingId, field, txnId }, true);
}

export async function matchFilingPenalty(penaltyId: string, txnId: string) {
    return post(`api/ops/filings/match-penalty`, { penaltyId, txnId }, true);
}

export async function findCandidateMatchingBankTransactionsForFiling(filingId: string, field: FilingRemittedField) {
    return get<IWellsFargoTransactionInfo[]>(
        `api/ops/filings/candidate-transactions/filing/${filingId}/${field}`,
        true
    );
}

export async function findCandidateMatchingBankTransactionsForFilingPenalty(penaltyId: string) {
    return get<IWellsFargoTransactionInfo[]>(`api/ops/filings/candidate-transactions/penalty/${penaltyId}`, true);
}

export async function filingSearch(
    fromDate: DateTime,
    toDate: DateTime,
    fromAmount?: number,
    toAmount?: number,
    state?: IJurisdiction,
    fein?: string,
    stateTaxId?: string,
    fromTaxDue?: number,
    toTaxDue?: number,
    fromOutstandingBalance?: number,
    toOutstandingBalance?: number,
    fromRemittedDirect?: number,
    toRemittedDirect?: number,
    filer?: string
) {
    return post<(FilingWithAccount & { outstandingBalance?: number; filedByName: string })[]>(
        `api/ops/filings/search`,
        {
            fromDate,
            toDate,
            fromAmount,
            toAmount,
            state: state?.abbreviatedName,
            fein,
            stateTaxId,
            fromTaxDue,
            toTaxDue,
            fromOutstandingBalance,
            toOutstandingBalance,
            fromRemittedDirect,
            toRemittedDirect,
            filer,
        },
        true
    );
}

export async function removeFilingAttachment(filingId: string, attachmentId: string) {
    await post<void>(`api/ops/filings/${filingId}/attachments/${attachmentId}/remove`, {}, true);
}

export async function getFilingAccounting(filingId: string, frequency: FilingFrequency) {
    return get<PeriodReport>(`api/ops/filings/${filingId}/accounting/frequency/${frequency}`, true);
}

export async function getFilingSubsumingAccounting(filingId: string) {
    return get<PeriodReport>(`api/ops/filings/${filingId}/subsuming-accounting`, true);
}
