import { get, put } from "@davo/portal-common";
import { IGroupLogin } from "@davo/types";
import isNil from "lodash/isNil";

export async function getStatesWithActiveGroupLogins(partnerId?: string) {
    const url = !isNil(partnerId) ? `api/ops/group-login/states/${partnerId}` : `api/ops/group-login/states`;
    return get<string[]>(url, true);
}

export async function getGroupCredentialsByState(stateCode: string, partnerId?: string) {
    const url = !isNil(partnerId)
        ? `api/ops/group-login/${stateCode}/${partnerId}`
        : `api/ops/group-login/${stateCode}`;
    return get<IGroupLogin | undefined>(url, true);
}

export async function setGroupCredentials(
    groupLogin: Omit<IGroupLogin, "id" | "created" | "updated">,
    partnerId?: string
) {
    const url = !isNil(partnerId) ? `api/ops/group-login/${partnerId}` : `api/ops/group-login`;
    await put(url, { ...groupLogin }, true);
}
