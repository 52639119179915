import { d30Toast, d30ToastError, PaperComponent, TextField } from "@davo/portal-common";
import { ManualSetAsideAndACH } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { voidManualSetAside } from "./services";

interface IVoidManualTransactionButtonType {
    manualSetAside: ManualSetAsideAndACH;
    onClose: () => void;
}

export function VoidManualTransactionButton({ onClose, manualSetAside }: IVoidManualTransactionButtonType) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [voidReason, setVoidReason] = useState<string | undefined>(undefined);

    const show = () => {
        setIsShowing(true);
    };

    const doVoid = (ms: ManualSetAsideAndACH) => {
        if (!voidReason) {
            d30ToastError(`Error: Void Reason required`);
            return;
        }

        voidManualSetAside(ms.id, voidReason)
            .then(() => {
                d30Toast("Transaction voided");
            })
            .catch(() => d30ToastError("Transaction could not be voided at this time"))
            .finally(() => {
                setIsShowing(false);
                onClose();
            });
    };

    return (
        <div style={{ marginTop: "2px", marginRight: "2px" }}>
            <Tooltip
                title={
                    manualSetAside.statusUpdatedBy
                        ? manualSetAside.statusUpdatedBy + ": " + manualSetAside.statusNotes
                        : "" + manualSetAside.statusNotes
                }
                placement="top">
                <span>
                    <Button
                        style={{ margin: "10px" }}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        disabled={!["pending", "posted", "hold"].includes(manualSetAside.status)}
                        onClick={show}>
                        VOID
                    </Button>
                </span>
            </Tooltip>

            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Void Transaction Reason</DialogTitle>
                    <DialogContent style={{ padding: "0px 24px" }}>
                        <TextField
                            label={"Reason for voiding transaction"}
                            value={voidReason ?? ""}
                            onChange={setVoidReason}
                            isMultiline={true}
                            rows={2}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button
                            style={{ margin: "10px" }}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            disabled={!["pending", "posted", "hold"].includes(manualSetAside.status)}
                            onClick={() => doVoid(manualSetAside)}>
                            Void Manual Set Aside Transaction
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
