import { d30Toast, d30ToastError, Header, TextField } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { addNewReferralSource, checkSourceNameAvailability } from "./services";

export function ReferralSourceModal({ closeDialog }: { closeDialog: (target?: any) => void }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [source, setSource] = useState<string>("");
    const [doesValidate, setDoesValidate] = useState<boolean>(false);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [sourceDescription, setSourceDescription] = useState<string | undefined>(undefined);

    const doBlur = () => {
        if (source) {
            checkSourceNameAvailability(source)
                .then(() => {
                    setMessage(undefined);
                    setDoesValidate(false);
                })
                .catch(() => {
                    setMessage("That name is already taken");
                    setDoesValidate(true);
                });
        }
    };

    const shutdown = () => {
        setSource("");
        setSourceDescription(undefined);
        closeDialog();
    };

    const addSource = async () => {
        if (!source || !sourceDescription) {
            return;
        }

        try {
            await addNewReferralSource(source, sourceDescription);
            d30Toast("New Source Added!");
        } catch (e: any) {
            d30ToastError("New source could not be added at this time!");
        } finally {
            shutdown();
        }
    };

    const checkAndSetSource = (val: string) => {
        setDoesValidate(false);
        setSource(val);
    };

    return (
        <Dialog
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"lg"}
            style={{ boxShadow: "none", padding: "10px" }}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    closeDialog(false);
                }
            }}>
            <DialogContent>
                <Header>Add New Referral Source</Header>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        label="Referral Source"
                        value={source ?? ""}
                        onBlur={doBlur}
                        onChange={(s: string) => checkAndSetSource(s)}
                    />
                    <Typography style={{ color: "red" }}>{message}&nbsp;</Typography>
                    <TextField
                        label="Description"
                        value={sourceDescription ?? ""}
                        style={{ marginTop: "15px" }}
                        rows={4}
                        isMultiline={true}
                        onChange={(s: string) => setSourceDescription(s)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => shutdown()} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={doesValidate || !sourceDescription || !source}
                    onClick={() => addSource()}
                    variant="contained"
                    color="primary"
                    autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
