import { d30ToastError, LinkButton, ReactTable, Select } from "@davo/portal-common";
import { FilingWithAccount, IJurisdiction, moneyFromCents, toDisplayDateString } from "@davo/types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getUnmatchedFilingMonths, getUnmatchedFilings } from "../services";
import { FilingAddWellsNoteModal } from "./FilingAddWellsNoteModal";
import { monthYearLabel, numberSorter } from "./Util";
import { WellsTransactionMatchFiling } from "./WellsTransactionMatchFiling";
import { WellsTransactionMatchIcon } from "./WellsTransactionMatchIcon";

export interface IUnmatchedFilingsType {
    states: IJurisdiction[];
}

const anySession = "Any Session";

export function UnmatchedFilings({ states }: IUnmatchedFilingsType) {
    const [filingMonths, setFilingMonths] = useState<string[]>([]);
    const [results, setResults] = useState<
        | (FilingWithAccount & {
              remittedFormatted: string;
              remittedPart2Formatted: string;
              remittedPart3Formatted: string;
              remittedPart4Formatted: string;
              remittedPart5Formatted: string;
          })[]
        | undefined
    >(undefined);
    const [filingSession, setFilingSession] = useState<string | undefined>();
    const [state, setState] = useState<IJurisdiction | undefined>();

    const refreshResults = useCallback(() => {
        getUnmatchedFilingMonths(state?.abbreviatedName)
            .then((filingSessionMonths) => {
                setFilingMonths([]);
                if (!isEmpty(filingSessionMonths)) {
                    setFilingMonths([anySession, ...filingSessionMonths]);
                }

                if (!filingSession) {
                    setResults([]);
                    return;
                }

                const period = filingSession === anySession ? undefined : filingSession;
                getUnmatchedFilings(period, state?.abbreviatedName)
                    .then((unmatchedFilings) => {
                        const filings = unmatchedFilings.map((f) => {
                            return {
                                ...f,
                                remittedFormatted: moneyFromCents(f.remitted),
                                remittedPart2Formatted: moneyFromCents(f.remittedPart2),
                                remittedPart3Formatted: moneyFromCents(f.remittedPart3),
                                remittedPart4Formatted: moneyFromCents(f.remittedPart4),
                                remittedPart5Formatted: moneyFromCents(f.remittedPart5),
                            };
                        });

                        setResults(filings);
                    })
                    .catch((e) => d30ToastError("Problem loading unmatched", e));
            })
            .catch((e) => d30ToastError("Problem loading months", e));
    }, [filingSession, state?.abbreviatedName]);

    useEffect(refreshResults, [refreshResults, filingSession, state]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Filed Date",
                accessor: "filedDate",
                Cell: (data: any) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                        <span>{toDisplayDateString(data.value)}</span>
                        <FilingAddWellsNoteModal filing={data.cell.row.original} />
                    </span>
                ),
            },
            {
                Header: "Filing",
                accessor: "accountName",
                Cell: (data: any) => (
                    <LinkButton
                        url={`/filings/${data.cell.row.original.id}`}
                        label={`${data.value}`}
                        labelMaxChars={35}
                        align={"left"}
                        target="_blank"
                        rel="noreferrer"
                    />
                ),
            },
            {
                Header: "Remitted",
                accessor: "remittedFormatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart1} onChange={refreshResults} />
                            {!filing.wfPart1 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remitted"}
                                    amount={filing.remitted}
                                    onMatch={refreshResults}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remitted"),
            },
            {
                Header: "Remitted2",
                accessor: "remittedPart2Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart2} onChange={refreshResults} />
                            {!filing.wfPart2 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart2"}
                                    amount={filing.remittedPart2}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart2"),
            },
            {
                Header: "Remitted3",
                accessor: "remittedPart3Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart3} onChange={refreshResults} />
                            {!filing.wfPart3 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart3"}
                                    amount={filing.remittedPart3}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart3"),
            },
            {
                Header: "Remitted4",
                accessor: "remittedPart4Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart4} onChange={refreshResults} />
                            {!filing.wfPart4 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart4"}
                                    amount={filing.remittedPart4}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart4"),
            },
            {
                Header: "Remitted5",
                accessor: "remittedPart5Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart5} onChange={refreshResults} />
                            {!filing.wfPart5 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart5"}
                                    amount={filing.remittedPart5}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart5"),
            },
            {
                Header: "State",
                accessor: "jurisdiction",
            },
            {
                Header: "Filer",
                accessor: "filedByName",
            },
        ];
    }, [refreshResults]);

    return (
        <div>
            <Select<string>
                title="Filing Session"
                value={filingSession}
                onChange={setFilingSession}
                options={filingMonths}
                label={(val) => (val === anySession ? anySession : monthYearLabel(val))}
                showFullWidth={false}
                noneLabel={"Select One"}
            />
            <Select<IJurisdiction>
                title="State"
                options={states}
                value={state}
                onChange={setState}
                label={(s) => s?.fullName}
                showFullWidth={false}
                noneLabel={"Any State"}
                style={{ marginLeft: "10px" }}
            />

            {!isNil(results) && isEmpty(results) && <div>No results found</div>}

            {!isNil(results) && !isEmpty(results) && (
                <ReactTable
                    columns={columns}
                    data={results}
                    refreshData={refreshResults}
                    options={{
                        pageSize: 10,
                    }}
                />
            )}
        </div>
    );
}
