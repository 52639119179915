import { BusinessDay, toDisplayDateString } from "@davo/types";
import React from "react";

interface IRenderFilingPeriodStart {
    date: string | BusinessDay;
}

export const RenderFilingPeriodStart: React.FunctionComponent<IRenderFilingPeriodStart> = ({ date }) => (
    <>{toDisplayDateString(date)}</>
);
