import { d30Toast, d30ToastError, ReactTable, Select } from "@davo/portal-common";
import { IJurisdiction, initialCap, moneyFromCents, toDisplayDateString } from "@davo/types";
import { Switch } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getUnmatchedWellsTransactions, setWellsTransactionWsud } from "../services";
import { currentMonth, monthYearLabel, selectableMonths, WFTransactionFormattedAmount } from "./Util";
import { WellsAddNoteModal } from "./WellsAddNoteModal";
import { WellsTransactionMatches } from "./WellsTransactionMatches";

export interface IWellsUnmatchedTransactions {
    states: IJurisdiction[];
}

export async function toggleIsWsud(txnId: string, isWsud: boolean) {
    await setWellsTransactionWsud(txnId, isWsud);
    d30Toast("Updated");
}

const anySession = "Any Session";

export function WellsUnmatchedTransactions({ states }: IWellsUnmatchedTransactions) {
    const [results, setResults] = useState<WFTransactionFormattedAmount[] | undefined>(undefined);
    const [filingSession, setFilingSession] = useState<string | undefined>(currentMonth);
    const [state, setState] = useState<IJurisdiction | undefined>();

    const refreshResults = useCallback(() => {
        if (!filingSession) {
            setResults([]);
            return;
        }
        // Convert amounts to decimals here so we can search for the formatted amount (rather than the integer amount) easily
        getUnmatchedWellsTransactions(filingSession === anySession ? undefined : filingSession, state?.abbreviatedName)
            .then((unmatched) => {
                const txns = unmatched.map((t) => {
                    return {
                        ...t,
                        transactionAmountFormatted: moneyFromCents(t.transactionAmount),
                        unmatchedAmountFormatted: moneyFromCents(t.transactionAmount - (t.amountMatched ?? 0)),
                    };
                });
                setResults(txns);
            })
            .catch((e) => d30ToastError("Problem loading data", e));
    }, [filingSession, state?.abbreviatedName]);

    useEffect(refreshResults, [refreshResults, filingSession, state]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Description",
                accessor: "transactionDescription",
                Cell: (data: any) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                        <span>{data.value}</span>
                        <WellsAddNoteModal txn={data.cell.row.original} />
                    </span>
                ),
            },
            {
                Header: "Amount",
                accessor: "transactionAmountFormatted",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${data.value}</div>,
            },
            {
                Header: "Unmatched Amount",
                accessor: "unmatchedAmountFormatted",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${data.value}</div>,
            },
            {
                Header: "Date",
                accessor: "postingDate",
                Cell: (data: any) => <>{toDisplayDateString(data.value, false)}</>,
            },
            {
                Header: "State",
                accessor: "state",
            },
            {
                Header: "Debit/Credit",
                accessor: "debitCreditIndicator",
                Cell: (data: any) => <>{initialCap(data.value)}</>,
            },
            {
                Header: "Filing(s)",
                accessor: "matchedFilings",
                Cell: (data: any) => <WellsTransactionMatches txn={data.cell.row.original} onChange={refreshResults} />,
            },
            {
                Header: "WSUD",
                accessor: "isWsud",
                Cell: (data: any) => (
                    <>
                        <Switch
                            checked={data.value}
                            onChange={async (event: any, isChecked: boolean) => {
                                const index = data.cell.row.index;
                                await toggleIsWsud(data.cell.row.original.id, isChecked);
                                if (results) {
                                    const row = results[index];
                                    row.isWsud = isChecked;
                                    setResults([...results]);
                                }
                            }}
                        />
                    </>
                ),
            },
        ];
    }, [refreshResults, results]);

    return (
        <div>
            <Select<string>
                title="Filing Session"
                value={filingSession}
                onChange={setFilingSession}
                options={[anySession, ...selectableMonths]}
                label={(val: string) => (val === anySession ? anySession : monthYearLabel(val))}
                showFullWidth={false}
                noneLabel={"Select One"}
            />
            <Select<IJurisdiction>
                title="State"
                options={states}
                value={state}
                onChange={setState}
                label={(s) => s?.fullName}
                showFullWidth={false}
                noneLabel={"Any State"}
                style={{ marginLeft: "10px" }}
            />

            {!isNil(results) && isEmpty(results) && <div>No results found</div>}

            {!isNil(results) && !isEmpty(results) && (
                <ReactTable
                    columns={columns}
                    data={results}
                    refreshData={refreshResults}
                    options={{
                        pageSize: 10,
                    }}
                />
            )}
        </div>
    );
}
