import { INewBoarding, OptionalDateTime, OptionalString } from "@davo/types";
import { AddNoteModal } from "../AddNoteModal";
import { updateOnboardingNote } from "../services";

export function toNoteable(a: INewBoarding) {
    return {
        id: a.locationId,
        note: a.onboardingNote,
        noteBy: a.onboardingNoteBy,
        noteByName: a.onboardingNoteByName,
        noteDate: a.onboardingNoteDate,
    };
}

export function OnboardingNoteModal({ txn }: { txn: INewBoarding }) {
    const updater = async (
        id: string,
        note: OptionalString,
        noteBy: OptionalString,
        noteByName: OptionalString,
        noteDate: OptionalDateTime
    ) => {
        await updateOnboardingNote(id, note);
        txn.onboardingNote = note;
        txn.onboardingNoteBy = noteBy;
        txn.onboardingNoteByName = noteByName;
        txn.onboardingNoteDate = noteDate;
    };
    return AddNoteModal({ noteable: toNoteable(txn), updater });
}
