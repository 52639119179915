import { d30Toast, d30ToastError, TextField } from "@davo/portal-common";
import { OptionalString, TaxProfile } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { setTaxProfileFilingNote } from "./services";

export interface ISetFilingNote {
    taxProfile: TaxProfile;
    refresh: () => void;
    buttonLabel?: string;
}

export const SetFilingNote: FunctionComponent<ISetFilingNote> = ({
    taxProfile,
    refresh,
    buttonLabel = "Edit Reoccurring Filing Note",
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [filingNote, setFilingNote] = useState<OptionalString>();

    useEffect(() => {
        setFilingNote(taxProfile.filingNote);
        setIsBusy(false);
    }, [taxProfile]);

    const cancel = () => {
        setIsEditing(false);

        // reset filing note back to original value
        taxProfile && setFilingNote(taxProfile.filingNote);
    };

    const doSave = () => {
        const note = filingNote ? filingNote.replace(/^\r+|\n|\f+$/g, "\n") : "";

        setTaxProfileFilingNote(taxProfile.id, note)
            .then(() => {
                setIsEditing(false);
                if (refresh) {
                    refresh();
                }
                d30Toast("Reoccurring Filing Note Change Successful");
            })
            .catch((e) => {
                d30ToastError("Problem setting tax profile filing note.", e);
                setIsBusy(false);
            });
    };

    const handleSubmit = () => {
        if (isBusy) return;
        setIsBusy(true);

        doSave();
    };

    return (
        <div data-testid={"setFilingNote"}>
            <Button
                data-testid={"launchSetFilingNoteBtn"}
                variant="outlined"
                onClick={() => {
                    setIsEditing(true);
                }}
                size="small">
                {buttonLabel}
            </Button>

            {isEditing && (
                <Dialog
                    data-testid={"setFilingNoteDialog"}
                    open={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    PaperProps={{
                        component: "form",
                        onSubmit: (event: FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            handleSubmit();
                        },
                    }}
                    onClose={cancel}>
                    <DialogTitle>Set Filing Note for Tax Profile</DialogTitle>
                    <DialogContent>
                        <TextField
                            inputProps={{
                                [`data-testid`]: "setFilingNoteInput",
                            }}
                            label="Filing Note"
                            value={`${filingNote || ""}`}
                            onChange={setFilingNote}
                            isMultiline={true}
                            rows={6}
                        />
                        <div style={{ textAlign: "center", fontSize: "12px", marginTop: "-10px" }}>
                            (This is an internal message. See account history for previously recorded notes)
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            data-testid={"cancelSaveFilingNoteBtn"}
                            disabled={isBusy}
                            onClick={cancel}>
                            Cancel
                        </Button>
                        <Button
                            data-testid={"saveFilingNoteBtn"}
                            disabled={isBusy}
                            startIcon={
                                isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                            }
                            variant="contained"
                            type={"submit"}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};
