import React from "react";
import { OpsNoticesTable } from "./OpsNoticesTable";

export function NoticesPane() {
    return (
        <div className={"notices-pane"}>
            <h2>Notices</h2>
            <div style={{ width: "100%" }}>
                <OpsNoticesTable />
            </div>
        </div>
    );
}
