import {
    d30ToastError,
    getJurisdictionFilingTypes,
    getJurisdictions,
    ReactTable,
    Select,
    sortBoolean,
    TextField,
} from "@davo/portal-common";
import { IJurisdiction, IJurisdictionFilingType, IJurisdictionUIFieldDefinitionBase } from "@davo/types";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";

const fieldFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{data.value}</span>}</>;
};

export function JurisdictionFilingTypePane() {
    const [states, setStates] = useState<IJurisdiction[] | undefined>();
    const [selectedState, setSelectedState] = useState<IJurisdiction | undefined>();
    const [filingTypesForState, setFilingTypesForState] = useState<IJurisdictionFilingType[]>();
    const [selectedFilingType, setSelectedFilingType] = useState<IJurisdictionFilingType | undefined>();
    const [fieldDefinitions, setFieldDefinitions] =
        useState<(IJurisdictionUIFieldDefinitionBase & { jurisdictionFlavorGroupDescription?: string })[]>();

    useAsyncEffect(async () => {
        setStates(await getJurisdictions());
    }, []);

    useEffect(() => {
        if (!selectedState) {
            return;
        }
        setSelectedFilingType(undefined);
        setFilingTypesForState(undefined);
        getJurisdictionFilingTypes(selectedState.id)
            .then((results) => {
                setFilingTypesForState(results);
                if (!isEmpty(results) && results.length == 1) {
                    setSelectedFilingType(results[0]);
                }
            })
            .catch((e) => d30ToastError("Problem getting jurisdiction filing types", e));
    }, [selectedState]);

    useEffect(() => {
        if (!selectedFilingType) {
            return;
        }

        if (!isEmpty(selectedFilingType.fields)) {
            const fieldDefs: (IJurisdictionUIFieldDefinitionBase & { jurisdictionFlavorGroupDescription?: string })[] =
                [];
            selectedFilingType.fields.forEach((f) => {
                f.definitions.forEach((def) =>
                    fieldDefs.push({ ...def, jurisdictionFlavorGroupDescription: f.jurisdictionFlavorGroupDescription })
                );
            });
            setFieldDefinitions(fieldDefs);
        }
    }, [selectedFilingType]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Label",
                accessor: "label",
                Cell: fieldFormat,
            },
            {
                Header: "Group Label",
                accessor: "jurisdictionFlavorGroupDescription",
                Cell: fieldFormat,
            },
            {
                Header: "Visible?",
                accessor: "isVisible",
                sortType: sortBoolean("isVisible"),
                Cell: (data: any) => <>{data.row.original.isVisible ? "Yes" : "No"}</>,
            },
            {
                Header: "Help Text",
                accessor: "helpText",
                Cell: fieldFormat,
            },
            {
                Header: "Tooltip Text",
                accessor: "toolTip",
                Cell: (data: any) => <>{data.value && <span>{data.value}</span>}</>,
            },
            {
                Header: "Validation Regex",
                accessor: "validationRegex",
                Cell: fieldFormat,
            },
            {
                Header: "Tag",
                accessor: "jurisdictionFieldType",
                Cell: (data: any) => {
                    if (!data.row.original.jurisdictionFieldType) {
                        return;
                    }
                    return <>{data.row.original.jurisdictionFieldType.tag}</>;
                },
            },
        ];
    }, []);

    if (!states) {
        return null;
    }
    return (
        <div>
            <Select<IJurisdiction>
                style={{ width: "300px" }}
                data-testid={"filingTypePaneState"}
                title="State"
                options={states}
                value={selectedState}
                noneLabel="Select a state..."
                label={(jurisdiction) => jurisdiction.fullName}
                onChange={(s) => {
                    setSelectedState(s);
                }}
                isRequired
            />

            {selectedState && !isEmpty(filingTypesForState) && (
                <Select<IJurisdictionFilingType>
                    style={{ marginBottom: "40px", width: "300px" }}
                    data-testid={"filingTypePaneFilingTypes"}
                    title="Filing Type"
                    options={filingTypesForState ?? []}
                    value={selectedFilingType}
                    noneLabel="Select a filing type..."
                    label={(jurisdiction) => jurisdiction.name}
                    onChange={(s) => {
                        setSelectedFilingType(s);
                    }}
                    isRequired
                />
            )}

            {selectedState && selectedFilingType && (
                <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            style={{ maxWidth: "300px" }}
                            label={"Name"}
                            value={selectedFilingType.name}
                            isDisabled={true}
                        />
                        <TextField
                            style={{ maxWidth: "300px" }}
                            label={"Tag"}
                            value={selectedFilingType.tag}
                            isDisabled={true}
                        />
                        <TextField
                            style={{ maxWidth: "300px" }}
                            label={"URL"}
                            value={selectedFilingType.url ?? ""}
                            isDisabled={true}
                        />
                    </div>

                    <Box sx={{ display: "flex" }}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={selectedFilingType.canAutofile} disabled={true} />}
                                    label="Can Autofile?"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={selectedFilingType.rateTagsRequired} disabled={true} />}
                                    label="Rate Tags Required?"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={selectedFilingType.isDefault} disabled={true} />}
                                    label="Default Filing Type?"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={selectedFilingType.twoFactorRequired} disabled={true} />
                                    }
                                    label="Two Factor Required?"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={selectedFilingType.posTypeRequired} disabled={true} />}
                                    label="POS Type Required?"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={selectedFilingType.tenderTypeRequired} disabled={true} />
                                    }
                                    label="Tender Type Required?"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={selectedFilingType.davoCheckCreds} disabled={true} />}
                                    label="DAVO Cred Checked?"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={selectedFilingType.skyscraperCheckCreds} disabled={true} />
                                    }
                                    label="Skyscraper Cred Checked?"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={selectedFilingType.active} disabled={true} />}
                                    label="Active?"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>

                    {fieldDefinitions && (
                        <ReactTable
                            title={"Tax Profile Field Definitions"}
                            columns={columns}
                            data={fieldDefinitions ?? []}
                            options={{
                                pageSize: 20,
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
}
