import { DavoLink } from "@davo/portal-common/lib/DavoLink";
import { PotentialMidTrans } from "@davo/types";
import RelatedMidTransIcon from "@mui/icons-material/Collections";
import { Tooltip } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React from "react";

export function RelatedMidTrans({ potentialMidTrans }: { potentialMidTrans: PotentialMidTrans[] }) {
    if (isEmpty(potentialMidTrans)) {
        return null;
    }

    const popup = (
        <div data-testid="potential-mid-trans-popup">
            <h4>Potential Mid Trans</h4>
            <ul>
                {potentialMidTrans.map((p) => (
                    <li key={p.locationId}>
                        <DavoLink to={`/no-account/location/${p.locationId}`} target={"_blank"}>
                            {p.locationName}
                        </DavoLink>
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <span
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "10px",
                marginRight: "10px",
            }}>
            <Tooltip title={popup} disableInteractive={false}>
                <RelatedMidTransIcon
                    data-testid="potential-mid-trans-icon"
                    aria-label="Potential Mid Trans"
                    color="primary"
                    style={{ paddingTop: "5px" }}
                />
            </Tooltip>
        </span>
    );
}
