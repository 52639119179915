import { d30Toast, d30ToastError, TextField } from "@davo/portal-common";
import { DisconnectReasonCodes } from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { deactivateLocation } from "./services";

export interface IDeactivateLocationButton {
    locationId: string;
    disconnectReasonCode: DisconnectReasonCodes | "";
    disconnectReasonNote: string;
    show: boolean;
    isDisabled: boolean;
    onClose?: () => void;
    toolTip?: string;
    warningMessage?: string;
}

export function DeactivateLocationButton({
    locationId,
    disconnectReasonCode,
    disconnectReasonNote,
    show,
    isDisabled,
    onClose,
    toolTip,
    warningMessage,
}: IDeactivateLocationButton) {
    const [isShowingDialog, setIsShowingDialog] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const [isBusy, setIsBusy] = useState<boolean>(false);

    if (toolTip === undefined) {
        toolTip = "Settle all $ and close all Filings";
    }

    const showDialog = () => {
        setNote("");
        setIsShowingDialog(true);
    };

    const doDeactivate = () => {
        setIsBusy(true);
        deactivateLocation(locationId, note)
            .then(() => {
                d30Toast(`Location deactivated`);
            })
            .catch((e) => d30ToastError("Could not deactivate", e))
            .finally(() => {
                setIsBusy(false);
                setIsShowingDialog(false);
                onClose && onClose();
            });
    };

    if (!show) {
        return null;
    }

    return (
        <div style={{ marginTop: "2px", marginRight: "2px", whiteSpace: "nowrap" }}>
            <Button
                disabled={isBusy || isDisabled}
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="secondary"
                style={{ marginBottom: "3px" }}
                data-testid={"deactivate-dialog-launcher"}>
                Deactivate
            </Button>
            {isDisabled && (
                <Tooltip title={toolTip} placement="top" data-testid={"deactivate-location-disabled"}>
                    <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                </Tooltip>
            )}
            {isShowingDialog && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsShowingDialog(false);
                    }}>
                    <DialogTitle>Deactivate?</DialogTitle>
                    <DialogContent>
                        {warningMessage && <Typography style={{ color: "red" }}>{warningMessage}</Typography>}
                        <br />
                        {disconnectReasonCode && (
                            <DialogContent>
                                <Typography>
                                    <span style={{ fontWeight: "bold" }}>Disconnecting Reason:</span>{" "}
                                    {disconnectReasonCode}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "bold" }}>Disconnecting Note:</span>{" "}
                                    {disconnectReasonNote}
                                </Typography>
                                <br />
                            </DialogContent>
                        )}

                        <TextField
                            label={"Deactivation Note"}
                            value={note}
                            onChange={setNote}
                            isMultiline={true}
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowingDialog(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="secondary" disabled={!note} onClick={doDeactivate}>
                            Deactivate
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
