import { getLatestBillingSummary } from "@davo/portal-common";
import { IBillingSummary, Location, moneyFromCents, toDisplayDateString } from "@davo/types";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";

function showAlert(alertCopy: string, failedBillings: IBillingSummary[]) {
    let msg: any = alertCopy;
    if (failedBillings) {
        msg = (
            <>
                {failedBillings.map((b) => {
                    return (
                        <span key={b.accountStarred}>
                            {b.accountStarred} - ${moneyFromCents(b.amount)} @ {toDisplayDateString(b.created)} -{" "}
                            {b.status} - {b.statusNotes}
                        </span>
                    );
                })}
            </>
        );
    }

    return (
        <Tooltip title={msg}>
            <div>
                <InfoIcon color="secondary" style={{ verticalAlign: "middle", marginRight: "10px" }} />
                <Typography variant="body1" color="textSecondary" style={{ display: "inline-block" }}>
                    {alertCopy}
                </Typography>
            </div>
        </Tooltip>
    );
}

export function BillingAlert({
    locations,
    alertCopy,
    accountId,
}: {
    locations?: Location[];
    alertCopy: string;
    accountId?: string;
}) {
    const [failedBillings, setFailedBillings] = useState<IBillingSummary[]>([]);

    useAsyncEffect(async () => {
        if (accountId) {
            const billingStatus = await getLatestBillingSummary(accountId);
            setFailedBillings(billingStatus.filter((b) => b.failed));
        }
    }, [accountId]);

    if (!isEmpty(failedBillings)) {
        return showAlert(alertCopy, failedBillings);
    }

    if (
        locations &&
        locations.some(
            (x) =>
                x.posType === "clover" &&
                x.active &&
                (x.cloverBillingStatus?.status === "INACTIVE" || x.cloverBillingStatus?.status === "LAPSED")
        )
    ) {
        return showAlert(alertCopy, []);
    }
    return null;
}
