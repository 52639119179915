import { getLocationsEnhancedAll } from "@davo/portal-common";
import { AccountRecord, Location, LocationRecord, Partner } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AccountLocationsDetail } from "./AccountLocationsDetail";
import { canDeactivateLocations, getAccount, ICanDeactivateLocationsType } from "./services";

export function InactiveLocationsWithLoad({ accountId, partner }: { accountId: string; partner?: Partner }) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [account, setAccount] = useState<AccountRecord>();
    const [inactiveLocations, setInactiveLocations] = useState<Location[]>();
    const [allAccountLocations, setAllAccountLocations] = useState<LocationRecord[]>();
    const [canDeactivateByLocation, setCanDeactivateByLocation] = useState<ICanDeactivateLocationsType>();

    useAsyncEffect(async () => {
        if (!isExpanded) {
            return;
        }
        const rich = await getAccount(accountId);
        if (rich.name === "<account issue - try logging in again>") {
            setAccount(undefined);
            return;
        } else {
            setAccount(rich);
        }
        const locations = await getLocationsEnhancedAll(accountId);
        setAllAccountLocations(locations);
        setInactiveLocations(locations.filter((l: Location) => !l.active));
        setCanDeactivateByLocation(await canDeactivateLocations(locations.map((l: Location) => l.id)));
    }, [isExpanded]);

    return (
        <div style={{ marginTop: "5px" }}>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}
                expanded={isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ fontSize: "20px" }}
                    onClick={() => setIsExpanded(!isExpanded)}>
                    Inactive Locations
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <AccountLocationsDetail
                        account={account}
                        locations={inactiveLocations}
                        canDeactivateByLocation={canDeactivateByLocation}
                        partner={partner}
                        allAccountLocations={allAccountLocations}
                        noneFoundMessage={"No inactive locations found"}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
