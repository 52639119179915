export const colors = {
    future: "#828aff",
    pending: "#FFAB2B",
    unsettled: "#FFAB2B",
    ready: "#6DD230",
    filed: "#000",
    clicked: "#555555",
    late: "#FE4D97",
    updated: "#00afff",
} as { [key: string]: string };

export const darkColors = {
    future: "#828aff",
    pending: "#FFAB2B",
    unsettled: "#FFAB2B",
    ready: "#6DD230",
    filed: "#FFF",
    clicked: "#888888",
    late: "#FE4D97",
    updated: "#00afff",
} as { [key: string]: string };
