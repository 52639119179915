import { d30Toast, useModalEditor, UseTaxModal } from "@davo/portal-common";
import { LocationRecord, Partner, User } from "@davo/types";
import { Button } from "@mui/material";
import React from "react";
import { AssignStaffModal } from "./AssignStaffModal";
import { updateAccount, updateAccountOwner } from "./services";
import { SetAccountNote } from "./SetAccountNote";
import { SetPartnerModal } from "./SetPartnerModal";

export interface IAccountDetailFooter {
    account: any;
    accountId: string;
    partner?: Partner;
    partners?: Partner[];
    refresh: () => void;
    locations: LocationRecord[];
}

export function AccountDetailFooter({
    account,
    accountId,
    partner,
    partners,
    refresh,
    locations,
}: IAccountDetailFooter) {
    const [showAccountOwnerModal, accountOwnerModalProps] = useModalEditor<string>(
        () => {
            refresh();
        },
        false,
        account?.accountOwnerId
    );

    const assignAccountOwner = async (passThroughIds: string[] | undefined, selected: User | undefined) => {
        if (passThroughIds) {
            if (selected) {
                await updateAccountOwner(passThroughIds[0], selected.id);
            } else {
                await updateAccountOwner(passThroughIds[0], undefined);
            }
            refresh();
        }
    };

    const setNewPartner = async (selected: Partner | undefined) => {
        await updateAccount(accountId, { partnerId: selected?.id ?? null });
        d30Toast("Successfully updated partner!");
        refresh();
    };

    return (
        <div
            data-testid={"accountDetailFooter"}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
            }}>
            {" "}
            <Button
                color="primary"
                style={{ margin: "5px" }}
                variant="outlined"
                size="small"
                onClick={() => showAccountOwnerModal(account?.accountOwnerId)}>
                Edit Account Owner
            </Button>
            <SetPartnerModal partners={partners} setPartnerCallback={setNewPartner} currentPartner={partner} />
            <UseTaxModal locations={locations} includeAllMonths={true} accountId={accountId} />
            <SetAccountNote account={account} onClose={refresh} />
            {accountOwnerModalProps.isDialogOpen && (
                <AssignStaffModal
                    shouldFilterByFilers={false}
                    passThroughIds={[accountId]}
                    assignStaffUser={assignAccountOwner}
                    {...accountOwnerModalProps}
                />
            )}
        </div>
    );
}
