import { d30Toast, d30ToastError, PaperComponent, Select, TextField } from "@davo/portal-common";
import { DisconnectReasonCodes, DiscountReasonKeys, DiscountReasons } from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { setAwaitingDisconnectForLocation } from "./services";

interface ISetAwaitingDisconnectButtonType {
    locationId: string;
    onClose?: () => void;
    show: boolean;
    isDisabled: boolean;
    isAwaitingDisconnect: boolean;
}

export function SetAwaitingDisconnectButton({
    locationId,
    onClose,
    show,
    isDisabled,
    isAwaitingDisconnect,
}: ISetAwaitingDisconnectButtonType) {
    const [isDialogShowing, setIsDialogShowing] = useState<boolean>(false);
    const [disconnectReasonCode, setDisconnectReasonCode] = useState<DisconnectReasonCodes | undefined>(undefined);
    const [disconnectReasonNote, setDisconnectReasonNote] = useState<string | undefined>("");

    const showDialog = () => {
        setDisconnectReasonNote("");
        setIsDialogShowing(true);
    };

    const doSubmit = () => {
        if (!disconnectReasonCode) {
            d30ToastError(`Error: Disconnect Reason required`);
            return;
        }
        setAwaitingDisconnectForLocation(locationId, disconnectReasonCode, disconnectReasonNote)
            .then(() => {
                d30Toast(`Location awaiting disconnect`);
            })
            .catch((e) => d30ToastError("Error setting awaiting disconnect", e))
            .finally(() => {
                setIsDialogShowing(false);
                if (onClose) {
                    onClose();
                }
            });
    };

    if (isAwaitingDisconnect) {
        return <Typography style={{ fontSize: "0.95em" }}>Location is pending disconnect</Typography>;
    }

    if (!show) {
        return null;
    }

    return (
        <div style={{ marginTop: "2px", marginRight: "2px" }}>
            <Button
                disabled={isDisabled}
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="secondary"
                style={{ marginBottom: "3px" }}>
                Set Awaiting Disconnect
            </Button>

            {!isDisabled && (
                <Tooltip title="Settle all $ and close all Filings" placement="top">
                    <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                </Tooltip>
            )}

            {isDialogShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsDialogShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Set awaiting disconnect?</DialogTitle>

                    <DialogContent style={{ padding: "0px 24px" }}>
                        <Select<DisconnectReasonCodes>
                            title="Reason"
                            value={disconnectReasonCode}
                            onChange={setDisconnectReasonCode}
                            options={DiscountReasonKeys}
                            label={(disconnectCode) => DiscountReasons[disconnectCode]}
                        />
                    </DialogContent>
                    <DialogContent style={{ padding: "0px 24px" }}>
                        <TextField
                            label={"Reason for disconnecting"}
                            value={disconnectReasonNote ?? ""}
                            onChange={setDisconnectReasonNote}
                            isMultiline={true}
                            rows={2}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsDialogShowing(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={!disconnectReasonCode || !disconnectReasonNote}
                            onClick={doSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
