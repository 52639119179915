import { hasPermission, Header, Select, useLoginContext } from "@davo/portal-common";
import { IJurisdictionFilingType, TaxProfile } from "@davo/types";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FunctionComponent, useEffect, useState } from "react";

export interface ITaxProfileTypeVerificationFormData {
    taxProfile: TaxProfile;
    filingTypes: IJurisdictionFilingType[];
    onSubmit: (data?: IJurisdictionFilingType) => void;
    onCancel: () => void;
    isUpdatingFilingType: boolean;
}

export const TaxProfileTypeVerificationForm: FunctionComponent<ITaxProfileTypeVerificationFormData> = ({
    taxProfile,
    filingTypes,
    onSubmit,
    onCancel,
    isUpdatingFilingType,
}) => {
    const loginContext = useLoginContext();
    const [taxProfileFilingType, setTaxProfileFilingType] = useState<IJurisdictionFilingType | undefined>(undefined);

    useEffect(() => {
        if (!taxProfile || !filingTypes) {
            return;
        }
        if (taxProfile.jurisdictionFilingTypeId) {
            setTaxProfileFilingType(filingTypes.find((t) => t.id === taxProfile.jurisdictionFilingTypeId));
        }
    }, [taxProfile, filingTypes]);

    return (
        <>
            <Header>Filing Type Verification</Header>
            <div data-testid={"filingTypeVerificationForm"}>
                <Select<IJurisdictionFilingType>
                    data-testid={"filingTypeSelect"}
                    inputProps={{
                        ["data-testid"]: "selectFilingTypeInput",
                    }}
                    isDisabled={isUpdatingFilingType}
                    noneLabel={`Select a filing type...`}
                    title="Filing Type"
                    options={filingTypes ?? []}
                    value={(filingTypes ?? []).find((t) => t.id === taxProfileFilingType?.id)}
                    onChange={(x) => setTaxProfileFilingType(x)}
                    label={(s) => s?.name}
                    isRequired
                />
                <div style={{ marginTop: "20px", float: "right" }}>
                    <Button
                        disabled={isUpdatingFilingType}
                        onClick={() => onCancel()}
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "5px" }}>
                        {"Cancel"}
                    </Button>
                    <Button
                        data-testid={"change-tax-profile-filing-type-button"}
                        disabled={
                            !taxProfileFilingType ||
                            !hasPermission(loginContext.permissions, "base_access") ||
                            isUpdatingFilingType
                        }
                        startIcon={
                            isUpdatingFilingType && (
                                <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                            )
                        }
                        onClick={() => onSubmit(taxProfileFilingType)}
                        variant="contained"
                        color="secondary">
                        {"Verify"}
                    </Button>
                </div>
            </div>
        </>
    );
};
