import {
    d30Toast,
    d30ToastError,
    NumberField,
    Select,
    TextField,
    useGlobalOptional,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import {
    AutofilerQueueEntry,
    filingMerchantNotes,
    FilingStatus,
    IMessageTemplate,
    IMessageTemplateText,
    isProduction,
    moneyFromCents,
    returnMerchantNotes,
    toDollars,
    toPennies,
} from "@davo/types";
import {
    Alert,
    Button,
    Checkbox,
    FormControlLabel,
    Icon,
    Link as MaterialLink,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { useOpsPortalConfigContext } from "../context";
import { useFilingDetailPaneContext } from "../context/FilingDetailPaneContext";
import { FilingPreviewModal } from "../FilingPreviewModal";
import {
    closeFiling,
    hasFilingAttachments,
    instantReconcileFiling,
    pullBackFundsForUnfundedRemittanceForFiling,
    sendBackDiscountForFiling,
    setTaxProfileExpandedFields,
    updateFiling,
} from "../services";
import { hasPermission } from "../util";
import { isAutofilerProcessing, isBulkProcessing } from "./AutofileUtils";
import { calculateAmountDue, getMaxAvailable, IClosingInfoType, statusLabel } from "./common";

const validateAmount = (amount: number | undefined) => {
    if (amount && amount < 0) {
        return `Please enter an amount greater than zero`;
    }
    if (amount && amount > 21474836) {
        // 2147483647 is mysql signed integer max and since we add zeros for cents, we need to drop two trailing digits
        return `Please enter an amount less than field maximum`;
    }
    return undefined;
};

export function FilingDetailForm() {
    const { opsPortalConfigInfo: configInfo } = useOpsPortalConfigContext();
    const loginContext = useLoginContext();
    const [autoFilerMap] = useGlobalOptional<{ [key: string]: AutofilerQueueEntry }>("autoFilerMap");

    const {
        filing,
        isEditingFiling,
        isFirstMonthMerchantPay,
        doesTaxProfileHavePriorOpenFilings,
        isReopening,
        fundingStatus,
        taxFromBreakout,
        setIsEditingFiling,
        refresh,
        hasCanFilePermission,
    } = useFilingDetailPaneContext();

    const [closingInfo, setClosingInfo] = useState<IClosingInfoType>({} as IClosingInfoType);
    const [hasExtraFields, setHasExtraFields] = useState<boolean>(false);
    const [shouldForce, setShouldForce] = useState<boolean>(false);
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const [merchantNotes, setMerchantNotes] = useState<IMessageTemplate[]>(filingMerchantNotes);
    const [merchantNoteId, setMerchantNoteId] = useState<string | undefined>(undefined);
    const [filingDate, setFilingDate] = useState<DateTime>(DateTime.now());
    const [startDuration] = useState<number>(new Date().getTime() / 1000);
    const [maxAvailable, setMaxAvailable] = useState<number>(0);

    const [filingStatusSelectOptions, setFilingStatusSelectOptions] = useState<FilingStatus[]>([]);
    const [isTaxDueP1Valid, setIsTaxDueP1Valid] = useState<boolean>(true);
    const [isTaxDueP2Valid, setIsTaxDueP2Valid] = useState<boolean>(true);
    const [isTaxDueP3Valid, setIsTaxDueP3Valid] = useState<boolean>(true);

    const [isDiscountP1Valid, setIsDiscountP1Valid] = useState<boolean>(true);
    const [isDiscountP2Valid, setIsDiscountP2Valid] = useState<boolean>(true);
    const [isDiscountP3Valid, setIsDiscountP3Valid] = useState<boolean>(true);

    const [areFeesValid, setAreFeesValid] = useState<boolean>(true);

    const [isRemittedFromDavoP1Valid, setIsRemittedFromDavoP1Valid] = useState<boolean>(true);
    const [isRemittedFromDavoP2Valid, setIsRemittedFromDavoP2Valid] = useState<boolean>(true);
    const [isRemittedFromDavoP3Valid, setIsRemittedFromDavoP3Valid] = useState<boolean>(true);
    const [isRemittedFromDavoP4Valid, setIsRemittedFromDavoP4Valid] = useState<boolean>(true);
    const [isRemittedFromDavoP5Valid, setIsRemittedFromDavoP5Valid] = useState<boolean>(true);

    const [isRemittedFromMerchantValid, setIsRemittedFromMerchantValid] = useState<boolean>(true);
    const [isStateCreditRemittanceValid, setIsStateCreditRemittanceValid] = useState<boolean>(true);

    const [isFilingDetailFormValid, setIsFilingDetailFormValid] = useState<boolean>(false);

    const [showFilingConfirmationModal, filingConfirmationModalProps] = useModalEditor<boolean>(
        (hasAcceptedConfirmation?: boolean) => {
            if (hasAcceptedConfirmation) {
                updateOrCloseFiling().catch((e) => d30ToastError("Problem updating filing.", e));
            }
        }
    );

    useEffect(() => {
        setMaxAvailable(getMaxAvailable(fundingStatus));
        if (isEditingFiling) {
            setClosingInfo({
                status: closingInfo.status ?? filing.status,
                taxDue: closingInfo.taxDue ?? filing.taxDue,
                taxDuePart1: closingInfo.taxDuePart1 ?? filing.taxDue,
                taxDuePart2: closingInfo.taxDuePart2 ?? 0,
                taxDuePart3: closingInfo.taxDuePart3 ?? 0,
                discount: closingInfo.discount ?? filing.discount,
                discountPart1: closingInfo.discountPart1 ?? filing.discount,
                discountPart2: closingInfo.discountPart2 ?? 0,
                discountPart3: closingInfo.discountPart3 ?? 0,
                fees: closingInfo.fees ?? filing.fees,
                remitted: closingInfo.remitted ?? filing.remitted,
                remittedPart2: closingInfo.remittedPart2 ?? filing.remittedPart2,
                remittedPart3: closingInfo.remittedPart3 ?? filing.remittedPart3,
                remittedPart4: closingInfo.remittedPart4 ?? filing.remittedPart4,
                remittedPart5: closingInfo.remittedPart5 ?? filing.remittedPart5,
                remittedDirect: closingInfo.remittedDirect ?? filing.remittedDirect,
                returned: closingInfo.returned ?? filing.returned,
                note: closingInfo.note ?? filing.note,
                sendMerchantEmail: closingInfo.sendMerchantEmail ?? false,
                updateReason: closingInfo.updateReason ?? undefined,
            });
            setFilingDate(filing.filedDate || DateTime.now());
        }

        if (isReopening && !!filing.filedDate) {
            setFilingDate(filing.filedDate);
        }
    }, [filing, fundingStatus, isEditingFiling, isReopening]);

    useEffect(() => {
        if (!closingInfo || !merchantNoteId) {
            return;
        }
        setClosingInfo({ ...closingInfo, merchantNote: "" });
        const merchantNoteObj = merchantNotes.find((fm: IMessageTemplate) => fm.displayTitle === merchantNoteId);
        const merchantNote = merchantNoteObj
            ? merchantNoteObj.texts.find((t: IMessageTemplateText) => t.language === "EN")
            : undefined;
        if (merchantNoteObj && merchantNote) {
            setClosingInfo({ ...closingInfo, merchantNote: merchantNote.text });
        }
    }, [merchantNoteId, merchantNotes]);

    const canSubmit = useCallback(() => {
        if (isClosing) {
            return false;
        }

        if (isEditingFiling) {
            return (
                !!closingInfo.updateReason &&
                closingInfo.taxDuePart1 !== undefined &&
                (closingInfo.remitted !== undefined ||
                    (filing &&
                        (isFirstMonthMerchantPay ||
                            filing.taxProfile.filingMethod === "merchant-pay" ||
                            filing.taxProfile.filingMethod === "file-only")))
            );
        }

        if (closingInfo.status === "filed") {
            return (
                closingInfo.taxDuePart1 !== undefined &&
                (closingInfo.remitted !== undefined ||
                    (filing &&
                        (isFirstMonthMerchantPay ||
                            filing.taxProfile.filingMethod === "merchant-pay" ||
                            filing.taxProfile.filingMethod === "file-only")))
            );
        } else if (closingInfo.status === "returned") {
            return !!closingInfo.note;
        } else if (closingInfo.status === "wontfile") {
            return maxAvailable <= 0;
        } else if (closingInfo.status === "reviewed") {
            return !!closingInfo.reviewNote;
        } else return !!closingInfo.status;
    }, [closingInfo, filing, isClosing, isEditingFiling, isFirstMonthMerchantPay, maxAvailable]);

    useEffect(() => {
        setFilingStatusSelectOptions((): FilingStatus[] => {
            // note these are loosely coupled to TaxProfileFilingMethod
            if (filing.taxProfile.filingMethod === "take-give-back") {
                return ["returned", "reviewed"];
            }

            if (
                doesTaxProfileHavePriorOpenFilings ||
                isBulkProcessing(filing.bulkTaskId, autoFilerMap ? autoFilerMap[filing.id] : undefined)
            ) {
                return ["reviewed"];
            }

            if (
                (+(fundingStatus?.status.fundedIn ?? 0) +
                    +(fundingStatus?.status.fundedOut ?? 0) -
                    +(fundingStatus?.remitted ?? 0)) /
                    100 <=
                0
            ) {
                return hasCanFilePermission ? ["filed", "wontfile", "reviewed"] : ["wontfile", "reviewed"];
            }

            return hasCanFilePermission
                ? ["filed", "returned", "wontfile", "reviewed"]
                : ["returned", "wontfile", "reviewed"];
        });
    }, [
        filing,
        fundingStatus,
        doesTaxProfileHavePriorOpenFilings,
        loginContext.permissions,
        autoFilerMap,
        hasCanFilePermission,
    ]);

    useEffect(() => {
        setIsFilingDetailFormValid(
            isTaxDueP1Valid &&
                isTaxDueP2Valid &&
                isTaxDueP3Valid &&
                isDiscountP1Valid &&
                isDiscountP2Valid &&
                isDiscountP3Valid &&
                areFeesValid &&
                isRemittedFromDavoP1Valid &&
                isRemittedFromDavoP2Valid &&
                isRemittedFromDavoP3Valid &&
                isRemittedFromDavoP4Valid &&
                isRemittedFromDavoP5Valid &&
                isRemittedFromMerchantValid &&
                isStateCreditRemittanceValid &&
                canSubmit()
        );
    }, [
        isTaxDueP1Valid,
        isTaxDueP2Valid,
        isTaxDueP3Valid,
        isDiscountP1Valid,
        isDiscountP2Valid,
        isDiscountP3Valid,
        areFeesValid,
        isRemittedFromDavoP1Valid,
        isRemittedFromDavoP2Valid,
        isRemittedFromDavoP3Valid,
        isRemittedFromDavoP4Valid,
        isRemittedFromDavoP5Valid,
        isRemittedFromMerchantValid,
        isStateCreditRemittanceValid,
        canSubmit,
    ]);

    const toggleExpandedFields = async () => {
        await setTaxProfileExpandedFields(filing.taxProfile.account.id, filing.taxProfile.id, !hasExtraFields);
        setHasExtraFields(!hasExtraFields);
    };

    const handleClosingTypeSelection = (closeInfo: IClosingInfoType, status?: FilingStatus) => {
        if (!status) {
            return;
        }

        setClosingInfo({ ...closeInfo, status, merchantNote: "", reviewNote: filing.reviewNote });
        setMerchantNoteId(undefined);
        setMerchantNotes(status === "returned" ? returnMerchantNotes : filingMerchantNotes);
    };

    const handleConfirmation = async () => {
        if (closingInfo.status === "filed" || closingInfo.status === "returned") {
            showFilingConfirmationModal();
        } else {
            await updateOrCloseFiling();
        }
    };

    const updateOrCloseFiling = async () => {
        const totalTaxDue =
            (closingInfo.taxDuePart1 ?? 0) + (closingInfo.taxDuePart2 ?? 0) + (closingInfo.taxDuePart3 ?? 0);
        const totalDiscount =
            (closingInfo.discountPart1 ?? 0) + (closingInfo.discountPart2 ?? 0) + (closingInfo.discountPart3 ?? 0);
        let shallowCopy: IClosingInfoType = {
            ...closingInfo,
            taxDue: totalTaxDue,
            discount: totalDiscount,
        };
        if (closingInfo.status === "reviewed") {
            shallowCopy = {
                ...shallowCopy,
                status: filing.status,
                reviewed: DateTime.utc(),
                reviewedById: loginContext.user?.id,
                updateReason: "Marked reviewed",
            };
        }

        if (canSubmit() && closingInfo.status) {
            setIsClosing(true);
            try {
                // Require at least one attachment if we're filing
                if (shallowCopy.status === "filed") {
                    if (
                        !shallowCopy.remitted &&
                        (shallowCopy.remittedPart2 ||
                            shallowCopy.remittedPart3 ||
                            shallowCopy.remittedPart4 ||
                            shallowCopy.remittedPart5)
                    ) {
                        d30ToastError(
                            "Unable to close filing. Please fill out the remitted field before additional parts."
                        );
                        return;
                    }
                    const hasAttachments = await hasFilingAttachments(filing.id);
                    if (!hasAttachments && isProduction(configInfo.d30Env)) {
                        d30ToastError("Unable to close filing. Please add at least one attachment and try again.");
                        return;
                    }
                } else if (closingInfo.status === "returned") {
                    shallowCopy = {
                        ...closingInfo,
                        returned: toPennies(Math.min(maxAvailable, taxFromBreakout) / 100),
                    };
                }

                const isClosingStatus =
                    shallowCopy.status && ["filed", "wontfile", "returned"].includes(shallowCopy.status);

                if (isClosingStatus && !isEditingFiling && shallowCopy.status) {
                    await closeFiling(
                        filing.id,
                        {
                            ...shallowCopy,
                            filedDate: filingDate ?? DateTime.now(),
                            status: shallowCopy.status,
                            duration: Math.trunc(new Date().getTime() / 1000 - startDuration),
                        },
                        shouldForce
                    );
                    d30Toast("Filing closed.");
                    // we can now attempt to send back the discount
                    if (shallowCopy.discount && shallowCopy.discount > 0 && !shouldForce) {
                        try {
                            await sendBackDiscountForFiling(filing.id);
                            d30Toast("Filing discount disbursed");
                        } catch (e: any) {
                            d30ToastError(e.message);
                        }
                    }
                    // we can now attempt to pull back unfunded remittances and reconcile the filing
                    if (
                        shallowCopy.status === "filed" &&
                        shallowCopy?.remitted &&
                        shallowCopy.remitted > 0 &&
                        !shouldForce
                    ) {
                        try {
                            await pullBackFundsForUnfundedRemittanceForFiling(filing.id);
                            d30Toast("Filing pull back for unfunded remittance complete (if any)");
                        } catch (e: any) {
                            d30ToastError(e.message);
                        }
                        try {
                            await instantReconcileFiling(filing.id);
                            d30Toast("Filing reconciled");
                        } catch (e: any) {
                            d30ToastError(e.message);
                        }
                    }
                } else if (shallowCopy.status) {
                    await updateFiling(filing.id, {
                        ...shallowCopy,
                        filedDate: isEditingFiling && filingDate ? filingDate : filing.filedDate,
                        note: shallowCopy.note,
                        status: shallowCopy.status,
                        updateAsOfDate: shallowCopy.updateAsOf,
                        updateReason: shallowCopy.updateReason
                            ? `Filing Edit: ${shallowCopy.updateReason}`
                            : `updated filing to ${shallowCopy.status}`,
                    });
                    d30Toast("Filing updated.");
                }
                // Callback to main page
                setIsEditingFiling(false);
                refresh();
            } catch (e: any) {
                d30ToastError(e.message);
            } finally {
                setIsClosing(false);
            }
        }
    };

    const renderNoteAndMessageFields = () => {
        if (!closingInfo.status && !closingInfo.reviewed) {
            return null;
        }

        if (closingInfo.status === "reviewed") {
            return [
                <TextField
                    key={"reviewNote"}
                    label="Notes specific to this filing"
                    isRequired={true}
                    value={closingInfo?.reviewNote ?? ""}
                    onChange={(reviewNote) => setClosingInfo({ ...closingInfo, reviewNote })}
                    isMultiline={true}
                    rows={4}
                />,
            ];
        }

        const results = [
            <TextField
                inputProps={{
                    [`data-testid`]: "filingNote",
                }}
                key={"filingNote"}
                label="Notes specific to this filing"
                isRequired={closingInfo.status === "returned"}
                value={closingInfo.note ?? ""}
                onChange={(note) => setClosingInfo({ ...closingInfo, note })}
                isMultiline={true}
                rows={4}
            />,
        ];

        if (closingInfo.status === "filed" || closingInfo.status === "returned") {
            if (isEditingFiling) {
                results.push(
                    <div key={"sendEmail"} style={{ display: "flex", width: "100%", alignItems: "left" }}>
                        <Tooltip title={"Send the merchant the filing confirmation email."}>
                            <FormControlLabel
                                label="Send email"
                                control={
                                    <Switch
                                        data-testid={"sendEmailContainer"}
                                        checked={closingInfo.sendMerchantEmail ?? false}
                                        style={{ marginBottom: "10px" }}
                                        onChange={(event: any, isChecked: boolean) =>
                                            setClosingInfo({ ...closingInfo, sendMerchantEmail: isChecked })
                                        }
                                    />
                                }
                            />
                        </Tooltip>
                        {hasPermission(loginContext.permissions, "refresh_filing_data") && (
                            <Tooltip title={"Add new POS data to this filing. This will update the filing asOf date."}>
                                <FormControlLabel
                                    label="Refresh filing data"
                                    control={
                                        <Switch
                                            data-testid={"refreshFilingDataContainer"}
                                            checked={closingInfo.updateAsOf ?? false}
                                            style={{ marginBottom: "10px" }}
                                            onChange={(event: any, isChecked: boolean) =>
                                                setClosingInfo({ ...closingInfo, updateAsOf: isChecked })
                                            }
                                        />
                                    }
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            }
            results.push(
                <div key={"merchantNote"}>
                    {(!isEditingFiling || closingInfo.sendMerchantEmail) && (
                        <TextField
                            data-testid={"merchantNoteContainer"}
                            inputProps={{
                                [`data-testid`]: "merchantNote",
                            }}
                            key={"merchantNote"}
                            label="Merchant message"
                            value={closingInfo.merchantNote ?? ""}
                            onChange={(merchantNote) => setClosingInfo({ ...closingInfo, merchantNote })}
                            isMultiline={true}
                            rows={8}
                        />
                    )}

                    {(!isEditingFiling || closingInfo.sendMerchantEmail) && (
                        <Select<string>
                            data-testid={"merchantNoteTemplateSelector"}
                            noneLabel="Merchant Note Templates"
                            options={merchantNotes.map((m: IMessageTemplate) => m.displayTitle)}
                            value={merchantNoteId ?? ""}
                            onChange={(msgId) => setMerchantNoteId(msgId)}
                            style={{ marginBottom: "10px" }}
                        />
                    )}
                </div>
            );
        } else if (closingInfo.status === "wontfile" && maxAvailable > 0) {
            return (
                <span style={{ padding: "10px", color: "#f50057" }}>
                    ’Will not file’ is not an available option when there is funding available on any filing for this
                    period. Either use the ‘Return to Merchant’ option or have the funding moved to the current period.
                </span>
            );
        }
        return results;
    };

    if (autoFilerMap && isAutofilerProcessing(autoFilerMap[filing.id])) {
        return (
            <div style={{ width: "100%" }}>
                <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                    The autofiler is currently processing this filing.
                </Alert>
            </div>
        );
    }

    enum NumericFields {
        taxDueP1 = "taxDueP1",
        taxDueP2 = "taxDueP2",
        taxDueP3 = "taxDueP3",
        discountP1 = "discountP1",
        discountP2 = "discountP2",
        discountP3 = "discountP3",
        stateCreditRemittance = "stateCreditRemittance",
        fees = "fees",
        remittedFromDavoP1 = "remittedFromDavoP1",
        remittedFromDavoP2 = "remittedFromDavoP2",
        remittedFromDavoP3 = "remittedFromDavoP3",
        remittedFromDavoP4 = "remittedFromDavoP4",
        remittedFromDavoP5 = "remittedFromDavoP5",
        remittedFromMerchant = "remittedFromMerchant",
    }

    const handleValidation = (v: number | undefined, dataTestId: keyof typeof NumericFields) => {
        const result = validateAmount(v);

        const isValid = typeof result !== "string";
        switch (dataTestId) {
            case String(NumericFields.taxDueP1): {
                setIsTaxDueP1Valid(isValid);
                break;
            }
            case String(NumericFields.taxDueP2): {
                setIsTaxDueP2Valid(isValid);
                break;
            }
            case String(NumericFields.taxDueP3): {
                setIsTaxDueP3Valid(isValid);
                break;
            }
            case String(NumericFields.discountP1): {
                setIsDiscountP1Valid(isValid);
                break;
            }
            case String(NumericFields.discountP2): {
                setIsDiscountP2Valid(isValid);
                break;
            }
            case String(NumericFields.discountP3): {
                setIsDiscountP3Valid(isValid);
                break;
            }
            case String(NumericFields.fees): {
                setAreFeesValid(isValid);
                break;
            }
            case String(NumericFields.remittedFromDavoP1): {
                setIsRemittedFromDavoP1Valid(isValid);
                break;
            }
            case String(NumericFields.remittedFromDavoP2): {
                setIsRemittedFromDavoP2Valid(isValid);
                break;
            }
            case String(NumericFields.remittedFromDavoP3): {
                setIsRemittedFromDavoP3Valid(isValid);
                break;
            }
            case String(NumericFields.remittedFromDavoP4): {
                setIsRemittedFromDavoP4Valid(isValid);
                break;
            }
            case String(NumericFields.remittedFromDavoP5): {
                setIsRemittedFromDavoP5Valid(isValid);
                break;
            }
            case String(NumericFields.remittedFromMerchant): {
                setIsRemittedFromMerchantValid(isValid);
                break;
            }
            case String(NumericFields.stateCreditRemittance): {
                setIsStateCreditRemittanceValid(isValid);
                break;
            }
            default: {
                // noop
            }
        }
        return result;
    };

    return (
        <div data-testid={"filingDetailForm"}>
            <Select<FilingStatus>
                title="Status"
                data-testid={"statusDropdown"}
                value={closingInfo.status}
                onChange={(status?: FilingStatus) => {
                    handleClosingTypeSelection(closingInfo, status);
                    setHasExtraFields(filing.taxProfile.showExpandedFields);
                }}
                noneLabel="Select Status"
                options={filingStatusSelectOptions}
                label={(value: FilingStatus) => {
                    return statusLabel[value];
                }}
                isDisabled={isEditingFiling}
            />

            {closingInfo.status === "filed" && (
                <div style={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            reduceAnimations={true}
                            label="Filed Date"
                            value={filingDate?.toJSDate()}
                            closeOnSelect={true}
                            onChange={(x) => {
                                x && setFilingDate(DateTime.fromJSDate(x));
                            }}
                            format="MM/dd/yyyy"
                            minDate={DateTime.now().minus({ years: 1 }).toJSDate()}
                            maxDate={DateTime.now().toJSDate()}
                            slotProps={{
                                field: { id: "filedDateInput" },
                                textField: { style: { width: "100%", margin: "20px 0" } },
                                toolbar: { hidden: true },
                            }}
                        />
                    </LocalizationProvider>
                    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                        <MaterialLink data-testid={"taxDueP1ExpandBtn"} onClick={() => [toggleExpandedFields()]}>
                            <Typography style={{ padding: "5px", cursor: "pointer" }}>
                                <Icon color={hasExtraFields ? "secondary" : "primary"}>
                                    {hasExtraFields ? "remove_circle_outline" : "add_circle_outline"}
                                </Icon>{" "}
                            </Typography>
                        </MaterialLink>
                        <NumberField
                            data-testid={`${NumericFields.taxDueP1}Container`}
                            inputProps={{
                                [`data-testid`]: NumericFields.taxDueP1,
                            }}
                            label="Tax due"
                            isRequired={true}
                            value={toDollars(closingInfo.taxDuePart1)}
                            validate={(v) => handleValidation(v, NumericFields.taxDueP1)}
                            onChange={(v) => {
                                setClosingInfo({
                                    ...closingInfo,
                                    taxDuePart1: toPennies(v),
                                });
                            }}
                        />
                    </div>
                    {hasExtraFields && (
                        <div style={{ paddingLeft: "35px" }}>
                            <NumberField
                                data-testid={`${NumericFields.taxDueP2}Container`}
                                inputProps={{
                                    [`data-testid`]: NumericFields.taxDueP2,
                                }}
                                label="Tax due part 2"
                                value={toDollars(closingInfo.taxDuePart2)}
                                validate={(v) => handleValidation(v, NumericFields.taxDueP2)}
                                onChange={(v) =>
                                    setClosingInfo({
                                        ...closingInfo,
                                        taxDuePart2: toPennies(v),
                                    })
                                }
                            />
                            <NumberField
                                data-testid={`${NumericFields.taxDueP3}Container`}
                                inputProps={{
                                    [`data-testid`]: NumericFields.taxDueP3,
                                }}
                                label="Tax due part 3"
                                value={toDollars(closingInfo.taxDuePart3)}
                                validate={(v) => handleValidation(v, NumericFields.taxDueP3)}
                                onChange={(v) =>
                                    setClosingInfo({
                                        ...closingInfo,
                                        taxDuePart3: toPennies(v),
                                    })
                                }
                            />
                        </div>
                    )}
                    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                        <MaterialLink data-testid={"discountP1ExpandBtn"} onClick={() => [toggleExpandedFields()]}>
                            <Typography style={{ padding: "5px", cursor: "pointer" }}>
                                <Icon color={hasExtraFields ? "secondary" : "primary"}>
                                    {hasExtraFields ? "remove_circle_outline" : "add_circle_outline"}
                                </Icon>{" "}
                            </Typography>
                        </MaterialLink>
                        <NumberField
                            data-testid={`${NumericFields.discountP1}Container`}
                            inputProps={{
                                [`data-testid`]: NumericFields.discountP1,
                            }}
                            label="Discount"
                            value={toDollars(closingInfo.discountPart1)}
                            validate={(v) => handleValidation(v, NumericFields.discountP1)}
                            onChange={(v) =>
                                setClosingInfo({
                                    ...closingInfo,
                                    discountPart1: toPennies(v),
                                })
                            }
                        />
                    </div>
                    {hasExtraFields && (
                        <div style={{ paddingLeft: "35px" }}>
                            {filing.taxProfile.filingMethod !== "file-only" && (
                                <div>
                                    <NumberField
                                        data-testid={`${NumericFields.discountP2}Container`}
                                        inputProps={{
                                            [`data-testid`]: NumericFields.discountP2,
                                        }}
                                        label="Discount part 2"
                                        value={toDollars(closingInfo.discountPart2)}
                                        validate={(v) => handleValidation(v, NumericFields.discountP2)}
                                        onChange={(v) =>
                                            setClosingInfo({
                                                ...closingInfo,
                                                discountPart2: toPennies(v),
                                            })
                                        }
                                    />
                                    <NumberField
                                        data-testid={`${NumericFields.discountP3}Container`}
                                        inputProps={{
                                            [`data-testid`]: NumericFields.discountP3,
                                        }}
                                        label="Discount part 3"
                                        value={toDollars(closingInfo.discountPart3)}
                                        validate={(v) => handleValidation(v, NumericFields.discountP3)}
                                        onChange={(v) =>
                                            setClosingInfo({
                                                ...closingInfo,
                                                discountPart3: toPennies(v),
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <NumberField
                        data-testid={`${NumericFields.fees}Container`}
                        inputProps={{
                            [`data-testid`]: NumericFields.fees,
                        }}
                        label="Fees"
                        value={toDollars(closingInfo.fees)}
                        validate={(v) => handleValidation(v, NumericFields.fees)}
                        onChange={(v) =>
                            setClosingInfo({
                                ...closingInfo,
                                fees: toPennies(v),
                            })
                        }
                    />
                    {!isFirstMonthMerchantPay &&
                        filing.taxProfile.filingMethod !== "merchant-pay" &&
                        filing.taxProfile.filingMethod !== "file-only" && (
                            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                                <MaterialLink onClick={() => [toggleExpandedFields()]}>
                                    <Typography style={{ padding: "5px", cursor: "pointer" }}>
                                        <Icon color={hasExtraFields ? "secondary" : "primary"}>
                                            {hasExtraFields ? "remove_circle_outline" : "add_circle_outline"}
                                        </Icon>{" "}
                                    </Typography>
                                </MaterialLink>
                                <NumberField
                                    data-testid={`${NumericFields.remittedFromDavoP1}Container`}
                                    inputProps={{
                                        [`data-testid`]: NumericFields.remittedFromDavoP1,
                                    }}
                                    label="Remitted from DAVO balance"
                                    isRequired={true}
                                    value={toDollars(closingInfo.remitted)}
                                    validate={(v) => handleValidation(v, NumericFields.remittedFromDavoP1)}
                                    onChange={(v) =>
                                        setClosingInfo({
                                            ...closingInfo,
                                            remitted: toPennies(v),
                                        })
                                    }
                                />
                            </div>
                        )}
                    {hasExtraFields && (
                        <div style={{ paddingLeft: "35px" }}>
                            {filing.taxProfile.state === "CT" && (
                                <div style={{ color: "red" }}>
                                    Using additional remitted fields for this state is unusual
                                </div>
                            )}
                            <NumberField
                                data-testid={`${NumericFields.remittedFromDavoP2}Container`}
                                inputProps={{
                                    [`data-testid`]: NumericFields.remittedFromDavoP2,
                                }}
                                label="Remitted from DAVO balance part 2"
                                value={toDollars(closingInfo.remittedPart2)}
                                validate={(v) => handleValidation(v, NumericFields.remittedFromDavoP2)}
                                onChange={(v) =>
                                    setClosingInfo({
                                        ...closingInfo,
                                        remittedPart2: toPennies(v),
                                    })
                                }
                            />
                            <NumberField
                                data-testid={`${NumericFields.remittedFromDavoP3}Container`}
                                inputProps={{
                                    [`data-testid`]: NumericFields.remittedFromDavoP3,
                                }}
                                label="Remitted from DAVO balance part 3"
                                value={toDollars(closingInfo.remittedPart3)}
                                validate={(v) => handleValidation(v, NumericFields.remittedFromDavoP3)}
                                onChange={(v) =>
                                    setClosingInfo({
                                        ...closingInfo,
                                        remittedPart3: toPennies(v),
                                    })
                                }
                            />
                            <NumberField
                                data-testid={`${NumericFields.remittedFromDavoP4}Container`}
                                inputProps={{
                                    [`data-testid`]: NumericFields.remittedFromDavoP4,
                                }}
                                label="Remitted from DAVO balance part 4"
                                value={toDollars(closingInfo.remittedPart4)}
                                validate={(v) => handleValidation(v, NumericFields.remittedFromDavoP4)}
                                onChange={(v) =>
                                    setClosingInfo({
                                        ...closingInfo,
                                        remittedPart4: toPennies(v),
                                    })
                                }
                            />
                            <NumberField
                                data-testid={`${NumericFields.remittedFromDavoP5}Container`}
                                inputProps={{
                                    [`data-testid`]: NumericFields.remittedFromDavoP5,
                                }}
                                label="Remitted from DAVO balance part 5"
                                value={toDollars(closingInfo.remittedPart5)}
                                validate={(v) => handleValidation(v, NumericFields.remittedFromDavoP5)}
                                onChange={(v) =>
                                    setClosingInfo({
                                        ...closingInfo,
                                        remittedPart5: toPennies(v),
                                    })
                                }
                            />
                        </div>
                    )}
                    {filing.taxProfile.filingMethod !== "file-only" && (
                        <NumberField
                            data-testid={`${NumericFields.remittedFromMerchant}Container`}
                            inputProps={{
                                [`data-testid`]: NumericFields.remittedFromMerchant,
                            }}
                            label="Remitted directly from merchant"
                            value={toDollars(closingInfo.remittedDirect)}
                            validate={(v) => handleValidation(v, NumericFields.remittedFromMerchant)}
                            onChange={(v) =>
                                setClosingInfo({
                                    ...closingInfo,
                                    remittedDirect: toPennies(v),
                                })
                            }
                        />
                    )}
                    Amount due: ${moneyFromCents(calculateAmountDue(closingInfo))}
                </div>
            )}
            {closingInfo.status === "returned" && (
                <>
                    Note: this will return $
                    {maxAvailable < taxFromBreakout
                        ? moneyFromCents(Math.max(maxAvailable, 0))
                        : moneyFromCents(Math.max(taxFromBreakout, 0))}{" "}
                    to the merchant immediately.
                </>
            )}

            {renderNoteAndMessageFields()}

            {isEditingFiling && (
                <TextField
                    key={"updateReason"}
                    isRequired={true}
                    inputProps={{
                        [`data-testid`]: "updateReasonTextarea",
                    }}
                    label="Why are you updating this filing?"
                    value={closingInfo.updateReason ?? ""}
                    onChange={(updateReason?: string) => setClosingInfo({ ...closingInfo, updateReason })}
                    isMultiline={true}
                    rows={4}
                />
            )}

            {closingInfo.status && (
                <div>
                    {loginContext.user?.type === "Superuser" && isFilingDetailFormValid && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={shouldForce}
                                    data-testid={"shouldForceCb"}
                                    onChange={() => setShouldForce(!shouldForce)}
                                />
                            }
                            labelPlacement={"start"}
                            label={<Typography>Force filing close? Last resort!</Typography>}
                        />
                    )}
                    {shouldForce && (
                        <>
                            Note: Post-file processing will not run with force (e.g. no discount, no recon, no clawback
                            etc)
                        </>
                    )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}>
                        <Button
                            data-testid={"primaryActionBtn"}
                            onClick={handleConfirmation}
                            style={{ marginRight: "10px" }}
                            color="primary"
                            variant="contained"
                            disabled={!isFilingDetailFormValid}>
                            {isEditingFiling || closingInfo.status === "reviewed" ? "Update Filing" : "Preview Close"}
                        </Button>
                        {isEditingFiling && (
                            <Button
                                onClick={() => {
                                    setIsEditingFiling(false);
                                    refresh();
                                }}
                                color="secondary"
                                variant="contained">
                                Cancel
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {filingConfirmationModalProps.isDialogOpen && (
                <FilingPreviewModal
                    filingInfo={closingInfo}
                    filingId={filing.id}
                    maxAvailable={maxAvailable}
                    taxFromBreakout={taxFromBreakout}
                    {...filingConfirmationModalProps}
                    force={shouldForce}
                />
            )}
        </div>
    );
}
