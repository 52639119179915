import {
    ConfirmationModal,
    d30ToastError,
    Select,
    setTaxProfileFilingMethod,
    useModalEditor,
} from "@davo/portal-common";
import { Location, OptionalString, Partner, TaxProfileFilingMethod } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";

export function TaxProfileFilingMethodButton({
    isDisabled,
    taxProfileId,
    taxProfileFilingMethodArg,
    location,
    partner,
    accountId,
    callback,
}: {
    isDisabled: boolean;
    taxProfileId: string;
    taxProfileFilingMethodArg?: string | null;
    location?: Location;
    partner?: Partner;
    accountId: string;
    callback: () => void;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [method, setMethod] = useState<OptionalString>(taxProfileFilingMethodArg);
    const [taxProfileFilingMethods, setTaxProfileFilingMethods] = useState<string[]>([
        "file-only",
        "merchant-pay",
        "take-give-back",
        "none",
    ]);
    const [showConfirmationModal, confirmationModalProps] = useModalEditor<string>();

    useAsyncEffect(() => {
        const tp = ["none", "take-give-back"];
        if (!partner || !partner.restrictMerchantOnly) {
            tp.push("merchant-pay");
        }
        if (!partner || !partner.restrictFileOnly) {
            tp.push("file-only");
        }
        setTaxProfileFilingMethods(tp);
    }, [method]);

    const doSaveFilingMethod = () => {
        if (taxProfileId && accountId) {
            if (method === "none") {
                setMethod(undefined);
                setTaxProfileFilingMethod(accountId, taxProfileId, undefined)
                    .then(() => {
                        setIsShowing(!isShowing);
                        callback();
                    })
                    .catch((e) => d30ToastError("Problem setting tax profile filing method.", e));
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                setTaxProfileFilingMethod(accountId, taxProfileId, method as TaxProfileFilingMethod)
                    .then(() => {
                        setIsShowing(!isShowing);
                        callback();
                    })
                    .catch((e) => d30ToastError("Problem setting tax profile filing method.", e));
            }
        }
    };

    const changeTaxProfileFilingMethod = (taxProfileFilingMethod?: string) => {
        if (
            (taxProfileFilingMethod === "file-only" || taxProfileFilingMethod === "merchant-pay") &&
            location &&
            location.bankAccount &&
            !location.bankAccount.paused
        ) {
            showConfirmationModal();
        }
        setMethod(taxProfileFilingMethod);
    };

    const formatVal = (val: string) => {
        // replace dashes and capitalize first letter
        return val.replace(/(-|^)([^-]?)/g, (_, prep, letter) => {
            return `${prep && " "}${letter.toUpperCase()}`;
        });
    };

    return (
        <div style={{ marginRight: "10px" }}>
            <Button
                disabled={isDisabled}
                variant="outlined"
                color="primary"
                size={"small"}
                style={{ margin: "5px 0" }}
                onClick={() => setIsShowing(!isShowing)}>
                Set Filing Method
            </Button>
            {isShowing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle>Set Filing Method</DialogTitle>
                    <DialogContent>
                        <Select<string>
                            title="Filing Method"
                            label={(s) => `${formatVal(s)}`}
                            options={taxProfileFilingMethods}
                            value={method}
                            onChange={(x) => changeTaxProfileFilingMethod(x)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setMethod(undefined);
                                    setIsShowing(!isShowing);
                                }}>
                                Cancel
                            </Button>
                            <Button
                                style={{ marginLeft: "5px" }}
                                variant="contained"
                                onClick={doSaveFilingMethod}
                                color="primary">
                                Save
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            )}
            {confirmationModalProps.isDialogOpen && (
                <ConfirmationModal
                    message="Typically you would want the bank paused with this setup."
                    title="WARNING: Bank not paused!"
                    showContinue={false}
                    cancelLabel="OK"
                    {...confirmationModalProps}
                />
            )}
        </div>
    );
}
