import { PartialAccount } from "@davo/portal-common";
import { RelatedLocation } from "@davo/types";
import { Chip, Divider } from "@mui/material";
import React, { FunctionComponent } from "react";
import { AccountSearch } from "./AccountSearch";
import { RelatedAccounts } from "./RelatedAccounts";

export interface ISelectExistingAccount {
    relatedLocations: RelatedLocation[];
    selectedAccount: PartialAccount | undefined;
    handleSelectAccount: (account: PartialAccount) => void;
    exclude?: string;
}

export const SelectExistingAccount: FunctionComponent<ISelectExistingAccount> = ({
    relatedLocations,
    handleSelectAccount,
    selectedAccount,
    exclude,
}) => {
    return (
        <div>
            {relatedLocations && relatedLocations.length > 0 && (
                <div>
                    <RelatedAccounts
                        relatedLocations={relatedLocations}
                        selectedAccount={selectedAccount}
                        handleSelectAccount={handleSelectAccount}
                    />
                    <Divider style={{ margin: "12px auto" }}>
                        <Chip label={"OR"} />
                    </Divider>
                </div>
            )}
            <AccountSearch handleSelectAccount={handleSelectAccount} exclude={exclude} />
        </div>
    );
};
