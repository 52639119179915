import { d30ToastError, LinkButton, Loading, ReactTable } from "@davo/portal-common";
import { FrequencyLabels, TaxProfileRecord, toDisplayDateTimeString, UntestedTaxProfileRecord } from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { List, ListItem, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RelatedMidTrans } from "./RelatedMidTrans";
import { getStatesWithActiveGroupLogins, getUntestedTaxProfiles } from "./services";
import { TaxProfileCredentialsButton } from "./TaxProfileCredentialsButton";

const _renderTaxProfileNameLink = (ua: TaxProfileRecord) => {
    return (
        <LinkButton
            url={`/accounts/${ua.accountId}`}
            label={`${ua.name} ~ ${ua.legalName}`}
            labelMaxChars={35}
            rel="noreferrer"
            target="_blank"
        />
    );
};

const _renderStateName = (data: any, groupLoginStates: string[] | undefined) => {
    const url = data.row.original.url;
    if (url) {
        return (
            <div>
                <span style={{ whiteSpace: "nowrap", marginRight: "4px" }}>
                    <a href={url} target="_blank" style={{ color: "inherit" }} rel="noreferrer">
                        {data.value}
                    </a>
                </span>
                {groupLoginStates && groupLoginStates.includes(data.value) && (
                    <Tooltip title={"This jurisdiction has a group login"} placement="top">
                        <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                    </Tooltip>
                )}
            </div>
        );
    } else {
        return (
            <div>
                {data.value}
                {groupLoginStates && groupLoginStates.includes(data.value) && (
                    <Tooltip title={"This jurisdiction has a group login"} placement="top">
                        <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                    </Tooltip>
                )}
            </div>
        );
    }
};

const _renderFilingType = (data: any) => {
    if (data.value) {
        return <>{data.value}</>;
    }
    return <>{data.row.original.defaultJurisdictionFilingTypeName}</>;
};

const _renderFailedCount = (data: any) => {
    return (
        <div>
            <span style={{ whiteSpace: "nowrap" }}>{data.value}</span>
            {data.row.original.bankPaused && (
                <Tooltip title={"Bank account is also paused"} placement="top">
                    <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                </Tooltip>
            )}
        </div>
    );
};

const _renderRecentFrequencyChange = (value: any) => {
    return (
        <div>
            <span data-testid={"recentFrequencyChange"} style={{ whiteSpace: "nowrap" }}>
                {value ? "Yes" : "No"}
            </span>
        </div>
    );
};

const _renderListOfReminders = (hasFiledBefore: boolean) => {
    return (
        <>
            <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                The following tax profiles need to be verified according to:
            </Typography>
            <List
                sx={{
                    listStyleType: "disc",
                    listStylePosition: "inside",
                }}>
                <ListItem
                    sx={{
                        padding: 0,
                        textAlign: "left",
                        listStyleType: "disc",
                        display: "list-item",
                    }}>
                    Do the credentials provided work?
                </ListItem>
                <ListItem
                    sx={{
                        padding: 0,
                        textAlign: "left",
                        listStyleType: "disc",
                        display: "list-item",
                    }}>
                    Should this profile be converted to use group login?
                </ListItem>
                {hasFiledBefore && (
                    <ListItem
                        sx={{
                            padding: 0,
                            textAlign: "left",
                            listStyleType: "disc",
                            display: "list-item",
                        }}>
                        If the filing frequency changed, is it correct?
                    </ListItem>
                )}
                {!hasFiledBefore && (
                    <>
                        <ListItem
                            sx={{
                                padding: 0,
                                textAlign: "left",
                                listStyleType: "disc",
                                display: "list-item",
                            }}>
                            Has DAVO&apos;s phone/email been added for 2FA contact?
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                textAlign: "left",
                                listStyleType: "disc",
                                display: "list-item",
                            }}>
                            Is the filing type correct?
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                textAlign: "left",
                                listStyleType: "disc",
                                display: "list-item",
                            }}>
                            Did they choose the right filing frequency?
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                textAlign: "left",
                                listStyleType: "disc",
                                display: "list-item",
                            }}>
                            Have the rates been been tagged?
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                textAlign: "left",
                                listStyleType: "disc",
                                display: "list-item",
                            }}>
                            Do we need to generate prior period filings and/or file old filings?
                        </ListItem>
                    </>
                )}
            </List>
        </>
    );
};

const _dateFormat = (data: any) => {
    return <>{data.value && <span>{toDisplayDateTimeString(data.value)}</span>}</>;
};

export function UntestedTaxProfile({ hasFiledBefore }: { hasFiledBefore: boolean }) {
    const [untestedTaxProfiles, setUntestedTaxProfiles] = useState<UntestedTaxProfileRecord[]>([]);
    const [groupLoginStates, setGroupLoginStates] = useState<string[]>();

    const refresh = useCallback(() => {
        setUntestedTaxProfiles([]);
        setGroupLoginStates(undefined);

        getUntestedTaxProfiles(hasFiledBefore)
            .then((results) => {
                setUntestedTaxProfiles(results);
            })
            .catch((e) => d30ToastError("Problem getting tax profiles.", e));

        getStatesWithActiveGroupLogins()
            .then((results) => {
                setGroupLoginStates(results);
            })
            .catch((e) => d30ToastError("Problem getting states.", e));
    }, [hasFiledBefore]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const untestedTaxProfilesColumns = useMemo(() => {
        return [
            {
                Header: "Updated",
                accessor: "updated",
                Cell: (data: any) => (
                    <div style={{ whiteSpace: "nowrap" }}>
                        {_dateFormat(data)}
                        <RelatedMidTrans potentialMidTrans={data.row.original.potentialMidTrans} />
                    </div>
                ),
            },
            {
                Header: "State",
                id: "state",
                accessor: (x: any) => x.state,
                Cell: (data: any) => _renderStateName(data, groupLoginStates),
            },
            {
                Header: "Filing Type",
                accessor: "jurisdictionFilingTypeName",
                Cell: _renderFilingType,
            },
            {
                Header: "Frequency",
                accessor: "frequency",
                Cell: (data: any) => FrequencyLabels[data.value],
            },
            {
                Header: "Freq Changed 1 Day Ago",
                accessor: "latestFrequencyChange",
                Cell: (data: any) => {
                    return _renderRecentFrequencyChange(data.row.original.frequencyChanged);
                },
            },
            {
                Header: "Fails Last 30 Days",
                accessor: "failedCount",
                Cell: _renderFailedCount,
            },
            {
                Header: "Credentials",
                id: "credentials",
                accessor: (x: any) => x.state,
                Cell: (data: any) => (
                    <TaxProfileCredentialsButton
                        taxProfileId={data.row.original.id}
                        accountId={data.row.original.accountId}
                        callback={refresh}
                        isReadOnly={false}
                    />
                ),
                disableSortBy: true,
            },
            {
                Header: "Tax Profile Name",
                accessor: "name",
                Cell: (data: any) => {
                    return _renderTaxProfileNameLink(data.row.original);
                },
            },
            {
                Header: "Legal Name",
                accessor: "legalName",
            },
        ];
    }, [refresh, groupLoginStates]);

    if (!untestedTaxProfiles) {
        return <Loading />;
    }

    return (
        <div>
            {_renderListOfReminders(hasFiledBefore)}
            <ReactTable
                columns={untestedTaxProfilesColumns}
                data={untestedTaxProfiles}
                refreshData={refresh}
                options={{
                    headerRowStyle: { verticalAlign: "top" },
                    hiddenColumns: ["legalName", !hasFiledBefore ? "latestFrequencyChange" : ""],
                }}
            />
        </div>
    );
}
