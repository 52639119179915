import { BodyText, DavoLink, Loading, POSLogo, ReactTable8, useLoginContext } from "@davo/portal-common";
import {
    BusinessDay,
    IBoardingMonthly,
    IBoardingUsers,
    IDashboardCleanUpTotal,
    IDashboardFilingsByDueDate,
    IDashboardFilingsByUser,
    IDashboardFilingsByUserByDay,
    IDashboardFilingsTotal,
    IDashboardSubscriptionsByPos,
    IDashboardSubscriptionsTotal,
    POSType,
} from "@davo/types";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { Card, CardContent, createTheme, Grid, ThemeProvider, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { createColumnHelper } from "@tanstack/react-table";
import isEqual from "lodash/isEqual";
import React, { useCallback, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import useAsyncEffect from "use-async-effect";
import {
    getBoardingMonthly,
    getBoardingUsers,
    getCleanUpTotal,
    getFilingsThisSessionByDueDate,
    getFilingsThisSessionByUser,
    getFilingsThisSessionByUserByDay,
    getFilingsThisSessionTotal,
    getSubscriptionsByPos,
    getSubscriptionsTotal,
} from "./services";

const getMonthDayFromDate = (dateStr: string) => {
    try {
        const dt = BusinessDay.fromSQLDate(dateStr);
        return `${dt.month}-${dt.day}`;
    } catch (_) {
        return dateStr;
    }
};

const formatDate = (theDate: any) => {
    return <span style={{ whiteSpace: "nowrap" }}>{theDate}</span>;
};

const formatUnfiled = (filingDueDate: string, unfiledCount: number) => {
    const dt = BusinessDay.isSQLDate(filingDueDate) ? BusinessDay.fromSQLDate(filingDueDate) : undefined;
    if (dt) {
        return (
            <div style={{ textAlign: "right" }}>
                {unfiledCount}
                {unfiledCount > 0 && (
                    <>
                        {dt < BusinessDay.today() && (
                            <ErrorIcon
                                style={{
                                    verticalAlign: "bottom",
                                    marginLeft: "3px",
                                    marginBottom: "3px",
                                    fontSize: "16px",
                                    color: "red",
                                }}
                            />
                        )}
                        {isEqual(dt, BusinessDay.today()) && (
                            <WarningIcon
                                style={{
                                    verticalAlign: "bottom",
                                    marginLeft: "3px",
                                    marginBottom: "3px",
                                    fontSize: "16px",
                                    color: "yellow",
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        );
    } else {
        return null;
    }
};

export function Dashboard() {
    const themeMUI = createTheme(useTheme(), {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "2px 8px 2px 2px",
                    },
                },
            },
        },
    });
    const useStyles = makeStyles(() => ({
        card: {
            height: "375px",
        },
        highlight: themeMUI.palette.mode === "dark" ? { color: "#fff" } : { color: "#000" },
    }));
    const classes = useStyles();
    const loginContext = useLoginContext();

    const [subscriptionsTotal, setSubscriptionsTotal] = useState<IDashboardSubscriptionsTotal>();
    const [subscriptionsByPos, setSubscriptionsByPos] = useState<IDashboardSubscriptionsByPos[]>();
    const [cleanUpTotal, setCleanUpTotal] = useState<IDashboardCleanUpTotal>();
    const [filingsTotal, setFilingsTotal] = useState<IDashboardFilingsTotal[]>();
    const [filingsByUser, setFilingsByUser] = useState<IDashboardFilingsByUser[]>();
    const [filingsByUserByDay, setFilingsByUserByDay] = useState<IDashboardFilingsByUserByDay[]>();
    const [filingsByDueDate, setFilingsByDueDate] = useState<IDashboardFilingsByDueDate[]>();
    const [boardingMonthly, setBoardingMonthly] = useState<IBoardingMonthly[]>();
    const [boardingUsers, setBoardingUsers] = useState<IBoardingUsers[]>();

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setSubscriptionsTotal(await getSubscriptionsTotal());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setSubscriptionsByPos(await getSubscriptionsByPos());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setCleanUpTotal(await getCleanUpTotal());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setFilingsTotal(await getFilingsThisSessionTotal());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setFilingsByDueDate(await getFilingsThisSessionByDueDate());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setFilingsByUser(await getFilingsThisSessionByUser());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setFilingsByUserByDay(await getFilingsThisSessionByUserByDay());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setBoardingMonthly(await getBoardingMonthly());
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            setBoardingUsers(await getBoardingUsers());
        }
    }, [loginContext.user]);

    const subscriptionsByPosColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IDashboardSubscriptionsByPos>();

        return [
            columnHelper.accessor("posType", {
                header: "",
                cell: (data) => <POSLogo posType={data.getValue() as POSType} width={70} />,
            }),
            columnHelper.accessor("todaySubs", {
                header: "Subs",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("bank", {
                header: "Bank",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("tp", {
                header: "TP",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("disconnects", {
                header: "Dis",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("totalSubs", {
                header: "Tot",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("quickDisconnects", {
                header: "Qck",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("fromDevice", {
                header: "Dev",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
        ];
    }, []);

    const filingsTotalColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IDashboardFilingsTotal>();
        return [
            columnHelper.accessor("filingDueDate", {
                header: "Due",
                cell: (data) => {
                    if (data.getValue() !== "Session") {
                        return (
                            <DavoLink to={`/filings?due-date=${data.getValue()}`} style={{ lineHeight: 1 }}>
                                {formatDate(getMonthDayFromDate(data.getValue()))}
                            </DavoLink>
                        );
                    } else {
                        return formatDate(data.getValue());
                    }
                },
            }),
            columnHelper.accessor("unfiled", {
                header: "Unfiled",
                cell: (data) => {
                    if (data.row.original.filingDueDate === "Session") {
                        return <div style={{ textAlign: "right" }}>{data.getValue()}</div>;
                    }
                    return formatUnfiled(data.row.original.filingDueDate, data.row.original.unfiled);
                },
            }),
            columnHelper.accessor("filed", {
                header: "Filed",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("automated", {
                header: "Auto",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("manual", {
                header: "Manual",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
        ];
    }, []);

    const filingsTotalExport = useCallback(() => {
        if (!filingsTotal) {
            return [];
        }
        const results: any = [];
        for (const d of filingsTotal) {
            results.push({
                "Filing due date": d.filingDueDate,
                Unfiled: d.unfiled,
                Filed: d.filed,
                Automated: d.automated,
                Manual: d.manual,
            });
        }
        return results;
    }, [filingsTotal]);

    const filingsByDueDateColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IDashboardFilingsByDueDate>();

        return [
            columnHelper.accessor("filingDueDate", {
                header: "Due",
                cell: (data) => {
                    const state = data.row.original.jurisdiction;
                    return (
                        <DavoLink to={`/filings?due-date=${data.getValue()}&state=${state}`} style={{ lineHeight: 1 }}>
                            {formatDate(getMonthDayFromDate(data.getValue()))}
                        </DavoLink>
                    );
                },
            }),
            columnHelper.accessor("jurisdiction", {
                header: "Jur",
            }),
            columnHelper.accessor("unfiled", {
                header: "Unfiled",
                cell: (data) => formatUnfiled(data.row.original.filingDueDate, data.row.original.unfiled),
            }),
            columnHelper.accessor("filed", {
                header: "Filed",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("automated", {
                header: "Auto",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("manual", {
                header: "Manual",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
        ];
    }, []);

    const filingsByDueDateExport = useCallback(() => {
        if (!filingsByDueDate) {
            return [];
        }
        const results: any = [];
        for (const d of filingsByDueDate) {
            results.push({
                "Filing due date": d.filingDueDate,
                Jurisdiction: d.jurisdiction,
                Unfiled: d.unfiled,
                Filed: d.filed,
                Automated: d.automated,
                Manual: d.manual,
            });
        }
        return results;
    }, [filingsByDueDate]);

    const filingsByUserColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IDashboardFilingsByUser>();

        return [
            columnHelper.accessor("filed", {
                header: "Filed",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("user", {
                header: "User",
            }),
            columnHelper.accessor("automated", {
                header: "Auto",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("manual", {
                header: "Manual",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
        ];
    }, []);

    const filingsByUserExport = useCallback(() => {
        if (!filingsByUser) {
            return [];
        }
        const results: any = [];
        for (const d of filingsByUser) {
            results.push({
                Filed: d.filed,
                User: d.user,
                Automated: d.automated,
                Manual: d.manual,
            });
        }
        return results;
    }, [filingsByUser]);

    const filingsByUserByDayColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IDashboardFilingsByUserByDay>();

        return [
            columnHelper.accessor("day", {
                header: "Day",
            }),
            columnHelper.accessor("filed", {
                header: "Filed",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("user", {
                header: "User",
            }),
            columnHelper.accessor("automated", {
                header: "Auto",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("manual", {
                header: "Manual",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
        ];
    }, []);

    const filingsByUserByDayExport = useCallback(() => {
        if (!filingsByUserByDay) {
            return [];
        }
        const results: any = [];
        for (const d of filingsByUserByDay) {
            results.push({
                "Filing due date": d.day,
                Filed: d.filed,
                User: d.user,
                Automated: d.automated,
                Manual: d.manual,
            });
        }
        return results;
    }, [filingsByUserByDay]);

    const boardingMonthlyColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IBoardingMonthly>();
        return [
            columnHelper.accessor("month", { header: "" }),
            columnHelper.accessor("boardings", {
                header: "Total",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("self", {
                header: "Self",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("unassigned", {
                header: "Unassigned",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("assigned", {
                header: "Assigned",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("helped", {
                header: "Helped",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
        ];
    }, []);

    const boardingUsersColumns = useMemo(() => {
        const columnHelper = createColumnHelper<IBoardingUsers>();
        return [
            columnHelper.accessor("assignee", { header: "Assignee" }),
            columnHelper.accessor("assigned", {
                header: "Assigned",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
            columnHelper.accessor("helped", {
                header: "Helped",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>{data.getValue()}</div>,
            }),
        ];
    }, []);

    return (
        <div>
            <ThemeProvider theme={themeMUI}>
                <BodyText>Filing information is for the current session</BodyText>
                <Grid container direction="row" spacing={1} style={{ marginTop: "5px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3} order={{ xs: 1 }}>
                        <Card className={classes.card}>
                            <CardContent>
                                {subscriptionsTotal ? (
                                    <>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            style={{ textDecoration: "underline" }}>
                                            {`Total Subscriptions`}
                                        </Typography>
                                        <Typography variant="h2">{subscriptionsTotal.totalSubs}</Typography>
                                        <BodyText>w/ TP:</BodyText>
                                        <Typography variant="h4">{subscriptionsTotal.totalSubsWithTp}</Typography>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            style={{ textDecoration: "underline" }}>
                                            {`Today's spotlight`}
                                        </Typography>
                                        <BodyText>Subscriptions:</BodyText>
                                        <Typography variant="h4">{subscriptionsTotal.todaySubs}</Typography>
                                        <BodyText>
                                            w/ bank <span className={classes.highlight}>{subscriptionsTotal.bank}</span>
                                        </BodyText>
                                        <BodyText>
                                            w/ tax profile{" "}
                                            <span className={classes.highlight}>{subscriptionsTotal.tp}</span>
                                        </BodyText>
                                        <BodyText>
                                            Disconnects{" "}
                                            <span className={classes.highlight}>{subscriptionsTotal.disconnects}</span>
                                        </BodyText>
                                        <BodyText>
                                            Disconnects same day{" "}
                                            <span className={classes.highlight}>
                                                {subscriptionsTotal.quickDisconnects}
                                            </span>
                                        </BodyText>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} order={{ xs: 3, lg: 2 }}>
                        <Card className={classes.card}>
                            <CardContent>
                                {subscriptionsByPos ? (
                                    <ReactTable8<IDashboardSubscriptionsByPos>
                                        columns={subscriptionsByPosColumns}
                                        data={subscriptionsByPos}
                                        options={{
                                            pageSize: 7,
                                            hideShowAll: true,
                                            hideToolbar: true,
                                        }}
                                    />
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3} order={{ xs: 2, lg: 3 }}>
                        <Card className={classes.card}>
                            <CardContent>
                                {cleanUpTotal ? (
                                    <>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            style={{ textDecoration: "underline" }}>
                                            Clover
                                        </Typography>
                                        <BodyText>
                                            No Account:{" "}
                                            <span className={classes.highlight}>{cleanUpTotal.noAccountClover}</span>
                                        </BodyText>
                                        <BodyText>
                                            No Bank:{" "}
                                            <span className={classes.highlight}>{cleanUpTotal.noBankClover}</span>
                                        </BodyText>
                                        <BodyText>
                                            LAPSED:{" "}
                                            <span className={classes.highlight}>{cleanUpTotal.cloverLapsed}</span>
                                        </BodyText>
                                        <br />
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            style={{ textDecoration: "underline" }}>
                                            General cleanup
                                        </Typography>
                                        <BodyText>
                                            No TP: <span className={classes.highlight}>{cleanUpTotal.noTp}</span>
                                        </BodyText>
                                        <BodyText>
                                            TP Failing:{" "}
                                            <span className={classes.highlight}>{cleanUpTotal.tpFailing}</span>
                                        </BodyText>
                                        <BodyText>
                                            Bank Paused:{" "}
                                            <span className={classes.highlight}>{cleanUpTotal.bankPaused}</span>
                                        </BodyText>
                                        <BodyText>
                                            Awaiting disconnect:{" "}
                                            <span className={classes.highlight}>{cleanUpTotal.awaitingDisconnect}</span>
                                        </BodyText>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 4 }}>
                        <Card className={classes.card} style={{ position: "relative" }}>
                            <CardContent>
                                {filingsTotal ? (
                                    <>
                                        <ReactTable8<IDashboardFilingsTotal>
                                            columns={filingsTotalColumns}
                                            data={filingsTotal}
                                            options={{
                                                pageSize: 11,
                                                hideShowAll: true,
                                                hideToolbar: true,
                                            }}
                                        />
                                        <CSVLink
                                            filename={"filingsTotals.csv"}
                                            data={filingsTotalExport()}
                                            style={{
                                                textDecoration: "unset",
                                                color: "unset",
                                                cursor: "pointer",
                                                position: "absolute",
                                                bottom: 10,
                                                left: 30,
                                            }}>
                                            <CloudDownloadIcon color="primary" />
                                        </CSVLink>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 5 }}>
                        <Card className={classes.card} style={{ position: "relative" }}>
                            <CardContent>
                                {filingsByDueDate ? (
                                    <>
                                        <ReactTable8<IDashboardFilingsByDueDate>
                                            columns={filingsByDueDateColumns}
                                            data={filingsByDueDate}
                                            options={{
                                                pageSize: 11,
                                                hideShowAll: true,
                                                hideToolbar: true,
                                            }}
                                        />
                                        <CSVLink
                                            filename={"filingsByDueDate.csv"}
                                            data={filingsByDueDateExport()}
                                            style={{
                                                textDecoration: "unset",
                                                color: "unset",
                                                cursor: "pointer",
                                                position: "absolute",
                                                bottom: 10,
                                                left: 30,
                                            }}>
                                            <CloudDownloadIcon color="primary" />
                                        </CSVLink>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 6 }}>
                        <Card className={classes.card} style={{ position: "relative" }}>
                            <CardContent>
                                {boardingMonthly ? (
                                    <>
                                        <ReactTable8<IBoardingMonthly>
                                            columns={boardingMonthlyColumns}
                                            data={boardingMonthly}
                                            options={{
                                                pageSize: 11,
                                                hideShowAll: true,
                                                hideToolbar: true,
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 7 }}>
                        <Card className={classes.card} style={{ position: "relative" }}>
                            <CardContent>
                                {filingsByUser ? (
                                    <>
                                        <ReactTable8<IDashboardFilingsByUser>
                                            columns={filingsByUserColumns}
                                            data={filingsByUser}
                                            options={{
                                                pageSize: 11,
                                                hideShowAll: true,
                                                hideToolbar: true,
                                            }}
                                        />
                                        <CSVLink
                                            filename={"filingsByUser.csv"}
                                            data={filingsByUserExport()}
                                            style={{
                                                textDecoration: "unset",
                                                color: "unset",
                                                cursor: "pointer",
                                                position: "absolute",
                                                bottom: 10,
                                                left: 30,
                                            }}>
                                            <CloudDownloadIcon color="primary" />
                                        </CSVLink>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 8 }}>
                        <Card className={classes.card} style={{ position: "relative" }}>
                            <CardContent>
                                {filingsByUserByDay ? (
                                    <>
                                        <ReactTable8<IDashboardFilingsByUserByDay>
                                            columns={filingsByUserByDayColumns}
                                            data={filingsByUserByDay}
                                            options={{
                                                pageSize: 11,
                                                hideShowAll: true,
                                                hideToolbar: true,
                                            }}
                                        />
                                        <CSVLink
                                            filename={"filingsByUserByDay.csv"}
                                            data={filingsByUserByDayExport()}
                                            style={{
                                                textDecoration: "unset",
                                                color: "unset",
                                                cursor: "pointer",
                                                position: "absolute",
                                                bottom: 10,
                                                left: 30,
                                            }}>
                                            <CloudDownloadIcon color="primary" />
                                        </CSVLink>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 9 }}>
                        <Card className={classes.card} style={{ position: "relative" }}>
                            <CardContent>
                                {boardingUsers ? (
                                    <>
                                        <ReactTable8<IBoardingUsers>
                                            columns={boardingUsersColumns}
                                            data={boardingUsers}
                                            options={{
                                                pageSize: 11,
                                                hideShowAll: true,
                                                hideToolbar: true,
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>
    );
}
