import { PartialAccount } from "@davo/portal-common";
import { RelatedLocation } from "@davo/types";
import React, { FunctionComponent } from "react";
import { NewAccountForm } from "../NewAccountForm";
import { SelectExistingAccount } from "../SelectExistingAccount";
import { MergeDestination } from "./ExtractionTypes";

export interface IChooseAccount {
    mergeDestination: MergeDestination;
    newAccountName: string;
    handleNewAccountNameChange: (val: string) => void;
    message?: string;
    relatedLocations: RelatedLocation[];
    selectedAccount: PartialAccount | undefined;
    handleSelectAccount: (account: PartialAccount) => void;
    exclude?: string;
}

export const ChooseAccount: FunctionComponent<IChooseAccount> = ({
    newAccountName,
    handleNewAccountNameChange,
    mergeDestination,
    message,
    relatedLocations,
    selectedAccount,
    handleSelectAccount,
    exclude,
}) => {
    return (
        <div>
            {mergeDestination === "new" && (
                <NewAccountForm
                    newAccountName={newAccountName}
                    handleNewAccountNameChange={handleNewAccountNameChange}
                    message={message}
                />
            )}
            {mergeDestination === "existing" && (
                <SelectExistingAccount
                    relatedLocations={relatedLocations}
                    selectedAccount={selectedAccount}
                    handleSelectAccount={handleSelectAccount}
                    exclude={exclude}
                />
            )}
        </div>
    );
};
