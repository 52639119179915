import {
    d30ToastError,
    getJurisdictionDefaultFilingType,
    getJurisdictionFilingType,
    ReactTable,
} from "@davo/portal-common";
import {
    IEncryptedStoreHistoryEntry,
    IJurisdictionFilingType,
    IJurisdictionUIField,
    IJurisdictionUIFieldDefinitionBase,
    TaxProfileWithCredentials,
    toDisplayDateString,
} from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getFilingCredentialHistory } from "./services";

const _renderUpdated = (data: any) => {
    return <>{data.cell.row.original.updated ? toDisplayDateString(data.cell.row.original.updated) : ""}</>;
};

const _renderUpdatedBy = (data: any) => {
    return <>{data.cell.row.original.updatedBy}</>;
};

export function TaxCredentialsDetailsModal({ taxProfile }: { taxProfile: TaxProfileWithCredentials }) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [history, setHistory] = useState<IEncryptedStoreHistoryEntry[] | undefined>(undefined);
    const [filingType, setFilingType] = useState<IJurisdictionFilingType>();
    const [fieldDefinitions, setFieldDefinitions] = useState<IJurisdictionUIFieldDefinitionBase[]>();

    useAsyncEffect(async () => {
        const filingCredentialHistory = await getFilingCredentialHistory(taxProfile.id).catch((e) => {
            d30ToastError("Problem getting filing credential history.", e);
            return [];
        });
        setHistory(filingCredentialHistory);

        let info: IJurisdictionFilingType;
        if (taxProfile.jurisdictionFilingTypeId) {
            info = await getJurisdictionFilingType(taxProfile.jurisdictionFilingTypeId);
        } else {
            info = await getJurisdictionDefaultFilingType(taxProfile.state);
        }
        setFilingType(info);
    }, []);

    useEffect(() => {
        if (filingType) {
            if (filingType.fields.length > 1) {
                if (isNil(taxProfile.jurisdictionUIFlavorGroupId)) {
                    // When tax profile has not been set with a flavor group, default to first one
                    setFieldDefinitions(filingType.fields[0].definitions);
                } else {
                    const flavorDef = filingType.fields.find(
                        (field: IJurisdictionUIField) =>
                            field.jurisdictionFlavorGroupId === taxProfile.jurisdictionUIFlavorGroupId
                    );
                    setFieldDefinitions(flavorDef?.definitions);
                }
            } else {
                // Use the basic default field definitions for a filing type
                setFieldDefinitions(filingType.fields[0].definitions);
            }
        }
    }, [filingType, taxProfile]);

    const columns = useMemo(() => {
        if (!fieldDefinitions) {
            return;
        }
        return [
            {
                Header: "Credentials",
                accessor: "encrypted",
                Cell: (data: any) => {
                    const creds = JSON.parse(data.cell.row.original.encrypted);
                    const userNameLabel = fieldDefinitions.find((def) => def.jurisdictionFieldType.tag === "USERNAME");
                    const passwordLabel = fieldDefinitions.find((def) => def.jurisdictionFieldType.tag === "PASSWORD");
                    const pinLabel = fieldDefinitions.find((def) => def.jurisdictionFieldType.tag === "PIN");

                    if (!userNameLabel || !passwordLabel) {
                        throw new Error("Unable to render the credentials without the Username or Password labels!");
                    }

                    return (
                        <>
                            <>
                                {userNameLabel?.label}: {creds.username}
                            </>
                            <br />
                            <>
                                {passwordLabel?.label}: {creds.password}
                            </>
                            <br />
                            <>
                                {creds.pin && (
                                    <>
                                        {pinLabel?.label}: {creds.pin}{" "}
                                    </>
                                )}
                            </>
                        </>
                    );
                },
            },
            {
                Header: "Updated",
                accessor: "updated",
                Cell: _renderUpdated,
            },
            {
                Header: "Updated By",
                accessor: "updatedBy",
                Cell: _renderUpdatedBy,
            },
        ];
    }, [fieldDefinitions]);

    if (!history || !filingType || !fieldDefinitions || !columns) {
        return null;
    }

    return (
        <div style={{ marginRight: "10px" }}>
            <Button
                variant="outlined"
                color="primary"
                size={"small"}
                style={{ marginTop: "2px", marginBottom: "2px" }}
                onClick={() => setIsShowing(!isShowing)}>
                History
            </Button>
            {isShowing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle style={{ paddingBottom: "unset" }}>Credentials History</DialogTitle>
                    <DialogContent>
                        <div style={{ fontSize: "10px", color: "#4D7CFE" }}>
                            <em>Last Updated By: {history.length > 0 && history[0].updatedBy}</em>
                        </div>
                        <ReactTable
                            columns={columns}
                            data={history}
                            options={{
                                hideToolbar: true,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => setIsShowing(!isShowing)}>
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
