import { d30Toast } from "@davo/portal-common";
import { LocationRecord, Partner, TaxProfileFilingMethod } from "@davo/types";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import isNil from "lodash/isNil";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

export const hasPermission = (userPermissions: string[] | undefined, neededPermission: string) => {
    if (isNil(userPermissions)) {
        return false;
    }
    return userPermissions.includes(neededPermission);
};

export const getLocationLabel = (l: LocationRecord, partner?: Partner) => {
    if (l) {
        return `${l.name} - ${l.address1 ?? ""} ${l.city ?? ""}${!l.active ? " - Inactive" : ""}${
            partner ? " - " + partner.name : ""
        }`;
    } else {
        return "";
    }
};

export const isTakeGiveBack = (filingMethod: TaxProfileFilingMethod | null | undefined) => {
    return filingMethod === "take-give-back";
};

export const displayPhone = (phone: string | undefined) => {
    if (phone) {
        return (
            <div style={{ display: "inline-flex", alignItems: "center" }} key={phone}>
                ({phone.substring(0, 3)})&nbsp;{phone.substring(3, 6)}&nbsp;{phone.substring(6, 11)}&nbsp;&nbsp;
                <CopyToClipboard text={phone} onCopy={() => d30Toast("Copied")}>
                    <FileCopyTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                </CopyToClipboard>
            </div>
        );
    } else {
        return null;
    }
};
