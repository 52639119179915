import { Loading } from "@davo/portal-common";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AccountDetailPaneFewLocations } from "./AccountDetailPaneFewLocations";
import { AccountDetailPaneManyLocations } from "./AccountDetailPaneManyLocations";
import { getNumberOfActiveLocationsForAccount } from "./services";

export function AccountDetailPane({ accountId }: { accountId: string }) {
    const [accountIdInState, setAccountIdInState] = useState<string | undefined>();
    const [numberOfLocations, setNumberOfLocations] = useState<number | undefined>(undefined);

    useAsyncEffect(async () => {
        // navigating directly from one account to another accesses the state before it's reloaded, forcing a reset on change
        if (accountId !== accountIdInState) {
            setNumberOfLocations(undefined);
        }
        const numActiveLocations = await getNumberOfActiveLocationsForAccount(accountId);

        setNumberOfLocations(numActiveLocations);
        setAccountIdInState(accountId);
    }, [accountId]);

    // the === undefined syntax is not the same as !numberOfLocations, which would catch 0 as well
    if (!accountIdInState || numberOfLocations === undefined) {
        return <Loading />;
    }

    return (
        <>
            {numberOfLocations >= 9 ? (
                <AccountDetailPaneManyLocations accountId={accountIdInState} />
            ) : (
                <AccountDetailPaneFewLocations accountId={accountIdInState} />
            )}
        </>
    );
}
