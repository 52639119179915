import { d30Toast, d30ToastError, Select, TextField } from "@davo/portal-common";
import { IGroupLogin } from "@davo/types";
import { Button } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getGroupCredentialsByState, getStatesWithActiveGroupLoginsForPartner, setGroupCredentials } from "./services";

export function PartnerGroupCredentials({ partnerId }: { partnerId: string }) {
    const [states, setStates] = useState<string[]>([]);
    const [selectedState, setSelectedState] = useState<string | undefined>(undefined);
    const [originCode, setOriginCode] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [pin, setPin] = useState<string | undefined>(undefined);

    useAsyncEffect(() => {
        resetFields();
        const statesWithActiveGroupLogins = getStatesWithActiveGroupLoginsForPartner(partnerId);
        statesWithActiveGroupLogins
            .then((statesWithGroupLogin) => {
                statesWithGroupLogin.sort();
                setStates(statesWithGroupLogin);
            })
            .catch((e) => d30ToastError("Problem with retrieving states with active group logins for the partner.", e));
    }, [partnerId]);

    useAsyncEffect(() => {
        if (!selectedState) {
            return;
        }

        const groupLoginByState = getGroupCredentialsByState(selectedState, partnerId);
        groupLoginByState
            .then((groupLogin) => {
                setOriginCode(groupLogin?.originCode);
                setUsername(groupLogin?.username);
                setPassword(groupLogin?.password);
                setPin(groupLogin?.pin);
            })
            .catch((e) =>
                d30ToastError(`Problem with retrieving group credentials for ${selectedState} for the partner.`, e)
            );
    }, [selectedState]);

    const resetFields = () => {
        setOriginCode(undefined);
        setSelectedState(undefined);
        setUsername(undefined);
        setPassword(undefined);
        setPin(undefined);
    };

    const doCancel = () => {
        resetFields();
    };

    const updateGroupCredentials = () => {
        if (!originCode) return;

        const updatedCredentials: Omit<IGroupLogin, "id" | "created" | "updated"> = {
            originCode: originCode,
            password: password,
            username: username,
            pin: pin,
        };
        setGroupCredentials(updatedCredentials, partnerId)
            .then(() => {
                doCancel();
                d30Toast("Partner Group Credentials Update Complete");
            })
            .catch((e) => {
                d30ToastError("Error while updating the partner group credentials", e);
            });
    };

    return (
        <div style={{ width: "100%" }}>
            <Select<string>
                style={{ marginBottom: "45px" }}
                title="State"
                options={states}
                value={selectedState}
                onChange={(s) => {
                    setSelectedState(s);
                }}
                label={(s: any) => s}
                noneLabel="Select a state..."
                isRequired
            />

            {selectedState && (
                <>
                    <TextField label={"Username"} value={username ?? ""} onChange={setUsername}></TextField>
                    <TextField label={"Password"} value={password ?? ""} onChange={setPassword}></TextField>
                    <TextField label={"Pin"} value={pin ?? ""} onChange={setPin}></TextField>
                    <br />
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                        <Button
                            onClick={doCancel}
                            variant="outlined"
                            color="primary"
                            style={{ width: "100px", marginRight: "5px" }}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                updateGroupCredentials();
                            }}
                            disabled={!selectedState || isNil(originCode) || !username || !password}
                            variant="contained"
                            color="primary"
                            style={{ width: "150px" }}>
                            Save
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}
