import { d30Toast, d30ToastError, NumberField, Select, TextField } from "@davo/portal-common";
import {
    BusinessDay,
    initialCap,
    ISubscriptionDiscount,
    meteredBilling,
    moneyFromCents,
    POSType,
    toDisplayDateString,
    toDollars,
    toPennies,
} from "@davo/types";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    DialogContentText,
    FormControlLabel,
    FormGroup,
    IconButton,
    Typography,
} from "@mui/material";
import isInteger from "lodash/isInteger";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import {
    addDiscountForSubscriptionFee,
    canAddLocationDiscount,
    isSubscriptionDiscounted,
    removeDiscountForSubscriptionFee,
} from "./services";

interface ISubscriptionDiscountInfoArgs {
    locationId: string;
    posType: POSType;
    canDiscount: boolean;
    canRemoveDiscount: boolean;
    hasPermission: boolean;
    reload: () => Promise<void>;
    maxFlat: number;
}

export function SubscriptionDiscountInfo({
    locationId,
    posType,
    canDiscount,
    canRemoveDiscount,
    hasPermission,
    reload,
    maxFlat,
}: ISubscriptionDiscountInfoArgs) {
    const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const discountReasonList = ["Beta Customer", "Other"];
    const typeList = ["flat", "percentage"];
    const [feeDiscount, setFeeDiscounted] = useState<ISubscriptionDiscount>();
    const [isContinuous, setIsContinuous] = useState<boolean>(false);
    const [discountMonths, setDiscountMonths] = useState<number | undefined>(undefined);
    const [discountReason, setDiscountReason] = useState<string | undefined>(undefined);
    const [discountType, setDiscountType] = useState<string | undefined>(undefined);
    const [discountAmount, setDiscountAmount] = useState<number | undefined>(undefined);
    const [discountReasonOther, setDiscountReasonOther] = useState<string>("");
    const [isDiscountExpanded, setIsDiscountExpanded] = useState<boolean>(false);
    const [canAddDiscount, setCanAddDiscount] = useState<boolean>();

    const loadFeeDiscount = async () => {
        setFeeDiscounted(await isSubscriptionDiscounted(locationId, BusinessDay.today()));
        setDiscountMonths(undefined);
        setDiscountReason(undefined);
        setDiscountAmount(undefined);
        setDiscountType(undefined);
        setIsContinuous(false);
        setDiscountReasonOther("");
    };

    useAsyncEffect(async () => {
        await loadFeeDiscount();
        setCanAddDiscount(await canAddLocationDiscount(locationId));
    }, []);

    const canSubmitDiscountFee = () => {
        return (
            canDiscount &&
            hasPermission &&
            (!!discountMonths || isContinuous) &&
            !!discountReason &&
            ((discountReason === "Other" && !!discountReasonOther) || discountReason !== "Other")
        );
    };

    const doAddDiscount = async () => {
        const reason = (discountReason === "Other" ? `Other - ${discountReasonOther}` : discountReason) ?? "";
        const result: { discounted: boolean; message: string } = await addDiscountForSubscriptionFee(
            locationId,
            reason,
            discountType ?? "flat",
            discountAmount ?? 0,
            discountMonths
        );

        if (result.discounted) {
            d30Toast("Subscription discount added.");

            // reload subscriptions
            await reload();
            await loadFeeDiscount();
            setIsDiscountExpanded(!isDiscountExpanded);
        } else {
            d30ToastError(result.message);
        }
    };

    const doRemoveDiscount = async () => {
        await removeDiscountForSubscriptionFee(locationId);
        d30Toast("Subscription discount removed.");
        await reload();
        await loadFeeDiscount();
    };

    const validateAmount = (amt: number | undefined) => {
        if (!isNil(amt) && discountType) {
            if (meteredBilling.includes(posType) && (discountType === "flat" || amt < 100)) {
                return `Discount must be 100% for metered billing POS Types`;
            }
            if (discountType === "flat") {
                const amtInPennies = toPennies(amt) ?? 0;
                if (amtInPennies > maxFlat || amtInPennies < 1) {
                    return `Flat discount cannot be greater than ${moneyFromCents(maxFlat)} or less than .01`;
                }
            } else if (discountType === "percentage") {
                if (amt > 100 || amt < 1) {
                    return `Discount percentage must be between 1 and 100 inclusive`;
                } else if (!isInteger(amt)) {
                    return `Discount percentage must be a whole number`;
                }
            }
        }
        return undefined;
    };

    return (
        <div data-testid={"discount-info"}>
            {!canAddDiscount && (
                <div style={{ margin: "10px" }}>
                    A discount exists for this account. <br />
                </div>
            )}

            {canAddDiscount && feeDiscount && (
                <div style={{ marginBottom: "10px" }}>
                    <div>
                        {feeDiscount.discountedUntil && (
                            <span title={feeDiscount.discountReason}>
                                Subscription discounted until {toDisplayDateString(feeDiscount.discountedUntil, false)}
                            </span>
                        )}
                        {!feeDiscount.discountedUntil && (
                            <span title={feeDiscount.discountReason}>Subscription Discounted Continuously</span>
                        )}

                        {canRemoveDiscount && hasPermission && (
                            <IconButton
                                aria-label="Remove discount"
                                onClick={() => doRemoveDiscount()}
                                title={"Remove discount"}
                                size="large">
                                <CancelIcon />
                            </IconButton>
                        )}
                    </div>
                    <span title={feeDiscount.type}>{`Type: ${initialCap(feeDiscount.type)}`}</span>
                    <br />
                    <span title={feeDiscount.amount.toString()}>{`Amount: ${
                        feeDiscount.type === "flat"
                            ? `$${moneyFromCents(feeDiscount.amount)}`
                            : `${feeDiscount.amount}%`
                    }`}</span>
                    <br />
                    <span title={feeDiscount.discountReason}>{`Reason: ${feeDiscount.discountReason}`}</span>
                    <br />
                    {isNil(feeDiscount.discountedUntil) ? (
                        <span title={"noend"}>{`This discount will never expire.`}</span>
                    ) : (
                        <span
                            title={feeDiscount.discountedUntil.toString()}>{`End: ${feeDiscount.discountedUntil}`}</span>
                    )}
                    <br />
                    <span title={feeDiscount.created.toString()}>{`Created: ${feeDiscount.created}`}</span>
                    <br />
                    {isNil(feeDiscount.user?.id) ? (
                        <span title={"unknownUser"}>{`By: unknown`}</span>
                    ) : (
                        <span title={feeDiscount.user.id}>
                            {`By: ${feeDiscount.user.firstName} ${feeDiscount.user.lastName} (${feeDiscount.user.email})`}
                        </span>
                    )}
                    <br />
                </div>
            )}

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!canDiscount && hasPermission && (
                    <Accordion
                        style={{ marginBottom: "30px", width: "100%" }}
                        expanded={isDiscountExpanded}
                        onChange={() => {
                            setIsDiscountExpanded(!isDiscountExpanded);
                        }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Discount Subscription (POS doesn’t support discounting)
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <DialogContentText variant={"body1"}>POS doesn’t support discounting</DialogContentText>
                        </AccordionDetails>
                    </Accordion>
                )}
                {canAddDiscount && canDiscount && hasPermission && (
                    <Accordion
                        style={{ marginBottom: "30px", width: "100%" }}
                        expanded={isDiscountExpanded}
                        onChange={() => {
                            setIsDiscountExpanded(!isDiscountExpanded);
                        }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Discount Subscription</AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <Typography variant={"body1"}>
                                {meteredBilling.includes(posType) && (
                                    <Typography variant={"body1"}>
                                        NOTE: for metered billing, use 100% discount
                                    </Typography>
                                )}
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                title={"Continuous"}
                                                checked={isContinuous}
                                                onChange={() => {
                                                    setIsContinuous(!isContinuous);
                                                    setDiscountMonths(undefined);
                                                }}
                                            />
                                        }
                                        label="Continuous"
                                    />
                                </FormGroup>

                                {!isContinuous && (
                                    <Select<number>
                                        title="How Many Months"
                                        isDisabled={isContinuous}
                                        options={monthList}
                                        value={discountMonths}
                                        onChange={setDiscountMonths}
                                        label={(s) => `${s} month${s > 1 ? "s" : ""}`}
                                    />
                                )}
                                <Select<string>
                                    title="Reason"
                                    options={discountReasonList}
                                    value={discountReason}
                                    onChange={setDiscountReason}
                                    isDisabled={!discountMonths && !isContinuous}
                                />
                                {discountReason === "Other" && (
                                    <TextField
                                        label={"Other"}
                                        value={discountReasonOther}
                                        onChange={setDiscountReasonOther}
                                        isMultiline={true}
                                        rows={4}
                                    />
                                )}
                                <Select<string>
                                    title="Type"
                                    options={meteredBilling.includes(posType) ? ["percentage"] : typeList}
                                    value={discountType}
                                    onChange={(value) => {
                                        setDiscountType(value);
                                    }}
                                    isDisabled={!discountReason}
                                    label={(s) => initialCap(s)}
                                />
                                <NumberField
                                    isDisabled={!discountType}
                                    label={`Amount`}
                                    value={
                                        discountType && discountType === "flat"
                                            ? toDollars(discountAmount)
                                            : discountAmount
                                    }
                                    onChange={(e) => {
                                        discountType && discountType === "flat"
                                            ? setDiscountAmount(toPennies(e))
                                            : setDiscountAmount(e);
                                    }}
                                    validate={validateAmount}
                                    isIntOnly={discountType === "percentage"}
                                />

                                <Button
                                    variant="outlined"
                                    onClick={doAddDiscount}
                                    size="small"
                                    color="primary"
                                    style={{ marginBottom: "2px", marginTop: "2px" }}
                                    disabled={!canSubmitDiscountFee()}
                                    title={`Discount the next ${discountMonths} subscription fee(s)`}>
                                    Discount Subscription
                                </Button>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
            </div>
        </div>
    );
}
