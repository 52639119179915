import { d30Toast, PaperComponent, TextField, useLoginContext, useModalEditor } from "@davo/portal-common";
import { toDisplayDateTimeString } from "@davo/types";
import NoteIcon from "@mui/icons-material/Note";
import NoteAddIcon from "@mui/icons-material/NoteOutlined";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

export interface INoteable {
    id: string;
    note?: string | null;
    noteBy?: string | null;
    noteByName?: string | null;
    noteDate?: DateTime | null;
}

export type UpdaterType = (
    id: string,
    note?: string | null,
    noteBy?: string | null,
    noteByName?: string | null,
    noteDate?: DateTime | null
) => Promise<void>;

export function AddNoteModal({ noteable, updater }: { noteable: INoteable; updater: UpdaterType }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const loginContext = useLoginContext();

    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");

    useEffect(() => {
        setNote(noteable.note ?? "");
    }, [noteable.note]);

    const persistNote = async () => {
        if (!loginContext.user) {
            return;
        }
        setIsBusy(true);
        try {
            const updatedNoteByName = `${loginContext.user.firstName ?? ""} ${loginContext.user.lastName ?? ""}`;
            // NOTE: Update object as well so the UI updates w/o having to re-retrieve the transaction
            await updater(noteable.id, note, loginContext.user.id, updatedNoteByName, DateTime.now());
            d30Toast("Note saved.");
            closeDialog();
        } finally {
            setIsBusy(false);
        }
    };

    const clearNote = async () => {
        setIsBusy(true);
        try {
            // NOTE: Update object as well so the UI updates w/o having to reretrieve the transaction
            await updater(noteable.id, "", undefined, undefined, undefined);
            d30Toast("Note removed.");
            closeDialog();
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <>
            <span
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    marginRight: "10px",
                }}>
                <Tooltip
                    title={
                        noteable.note && noteable.noteDate
                            ? `${noteable.note} - ${noteable.noteByName ?? ""} @ ${toDisplayDateTimeString(
                                  noteable.noteDate
                              )}`
                            : "Add Note"
                    }>
                    <span>
                        <IconButton
                            aria-label="Add Note"
                            onClick={() => {
                                showDialog();
                            }}
                            size="large">
                            {!noteable.note && <NoteAddIcon color="primary" />}
                            {noteable.note && <NoteIcon color="primary" />}
                        </IconButton>
                    </span>
                </Tooltip>
            </span>

            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    fullWidth={true}
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="draggable-dialog-title"> {noteable.note ? "Update" : "Add"} Note</DialogTitle>
                    <DialogContent>
                        <TextField label={"Note"} value={note} onChange={setNote} isMultiline={true} rows={5} />

                        {noteable.note && noteable.noteDate && (
                            <div style={{ float: "right", fontWeight: "bold", color: "#6b6666" }}>
                                <span>
                                    <em>
                                        <sup>
                                            {noteable.noteByName} @ {toDisplayDateTimeString(noteable.noteDate)}{" "}
                                        </sup>
                                    </em>
                                </span>
                            </div>
                        )}

                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" disabled={isBusy} onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={isBusy || !noteable.note}
                            onClick={() => clearNote()}>
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !note}
                            onClick={() => persistNote()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
