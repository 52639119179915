import { getArray, put } from "@davo/portal-common";
import { ACHTransactionSimple, ACHTransactionStatus, MetaACHTransactionSimple } from "@davo/types";

export async function getAchPendingTransactions(): Promise<ACHTransactionSimple[]> {
    return getArray(`api/ops/ach/pending-transactions`, true, MetaACHTransactionSimple);
}

export async function changeACHStatus(achId: string, newStatus: ACHTransactionStatus, toggleReason: string) {
    await put(`api/ops/ach/${achId}/change-status`, { newStatus, toggleReason }, true);
}
