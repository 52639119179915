import {
    ConnectionStatus,
    DavoLink,
    Loading,
    mapIAccountLocationsStatusToIConnectionStatus,
    PartnerLogo,
    portalUrl,
} from "@davo/portal-common";
import { IAccountLocationStatus, IConnectionStatus } from "@davo/types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import React from "react";
import { HubSpotLink } from "./HubSpotLink";

export function AccountDetailHeader({
    account,
    partner,
    accountOwnerFirstName,
}: {
    account: { id: string; name: string; status: IAccountLocationStatus };
    partner?: { tag: string; name: string };
    accountOwnerFirstName?: string | null;
}) {
    const connectionStatusProvided: IConnectionStatus = mapIAccountLocationsStatusToIConnectionStatus(account.status);
    return (
        <>
            {accountOwnerFirstName && (
                <span
                    style={{
                        margin: "5px 0 5px 10%",
                        float: "right",
                        color: "#4d7cfe",
                        marginRight: "5%",
                        fontWeight: "600",
                    }}>
                    Account Owner: {accountOwnerFirstName}
                </span>
            )}
            <div>
                {account ? (
                    <div style={{ display: "inline-flex", width: "100%", justifyContent: "space-between" }}>
                        <Breadcrumbs
                            style={{ marginBottom: 10, marginLeft: 10 }}
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb">
                            <DavoLink to="/accounts">Accounts</DavoLink>
                            <Typography color="textPrimary">{account.name}</Typography>
                            {partner && (
                                <PartnerLogo
                                    partnerTag={partner.tag}
                                    partnerName={partner.name}
                                    style={{ width: "80px", margin: "-10px 10px -10px 10px" }}
                                />
                            )}
                        </Breadcrumbs>
                        <div style={{ marginRight: 56, display: "flex", alignItems: "center", gap: 10 }}>
                            <ConnectionStatus
                                accountId={account.id}
                                hideMessage={false}
                                connectionStatusProvided={connectionStatusProvided}
                            />
                            <HubSpotLink type={"account"} davoId={account.id} />
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    window.open(`${portalUrl}/${account.id}?opsLogin=required`, "_blank");
                                }}
                                data-testid={"portal_link"}>
                                Portal
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </>
    );
}
