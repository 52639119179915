import { d30ToastError, LinkButton, Loading, ReactTable } from "@davo/portal-common";
import { DisconnectBankRemovalState, DisconnectData, toDisplayDateString, truncateString } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseOpenFilingsButton } from "./CloseOpenFilingsButton";
import { DeactivateLocationButton } from "./DeactivateLocationButton";
import { DisconnectAddNoteModal } from "./DisconnectAddNote";
import { DisconnectAutomationButton } from "./DisconnectAutomationButton";
import { DisconnectsPendingFunds } from "./DisconnectsPendingFunds";
import { EditDisconnectReasonButton } from "./EditDisconnectReasonButton";
import { RelatedMidTrans } from "./RelatedMidTrans";
import { getDisconnectsAccounts } from "./services";

export const formatDisconnectedDate = (dt: DateTime) => {
    if (dt) {
        return (
            <span
                style={{
                    whiteSpace: "nowrap",
                    fontWeight: dt > DateTime.utc().minus({ day: 1 }) ? "bold" : "normal",
                }}>
                {toDisplayDateString(dt)}
            </span>
        );
    } else {
        return null;
    }
};

export function DisconnectsPane() {
    const [disconnects, setDisconnects] = useState<DisconnectData[]>();

    const refreshDisconnects = useCallback(() => {
        setDisconnects(undefined);
        getDisconnectsAccounts()
            .then((results) => setDisconnects(results))
            .catch((e) => d30ToastError("Problem loading disconnects", e));
    }, []);

    useEffect(() => {
        refreshDisconnects();
    }, [refreshDisconnects]);

    const columnsActionable = useMemo(() => {
        const renderActions = (data: any) => {
            if (data.row.original.locationId) {
                return (
                    <div>
                        <CloseOpenFilingsButton
                            locationId={data.row.original.locationId}
                            onClose={refreshDisconnects}
                        />
                        <DisconnectAutomationButton
                            accountId={data.row.original.accountId}
                            locationId={data.row.original.locationId}
                            reason={data.row.original.disconnectReasonCode}
                            onClose={refreshDisconnects}
                        />
                        <DeactivateLocationButton
                            locationId={data.row.original.locationId}
                            disconnectReasonCode={data.row.original.disconnectReasonCode}
                            disconnectReasonNote={data.row.original.disconnectReasonNote}
                            show={data.row.original.showDeactivate}
                            isDisabled={!data.row.original.canDeactivate}
                            onClose={refreshDisconnects}
                            warningMessage={
                                DisconnectBankRemovalState.includes(data.row.original.jurisdiction)
                                    ? "Make sure to remove DAVO banking credentials from the location's state account before deactivating"
                                    : undefined
                            }
                        />
                    </div>
                );
            } else {
                return null;
            }
        };

        return [
            {
                Header: "Disconnected",
                accessor: "disconnectedDate",
                Cell: (data: any) => (
                    <>
                        {formatDisconnectedDate(data.value)}
                        <div style={{ whiteSpace: "nowrap" }}>
                            <DisconnectAddNoteModal d={data.row.original} />
                            <RelatedMidTrans potentialMidTrans={data.row.original.potentialMidTrans} />
                        </div>
                    </>
                ),
            },
            {
                Header: "Account",
                accessor: "accountName",
                Cell: (data: any) => (
                    <LinkButton
                        url={`/accounts/${data.row.original.accountId}`}
                        label={data.value}
                        labelMaxChars={35}
                        align={"left"}
                    />
                ),
            },
            {
                Header: "Location",
                accessor: "locationName",
                Cell: (data: any) => <Typography>{truncateString(data.value, 35)}</Typography>,
            },
            {
                Header: "Disconnect Reason",
                accessor: "disconnectReasonCode",
                Cell: (data: any) => {
                    return (
                        <EditDisconnectReasonButton
                            disconnectNote={data.row.original.disconnectReasonNote}
                            disconnectCode={data.row.original.disconnectReasonCode}
                            locationId={data.row.original.locationId}
                            onClose={refreshDisconnects}
                        />
                    );
                },
            },
            {
                Header: "",
                accessor: "id",
                Cell: renderActions,
            },
        ];
    }, [refreshDisconnects]);

    return (
        <div>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}
                expanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Actionable Disconnects</AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    {!disconnects && <Loading />}
                    {disconnects && (
                        <ReactTable columns={columnsActionable} data={disconnects} refreshData={refreshDisconnects} />
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Disconnects with pending funds</AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <DisconnectsPendingFunds />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
