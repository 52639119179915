import { ReactTable } from "@davo/portal-common";
import isNil from "lodash/isNil";
import startCase from "lodash/startCase";
import React, { useEffect, useMemo, useState } from "react";

export function FieldList({ object, pageSize }: { object: any; pageSize?: number }) {
    const [data, setData] = useState<{ name: string; value: any }[]>();

    useEffect(() => {
        setData(
            Object.keys(object).map((key) => ({
                name: startCase(key),
                value: object[key],
            }))
        );
    }, [object]);

    const columns = useMemo(() => {
        return [
            { Header: "", accessor: "name", disableSortBy: true },
            { Header: "", accessor: "value", disableSortBy: true },
        ];
    }, []);

    if (isNil(data)) {
        return null;
    }
    return (
        <ReactTable
            columns={columns}
            data={data}
            options={{
                pageSize: pageSize ? pageSize : Object.keys(object).length,
                pageSizeOptions: [pageSize ? pageSize : Object.keys(object).length],
                hideToolbar: true,
            }}
        />
    );
}
