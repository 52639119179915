import { d30Toast, d30ToastError, PaperComponent, useLoginContext } from "@davo/portal-common";
import { Location } from "@davo/types";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { reactivateLocation } from "./services";

export function ReactivateLocationButton({ location, onClose }: { location: Location; onClose?: () => void }) {
    const loginContext = useLoginContext();
    const [isShowing, setIsShowing] = useState<boolean>(false);

    const show = () => {
        setIsShowing(true);
    };

    const doReactivate = () => {
        reactivateLocation(location.id)
            .then(() => {
                d30Toast(`Location reactivated`);
            })
            .catch((e) => {
                d30ToastError("Could not reactivate", e);
            })
            .finally(() => {
                setIsShowing(false);
                if (onClose) {
                    onClose();
                }
            });
    };

    if (location.active) {
        return null;
    }

    return (
        <div style={{ marginTop: "2px", marginRight: "2px" }}>
            <Button
                disabled={loginContext.user?.type !== "Superuser"}
                variant="outlined"
                onClick={show}
                size="small"
                color="secondary"
                style={{ marginBottom: "3px" }}>
                Reactivate
            </Button>
            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Reactivate?</DialogTitle>
                    <DialogActions>
                        <Button variant="outlined" color="secondary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="secondary" onClick={doReactivate}>
                            Reactivate
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
