import { d30Toast, d30ToastError, useLoginContext } from "@davo/portal-common";
import { Location } from "@davo/types";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { reconnectLocation } from "./services";

export function ReconnectLocationButton({
    location,
    show,
    onClose,
}: {
    location: Location;
    show: boolean;
    onClose?: () => void;
}) {
    const loginContext = useLoginContext();

    const [isDialogShowing, setIsDialogShowing] = useState<boolean>(false);

    const showDialog = () => {
        setIsDialogShowing(true);
    };

    const doReconnect = () => {
        reconnectLocation(location.id)
            .then(() => {
                d30Toast(`Location reconnected`);
            })
            .catch((e) => {
                d30ToastError("Could not reconnect", e);
            })
            .finally(() => {
                setIsDialogShowing(false);
                if (onClose) {
                    onClose();
                }
            });
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <div style={{ marginTop: "2px", marginRight: "2px" }}>
                <Button
                    disabled={loginContext.user?.type !== "Superuser"}
                    variant="outlined"
                    onClick={showDialog}
                    size="small"
                    color="secondary"
                    style={{ marginBottom: "3px" }}>
                    Force Reconnect
                </Button>
                {isDialogShowing && (
                    <Dialog
                        open={true}
                        onClose={() => {
                            setIsDialogShowing(false);
                        }}>
                        <DialogTitle>Force Reconnect?</DialogTitle>
                        <DialogActions>
                            <Button variant="outlined" color="primary" onClick={() => setIsDialogShowing(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="secondary" onClick={doReconnect}>
                                Reconnect
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        </>
    );
}
