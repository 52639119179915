import {
    BodyText,
    ConnectionStatus,
    d30ToastError,
    defaultConnectionStatus,
    getAccountLocationsStatus,
    getPartner,
    Loading,
    mapIAccountLocationsStatusToIConnectionStatus,
    POSLogo,
    ReactTable,
    reduceToSingleAccountLocationStatus,
    useModalEditor,
} from "@davo/portal-common";
import {
    IAccountLocationStatus,
    Location,
    LocationRecord,
    MinimalAccountWithLocationStatus,
    Partner,
    POSType,
    User,
} from "@davo/types";
import { Alert, Button, Typography, useTheme } from "@mui/material";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { AccountAlerts } from "./AccountAlerts";
import { AccountDetailAccordions } from "./AccountDetailAccordions";
import { AccountDetailFooter } from "./AccountDetailFooter";
import { AccountDetailHeader } from "./AccountDetailHeader";
import { BillingAlert } from "./components";
import { InactiveLocationsWithLoad } from "./InactiveLocationsWithLoad";
import { LocationDetailModal } from "./LocationDetailModal";
import { getAllPartners, getMinimalAccount, getUser } from "./services";

const renderText = (data: string) => {
    return <Typography color="primary">{data}</Typography>;
};

const renderLogo = (data: string) => {
    return <POSLogo posType={data as POSType} width={70} />;
};

export function AccountDetailPaneManyLocations({ accountId }: { accountId: string }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [update, setUpdate] = useState<number>(0);
    const [accountOwner, setAccountOwner] = useState<User>();
    const [account, setAccount] = useState<MinimalAccountWithLocationStatus>();
    const [activeLocations, setActiveLocations] = useState<(LocationRecord & { status?: IAccountLocationStatus })[]>();
    const [partner, setPartner] = useState<Partner | undefined>(undefined);
    const [partners, setPartners] = useState<Partner[] | undefined>(undefined);

    const isDarkMode = theme.palette.mode === "dark";

    const refresh = () => {
        setUpdate(() => update + 1);
    };

    const [showLocationDetail, locationDetailProps] = useModalEditor<string>(() => {
        refresh();
    });

    useAsyncEffect(async () => {
        const [minimal, accLocationsStatus] = await Promise.all([
            getMinimalAccount(accountId),
            getAccountLocationsStatus(accountId),
        ]);

        if (minimal.name === "<account issue - try logging in again>") {
            setAccount(undefined);
            d30ToastError("Account not found.");
            navigate("/accounts");
            return;
        }
        // If we're looking at an alias, then redirect to the actual account id
        if (minimal.id !== accountId) {
            navigate(`/accounts/${minimal.id}`);
            return;
        }

        setAccount({
            ...minimal,
            status: reduceToSingleAccountLocationStatus(accLocationsStatus),
        });

        let acctOwnerPromise;
        if (minimal.accountOwnerId) {
            acctOwnerPromise = getUser(minimal.accountOwnerId);
        }
        let partnerPromise;
        if (minimal.partnerId) {
            partnerPromise = getPartner(minimal.partnerId);
        }
        const minimalLocationsWithStatus = minimal.minimalLocations.map((ml) => {
            const locationStatus = accLocationsStatus.find((asl) => asl.locationId === ml.id);
            const hasAnyIssue =
                locationStatus?.statusInfo.areBankAccountsRestricted ||
                locationStatus?.statusInfo.arePausedBankAccountsInUse ||
                locationStatus?.statusInfo.hasFailingTaxInfo ||
                locationStatus?.statusInfo.hasMissingBankInfo ||
                locationStatus?.statusInfo.hasMissingTaxInfo ||
                locationStatus?.statusInfo.hasUntestedTaxCredentials ||
                locationStatus?.statusInfo.pausedWithUnpauseDate;
            if (!hasAnyIssue) {
                return ml;
            }
            return {
                ...ml,
                ...(locationStatus ? { status: locationStatus } : {}),
            };
        }) as (LocationRecord & { status?: IAccountLocationStatus })[];
        const sortedLocation = sortBy(
            minimalLocationsWithStatus.filter((l: LocationRecord) => l.active),
            "name"
        );
        setActiveLocations(sortedLocation);
        if (acctOwnerPromise) {
            setAccountOwner(await acctOwnerPromise);
        } else {
            setAccountOwner(undefined);
        }
        if (partnerPromise) {
            setPartner(await partnerPromise);
        } else {
            setPartner(undefined);
        }
        const allPartners = await getAllPartners();
        setPartners(allPartners);
    }, [accountId, update]);

    const activeLocationsColumns = useMemo(() => {
        return [
            {
                Header: "POS Type",
                accessor: "posType",
                Cell: (x: any) => renderLogo(x.value),
            },
            {
                Header: "State",
                accessor: "state",
                Cell: (state: any) => renderText(state.value),
            },
            {
                Header: "Location Name",
                accessor: "name",
                Cell: (x: any) => renderText(x.value),
            },
            {
                Header: "Status",
                accessor: "locationId",
                disableSortBy: true,
                Cell: (data: any) => {
                    const locationId = (data.row.original as LocationRecord).id;
                    const status = data.row.original.status as IAccountLocationStatus | undefined;
                    return (
                        <ConnectionStatus
                            accountId={accountId}
                            locationId={locationId}
                            connectionStatusProvided={
                                status ? mapIAccountLocationsStatusToIConnectionStatus(status) : defaultConnectionStatus
                            }
                            hideMessage={false}
                        />
                    );
                },
            },
            {
                Header: "",
                accessor: "address1",
            },
            {
                Header: "",
                accessor: "address2",
            },
            {
                Header: "",
                accessor: "city",
            },
            {
                Header: "",
                accessor: "county",
            },
            {
                Header: "",
                accessor: "zip",
            },
            {
                Header: "",
                accessor: "id",
                Cell: (x: any) => {
                    return (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => showLocationDetail(x.value)}
                            data-testid={`location_${x.id}_link`}>
                            Open
                        </Button>
                    );
                },
            },
        ];
    }, [showLocationDetail]);

    if (!account) {
        return <Loading />;
    }

    return (
        <>
            <AccountDetailHeader account={account} accountOwnerFirstName={accountOwner?.firstName} partner={partner} />
            <BillingAlert
                locations={activeLocations as Location[] | undefined}
                alertCopy={"Please resolve billing issues"}
                accountId={accountId}
            />
            {!isNil(account.note) && (
                <Alert variant={isDarkMode ? "outlined" : "standard"} style={{ marginBottom: "6px" }} severity="info">
                    <div>
                        <strong>Account Note:</strong>
                        <BodyText>{account.note}</BodyText>
                    </div>
                </Alert>
            )}
            <AccountAlerts accountId={accountId} />

            {activeLocations && activeLocations.length !== 0 && (
                <div style={{ alignItems: "center" }}>
                    <ReactTable
                        columns={activeLocationsColumns}
                        skipPageReset={true}
                        options={{
                            pageSize: 15,
                            pageSizeOptions: [15, 100],
                            hideShowAll: true,
                            headerRowStyle: { verticalAlign: "top" },
                            hiddenColumns: ["address1", "address2", "city", "county", "zip"],
                        }}
                        data={activeLocations}
                    />
                </div>
            )}

            {activeLocations && activeLocations.length === 0 && <Alert severity="warning">No Active Locations</Alert>}

            <AccountDetailAccordions
                accountId={accountId}
                partnerId={partner?.id}
                locations={activeLocations as Location[] | undefined}
            />
            <InactiveLocationsWithLoad accountId={accountId} partner={partner} />
            <AccountDetailFooter
                account={account}
                accountId={accountId}
                partner={partner}
                partners={partners}
                refresh={refresh}
                locations={activeLocations ?? []}
            />
            {locationDetailProps.isDialogOpen && <LocationDetailModal {...locationDetailProps} />}
        </>
    );
}
