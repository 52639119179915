import { d30Toast, d30ToastError, Header, NumberOnlyField, Select, TextField } from "@davo/portal-common";
import {
    FilingPenalty,
    finalizedPenaltyStatuses,
    money,
    OptionalString,
    PenaltyReason,
    PenaltyReasonKeys,
    PenaltyReasons,
    PenaltyStatus,
    PenaltyStatuses,
    PenaltyStatusKeys,
    PenaltyType,
    PenaltyTypeKeys,
    PenaltyTypes,
    toDollars,
    toPennies,
} from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { addNewPenalty, editExistingPenalty } from "./services";

export interface IPenaltyModalType {
    filingId?: string;
    target?: FilingPenalty;
    setOpen: (open: boolean) => void;
    headerText: string;
    commandButtonText: string;
    refresh: () => void;
}

export function PenaltyModal({ filingId, target, setOpen, headerText, commandButtonText, refresh }: IPenaltyModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [penaltyType, setPenaltyType] = useState<PenaltyType | undefined | null>(undefined);
    const [status, setStatus] = useState<PenaltyStatus | undefined>("unworked");
    const [statuses, setStatuses] = useState<PenaltyStatus[]>(["unworked"]);
    const [reason, setReason] = useState<PenaltyReason | undefined | null>(undefined);
    const [amount, setAmount] = useState<number | undefined | null>(undefined);
    const [isDavoResponsible, setIsDavoResponsible] = useState<boolean>(false);
    const [notes, setNotes] = useState<OptionalString>(undefined);

    useAsyncEffect(() => {
        setPenaltyType(target?.type);
        setStatus(target?.status ?? "unworked");
        setReason(target?.reason);
        setAmount(target?.amount);
        setIsDavoResponsible(target?.davoResponsible ?? false);
        setNotes(target?.notes);
    }, [target]);

    useEffect(() => {
        if (!penaltyType) return;
        if (penaltyType !== "penalty") {
            setStatuses(PenaltyStatusKeys.filter((s) => s !== "successfullyWaived"));
        } else {
            setStatuses(PenaltyStatusKeys);
        }
    }, [penaltyType]);

    const shutdown = () => {
        setOpen(false);
    };

    const addEdit = () => {
        if (!status || !reason) {
            return;
        }
        const penaltyAmount = amount && amount > 0 ? amount : undefined;
        const localFilingId = target ? target.filingId : (filingId ?? "");
        if (target) {
            editExistingPenalty(target.filingId, target.id, {
                type: penaltyType,
                status,
                reason,
                amount: penaltyAmount,
                davoResponsible: isDavoResponsible,
                notes,
            })
                .then(() => {
                    refresh();
                    shutdown();
                    d30Toast("Edit penalty complete");
                })
                .catch((e) => {
                    d30ToastError("Problem editing penalty.", e);
                });
        } else {
            addNewPenalty(localFilingId, {
                type: penaltyType,
                status,
                reason,
                amount: penaltyAmount,
                davoResponsible: isDavoResponsible,
                notes,
            })
                .then(() => {
                    refresh();
                    shutdown();
                    d30Toast("Add penalty complete");
                })
                .catch((e) => {
                    d30ToastError("Problem adding penalty.", e);
                });
        }
    };

    return (
        <Dialog
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"md"}
            fullWidth={true}
            onClose={(event, closeReason) => {
                if (closeReason !== "backdropClick") {
                    shutdown();
                }
            }}>
            <DialogTitle id="responsive-dialog-title"></DialogTitle>
            <DialogContent>
                <Header>{headerText}</Header>
                <Select<PenaltyType>
                    title="Type"
                    value={penaltyType}
                    onChange={setPenaltyType}
                    options={PenaltyTypeKeys}
                    label={(typeId: PenaltyType) => PenaltyTypes[typeId]}
                />
                <br />
                <Select<PenaltyStatus>
                    title="Status"
                    value={status}
                    onChange={setStatus}
                    options={statuses}
                    label={(statusId: PenaltyStatus) => PenaltyStatuses[statusId]}
                />
                <br />
                <Select<PenaltyReason>
                    title="Reason"
                    value={reason}
                    onChange={setReason}
                    options={PenaltyReasonKeys}
                    label={(reasonId: PenaltyReason) => PenaltyReasons[reasonId]}
                />
                <br />
                <NumberOnlyField
                    label="Amount"
                    value={toDollars(amount)}
                    formatter={money}
                    onBlur={(amt: number | undefined) => setAmount(toPennies(amt))}
                />
                <br />
                <FormControlLabel
                    control={
                        <Switch
                            checked={isDavoResponsible}
                            onChange={(event: any, isChecked: boolean) => setIsDavoResponsible(isChecked)}
                        />
                    }
                    label="Davo Responsible?"
                />
                <br />
                <TextField value={notes ?? ""} onChange={setNotes} label={"Notes"} isMultiline={true} rows={4} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => shutdown()} variant="outlined" color="primary" style={{ width: "100px" }}>
                    Cancel
                </Button>
                <Button
                    onClick={addEdit}
                    variant="contained"
                    color="primary"
                    style={{ width: "150px" }}
                    disabled={
                        !penaltyType ||
                        !status ||
                        !reason ||
                        (amount === undefined && finalizedPenaltyStatuses.includes(status))
                    }>
                    {commandButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
