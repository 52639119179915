import { get, getArray, getOne, patch, post, put } from "@davo/portal-common";
import {
    AccountLogHistory,
    AccountRecord,
    AccountSearchRecord,
    AccountSummary,
    BusinessDay,
    DailyDetail,
    IAccountDiscount,
    InternalSummary,
    ItemizedDailyDetail,
    MetaAccountLogHistory,
    MetaAccountRecord,
    MetaItemizedDailyDetail,
    MetaMinimalAccountWithLocationInfo,
} from "@davo/types";

export async function getAllAccountsForSearchTerm<T extends AccountSearchRecord | AccountSummary>(
    params: {
        searchTerm: string | undefined;
        advancedSearch: boolean;
        excludeAccountId: string | undefined;
        accountOwner: string | undefined;
    },
    abortController?: AbortController
): Promise<T[]> {
    return post(
        `api/ops/accounts/search`,
        {
            searchTerm: params.searchTerm,
            advancedSearch: params.advancedSearch,
            excludeAccountId: params.excludeAccountId,
            accountOwner: params.accountOwner,
        },
        true,
        undefined,
        abortController
    );
}

export async function getAllAccountsForUser(
    userId: string
): Promise<(AccountRecord & { hasActiveLocations: boolean })[]> {
    return get<(AccountRecord & { hasActiveLocations: boolean })[]>(`api/ops/accounts/user/${userId}`, true);
}

export async function getAccountHistory(accountId: string): Promise<AccountLogHistory[]> {
    return getArray(`api/ops/accounts/history/${accountId}`, true, MetaAccountLogHistory);
}

export async function disableAlert(historyId: string) {
    return post(`api/ops/accounts/disable-alert/${historyId}`, {}, true);
}

export async function getAccount(accountId: string): Promise<AccountRecord> {
    return getOne(`api/ops/accounts/${accountId}/`, true, MetaAccountRecord);
}

export async function getMinimalAccount(accountId: string) {
    return getOne(`api/ops/accounts/minimal/${accountId}`, true, MetaMinimalAccountWithLocationInfo);
}

export async function setAccountNote(accountId: string, accountNote?: string | null) {
    await put(`api/ops/accounts/${accountId}/account-note`, { accountNote }, true);
}

export const updateAccount = async (accountId: string, accountUpdates: Partial<AccountRecord>) => {
    return patch(`api/ops/accounts/${accountId}`, { ...accountUpdates }, true);
};

// NOTE: yearMonth is yyyy-MM (e.g. 2020-08)
export async function getDailyDetailForLocation(
    locationId: string,
    yearMonth: string,
    accountId?: string,
    selectedLocation?: string
): Promise<{ dailyDetails: DailyDetail[]; summary: InternalSummary }> {
    return post(
        `api/ops/accounts/${accountId}/locations/${locationId}/detail/month/${yearMonth}`,
        {
            selectedLocation,
        },
        true
    );
}

export async function getItemizedDailyDetail(
    accountId: string,
    bizday: string,
    identifier: string
): Promise<ItemizedDailyDetail[]> {
    return getArray(
        `api/ops/accounts/${accountId}/locations/${identifier}/detail/${bizday}`,
        true,
        MetaItemizedDailyDetail
    );
}

export async function updateAccountOwner(accountId: string, newAssigneeId: string | undefined) {
    await put(`api/ops/accounts/${accountId}/assignee/${newAssigneeId}`, {}, true);
}

export async function getRecentAccounts() {
    return get<Array<string>>(`api/ops/accounts/recent`, true, {});
}

export async function addAccountDiscount(
    accountId: string,
    reason: string,
    type: string,
    amount: number,
    numMonths?: number
) {
    return post(`api/ops/accounts/${accountId}/add-discount`, { reason, type, amount, numMonths }, true);
}

export async function removeAccountDiscount(accountId: string) {
    return post(`api/ops/accounts/${accountId}/remove-discount`, {}, true);
}

export async function getAccountDiscount(accountId: string, date: BusinessDay) {
    return get<IAccountDiscount>(`api/ops/accounts/${accountId}/discount/date/${date.toSQLDate()}`, true);
}

export async function getNumberOfActiveLocationsForAccount(accountId: string) {
    return get<number>(`api/ops/accounts/${accountId}/locations/numberOfActive`, true);
}

export async function canAddAccountDiscount(accountId: string) {
    return get<boolean>(`api/ops/accounts/${accountId}/discount/can-add`, true);
}
