import { d30ToastError, getPartner, PaperComponent } from "@davo/portal-common";
import { Partner, TaxProfile, TaxProfileWithCredentials } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { TaxCredentialsDetailsModal } from "./TaxCredentialsDetailsModal";
import { TaxProfileCredentialsInfo } from "./TaxProfileCredentialsInfo";
import { TaxProfileCredentialsStatus } from "./components";
import { getAccount, getStatesWithActiveGroupLogins, getTaxProfileWithCredentials } from "./services";

export function TaxProfileCredentialsButton({
    accountId,
    taxProfileId,
    taxProfilePreloaded,
    taxProfileWithCredentialsPreloaded,
    credentialsFailingOverrideStatus,
    isReadOnly,
    callback,
    // careful, if you have it pass this!
    partner,
}: {
    accountId: string;
    taxProfileId: string;
    taxProfilePreloaded?: TaxProfile;
    taxProfileWithCredentialsPreloaded?: TaxProfileWithCredentials;
    credentialsFailingOverrideStatus?: string;
    isReadOnly: boolean;
    callback?: () => void;
    partner?: Partner;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [taxProfile, setTaxProfile] = useState<TaxProfile>();
    const [taxProfileWithCredentials, setTaxProfileWithCredentials] = useState<TaxProfileWithCredentials>();
    const [partnerRec, setPartnerRec] = useState<Partner | undefined>(undefined);
    const [partnerGroupPolicyStates, setPartnerGroupPolicyStates] = useState<string[]>();
    const [internalGroupPolicyStates, setInternalGroupPolicyStates] = useState<string[]>();

    useAsyncEffect(async () => {
        if (taxProfilePreloaded || taxProfileWithCredentialsPreloaded) {
            return;
        }
        const tp = await getTaxProfileWithCredentials(taxProfileId).catch((e) => {
            d30ToastError("Problem getting tax profile.", e);
            return undefined;
        });
        setTaxProfile(tp);
        setTaxProfileWithCredentials(tp);
    }, [taxProfileId]);

    useEffect(() => {
        if (taxProfileWithCredentialsPreloaded) {
            setTaxProfile(taxProfileWithCredentialsPreloaded);
            setTaxProfileWithCredentials(taxProfileWithCredentialsPreloaded);
        }
    }, [taxProfileWithCredentialsPreloaded]);

    useAsyncEffect(async () => {
        if (taxProfilePreloaded) {
            setTaxProfile(taxProfilePreloaded);

            if (!taxProfileWithCredentialsPreloaded) {
                const tp = await getTaxProfileWithCredentials(taxProfilePreloaded.id).catch((e) => {
                    d30ToastError("Problem getting tax profile.", e);
                    return undefined;
                });
                setTaxProfileWithCredentials(tp);
            }
        }
    }, [taxProfilePreloaded]);

    useAsyncEffect(async () => {
        if (!isShowing) {
            return;
        }

        if (partner) {
            setPartnerRec(partner);
        } else if (!partner) {
            // if the surrounding comp has partner, pass it!
            const account = await getAccount(accountId);
            if (account.partnerId) {
                const part = await getPartner(account.partnerId);
                setPartnerRec(part);
            }
        }

        setInternalGroupPolicyStates(await getStatesWithActiveGroupLogins());
    }, [isShowing, accountId, partner]);

    useAsyncEffect(async () => {
        if (partnerRec) {
            setPartnerGroupPolicyStates(await getStatesWithActiveGroupLogins(partnerRec.id));
        } else {
            setPartnerGroupPolicyStates([]);
        }
    }, [partnerRec]);

    const _renderCredInfo = () => {
        if (taxProfile) {
            return <TaxProfileCredentialsStatus taxProfile={taxProfile} />;
        } else {
            return null;
        }
    };

    // eslint-disable-next-line react/display-name
    const TaxCredentialsDetailsModalMemo = React.memo(() => {
        return taxProfileWithCredentials ? <TaxCredentialsDetailsModal taxProfile={taxProfileWithCredentials} /> : null;
    });

    return (
        <div style={{ marginRight: "10px" }}>
            <Button
                variant="outlined"
                color="primary"
                size={"small"}
                style={{ marginTop: "2px", marginBottom: "2px" }}
                onClick={() => setIsShowing(!isShowing)}>
                {_renderCredInfo()} Credentials
            </Button>
            {isShowing && taxProfileWithCredentials && (
                <Dialog
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    style={{ boxShadow: "none" }}
                    maxWidth={"lg"}
                    onClose={(event, reason) => {
                        if (!["backdropClick"].includes(reason)) {
                            setIsShowing(false);
                        }
                    }}>
                    <DialogTitle id="draggable-dialog-title">Filing Credentials</DialogTitle>
                    <DialogContent>
                        <TaxProfileCredentialsInfo
                            partner={partnerRec}
                            isReadOnly={isReadOnly}
                            taxProfileId={taxProfileWithCredentials.id}
                            taxProfileWithCredentialsPreloaded={taxProfileWithCredentials}
                            accountId={accountId}
                            credentialsFailingOverrideStatus={credentialsFailingOverrideStatus}
                            callback={callback}
                            internalGroupPolicyStates={internalGroupPolicyStates}
                            partnerGroupPolicyStates={partnerGroupPolicyStates}
                        />
                    </DialogContent>
                    <DialogActions>
                        <TaxCredentialsDetailsModalMemo />
                        <Button
                            size={"small"}
                            variant="contained"
                            color="primary"
                            onClick={() => setIsShowing(!isShowing)}>
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
