import { get, post } from "@davo/portal-common";

export * from "./accounts";
export * from "./ach";
export * from "./bank";
export * from "./billings";
export * from "./config";
export * from "./dashboard";
export * from "./filings";
export * from "./groupLogin";
export * from "./invitations";
export * from "./locations";
export * from "./manualSetAsides";
export * from "./opspane";
export * from "./outflows";
export * from "./partners";
export * from "./permissions";
export * from "./referrals";
export * from "./sample";
export * from "./taxprofile";
export * from "./users";

export async function getPayorInfo() {
    return get(`api/ops/payorInfo`, true);
}

export async function sendPasswordResetEmail(email: string) {
    await post<void>(`api/ops/send-password-reset-email/${email}`, {}, true);
}

export async function sendAddOpsUserEmail(email: string) {
    await post<void>(`api/ops/send-add-ops-user-email/${email}`, {}, true);
}

export async function autofile(filingId: string, isTest = true) {
    await post<void>(`api/ops/auto-file`, { filingId, test: isTest }, true);
}

export async function testAutofileRows(filingIds: string[]) {
    await post<void>(`api/ops/auto-file-test-rows`, { filingIds }, true);
}

export async function fileAutofileRows(filingIds: string[]) {
    await post<void>(`api/ops/auto-file-file-rows`, { filingIds }, true);
}

export async function getHubSpotId(davoId: string) {
    return get<string>(`api/ops/hubspot/lookup/davo-id/${davoId}`, true);
}
