import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { BillingFailurePane } from "./BillingFailurePane";
import { SetAsideFailuresPane } from "./SetAsideFailuresPane";

export function ACHFailuresPane() {
    return (
        <div>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Billing Failures
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <BillingFailurePane />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Set-aside Failures
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <SetAsideFailuresPane />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
