import { AmendmentOpsPaneRecord, OptionalString } from "@davo/types";
import { DateTime } from "luxon";
import { AddNoteModal } from "./AddNoteModal";
import { setAmendmentAdditionalNote } from "./services";

export type AmendmentWithAdditionalNote = AmendmentOpsPaneRecord & { amendmentAdditionalNoteByName: OptionalString };

export function toNoteable(a: AmendmentWithAdditionalNote) {
    return {
        id: a.filingId,
        note: a.amendmentAdditionalNote,
        noteBy: a.amendmentAdditionalNoteBy,
        noteByName: a.amendmentAdditionalNoteByName,
        noteDate: a.amendmentAdditionalNoteDate,
    };
}

export function AmendmentAddAdditionalNoteModal({ amendment }: { amendment: AmendmentWithAdditionalNote }) {
    const updater = async (
        id: string,
        note: OptionalString,
        noteBy: OptionalString,
        noteByName: OptionalString,
        noteDate: DateTime | undefined | null
    ) => {
        await setAmendmentAdditionalNote(id, note);
        amendment.amendmentAdditionalNote = note;
        amendment.amendmentAdditionalNoteBy = noteBy;
        amendment.amendmentAdditionalNoteByName = noteByName;
        amendment.amendmentAdditionalNoteDate = noteDate;
    };
    return AddNoteModal({ noteable: toNoteable(amendment), updater });
}
