import { CloverBillingStatus, POSType } from "@davo/types";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getCloverBillingStatus } from "./services";

export function CloverBillingStatusRender({ locationId, locationPOS }: { locationId: string; locationPOS: POSType }) {
    const [status, setStatus] = useState<CloverBillingStatus | undefined>();

    useAsyncEffect(async () => {
        setStatus(locationPOS === "clover" ? await getCloverBillingStatus(locationId) : undefined);
    }, [locationId]);

    if (!status || status.status === "ACTIVE") {
        return <div data-testid={"cloverBillingStatus"} />;
    }

    return (
        <div data-testid={"cloverBillingStatus"}>
            Clover billing reports {status.status} <br /> and days lapsed {status.daysLapsed}
        </div>
    );
}
