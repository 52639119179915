import { useGlobalOptional, useLoginContext } from "@davo/portal-common";
import { AutofilerQueueEntry, FilingDetails } from "@davo/types";
import { Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { autofile } from "../services";
import { hasPermission } from "../util";
import { canFileAutofile, canTestAutofile, isBulkProcessing } from "./AutofileUtils";

export interface IAutofilerButtonsType {
    filing?: FilingDetails;
    refreshFiling?: (filingIdChanged: string) => void;
}

export function AutofilerButtons({ filing, refreshFiling }: IAutofilerButtonsType) {
    const loginContext = useLoginContext();
    const [autoFilerMap] = useGlobalOptional<{ [key: string]: AutofilerQueueEntry }>("autoFilerMap");
    const [autoFilerStatus, setAutoFilerStatus] = useState<AutofilerQueueEntry>();

    useEffect(() => {
        if (filing && autoFilerMap && autoFilerMap[filing.id]) {
            setAutoFilerStatus(autoFilerMap[filing.id]);
        } else {
            setAutoFilerStatus(undefined);
        }
    }, [filing, autoFilerMap]);

    const content = useMemo(() => {
        let canTest = false;
        let canFile = false;
        if (!autoFilerStatus) {
            canTest = filing?.canAutofile ?? false;
        } else if (filing) {
            canTest = canTestAutofile(filing.canAutofile, autoFilerStatus);
            canFile = hasPermission(loginContext.permissions, "can_autofile")
                ? canFileAutofile(filing, autoFilerStatus)
                : false;
        }
        return {
            canTest,
            canFile,
        };
    }, [autoFilerStatus, loginContext.permissions, filing]);

    if (!loginContext.user || !filing) {
        return null;
    }

    if (!content.canTest && !content.canFile) {
        return null;
    }

    return (
        <div style={{ display: "flex" }} key={`${filing.id}-buttons`}>
            <Button
                disabled={!content.canTest || isBulkProcessing(filing.bulkTaskId, autoFilerStatus)}
                variant={"contained"}
                color="primary"
                size="small"
                onClick={async () => {
                    await autofile(filing.id, true);
                    refreshFiling && refreshFiling(filing.id);
                }}>
                Test
            </Button>
            &nbsp;
            <Button
                disabled={!content.canFile || isBulkProcessing(filing.bulkTaskId, autoFilerStatus)}
                variant={"contained"}
                color="primary"
                size="small"
                onClick={async () => {
                    await autofile(filing.id, false);
                    refreshFiling && refreshFiling(filing.id);
                }}>
                File
            </Button>
        </div>
    );
}

AutofilerButtons.displayName = "AutofilerButtons";
