import {
    ConfirmationModal,
    LinkButton,
    Loading,
    ReactTable,
    d30Toast,
    d30ToastError,
    useModalEditor,
} from "@davo/portal-common";
import {
    FilingPenaltyExtension,
    PenaltyReasons,
    PenaltyStatuses,
    PenaltyTypes,
    finalizedPenaltyStatuses,
    moneyFromCents,
    toDisplayDateString,
} from "@davo/types";
import { Badge, Button, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PenaltyModal } from "./PenaltyModal";
import { PenaltyNoticeModal } from "./PenaltyNoticeModal";
import { FilingPenaltyAddWellsNoteModal } from "./reconcile-remitted/FilingPenaltyAddWellsNoteModal";
import { WellsTransactionMatchFilingPenalty } from "./reconcile-remitted/WellsTransactionMatchFilingPenalty";
import { WellsTransactionMatchIcon } from "./reconcile-remitted/WellsTransactionMatchIcon";
import {
    deletePenalty,
    getPenaltiesByStatus,
    getPenaltiesForFiling,
    getPenalty,
    markPenaltyReviewed,
} from "./services";

export interface IPenaltyTableType {
    tableTitle?: string;
    filingId?: string;
    statuses?: string[];
    excludeOldPenalties?: boolean;
    hideAccountColumns: boolean;
    excludeWellsMatched?: boolean;
    isDavoResponsibleOnly?: boolean;
    showWells?: boolean;
    penaltyId?: string;
}

export function PenaltyTable({
    tableTitle,
    filingId,
    statuses,
    excludeOldPenalties,
    hideAccountColumns,
    excludeWellsMatched,
    isDavoResponsibleOnly,
    showWells,
    penaltyId,
}: IPenaltyTableType) {
    const controllerRef = useRef<AbortController | undefined>();
    const [filingPenalties, setFilingPenalties] = useState<FilingPenaltyExtension[]>();
    const [isFilingPenaltyDialogOpen, setIsFilingPenaltyDialogOpen] = useState<boolean>(false);
    const [isNoticeDialogOpen, setIsNoticeDialogOpen] = useState<boolean>(false);
    const [filingPenaltyDialogFilingPenalty, setFilingPenaltyDialogFilingPenalty] = useState<
        FilingPenaltyExtension | undefined
    >(undefined);
    const [filingPenaltyDialogFilingId, setFilingPenaltyDialogFilingId] = useState<string | undefined>(undefined);
    const [filingPenaltyDialogHeaderText, setFilingPenaltyDialogHeaderText] = useState<string>("");
    const [filingPenaltyDialogCommandButtonText, setFilingPenaltyDialogCommandButtonText] = useState<string>("");
    const [hiddenAccountColumns, setHiddenAccountColumns] = useState<string[]>();

    const [showConfirmationModal, confirmationModalProps] = useModalEditor<FilingPenaltyExtension>(
        (filingPenalty?: FilingPenaltyExtension) => {
            if (filingPenalty) {
                deletePenalty(filingPenalty.filingId, filingPenalty.id)
                    .then(() => {
                        d30Toast("Penalty has been deleted");
                        refresh();
                    })
                    .catch((e) => {
                        d30ToastError("Problem deleting penalty.", e);
                    });
            }
        }
    );

    useEffect(() => {
        const columns = [];
        if (hideAccountColumns) {
            columns.push("accountName");
            columns.push("periodEnd");
        }
        setHiddenAccountColumns(columns);
    }, [hideAccountColumns]);

    const refresh = useCallback(() => {
        setFilingPenalties(undefined);
        if (penaltyId) {
            getPenalty(penaltyId)
                .then((results) => {
                    setFilingPenalties([results]);
                })
                .catch((e) => d30ToastError("Problem getting penalty.", e));
        } else if (filingId) {
            getPenaltiesForFiling(filingId)
                .then((results) => {
                    setFilingPenalties(results);
                })
                .catch((e) => d30ToastError("Problem getting penalties for filing.", e));
        } else if (statuses) {
            if (controllerRef?.current) {
                controllerRef.current.abort();
            }
            controllerRef.current = new AbortController();

            getPenaltiesByStatus(
                statuses,
                excludeOldPenalties ?? false,
                excludeWellsMatched ?? false,
                isDavoResponsibleOnly ?? false
            )
                .then((results) => {
                    setFilingPenalties(results);
                })
                .catch((e) => d30ToastError("Problem getting penalties.", e));
        }
    }, [excludeOldPenalties, excludeWellsMatched, filingId, isDavoResponsibleOnly, penaltyId, statuses]);

    useEffect(() => {
        refresh();
    }, [refresh, statuses, excludeOldPenalties, penaltyId]);

    const doMarkReviewed = useCallback(
        (filingPenalty: FilingPenaltyExtension) => {
            markPenaltyReviewed(filingPenalty.filingId, filingPenalty.id)
                .then(() => {
                    d30Toast("Marked as reviewed");
                    refresh();
                })
                .catch((e) => {
                    d30ToastError("Problem marking as reviewed.", e);
                });
        },
        [refresh]
    );

    const doDelete = useCallback(
        (filingPenalty: FilingPenaltyExtension) => {
            showConfirmationModal(filingPenalty);
        },
        [showConfirmationModal]
    );

    const renderAccountName = (data: any) => {
        return (
            <LinkButton
                url={`/filings/${data.filingId}`}
                label={`${data.accountName}`}
                labelMaxChars={35}
                align={"left"}
                rel="noreferrer"
                target="_blank"
            />
        );
    };

    const filingPenaltiesColumns = useMemo(() => {
        const canWellsMatch = (filingPenalty: FilingPenaltyExtension) => {
            return showWells && filingPenalty.status === "completed" && filingPenalty.davoResponsible;
        };

        return [
            {
                Header: "Last Reviewed",
                id: "updated",
                accessor: "updated",
                Cell: (data: any) => {
                    const penalty = data.row.original;
                    return (
                        <>
                            <span style={{ whiteSpace: "nowrap" }}>
                                <span>{toDisplayDateString(penalty.updated)}</span>
                                {canWellsMatch(penalty) && <FilingPenaltyAddWellsNoteModal penalty={penalty} />}
                            </span>
                        </>
                    );
                },
            },
            {
                Header: "Name",
                id: "accountName",
                accessor: "accountName",
                Cell: (data: any) => {
                    return renderAccountName(data.row.original);
                },
            },
            {
                Header: "State",
                id: "state",
                accessor: "state",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Type",
                id: "type",
                accessor: "type",
                Cell: (data: any) => <>{PenaltyTypes[data.value]}</>,
            },
            {
                Header: "Status",
                id: "status",
                accessor: "status",
                Cell: (data: any) => <>{PenaltyStatuses[data.value]}</>,
            },
            {
                Header: "Reason",
                id: "reason",
                accessor: "reason",
                Cell: (data: any) => <>{PenaltyReasons[data.value]}</>,
            },
            {
                Header: "Amount",
                id: "amount",
                accessor: "amount",
                Cell: (data: any) => {
                    const penalty = data.row.original;
                    return (
                        <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                            {data.value ? moneyFromCents(data.value) : ""}
                            {canWellsMatch(penalty) && (
                                <>
                                    <WellsTransactionMatchIcon wfTransactionId={penalty.wellsTxn} onChange={refresh} />
                                    {!penalty.wellsTxn && (
                                        <WellsTransactionMatchFilingPenalty
                                            penaltyId={penalty.id}
                                            amount={penalty.amount}
                                            onMatch={() => refresh()}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "Davo Responsible",
                id: "davoResponsible",
                accessor: "davoResponsible",
                Cell: (data: any) => <div>{data.value ? "Yes" : "No"}</div>,
            },
            {
                Header: "Period End",
                id: "periodEnd",
                accessor: "periodEnd",
                Cell: (data: any) => <>{data.value ? toDisplayDateString(data.value) : ""}</>,
            },
            {
                Header: "Notes",
                id: "notes",
                accessor: "notes",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Created By",
                id: "createdByName",
                accessor: "createdByName",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "",
                id: "actions",
                accessor: "id",
                disableSortBy: true,
                Cell: (data: any) => {
                    const filingPenalty = data.row.original;
                    return (
                        <Stack direction="column">
                            <Stack direction="row">
                                <Button
                                    style={{ margin: "5px" }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        setFilingPenaltyDialogFilingId(undefined);
                                        setFilingPenaltyDialogFilingPenalty(filingPenalty);
                                        setFilingPenaltyDialogHeaderText("Edit Penalty");
                                        setFilingPenaltyDialogCommandButtonText("Update");
                                        setIsFilingPenaltyDialogOpen(true);
                                    }}>
                                    Edit
                                </Button>
                                <Badge
                                    color={"primary"}
                                    badgeContent={
                                        filingPenalty.noticeIds && filingPenalty.noticeIds.length > 0
                                            ? filingPenalty.noticeIds.length
                                            : null
                                    }>
                                    <Button
                                        style={{ margin: "5px" }}
                                        variant={"outlined"}
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setFilingPenaltyDialogFilingPenalty(filingPenalty);
                                            setIsNoticeDialogOpen(true);
                                        }}>
                                        Notices
                                    </Button>
                                </Badge>
                            </Stack>

                            {!finalizedPenaltyStatuses.includes(filingPenalty.status) && (
                                <Stack direction="row">
                                    <Button
                                        style={{ margin: "5px" }}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={() => doMarkReviewed(filingPenalty)}>
                                        Mark Reviewed
                                    </Button>
                                    <Button
                                        style={{ margin: "5px" }}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={() => doDelete(filingPenalty)}>
                                        Delete
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                    );
                },
            },
        ];
    }, [doDelete, doMarkReviewed, refresh, showWells]);

    if (!filingPenalties) {
        return <Loading />;
    }

    return (
        <>
            {filingId && (
                <Button
                    style={{ margin: "5px" }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                        setFilingPenaltyDialogFilingId(filingId);
                        setFilingPenaltyDialogFilingPenalty(undefined);
                        setFilingPenaltyDialogHeaderText("Add Penalty");
                        setFilingPenaltyDialogCommandButtonText("Add");
                        setIsFilingPenaltyDialogOpen(true);
                    }}>
                    Add Penalty
                </Button>
            )}

            {penaltyId && (
                <Button
                    style={{ margin: "5px" }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                        penaltyId = undefined;
                    }}>
                    Back to list
                </Button>
            )}
            <ReactTable
                title={tableTitle ?? ""}
                columns={filingPenaltiesColumns}
                data={filingPenalties}
                refreshData={refresh}
                options={{
                    pageSize: 10,
                    hiddenColumns: hiddenAccountColumns,
                    headerRowStyle: { verticalAlign: "top" },
                }}
            />
            {confirmationModalProps.isDialogOpen && (
                <ConfirmationModal
                    message="Are you sure you want to delete this penalty?"
                    title="Delete penalty?"
                    continueLabel="Delete"
                    {...confirmationModalProps}
                />
            )}
            {isFilingPenaltyDialogOpen && (
                <PenaltyModal
                    filingId={filingPenaltyDialogFilingId}
                    target={filingPenaltyDialogFilingPenalty}
                    setOpen={setIsFilingPenaltyDialogOpen}
                    headerText={filingPenaltyDialogHeaderText}
                    commandButtonText={filingPenaltyDialogCommandButtonText}
                    refresh={refresh}
                />
            )}
            {isNoticeDialogOpen && (
                <PenaltyNoticeModal
                    target={filingPenaltyDialogFilingPenalty}
                    setOpen={setIsNoticeDialogOpen}
                    refresh={refresh}
                />
            )}
        </>
    );
}
