import { d30Toast, PaperComponent, POSLogo, ReactTable } from "@davo/portal-common";
import { POSType, toDisplayDateTimeString } from "@davo/types";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import useAsyncEffect from "use-async-effect";
import { getPOSCredentials, testPOSCredentials } from "./services";

export function POSCredentialsButton({
    locationId,
    locationPOS,
    showAsText,
}: {
    locationId: string;
    locationPOS: POSType;
    showAsText?: boolean;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [tableData, setTableData] = useState<{ title: string; value: string }[]>();

    useAsyncEffect(async () => {
        if (!isShowing) {
            return;
        }
        const check = await testPOSCredentials(locationId, `${locationPOS}`);
        const creds = await getPOSCredentials(locationId);

        const data = [
            { title: "DAVO Location Id", value: creds?.locationId },
            { title: "POS Merchant Id", value: creds?.posMerchant },
            { title: "POS Location Id", value: creds?.posLocation },
        ];
        if (creds?.token) {
            data.push({ title: "Token", value: creds?.token });
        }
        if (creds?.updated) {
            const updated = toDisplayDateTimeString(creds.updated);
            data.push({ title: "Creds Updated", value: updated });
        }
        if (creds?.disconnected) {
            data.push({
                title: "Disconnected",
                value: toDisplayDateTimeString(creds.disconnected),
            });
        }
        if (check && Object.prototype.hasOwnProperty.call(check, "valid")) {
            data.push({ title: "Valid", value: `${check.valid.toString()}` });
        }
        if (check?.validityPeriod) {
            data.push({
                title: "Validity Period",
                value: `${check.validityPeriod}`,
            });
        }
        if (check?.validUntil) {
            data.push({
                title: "Valid Until",
                value: toDisplayDateTimeString(check.validUntil),
            });
        }
        setTableData(data);
    }, [isShowing, locationId]);

    const columns = useMemo(() => {
        return [
            {
                Header: "",
                accessor: "title",
            },
            {
                Header: "",
                accessor: "value",
                Cell: (data: any) => (
                    <>
                        {data.value}&nbsp;&nbsp;
                        {!!data.value &&
                            !["Creds Updated", "Disconnected"].includes(data.cell.row.original.title) &&
                            !data.cell.row.original.title.startsWith("Valid") && (
                                <CopyToClipboard text={data.value} onCopy={() => d30Toast("Copied")}>
                                    <FileCopyTwoToneIcon
                                        fontSize="small"
                                        color="primary"
                                        style={{ verticalAlign: "middle" }}
                                    />
                                </CopyToClipboard>
                            )}
                    </>
                ),
            },
        ];
    }, []);

    return (
        <div style={{ marginRight: "10px", padding: "10px 0" }}>
            {showAsText ? (
                <div onClick={() => setIsShowing(!isShowing)}>{locationPOS}</div>
            ) : (
                <Button onClick={() => setIsShowing(!isShowing)} style={{ padding: 0 }}>
                    <POSLogo posType={locationPOS} width={125} />
                </Button>
            )}
            {isShowing && tableData && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">POS Credentials</DialogTitle>
                    <DialogContent>
                        <ReactTable
                            columns={columns}
                            data={tableData}
                            refreshData={tableData}
                            options={{
                                hideToolbar: true,
                                pageSize: tableData.length,
                                pageSizeOptions: [tableData.length],
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => setIsShowing(!isShowing)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
