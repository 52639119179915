import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { BankNSFPane } from "./BankNSFPane";
import { BankPausedPane } from "./BankPausedPane";

export function BankFailuresPane() {
    return (
        <div>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Paused for too many NSFs
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <BankNSFPane />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Bank Paused
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <BankPausedPane />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
