import { d30ToastError, LinkButton, Loading, ReactTable } from "@davo/portal-common";
import { NSFOpsPaneRecord, toDisplayDateString } from "@davo/types";
import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getNSFLocations } from "./services";
import { displayPhone } from "./util";

const dateFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>}</>;
};

const renderAccountName = (data: any) => {
    return (
        <>
            <LinkButton
                url={`/accounts/${data.cell.row.original.accountId}`}
                label={data.cell.row.original.name || "Account"}
                labelMaxChars={35}
                align={"left"}
            />
        </>
    );
};

const renderPhones = (data: any) => {
    return <>{data.value?.split(" ").map((p: any) => displayPhone(p))}</>;
};

const renderEmails = (data: any) => {
    const emails = data.value ? data.value.split(" ") : [];
    return emails.map((e: string) => {
        return (
            <Typography color={"primary"} key={e}>
                <a href={`mailto:${e}`} style={{ marginRight: "8px", color: "inherit" }}>
                    {e}
                </a>
            </Typography>
        );
    });
};

export function BankNSFPane() {
    const [locations, setLocations] = useState<NSFOpsPaneRecord[]>();

    const refresh = useCallback(() => {
        setLocations(undefined);
        getNSFLocations()
            .then((results) => setLocations(results))
            .catch((e) => d30ToastError("Problem loading data", e));
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Paused",
                accessor: "date" as const,
                Cell: dateFormat,
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: renderAccountName,
                width: "auto",
            },
            { Header: "Emails", accessor: "emails", Cell: renderEmails },
            {
                Header: "Phones",
                accessor: "phones",
                Cell: renderPhones,
            },
        ];
    }, []);

    if (!locations) {
        return <Loading />;
    }
    return (
        <div>
            <ReactTable
                columns={columns}
                data={locations}
                refreshData={refresh}
                options={{
                    pageSize: 10,
                }}
            />
        </div>
    );
}
