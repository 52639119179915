import React from "react";
import { useMatch } from "react-router-dom";
import { LocationNoAccountDetailPane } from "./LocationNoAccountDetailPane";
import { NoAccountSearch } from "./NoAccountSearch";

export const NoAccount = () => {
    const match = useMatch("/no-account/location/:locationId");
    const { locationId } = match?.params ?? {};

    if (locationId) {
        return <LocationNoAccountDetailPane locationId={locationId} />;
    } else {
        return <NoAccountSearch />;
    }
};
