import {
    d30Toast,
    d30ToastError,
    ORPHAN_RELATED_ACCOUNT_ID,
    PaperComponent,
    PartialAccount,
} from "@davo/portal-common";
import { initialCap, RelatedLocation, validateNotNull } from "@davo/types";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CloseIcon from "@mui/icons-material/Close";
import {
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfirmExtractionDetails } from "./ExtractLocation/ConfirmExtractionDetails";
import { ChooseAccount } from "./ExtractLocation/Steps/ChooseAccount";
import { ChooseDestination } from "./ExtractLocation/Steps/ChooseDestination";
import { ExtractionStep, ExtractionStepType, MergeDestination } from "./ExtractLocation/Steps/ExtractionTypes";
import {
    getAccount,
    mergeLocationToAccount,
    mergeLocationToNewAccount,
    redeemInvitationToAccount,
    redeemInvitationToNewAccount,
} from "./services";

export interface ILocationExtractionWizard {
    mergeType: "Redeem" | "Extract" | undefined;
    relatedLocations: RelatedLocation[];
    id: { accountId?: string; invitationId?: string };
    locationId: string;
    locationName: string;
    closeDialog(): void;
}

const steps: ExtractionStepType[] = ["chooseDestination", "chooseAccount", "confirm"];
const finalStepIdx = steps.length - 1;
const getIdxForStep = (step: ExtractionStepType) => steps.indexOf(step);
const wizId = "draggable-dialog-title";
const getStepperTitle = (step: ExtractionStepType) => {
    switch (step) {
        case "chooseDestination":
            return "Destination Type";
        case "chooseAccount":
            return "Target Account";
        case "confirm":
            return "Confirm";
        default:
            return null;
    }
};
const getNewAccountNameValidationError = (v: string) => (v ? undefined : validateNotNull(v));

export const LocationExtractionWizard: FunctionComponent<ILocationExtractionWizard> = ({
    mergeType,
    relatedLocations,
    id,
    locationId,
    locationName,
    closeDialog,
}) => {
    const [sourceAccount, setSourceAccount] = useState<PartialAccount>();
    const [extractionStep, setExtractionStep] = useState<ExtractionStepType>("chooseDestination");
    const [extractionStepIdx, setExtractionStepIdx] = useState<number>(getIdxForStep(extractionStep));
    const [isOnFirstStep, setIsOnFirstStep] = useState<boolean>(true);
    const [isOnLastStep, setIsOnLastStep] = useState<boolean>(false);
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const [isExtracting, setIsExtracting] = useState<boolean>(false);
    const navigate = useNavigate();

    // step 1
    const [mergeDestination, setMergeDestination] = useState<MergeDestination>("existing");
    const handleMergeDestinationChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) =>
        setMergeDestination(value);

    // step 2
    const [newAccountName, setNewAccountName] = useState<string>("");
    const handleNewAccountNameChange = (val: string) => setNewAccountName(val);

    const [selectedAccount, setSelectedAccount] = useState<PartialAccount>();
    const handleSelectAccount = async (acct: PartialAccount) => {
        const accountRecord = await getAccount(acct.id);
        setSelectedAccount({
            ...acct,
            partnerId: accountRecord.partnerId,
        });
    };

    // step 3
    const [shouldConfirmPartnerBilling, setShouldConfirmPartnerBilling] = useState<boolean>(false);
    const [hasConfirmedPartnerBilling, setHasConfirmedPartnerBilling] = useState<boolean>(false);

    const getStepperOptionalContent = (step: ExtractionStepType) => {
        switch (step) {
            case "chooseDestination":
                return `${initialCap(mergeDestination)} account`;
            case "chooseAccount":
                return selectedAccount?.name ?? newAccountName;
            case "confirm":
                if (sourceAccount?.partnerId || selectedAccount?.partnerId) {
                    return !hasConfirmedPartnerBilling ? <AssignmentLateIcon /> : <AssignmentTurnedInIcon />;
                } else {
                    return null;
                }
            default:
                return null;
        }
    };

    const getStepperContent = (step: ExtractionStepType) => {
        switch (step) {
            case "chooseDestination":
                return (
                    <ChooseDestination
                        mergeDestination={mergeDestination}
                        handleMergeDestinationChange={handleMergeDestinationChange}
                    />
                );
            case "chooseAccount":
                return (
                    <ChooseAccount
                        mergeDestination={mergeDestination}
                        newAccountName={newAccountName}
                        handleNewAccountNameChange={handleNewAccountNameChange}
                        relatedLocations={relatedLocations}
                        selectedAccount={selectedAccount}
                        handleSelectAccount={handleSelectAccount}
                        exclude={id.accountId}
                    />
                );
            case "confirm":
                return (
                    <ConfirmExtractionDetails
                        mergeType={mergeType}
                        sourceAccount={sourceAccount}
                        newAccountName={newAccountName}
                        mergeDestination={mergeDestination}
                        locationName={locationName}
                        locationId={locationId}
                        selectedAccount={selectedAccount}
                        shouldRequirePartnerConfirm={shouldConfirmPartnerBilling}
                        hasConfirmedPartnerBilling={hasConfirmedPartnerBilling}
                        setHasConfirmedPartnerBilling={setHasConfirmedPartnerBilling}
                    />
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const idx = getIdxForStep(extractionStep);
        setExtractionStepIdx(idx);
        setIsOnFirstStep(idx === 0);
        setIsOnLastStep(idx >= finalStepIdx);
    }, [extractionStep]);

    useEffect(() => {
        let isValid = false;
        switch (extractionStep) {
            case "chooseAccount": {
                if (mergeDestination === "new") {
                    const isNewAccountNameInvalid = !!getNewAccountNameValidationError(newAccountName);
                    isValid = !isNewAccountNameInvalid;
                } else if (mergeDestination === "existing") {
                    isValid = !!selectedAccount;
                }
                break;
            }
            case "confirm": {
                isValid = true;
                break;
            }
            case "chooseDestination":
            default: {
                isValid = true;
            }
        }
        setIsNextDisabled(!isValid);
    }, [extractionStep, mergeDestination, newAccountName, selectedAccount]);

    useEffect(() => {
        if (mergeDestination === "new") {
            setSelectedAccount(undefined);
        } else if (mergeDestination === "existing") {
            setNewAccountName("");
        }
    }, [mergeDestination]);

    useEffect(() => {
        if (id.accountId && id.accountId !== ORPHAN_RELATED_ACCOUNT_ID) {
            getAccount(id.accountId)
                .then((existingAccount) => {
                    setSourceAccount(existingAccount);
                })
                .catch((e) => d30ToastError("Problem getting existing account.", e));
        }
    }, [id]);

    useEffect(() => {
        if (selectedAccount && selectedAccount.id !== ORPHAN_RELATED_ACCOUNT_ID) {
            setHasConfirmedPartnerBilling(false);
        }
    }, [selectedAccount]);

    useEffect(() => {
        const hasPartnerBillingImplication = !!selectedAccount?.partnerId || !!sourceAccount?.partnerId;
        setShouldConfirmPartnerBilling(hasPartnerBillingImplication);
    }, [sourceAccount, selectedAccount]);

    const handleNext = () => {
        const nextStepIdx = extractionStepIdx + 1;
        if (!isOnLastStep) {
            const nextStepKey = ExtractionStep[nextStepIdx] as ExtractionStepType;
            setExtractionStep(nextStepKey);
        }
    };

    const handleBack = () => {
        const prevStepIdx = extractionStepIdx - 1;
        const nextStepKey = ExtractionStep[prevStepIdx];
        setExtractionStep(nextStepKey as ExtractionStepType);
    };

    const doMergeToNewAccount = () => {
        if (isExtracting) return;
        setIsExtracting(true);
        const extractionToast = d30Toast("Extracting to new account");

        const mergePromise: Promise<{ accountId: string }> = id.invitationId
            ? redeemInvitationToNewAccount(id.invitationId, newAccountName, [locationId])
            : mergeLocationToNewAccount(locationId, newAccountName);

        return mergePromise
            .then((newAccount) => {
                extractionToast && toast.dismiss(extractionToast);
                d30Toast("Extraction to new account complete.");
                navigate(`/accounts/${newAccount.accountId}`);
            })
            .catch((e) => {
                d30ToastError("Problem merging location to new account", e);
                setIsExtracting(false);
            });
    };

    const doMergeToExistingAccount = () => {
        if (!selectedAccount) return;
        if (isExtracting) return;
        setIsExtracting(true);
        const extractionToast = d30Toast("Extracting to existing account");

        const mergePromise: Promise<{}> = id.invitationId
            ? redeemInvitationToAccount(id.invitationId, selectedAccount.id, [locationId])
            : mergeLocationToAccount(locationId, selectedAccount.id);

        return mergePromise
            .then(() => {
                extractionToast && toast.dismiss(extractionToast);
                d30Toast("Extraction to existing account complete");
                navigate(`/accounts/${selectedAccount.id}`);
            })
            .catch((e) => {
                extractionToast && toast.dismiss(extractionToast);
                d30ToastError(e.message, e);
                setIsExtracting(false);
            });
    };

    const handleSubmit = async () => {
        if (mergeDestination === "new") {
            await doMergeToNewAccount();
        } else if (mergeDestination === "existing") {
            await doMergeToExistingAccount();
        }
    };

    return (
        <Dialog
            data-testid={"locationExtractionWiz"}
            PaperComponent={PaperComponent}
            aria-labelledby={wizId}
            fullWidth={true}
            maxWidth={"md"}
            open={true}
            onClose={() => !isExtracting && closeDialog()}>
            <DialogTitle id={wizId}>
                <Stack justifyContent={"space-between"} direction="row" alignItems={"start"}>
                    <Typography component={"div"}>
                        <Typography variant={"h4"} component={"h2"}>
                            {mergeType} location
                        </Typography>
                        <Typography variant={"subtitle2"} component={"span"}>
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                {[
                                    <span key={"locationToExtract"}>{locationName}</span>,
                                    <span key={"targetAccount"}>
                                        {mergeDestination === "new" &&
                                            (newAccountName ? (
                                                newAccountName
                                            ) : (
                                                <AddBoxIcon
                                                    style={{ verticalAlign: "text-bottom", fontSize: "large" }}
                                                />
                                            ))}

                                        {mergeDestination === "existing" &&
                                            (selectedAccount?.name || (
                                                <AccountBoxIcon
                                                    style={{ verticalAlign: "text-bottom", fontSize: "large" }}
                                                />
                                            ))}
                                    </span>,
                                ]}
                            </Breadcrumbs>
                        </Typography>
                    </Typography>

                    <IconButton disabled={isExtracting} onClick={closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stepper style={{ margin: "20px auto 40px" }} activeStep={extractionStepIdx} alternativeLabel>
                    {steps.map((stepType) => (
                        <Step key={stepType}>
                            <StepLabel
                                data-testid={`${stepType}-step-label`}
                                optional={getStepperOptionalContent(stepType)}>
                                {getStepperTitle(stepType)}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>{getStepperContent(extractionStep)}</div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBack}
                    disabled={isOnFirstStep || isExtracting}>
                    Back
                </Button>
                {!isOnLastStep && (
                    <Button
                        data-testid={"nextBtn"}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={isNextDisabled}>
                        Next
                    </Button>
                )}
                {isOnLastStep && (
                    <Button
                        data-testid={"submitExtraction"}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        startIcon={
                            isExtracting && (
                                <CircularProgress
                                    data-testid={"extraction-in-progress"}
                                    disableShrink
                                    size={"1rem"}
                                    style={{ color: "inherit" }}
                                />
                            )
                        }
                        disabled={(shouldConfirmPartnerBilling && !hasConfirmedPartnerBilling) || isExtracting}>
                        {mergeType}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
