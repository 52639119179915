import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { AmendmentsCompletedPane } from "./AmendmentsCompletedPane";
import { AmendmentsRequiredPane } from "./AmendmentsRequiredPane";

export function AmendmentsPane() {
    return (
        <div>
            <div style={{ width: "100%" }}>
                <AmendmentsRequiredPane />
            </div>
            <br />
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Completed Amendments</AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <AmendmentsCompletedPane />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
