import { d30ToastError, PaperComponent, Select, TextField } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { setManualFiling } from "./services";

const manualFilingReasons = [
    "Fast Files",
    "Filing not supported (Alt. website or filing type)",
    "Multiple locations on filing",
    "Multiple jurisdictions on filing",
    "Tax rate configuration not supported",
    "Pre-payment not supported",
    "Other",
];

export interface IManualFilingReasons {
    taxProfileId: string;
    callback: () => void;
    isManual: boolean;
}

export function ManualFilingReasonModal({ taxProfileId, callback, isManual }: IManualFilingReasons) {
    const [reason, setReason] = useState<string | undefined>(undefined);
    const [isOpenTextArea, setIsOpenTextArea] = useState<boolean>(false);
    const [note, setNote] = useState<string | undefined>(undefined);
    const [isShowing, setIsShowing] = useState<boolean>(false);

    const doSaveReason = async () => {
        try {
            if (reason === "Other") {
                await setManualFiling(taxProfileId, !isManual, note);
                setNote(undefined);
            } else {
                await setManualFiling(taxProfileId, !isManual, reason);
                setReason(undefined);
            }
            setIsShowing(false);
            callback();
        } catch (e: any) {
            d30ToastError(e.message);
        }
    };

    useAsyncEffect(() => {
        if (reason === "Other" && !isOpenTextArea) {
            setIsOpenTextArea(true);
        }
    }, [reason]);

    return (
        <div style={{ marginRight: "10px" }}>
            {isManual ? (
                <Button
                    data-testid={"turnOffManualFilingButton"}
                    disabled={false}
                    variant="outlined"
                    color="primary"
                    size={"small"}
                    style={{ margin: "5px 0" }}
                    onClick={doSaveReason}>
                    Turn off Manual Filing
                </Button>
            ) : (
                <Button
                    data-testid={"requireManualFilingButton"}
                    disabled={false}
                    variant="outlined"
                    color="primary"
                    size={"small"}
                    style={{ marginTop: "2px", marginBottom: "2px" }}
                    onClick={() => setIsShowing(!isShowing)}>
                    Require Manual Filing
                </Button>
            )}

            {isShowing && (
                <Dialog
                    data-testid={"manualReasonModal"}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Set Manual Filing</DialogTitle>
                    <DialogContent>
                        <Select<string>
                            data-testid={"manualReasonSelector"}
                            title="Type"
                            label={(s) => s}
                            options={manualFilingReasons}
                            value={reason}
                            onChange={setReason}
                        />
                    </DialogContent>
                    {isOpenTextArea && (
                        <TextField
                            label={"Reason for requiring manual filing"}
                            value={note ?? ""}
                            onChange={setNote}
                            isMultiline={true}
                            rows={4}
                        />
                    )}
                    <DialogActions>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setIsShowing(!isShowing);
                                }}>
                                Cancel
                            </Button>
                            <Button
                                style={{ marginLeft: "5px" }}
                                disabled={!reason || (isOpenTextArea && !note)}
                                variant="contained"
                                onClick={doSaveReason}
                                color="primary">
                                Save
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
