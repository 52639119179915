import {
    d30Toast,
    d30ToastError,
    MultiSelect,
    PaperComponent,
    Select,
    TextField,
    useLoginContext,
} from "@davo/portal-common";
import { AppType, AppTypeDescriptions, AppTypes, IPermission, isEmailValid, UserPermissionAppType } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import {
    createOpsUser,
    createUserPermissions,
    getPermissions,
    getPermissionsWithFilter,
    sendAddOpsUserEmail,
} from "./services";
import { hasPermission } from "./util";

export function AddOpsUserModal() {
    const loginContext = useLoginContext();
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [permissions, setPermissions] = useState<IPermission[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<IPermission[]>([]);
    const [selectedApp, setSelectedApp] = useState<AppTypes | undefined>(undefined);
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const updatePermissions = (permissionsPromise: Promise<IPermission[]>) => {
        permissionsPromise
            .then((perms) => {
                setPermissions(perms);
            })
            .catch((e) => d30ToastError("Problem with retrieving permissions.", e));
    };

    useEffect(() => {
        const permissionsPromise = getPermissions();
        updatePermissions(permissionsPromise);
    }, []);

    useEffect(() => {
        if (!isNil(selectedApp)) {
            const permissionsPromise = getPermissionsWithFilter(selectedApp);
            updatePermissions(permissionsPromise);
        } else {
            const permissionsPromise = getPermissions();
            updatePermissions(permissionsPromise);
        }
    }, [selectedApp]);

    const addOpsUser = async () => {
        try {
            if (email) {
                const opsUser = await createOpsUser(firstName, lastName, email, phone);
                d30Toast("Ops user added");

                if (opsUser && !isEmpty(selectedPermissions)) {
                    await createUserPermissions(
                        opsUser.id,
                        selectedPermissions.map((permission) => permission.permissionTag)
                    );
                    d30Toast("Ops permissions created");
                }

                await sendAddOpsUserEmail(email);
                d30Toast("Portal Link sent to user via email");
                _shutdown();
            }
        } catch (e: any) {
            d30ToastError(e.message);
        }
    };

    const _shutdown = () => {
        setIsCreating(false);
        setSelectedPermissions([]);
        setFirstName(undefined);
        setLastName(undefined);
        setEmail(undefined);
        setPhone(undefined);
        setSelectedApp(undefined);
    };

    return (
        <div>
            <div style={{ display: "inline-flex", width: "100%", justifyContent: "space-between" }}>
                <h2>Search For User</h2>
                <Button
                    disabled={!hasPermission(loginContext.permissions, "add_ops_user")}
                    onClick={() => setIsCreating(true)}>
                    <Typography style={{ paddingBottom: "8px", cursor: "pointer" }}>
                        <Icon>add_circle_outline</Icon> Add Ops User
                    </Typography>
                </Button>
            </div>
            {isCreating && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    maxWidth={"lg"}
                    fullScreen={makeFullScreen}
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                            _shutdown();
                        }
                    }}>
                    <DialogTitle id="draggable-dialog-title">Add Ops User</DialogTitle>
                    <DialogContentText>The Ops user will be set as admin</DialogContentText>
                    <DialogContent style={{ display: "grid" }}>
                        <TextField label="First Name" value={firstName ?? ""} onChange={setFirstName} />
                        <TextField label="Last Name" value={lastName ?? ""} onChange={setLastName} />
                        <TextField label="Email Address" value={email ?? ""} onChange={setEmail} />
                        <TextField label="Phone" value={phone ?? ""} onChange={setPhone} />
                        <br />
                        <Typography>App:</Typography>
                        <div
                            style={{
                                marginBottom: "10px",
                                overflowY: "scroll",
                                border: "1px solid #CCCCCC",
                                borderRadius: "5px",
                            }}>
                            <Select<AppTypes>
                                noneLabel={`Select an App...`}
                                options={Object.keys(AppTypes).sort() as AppTypes[]}
                                value={selectedApp}
                                onChange={setSelectedApp}
                                label={(value) => <>{`${UserPermissionAppType[value as AppType]}`}</>}
                            />
                        </div>
                        <Typography>Permissions:</Typography>
                        <div
                            style={{
                                marginBottom: "10px",
                                overflowY: "scroll",
                                border: "1px solid #CCCCCC",
                                borderRadius: "5px",
                            }}>
                            <MultiSelect<IPermission>
                                style={{ textOverflow: "ellipsis", maxHeight: "1000px" }}
                                options={permissions}
                                value={selectedPermissions}
                                label={(permission: IPermission) => (
                                    <>{`${AppTypeDescriptions[permission.app as AppType]}: ${permission.description}`}</>
                                )}
                                onChange={(values: IPermission[] | undefined) => {
                                    setSelectedPermissions(values ?? []);
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => _shutdown()} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button
                            disabled={
                                (isCreating && !firstName) ||
                                (isCreating && !lastName) ||
                                (isCreating &&
                                    (!email ||
                                        (!!email && !isEmailValid(email)) ||
                                        (!!email && !email.endsWith("@avalara.com"))))
                            }
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                await addOpsUser();
                            }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
