import { OptionalString, ReturnedByCheck } from "@davo/types";
import { DateTime } from "luxon";
import { AddNoteModal } from "../AddNoteModal";
import { setOutflowWellsNote } from "../services";

export function toNoteable(o: ReturnedByCheck) {
    return {
        id: o.id,
        note: o.wellsNote,
        noteBy: o.wellsNoteBy,
        noteByName: o.wellsNoteByName,
        noteDate: o.wellsNoteDate,
    };
}

export function ReturnedByCheckWellsAddNoteModal({ outflow }: { outflow: ReturnedByCheck }) {
    const updater = async (
        id: string,
        note: OptionalString,
        noteBy: OptionalString,
        noteByName: OptionalString,
        noteDate: DateTime | undefined | null
    ) => {
        await setOutflowWellsNote(id, note);
        outflow.wellsNote = note;
        outflow.wellsNoteBy = noteBy;
        outflow.wellsNoteByName = noteByName;
        outflow.wellsNoteDate = noteDate;
    };
    return AddNoteModal({ noteable: toNoteable(outflow), updater });
}
