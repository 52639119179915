import { get, getOne, post, put } from "@davo/portal-common";
import {
    IWellsFargoStateMapping,
    IWellsFargoTransaction,
    IWellsFargoTransactionInfo,
    MetaBankAccountAchInfoMessage,
    MetaBankAccountWithAccount,
    OptionalString,
    toJSON,
} from "@davo/types";
import { DateTime } from "luxon";

export async function getBankAccountWithAccount(bankAccountId: string) {
    return getOne(`api/ops/bank-accounts/${bankAccountId}/account`, true, MetaBankAccountWithAccount);
}

export async function updateBankAccountAchInfo(
    bankAccountId: string,
    achFirstName: OptionalString,
    achLastName: OptionalString
) {
    await put(
        `api/ops/bank-accounts/${bankAccountId}/achInfo`,
        toJSON(MetaBankAccountAchInfoMessage, {
            achFirstName,
            achLastName,
        }),
        true
    );
}

export async function getWellsTransactionsWithoutState() {
    return get<IWellsFargoTransaction[]>(`api/ops/bank/bank-transactions/no-state`, true);
}

// NOTE: period is in yyyy-MM format
export async function getUnmatchedWellsTransactions(period: string | undefined, state: string | undefined) {
    return post<IWellsFargoTransactionInfo[]>(`api/ops/bank/bank-transactions/unmatched`, { period, state }, true);
}

export async function setWellsTransactionState(txnId: string, state: string) {
    await post(`api/ops/bank/bank-transactions/${txnId}/state`, { state }, true);
}

export async function setWellsTransactionWsud(txnId: string, isWsud: boolean) {
    await post(`api/ops/bank/bank-transactions/${txnId}/wsud`, { isWsud }, true);
}

export async function setWellsTransactionNote(txnId: string, note: OptionalString) {
    await put(`api/ops/bank/bank-transactions/${txnId}/note`, { note }, true);
}

export async function findMatchingWellsTransactions(pattern: string) {
    return post(`api/ops/bank/bank-transactions/matching`, { pattern }, true);
}

export async function addWellsStateMapping(pattern: string, state: string) {
    return post(`api/ops/bank/bank-transactions-mapping/add`, { pattern, state }, true);
}

export async function getWellsTransaction(id: string) {
    return get<IWellsFargoTransactionInfo>(`api/ops/bank/bank-transactions/id/${id}`, true);
}

export async function getWellsStateMappings() {
    return get<IWellsFargoStateMapping[]>(`api/ops/bank/bank-transactions-mappings`, true);
}

export async function transactionSearch(
    fromDate: DateTime,
    toDate: DateTime,
    amountInPennies?: number,
    stateMappingId?: string,
    description?: string,
    state?: string
) {
    return post<IWellsFargoTransactionInfo[]>(
        `api/ops/bank/transaction-search`,
        {
            fromDate,
            toDate,
            amountInPennies,
            stateMappingId,
            description,
            state,
        },
        true
    );
}

export async function unmatchWellsTransaction(txnId: string, filingId?: string) {
    await post(`api/ops/bank/unmatch-transaction/${txnId}`, { filingId }, true);
}
