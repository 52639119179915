import { get } from "@davo/portal-common";
import {
    IBoardingMonthly,
    IBoardingUsers,
    IDashboardCleanUpTotal,
    IDashboardFilingsByDueDate,
    IDashboardFilingsByUser,
    IDashboardFilingsByUserByDay,
    IDashboardFilingsTotal,
    IDashboardSubscriptionsByPos,
    IDashboardSubscriptionsTotal,
} from "@davo/types";

export async function getSubscriptionsTotal() {
    return get<IDashboardSubscriptionsTotal>(`api/ops/dashboard/subscriptions/total`, true);
}

export async function getSubscriptionsByPos() {
    return get<IDashboardSubscriptionsByPos[]>(`api/ops/dashboard/subscriptions/bypos`, true);
}

export async function getCleanUpTotal() {
    return get<IDashboardCleanUpTotal>(`api/ops/dashboard/cleanup/total`, true);
}

export async function getFilingsThisSessionByUserByDay() {
    return get<IDashboardFilingsByUserByDay[]>(`api/ops/dashboard/filings/session/byuserbyday`, true);
}

export async function getFilingsThisSessionByUser() {
    return get<IDashboardFilingsByUser[]>(`api/ops/dashboard/filings/session/byuser`, true);
}

export async function getFilingsThisSessionTotal() {
    return get<IDashboardFilingsTotal[]>(`api/ops/dashboard/filings/session/total`, true);
}

export async function getFilingsThisSessionByDueDate() {
    return get<IDashboardFilingsByDueDate[]>(`api/ops/dashboard/filings/session/byduedate`, true);
}

export async function getBoardingMonthly() {
    return get<IBoardingMonthly[]>(`api/ops/dashboard/boarding/monthly`, true);
}

export async function getBoardingUsers() {
    return get<IBoardingUsers[]>(`api/ops/dashboard/boarding/users`, true);
}
