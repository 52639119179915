import { d30ToastError, ReactTable, Select, useLoginContext } from "@davo/portal-common";
import { IEmailMessage, toDisplayDateTimeString, User } from "@davo/types";
import { FormControlLabel, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { currentMonth, monthYearLabel, selectableMonths } from "./reconcile-remitted/Util";
import { getEmailHistory, getEmailTypes } from "./services";

export interface IEmailHistoryPaneType {
    userDetail: User;
}

export function EmailHistoryPane({ userDetail }: IEmailHistoryPaneType) {
    const [emailHistory, setEmailHistory] = useState<IEmailMessage[]>([]);
    const [month, setMonth] = useState<string | undefined>(currentMonth);
    const [emailTypes, setEmailTypes] = useState<string[]>([]);
    const [emailType, setEmailType] = useState<string | undefined>();
    const loginContext = useLoginContext();
    const theme = useTheme();

    useAsyncEffect(async () => {
        if (userDetail) {
            await getEmailHistory(userDetail.id, month, emailType)
                .then((results) => setEmailHistory(results))
                .catch((e) => d30ToastError("Problem loading data", e));
        }
        setEmailTypes(await getEmailTypes());
    }, [loginContext.user, userDetail.id, month, emailType]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Created",
                accessor: "created",
                Cell: (data: any) => <>{data.value && toDisplayDateTimeString(data.value)}</>,
            },
            {
                Header: "Type",
                accessor: "emailType",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Subject",
                accessor: "subject",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Delivered",
                accessor: "deliveredDate",
                Cell: (data: any) => <>{data.value && toDisplayDateTimeString(data.value)}</>,
            },
            {
                Header: "Opened",
                accessor: "openedDate",
                Cell: (data: any) => <>{data.value && toDisplayDateTimeString(data.value)}</>,
            },
            {
                Header: "Bounced Message",
                accessor: "bouncedMessage",
                Cell: (data: any) => <>{data.value}</>,
            },
        ];
    }, []);

    return (
        <div className={`${theme.palette.mode}`} style={{ width: "100%" }}>
            <>
                <FormControlLabel
                    control={
                        <Select<string>
                            title="Type"
                            style={{ minWidth: "255px" }}
                            noneLabel={"Any"}
                            options={emailTypes}
                            value={emailType}
                            onChange={setEmailType}
                            data-testid={"typeSelector"}
                        />
                    }
                    label={""}
                />
                <Select<string>
                    title="Month"
                    value={month}
                    onChange={setMonth}
                    options={selectableMonths}
                    label={(val: string) => monthYearLabel(val)}
                    showFullWidth={false}
                    noneLabel={"Last 30 Days"}
                    data-testid={"monthSelector"}
                />
            </>
            <div data-testid={"emailTable"}>
                <ReactTable
                    columns={columns}
                    data={emailHistory ?? []}
                    options={{
                        pageSize: 15,
                        hideToolbar: true,
                    }}
                />
            </div>
        </div>
    );
}
