import React from "react";
import { useMatch } from "react-router-dom";
import { AccountDetailPane } from "./AccountDetailPane";
import { OpsAccountSearch } from "./OpsAccountSearch";

export function Accounts() {
    const match = useMatch("/accounts/:accountId");
    const { accountId } = match?.params ?? {};

    if (accountId) {
        return <AccountDetailPane accountId={accountId} />;
    }

    return <OpsAccountSearch />;
}
