import { ReactTable, Select, TextField } from "@davo/portal-common";
import { IJurisdiction, IWellsFargoStateMapping, initialCap, moneyFromCents, toDisplayDateString } from "@davo/types";
import { Button, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import { getWellsStateMappings, transactionSearch } from "../services";
import { WFTransactionFormattedAmount, numberSorter } from "./Util";
import { WellsAddNoteModal } from "./WellsAddNoteModal";
import { WellsTransactionMatches } from "./WellsTransactionMatches";

export interface IWellsTransactionSearchType {
    states: IJurisdiction[];
}

export function WellsTransactionSearch({ states }: IWellsTransactionSearchType) {
    const [results, setResults] = useState<WFTransactionFormattedAmount[] | undefined>(undefined);
    const [amount, setAmount] = useState<string>("");
    const [stateMappingId, setStateMappingId] = useState<string | undefined>(undefined);
    const [stateMappingList, setStateMappingList] = useState<IWellsFargoStateMapping[]>([]);
    const [description, setDescription] = useState<string>("");
    const [state, setState] = useState<IJurisdiction | undefined>();
    const [fromDate, setFromDate] = useState<DateTime>(DateTime.now().minus({ month: 1 }));
    const [toDate, setToDate] = useState<DateTime>(DateTime.now());

    const refreshResults = useCallback(async () => {
        const mappings = await getWellsStateMappings();
        setStateMappingList(mappings);
        const amountNumber = amount ? Math.round(parseFloat(amount.replace(/[^\d.]/g, "")) * 100) : undefined;

        // Convert amounts to decimals here so we can search for the formatted amount (rather than the integer amount) easily
        const txns = (
            await transactionSearch(fromDate, toDate, amountNumber, stateMappingId, description, state?.abbreviatedName)
        ).map((t) => {
            return {
                ...t,
                transactionAmountFormatted: moneyFromCents(t.transactionAmount),
                unmatchedAmountFormatted: moneyFromCents(t.transactionAmount - (t.amountMatched ?? 0)),
            };
        });

        setResults(txns);
    }, [amount, description, fromDate, state, stateMappingId, toDate]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Description",
                accessor: "transactionDescription",
                Cell: (data: any) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                        <span>{data.value}</span>
                        <WellsAddNoteModal txn={data.cell.row.original} />
                    </span>
                ),
            },
            {
                Header: "Amount",
                accessor: "transactionAmountFormatted",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${data.value}</div>,
                sortType: numberSorter("transactionAmount"),
            },
            {
                Header: "Unmatched Amount",
                accessor: "unmatchedAmountFormatted",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${data.value}</div>,
            },
            {
                Header: "Date",
                accessor: "postingDate",
                Cell: (data: any) => <>{toDisplayDateString(data.value, false)}</>,
            },
            {
                Header: "State",
                accessor: "state",
            },
            {
                Header: "Debit/Credit",
                accessor: "debitCreditIndicator",
                Cell: (data: any) => <>{initialCap(data.value)}</>,
            },
            {
                Header: "Matches",
                accessor: "matchedEntities",
                Cell: (data: any) => (
                    <WellsTransactionMatches
                        key={data.cell.row.original}
                        txn={data.cell.row.original}
                        onChange={refreshResults}
                    />
                ),
            },
        ];
    }, [refreshResults]);

    return (
        <div>
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                        label="From Date"
                        value={fromDate.toJSDate()}
                        closeOnSelect={true}
                        onChange={(date) => date && setFromDate(DateTime.fromJSDate(date))}
                        format="MM/dd/yyyy"
                        slotProps={{
                            textField: { style: { width: "100%", margin: "20px 0" } },
                            toolbar: { hidden: true },
                        }}
                    />

                    <MobileDatePicker
                        label="To Date"
                        value={toDate.toJSDate()}
                        closeOnSelect={true}
                        onChange={(date) => date && setToDate(DateTime.fromJSDate(date))}
                        format="MM/dd/yyyy"
                        slotProps={{
                            textField: { style: { width: "100%", margin: "20px 0" } },
                            toolbar: { hidden: true },
                        }}
                    />
                </LocalizationProvider>
            </div>
            <TextField label={"Amount"} value={amount} onChange={setAmount} showFullWidth={false} />
            <Select<string>
                title="Mapping"
                value={stateMappingId}
                options={stateMappingList.map((m) => m.id)}
                onChange={setStateMappingId}
                label={(mappingId) => {
                    const mapping = stateMappingList.find((m) => m.id === mappingId);
                    return mapping?.descriptionPattern ?? "";
                }}
                showFullWidth={false}
                noneLabel={"Select one"}
                style={{ marginLeft: "10px" }}
            />
            <TextField
                label={"Description"}
                value={description}
                onChange={setDescription}
                showFullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <Select<IJurisdiction>
                title="State"
                options={states}
                value={state}
                onChange={setState}
                label={(s) => s?.fullName}
                showFullWidth={false}
                noneLabel={"Any State"}
                style={{ marginLeft: "10px" }}
            />
            <br />
            <Button variant="outlined" color="primary" onClick={refreshResults}>
                {"Search"}
            </Button>
            {results && (
                <>
                    <ReactTable
                        columns={columns}
                        data={results}
                        refreshData={refreshResults}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [10, 100],
                        }}
                    />
                    <Typography color="textPrimary" style={{ textAlign: "right" }}>
                        Results limited to first 100
                    </Typography>
                </>
            )}
        </div>
    );
}
