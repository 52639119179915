import { amountRowStyle, d30Toast, rowStyle, thStyle, useModalEditor } from "@davo/portal-common";
import { FilingRemittedField, IWellsFargoTransactionInfo, moneyFromCents, toDisplayDateString } from "@davo/types";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Radio,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { findCandidateMatchingBankTransactionsForFiling, matchFilingRemittedAmount } from "../services";

export interface IWellsTransactionMatchFilingType {
    filingId: string;
    field: FilingRemittedField;
    amount: number;
    onMatch: () => void;
}

export function WellsTransactionMatchFiling({ filingId, field, amount, onMatch }: IWellsTransactionMatchFilingType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [txns, setTxns] = useState<IWellsFargoTransactionInfo[]>([]);
    const [txnId, setTxnId] = useState<string | undefined>(undefined);

    useAsyncEffect(async () => {
        if (isDialogOpen) {
            setTxns(await findCandidateMatchingBankTransactionsForFiling(filingId, field));
        }
    }, [filingId, field, isDialogOpen]);

    const matchTransaction = async () => {
        if (!txnId) {
            return;
        }
        await matchFilingRemittedAmount(filingId, field, txnId);
        d30Toast("Success!");
        onMatch();
        closeDialog();
    };

    if (!amount) {
        return <span style={{ marginRight: "62px" }}>&nbsp;</span>;
    }

    return (
        <>
            <div
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    marginRight: "10px",
                }}>
                <Tooltip title={"Match filing"}>
                    <span>
                        <IconButton
                            aria-label="match"
                            onClick={() => {
                                showDialog();
                            }}
                            size="large">
                            <PanoramaFishEyeIcon color="primary" />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>

            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="responsive-dialog-title">
                        Match Filing Amount - ${moneyFromCents(amount)} ({field})
                    </DialogTitle>
                    <DialogContent>
                        {isEmpty(txns) && <div>No potential matches found</div>}

                        {!isEmpty(txns) && (
                            <table>
                                <thead>
                                    <tr>
                                        <th style={thStyle}>&nbsp;</th>
                                        <th style={thStyle}>Description</th>
                                        <th style={thStyle}>Unmatched Amount</th>
                                        <th style={thStyle}>Total Amount</th>
                                        <th style={thStyle}>Date</th>
                                        <th style={thStyle}>State</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {txns.map((t: IWellsFargoTransactionInfo) => {
                                        return (
                                            <tr key={t.id}>
                                                <td>
                                                    <Radio
                                                        checked={t.id === txnId}
                                                        value={t.id}
                                                        onClick={() => setTxnId(t.id)}
                                                    />
                                                </td>
                                                <td style={rowStyle}>{t.transactionDescription}</td>
                                                <td style={amountRowStyle}>
                                                    ${moneyFromCents(t.transactionAmount - (t.amountMatched ?? 0))}
                                                </td>
                                                <td style={amountRowStyle}>${moneyFromCents(t.transactionAmount)}</td>
                                                <td style={rowStyle}>{toDisplayDateString(t.postingDate, false)}</td>
                                                <td style={rowStyle}>{t.state}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}

                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => matchTransaction()}
                            disabled={!txnId}
                            color="primary"
                            variant="contained">
                            Match
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
