import { DavoLink } from "@davo/portal-common";
import React from "react";

interface IRenderRecentlyViewed {
    recentlyViewed: string[];
}

export const RenderRecentlyViewed: React.FunctionComponent<IRenderRecentlyViewed> = ({ recentlyViewed }) => {
    return (
        <>
            <h3>RECENTLY VIEWED FILINGS</h3>
            {recentlyViewed.map((r) => (
                <span key={r} style={{ marginBottom: "10px", display: "block" }}>
                    <DavoLink to={`/filings/${r.split(":")[0]}`}>{r.split(":")[1]}</DavoLink>
                </span>
            ))}
        </>
    );
};
