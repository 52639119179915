import { Header, Loading, PaperComponent } from "@davo/portal-common";
import { DryRunResults, moneyFromCents, percent, toPennies } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { IClosingInfoType } from "./filing/common";
import { dryRunFilingClose } from "./services";

export interface IFilingPreviewModalType {
    filingId: string;
    maxAvailable: number;
    taxFromBreakout: number;
    force: boolean;
    filingInfo: IClosingInfoType;
    closeDialog: (target?: boolean) => void;
}

export function FilingPreviewModal({
    filingId,
    maxAvailable,
    taxFromBreakout,
    filingInfo,
    force,
    closeDialog,
}: IFilingPreviewModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [results, setResults] = useState<DryRunResults | undefined>(undefined);

    const shutdown = (canConfirm: boolean) => {
        setResults(undefined);
        closeDialog(canConfirm);
    };

    useAsyncEffect(async () => {
        const totalTaxDue =
            (filingInfo.taxDuePart1 ?? 0) + (filingInfo.taxDuePart2 ?? 0) + (filingInfo.taxDuePart3 ?? 0);
        const totalDiscount =
            (filingInfo.discountPart1 ?? 0) + (filingInfo.discountPart2 ?? 0) + (filingInfo.discountPart3 ?? 0);
        let shallowCopy: IClosingInfoType = {
            ...filingInfo,
            taxDue: totalTaxDue,
            discount: totalDiscount,
        };
        if (filingInfo.status === "returned") {
            let r = toPennies(Math.min(maxAvailable, taxFromBreakout) / 100);
            if (r) {
                r = Math.max(r, 0);
            }
            shallowCopy = {
                ...filingInfo,
                returned: r,
            };
        }
        if (shallowCopy.status) {
            const resultFromDryRun = await dryRunFilingClose(
                filingId,
                {
                    ...shallowCopy,
                    filedDate: DateTime.now(),
                    status: shallowCopy.status,
                    duration: 100,
                },
                force
            );
            setResults(resultFromDryRun);
        }
    }, []);

    return (
        <Dialog
            fullScreen={makeFullScreen}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            maxWidth={"lg"}
            style={{ boxShadow: "none", padding: "10px" }}
            open={true}
            onClose={() => {
                shutdown(false);
            }}>
            <DialogContent>
                <Header>Filing Close Preview</Header>

                {!results && <Loading></Loading>}

                {/* NOTE this pattern below, unscrupulous future developer --> results.returnToMerchantTotal !== undefined && */}
                {/* This needs to stay this way otherwise a zero is rendered to the screen*/}

                {results && (
                    <>
                        <List sx={{ pt: 0, paddingBottom: "0px", paddingTop: "0px" }}>
                            {!isNil(results.availableAmountAfterClose) && (
                                <ListItem key={"available"}>
                                    <ListItemText
                                        sx={{
                                            fontSize: 20,
                                            color: results.availableAmountAfterClose < 0 ? "red" : "forestgreen",
                                        }}
                                        primary={`Amount available after filing close (approximate): ${moneyFromCents(
                                            results.availableAmountAfterClose
                                        )}`}
                                    />
                                </ListItem>
                            )}
                        </List>
                        <ul>
                            {results.filingErrorMessage && (
                                <li>
                                    <h3 style={{ color: "red" }}>Filing error:</h3>
                                    <Typography>{results.filingErrorMessage}</Typography>
                                </li>
                            )}
                            {!results.filingErrorMessage && results.amountOutstanding && (
                                <li>
                                    <h3 style={{ color: "orange" }}>Filing warning:</h3>
                                    <Typography>
                                        Outstanding tax (not covered): ${moneyFromCents(results.amountOutstanding)}
                                    </Typography>
                                </li>
                            )}
                            {!results.filingErrorMessage &&
                                !results.amountOutstanding &&
                                (!!results.returnToMerchantTotal || results.returnToMerchantTotal === 0) && (
                                    <li>
                                        <h3 style={{ color: "forestgreen" }}>Filing info:</h3>
                                    </li>
                                )}
                            {results.returnToMerchantTotal !== undefined && (
                                <Typography>
                                    Amount that would be returned to merchant: $
                                    {moneyFromCents(results.returnToMerchantTotal)}
                                </Typography>
                            )}
                            {results.discountErrorMessage && (
                                <li>
                                    <h3 style={{ color: "orange" }}>Discount warning:</h3>
                                    <Typography> {results.discountErrorMessage}</Typography>
                                </li>
                            )}
                            {!results.discountErrorMessage && (results.discountUsedForUnderpay || results.discount) && (
                                <li>
                                    <h3 style={{ color: "forestgreen" }}>Discount info:</h3>
                                </li>
                            )}
                            {results.discountUsedForUnderpay !== undefined && (
                                <Typography>
                                    Discount used for underpay: ${moneyFromCents(results.discountUsedForUnderpay)}
                                </Typography>
                            )}
                            {results.discount !== undefined && (
                                <Typography>
                                    Discount that would be returned to merchant: $
                                    {moneyFromCents(results.discount ?? 0)}
                                </Typography>
                            )}

                            {results.pullBackErrorMessage && (
                                <li>
                                    <h3 style={{ color: "orange" }}>Period transfer warning:</h3>
                                    <Typography> {results.pullBackErrorMessage}</Typography>
                                </li>
                            )}
                            {!results.pullBackErrorMessage && results.pullBackAmount && (
                                <li>
                                    <h3 style={{ color: "forestgreen" }}>Period transfer info:</h3>
                                </li>
                            )}
                            {results.pullBackAmount !== undefined && (
                                <Typography>
                                    Amount transferred in to cover underpay: ${moneyFromCents(results.pullBackAmount)}
                                </Typography>
                            )}

                            {results.reconcileErrorMessage && (
                                <li>
                                    <h3 style={{ color: "orange" }}>Reconcile warning:</h3>
                                    <Typography>{results.reconcileErrorMessage}</Typography>
                                </li>
                            )}
                            {!results.reconcileErrorMessage &&
                                (results.coveredUnderfund || results.reconcileAmount) && (
                                    <li>
                                        <h3 style={{ color: "forestgreen" }}>Reconcile info:</h3>
                                    </li>
                                )}
                            {!isNil(results.coveredUnderfund) && (
                                <Typography>
                                    Covered underfunding: ${moneyFromCents(results.coveredUnderfund)} (
                                    {percent(
                                        results.coveredUnderfund /
                                            ((filingInfo.taxDuePart1 ?? 0) +
                                                (filingInfo.taxDuePart2 ?? 0) +
                                                (filingInfo.taxDuePart3 ?? 0))
                                    )}
                                    )
                                </Typography>
                            )}
                            {!isNil(results.reconcileAmount) && results.reconcileAmount < 0 && (
                                <Typography>
                                    Reconcile amount (to be returned): ${moneyFromCents(-results.reconcileAmount)}
                                </Typography>
                            )}
                            {!isNil(results.reconcileAmount) && results.reconcileAmount > 0 && (
                                <Typography>
                                    Reconcile amount (to be set aside): ${moneyFromCents(results.reconcileAmount)}
                                </Typography>
                            )}
                        </ul>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {results && (
                    <Button
                        onClick={() => shutdown(false)}
                        variant="contained"
                        color="primary"
                        style={{ width: "100px" }}
                        autoFocus>
                        {!results.filingErrorMessage && "CANCEL"}
                        {results.filingErrorMessage && "BACK"}
                    </Button>
                )}
                {results && !results.filingErrorMessage && (
                    <Button
                        data-testid={"fileFiling"}
                        onClick={() => shutdown(true)}
                        variant="contained"
                        color="primary"
                        style={{ width: "100px" }}
                        autoFocus>
                        FILE
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
