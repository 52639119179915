import { d30Toast, d30ToastError, Loading, Select, TextField } from "@davo/portal-common";
import { BusinessDay, initialCap, moneyFromCents, noop, OutflowType } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { doOutflow, getOtherLocationsForInterLocationTransferOutflow } from "./services";

const outflowTypes = ["write-off", "returned-by-check", "inter-location-transfer"] as OutflowType[];

export function OutflowButton({
    isDisabled,
    periodDay,
    locationId,
    davoBalance,
    refresh = noop,
}: {
    isDisabled: boolean;
    periodDay: BusinessDay;
    locationId: string;
    davoBalance: number;
    refresh?: () => void;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const [checkNumber, setCheckNumber] = useState<string | undefined>(undefined);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [type, setType] = useState<OutflowType | undefined>(undefined);
    const [otherLocations, setOtherLocations] = useState<
        { id?: string; name?: string; balance?: number }[] | undefined
    >(undefined);

    useAsyncEffect(async () => {
        if (type && type !== "returned-by-check") {
            setCheckNumber(undefined);
        }

        if (type && type === "inter-location-transfer") {
            setOtherLocations(undefined);
            const results = await getOtherLocationsForInterLocationTransferOutflow(locationId, davoBalance, periodDay);
            setOtherLocations(results);
        }
    }, [type]);

    const show = () => {
        setNote("");
        setIsShowing(true);
    };

    const outflow = () => {
        if (note && type) {
            setIsBusy(true);
            let locs: { locationId: string; nestedAmountInPennies: number }[] | undefined = [];
            if (type === "inter-location-transfer" && otherLocations && otherLocations.length > 0) {
                for (const l of otherLocations) {
                    if (l.id && l.balance) {
                        locs.push({ locationId: l.id, nestedAmountInPennies: l.balance });
                    } else {
                        break;
                    }
                }
            } else {
                locs = undefined;
            }
            doOutflow(locationId, -davoBalance, periodDay, type, note, locs, checkNumber)
                .then(() => {
                    d30Toast(`${moneyFromCents(-davoBalance)} ${type} succeeded.`);
                    refresh();
                    setIsShowing(false);
                })
                .catch((e) => d30ToastError("Problem processing set-aside.", e))
                .finally(() => setIsBusy(false));
        }
    };
    const renderOtherLocationInfo = () => {
        if (!otherLocations) {
            return null;
        }
        return (
            <div>
                {otherLocations.map((otherLocation) => (
                    <div key={otherLocation.id}>
                        <Typography>{`Found corresponding location: ${otherLocation.name}`}</Typography>
                        <Typography>{`Amount to be marked as ${type}: ${moneyFromCents(
                            -(otherLocation.balance ?? 0)
                        )}`}</Typography>
                        <TextField label={"Note"} value={note} onChange={setNote} />
                        <Typography>Period Day: {periodDay.toString()}</Typography>
                    </div>
                ))}
            </div>
        );
    };

    const renderWarningMessage = () => {
        if (type === "write-off") {
            if (Math.sign(davoBalance) === -1) {
                return "Writing this off means we will be reporting this to the state as unclaimed property.  You should only be doing this if we really can’t return the funds to the merchant";
            } else if (Math.sign(davoBalance) === 1) {
                return "Writing this off means DAVO is out this money and will record a loss on our balance sheet";
            }
        } else if (type === "returned-by-check") {
            if (Math.sign(davoBalance) === -1) {
                return "Marking this money in this way means we have written a check to the merchant for the balance";
            } else if (Math.sign(davoBalance) === 1) {
                return "Marking this money in this way means we have received a written check from the merchant for the balance";
            }
        }
        return "";
    };

    return (
        <>
            <Button
                disabled={isDisabled || davoBalance === 0}
                variant="outlined"
                onClick={show}
                size="small"
                color="primary"
                sx={{ width: "174px" }}>
                Outflow
            </Button>
            {isShowing && (
                <Dialog open={true}>
                    <DialogTitle>Outflow</DialogTitle>
                    <DialogContent>
                        <Select<OutflowType>
                            title="Type"
                            value={type}
                            onChange={setType}
                            options={outflowTypes}
                            label={(x: OutflowType) => initialCap(x)}
                        />
                        {type && type !== "inter-location-transfer" && (
                            <div>
                                <Typography>{`Amount to be marked as ${type}: ${moneyFromCents(-davoBalance)}`}</Typography>
                                <TextField label={"Note"} value={note} onChange={setNote} />
                                <Typography>Period Day: {periodDay.toString()}</Typography>
                                <Typography>Note: {renderWarningMessage()}</Typography>
                            </div>
                        )}
                        {type && type === "returned-by-check" && (
                            <div>
                                <TextField label={"Check Number"} value={checkNumber} onChange={setCheckNumber} />
                            </div>
                        )}

                        {type && type === "inter-location-transfer" && !otherLocations && <Loading />}
                        {type &&
                            type === "inter-location-transfer" &&
                            otherLocations &&
                            otherLocations.length > 0 &&
                            renderOtherLocationInfo()}
                        {type &&
                            type === "inter-location-transfer" &&
                            otherLocations &&
                            otherLocations.length === 0 && (
                                <div>
                                    <Typography>{`Could not find location with corresponding DAVO balance for transfer.`}</Typography>
                                </div>
                            )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" disabled={isBusy || !note} onClick={outflow}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
