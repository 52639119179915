import { d30ToastError, LinkButton, Loading, ReactTable, Select, useLoginContext } from "@davo/portal-common";
import { FailedBillingData, isACHNachaRejection, moneyFromCents, toDisplayDateString } from "@davo/types";
import { Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { HubSpotLink } from "./HubSpotLink";
import { RebillModalButton } from "./RebillModalButton";
import { filingSessionFormat } from "./reconcile-remitted/Util";
import { getFailedBillings } from "./services";
import { hasPermission } from "./util";

const _renderAccountName = (data: any) => {
    return (
        <>
            <LinkButton
                url={`/accounts/${data.cell.row.original.accountId}`}
                label={data.cell.row.original.accountName || "Account"}
                rel="noreferrer"
                target="_blank"
                labelMaxChars={35}
            />
        </>
    );
};

const _renderHubspotLink = (data: any) => {
    return (
        <>
            <HubSpotLink type={"account"} davoId={data.value} />
        </>
    );
};

const _renderStatus = (data: any) => {
    return (
        <>
            <Typography style={{ marginRight: "5px" }}>{`${data.value}`}</Typography>
        </>
    );
};

const _renderAmount = (data: any) => {
    return <Typography>${moneyFromCents(data.value)}</Typography>;
};

const _renderRetryFailed = (data: any) => {
    return <>{data.value ? "Yes" : "No"}</>;
};

const _dateFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>}</>;
};

export function BillingFailurePane() {
    const loginContext = useLoginContext();
    const [billingMonths, setBillingMonths] = useState<string[]>([]);
    const [failedBillings, setFailedBillings] = useState<FailedBillingData[]>();
    const [yearMonth, setYearMonth] = useState<string | undefined>(
        DateTime.local().startOf("month").minus(1).toFormat(filingSessionFormat)
    );
    const [showNoRecordsFound, setShowNoRecordsFound] = useState<boolean>(false);
    const controllerRef = useRef<AbortController | undefined>();

    useAsyncEffect(async () => {
        const vals: string[] = [];
        for (let i = 0; i < 18; i++) {
            const date = DateTime.local().startOf("month").minus({ months: i });
            vals.push(date.toFormat(filingSessionFormat));
        }
        setBillingMonths(vals);
    }, []);

    const refresh = useCallback(() => {
        if (controllerRef?.current) {
            controllerRef.current.abort();
        }

        setShowNoRecordsFound(false);
        setFailedBillings(undefined);

        if (yearMonth) {
            controllerRef.current = new AbortController();

            getFailedBillings(yearMonth, controllerRef.current)
                .then((results) => {
                    setFailedBillings(results);
                    setShowNoRecordsFound(isEmpty(results));
                })
                .catch((e) => {
                    d30ToastError("Problem getting failed billings.", e);
                });
        }
    }, [yearMonth]);

    useAsyncEffect(async () => {
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Invoiced",
                accessor: "invoiceDate",
                Cell: _dateFormat,
            },
            {
                Header: "Account",
                accessor: "accountName",
                Cell: _renderAccountName,
            },
            {
                Header: "",
                accessor: "accountId",
                Cell: _renderHubspotLink,
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: _renderAmount,
            },
            {
                Header: "Status",
                accessor: "forteStatus",
                Cell: _renderStatus,
            },
            {
                Header: "Retry Failed",
                accessor: "retryFailed",
                Cell: _renderRetryFailed,
            },
            {
                Header: "",
                accessor: "id",
                Cell: (data: any) => (
                    <RebillModalButton
                        invoiceId={data.row.original.billingInvoiceId}
                        invoiceDate={data.row.original.invoiceDate}
                        amount={data.row.original.amount}
                        onRebill={() => {
                            refresh();
                        }}
                        isFullWidth={true}
                        isDisabled={
                            isACHNachaRejection(data.row.original.forteStatus) &&
                            !hasPermission(loginContext.permissions, "nacha_lockout_override")
                        }
                    />
                ),
            },
        ];
    }, [loginContext.permissions, refresh]);

    if (!loginContext.user) {
        return null;
    }

    if (isEmpty(billingMonths)) {
        return <Loading />;
    }

    return (
        <div>
            <Select<string>
                data-testid={"billingMonths"}
                title="Year/Month"
                options={billingMonths}
                value={yearMonth}
                onChange={setYearMonth}
            />

            {showNoRecordsFound && (
                <div data-testid={"noBillingFailuresMatchSearchCriteriaCopy"}>
                    <Typography style={{ marginBottom: "20px" }} variant="body1">
                        No Billing Failures Matching Search Criteria
                    </Typography>
                </div>
            )}

            {yearMonth && isNil(failedBillings) && <Loading />}

            {!isEmpty(failedBillings) && (
                <div data-testid={"billingFailuresTable"}>
                    <ReactTable
                        columns={columns}
                        data={failedBillings}
                        refreshData={refresh}
                        options={{
                            pageSize: 10,
                        }}
                    />
                </div>
            )}
        </div>
    );
}
