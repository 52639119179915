import { d30ToastError, ReactTable, useLoginContext } from "@davo/portal-common";
import { SmsMessageDisplay, toDisplayDateTimeString, User } from "@davo/types";
import { useTheme } from "@mui/material";
import { decode } from "html-entities";
import truncate from "lodash/truncate";
import React, { useEffect, useMemo, useState } from "react";
import "./css/sms.css";
import { getSmsMessageHistory } from "./services";

interface ISmsHistoryPaneType {
    userDetail: User;
}

export function SmsHistoryPane({ userDetail }: ISmsHistoryPaneType) {
    const [smsMessageHistory, setSmsMessageHistory] = useState<SmsMessageDisplay[]>([]);
    const loginContext = useLoginContext();
    const theme = useTheme();

    useEffect(() => {
        if (loginContext.user) {
            getSmsMessageHistory(userDetail.id)
                .then((results) => setSmsMessageHistory(results))
                .catch((e) => d30ToastError("Problem loading data", e));
        }
    }, [loginContext.user, userDetail.id]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Date",
                accessor: "created",
                Cell: (data: any) => <>{toDisplayDateTimeString(data.value)}</>,
            },
            {
                Header: "Merchant",
                accessor: "merchantFullname",
                Cell: (data: any) => (
                    <a href={data.cell.row.original.merchantUserId} style={{ color: "inherit" }}>
                        {data.value}
                    </a>
                ),
            },
            {
                Header: "Message",
                accessor: "message",
                Cell: (data: any) => <>{truncate(decode(data.value), { length: 50 })}</>,
            },
            {
                Header: "Last Msg From",
                accessor: "fromMerchant",
                Cell: (data: any) => <>{data.value === 0 ? "ops" : "merchant"}</>,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (data: any) => <>{data.value}</>,
            },
        ];
    }, []);

    return (
        <div className={`${theme.palette.mode}`} style={{ width: "100%" }}>
            <ReactTable
                columns={columns}
                data={smsMessageHistory ?? []}
                options={{
                    pageSize: 12,
                    hideToolbar: true,
                }}
            />
        </div>
    );
}
