import {
    d30Toast,
    d30ToastError,
    getJurisdictions,
    ReactTable,
    Select,
    TextField,
    useModalEditor,
} from "@davo/portal-common";
import { IJurisdiction, IWellsFargoTransaction, moneyFromCents, toDisplayDateString } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { addWellsStateMapping, findMatchingWellsTransactions } from "../services";

export function WellsAddDescriptionPatternModal({ onCreate }: { onCreate: () => void }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [selectedState, setSelectedState] = useState<IJurisdiction | undefined>();
    const [pattern, setPattern] = useState<string | undefined>(undefined);
    const [isWildCardBefore, setIsWildCardBefore] = useState<boolean>(false);
    const [isWildCardAfter, setIsWildCardAfter] = useState<boolean>(true);
    const [isTested, setIsTested] = useState<boolean>(false);
    const [results, setResults] = useState<IWellsFargoTransaction[]>([] as any);

    useAsyncEffect(async () => {
        if (!isDialogOpen) {
            return;
        }
        setStates(await getJurisdictions());
        setPattern("");
        setIsWildCardBefore(false);
        setIsWildCardAfter(true);
        setIsTested(false);
    }, [isDialogOpen]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Description",
                accessor: "transactionDescription",
            },
            {
                Header: "Amount",
                accessor: "transactionAmount",
                Cell: (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(data.value)}</div>,
            },
            {
                Header: "Date",
                accessor: "postingDate",
                Cell: (data: any) => <>{toDisplayDateString(data.value, false)}</>,
            },
            {
                Header: "State",
                accessor: "state",
            },
        ];
    }, []);

    return (
        <>
            <Button
                data-testid={"add-descriptor"}
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="primary"
                style={{ marginTop: "3px" }}>
                Add descriptor
            </Button>
            {isDialogOpen && (
                <Dialog
                    data-testid={"dialog"}
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Add State Mapping</DialogTitle>
                    <DialogContent>
                        <Switch
                            checked={isWildCardBefore}
                            onChange={(_, isChecked) => {
                                setIsWildCardBefore(isChecked);
                                setIsTested(false);
                            }}
                        />{" "}
                        Allow any characters before
                        <br />
                        <TextField
                            value={pattern ?? ""}
                            isDisabled={isBusy}
                            label={"Text to match (case insensitive)"}
                            onChange={(val) => {
                                setPattern(val);
                                setIsTested(false);
                            }}
                        />
                        <br />
                        <Switch
                            checked={isWildCardAfter}
                            onChange={(_, isChecked) => {
                                setIsWildCardAfter(isChecked);
                                setIsTested(false);
                            }}
                        />{" "}
                        Allow any characters after
                        <br />
                        <Typography variant={"body1"}>
                            <div>Please select a state for this transaction:</div>
                            <Select<IJurisdiction>
                                title="State"
                                options={states}
                                value={selectedState}
                                onChange={setSelectedState}
                                label={(s) => s?.fullName}
                            />
                        </Typography>
                        <p style={{ display: isTested ? "block" : "none" }}>
                            <h3>Results</h3>

                            <ReactTable
                                columns={columns}
                                data={results}
                                options={{
                                    pageSize: 10,
                                }}
                            />
                        </p>
                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" disabled={isBusy} onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !pattern}
                            onClick={async () => {
                                const fullPattern = `${isWildCardBefore ? "%" : ""}${pattern}${isWildCardAfter ? "%" : ""}`;
                                setResults(await findMatchingWellsTransactions(fullPattern));
                                setIsTested(true);
                            }}>
                            Test
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !selectedState || !pattern || !isTested}
                            onClick={async () => {
                                if (!selectedState) {
                                    return;
                                }
                                setIsBusy(true);
                                const fullPattern = `${isWildCardBefore ? "%" : ""}${pattern}${isWildCardAfter ? "%" : ""}`;
                                try {
                                    await addWellsStateMapping(fullPattern, selectedState.abbreviatedName);
                                    onCreate();
                                    d30Toast("Added");
                                    closeDialog();
                                } catch (e: any) {
                                    d30ToastError("Error adding pattern");
                                } finally {
                                    setIsBusy(false);
                                }
                            }}>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
