import {
    BodyText,
    ConfirmationModal,
    d30Toast,
    d30ToastError,
    DavoLink,
    ManualActivityModal,
    ManualActivityTable,
    ReactTable,
    TaxProfileEdit,
    useLoginContext,
    useModalEditor,
    UseTaxTable,
} from "@davo/portal-common";
import {
    AmendmentStatuses,
    BusinessDay,
    hasMidMonthPaymentOrIsWeirdCA,
    Location,
    moneyFromCents,
    toDisplayDateString,
    toDisplayDateTimeString,
    User,
} from "@davo/types";
import BusinessIcon from "@mui/icons-material/Business";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import InfoIcon from "@mui/icons-material/Info";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Radio,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import assert from "assert";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { default as CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { AssignStaffModal } from "./AssignStaffModal";
import { AuthCode } from "./AuthCode";
import { BankAccountCredentialsButton } from "./BankAccountCredentialsButton";
import { DailyActivity } from "./DailyActivity";
import { FilingAccounting } from "./FIlingAccounting";
import { FieldList } from "./FieldList";
import { FilingAttachmentUpload } from "./FilingAttachmentUpload";
import { FilingAttachments } from "./FilingAttachments";
import { FilingUpdateAudit } from "./FilingUpdateAudit";
import { LocationExternalIdentifierModal } from "./LocationExternalIdentifierModal";
import { OverlappingFilings } from "./OverlappingFilings";
import { PenaltyTable } from "./PenaltyTable";
import { ReopenFilingModal } from "./ReopenFilingModal";
import { ReturnFundsModal } from "./ReturnFundsModal";
import { SetFilingNote } from "./SetFilingNote";
import { TaxProfileCredentialsButton } from "./TaxProfileCredentialsButton";
import { TaxProfileCredentialsInfo } from "./TaxProfileCredentialsInfo";
import { TaxProfileVerificationModal } from "./TaxProfileVerificationModal";
import { useOpsPortalConfigContext } from "./context";
import { useFilingDetailPaneContext } from "./context/FilingDetailPaneContext";
import { AutofilerButtons } from "./filing/AutofilerButtons";
import { AutofilerProcessingDetail } from "./filing/AutofilerProcessingDetail";
import { FilingAmendmentForm } from "./filing/FilingAmendmentForm";
import { FilingDetailForm } from "./filing/FilingDetailForm";
import { NonTaxableByLocationAccordion } from "./filing/NonTaxableByLocationAccordion";
import { PrecludedTaxBreakOut } from "./filing/PrecludedTaxBreakOut";
import { TaxBreakout } from "./filing/TaxBreakout";
import { statusLabel } from "./filing/common";
import { WellsTransactionMatchIcon } from "./reconcile-remitted/WellsTransactionMatchIcon";
import {
    getLocationsSubscriptionStartDate,
    getNextMonthFundingForExcessCheck,
    regenerateFiling,
    reOpenFiling,
    resendFilingConfirmation,
    setTaxRateType,
    trueUpFiling,
    updateFilingAssignee,
} from "./services";
import { hasPermission, isTakeGiveBack } from "./util";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        "& span": {
            color: "#768ad7",
            textTransform: "uppercase",
            fontWeight: 600,
            letterSpacing: ".75px",
            fontSize: "12px",
        },
    },
    label: {
        flexGrow: 1,
    },
    buttonAlignment: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "65%",
    },
}));

export function FilingDetailPane() {
    const {
        filingId,
        filing,
        locationsIncludedInFiling,
        taxProfileTaxRates,
        fundingStatus,
        hasPendingFunds,
        maxAvailable,
        nonPrepaymentRemittances,
        grossAmounts,
        taxableAmounts,
        nonTaxableAmounts,
        tenderCash,
        tenderCredit,
        posTypes,
        taxFromBreakout,
        doesTaxProfileHavePriorOpenFilings,
        payor,
        partner,
        FEINDuplicates,
        stateTaxIdDuplicates,
        taxProfileOtherFilingsRequiringAmendment,
        isFirstMonthMerchantPay,
        additionalSales,
        useTax,
        partnerGroupPolicyStates,
        internalGroupPolicyStates,
        prepaymentsMade,
        firstFiling,

        filingTypes,
        jurisdictionConfig,
        filingTypeConfig,
        hasCanFilePermission,
        hasCanReturnLeftoverFundsPermission,

        taxType,
        setTaxType,

        isEditingFiling,
        setIsEditingFiling,
        isAmendingFiling,
        setIsAmendingFiling,
        isReopening,
        setIsReopening,

        refresh,
    } = useFilingDetailPaneContext();

    const classes = useStyles();
    const navigate = useNavigate();
    const loginContext = useLoginContext();

    const [showReopenModal, setShowReopenModal] = useState<boolean>(false);
    const [isTrueUpInProgress, setIsTrueUpInProgress] = useState<boolean>(false);
    const [recentSubs, setRecentSubs] = useState<Location[]>([]);
    const [nextMonthFunding, setNextMonthFunding] = useState<number | undefined>();
    const [showTaxProfileEdit, taxProfileEditProps] = useModalEditor<string[]>((targetId?: string[]) => {
        if (targetId) {
            refresh();
        }
    });

    const [showRegenerateConfirmationModal, regenerateConfirmationModalProps] = useModalEditor<string>(
        (targetId?: string) => {
            if (targetId) {
                let id: string;
                if (filing) {
                    id = filing.taxProfile.account.id;
                }
                regenerateFiling(targetId)
                    .then(() => {
                        d30Toast("Filing regenerated.");
                        if (filing) {
                            navigate(`/accounts/${id}`);
                        }
                    })
                    .catch(() => d30ToastError("Filing could not be regenerated."));
            }
        }
    );

    const [showTrueUpConfirmationModal, trueUpConfirmationModalProps] = useModalEditor<string>((targetId?: string) => {
        // prevent double clicks
        if (targetId && !isTrueUpInProgress && ["open"].includes(filing.status)) {
            setIsTrueUpInProgress(true);
            trueUpFiling(filing.id)
                .then(() => {
                    d30Toast("Manual set aside for true up initiated.");
                    refresh();
                })
                .catch((e) => d30ToastError(`Filing could not be trued up: ${e.message}`))
                .finally(() => setIsTrueUpInProgress(false));
        }
    });

    const [showFilingAssigneeModal, filingAssigneeModalProps] = useModalEditor<string | null>(
        () => {},
        false,
        filing.assignedUserId
    );
    const [showAssignStateExternalId, assignStateExternalIdProps] = useModalEditor<string>();
    const [showTaxProfileVerificationModal, setShowTaxProfileVerificationModal] = useState<boolean>(false);
    const { opsPortalConfigInfo: configInfo } = useOpsPortalConfigContext();
    const [showReturnFundsModal, returnFundsModalProps] = useModalEditor<string>();
    const [summaryData, setSummaryData] = useState<
        {
            title: string;
            sales: string;
            returns: string;
            net: string;
        }[]
    >([]);

    useAsyncEffect(async () => {
        if (locationsIncludedInFiling.length > 0 && filing) {
            const recentSubsThatInteractedWithFirstFunding: Location[] = [];
            const locationsWithFirstFundingInteraction = locationsIncludedInFiling.filter(
                (x) => x.firstFundingEligible === "accepted" || x.firstFundingEligible === "declined"
            );
            if (locationsWithFirstFundingInteraction.length > 0) {
                const subStartDates = await getLocationsSubscriptionStartDate(
                    locationsWithFirstFundingInteraction.map((x: Location) => x.id)
                );
                for (const l of locationsWithFirstFundingInteraction) {
                    const locStartDate = subStartDates.find((x) => x.locationId === l.id);
                    if (
                        locStartDate &&
                        locStartDate.startDate >= filing.periodStart &&
                        locStartDate.startDate <= filing.periodEnd.offset(5)
                    ) {
                        recentSubsThatInteractedWithFirstFunding.push(l);
                    }
                }
            }

            setRecentSubs(recentSubsThatInteractedWithFirstFunding);
        }
    }, [locationsIncludedInFiling]);

    useAsyncEffect(async () => {
        if (locationsIncludedInFiling.length > 0 && filing) {
            const nmf = await getNextMonthFundingForExcessCheck(filing.id);
            setNextMonthFunding(nmf);
        }
    }, [locationsIncludedInFiling]);

    useEffect(() => {
        if (!filing) {
            return;
        }
        if (filing.status === "open" || isEditingFiling || isAmendingFiling) {
            if (
                (filingTypes && filingTypes.length > 1 && isNil(filing.taxProfile.typeVerifiedBy)) ||
                (taxProfileTaxRates && taxProfileTaxRates.filter((x) => x.included === "unverified").length > 0)
            ) {
                setShowTaxProfileVerificationModal(true);
            } else {
                setShowTaxProfileVerificationModal(false);
            }
        }
    }, [filingTypes, taxProfileTaxRates]);

    const summaryColumns = useMemo(() => {
        return [
            {
                Header: "",
                accessor: "title",
                disableSortBy: true,
            },
            {
                Header: "Sales",
                accessor: "sales",
                disableSortBy: true,
                Cell: (data: any) => <>{data.value && <div style={{ textAlign: "right" }}>${data.value}</div>}</>,
            },
            {
                Header: "Returns",
                accessor: "returns",
                disableSortBy: true,
                Cell: (data: any) => <>{data.value && <div style={{ textAlign: "right" }}>${data.value}</div>}</>,
            },
            {
                Header: "Net",
                accessor: "net",
                disableSortBy: true,
                rightAlign: true,
                Cell: (data: any) => (
                    <>
                        <div style={{ textAlign: "right" }}>
                            {!!data.value && (
                                <>
                                    ${data.value}&nbsp;&nbsp;
                                    <CopyToClipboard text={data.value} onCopy={() => d30Toast("Copied")}>
                                        <FileCopyTwoToneIcon
                                            fontSize="small"
                                            color="primary"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </CopyToClipboard>
                                </>
                            )}
                        </div>
                    </>
                ),
            },
        ];
    }, []);

    useEffect(() => {
        setSummaryData([
            {
                title: "Gross",
                sales: moneyFromCents(grossAmounts.sales),
                returns: moneyFromCents(grossAmounts.returns),
                net: moneyFromCents(grossAmounts.net),
            },
            {
                title: "Taxable",
                sales: moneyFromCents(taxableAmounts.sales),
                returns: moneyFromCents(taxableAmounts.returns),
                net: moneyFromCents(taxableAmounts.net),
            },
            {
                title: "NonTaxable",
                sales: moneyFromCents(nonTaxableAmounts.sales),
                returns: moneyFromCents(nonTaxableAmounts.returns),
                net: moneyFromCents(nonTaxableAmounts.net),
            },
            {
                title: "Tax",
                sales: "",
                returns: "",
                net: moneyFromCents(taxFromBreakout),
            },
        ]);
    }, [grossAmounts, nonTaxableAmounts, taxFromBreakout, taxableAmounts]);

    const displayNotificationForMissingBankAccount = () => {
        let hasAtLeastOneBankAccount = false;
        let hasAtLeastOneNoBankAccount = false;
        for (const l of locationsIncludedInFiling) {
            if (l.bankAccount) {
                hasAtLeastOneBankAccount = true;
            } else {
                hasAtLeastOneNoBankAccount = true;
            }
        }
        return hasAtLeastOneBankAccount && hasAtLeastOneNoBankAccount;
    };

    const getOneBankAccount = () => {
        let bankAccount;
        for (const l of locationsIncludedInFiling) {
            if (l.bankAccount && !bankAccount) {
                bankAccount = l.bankAccount;
            } else if (l.bankAccount && bankAccount) {
                return { bankAccountId: undefined, bankAccountName: undefined };
            }
        }
        if (bankAccount) {
            return { bankAccountId: bankAccount.id, bankAccountName: bankAccount.name };
        } else {
            return { bankAccountId: undefined, bankAccountName: undefined };
        }
    };

    const getFilingDatesAreTrueUpEligible = () => {
        if (!filing) {
            return false;
        } else if (DateTime.utc() > filing.filingDueDate.toDateTime().startOf("month").plus({ days: 5 })) {
            // we can not true up if after the 5th of the month it’s due
            return false;
        } else if (filing.periodEnd > BusinessDay.fromDateTime(DateTime.utc().startOf("month").minus({ days: 2 }))) {
            // we can true up this period, and last period
            return true;
        }
        return false;
    };

    const cancelEditingFiling = () => {
        setIsEditingFiling(false);
        refresh();
    };

    const cancelAmendingFiling = () => {
        setIsAmendingFiling(false);
        refresh();
    };

    const reOpen = () => {
        setShowReopenModal(true);
    };

    const editFiling = () => {
        assert(filing);
        setIsEditingFiling(true);
        refresh();
    };

    const amendingFiling = () => {
        assert(filing);
        setIsAmendingFiling(true);
        refresh();
    };

    const trueUp = () => {
        showTrueUpConfirmationModal(filing.id);
    };

    const regenerateThis = () => {
        showRegenerateConfirmationModal(filing.id);
    };

    const assignFilingAssignee = (passThroughIds: string[] | undefined, selected: User | undefined) => {
        if (passThroughIds) {
            updateFilingAssignee(passThroughIds[0], selected?.id ?? undefined)
                .then(() => {
                    d30Toast("Filing assignee updated.");
                    refresh();
                })
                .catch((e) => d30ToastError(e.message));
        }
    };

    const renderFirstFundingAlert = () => {
        if (recentSubs.length < 1) {
            return null;
        }
        let text = "This merchant did not initiate first funding, perhaps due to inaction";
        if (recentSubs.some((loc) => loc.firstFundingEligible === "accepted")) {
            text =
                "This merchant initiated a first funding transaction for their location(s) during this filing period.";
        } else if (recentSubs.some((loc) => loc.firstFundingEligible === "declined")) {
            text =
                "This merchant declined to initiate a first funding transaction for their location(s) during this filing period.";
        }
        return (
            <div style={{ maxWidth: "670px" }}>
                <strong>First funding:</strong>
                <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                    {text}
                </Typography>
            </div>
        );
    };

    const renderReturnFundsButton = () => {
        let locationID = "1";
        if (locationsIncludedInFiling.length === 1) {
            for (const l of locationsIncludedInFiling) {
                locationID = l.id;
            }
            if (maxAvailable > 0) {
                return (
                    <>
                        <Tooltip
                            placement="right"
                            title={
                                !hasCanReturnLeftoverFundsPermission && !hasCanFilePermission
                                    ? "Manual Return requires permission"
                                    : ""
                            }>
                            <span>
                                <Button
                                    onClick={() => {
                                        showReturnFundsModal();
                                    }}
                                    disabled={!hasCanReturnLeftoverFundsPermission || !hasCanFilePermission}
                                    color="primary"
                                    size={"small"}
                                    variant="outlined"
                                    style={{ margin: "5px 2px" }}>
                                    Return Leftover Funds
                                </Button>
                            </span>
                        </Tooltip>
                        {returnFundsModalProps.isDialogOpen && (
                            <ReturnFundsModal
                                periodDay={filing.periodEnd}
                                accountId={filing.taxProfile.account.id}
                                locationId={locationID}
                                balance={maxAvailable}
                                {...returnFundsModalProps}
                                refresh={refresh}
                            />
                        )}
                    </>
                );
            }
        }
        return null;
    };

    const renderFilingDetailForm = () => {
        if (taxProfileTaxRates ? taxProfileTaxRates.filter((x) => x.included === "unverified").length === 0 : true) {
            if (
                (isEditingFiling || !["filed", "wontfile", "returned"].includes(filing.status)) &&
                (!hasPendingFunds || hasMidMonthPaymentOrIsWeirdCA(filing))
            ) {
                return <FilingDetailForm />;
            } else if (isAmendingFiling && !["open"].includes(filing.status)) {
                return (
                    <FilingAmendmentForm
                        filing={filing}
                        isAmendingFiling={isAmendingFiling}
                        onUpdate={() => {
                            setIsAmendingFiling(false);
                            refresh();
                        }}
                        onCancel={cancelAmendingFiling}
                    />
                );
            }
        }
        return null;
    };

    const getTenderTypesAndPos = () => {
        const base = [];
        if (jurisdictionConfig?.tenderTypeRequired || filingTypeConfig?.tenderTypeRequired) {
            base.push(
                {
                    tenderCash: <div style={{ textAlign: "right" }}>${moneyFromCents(tenderCash)}</div>,
                },
                {
                    tenderCredit: <div style={{ textAlign: "right" }}>${moneyFromCents(tenderCredit)}</div>,
                }
            );
        }
        if (jurisdictionConfig?.posTypeRequired || filingTypeConfig?.posTypeRequired) {
            base.push({
                posTypes: <div style={{ textAlign: "right" }}>{posTypes.join(", ")}</div>,
            });
        }
        return Object.assign({}, ...base);
    };

    const renderAlerts = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                <List
                    className={classes.root}
                    style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, marginTop: "unset" }}>
                        <ListItemAvatar style={{ minWidth: "unset", marginRight: "10px" }}>
                            <Avatar style={{ width: "unset", height: "unset", padding: "5px" }}>
                                <BusinessIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            className={classes.root}
                            style={{ padding: "0px 10px 10px 0" }}
                            primary="Account:"
                            secondary={
                                <DavoLink to={`/accounts/${filing.taxProfile.account.id}`}>
                                    {filing.taxProfile.name} ~ {filing.taxProfile.legalName}
                                </DavoLink>
                            }
                            secondaryTypographyProps={{
                                component: "div",
                            }}
                        />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, marginTop: "unset" }}>
                        <ListItemAvatar style={{ minWidth: "unset", marginRight: "10px" }}>
                            <Avatar style={{ width: "unset", height: "unset", padding: "5px" }}>
                                <GpsFixedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Jurisdiction" secondary={filing.jurisdiction} />
                    </ListItem>
                    {filingTypeConfig && (
                        <ListItem style={{ paddingTop: 0, paddingBottom: 0, marginTop: "unset" }}>
                            <ListItemAvatar style={{ minWidth: "unset", marginRight: "10px" }}>
                                <Avatar style={{ width: "unset", height: "unset", padding: "5px" }}>
                                    <TextSnippetOutlinedIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Filing type" secondary={filingTypeConfig.name} />
                        </ListItem>
                    )}
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, marginTop: "unset" }}>
                        <ListItemAvatar style={{ minWidth: "unset", marginRight: "10px" }}>
                            <Avatar style={{ width: "unset", height: "unset", padding: "5px" }}>
                                <UpdateIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={filing.frequency}
                            secondary={`(${toDisplayDateString(filing.periodStart)} / ${toDisplayDateString(
                                filing.periodEnd
                            )}) due: ${toDisplayDateString(filing.filingDueDate)}`}
                        />
                    </ListItem>
                </List>
                <div style={{ display: "flex", flexDirection: "column", margin: "0 10px", flexGrow: 1 }}>
                    {hasCanFilePermission && (
                        <div style={{ display: "flex", justifyContent: "right", margin: "10px" }}>
                            <AutofilerButtons
                                filing={filing}
                                refreshFiling={() => {
                                    refresh();
                                }}
                            />
                            <AutofilerProcessingDetail filing={filing} />
                        </div>
                    )}
                    {filing.status === "open" && <AuthCode jurisdiction={filing.jurisdiction} />}
                    {filing.taxProfile.filingNote && (
                        <Alert style={{ maxWidth: "670px", whiteSpace: "pre-line" }} severity="error">
                            <AlertTitle>Filing Note:</AlertTitle>
                            {filing.taxProfile.filingNote}
                        </Alert>
                    )}
                    {filing.taxProfile.account && filing.taxProfile.account.note && (
                        <Alert severity="info">{renderAccountNote(filing.taxProfile.account.note)}</Alert>
                    )}
                    {locationsIncludedInFiling.some((loc) => loc.beta) && (
                        <Alert severity="warning">Filing includes a location in beta</Alert>
                    )}
                    {locationsIncludedInFiling.some((loc) => loc.bankAccount?.paused) && (
                        <Alert severity="warning">
                            Filing includes a bank that is paused, do not remit money in excess of funds on hand
                        </Alert>
                    )}
                    {recentSubs.length > 0 && <Alert severity="warning">{renderFirstFundingAlert()}</Alert>}
                    {firstFiling && firstFiling.first && firstFiling.prepayment && (
                        <Alert severity="warning">Careful, this is the first prepayment for this new tax profile</Alert>
                    )}
                    {firstFiling && firstFiling.first && !firstFiling.prepayment && (
                        <Alert severity="warning">
                            Careful, this is the first time this tax profile is being filed
                        </Alert>
                    )}
                    {filing.reviewed && filing.reviewNote && (
                        <Alert
                            classes={{
                                message: classes.label,
                            }}
                            style={{ marginTop: "15px" }}
                            severity="warning">
                            <strong>Review Note: </strong>
                            <BodyText>{filing.reviewNote}</BodyText>
                            <BodyText>{`${filing.reviewedUser?.firstName} ${
                                filing.reviewedUser?.lastName
                            } @ ${toDisplayDateTimeString(filing.reviewed)}`}</BodyText>
                        </Alert>
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "column", margin: "0 10px", flexGrow: 1 }}>
                    <OverlappingFilings filingId={filing.id} />
                </div>
            </div>
        );
    };

    const getFieldList = () => {
        const base = [];
        base.push({
            filedOn: (
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                    {filing.filedDate ? toDisplayDateTimeString(filing.filedDate) : ""}
                </div>
            ),
        });
        base.push({
            filedBy: (
                <div style={{ textAlign: "right" }}>{`${filing.filedBy?.firstName} ${filing.filedBy?.lastName}`}</div>
            ),
        });
        if (filing.autofiledById) {
            base.push({
                autofiledBy: (
                    <div style={{ textAlign: "right" }}>
                        {`${filing.autofiledBy?.firstName} ${filing.autofiledBy?.lastName}`}
                    </div>
                ),
            });
        }
        if (filing.amendmentStatus) {
            base.push({
                amendmentStatus: <div style={{ textAlign: "right" }}>{AmendmentStatuses[filing.amendmentStatus]}</div>,
            });
        }
        if (filing.amendmentNote) {
            base.push({ amendmentNote: filing.amendmentNote });
        }

        base.push({ salesTax: <div style={{ textAlign: "right" }}>${moneyFromCents(filing.taxDue || 0)}</div> });
        base.push({ discount: <div style={{ textAlign: "right" }}>${moneyFromCents(filing.discount || 0)}</div> });
        base.push({ fees: <div style={{ textAlign: "right" }}>${moneyFromCents(filing.fees || 0)}</div> });
        base.push({
            amountDue: (
                <div style={{ textAlign: "right" }}>
                    ${moneyFromCents(+(filing.taxDue || 0) - +(filing.discount || 0) + +(filing.fees || 0))}
                </div>
            ),
        });

        base.push({
            amountRemitted: (
                <div style={{ textAlign: "right" }}>
                    ${moneyFromCents(filing.remitted || 0)}
                    <br />
                    <WellsTransactionMatchIcon
                        wfTransactionId={filing.wfPart1}
                        filingId={filingId}
                        onChange={refresh}
                    />{" "}
                </div>
            ),
        });
        if ((filing.remittedPart2 ?? 0) !== 0) {
            base.push({
                amountRemittedPart2: (
                    <div style={{ textAlign: "right" }}>
                        ${moneyFromCents(filing.remittedPart2 || 0)}
                        <br />
                        <WellsTransactionMatchIcon
                            wfTransactionId={filing.wfPart2}
                            filingId={filingId}
                            onChange={refresh}
                        />{" "}
                    </div>
                ),
            });
        }
        if ((filing.remittedPart3 ?? 0) !== 0) {
            base.push({
                amountRemittedPart3: (
                    <div style={{ textAlign: "right" }}>
                        ${moneyFromCents(filing.remittedPart3 || 0)}
                        <br />
                        <WellsTransactionMatchIcon
                            wfTransactionId={filing.wfPart3}
                            filingId={filingId}
                            onChange={refresh}
                        />{" "}
                    </div>
                ),
            });
        }
        if ((filing.remittedPart4 ?? 0) !== 0) {
            base.push({
                amountRemittedPart4: (
                    <div style={{ textAlign: "right" }}>
                        ${moneyFromCents(filing.remittedPart4 || 0)}
                        <br />
                        <WellsTransactionMatchIcon
                            wfTransactionId={filing.wfPart4}
                            filingId={filingId}
                            onChange={refresh}
                        />{" "}
                    </div>
                ),
            });
        }
        if ((filing.remittedPart5 ?? 0) !== 0) {
            base.push({
                amountRemittedPart5: (
                    <div style={{ textAlign: "right" }}>
                        ${moneyFromCents(filing.remittedPart5 || 0)}
                        <br />
                        <WellsTransactionMatchIcon
                            wfTransactionId={filing.wfPart5}
                            filingId={filingId}
                            onChange={refresh}
                        />{" "}
                    </div>
                ),
            });
        }
        base.push({
            amountRemittedDirect: (
                <div style={{ textAlign: "right" }}>${moneyFromCents(filing.remittedDirect || 0)}</div>
            ),
        });
        base.push({
            amountOutstanding: (
                <div style={{ textAlign: "right" }}>
                    $
                    {moneyFromCents(
                        +(filing.taxDue || 0) -
                            +(filing.discount || 0) +
                            +(filing.fees || 0) -
                            +(filing.remitted || 0) -
                            +(filing.remittedPart2 || 0) -
                            +(filing.remittedPart3 || 0) -
                            +(filing.remittedPart4 || 0) -
                            +(filing.remittedPart5 || 0) -
                            +(filing.remittedDirect || 0)
                    )}
                </div>
            ),
        });
        base.push({ note: filing.note ?? "" });
        base.push({ merchantNote: filing.merchantNote ?? "" });
        return <FieldList object={Object.assign({}, ...base)} />;
    };

    const renderAccountNote = (accountNote: string | undefined) => {
        if (!accountNote) {
            return null;
        }
        return (
            <div>
                <strong>Account Note:</strong>
                <BodyText>{accountNote}</BodyText>
            </div>
        );
    };

    const getNotFundedTotalCents = () => {
        let tax = fundingStatus.tax ?? 0;
        tax = tax - +(fundingStatus.status.fundedIn ?? 0);
        tax = tax + +(fundingStatus.status.fundedOut ?? 0);
        tax = tax - +(fundingStatus.status.pendingIn ?? 0);
        tax = tax + +(fundingStatus.status.pendingOut ?? 0);
        // Negative shouldn't really happen under normal operation, but is seen on migrated accounts sometimes.
        // Overfunding is implied and shows well on the screen...having it also fall into "Not Funded" is confusing.
        return tax <= 0 ? 0 : tax;
    };

    const doResendConfirmation = () => {
        resendFilingConfirmation(filing.id)
            .then(() => d30Toast("Confirmation sent."))
            .catch(() => d30ToastError("Filing could not be reopened."));
    };

    const calculateAmountWeCanPay = () => {
        const available = maxAvailable;
        if (filing.status === "open" && taxProfileTaxRates && available === 0 && !filing.taxProfile.filingMethod) {
            return (
                <div style={{ width: "100%" }}>
                    <Tooltip title={`Account not funded.`}>
                        <Alert style={{ marginTop: "10px", width: "100%" }} severity="error">
                            NOT FUNDED; DO NOT REMIT FUNDS
                        </Alert>
                    </Tooltip>
                </div>
            );
        }

        if (filing.status === "open" && taxProfileTaxRates && available > 0 && !filing.taxProfile.filingMethod) {
            let maxCover = taxFromBreakout * configInfo.filingSettings.maxAdjustmentPercentage;
            if (!isNil(nextMonthFunding) && nextMonthFunding < maxCover) {
                // don't allow excess remittance in cases where the next month does not have matching funds on hand
                maxCover = Math.max(nextMonthFunding, 0);
            }
            let max = available + maxCover;
            if (locationsIncludedInFiling.some((loc) => loc.bankAccount?.paused) || max < taxFromBreakout) {
                // don't allow excess remittance for paused banks
                // don't allow excess remittance in cases where that would still cause the filing to be partial paid
                max = available;
            }

            return (
                <>
                    <div style={{ width: "100%" }}>
                        <Tooltip title={`Accounts for 10% over tax due.`}>
                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="error">
                                FUNDING LIMIT; remittance not to exceed ${moneyFromCents(max)}
                            </Alert>
                        </Tooltip>
                    </div>
                    {partner && partner.restrictPartialPay && (
                        <div style={{ width: "100%" }}>
                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                                This partner does not allow partial pay.
                            </Alert>
                        </div>
                    )}
                </>
            );
        }

        if (
            filing.status === "open" &&
            !filing.taxProfile.filingMethod &&
            available > taxFromBreakout &&
            filing.prepayment
        ) {
            return (
                <div style={{ width: "100%" }}>
                    <Tooltip title={`Prepayments should not remit more than POS calculated funding.`}>
                        <Alert style={{ marginTop: "10px", width: "100%" }} severity="error">
                            OVER FUNDED PREPAYMENT; remittance not to exceed ${moneyFromCents(taxFromBreakout)}
                        </Alert>
                    </Tooltip>
                </div>
            );
        }
        return null;
    };

    const handleReopen = async (reason: string) => {
        if (!loginContext.user) {
            return;
        }
        setShowReopenModal(false);
        if (!isReopening && ["wontfile", "returned", "filed"].includes(filing.status)) {
            setIsReopening(true);
            try {
                await reOpenFiling(filing.id, loginContext.user.id, reason);
                d30Toast("Filing reopened.");
                refresh();
            } catch {
                d30ToastError("Filing could not be reopened.");
            } finally {
                setIsReopening(false);
            }
        }
    };

    const shouldShowTenderOrPOSTypeInfo = () => {
        if (!jurisdictionConfig) {
            return false;
        }
        if (filingTypeConfig) {
            return filingTypeConfig.tenderTypeRequired || filingTypeConfig.posTypeRequired;
        } else {
            return jurisdictionConfig.tenderTypeRequired || jurisdictionConfig.posTypeRequired;
        }
    };

    const getFieldsForFundingBlock = () => {
        const base = [];

        base.push({
            funded: <div style={{ textAlign: "right" }}>${moneyFromCents(fundingStatus.status.fundedIn ?? 0)}</div>,
        });
        if ((fundingStatus.status.fundedOut ?? 0) !== 0) {
            base.push({
                returned: (
                    <div style={{ textAlign: "right" }}>
                        ${moneyFromCents(-1 * (fundingStatus.status.fundedOut ?? 0))}{" "}
                        {/* fundedOut will be negative, flip sign for UI */}
                    </div>
                ),
            });
        }
        if ((fundingStatus.status.transferred ?? 0) !== 0) {
            base.push({
                transferred: (
                    <div style={{ textAlign: "right" }}>${moneyFromCents(fundingStatus.status.transferred ?? 0)}</div>
                ),
            });
        }
        base.push({
            pendingIn: <div style={{ textAlign: "right" }}>${moneyFromCents(fundingStatus.status.pendingIn ?? 0)}</div>,
        });
        base.push({
            pendingOut: (
                <div style={{ textAlign: "right" }}>${moneyFromCents(fundingStatus.status.pendingOut ?? 0)}</div>
            ),
        });

        base.push({
            notFunded:
                (fundingStatus.status.failed ?? 0) > 0 ? (
                    <Tooltip
                        title={`There are rejected transactions totalling: $${moneyFromCents(
                            fundingStatus.status.failed ?? 0
                        )}`}>
                        <div style={{ textAlign: "right" }}>
                            <span>${moneyFromCents(getNotFundedTotalCents())}</span>
                            <InfoIcon
                                fontSize={"small"}
                                color="primary"
                                style={{ verticalAlign: "middle", marginLeft: "5px" }}
                            />
                        </div>
                    </Tooltip>
                ) : (
                    <div style={{ textAlign: "right" }}>${moneyFromCents(getNotFundedTotalCents())}</div>
                ),
        });
        base.push({
            otherRemittances: (
                <div style={{ textAlign: "right" }}>${moneyFromCents(nonPrepaymentRemittances ?? 0)}</div>
            ),
        });

        base.push({
            prepaymentsMade: <div style={{ textAlign: "right" }}>${moneyFromCents(prepaymentsMade ?? 0)}</div>,
        });

        base.push({
            remitted: (
                <div style={{ textAlign: "right" }}>
                    $
                    {moneyFromCents(
                        (Math.round(fundingStatus.remitted) ?? 0) -
                            Math.round(
                                prepaymentsMade
                                    ? prepaymentsMade + nonPrepaymentRemittances
                                    : (nonPrepaymentRemittances ?? 0)
                            )
                    )}
                </div>
            ),
        });

        base.push({ available: <div style={{ textAlign: "right" }}>${moneyFromCents(Math.round(maxAvailable))}</div> });

        return Object.assign({}, ...base);
    };

    return (
        <div>
            {renderAlerts()}
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}
                expanded={true}>
                <AccordionDetails style={{ display: "flex" }}>
                    <div>
                        <ReactTable
                            columns={summaryColumns}
                            data={summaryData}
                            options={{
                                hideToolbar: true,
                                pageSize: 4,
                                pageSizeOptions: [4],
                            }}
                        />
                        <div>
                            <Radio
                                color="primary"
                                checked={taxType === "distinct"}
                                onClick={async () => {
                                    try {
                                        setTaxType("distinct");
                                        await setTaxRateType(filing.taxProfileId, "distinct");
                                        refresh();
                                        d30Toast("Tax profile settings updated");
                                    } catch (e: any) {
                                        d30ToastError(e.message);
                                    }
                                }}
                            />
                            &nbsp;&nbsp;Distinct Tax Rates
                        </div>
                        <div>
                            <Radio
                                color="primary"
                                checked={taxType === "overlapping"}
                                onClick={async () => {
                                    try {
                                        setTaxType("overlapping");
                                        await setTaxRateType(filing.taxProfileId, "overlapping");
                                        refresh();
                                        d30Toast("Tax profile settings updated");
                                    } catch (e: any) {
                                        d30ToastError(e.message);
                                    }
                                }}
                            />
                            &nbsp;&nbsp;Overlapping Tax Rates
                        </div>
                        {shouldShowTenderOrPOSTypeInfo() && (
                            <div style={{ padding: "40px 0 20px 0" }}>
                                <FieldList object={getTenderTypesAndPos()} />
                            </div>
                        )}
                        <>
                            <div style={{ paddingTop: "25px" }}>
                                {(filing.status === "open" || isEditingFiling || isAmendingFiling) && (
                                    <>
                                        <TaxProfileCredentialsInfo
                                            isReadOnly={
                                                locationsIncludedInFiling.every((l) => !l.active) ||
                                                isTakeGiveBack(filing.taxProfile.filingMethod)
                                            }
                                            taxProfileId={filing.taxProfileId}
                                            accountId={filing.taxProfile.account.id}
                                            callback={refresh}
                                            partner={partner}
                                            internalGroupPolicyStates={internalGroupPolicyStates}
                                            partnerGroupPolicyStates={partnerGroupPolicyStates}
                                        />
                                        <br />
                                    </>
                                )}
                                {filing.status !== "open" && !isEditingFiling && !isAmendingFiling && (
                                    <TaxProfileCredentialsButton
                                        isReadOnly={
                                            locationsIncludedInFiling.every((l) => !l.active) ||
                                            isTakeGiveBack(filing.taxProfile.filingMethod)
                                        }
                                        taxProfileId={filing.taxProfileId}
                                        taxProfilePreloaded={filing.taxProfile}
                                        accountId={filing.taxProfile.account.id}
                                        callback={refresh}
                                        partner={partner}
                                    />
                                )}
                            </div>

                            {(filing.status === "open" || isEditingFiling || isAmendingFiling) &&
                                configInfo &&
                                !isFirstMonthMerchantPay &&
                                !["file-only", "take-give-back", "merchant-pay"].includes(
                                    filing.taxProfile.filingMethod ?? ""
                                ) &&
                                // available fund or remitted
                                (0 < maxAvailable ||
                                    0 < (fundingStatus.remitted ?? 0) ||
                                    hasMidMonthPaymentOrIsWeirdCA(filing)) &&
                                (taxProfileTaxRates
                                    ? taxProfileTaxRates.filter((x) => x.included === "unverified").length === 0
                                    : true) && (
                                    <div style={{ paddingTop: "25px" }}>
                                        <Typography style={{ textDecoration: "underline" }}>
                                            PAYOR Information
                                        </Typography>
                                        <br />
                                        {configInfo.payorName}&nbsp;&nbsp;
                                        <CopyToClipboard text={configInfo.payorName} onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                        <br />
                                        {configInfo.payorAddress}&nbsp;&nbsp;
                                        <CopyToClipboard
                                            text={configInfo.payorAddress}
                                            onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                        <br />
                                        {configInfo.payorTown}&nbsp;&nbsp;
                                        <CopyToClipboard text={configInfo.payorTown} onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                        <br />
                                        {configInfo.payorStateAndZIP}&nbsp;&nbsp;
                                        <CopyToClipboard
                                            text={configInfo.payorStateAndZIP}
                                            onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                        <br />
                                        <br />
                                        {configInfo.bankName}&nbsp;&nbsp;
                                        <CopyToClipboard text={configInfo.bankName} onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                        <br />
                                        {payor.routing}&nbsp;&nbsp;
                                        <CopyToClipboard text={payor.routing} onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                        <br />
                                        {payor.accountNumber}&nbsp;&nbsp;
                                        <CopyToClipboard text={payor.accountNumber} onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                    </div>
                                )}
                            {(filing.status === "open" || isEditingFiling || isAmendingFiling) &&
                                (isFirstMonthMerchantPay ||
                                    filing.taxProfile.filingMethod === "merchant-pay" ||
                                    filing.taxProfile.state === "WA") &&
                                getOneBankAccount().bankAccountId && (
                                    <div style={{ paddingTop: "50px" }}>
                                        <Typography style={{ textDecoration: "underline" }}>
                                            PAYOR Information{" "}
                                            {filing.taxProfile.state === "WA" ? `(for WA B&O/Litter)` : ""}
                                        </Typography>
                                        <br />
                                        {getOneBankAccount().bankAccountName}&nbsp;&nbsp;
                                        <CopyToClipboard
                                            text={getOneBankAccount().bankAccountName ?? "Bank"}
                                            onCopy={() => d30Toast("Copied")}>
                                            <FileCopyTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                        </CopyToClipboard>
                                        <br />
                                        <BankAccountCredentialsButton
                                            bankAccountId={getOneBankAccount().bankAccountId}
                                        />
                                    </div>
                                )}
                        </>
                    </div>
                    <div style={{ paddingLeft: "40px" }}>
                        <FieldList object={getFieldsForFundingBlock()} pageSize={12} />
                        <FilingAccounting filing={filing} />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingLeft: "40px",
                        }}>
                        {calculateAmountWeCanPay()}
                        {(filing.status === "open" || isEditingFiling) &&
                            filing.taxProfile.frequency !== filing.frequency && (
                                <div style={{ width: "100%" }}>
                                    <Alert style={{ marginTop: "10px", width: "100%" }} severity="error">
                                        Filing frequency does not match the Tax Profile, please verify this filing is
                                        valid.
                                    </Alert>
                                </div>
                            )}
                        {(filing.status === "open" || isEditingFiling) && !isNil(filing.bulkTaskId) && (
                            <div style={{ width: "100%" }}>
                                <Tooltip title={`Accounts for 10% over tax due.`}>
                                    <Alert style={{ marginTop: "10px", width: "100%" }} severity="error">
                                        BULK PROCESSING: Filing cannot be manually filed at this time.
                                    </Alert>
                                </Tooltip>
                            </div>
                        )}
                        {displayNotificationForMissingBankAccount() && (
                            <div style={{ width: "100%" }}>
                                <Alert style={{ marginTop: "10px", width: "100%" }} severity="error">
                                    Bank account is missing for at least one location.
                                </Alert>
                            </div>
                        )}
                        {(filing.status === "open" || isEditingFiling || isAmendingFiling) &&
                            taxProfileTaxRates &&
                            taxProfileTaxRates.filter((x) => x.included === "unverified").length > 0 && (
                                <div style={{ width: "100%" }}>
                                    <Alert style={{ marginTop: "10px", width: "100%" }} severity="error">
                                        There are unverified Tax Rates.
                                        <Button
                                            onClick={() => {
                                                setShowTaxProfileVerificationModal(true);
                                            }}
                                            color="primary"
                                            size={"small"}
                                            variant="outlined"
                                            style={{ marginTop: "5px", backgroundColor: "white" }}>
                                            Verify tax rates
                                        </Button>
                                        <br />
                                        {(isEditingFiling || isAmendingFiling) && (
                                            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                                                Verify tax rates before editing or cancel
                                                <br />
                                                <Button
                                                    onClick={() => {
                                                        cancelEditingFiling();
                                                        cancelAmendingFiling();
                                                    }}
                                                    color="primary"
                                                    size={"small"}
                                                    variant="outlined"
                                                    style={{ marginTop: "5px", backgroundColor: "white" }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        )}
                                    </Alert>
                                </div>
                            )}
                        {(filing.status === "open" || isEditingFiling) &&
                            (!hasPendingFunds || hasMidMonthPaymentOrIsWeirdCA(filing)) &&
                            (taxProfileTaxRates
                                ? taxProfileTaxRates.filter((x) => x.included === "unverified").length === 0
                                : true) && (
                                <>
                                    {filing.periodEnd > BusinessDay.today() && (
                                        <div style={{ width: "100%" }}>
                                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                                                Filing period doesn&apos;t end until{" "}
                                                {toDisplayDateString(filing.periodEnd)}, please be extra sure you really
                                                should be filing this.
                                            </Alert>
                                        </div>
                                    )}
                                    {filing.filingDueDate > BusinessDay.today() && BusinessDay.today().day < 5 && (
                                        <div style={{ width: "100%" }}>
                                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                                                Filing data may not be complete until the 5th, please be extra sure you
                                                really should be filing this.
                                            </Alert>
                                        </div>
                                    )}

                                    {!isEmpty(stateTaxIdDuplicates) && (
                                        <div style={{ width: "100%" }}>
                                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                                                Another filing exists with the same State Tax ID. Please resolve this
                                                issue before continuing.
                                                <div>
                                                    {" "}
                                                    Duplicates:&nbsp;
                                                    {stateTaxIdDuplicates.map((f, index) => (
                                                        <>
                                                            <DavoLink
                                                                to={`/filings/${f.id}`}
                                                                target={"_blank"}
                                                                style={{ display: "inline" }}>
                                                                <Tooltip title={f.description}>
                                                                    <span>{index + 1}</span>
                                                                </Tooltip>
                                                            </DavoLink>
                                                            {index !== stateTaxIdDuplicates.length - 1 && <>, </>}
                                                        </>
                                                    ))}
                                                </div>
                                            </Alert>
                                        </div>
                                    )}

                                    {!isEmpty(FEINDuplicates) && (
                                        <div style={{ width: "100%" }}>
                                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                                                Another filing exists with the same FEIN. Please resolve this issue
                                                before continuing.
                                                <div>
                                                    {" "}
                                                    Duplicates:&nbsp;
                                                    {FEINDuplicates.map((f, index) => (
                                                        <>
                                                            <DavoLink
                                                                to={`/filings/${f.id}`}
                                                                target={"_blank"}
                                                                style={{ display: "inline" }}>
                                                                <Tooltip title={f.description}>
                                                                    <span>{index + 1}</span>
                                                                </Tooltip>
                                                            </DavoLink>
                                                            {index !== FEINDuplicates.length - 1 && <>, </>}
                                                        </>
                                                    ))}
                                                </div>
                                            </Alert>
                                        </div>
                                    )}

                                    {!isEmpty(taxProfileOtherFilingsRequiringAmendment) && (
                                        <div style={{ width: "100%" }}>
                                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                                                Another filing exists requiring an amendment. Please resolve this issue
                                                before continuing.
                                                <div>
                                                    {" "}
                                                    Filings:&nbsp;
                                                    {taxProfileOtherFilingsRequiringAmendment.map((f, index) => (
                                                        <>
                                                            <DavoLink
                                                                to={`/filings/${f.id}`}
                                                                target={"_blank"}
                                                                style={{ display: "inline" }}>
                                                                <Tooltip title={f.description}>
                                                                    <span>{index + 1}</span>
                                                                </Tooltip>
                                                            </DavoLink>
                                                            {index !==
                                                                taxProfileOtherFilingsRequiringAmendment.length - 1 && (
                                                                <>, </>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </Alert>
                                        </div>
                                    )}

                                    {(!doesTaxProfileHavePriorOpenFilings || isEditingFiling) && (
                                        <>
                                            {(isFirstMonthMerchantPay ||
                                                filing.taxProfile.filingMethod === "merchant-pay") && (
                                                <div style={{ fontWeight: "bold", width: "100%" }}>
                                                    <Alert
                                                        style={{ marginTop: "10px", width: "100%" }}
                                                        severity="warning">
                                                        No funds collected, pay directly from merchant&apos;s account.
                                                    </Alert>
                                                </div>
                                            )}

                                            {locationsIncludedInFiling.some((loc) => !loc.active) && (
                                                <div style={{ width: "100%" }}>
                                                    <Alert
                                                        style={{ marginTop: "10px", width: "100%" }}
                                                        severity="warning">
                                                        This merchant has a disconnected location.
                                                    </Alert>
                                                </div>
                                            )}

                                            {isTakeGiveBack(filing.taxProfile.filingMethod) && (
                                                <div style={{ fontWeight: "bold", width: "100%" }}>
                                                    <Alert style={{ marginTop: "10px", width: "100%" }} severity="info">
                                                        Take and give back tax profile, return funds to merchant.
                                                    </Alert>
                                                </div>
                                            )}

                                            {filing.taxProfile.filingMethod === "file-only" && (
                                                <div style={{ fontWeight: "bold", width: "100%" }}>
                                                    <Alert style={{ marginTop: "10px", width: "100%" }} severity="info">
                                                        {" "}
                                                        File-only tax profile, no funds collected.
                                                    </Alert>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {doesTaxProfileHavePriorOpenFilings && !isEditingFiling && (
                                        <div style={{ width: "100%" }}>
                                            <Alert style={{ marginTop: "10px", width: "100%" }} severity="info">
                                                Cannot close this filing until older filings for this tax profile are
                                                closed.
                                            </Alert>
                                        </div>
                                    )}
                                </>
                            )}
                        {hasPendingFunds && (
                            <div style={{ width: "100%" }}>
                                <Alert style={{ marginTop: "10px", width: "100%" }} severity="info">
                                    There are pending transactions.
                                </Alert>
                            </div>
                        )}
                        {partner && (
                            <div style={{ width: "100%" }}>
                                <Alert style={{ marginTop: "10px", width: "100%" }} severity="info">
                                    This filing is associated with a {partner.name} account.
                                </Alert>
                            </div>
                        )}
                        <div style={{ marginTop: "5px" }}>
                            <FilingAttachmentUpload
                                filing={filing}
                                onUpload={refresh}
                                isEditingFiling={isEditingFiling}
                                isAmendingFiling={isAmendingFiling}
                            />
                        </div>
                        {filing.assignedUser && filing.assignedUser.id !== loginContext.user?.id && (
                            <Alert style={{ marginTop: "15px" }} severity="warning">
                                Filing is currently assigned to {filing.assignedUser.firstName}, please consult them for
                                more information
                            </Alert>
                        )}
                        {renderFilingDetailForm()}
                        {filing.status === "filed" && getFieldList()}
                        {filing.status === "wontfile" && (
                            <FieldList
                                object={{
                                    status: statusLabel[filing.status],
                                    closedOn: filing.filedDate ? toDisplayDateTimeString(filing.filedDate) : "",
                                    closedBy: `${filing.filedBy?.firstName} ${filing.filedBy?.lastName}`,
                                    note: filing.note,
                                }}
                            />
                        )}
                        {filing.status === "returned" && (
                            <FieldList
                                object={{
                                    status: statusLabel[filing.status],
                                    closedOn: filing.filedDate ? toDisplayDateTimeString(filing.filedDate) : "",
                                    closedBy: `${filing.filedBy?.firstName} ${filing.filedBy?.lastName}`,
                                    amountReturned: (
                                        <div style={{ textAlign: "right" }}>
                                            ${moneyFromCents(filing.returned || 0)}
                                        </div>
                                    ),
                                    note: filing.note,
                                    merchantNote: filing.merchantNote,
                                }}
                            />
                        )}
                        <FilingAttachments
                            accountId={filing.taxProfile.account.id}
                            filing={filing}
                            isEditing={filing.status === "open" || isEditingFiling || isAmendingFiling}
                            onRemove={refresh}
                        />
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {filing.status === "filed" && !(isEditingFiling || isReopening || isAmendingFiling) && (
                                <>
                                    <Tooltip
                                        title={
                                            hasPendingFunds
                                                ? "Filing cannot be edited until pending funds are settled. Please try again later."
                                                : ""
                                        }>
                                        <span>
                                            <Button
                                                onClick={editFiling}
                                                color="primary"
                                                size={"small"}
                                                variant="outlined"
                                                style={{
                                                    marginLeft: "2px",
                                                    marginRight: "5px",
                                                    marginTop: "2px",
                                                    marginBottom: "2px",
                                                }}
                                                disabled={hasPendingFunds}>
                                                Edit Filing
                                            </Button>
                                        </span>
                                    </Tooltip>

                                    <Button
                                        onClick={amendingFiling}
                                        color="primary"
                                        size={"small"}
                                        variant="outlined"
                                        style={{
                                            marginLeft: "2px",
                                            marginRight: "5px",
                                            marginTop: "2px",
                                            marginBottom: "2px",
                                        }}>
                                        Amend Filing
                                    </Button>
                                </>
                            )}
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {["wontfile", "returned", "filed"].includes(filing.status) &&
                                !(isEditingFiling || isReopening || isAmendingFiling) && (
                                    <Button
                                        onClick={reOpen}
                                        disabled={
                                            isReopening ||
                                            isEditingFiling ||
                                            !hasPermission(loginContext.permissions, "reopen_filing")
                                        }
                                        color="secondary"
                                        size={"small"}
                                        variant="outlined"
                                        style={{
                                            marginLeft: "2px",
                                            marginRight: "5px",
                                            marginTop: "2px",
                                            marginBottom: "2px",
                                        }}>
                                        Reopen Filing
                                    </Button>
                                )}
                            {showReopenModal && (
                                <ReopenFilingModal
                                    handleReopen={handleReopen}
                                    closeDialog={() => setShowReopenModal(false)}
                                />
                            )}
                            {filing.status !== "open" && !isAmendingFiling && (
                                <div>
                                    <Button
                                        onClick={doResendConfirmation}
                                        color="secondary"
                                        size={"small"}
                                        variant="outlined"
                                        style={{
                                            marginLeft: "2px",
                                            marginRight: "5px",
                                            marginTop: "2px",
                                            marginBottom: "2px",
                                        }}>
                                        Resend Filing Confirmation
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <TaxBreakout />

            {!isEmpty(additionalSales) && (
                <ManualActivityTable
                    additionalSalesManualActivity={additionalSales}
                    filterLocations={locationsIncludedInFiling}
                />
            )}

            {!isEmpty(useTax) && <UseTaxTable useTax={useTax} filterLocations={locationsIncludedInFiling} />}

            <PrecludedTaxBreakOut />

            <NonTaxableByLocationAccordion />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Daily Activity
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <DailyActivity filterLocations={locationsIncludedInFiling} filingId={filing.id} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", flexDirection: "column" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Penalties
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                    <PenaltyTable filingId={filingId} hideAccountColumns={true} showWells={true} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", flexDirection: "column" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Update Audit
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                    <FilingUpdateAudit filingId={filingId} />
                </AccordionDetails>
            </Accordion>

            <div style={{ marginTop: "5px" }} />
            <div className={classes.buttonAlignment}>
                {filing.status === "open" && (
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => showFilingAssigneeModal(filing.assignedUserId)}
                        style={{ margin: "5px 2px" }}>
                        {filing.assignedUserId ? "Reassign" : "Assign"}
                    </Button>
                )}
                {filingAssigneeModalProps.isDialogOpen && (
                    <AssignStaffModal
                        shouldFilterByFilers={true}
                        passThroughIds={[filing.id]}
                        assignStaffUser={assignFilingAssignee}
                        {...filingAssigneeModalProps}
                    />
                )}

                {!["wontfile", "returned", "filed"].includes(filing.status) && (
                    <div style={{ margin: "5px" }}>
                        <ManualActivityModal
                            accountId={filing.taxProfile.account.id}
                            locations={locationsIncludedInFiling}
                            isDisabled={false}
                            refresh={refresh}
                            canAddNewTaxRate={true}
                            isMerchantPay={
                                filing.taxProfile.filingMethod === "file-only" ||
                                filing.taxProfile.filingMethod === "merchant-pay"
                            }
                            filingMonth={filing.periodStart}
                            maxTaxCollectedAllowed={loginContext.user?.type !== "Superuser" ? 10000 : undefined}
                            useDefaultPeriod={true}
                            buttonVariant={"outlined"}
                        />
                    </div>
                )}

                {renderReturnFundsButton()}

                {filing.status === "open" && taxProfileTaxRates && (
                    <>
                        <Button
                            onClick={trueUp}
                            disabled={
                                !hasPermission(loginContext.permissions, "trueup_filing") ||
                                getNotFundedTotalCents() === 0 ||
                                !getFilingDatesAreTrueUpEligible()
                            }
                            color="secondary"
                            size={"small"}
                            variant="outlined"
                            style={{ margin: "5px 2px" }}>
                            True Up Filing
                        </Button>
                        {trueUpConfirmationModalProps.isDialogOpen && (
                            <ConfirmationModal
                                message={`Are you sure you want to true up this filing?  This will set aside $${moneyFromCents(
                                    getNotFundedTotalCents()
                                )}`}
                                title="True up filing?"
                                {...trueUpConfirmationModalProps}
                                target={filing.id}
                            />
                        )}
                    </>
                )}

                {filing.status === "open" && isNil(filing.bulkTaskId) && (
                    <>
                        <Button
                            onClick={regenerateThis}
                            color="secondary"
                            size={"small"}
                            variant="outlined"
                            style={{ margin: "5px 2px" }}>
                            Regenerate Filing
                        </Button>
                        {regenerateConfirmationModalProps.isDialogOpen && (
                            <ConfirmationModal
                                message="Are you sure you want to regenerate this filing?"
                                title="Regenerate filing?"
                                {...regenerateConfirmationModalProps}
                                target={filing.id}
                            />
                        )}
                    </>
                )}
            </div>

            {(filing.status === "open" || isEditingFiling || isAmendingFiling) && (
                <div className={classes.buttonAlignment}>
                    {configInfo && (
                        <>
                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                style={{ margin: "5px 2px" }}
                                onClick={() => showTaxProfileEdit([filing.taxProfile.id])}>
                                Edit Tax Profile
                            </Button>
                            {taxProfileEditProps.isDialogOpen && (
                                <TaxProfileEdit
                                    accountId={filing.taxProfile.account.id}
                                    {...taxProfileEditProps}
                                    shouldLimitEdit={true}
                                />
                            )}
                        </>
                    )}

                    <Button
                        onClick={() => {
                            setShowTaxProfileVerificationModal(true);
                        }}
                        color="primary"
                        size={"small"}
                        variant="outlined"
                        style={{ margin: "5px 2px" }}>
                        Edit included tax rates
                    </Button>
                    {showTaxProfileVerificationModal && (
                        <TaxProfileVerificationModal
                            accountId={filing.taxProfile.account.id}
                            taxProfileId={filing.taxProfile.id}
                            taxProfileTaxRates={taxProfileTaxRates}
                            state={filing.taxProfile.state}
                            filingIdForContinue={filing.id}
                            closeDialog={() => {
                                setShowTaxProfileVerificationModal(false);
                            }}
                            onSave={() => {
                                refresh();
                            }}
                        />
                    )}

                    <div style={{ margin: "5px 2px" }}>
                        <SetFilingNote taxProfile={filing.taxProfile} refresh={refresh} />
                    </div>

                    {!isTakeGiveBack(filing.taxProfile.filingMethod) && (
                        <div style={{ margin: "5px 2px" }}>
                            <Button
                                onClick={() => showAssignStateExternalId()}
                                color="primary"
                                size={"small"}
                                variant="outlined">
                                Edit External Id
                            </Button>
                            {assignStateExternalIdProps.isDialogOpen && (
                                <LocationExternalIdentifierModal
                                    accountId={filing.taxProfile.account.id}
                                    locations={locationsIncludedInFiling}
                                    taxProfile={filing.taxProfile}
                                    partner={partner}
                                    refresh={refresh}
                                    {...assignStateExternalIdProps}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
