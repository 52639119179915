import {
    PaperComponent,
    Select,
    TextField,
    d30Toast,
    d30ToastError,
    disconnectLocation,
    runDisconnectAutomation,
} from "@davo/portal-common";
import { DisconnectReasonCodes, DiscountReasonKeys, DiscountReasons } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";

interface IForceDisconnectButtonType {
    locationId: string;
    disconnected?: DateTime | null;
    show: boolean;
    onClose?: () => void;
}

export function ForceDisconnectButton({ locationId, disconnected, show, onClose }: IForceDisconnectButtonType) {
    const [isDialogShowing, setIsDialogShowing] = useState<boolean>(false);
    const [disconnectReasonCode, setDisconnectReasonCode] = useState<DisconnectReasonCodes | undefined>(undefined);
    const [disconnectReasonNote, setDisconnectReasonNote] = useState<string | undefined>("");

    const showDialog = () => {
        setDisconnectReasonNote("");
        setIsDialogShowing(true);
    };

    const doSubmit = () => {
        if (!disconnectReasonCode) {
            d30ToastError(`Error: Disconnect Reason required`);
            return;
        }
        disconnectLocation(locationId, disconnectReasonCode, disconnectReasonNote)
            .then(async () => {
                if (["CPA", "SELF", "DELINQUENT"].includes(disconnectReasonCode)) {
                    await runDisconnectAutomation(locationId, disconnectReasonCode)
                        .then(() => {
                            d30Toast(`Disconnect automation complete, location marked inactive`);
                        })
                        .catch((e: any) => d30ToastError(`Could not run disconnect automation: ${e.message}`));
                } else {
                    d30Toast(`Location disconnected`);
                }
            })
            .then(() => {
                setIsDialogShowing(false);
                if (onClose) {
                    onClose();
                }
            })
            .catch((x) => d30ToastError(`Error forcing disconnect: ${x.message}`));
    };

    if (!show || disconnected) {
        return null;
    }
    return (
        <div style={{ marginTop: "2px", marginRight: "2px" }}>
            <Button
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="secondary"
                style={{ marginBottom: "3px" }}>
                Force Disconnect
            </Button>

            {isDialogShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsDialogShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Force disconnect?</DialogTitle>

                    <DialogContent style={{ padding: "0px 24px" }}>
                        <Select<DisconnectReasonCodes>
                            title="Reason"
                            value={disconnectReasonCode}
                            onChange={setDisconnectReasonCode}
                            options={DiscountReasonKeys}
                            label={(disconnectCode) => DiscountReasons[disconnectCode]}
                        />
                    </DialogContent>
                    {disconnectReasonCode && disconnectReasonCode === "OTHER" && (
                        <DialogContent style={{ padding: "0px 24px" }}>
                            <TextField
                                label={"Reason for disconnecting"}
                                value={disconnectReasonNote ?? ""}
                                onChange={setDisconnectReasonNote}
                                isMultiline={true}
                                rows={2}
                            />
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsDialogShowing(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={
                                !disconnectReasonCode || (disconnectReasonCode === "OTHER" && !disconnectReasonNote)
                            }
                            onClick={doSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
