import { Filing, FilingPenaltyExtension, OptionalString } from "@davo/types";
import { DateTime } from "luxon";
import { AddNoteModal } from "../AddNoteModal";
import { setFilingWellsNote } from "../services";

export type FilingWithNote = Filing & { wellsNoteByName: OptionalString };

export function toNoteable(f: FilingWithNote | FilingPenaltyExtension) {
    return {
        id: f.id,
        note: f.wellsNote,
        noteBy: f.wellsNoteBy,
        noteByName: f.wellsNoteByName,
        noteDate: f.wellsNoteDate,
    };
}

export function FilingAddWellsNoteModal({ filing }: { filing: FilingWithNote }) {
    const updater = async (
        id: string,
        note: OptionalString,
        noteBy: OptionalString,
        noteByName: OptionalString,
        noteDate: DateTime | undefined | null
    ) => {
        await setFilingWellsNote(id, note);
        filing.wellsNote = note;
        filing.wellsNoteBy = noteBy;
        filing.wellsNoteByName = noteByName;
        filing.wellsNoteDate = noteDate;
    };
    return AddNoteModal({ noteable: toNoteable(filing), updater });
}
