import { IWellsFargoTransactionInfo } from "@davo/types";
import { DateTime } from "luxon";

export type WFTransactionFormattedAmount = IWellsFargoTransactionInfo & { transactionAmountFormatted: string };

export const filingSessionFormat = "yyyy-MM";
export const selectableMonths: string[] = [];

for (let i = 0; i < 18; i++) {
    const date = DateTime.local().startOf("month").minus({ months: i });
    selectableMonths.push(date.toFormat(filingSessionFormat));
}

export const monthYearLabel = (filingSession: string) => {
    const month = DateTime.fromFormat(filingSession, filingSessionFormat);
    return `${month.monthLong} ${month.year}`;
};

export const currentMonth = DateTime.local().toFormat(filingSessionFormat);

export const numberSorter = (fieldName: string) => {
    return (x: any, y: any) => {
        const a = x.original[fieldName];
        const b = y.original[fieldName];
        if (a > b) {
            return 1;
        } else if (b > a) {
            return -1;
        } else {
            return 0;
        }
    };
};
