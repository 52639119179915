import { d30Toast, d30ToastError, PaperComponent, Select, useModalEditor } from "@davo/portal-common";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import round from "lodash/round";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { billForExtraFilings, getStandardSubscriptionCharge } from "./services";

interface IBillForExtraFilingsModalType {
    locationId: string;
}

export function BillForExtraFilingsModal({ locationId }: IBillForExtraFilingsModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [numMonths, setNumMonths] = useState<number | undefined>(1);
    const [subscriptionCharge, setSubscriptionCharge] = useState<number | undefined>();
    const [monthList] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useAsyncEffect(async () => {
        if (isDialogOpen) {
            setNumMonths(1);
            try {
                const standard = (await getStandardSubscriptionCharge()).charge;
                setSubscriptionCharge(standard);
            } catch (e: any) {
                d30ToastError(e.message);
            }
        }
    }, [locationId, isDialogOpen]);

    const getAmount = () => {
        if (subscriptionCharge) {
            return round((subscriptionCharge / 100) * (numMonths ?? 1), 2);
        }
    };

    const doBill = async () => {
        if (!numMonths || !subscriptionCharge) {
            return;
        }
        try {
            setIsBusy(true);
            const amount = getAmount();
            await billForExtraFilings(locationId, numMonths);
            d30Toast(`Success! The merchant will see a charge of $${amount} on their next monthly invoice.`);
            closeDialog();
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <div>
            <Button variant="outlined" onClick={showDialog} size="small" color="primary" style={{ marginTop: "3px" }}>
                Bill for Extra Filings
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => closeDialog()}>
                    <DialogTitle id="draggable-dialog-title">Bill for Extra Filings</DialogTitle>
                    <DialogContent>
                        <Typography variant={"body1"}>
                            <Select<number>
                                title="Number of months"
                                options={monthList}
                                value={numMonths}
                                onChange={setNumMonths}
                                label={(s) => `${s}`}
                            />
                        </Typography>

                        <Typography>Amount: ${getAmount()}</Typography>
                        <Typography>Note: sales tax is not included here and will be calculated.</Typography>

                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" disabled={isBusy} onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!numMonths || isBusy || !subscriptionCharge}
                            onClick={() => doBill()}>
                            Bill
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
