import {
    d30Toast,
    d30ToastError,
    getTaxProfile,
    getUserPermissionsForAccount,
    Select,
    useLoginContext,
} from "@davo/portal-common";
import {
    getSMSTemplate,
    IUserAndPermissions,
    TaxProfileFailureReason,
    TaxProfileFailureReasonKeys,
    TaxProfileFailureReasonsDescriptions,
} from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { markTaxProfileCredentialsAsFailing, sendSmsMessage } from "./services";

export interface IConfirmFailingCredentialsType {
    accountId?: string;
    taxProfileId?: string;
    taxProfileState?: string;
    doneCallback?: () => void;
}

export function ConfirmFailingCredentials({ accountId, taxProfileId, doneCallback }: IConfirmFailingCredentialsType) {
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const loginContext = useLoginContext();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [failingMessage, setFailingMessage] = useState<TaxProfileFailureReason | undefined>();

    const markFailing = async () => {
        if (isBusy) return;
        try {
            setIsBusy(true);
            if (accountId && taxProfileId) {
                await markTaxProfileCredentialsAsFailing(accountId, taxProfileId, "failing", failingMessage);

                const users = await getUserPermissionsForAccount(accountId);
                const admins = users.filter((u: IUserAndPermissions) => !u.doNotSms && !u.smsUndeliverable);
                const tp = await getTaxProfile(accountId, taxProfileId);
                if (failingMessage) {
                    for (const admin of admins) {
                        try {
                            const sms = getSMSTemplate(failingMessage, "EN", admin, tp.state, loginContext.user).EN;
                            await sendSmsMessage(admin.id, sms, "user");
                            d30Toast("SMS Sent!");
                        } catch (err) {
                            d30ToastError("Error: cannot send SMS");
                        }
                    }
                }

                d30Toast("Email sent!");
            }
            doneCallback && doneCallback();
        } catch (e: any) {
            d30ToastError("Problem marking credentials failing", e);
            setIsBusy(false);
        } finally {
            setIsConfirming(false);
        }
    };

    return (
        <>
            <Button
                data-testid={"setFailingMessage"}
                disabled={isBusy}
                variant="outlined"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                    setIsConfirming(true);
                    setFailingMessage(undefined);
                }}
                size="small"
                color="secondary">
                Mark Failing
            </Button>
            {isConfirming && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsConfirming(false);
                    }}>
                    <DialogTitle>Mark Profile Failing?</DialogTitle>
                    <DialogContent>
                        <Select<TaxProfileFailureReason>
                            data-testid={"failureReasonSelector"}
                            title="Failing Reason"
                            isRequired
                            value={failingMessage}
                            onChange={setFailingMessage}
                            noneLabel="Select Reason"
                            options={TaxProfileFailureReasonKeys}
                            label={(status) => TaxProfileFailureReasonsDescriptions[status]}
                        />
                        <div style={{ textAlign: "center", fontSize: "10px", marginTop: "-10px" }}>
                            (Email copy varies to drive the action needed)
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isBusy} variant="outlined" onClick={() => setIsConfirming(false)}>
                            Cancel
                        </Button>
                        <Button
                            data-testid={"markFailingWithReason"}
                            variant="contained"
                            color="secondary"
                            onClick={markFailing}
                            disabled={!failingMessage || isBusy}
                            startIcon={
                                isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                            }>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
