import { IWellsFargoTransactionInfo, OptionalString } from "@davo/types";
import { DateTime } from "luxon";
import { AddNoteModal } from "../AddNoteModal";
import { setWellsTransactionNote } from "../services";

export function WellsAddNoteModal({ txn }: { txn: IWellsFargoTransactionInfo }) {
    const updater = async (
        id: string,
        note: OptionalString,
        noteBy: OptionalString,
        noteByName: OptionalString,
        noteDate: DateTime | undefined | null
    ) => {
        await setWellsTransactionNote(id, note);
        txn.note = note;
        txn.noteBy = noteBy;
        txn.noteByName = noteByName;
        txn.noteDate = noteDate;
    };
    return AddNoteModal({ noteable: txn, updater });
}
