import { d30ToastError, Loading, ReactTable } from "@davo/portal-common";
import { AccountLogHistory, toDisplayDateTimeString } from "@davo/types";
import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAccountHistory } from "./services";

const dateFormat = (data: any) => <>{toDisplayDateTimeString(data.value)}</>;
const useLocationForDescription = [
    "tax-profile-associated",
    "tax-profile-unassociated",
    "bank-account-associated",
    "bank-account-unassociated",
];

export function HistoryTable({ accountId }: { accountId?: string }) {
    const [logEvents, setLogEvents] = useState<AccountLogHistory[]>();

    const refresh = useCallback(() => {
        if (!accountId) {
            return;
        }
        setLogEvents(undefined);
        getAccountHistory(accountId)
            .then((results) => setLogEvents(results))
            .catch((e) => d30ToastError("Problem getting account history.", e));
    }, [accountId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        const renderDescription = (data: any) => {
            return (
                <Typography
                    style={{
                        maxWidth: "270px",
                        textOverflow: "ellipsis",
                        whiteSpace: "break-spaces",
                        overflow: "hidden",
                    }}
                    variant="body1">
                    {!data.cell.row.original.description &&
                    !!data.cell.row.original.locationId &&
                    useLocationForDescription.includes(data.cell.row.original.event)
                        ? `location: ${data.cell.row.original.locationId}`
                        : data.cell.row.original.description}
                </Typography>
            );
        };

        const renderLabel = (data: any) => {
            return (
                <Typography
                    style={{
                        maxWidth: "270px",
                        textOverflow: "ellipsis",
                        whiteSpace: "break-spaces",
                        overflow: "hidden",
                    }}
                    variant="body1">
                    {data.value}
                </Typography>
            );
        };

        return [
            {
                Header: "Date",
                accessor: "timestamp",
                Cell: dateFormat,
            },
            {
                Header: "Updated By",
                accessor: (data: any) => <>{data.updatedBy}</>,
            },
            {
                Header: "Updated",
                accessor: "label",
                Cell: renderLabel,
            },
            { Header: "Event", accessor: "event" },
            {
                Header: "Description",
                accessor: "description",
                Cell: renderDescription,
            },
        ];
    }, []);

    if (!accountId || !logEvents) {
        return <Loading />;
    }

    return (
        <div style={{ width: "100%" }}>
            <ReactTable
                columns={columns}
                data={logEvents}
                options={{
                    pageSize: 10,
                }}
                refreshData={refresh}
            />
        </div>
    );
}
