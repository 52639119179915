import {
    BusinessDayPicker,
    getLocation,
    getTaxProfilesForLocation,
    PaperComponent,
    ReactTable,
    useModalEditor,
} from "@davo/portal-common";
import {
    BusinessDay,
    FilingFrequency,
    initialCap,
    LedgerReportLine,
    LocationRecord,
    money,
    moneyFromCents,
    OutflowWithFirstName,
    PeriodReport,
    TaxProfile,
    toDisplayDateString,
    toDollars,
} from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { ChangePeriodFundsButton } from "./ChangePeriodFundsButton";
import { FieldList } from "./FieldList";
import { FilingFrequencySelector } from "./FilingFrequencySelector";
import { ManualSetAsideButton } from "./ManualSetAsideButton";
import { OutflowButton } from "./OutflowButton";
import {
    getLocationAccounting,
    getLocationAccountingForFrequency,
    getLocationAnnualBalancesForThisYearAndLast,
    getOutflowsForPeriod,
} from "./services";

const dateFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>}</>;
};

export interface ILocationAccountingType {
    locationId: string;
    accountId: string;
    initialPeriodDay?: BusinessDay;
}

export function LocationAccounting({ locationId, accountId, initialPeriodDay }: ILocationAccountingType) {
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [periodDay, setPeriodDay] = useState<BusinessDay>(initialPeriodDay ?? BusinessDay.today());
    const [update, setUpdate] = useState<number>(1); // hack to force updates
    const [outflows, setOutflows] = useState<OutflowWithFirstName[]>();
    const [frequency, setFrequency] = useState<FilingFrequency>();
    const [frequencyOptions, setFrequencyOptions] = useState<FilingFrequency[]>(["month"]);
    const [location, setLocation] = useState<LocationRecord>();
    const [taxProfiles, setTaxProfiles] = useState<TaxProfile[]>();
    const [locationPeriodAccounting, setLocationPeriodAccounting] = useState<PeriodReport>();
    const [annualBalance, setAnnualBalance] = useState<{
        amountInPenniesThisYear: number;
        amountInPenniesLastYear: number;
        periodThisYear: { start: BusinessDay; end: BusinessDay };
        periodLastYear: { start: BusinessDay; end: BusinessDay };
        processed: boolean;
    }>({
        amountInPenniesThisYear: 0,
        amountInPenniesLastYear: 0,
        periodThisYear: { start: BusinessDay.today(), end: BusinessDay.today() },
        periodLastYear: { start: BusinessDay.today(), end: BusinessDay.today() },
        processed: false,
    });

    useAsyncEffect(async () => {
        if (!location || !taxProfiles) {
            return;
        }
        const fSet = new Set<FilingFrequency>(taxProfiles.map((x: TaxProfile) => x.frequency));
        setFrequencyOptions(Array.from(fSet));
        let periodAccounting: PeriodReport;
        if (!frequency || frequencyOptions.length < 2) {
            periodAccounting = await getLocationAccounting(location.id, periodDay);
        } else {
            periodAccounting = await getLocationAccountingForFrequency(location.id, frequency, periodDay);
        }
        setOutflows(
            await getOutflowsForPeriod(location.id, periodAccounting.period.start, periodAccounting.period.end)
        );
        setLocationPeriodAccounting(periodAccounting);
        const annualAccounting = await getLocationAnnualBalancesForThisYearAndLast(location.id);
        setAnnualBalance(annualAccounting);
    }, [location, taxProfiles, frequency, periodDay, update]);

    useAsyncEffect(async () => {
        if (!isDialogOpen) {
            return;
        }
        const locPromise = getLocation(accountId, locationId);
        const tpsPromise = getTaxProfilesForLocation(accountId, locationId);

        const [loc, tpList] = await Promise.all([locPromise, tpsPromise]);

        setLocation(loc);
        setTaxProfiles(tpList);
    }, [isDialogOpen]);

    const updateFrequency = (filingFrequency: FilingFrequency) => {
        setFrequency(filingFrequency);
        setUpdate(() => update + 1);
    };

    const accountingColumns = useMemo(() => {
        return [
            {
                Header: "Type",
                id: "type",
                accessor: "account",
                Cell: (data: any) => <>{initialCap(data.value.substring(0, data.value.indexOf(":")))}</>,
                disableSortBy: true,
            },
            {
                Header: "Account",
                id: "account",
                accessor: "account",
                Cell: (data: any) => data.value.substring(+data.value.indexOf(":") + 1),
                disableSortBy: true,
            },
            {
                Header: "Balance",
                accessor: "balance",
                Cell: (data: any) => (
                    <>{money(((data.cell.row.original.account.startsWith("asset") ? -1 : 1) * data.value) / 100)}</>
                ),
                disableSortBy: true,
            },
            {
                Header: "",
                id: "actions",
                accessor: "account",
                Cell: (data: any) => {
                    if (!location || !locationPeriodAccounting) {
                        return null;
                    }
                    if (data.cell.row.original.account === "asset:davo-balance") {
                        return (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexFlow: "row nowrap",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                    <ManualSetAsideButton
                                        periodDay={periodDay}
                                        isReturn={true}
                                        balance={-data.cell.row.original.balance}
                                        accountId={accountId}
                                        locationId={location.id}
                                        refresh={() => setUpdate(() => update + 1)}
                                        period={
                                            frequency
                                                ? {
                                                      start: locationPeriodAccounting.period.start,
                                                      end: locationPeriodAccounting.period.end,
                                                  }
                                                : undefined
                                        }
                                    />
                                    {periodDay > BusinessDay.today() && (
                                        <Tooltip
                                            title="Because a future date is selected, manual set aside operations are disabled."
                                            placement="right">
                                            <HelpTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle", marginLeft: "10px" }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            </>
                        );
                    } else if (data.cell.row.original.account === "asset:merchant-balance") {
                        let bal = -data.cell.row.original.balance;
                        for (const r of locationPeriodAccounting.report) {
                            if (r.account === "liability:owed-to-state") {
                                if (r.balance < 0) {
                                    bal -= r.balance;
                                }
                                break;
                            }
                        }
                        return (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexFlow: "row nowrap",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                    <ManualSetAsideButton
                                        periodDay={periodDay}
                                        isReturn={false}
                                        balance={bal}
                                        accountId={accountId}
                                        locationId={location.id}
                                        refresh={() => setUpdate(() => update + 1)}
                                        period={
                                            frequency
                                                ? {
                                                      start: locationPeriodAccounting.period.start,
                                                      end: locationPeriodAccounting.period.end,
                                                  }
                                                : undefined
                                        }
                                    />
                                    {periodDay > BusinessDay.today() && (
                                        <Tooltip
                                            title="Because a future date is selected, manual set aside operations are disabled."
                                            placement="right">
                                            <HelpTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle", marginLeft: "10px" }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            </>
                        );
                    } else if (data.cell.row.original.account === "asset:returned-to-merchant") {
                        // asset account, flip sign
                        const returnedAmount = -data.cell.row.original.balance;
                        let merchantBalanceAmount = 0;
                        let balanceBoth = returnedAmount;
                        for (const r of locationPeriodAccounting.report) {
                            if (r.account === "asset:merchant-balance") {
                                merchantBalanceAmount = -r.balance;
                                break;
                            }
                        }
                        balanceBoth += merchantBalanceAmount;
                        return (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexFlow: "row nowrap",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                    <ManualSetAsideButton
                                        periodDay={periodDay}
                                        isReturn={false}
                                        balance={balanceBoth}
                                        accountId={accountId}
                                        locationId={location.id}
                                        refresh={() => setUpdate(() => update + 1)}
                                        returnedToMerchantBalance={returnedAmount}
                                        period={
                                            frequency
                                                ? {
                                                      start: locationPeriodAccounting.period.start,
                                                      end: locationPeriodAccounting.period.end,
                                                  }
                                                : undefined
                                        }
                                    />
                                    {periodDay > BusinessDay.today() && (
                                        <Tooltip
                                            title="Because a future date is selected, manual set aside operations are disabled."
                                            placement="right">
                                            <HelpTwoToneIcon
                                                fontSize="small"
                                                color="primary"
                                                style={{ verticalAlign: "middle", marginLeft: "10px" }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            </>
                        );
                    } else {
                        return null;
                    }
                },
                disableSortBy: true,
            },
        ];
    }, [accountId, frequency, location, locationPeriodAccounting, periodDay, update]);

    const outflowColumns = useMemo(() => {
        return [
            {
                Header: "Type",
                id: "type",
                accessor: "type",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Amount",
                id: "amount",
                accessor: "amount",
                Cell: (data: any) => <>{toDollars(data.value)}</>,
            },
            {
                Header: "Created By",
                id: "createdBy",
                accessor: "firstName",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Created",
                id: "created",
                accessor: "created",
                Cell: dateFormat,
            },
            {
                Header: "Note",
                id: "description",
                accessor: "description",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Check#",
                id: "checkNumber",
                accessor: "checkNumber",
                Cell: (data: any) => <>{data.value}</>,
            },
        ];
    }, []);

    return (
        <>
            <Button
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="primary"
                style={{ marginTop: "2px", marginBottom: "2px" }}>
                Accounting
            </Button>
            {isDialogOpen && location && locationPeriodAccounting && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"md"}
                    fullWidth={true}
                    open={true}
                    onClose={closeDialog}>
                    <DialogTitle id="draggable-dialog-title">Location Accounting: {location.name}</DialogTitle>
                    <DialogContent>
                        {taxProfiles && taxProfiles.length > 0 && (
                            <FilingFrequencySelector
                                defaultFrequency={locationPeriodAccounting.frequency}
                                taxProfiles={taxProfiles}
                                onSelect={updateFrequency}
                            />
                        )}
                        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                            <BusinessDayPicker
                                label={"Period Day"}
                                onChange={(x: BusinessDay) => setPeriodDay(x)}
                                value={periodDay}
                                hasNoMargin={true}
                                isFullWidth={true}
                            />
                        </div>
                        <Typography style={{ marginTop: "20px" }} variant={"h5"}>
                            Period
                        </Typography>
                        <FieldList
                            object={{
                                due: locationPeriodAccounting.period.due.toString(),
                                start: locationPeriodAccounting.period.start.toString(),
                                end: locationPeriodAccounting.period.end.toString(),
                                prepayment: locationPeriodAccounting.period.prepayment.toString(),
                            }}
                        />
                        <Typography style={{ marginTop: "20px" }} variant={"h5"}>
                            Accounting
                        </Typography>
                        <ReactTable
                            columns={accountingColumns}
                            data={locationPeriodAccounting.report}
                            options={{
                                hideToolbar: true,
                                pageSize: 15,
                            }}
                        />
                        {outflows && outflows.length > 0 && (
                            <>
                                <Typography style={{ marginTop: "20px" }} variant={"h5"}>
                                    Write-Off History
                                </Typography>

                                <ReactTable
                                    columns={outflowColumns}
                                    data={outflows}
                                    options={{
                                        pageSize: outflows.length,
                                        hideToolbar: true,
                                    }}
                                />
                            </>
                        )}
                        <br />
                        {annualBalance.processed && (
                            <>
                                <Typography>{`Location annual balance this year`}</Typography>
                                <Typography>{`${annualBalance.periodThisYear.start} to ${
                                    annualBalance.periodThisYear.end
                                }: $${moneyFromCents(annualBalance.amountInPenniesThisYear)}`}</Typography>
                                <Typography>{`Location annual balance last year`}</Typography>
                                <Typography>{`${annualBalance.periodLastYear.start} to ${
                                    annualBalance.periodLastYear.end
                                }: $${moneyFromCents(annualBalance.amountInPenniesLastYear)}`}</Typography>
                            </>
                        )}
                        {!annualBalance.processed && (
                            <>
                                <Typography>{`Location annual balances could not be loaded because this location does not have a point in the year when accounting goes to zero.`}</Typography>
                            </>
                        )}
                        <br />
                        {locationPeriodAccounting && (
                            <div style={{ marginTop: "20px", display: "flex" }}>
                                <div style={{ margin: "5px 5px 5px 0px" }}>
                                    <ChangePeriodFundsButton
                                        davoBalance={
                                            locationPeriodAccounting
                                                ? (locationPeriodAccounting.report.filter(
                                                      (a: LedgerReportLine) => a.account === "asset:davo-balance"
                                                  )[0]?.balance ?? 0)
                                                : 0
                                        }
                                        merchantBalance={
                                            locationPeriodAccounting
                                                ? (locationPeriodAccounting.report.filter(
                                                      (a: LedgerReportLine) => a.account === "asset:merchant-balance"
                                                  )[0]?.balance ?? 0)
                                                : 0
                                        }
                                        originDay={periodDay}
                                        locationId={location.id}
                                        refresh={() => setUpdate(() => update + 1)}
                                    />
                                </div>
                                <div style={{ display: "inline-flex", margin: "5px" }}>
                                    <OutflowButton
                                        isDisabled={false}
                                        davoBalance={
                                            locationPeriodAccounting
                                                ? (locationPeriodAccounting.report.filter(
                                                      (a: LedgerReportLine) => a.account === "asset:davo-balance"
                                                  )[0]?.balance ?? 0)
                                                : 0
                                        }
                                        periodDay={periodDay}
                                        locationId={location.id}
                                        refresh={() => setUpdate(() => update + 1)}
                                    />
                                </div>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={closeDialog}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
