import { PaperComponent } from "@davo/portal-common";
import { ItemizedDailyDetail, moneyFromCents, toDisplayDateTimeString } from "@davo/types";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import orderBy from "lodash/orderBy";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import useAsyncEffect from "use-async-effect";
import { ItemizedDailyActivityTable } from "./ItemizedDailyActivityTable";
import { getItemizedDailyDetail } from "./services";

export interface IItemizedDailyActivityOptionType {
    identifier: string;
    bizday: string;
    accountId?: string | null;
    showSalesBreakout: boolean;
    setShowSalesBreakout: (show: boolean) => void;
}

export function ItemizedDailyActivity({
    identifier,
    bizday,
    accountId,
    showSalesBreakout,
    setShowSalesBreakout,
}: IItemizedDailyActivityOptionType) {
    // getItemizedDailyDetail can take either locationId or filingId as identifier
    const [dataStatus, setDataStatus] = useState<"idle" | "requesting" | "requested">("idle");
    const [itemizedBreakout, setItemizedBreakout] = useState<ItemizedDailyDetail[]>([]);

    useAsyncEffect(async () => {
        if (showSalesBreakout) {
            setDataStatus("requesting");
            if (identifier && bizday) {
                const bd = await getItemizedDailyDetail(accountId ?? identifier, bizday, identifier);
                if (bd && bd.length > 0) {
                    setItemizedBreakout(bd);
                }
            } else {
                throw new Error("Identifier (filing or location id) and bizday are required.");
            }
            setDataStatus("requested");
        }
    }, [showSalesBreakout, bizday, accountId, identifier]);

    function getDetailsForExport() {
        const results: any = [];
        for (const d of itemizedBreakout) {
            results.push({
                Type: d.activityType,
                "Order ID": d.posActivityGroupId,
                "Payment ID": d.posActivityId,
                "Total Sales": moneyFromCents(d.totalSales),
                "Taxable Sales": moneyFromCents(d.taxableSales),
                Tax: moneyFromCents(d.tax),
                "Tax Name": d.taxName,
                "Tax Rate": d.taxRate ? d.taxRate / 1e5 : "0.00",
                "Facilitator Remitted": d.facilitatorRemitted ? "Y" : "N",
                "POS Timestamp": toDisplayDateTimeString(d.posTimestamp),
                Normalized: d.normalized,
            });
        }
        return orderBy(results, ["Date", "Location"]);
    }

    const renderMessage = () => {
        if (dataStatus === "requesting") {
            return <>Getting sales data</>;
        } else if (dataStatus === "requested") {
            return <>No sales for this day</>;
        } else {
            return "";
        }
    };

    const closeDialog = () => {
        setDataStatus("idle");
        setShowSalesBreakout(false);
    };

    const getExportFileName = () => {
        const name = window.location.pathname.replace("/", "_");
        return `itemizedSales_${bizday}_${name}.csv`;
    };

    return (
        <>
            {showSalesBreakout && (
                <Dialog
                    aria-labelledby="draggable-dialog-title"
                    PaperComponent={PaperComponent}
                    open={true}
                    fullWidth={itemizedBreakout && itemizedBreakout.length > 0}
                    maxWidth={"xl"}
                    onClose={closeDialog}>
                    <DialogTitle id="draggable-dialog-title">
                        <>
                            <Typography style={{ float: "right", cursor: "pointer" }}>
                                <CSVLink
                                    filename={getExportFileName()}
                                    data={getDetailsForExport()}
                                    style={{ textDecoration: "unset", color: "unset" }}>
                                    <CloudDownloadIcon color="primary" />
                                </CSVLink>
                            </Typography>
                            ITEMIZED SALES BREAKOUT: {bizday}
                        </>
                    </DialogTitle>
                    <DialogContent>
                        {itemizedBreakout && itemizedBreakout.length > 0 ? (
                            <ItemizedDailyActivityTable itemizedBreakout={itemizedBreakout} />
                        ) : (
                            renderMessage()
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => setShowSalesBreakout(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
