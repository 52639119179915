import { d30ToastError, getLocationLabel, getLocationsForTaxProfile, ReactTable, Select } from "@davo/portal-common";
import { ITTRSRateInfo, LocationRecord, ValidationError } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import React, { useEffect, useMemo, useState } from "react";
import { lookupRatesForLocation } from "./services";

export interface ITaxProfileTaxRateLookupProps {
    accountId: string;
    taxProfileId: string;
}

export const TaxProfileTaxRateLookup = ({ accountId, taxProfileId }: ITaxProfileTaxRateLookupProps) => {
    const [locations, setLocations] = useState<LocationRecord[]>();
    const [selectedLocation, setSelectedLocation] = useState<LocationRecord>();
    const [ttrsRateInfo, setTTRSRateInfo] = useState<ITTRSRateInfo>();
    const [showTTRSErrorMessage, setShowTTRSErrorMessage] = useState<boolean>(false);
    const [canExpandUse, setCanExpandUse] = useState<boolean>(true);

    useEffect(() => {
        if (!accountId || !taxProfileId) {
            return;
        }

        getLocationsForTaxProfile(accountId, taxProfileId)
            .then((locs) => {
                setLocations(locs);
                if (locs.length === 1) {
                    setSelectedLocation(locs[0]);
                }
            })
            .catch((e) => d30ToastError("Problem loading locations", e));
    }, [accountId, taxProfileId]);

    useEffect(() => {
        setShowTTRSErrorMessage(false);
        setTTRSRateInfo(undefined);

        if (selectedLocation) {
            lookupRatesForLocation(selectedLocation.id)
                .then((info) => {
                    const updatedInfo: ITTRSRateInfo = {
                        totalRate: info.totalRate,
                        rates: sortBy(info.rates, ["jurisdictionType", "rate"]),
                    };
                    setTTRSRateInfo(updatedInfo);
                })
                .catch((e) => {
                    if (e instanceof ValidationError) {
                        setShowTTRSErrorMessage(true);
                    } else {
                        d30ToastError("Problem looking up rates", e);
                    }
                });
        }
    }, [selectedLocation]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Name",
                accessor: "name",
                Cell: (data: any) => <div>{data.value}</div>,
            },
            {
                Header: "Type",
                accessor: "jurisdictionType",
                Cell: (data: any) => <div>{data.value}</div>,
            },
            {
                Header: "Rate",
                accessor: "rate",
                Cell: (data: any) => <div>{(data.value * 100).toFixed(3)}%</div>,
            },
        ];
    }, []);

    if (!locations || isEmpty(locations)) {
        return null;
    }

    return (
        <div>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "non:xe", width: "100%", marginTop: "5px" }}
                expanded={canExpandUse}>
                <AccordionSummary
                    onClick={() => setCanExpandUse(!canExpandUse)}
                    expandIcon={<ExpandMoreIcon />}
                    style={{ fontSize: "18px" }}>
                    Lookup Rates
                </AccordionSummary>
                <AccordionDetails>
                    {locations && locations.length > 1 && (
                        <div style={{ width: "100%", marginBottom: "15px" }}>
                            <Select<LocationRecord>
                                data-testid={"taxRateLookupLocationSelector"}
                                noneLabel={`Select a location...`}
                                value={selectedLocation}
                                onChange={setSelectedLocation}
                                options={locations}
                                label={(l) => getLocationLabel(l)}
                            />
                        </div>
                    )}
                    {selectedLocation && (
                        <div>
                            {showTTRSErrorMessage && (
                                <>
                                    <Typography
                                        data-testid={"taxRateLookupErrorMessageHeader"}
                                        style={{
                                            fontWeight: "bold",
                                            color: "#FF0000",
                                        }}>
                                        {
                                            "Unable to lookup rates for the provided address. Please verify the location's address."
                                        }
                                    </Typography>
                                    <br />
                                </>
                            )}
                            {ttrsRateInfo && (
                                <>
                                    <Typography
                                        data-testid={"taxRateLookupTotalOfRatesHeader"}
                                        style={{
                                            fontWeight: "bold",
                                            color: "#6DD230",
                                        }}>{`Total of rates: ${(ttrsRateInfo.totalRate * 100).toFixed(3)}%`}</Typography>
                                    <br />
                                    <ReactTable
                                        columns={columns}
                                        data={ttrsRateInfo.rates}
                                        options={{
                                            hideToolbar: true,
                                            pageSize: 25,
                                            pageSizeOptions: [25],
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
