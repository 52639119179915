import {
    d30ToastError,
    getAdditionalSalesManualActivityWithSetAsideStatus,
    getJurisdictionByStateCode,
    getJurisdictionFilingTypes,
    getPartner,
    getUseTaxAmounts,
    Loading,
    useAsyncRefresh,
    useLoginContext,
} from "@davo/portal-common";
import {
    DailyDetail,
    FilingDetails,
    FilingSummary,
    FilingTaxProfileTaxRate,
    IFilteredBreakoutType,
    IJurisdiction,
    IJurisdictionFilingType,
    IManualActivity,
    initialCap,
    IUseTaxAndUser,
    Location,
    LocationRecord,
    Partner,
    TaxProfileTaxRate,
} from "@davo/types";
import React, { createContext, Dispatch, FunctionComponent, PropsWithChildren, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { getMaxAvailable, IFundingStatusType } from "../filing/common";
import {
    getFEINDuplicates,
    getFiling,
    getFilingCalculation,
    getFilingTaxProfileHasOtherFilingsRequiringAmendment,
    getFilingTaxProfileTaxRates,
    getFirstFilingInfo,
    getLocationsForFiling,
    getNestedRemitted,
    getPayorInfo,
    getStatesWithActiveGroupLogins,
    getStateTaxIdDuplicates,
    getTaxProfilePriorOpenFilings,
    getTotalPrepayment,
} from "../services";
import { hasPermission } from "../util";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFilingDetailPaneContext {
    filingId: string;
    filing: FilingDetails;
    locationsIncludedInFiling: Location[];
    taxProfileTaxRates: TaxProfileTaxRate[];
    fundingStatus: IFundingStatusType;
    hasPendingFunds: boolean;
    maxAvailable: number;
    nonPrepaymentRemittances: number;
    precludedTaxRates: TaxProfileTaxRate[];
    dailyDetails: DailyDetail[];
    grossAmounts: ITotalAmounts;
    taxableAmounts: ITotalAmounts;
    nonTaxableAmounts: ITotalAmounts;
    tenderCash: number;
    tenderCredit: number;
    posTypes: string[];
    taxFromBreakout: number;
    doesTaxProfileHavePriorOpenFilings: boolean;
    payor: { accountNumber: string; routing: string };
    partner: Partner | undefined;
    FEINDuplicates: FilingSummary[];
    stateTaxIdDuplicates: FilingSummary[];
    taxProfileOtherFilingsRequiringAmendment: FilingSummary[];
    isFirstMonthMerchantPay: boolean;
    additionalSales: (IManualActivity & { manualSetAsideStatus: string | undefined })[];
    useTax: IUseTaxAndUser[];
    partnerGroupPolicyStates: string[];
    internalGroupPolicyStates: string[];
    prepaymentsMade: number | undefined;
    firstFiling: { first: boolean; prepayment?: boolean };

    filingTypes: IJurisdictionFilingType[];
    jurisdictionConfig: IJurisdictionFilingType | undefined;
    filingTypeConfig: IJurisdictionFilingType | undefined;
    hasCanFilePermission: boolean;
    hasCanReturnLeftoverFundsPermission: boolean;

    taxType: "distinct" | "overlapping";
    setTaxType: Dispatch<"distinct" | "overlapping">;

    taxBreakoutLocationSelected: LocationRecord | undefined;
    setTaxBreakoutLocationSelected: Dispatch<LocationRecord | undefined>;
    filteredBreakout: IFilteredBreakoutType;
    setFilteredBreakout: Dispatch<IFilteredBreakoutType>;

    isEditingFiling: boolean;
    setIsEditingFiling: Dispatch<boolean>;
    isAmendingFiling: boolean;
    setIsAmendingFiling: Dispatch<boolean>;
    isReopening: boolean;
    setIsReopening: Dispatch<boolean>;

    refresh: () => void;
}

export const FilingDetailPaneContext = createContext({} as IFilingDetailPaneContext);
export const useFilingDetailPaneContext = () => useContext(FilingDetailPaneContext);

export interface IAFilingDetailPaneContextProviderProps {
    filingId: string;
}

interface ITotalAmounts {
    sales: number;
    returns: number;
    net: number;
}

const getZeroTotalAmounts = (): ITotalAmounts => {
    return { sales: 0, returns: 0, net: 0 };
};

export const FilingDetailPaneContextProvider: FunctionComponent<
    PropsWithChildren<IAFilingDetailPaneContextProviderProps>
> = ({ children, filingId: fId }) => {
    const navigate = useNavigate();

    const loginContext = useLoginContext();

    const [filingId] = useState<string>(fId);
    const [filing, setFiling] = useState<FilingDetails>();
    const [locationsIncludedInFiling, setLocationsIncludedInFiling] = useState<Location[]>();
    const [taxType, setTaxType] = useState<"distinct" | "overlapping">("overlapping");
    const [taxProfileTaxRates, setTaxProfileTaxRates] = useState<TaxProfileTaxRate[]>(); // all rates while the filing is open, freeze dried included rates after filing close
    const [fundingStatus, setFundingStatus] = useState<IFundingStatusType>();
    const [hasPendingFunds, setHasPendingFunds] = useState<boolean>(false);
    const [maxAvailable, setMaxAvailable] = useState<number>(0);
    const [nonPrepaymentRemittances, setNonPrepaymentRemittances] = useState<number>(0);
    const [precludedTaxRates, setPrecludedTaxRates] = useState<TaxProfileTaxRate[]>();
    const [dailyDetails, setDailyDetails] = useState<DailyDetail[]>([]);
    const [grossAmounts, setGrossAmounts] = useState<ITotalAmounts>(getZeroTotalAmounts());
    const [taxableAmounts, setTaxableAmounts] = useState<ITotalAmounts>(getZeroTotalAmounts());
    const [nonTaxableAmounts, setNonTaxableAmounts] = useState<ITotalAmounts>(getZeroTotalAmounts());
    const [tenderCash, setTenderCash] = useState<number>(0);
    const [tenderCredit, setTenderCredit] = useState<number>(0);
    const [posTypes, setPosTypes] = useState<string[]>([]);
    const [taxFromBreakout, setTaxFromBreakout] = useState<number>(0);
    const [doesTaxProfileHavePriorOpenFilings, setDoesTaxProfileHavePriorOpenFilings] = useState<boolean>(false);
    const [payor, setPayor] = useState<{ accountNumber: string; routing: string }>({
        accountNumber: "xxxxx",
        routing: "xxxxx",
    });
    const [partner, setPartner] = useState<Partner | undefined>(undefined);
    const [FEINDuplicates, setFEINDuplicates] = useState<FilingSummary[]>([]);
    const [stateTaxIdDuplicates, setStateTaxIdDuplicates] = useState<FilingSummary[]>([]);
    const [taxProfileOtherFilingsRequiringAmendment, setTaxProfileOtherFilingsRequiringAmendment] = useState<
        FilingSummary[]
    >([]);
    const [isFirstMonthMerchantPay, setIsFirstMonthMerchantPay] = useState<boolean>(false);
    const [additionalSales, setAdditionalSales] =
        useState<(IManualActivity & { manualSetAsideStatus: string | undefined })[]>();
    const [useTax, setUseTax] = useState<IUseTaxAndUser[]>();
    const [partnerGroupPolicyStates, setPartnerGroupPolicyStates] = useState<string[]>();
    const [internalGroupPolicyStates, setInternalGroupPolicyStates] = useState<string[]>();
    const [prepaymentsMade, setPrepaymentsMade] = useState<number | undefined>();
    const [firstFiling, setFirstFiling] = useState<{ first: boolean; prepayment?: boolean }>();

    const [filingTypes, setFilingTypes] = useState<IJurisdictionFilingType[]>();
    const [jurisdictionConfig, setJurisdictionConfig] = useState<IJurisdictionFilingType>();
    const [filingTypeConfig, setFilingTypeConfig] = useState<IJurisdictionFilingType>();
    const [hasCanFilePermission, setHasCanFilePermission] = useState<boolean>(false);
    const [hasCanReturnLeftoverFundsPermission, setHasCanReturnLeftoverFundsPermission] = useState<boolean>(false);

    const [taxBreakoutLocationSelected, setTaxBreakoutLocationSelected] = useState<LocationRecord>();
    const [filteredBreakout, setFilteredBreakout] = useState<IFilteredBreakoutType>({});

    const [isEditingFiling, setIsEditingFiling] = useState<boolean>(false);
    const [isAmendingFiling, setIsAmendingFiling] = useState<boolean>(false);
    const [isReopening, setIsReopening] = useState<boolean>(false);

    const [refresh] = useAsyncRefresh(async () => {
        try {
            // start these asap, but don't wait
            const filingCalcPromise = getFilingCalculation(filingId, isAmendingFiling || isEditingFiling);

            const [
                taxFiling,
                payorInfo,
                hasPriorOpenFilings,
                locationsForFiling,
                taxRates,
                nestedRemitted,
                stateTaxIdDupes,
                feinDupes,
                taxProfileOtherFilingsRequiringAmendmentRecords,
                totalPrepayment,
                firstFilingInfo,
            ] = await Promise.all([
                getFiling(filingId),
                getPayorInfo(),
                getTaxProfilePriorOpenFilings(filingId),
                getLocationsForFiling(filingId),
                getFilingTaxProfileTaxRates(filingId), // rates as they were at filing close
                getNestedRemitted(filingId),
                getFEINDuplicates(filingId),
                getStateTaxIdDuplicates(filingId),
                getFilingTaxProfileHasOtherFilingsRequiringAmendment(filingId),
                getTotalPrepayment(filingId),
                getFirstFilingInfo(filingId),
            ]);
            // start this call asap
            const partnerPromise = taxFiling.taxProfile.account.partnerId
                ? getPartner(taxFiling.taxProfile.account.partnerId)
                : undefined;
            setFiling(taxFiling);
            setTaxType(taxFiling.taxProfile.taxRateType as "overlapping" | "distinct");
            setPayor(payorInfo);
            setDoesTaxProfileHavePriorOpenFilings(hasPriorOpenFilings);
            setTaxProfileOtherFilingsRequiringAmendment(taxProfileOtherFilingsRequiringAmendmentRecords);
            setFEINDuplicates(feinDupes);
            setStateTaxIdDuplicates(stateTaxIdDupes);
            setPrepaymentsMade(totalPrepayment);
            setFirstFiling(firstFilingInfo);
            const allPosTypes = [
                ...locationsForFiling
                    .filter((l) => l.posType !== "integrator")
                    .map((l) => initialCap(l.posType.replace("-", " "))),
                ...locationsForFiling
                    .filter((l) => !!l.externalPosType)
                    .map((l) => initialCap(l.externalPosType!.replace("-", " "))),
            ];
            setPosTypes(Array.from(new Set(allPosTypes)));
            setLocationsIncludedInFiling(locationsForFiling);
            const locationIds: string[] = locationsForFiling?.filter((loc) => loc.id).map(({ id }) => id);

            setIsFirstMonthMerchantPay(
                locationsForFiling.some(
                    (x) =>
                        x.firstMonthMerchantPay &&
                        x.created &&
                        x.created.startOf("month") === taxFiling.periodEnd.toDateTime().startOf("month")
                )
            );

            const getAdditionalSalesPromise =
                taxFiling && locationIds.length > 0
                    ? getAdditionalSalesManualActivityWithSetAsideStatus(
                          locationIds,
                          taxFiling.periodStart,
                          taxFiling.periodEnd
                      )
                    : undefined;
            const getUseTaxPromise =
                taxFiling && locationIds.length > 0
                    ? getUseTaxAmounts(locationIds, taxFiling.periodStart, taxFiling.periodEnd)
                    : undefined;

            const [filingCalc, use, additional] = await Promise.all([
                filingCalcPromise,
                getUseTaxPromise,
                getAdditionalSalesPromise,
            ]);

            setAdditionalSales(additional ?? []);
            setUseTax(use ?? []);

            const theFundingStatus = {
                tax: filingCalc.summary.tax,
                remitted: filingCalc.summary.remitted,
                status: filingCalc.summary.status,
            };
            setFundingStatus(theFundingStatus);
            setHasPendingFunds((fundingStatus && fundingStatus.status.pendingIn > 0) ?? false);
            setMaxAvailable(getMaxAvailable(theFundingStatus));

            if (taxFiling.status === "open" || isEditingFiling || isAmendingFiling) {
                setTaxProfileTaxRates(filingCalc.summary.taxProfileTaxRates);
                const precludedRates: TaxProfileTaxRate[] = [];
                for (const r of filingCalc.summary.taxProfileTaxRates) {
                    if (r.included !== "included") {
                        precludedRates.push(r);
                    }
                }
                setPrecludedTaxRates(precludedRates);
            } else {
                const precludedRates: TaxProfileTaxRate[] = [];
                // first push rates that were precluded at the time
                for (const r of taxRates) {
                    if (r.included === "precluded") {
                        precludedRates.push(r);
                    }
                }
                // then push rates that did not exist at the time
                for (const t of filingCalc.summary.taxProfileTaxRates) {
                    if (
                        taxRates.find((x: FilingTaxProfileTaxRate) => {
                            // note custom taxes have the same ID but could have different rates since we aggregate similar rates
                            return (
                                x.taxId === t.taxId &&
                                (x.taxRate === t.taxRate ||
                                    (t.taxId.startsWith("custom-tax:") && x.taxId.startsWith("custom-tax:")))
                            );
                        }) === undefined
                    ) {
                        precludedRates.push(t);
                    }
                }
                setPrecludedTaxRates(precludedRates);
                if (taxRates.length > 0) {
                    setTaxProfileTaxRates(taxRates);
                } else {
                    // to cover legacy filings that don't have rates freeze dried
                    setTaxProfileTaxRates(filingCalc.summary.taxProfileTaxRates);
                }
            }
            setNonTaxableAmounts({
                sales: filingCalc.includedNonTaxableSales,
                returns: filingCalc.includedNonTaxableReturns,
                net: filingCalc.includedNonTaxableSales - filingCalc.includedNonTaxableReturns,
            });
            setGrossAmounts({
                sales: filingCalc.includedSales,
                returns: filingCalc.includedReturns,
                net: filingCalc.includedSales - filingCalc.includedReturns,
            });
            setTaxableAmounts({
                sales: filingCalc.includedTaxableSales,
                returns: filingCalc.includedTaxableReturns,
                net: filingCalc.includedTaxableSales - filingCalc.includedTaxableReturns,
            });
            setTaxFromBreakout(Math.round(filingCalc.includedTax));
            setTenderCash(Math.round(filingCalc.allTenderCash));
            setTenderCredit(Math.round(filingCalc.allTenderCredit));
            setDailyDetails(filingCalc.dailyDetails);

            const [part] = await Promise.all([partnerPromise]);
            setNonPrepaymentRemittances(nestedRemitted.reduce((p: number, a: any) => p + +a.nestedTaxRemitted, 0));
            setPartner(part);
            if (part) {
                setPartnerGroupPolicyStates(await getStatesWithActiveGroupLogins(part.id));
            } else {
                setPartnerGroupPolicyStates([]);
            }
            setInternalGroupPolicyStates(await getStatesWithActiveGroupLogins());
        } catch (e: any) {
            // this is a bit of an assumption
            d30ToastError("Filing not found.");
            navigate("/filings");
            return;
        }
    });

    useAsyncEffect(async () => {
        setHasCanFilePermission(hasPermission(loginContext.permissions, "can_file"));
        setHasCanReturnLeftoverFundsPermission(hasPermission(loginContext.permissions, "return_leftover_funds"));
        if (filing) {
            const jurisdiction: IJurisdiction = await getJurisdictionByStateCode(filing.jurisdiction);
            const jurisdictionFilingTypes = await getJurisdictionFilingTypes(jurisdiction.id);
            setFilingTypes(jurisdictionFilingTypes);

            setJurisdictionConfig(jurisdictionFilingTypes.find((x: IJurisdictionFilingType) => x.isDefault));
            if (filing.jurisdictionFilingTypeId) {
                setFilingTypeConfig(
                    jurisdictionFilingTypes.find(
                        (x: IJurisdictionFilingType) => x.id === filing.jurisdictionFilingTypeId
                    )
                );
            }
        }
    }, [filing, loginContext.permissions]);

    if (
        !filing ||
        !locationsIncludedInFiling ||
        !taxProfileTaxRates ||
        !fundingStatus ||
        !precludedTaxRates ||
        !partnerGroupPolicyStates ||
        !internalGroupPolicyStates ||
        !firstFiling ||
        !filingTypes ||
        !additionalSales ||
        !useTax
    ) {
        return <Loading />;
    }

    return (
        <FilingDetailPaneContext.Provider
            value={{
                filingId,
                locationsIncludedInFiling,
                filing,
                taxProfileTaxRates,
                fundingStatus,
                hasPendingFunds,
                maxAvailable,
                nonPrepaymentRemittances,
                precludedTaxRates,
                dailyDetails,
                grossAmounts,
                taxableAmounts,
                nonTaxableAmounts,
                tenderCash,
                tenderCredit,
                posTypes,
                taxFromBreakout,
                doesTaxProfileHavePriorOpenFilings,
                payor,
                partner,
                FEINDuplicates,
                stateTaxIdDuplicates,
                taxProfileOtherFilingsRequiringAmendment,
                isFirstMonthMerchantPay,
                additionalSales,
                useTax,
                partnerGroupPolicyStates,
                internalGroupPolicyStates,
                prepaymentsMade,
                firstFiling,

                filingTypes,
                jurisdictionConfig,
                filingTypeConfig,
                hasCanFilePermission,
                hasCanReturnLeftoverFundsPermission,

                taxType,
                setTaxType,

                taxBreakoutLocationSelected,
                setTaxBreakoutLocationSelected,
                filteredBreakout,
                setFilteredBreakout,

                isEditingFiling,
                setIsEditingFiling,
                isAmendingFiling,
                setIsAmendingFiling,
                isReopening,
                setIsReopening,

                refresh,
            }}>
            {children}
        </FilingDetailPaneContext.Provider>
    );
};
