import {
    d30ToastError,
    getJurisdictionByStateCode,
    getJurisdictionFilingTypes,
    getTaxProfile,
    PaperComponent,
    updateTaxProfileFilingType,
    updateTaxProfileTaxRates,
} from "@davo/portal-common";
import { IJurisdiction, IJurisdictionFilingType, TaxProfile, TaxProfileTaxRate } from "@davo/types";
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { TaxProfileTaxRateVerificationForm } from "./TaxProfileTaxRateVerificationForm";
import { TaxProfileTypeVerificationForm } from "./TaxProfileTypeVerificationForm";
import { setTaxProfileTypeVerificationStatus } from "./services";

export interface ITaxProfileVerificationModalProps {
    accountId: string;
    taxProfileId: string;
    taxProfileTaxRates: TaxProfileTaxRate[] | undefined;
    state: string;
    filingIdForContinue?: string;
    onSave: () => void;
    closeDialog: (target?: string, error?: string) => void;
}

export const TaxProfileVerificationModal = ({
    accountId,
    taxProfileId,
    taxProfileTaxRates,
    state,
    filingIdForContinue,
    onSave,
    closeDialog,
}: ITaxProfileVerificationModalProps) => {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [status, setStatus] = useState<"typeVerification" | "rateVerification">();
    const [taxProfile, setTaxProfile] = useState<TaxProfile>();
    const [filingTypes, setFilingTypes] = useState<IJurisdictionFilingType[]>();
    const [hasUnverifiedRates, setHasUnverifiedRates] = useState<boolean>(false);
    const [isBusyVerifyingRates, setIsBusyVerifyingRates] = useState<boolean>(false);

    const [isUpdatingFilingType, setIsUpdatingFilingType] = useState<boolean>(false);

    const _shutdown = (result?: string) => {
        setStatus(undefined);
        closeDialog(result);
    };

    useEffect(() => {
        setHasUnverifiedRates(
            taxProfileTaxRates ? taxProfileTaxRates.filter((x) => x.included === "unverified").length > 0 : false
        );
    }, [taxProfileTaxRates]);

    useAsyncEffect(async () => {
        if (!accountId || !taxProfileId) {
            return;
        }
        const tp = await getTaxProfile(accountId, taxProfileId);
        setTaxProfile(tp);
    }, [accountId, taxProfileId, status]);

    useAsyncEffect(async () => {
        if (!taxProfile) {
            return;
        }
        const jurisdiction: IJurisdiction = await getJurisdictionByStateCode(taxProfile.state);
        const filingtypes: IJurisdictionFilingType[] = (await getJurisdictionFilingTypes(jurisdiction.id)).filter(
            (ft) => ft.active
        );
        setFilingTypes(filingtypes);
    }, [taxProfile]);

    useEffect(() => {
        if (!taxProfile || !filingTypes) {
            return;
        }

        if (filingTypes.length === 1) {
            setStatus("rateVerification");
        } else if (filingTypes.length > 1) {
            if (isNil(taxProfile.typeVerifiedBy)) {
                setStatus("typeVerification");
            } else {
                setStatus("rateVerification");
            }
        } else if (hasUnverifiedRates) {
            setStatus("rateVerification");
        }
    }, [filingTypes, taxProfile, hasUnverifiedRates]);

    if (!taxProfile || !status) {
        return;
    }

    const _onSubmitTypeVerify = (data?: IJurisdictionFilingType) => {
        if (isUpdatingFilingType) return;
        setIsUpdatingFilingType(true);
        updateTaxProfileFilingType(accountId, taxProfile.id, data?.id)
            .then(async () => {
                // then mark the tax profile verified
                await setTaxProfileTypeVerificationStatus(accountId, taxProfile.id, true);
                if (hasUnverifiedRates) {
                    setStatus("rateVerification");
                } else {
                    onSave();
                    _shutdown(filingIdForContinue);
                }
            })
            .catch((e) => {
                d30ToastError("Error while verifying the filing type", e);
                setIsUpdatingFilingType(false);
            });
    };

    const _onSubmitRatesVerify = (data: TaxProfileTaxRate[]) => {
        if (isBusyVerifyingRates) return;

        setIsBusyVerifyingRates(true);
        updateTaxProfileTaxRates(accountId, data)
            .then(() => {
                onSave();
                _shutdown(filingIdForContinue);
            })
            .catch((e) => {
                d30ToastError("Problem updating tax profile tax rates.", e);
                setIsBusyVerifyingRates(false);
            });
    };

    return (
        <Dialog
            open={true}
            fullScreen={makeFullScreen}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            style={{ boxShadow: "none" }}
            maxWidth={"lg"}
            onClose={(event, reason) => {
                if (!["backdropClick"].includes(reason)) {
                    _shutdown();
                }
            }}>
            <DialogTitle id="draggable-dialog-title" />
            <DialogContent>
                {status === "typeVerification" && (
                    <TaxProfileTypeVerificationForm
                        taxProfile={taxProfile}
                        filingTypes={filingTypes ?? []}
                        onSubmit={_onSubmitTypeVerify}
                        onCancel={() => _shutdown(filingIdForContinue)}
                        isUpdatingFilingType={isUpdatingFilingType}
                    />
                )}
                {status === "rateVerification" && (
                    <TaxProfileTaxRateVerificationForm
                        accountId={accountId}
                        taxProfileId={taxProfileId}
                        state={state}
                        taxProfileTaxRates={taxProfileTaxRates ?? []}
                        filingIdForContinue={filingIdForContinue}
                        onSubmit={_onSubmitRatesVerify}
                        onCancel={() => _shutdown(filingIdForContinue)}
                        isBusyVerifyingRates={isBusyVerifyingRates}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
