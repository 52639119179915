import { d30Toast, d30ToastError, TextField } from "@davo/portal-common";
import { AccountRecord, OptionalString } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { setAccountNote } from "./services";

interface ISetAccountNoteType {
    account: AccountRecord;
    onClose: () => void;
}

export function SetAccountNote({ account, onClose }: ISetAccountNoteType) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [newAccountNote, setNewAccountNote] = useState<OptionalString>();

    useEffect(() => {
        if (!account) {
            return;
        } else {
            setNewAccountNote(account.note);
        }
        setIsBusy(false);
    }, [account]);

    if (!account) {
        return null;
    }

    const cancel = () => {
        setIsEditing(false);

        // reset filing note back to original value
        setNewAccountNote(account.note);
    };

    const save = () => {
        setIsBusy(true);
        setAccountNote(account.id, newAccountNote)
            .then(() => {
                d30Toast("Reoccurring Account Note Change Successful");
            })
            .catch((e) => d30ToastError("Problem saving account note.", e))
            .finally(() => {
                setIsBusy(false);
                setIsEditing(false);
                onClose();
            });
    };

    return (
        <>
            <Button
                disabled={isBusy}
                variant="outlined"
                style={{ margin: "5px" }}
                onClick={() => {
                    setIsEditing(true);
                }}
                size="small"
                color="primary">
                Edit Account Note
            </Button>
            {isEditing && (
                <Dialog
                    open={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    onClose={() => {
                        setIsEditing(false);
                    }}>
                    <DialogTitle>Set Note For Account</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Account Note"
                            value={`${newAccountNote || ""}`}
                            onChange={setNewAccountNote}
                            isMultiline={true}
                            rows={1}
                        />
                        <div style={{ textAlign: "center", fontSize: "10px", marginTop: "-10px" }}>
                            (Previous account notes are saved in Account Log)
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={save}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
