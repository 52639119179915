import { getJurisdictionRateType, getLocationToTaxProfiles, ReactTable8, Select } from "@davo/portal-common";
import {
    IFilteredBreakoutType,
    IJurisdictionRateType,
    LocationRecord,
    LocationToTaxProfile,
    moneyFromCents,
    percent,
    TaxProfileTaxRate,
    TaxRateInfo,
} from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useFilingDetailPaneContext } from "../context/FilingDetailPaneContext";
import { TaxBreakoutLocationDisplay } from "./TaxBreakoutLocationDisplay";

export function PrecludedTaxBreakOut() {
    const {
        filing,
        locationsIncludedInFiling,
        refresh,
        isEditingFiling,
        taxProfileTaxRates,
        dailyDetails,
        precludedTaxRates,
        taxBreakoutLocationSelected,
        setTaxBreakoutLocationSelected,
        setFilteredBreakout,
    } = useFilingDetailPaneContext();

    const [filteredBreakoutForPrecludedRates, setFilteredBreakoutForPrecludedRates] = useState<IFilteredBreakoutType>(
        {}
    );
    const [subTypes, setSubTypes] = useState<IJurisdictionRateType[] | undefined>(undefined);
    const [locationToTaxProfileMapping, setLocationToTaxProfileMapping] = useState<LocationToTaxProfile[]>();
    const [breakoutForPrecludedRates, setBreakoutForPrecludedRates] = useState<IFilteredBreakoutType>();
    const [tableData, setTableData] = useState<TaxRateInfo[]>([]);

    useAsyncEffect(async () => {
        const subJ: IJurisdictionRateType[] = [];
        for (const rate of taxProfileTaxRates) {
            if (rate.jurisdictionRateTypeId) {
                subJ.push(await getJurisdictionRateType(rate.jurisdictionRateTypeId));
            }
        }

        setSubTypes(subJ);
    }, [taxProfileTaxRates]);

    useAsyncEffect(async () => {
        const locationIds = locationsIncludedInFiling.map((l) => l.id);
        setLocationToTaxProfileMapping(
            await getLocationToTaxProfiles(filing.taxProfile.account.id, filing.taxProfile.id, locationIds)
        );
    }, [locationsIncludedInFiling]);

    useEffect(() => {
        const breakoutFiltered = {} as IFilteredBreakoutType;
        for (const detail of dailyDetails) {
            for (const t of detail.taxes) {
                const tptr = precludedTaxRates.find((x: TaxProfileTaxRate) => {
                    // custom tax rates are aggregates so the rates may not be the same
                    return (
                        x.taxId === t.taxId &&
                        (x.taxRate === t.taxRate ||
                            (t.taxId.startsWith("custom-tax:") && x.taxId.startsWith("custom-tax:")))
                    );
                });
                if (tptr !== undefined) {
                    breakoutFiltered[t.taxId] = breakoutFiltered[t.taxId] ?? {
                        id: t.taxId,
                        location: detail.locationId,
                        name: t.taxName,
                        state: t.state,
                        sales: 0,
                        returns: 0,
                        tax: 0,
                        rateTypeId: tptr.jurisdictionRateTypeId,
                        rate: t.taxRate,
                    };
                    breakoutFiltered[t.taxId].sales += t.sales;
                    breakoutFiltered[t.taxId].returns += t.refunds;
                    breakoutFiltered[t.taxId].tax += t.tax;
                }
            }
        }
        setBreakoutForPrecludedRates(breakoutFiltered);
    }, [dailyDetails, precludedTaxRates]);

    useEffect(() => {
        if (!taxBreakoutLocationSelected) {
            setFilteredBreakout({} as IFilteredBreakoutType);
            return;
        }
        const breakoutFiltered = {} as IFilteredBreakoutType;
        for (const detail of dailyDetails) {
            if (detail.locationId === taxBreakoutLocationSelected.id) {
                for (const detailTaxRate of detail.taxes) {
                    const matchingRate = taxProfileTaxRates.find((taxProfileTaxRate: TaxProfileTaxRate) => {
                        // note custom taxes have the same ID but could have different rates since we aggregate similar rates
                        return (
                            taxProfileTaxRate.taxId === detailTaxRate.taxId &&
                            (taxProfileTaxRate.taxRate === detailTaxRate.taxRate ||
                                (detailTaxRate.taxId.startsWith("custom-tax:") &&
                                    taxProfileTaxRate.taxId.startsWith("custom-tax:"))) &&
                            (taxProfileTaxRate.included === "included" || taxProfileTaxRate.included === "unverified")
                        );
                    });
                    if (matchingRate !== undefined) {
                        breakoutFiltered[detailTaxRate.taxId] = breakoutFiltered[detailTaxRate.taxId] ?? {
                            id: detailTaxRate.taxId,
                            location: detail.locationId,
                            name: detailTaxRate.taxName,
                            state: detailTaxRate.state,
                            sales: 0,
                            returns: 0,
                            tax: 0,
                            rateTypeId: matchingRate?.jurisdictionRateTypeId,
                            rate: detailTaxRate.taxRate,
                        };
                        breakoutFiltered[detailTaxRate.taxId].sales += detailTaxRate.sales;
                        breakoutFiltered[detailTaxRate.taxId].returns += detailTaxRate.refunds;
                        breakoutFiltered[detailTaxRate.taxId].tax += detailTaxRate.tax;
                    }
                }
            }
        }
        setFilteredBreakout(breakoutFiltered);
        if (filing) {
            if (!breakoutForPrecludedRates) {
                return;
            }
            setFilteredBreakoutForPrecludedRates(breakoutForPrecludedRates);
        }
    }, [dailyDetails, filing, breakoutForPrecludedRates, taxBreakoutLocationSelected, taxProfileTaxRates]);

    const columns = useMemo(() => {
        const renderJurisdictionTaxRateTypeName = (taxRate: TaxRateInfo) => {
            if (subTypes) {
                const subType = subTypes.find((juris) => juris.id === taxRate.rateTypeId);
                if (subType) {
                    return <>{subType.name}</>;
                }
            }
            return null;
        };
        const columnHelper = createColumnHelper<TaxRateInfo>();

        const cols = [];
        cols.push(
            columnHelper.accessor("name", {
                header: "Tax Name",
            }),
            columnHelper.accessor("rate", {
                header: "Tax Rate",
                cell: (data: any) => <>{percent(data.getValue() / 1e7)}</>,
            })
        );
        if (tableData.some((x: TaxRateInfo) => x.rateTypeId)) {
            cols.push(
                columnHelper.accessor("rateTypeId", {
                    header: "Tax Rate Type",
                    id: "jurisdictionRateType",
                    cell: (data: any) => renderJurisdictionTaxRateTypeName(data.cell.row.original),
                })
            );
        }
        cols.push(
            columnHelper.accessor("sales", {
                header: "Sales",
                cell: (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(data.getValue())}</div>,
                meta: {
                    rightAlign: true,
                },
            }),
            columnHelper.accessor("returns", {
                header: "Returns",
                cell: (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(-data.getValue())}</div>,
                meta: {
                    rightAlign: true,
                },
            }),
            columnHelper.accessor("sales", {
                header: "Net Sales",
                id: "netSales",
                cell: (data: any) => (
                    <div style={{ textAlign: "right" }}>
                        ${moneyFromCents(+data.getValue() + +data.cell.row.original.returns)}
                    </div>
                ),
                meta: {
                    rightAlign: true,
                },
            }),
            columnHelper.accessor("tax", {
                header: "Tax",
                cell: (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(data.getValue())}</div>,
                meta: {
                    rightAlign: true,
                },
            })
        );
        return cols;
    }, [subTypes, tableData]);

    useEffect(() => {
        const f = taxBreakoutLocationSelected ? filteredBreakoutForPrecludedRates : (breakoutForPrecludedRates ?? {});

        setTableData(Object.values(f));
    }, [taxBreakoutLocationSelected, filteredBreakoutForPrecludedRates, breakoutForPrecludedRates]);

    return (
        <>
            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none" }}
                defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Precluded Tax Breakout
                    <Tooltip
                        title={
                            filing.status !== "open" && !isEditingFiling
                                ? `This table shows tax rates that were not included at the time of filing close`
                                : `Rates not included on this filing`
                        }>
                        <div>
                            <InfoIcon
                                fontSize={"small"}
                                color="primary"
                                style={{ verticalAlign: "middle", marginLeft: "10px" }}
                            />
                        </div>
                    </Tooltip>
                </AccordionSummary>

                {locationsIncludedInFiling.length > 1 ? (
                    <div style={{ marginLeft: "25px", width: "96%" }}>
                        <Select<LocationRecord>
                            noneLabel={`All ${locationsIncludedInFiling.length} locations`}
                            value={taxBreakoutLocationSelected}
                            onChange={setTaxBreakoutLocationSelected}
                            options={locationsIncludedInFiling}
                            label={(l) => l.name}
                        />
                    </div>
                ) : (
                    locationsIncludedInFiling.length > 0 &&
                    precludedTaxRates &&
                    precludedTaxRates.length > 0 && (
                        <div style={{ marginTop: "5px", marginLeft: "30px" }}>
                            <Typography>{locationsIncludedInFiling[0].name}</Typography>
                            <br />
                            <TaxBreakoutLocationDisplay
                                location={locationsIncludedInFiling[0]}
                                taxProfileId={filing.taxProfile.id}
                                locationToTaxProfileMapping={locationToTaxProfileMapping}
                            />
                        </div>
                    )
                )}

                {taxBreakoutLocationSelected && (
                    <div style={{ marginTop: "5px", marginLeft: "30px" }}>
                        <TaxBreakoutLocationDisplay
                            location={taxBreakoutLocationSelected}
                            taxProfileId={filing.taxProfile.id}
                            locationToTaxProfileMapping={locationToTaxProfileMapping}
                        />
                    </div>
                )}

                <AccordionDetails style={{ display: "flex", marginTop: "10px" }}>
                    <ReactTable8<TaxRateInfo>
                        columns={columns}
                        data={tableData}
                        refreshData={refresh}
                        options={{
                            pageSize: 100,
                            hideToolbar: true,
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}
