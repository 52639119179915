import { getSelectTheme, PartialAccount } from "@davo/portal-common";
import { AccountSearchRecord } from "@davo/types";
import { useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { getAllAccountsForSearchTerm } from "../services";

export interface IAccountSearch {
    handleSelectAccount: (account: PartialAccount) => void;
    exclude?: string;
}

const formatOptionLabel = (accountSearch: AccountSearchRecord) => {
    return accountSearch.hasActiveLocations ? accountSearch.name : `${accountSearch.name} (no active locations)`;
};

export const AccountSearch: FunctionComponent<IAccountSearch> = ({ handleSelectAccount, exclude }) => {
    const themeMUI = useTheme();
    const formThemeColors = getSelectTheme(themeMUI);

    const MenuList = (props: any) => {
        return (
            <components.MenuList {...props}>
                {props.children}
                {props.options.length <= 8 ? (
                    <div />
                ) : (
                    <div style={{ textAlign: "center" }}>{`Showing first 8 results, narrow search`}</div>
                )}
            </components.MenuList>
        );
    };

    const loadOptions = async (newValue: string) => {
        const trimmedValue = newValue.trim();
        if (trimmedValue.length > 2) {
            // start searching after the first two characters
            return getAllAccountsForSearchTerm<AccountSearchRecord>({
                searchTerm: trimmedValue,
                advancedSearch: true,
                excludeAccountId: exclude,
                accountOwner: undefined,
            });
        } else {
            return Promise.resolve([]);
        }
    };

    return (
        <div>
            <div style={{ fontWeight: "bold", marginBottom: "6px" }}>Search for Account</div>
            <AsyncSelect
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...formThemeColors,
                    },
                })}
                components={{ MenuList }}
                onChange={async (acctSearchRecord) => {
                    if (!acctSearchRecord) return;

                    handleSelectAccount({
                        id: acctSearchRecord.id,
                        name: acctSearchRecord.name,
                    });
                }}
                formatOptionLabel={(x: AccountSearchRecord) => formatOptionLabel(x)}
                defaultOptions={false}
                cacheOptions={true}
                loadOptions={loadOptions}
                escapeClearsValue={true}
            />
        </div>
    );
};
