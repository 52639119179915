import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { FunctionComponent } from "react";
import { MergeDestination } from "./ExtractionTypes";

export interface IChooseDestination {
    mergeDestination: MergeDestination;
    handleMergeDestinationChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const ChooseDestination: FunctionComponent<IChooseDestination> = ({
    mergeDestination,
    handleMergeDestinationChange,
}) => {
    return (
        <FormControl data-testid={"chooseDestination"}>
            <RadioGroup
                name="extraction-destination-radio-group"
                value={mergeDestination}
                onChange={handleMergeDestinationChange}>
                <FormControlLabel value="existing" control={<Radio />} label="Existing Account" />
                <FormControlLabel value="new" control={<Radio />} label="New Account" />
            </RadioGroup>
        </FormControl>
    );
};
