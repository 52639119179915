import {
    DavoLink,
    LinkButton,
    MultiSelect,
    PaperComponent,
    ReactTable,
    Select,
    d30Toast,
    d30ToastError,
    useModalEditor,
} from "@davo/portal-common";
import { IReferralLocationDetails, IReferralSource, toDisplayDateTimeString } from "@davo/types";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { ReferralSourceModal } from "./ReferralSourceModal";
import { getRefTrackingSources, getReferrals, recordReferralAsPaid, updateReferralSource } from "./services";

const yearMonthFormat = "yyyy-MM";

const defaultYearMonth = () => {
    return DateTime.utc().toFormat(yearMonthFormat);
};

export const selectableMonths: string[] = [];
for (let i = 0; i < 9; i++) {
    const date = DateTime.local().startOf("month").minus({ months: i });
    selectableMonths.push(date.toFormat(yearMonthFormat));
}

export const yearMonthLabel = (yearMonth: string) => {
    const month = DateTime.fromFormat(yearMonth, yearMonthFormat);
    return `${month.monthLong} ${month.year}`;
};

export function Referrals() {
    const [referrals, setReferrals] = useState<IReferralLocationDetails[]>();
    const [sources, setSources] = useState<IReferralSource[]>([]);
    const [selectedSources, setSelectedSources] = useState<IReferralSource[]>([]);
    const [sourceToUpdate, setSourceToUpdate] = useState<IReferralSource>();
    const [yearMonth, setYearMonth] = useState<string>(defaultYearMonth());
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isToggleInactive, setIsToggleInactive] = useState<boolean>(false);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [showReferralSourceModal, referralSourceModalProps] = useModalEditor<boolean>(() => {
        getRefTrackingSources()
            .then((result) => {
                setSources(result);
            })
            .catch((e) => d30ToastError("Problem getting referral sources.", e));
    });

    const refresh = useCallback(() => {
        if (isNil(yearMonth) || isEmpty(selectedSources)) {
            return;
        }
        getReferrals(
            yearMonth,
            selectedSources.map((s) => s.refSource)
        )
            .then((results) => {
                setReferrals(results);
            })
            .catch((e) => d30ToastError("Problem getting referrals.", e));
    }, [selectedSources, yearMonth]);

    useAsyncEffect(async () => {
        setYearMonth(defaultYearMonth());
        setSources(await getRefTrackingSources());
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const _shutdown = () => {
        setShowModal(false);
        setSourceToUpdate(undefined);
        setDescription(undefined);
        setIsToggleInactive(false);
    };

    const columns = useMemo(() => {
        return [
            {
                Header: "Link Clicked",
                accessor: "latestLinkClicked",
                Cell: (data: any) => <>{toDisplayDateTimeString(data.value, { showSeconds: true })}</>,
            },
            {
                Header: "Source",
                accessor: "refSource",
            },
            {
                Header: "ID",
                accessor: "refId",
            },
            {
                Header: "Deal",
                accessor: "refDealId",
            },
            {
                Header: "Lead Submitted",
                accessor: "latestLeadSubmitted",
                Cell: (data: any) => (
                    <>{data.value ? toDisplayDateTimeString(data.value, { showSeconds: true }) : ""}</>
                ),
            },
            {
                Header: "Location",
                accessor: "locationName",
                Cell: (data: any) => {
                    const ref: IReferralLocationDetails = data.cell.row.original;
                    if (!ref.accountId && ref.locationId) {
                        return <DavoLink to={`/no-account/location/${ref.locationId}`}>{data.value}</DavoLink>;
                    } else {
                        return <>{data.value}</>;
                    }
                },
            },
            {
                Header: "Account added",
                accessor: "hasAccount",
                Cell: (data: any) => (
                    <>{data.value ? toDisplayDateTimeString(data.value, { showSeconds: true }) : ""}</>
                ),
            },
            {
                Header: "Account created",
                accessor: "hasNewAccount",
                Cell: (data: any) => (
                    <>{data.value ? toDisplayDateTimeString(data.value, { showSeconds: true }) : ""}</>
                ),
            },
            {
                Header: "Account",
                accessor: "accountId",
                Cell: (data: any) => {
                    const ref: IReferralLocationDetails = data.cell.row.original;
                    const accountId = ref.accountId;
                    const accountName = ref.accountName;

                    if (accountId && accountName) {
                        return (
                            <LinkButton
                                url={`/accounts/${accountId}`}
                                label={accountName}
                                labelMaxChars={35}
                                align={"left"}
                            />
                        );
                    }
                    return null;
                },
            },
            {
                Header: "Bank added",
                accessor: "hasBank",
                Cell: (data: any) => (
                    <>{data.value ? toDisplayDateTimeString(data.value, { showSeconds: true }) : ""}</>
                ),
            },
            {
                Header: "Disconnected",
                accessor: "disconnected",
                Cell: (data: any) => (
                    <>{data.value ? toDisplayDateTimeString(data.value, { showSeconds: true }) : ""}</>
                ),
            },
            {
                Header: "User",
                accessor: "",
                Cell: (data: any) => {
                    const ref: IReferralLocationDetails = data.cell.row.original;
                    const userId = ref.userId;
                    const userName = ref.userName;

                    if (userId && userName) {
                        return (
                            <LinkButton
                                url={`/users/${userId}`}
                                label={userName}
                                labelMaxChars={35}
                                align={"left"}
                                target="_blank"
                            />
                        );
                    }
                    return null;
                },
            },
            {
                Header: "First Billing Funded",
                accessor: "firstBillingFunded",
                Cell: (data: any) => (
                    <>{data.value ? toDisplayDateTimeString(data.value, { showSeconds: true }) : ""}</>
                ),
            },
            {
                Header: "Paid",
                accessor: "paid",
                Cell: (data: any) => {
                    const paid = data.value;
                    if (paid) {
                        return <>{toDisplayDateTimeString(paid, { showSeconds: true })}</>;
                    }

                    const ref: IReferralLocationDetails = data.cell.row.original;
                    const id = ref.id;
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={async () => {
                                await recordReferralAsPaid(id);
                                d30Toast("Updated.");
                                refresh();
                            }}
                            disabled={!ref.locationName}>
                            Mark Paid
                        </Button>
                    );
                },
            },
            {
                Header: "IP",
                accessor: "ip",
            },
        ];
    }, [refresh]);

    return (
        <div style={{ width: "95%" }}>
            <Select<string>
                title="Month"
                value={yearMonth}
                options={selectableMonths}
                label={yearMonthLabel}
                showFullWidth={false}
                onChange={(value: string | undefined) => {
                    if (value) {
                        setYearMonth(value);
                        refresh();
                    }
                }}
            />
            <div
                style={{
                    marginBottom: "10px",
                    overflowY: "scroll",
                    border: "1px solid #CCCCCC",
                    borderRadius: "5px",
                }}>
                <div>
                    <label style={{ position: "relative", top: "-4px", right: "-10px", fontSize: "13px" }}>
                        Sources
                    </label>
                    <MultiSelect<IReferralSource>
                        style={{ textOverflow: "ellipsis", maxHeight: "300px" }}
                        options={sources}
                        value={selectedSources}
                        noneLabel="-- Select --"
                        label={(s: IReferralSource) => (
                            <>
                                {s.refSource} {s.description ? ` - ${s.description}` : ""}
                                <EditOutlinedIcon
                                    style={{ display: "inline-block", float: "right" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSourceToUpdate(s);
                                        setShowModal(true);
                                    }}
                                />
                            </>
                        )}
                        onChange={(values: IReferralSource[] | undefined) => {
                            setSelectedSources(values ?? []);
                            refresh();
                        }}
                    />
                </div>
            </div>
            <br />
            <Button variant="outlined" onClick={() => showReferralSourceModal()} size="small" color="primary">
                Add Referral Source
            </Button>
            {referralSourceModalProps.isDialogOpen && <ReferralSourceModal {...referralSourceModalProps} />}

            {referrals && (
                <ReactTable
                    columns={columns}
                    data={referrals}
                    refreshData={refresh}
                    options={{
                        headerRowStyle: { verticalAlign: "top" },
                    }}
                />
            )}
            {showModal && (
                <Dialog PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={true}>
                    <DialogTitle id="draggable-dialog-title">Update Referral Source Description</DialogTitle>
                    <DialogContent>
                        <TextField
                            style={{ width: "333px" }}
                            multiline={true}
                            rows={2}
                            value={description ?? sourceToUpdate?.description}
                            onChange={(e) => setDescription(e.target.value)}></TextField>
                    </DialogContent>
                    <DialogActions>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isToggleInactive}
                                        onChange={() => setIsToggleInactive(!isToggleInactive)}
                                    />
                                }
                                label="Mark Inactive"
                            />
                        </FormGroup>
                        <Button variant="outlined" color="primary" onClick={() => _shutdown()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                if (!sourceToUpdate?.id) {
                                    return;
                                }
                                setShowModal(false);
                                setSourceToUpdate(undefined);
                                await updateReferralSource(sourceToUpdate?.id, description, isToggleInactive);
                                d30Toast("Referral source updated!");
                                setSources(await getRefTrackingSources());
                            }}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
