import { getLocationsAll, getPartner } from "@davo/portal-common";
import { LocationRecord, LocationWithPartnerId, Partner } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { LocationDetail } from "./LocationDetail";
import { canDeactivateLocations, getLocationDetails, ICanDeactivateLocationsType } from "./services";

export function LocationDetailModal({
    target,
    closeDialog,
}: {
    target?: string;
    closeDialog: (target?: string) => void;
}) {
    const [location, setLocation] = useState<LocationWithPartnerId>();
    const [allAccountLocations, setAllAccountLocations] = useState<LocationRecord[]>();
    const [partner, setPartner] = useState<Partner | undefined>(undefined);
    const [deactivationStatus, setDeactivationStatus] = useState<ICanDeactivateLocationsType>();
    const getLocationInfo = async () => {
        if (!target) {
            return;
        }
        if (target !== location?.id) {
            setLocation(undefined);
            setDeactivationStatus(undefined);
        }
        const _location = await getLocationDetails(target);

        if (!_location) {
            return;
        }

        let partnerPromise;
        if (_location.partnerId) {
            partnerPromise = getPartner(_location.partnerId);
        }

        setLocation(_location);

        let locationsPromise;
        if (_location.accountId) {
            locationsPromise = getLocationsAll(_location.accountId);
        }

        const canDeactivateLocationPromise = await canDeactivateLocations([_location.id]);

        const [_partner, _locations, _canDeactivateLocation] = await Promise.all([
            partnerPromise,
            locationsPromise,
            canDeactivateLocationPromise,
        ]);

        setPartner(_partner);
        setAllAccountLocations(_locations);
        setDeactivationStatus(_canDeactivateLocation);
    };

    useAsyncEffect(async () => {
        await getLocationInfo();
    }, [target]);

    const shutdown = () => {
        closeDialog();
    };

    if (!location || !location.accountId) {
        return null;
    }

    return (
        <Dialog
            open={true}
            maxWidth="lg"
            aria-labelledby="dialog-title"
            style={{ boxShadow: "none", margin: "5px 2px" }}
            onClose={() => {
                shutdown();
            }}>
            <DialogTitle id="dialog-title">Location Detail</DialogTitle>
            <DialogContent>
                <LocationDetail
                    location={location}
                    accountId={location.accountId}
                    deactivationStatusLoaded={deactivationStatus ? deactivationStatus[location.id] : undefined}
                    partner={partner}
                    onChange={async () => {
                        await getLocationInfo();
                    }}
                    allAccountLocations={allAccountLocations}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => shutdown()} variant="outlined" color="primary" style={{ width: "100px" }}>
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    );
}
