import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React from "react";
import { FailingCredentials } from "./FailingCredentials";
import { GroupCredentials } from "./GroupCredentials";
import { UntestedTaxProfile } from "./UntestedTaxProfile";

export function TaxCredentialsPane() {
    return (
        <div>
            <div style={{ marginTop: "30px", width: "100%" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ fontSize: "20px" }}>Validating new</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                            <UntestedTaxProfile hasFiledBefore={false} />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div style={{ marginTop: "30px", width: "100%" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ fontSize: "20px" }}>Validating existing</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                            <UntestedTaxProfile hasFiledBefore={true} />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div style={{ marginTop: "30px", width: "100%" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ fontSize: "20px" }}>Failing Profiles</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                            <FailingCredentials />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div style={{ marginTop: "30px", width: "100%" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ fontSize: "20px" }}>Group Credentials</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                            <GroupCredentials />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}
