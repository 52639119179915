import {
    d30ToastError,
    LinkButton,
    Loading,
    ReactTable,
    sortDate,
    useModalEditor,
    useRefresh,
} from "@davo/portal-common";
import { AmendmentOpsPaneRecord, toDisplayDateString } from "@davo/types";
import { Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { CellProps } from "react-table";
import { AmendmentAddAdditionalNoteModal } from "./AmendmentAddAdditionalNote";
import "./AmendmentsRequiredPane.css";
import { AssignStaffModal } from "./AssignStaffModal";
import { RenderAssignee } from "./filing/RenderAssignee";
import { getRequiredAmendments, updateFilingAssignee } from "./services";

const renderAccountName = (data: AmendmentOpsPaneRecord) => {
    return (
        <LinkButton
            url={`/accounts/${data.accountId}`}
            label={`${data.accountName}`}
            labelMaxChars={35}
            align={"left"}
            rel="noreferrer"
            target="_blank"
        />
    );
};

const renderFilingPeriodEnd = (data: AmendmentOpsPaneRecord) => {
    return (
        <LinkButton
            url={`/filings/${data.filingId}`}
            label={`${toDisplayDateString(data.filingPeriodEnd)}`}
            labelMaxChars={35}
            align={"left"}
            rel="noreferrer"
            target="_blank"
        />
    );
};

const renderDavoError = (hasDavoError: boolean) => <div data-testid={"davoError"}>{hasDavoError ? "Y" : ""}</div>;

const renderHasNotice = (hasNotice: boolean) => <div data-testid={"hasNotice"}>{hasNotice ? "Y" : ""}</div>;

export function AmendmentsRequiredPane() {
    const [amendments, setAmendments] = useState<AmendmentOpsPaneRecord[]>();
    const [filingIdToAssign, setFilingIdToAssign] = useState<string>();
    const [refresh] = useRefresh(() => {
        getRequiredAmendments()
            .then((results) => setAmendments(results))
            .catch((e) => d30ToastError("Problem getting amendments.", e));
    });

    const [showFilingAssigneeModal, filingAssigneeModalProps] = useModalEditor<string | null>();

    const columns = useMemo(() => {
        return [
            {
                Header: "Date Marked",
                accessor: "amendmentDate" as const,
                Cell: (data: any) => (
                    <>
                        {data.value && (
                            <>
                                <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>
                                <AmendmentAddAdditionalNoteModal amendment={data.cell.row.original} />
                            </>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "accountName",
                Cell: (data: any) => {
                    return renderAccountName(data.cell.row.original);
                },
            },
            {
                Header: "Period End",
                accessor: "filingPeriodEnd" as const,
                Cell: (data: any) => {
                    return renderFilingPeriodEnd(data.cell.row.original);
                },
                sortType: sortDate("filingPeriodEnd"),
            },
            {
                Header: "Jurisdiction",
                accessor: "jurisdiction" as const,
                Cell: (data: any) => <div>{data.value}</div>,
            },
            {
                Header: "Error",
                accessor: "davoError" as const,
                Cell: (data: CellProps<any, any>) => renderDavoError(data.row.original.davoError),
            },
            {
                Header: "Notice",
                accessor: "hasNotice" as const,
                Cell: (data: CellProps<any, any>) => renderHasNotice(data.row.original.hasNotice),
            },
            {
                Header: "Note",
                accessor: "amendmentNote",
                Cell: (data: any) => <div>{data.value}</div>,
            },
            {
                Header: "Assignee",
                accessor: "assignee",
                Cell: (data: CellProps<any, any>) => <RenderAssignee userId={data.row.original.assignedUserId} />,
            },
            {
                Header: "",
                accessor: "assign",
                Cell: (data: CellProps<any, any>) => {
                    return (
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                setFilingIdToAssign(data.row.original.filingId);
                                showFilingAssigneeModal(data.row.original.assignedUserId);
                            }}>
                            {data.row.original.assignedUserId ? "Reassign" : "Assign"}
                        </Button>
                    );
                },
                disableSortBy: true,
            },
        ];
    }, [showFilingAssigneeModal]);

    if (!amendments) {
        return <Loading />;
    }
    return (
        <div>
            <ReactTable
                title="Required Amendments"
                columns={columns}
                data={amendments}
                refreshData={refresh}
                options={{
                    pageSize: 10,
                }}
            />
            {filingAssigneeModalProps.isDialogOpen && (
                <AssignStaffModal
                    shouldFilterByFilers={true}
                    {...filingAssigneeModalProps}
                    assignStaffUser={(_passThroughIds, selected) => {
                        filingIdToAssign &&
                            updateFilingAssignee(filingIdToAssign, selected?.id ?? undefined, true)
                                .then(() => refresh())
                                .catch((e) => d30ToastError(e.message, e));
                    }}
                />
            )}
        </div>
    );
}
