import { d30ToastError, downloadFilingAttachmentFile } from "@davo/portal-common";
import { FilingAttachment, FilingWithAttachments } from "@davo/types";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownload";
import DeleteTwoToneIcon from "@mui/icons-material/Delete";
import { IconButton, Link, Typography } from "@mui/material";
import React from "react";
import { removeFilingAttachment } from "./services";

const removeAttachmentWhileEditing = async (attachment: FilingAttachment) => {
    try {
        await removeFilingAttachment(attachment.filingId, attachment.id);
    } catch (e: any) {
        d30ToastError(e.message);
    }
};

export function FilingAttachments({
    accountId,
    filing,
    isEditing,
    onRemove,
}: {
    accountId: string;
    filing: FilingWithAttachments;
    isEditing: boolean;
    onRemove?: () => void;
}) {
    if (filing.attachments.length === 0) {
        return null;
    }
    return (
        <div>
            {filing.attachments
                .filter((x) => !!x.filename)
                .map((attachment) => (
                    <div key={attachment.id}>
                        <div style={{ display: "inline-flex" }}>
                            <Link
                                download
                                target="_blank"
                                onClick={async () =>
                                    downloadFilingAttachmentFile(accountId, filing.taxProfileId, filing.id, attachment)
                                }>
                                <Typography style={{ marginRight: "4px", cursor: "pointer" }}>
                                    <CloudDownloadTwoToneIcon color="primary" />
                                </Typography>
                            </Link>
                        </div>
                        {isEditing && onRemove && (
                            <div style={{ display: "inline-flex" }}>
                                <IconButton
                                    aria-label="remove"
                                    onClick={async () => {
                                        await removeAttachmentWhileEditing(attachment);
                                        onRemove();
                                    }}>
                                    <DeleteTwoToneIcon color="primary" />
                                </IconButton>
                                <br />
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
}
