import { d30Toast, d30ToastError, NoticesTable } from "@davo/portal-common";
import { FilingPenaltyExtension, INotice } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { CustomTabs } from "./components/CustomTabs";
import {
    associateNoticeToPenalty,
    getNoticesForPenalty,
    getPossibleNoticesForPenalty,
    unassociateNotice,
} from "./services";

export interface INoticeModalType {
    target?: FilingPenaltyExtension;
    setOpen: (open: boolean) => void;
    refresh: () => void;
}

export function PenaltyNoticeModal({ target, setOpen, refresh }: INoticeModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [notices, setNotices] = useState<(INotice & { downloadReady: boolean })[]>([]);
    const [mode, setMode] = useState<"display" | "add">("display");
    const [possibleNotices, setPossibleNotices] = useState<(INotice & { downloadReady: boolean })[] | undefined>(
        undefined
    );

    useAsyncEffect(async () => {
        setNotices([]);
        if (!target) return;
        const res: (INotice & { downloadReady: boolean })[] = await getNoticesForPenalty(target.filingId, target.id);
        setNotices(res);
    }, [target]);

    useAsyncEffect(async () => {
        setPossibleNotices([]);
        if (!target || mode === "display") return;
        const res = await getPossibleNoticesForPenalty(target.filingId, target.id);
        res.sort((x, y) => {
            if (x.status === "new" && y.status !== "new") {
                return -1;
            } else if (x.status?.includes("open") && y.status?.includes("closed")) {
                return -1;
            } else if (x.status?.includes("closed") && y.status?.includes("open")) {
                return 1;
            } else {
                return x.updated < y.updated ? -1 : 1;
            }
        });
        setPossibleNotices(res);
    }, [mode]);

    const shutdown = () => {
        setMode("display");
        setOpen(false);
    };

    const add = (noticeId: string) => {
        if (!target) return;
        associateNoticeToPenalty(target.id, noticeId)
            .then(() => {
                refresh();
                d30Toast("Notice associated");
            })
            .catch((e) => {
                d30ToastError("Problem associating notice.", e);
            });
    };

    const remove = (noticeId: string) => {
        if (!target) return;
        unassociateNotice(noticeId)
            .then(() => {
                refresh();
                d30Toast("Notice unassociated");
            })
            .catch((e) => {
                d30ToastError("Problem unassociating notice.", e);
            });
    };

    const renderDisplay = () => {
        return (
            <>
                {notices && notices.length > 0 && (
                    <NoticesTable
                        notices={notices}
                        actionLabel={"Remove"}
                        action={remove}
                        includeCancelButton={false}
                        includeNotes={false}
                    />
                )}
                {notices && notices.length === 0 && <Typography>No notices associated with this penalty</Typography>}
            </>
        );
    };

    const renderAdd = () => {
        return (
            <>
                {possibleNotices && possibleNotices.length > 0 && (
                    <NoticesTable
                        notices={possibleNotices}
                        actionLabel={"Add"}
                        action={add}
                        includeCancelButton={false}
                        includeNotes={false}
                    />
                )}
                {possibleNotices && possibleNotices.length === 0 && (
                    <Typography>No notices are unassociated for this account</Typography>
                )}
            </>
        );
    };

    return (
        <div>
            <Dialog
                aria-labelledby="draggable-dialog-title-notices"
                fullScreen={makeFullScreen}
                open={true}
                maxWidth={"md"}
                fullWidth={true}
                onClose={shutdown}>
                <DialogTitle id="draggable-dialog-title-notices">Notices</DialogTitle>
                <DialogContent style={{ minHeight: "480px" }}>
                    <CustomTabs
                        mode={mode}
                        setMode={setMode}
                        tabs={[
                            { label: "Associated", value: "display", content: renderDisplay() },
                            { label: "Unassociated", value: "add", content: renderAdd() },
                        ]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => shutdown()} variant="contained" color="primary" style={{ width: "100px" }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
