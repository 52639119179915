import { d30ToastError, LinkButton, Loading, ReactTable } from "@davo/portal-common";
import {
    FailedTaxProfileRecord,
    TaxProfileFailureReason,
    TaxProfileFailureReasons,
    toDisplayDateTimeString,
} from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ResendFailedTaxCredentialsModal } from "./ResendFailedTaxCredentialsModal";
import { getFailingTaxProfiles } from "./services";
import { TaxProfileCredentialsButton } from "./TaxProfileCredentialsButton";

const renderAdminEmailAddresses = (data: any) => {
    const results: any = [];

    const emails = data.value.split(" ");

    for (const email of emails) {
        results.push(
            <Typography color={"primary"} key={email}>
                <a href={`mailto:${email}`} style={{ color: "inherit" }}>
                    {email}
                </a>
                <br />
            </Typography>
        );
    }
    return (
        <div>
            {results}
            <ResendFailedTaxCredentialsModal taxProfileId={data.cell.row.original.id} />
        </div>
    );
};

const renderFailureTimestamp = (data: any) => {
    return <span>{toDisplayDateTimeString(data.value)}</span>;
};

const renderFailureReason = (reason: string) => {
    return <span style={{ whiteSpace: "nowrap" }}>{TaxProfileFailureReasons[reason as TaxProfileFailureReason]}</span>;
};

const renderProfile = (data: any) => {
    return (
        <LinkButton url={`/accounts/${data.accountId}`} label={`${data.name} ~ ${data.legalName}`} labelMaxChars={35} />
    );
};

const renderFailedCount = (data: any) => {
    return (
        <div>
            <span style={{ whiteSpace: "nowrap", marginRight: "4px" }}>{data.value}</span>
            {data.cell.row.original.bankPaused && (
                <Tooltip title={"Bank account is also paused"} placement="top">
                    <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                </Tooltip>
            )}
        </div>
    );
};

const renderState = (data: any) => {
    return <span>{data.cell.row.original.state}</span>;
};

export function FailingCredentials() {
    const [failingTaxProfiles, setFailingTaxProfiles] = useState<FailedTaxProfileRecord[]>();

    const refresh = useCallback(() => {
        setFailingTaxProfiles(undefined);
        getFailingTaxProfiles()
            .then((failing) => {
                failing.sort((x, y) => {
                    if (x.failedTimestamp > y.failedTimestamp) {
                        return -1;
                    } else if (x.failedTimestamp === y.failedTimestamp) {
                        if (x.failingReason === "password" && y.failingReason !== "password") {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else {
                        return 1;
                    }
                });
                setFailingTaxProfiles(failing);
            })
            .catch((e) => d30ToastError("Problem getting failing tax profiles.", e));
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Failure Date",
                Cell: renderFailureTimestamp,
                accessor: "failedTimestamp",
            },
            {
                Header: "State",
                Cell: renderState,
                accessor: "state",
            },
            {
                Header: "Failure Reason",
                Cell: (data: any) => {
                    return renderFailureReason(data.cell.row.original.failingReason);
                },
                accessor: "failingReason",
            },
            {
                Header: "Frequency",
                accessor: "frequency",
            },
            {
                Header: "Fails Last 30 Days",
                Cell: renderFailedCount,
                accessor: "failedCount",
            },
            {
                Header: "Recently Boarded",
                accessor: "recentlyBoarded",
                Cell: (data: any) => {
                    return data.value ? "Y" : "N";
                },
            },
            {
                Header: "Email",
                Cell: renderAdminEmailAddresses,
                accessor: "adminEmailAddresses",
            },
            {
                Header: "Credentials",
                id: "credentials",
                accessor: (x: any) => x.state,
                Cell: (data: any) => (
                    <TaxProfileCredentialsButton
                        taxProfileId={data.row.original.id}
                        accountId={data.row.original.accountId}
                        callback={refresh}
                        isReadOnly={false}
                    />
                ),
                disableSortBy: true,
            },
            {
                Header: "Tax Profile Name",
                Cell: (value: any) => {
                    return renderProfile(value.cell.row.original);
                },
                accessor: "name",
            },
            {
                Header: "Legal Name",
                accessor: "legalName",
            },
        ];
    }, []);

    if (!failingTaxProfiles) {
        return <Loading />;
    }
    return (
        <div>
            <ReactTable
                columns={columns}
                options={{
                    hiddenColumns: ["legalName"],
                    dataTestId: "failingCredentialsTable",
                }}
                data={failingTaxProfiles}
                refreshData={refresh}
            />
        </div>
    );
}
