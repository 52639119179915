import {
    getGlobal,
    LinkButton,
    Loading,
    portalUrl,
    ReactTable,
    Select,
    setGlobal,
    StatusDot,
} from "@davo/portal-common";
import { AccountSummary, User } from "@davo/types";
import { Button } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getAllAccountsForSearchTerm, getAllStaff } from "./services";

interface IAccountSearchFilters {
    searchText: string;
}

const initialFilters: IAccountSearchFilters = {
    searchText: "",
};

const savedFilters: any = getGlobal("accountSearchFilters");
if (!savedFilters) {
    setGlobal("accountSearchFilters", { ...initialFilters });
}

const renderStatus = (data: any) => {
    return (
        <StatusDot
            level={data.cell.row.original.minimumLocationStatus}
            message={data.cell.row.original.minimumLocationStatusMessage}
        />
    );
};

const renderName = (data: any) => {
    return (
        <div style={{ float: "left" }}>
            <LinkButton
                url={`/accounts/${data.cell.row.original.id}`}
                style={{ textAlign: "left", color: "#78a0cd" }}
                label={data.cell.row.original.name}
                labelMaxChars={35}
            />
        </div>
    );
};

export function AccountOwnerFilter({ show }: { show: boolean }) {
    const [selectedOwner, setSelectedOwner] = useState<User>();
    const [filterByOwnerOptions, setFilterByOwnerOptions] = useState<User[]>();
    const [ownerResults, setOwnerResults] = useState<AccountSummary[]>();

    useAsyncEffect(async () => {
        const allStaff = await getAllStaff();
        allStaff.sort((x, y) => {
            if (x.email && y.email) {
                if (x.email < y.email) {
                    return -1;
                }
                if (x.email > y.email) {
                    return 1;
                }
            }
            return 0;
        });
        setFilterByOwnerOptions(allStaff);
    }, []);

    useAsyncEffect(async () => {
        if (selectedOwner) {
            setOwnerResults(
                await getAllAccountsForSearchTerm({
                    searchTerm: undefined,
                    advancedSearch: false,
                    excludeAccountId: undefined,
                    accountOwner: selectedOwner.id,
                })
            );
        }
    }, [selectedOwner]);

    const ownerResultsColumns = useMemo(() => {
        return [
            {
                Header: "Name",
                accessor: "name",
                Cell: renderName,
            },
            {
                Header: "Portal",
                accessor: "portal",
                Cell: (data: any) => (
                    <Button
                        size="small"
                        variant="outlined"
                        style={{ color: "#78a0cd", border: "1px solid #78a0cd" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(`${portalUrl}/${data.cell.row.original.id}?opsLogin=required`, "_blank");
                        }}>
                        Portal
                    </Button>
                ),
            },
            {
                Header: "Status",
                accessor: "minimumLocationStatus",
                Cell: renderStatus,
            },
            {
                Header: "Location Ids",
                accessor: "locationIdList",
            },
        ];
    }, []);

    if (!show) {
        return null;
    }
    return (
        <div style={{ marginLeft: "5px", width: "100%" }}>
            {filterByOwnerOptions && filterByOwnerOptions.length > 0 && (
                <Select<User>
                    title="Assignee"
                    style={{ width: "300px" }}
                    options={filterByOwnerOptions}
                    label={(u: User) =>
                        !isNil(u.firstName) && !isNil(u.lastName) ? `${u.firstName} ${u.lastName}` : "No name found"
                    }
                    noneLabel={"None"}
                    value={selectedOwner}
                    onChange={setSelectedOwner}
                />
            )}
            {selectedOwner && !ownerResults && <Loading />}
            {selectedOwner && ownerResults && ownerResults.length === 0 && (
                <span style={{ fontSize: "15px", color: "red", marginLeft: "10px" }}>
                    No accounts assigned to this user
                </span>
            )}
            {ownerResults && ownerResults.length > 0 && (
                <ReactTable
                    columns={ownerResultsColumns}
                    data={ownerResults}
                    options={{
                        pageSize: 10,
                        hiddenColumns: ["locationIdList", "locationNameList"],
                        headerRowStyle: {
                            color: "primary",
                        },
                    }}
                />
            )}
        </div>
    );
}
