import { StatusDot } from "@davo/portal-common";
import { getLocationsInfo, StatusLevel, TaxProfile, TaxProfileFailureReasonsDescriptions } from "@davo/types";
import React from "react";

export interface ITaxProfileCredentialsStatus {
    taxProfile: TaxProfile;
}

export function TaxProfileCredentialsStatus({ taxProfile }: ITaxProfileCredentialsStatus) {
    const { hasFailingTaxInfo, hasUntestedTaxCredentials } = getLocationsInfo([], null, [taxProfile]);

    let level: StatusLevel = "ok";
    let message = "OK";
    if (hasFailingTaxInfo) {
        level = "error";
        message = "Failing";

        if (taxProfile.failingReason) {
            message += ` - ${TaxProfileFailureReasonsDescriptions[taxProfile.failingReason]}`;
        }
    } else if (hasUntestedTaxCredentials) {
        level = "warning";
        message = "Untested";
    }
    // else working
    return <StatusDot level={level} toolTip={message} />;
}
