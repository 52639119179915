import { get, post, put } from "@davo/portal-common";
import { IWellsFargoTransactionInfo, OptionalString, ReturnedByCheck } from "@davo/types";

export async function setOutflowWellsNote(outflowId: string, note: OptionalString) {
    await put(`api/ops/outflows/${outflowId}/note`, { note }, true);
}

export async function getUnmatchedReturnedByCheck(abortController?: AbortController) {
    return get<ReturnedByCheck[]>(`api/ops/outflows/returned-by-check/unmatched`, true, abortController);
}

export async function findCandidateMatchingBankTransactionsForReturnedByCheck(outflowId: string) {
    return get<IWellsFargoTransactionInfo[]>(`api/ops/outflows/${outflowId}/candidate-transactions`, true);
}

export async function matchReturnedByCheck(outflowId: string, txnId: string) {
    await post(`api/ops/outflows/${outflowId}/txn-match`, { outflowId, txnId }, true);
}
