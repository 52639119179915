import {
    d30Toast,
    d30ToastError,
    getJurisdictionByStateCode,
    getJurisdictionDefaultFilingType,
    getJurisdictionFilingType,
    hasPermission,
    Loading,
    ReactTable,
    updateTaxProfileFilingType,
    useLoginContext,
} from "@davo/portal-common";
import {
    FrequencyLabels,
    IJurisdiction,
    IJurisdictionFilingType,
    IJurisdictionUIField,
    IJurisdictionUIFieldDefinitionBase,
    Partner,
    TaxProfileFailureReasonsDescriptions,
    TaxProfileWithCredentials,
} from "@davo/types";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import { Button, Typography, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import useAsyncEffect from "use-async-effect";
import { ConfirmFailingCredentials } from "./ConfirmFailingCredentials";
import {
    getTaxProfileWithCredentials,
    markTaxProfileCredentialsAsFailing,
    setTaxProfileTypeVerificationStatus,
} from "./services";
import { SetCredentialsNote } from "./SetCredentialsNote";

const StateTaxSiteLink = ({ filingType }: { filingType: IJurisdictionFilingType | undefined }) => {
    const theme = useTheme();

    if (!filingType || !filingType.url) {
        return null;
    }

    return (
        <a
            href={filingType.url}
            target="_blank"
            style={{ color: theme.palette.mode === "dark" ? "#fff" : "#00e" }}
            rel="noreferrer">
            {`${filingType.name} site`}
        </a>
    );
};

export interface ITaxProfileCredentialsInfoType {
    taxProfileId: string;
    isReadOnly: boolean;
    taxProfileWithCredentialsPreloaded?: TaxProfileWithCredentials;
    accountId: string;
    credentialsFailingOverrideStatus?: string;
    callback?: () => void;
    partner?: Partner;
    partnerGroupPolicyStates?: string[];
    internalGroupPolicyStates?: string[];
}

export function TaxProfileCredentialsInfo({
    taxProfileId,
    isReadOnly,
    taxProfileWithCredentialsPreloaded,
    accountId,
    credentialsFailingOverrideStatus,
    callback,
    partner,
    partnerGroupPolicyStates,
    internalGroupPolicyStates,
}: ITaxProfileCredentialsInfoType) {
    const loginContext = useLoginContext();

    const [taxProfile, setTaxProfile] = useState<TaxProfileWithCredentials>();
    const [tableData, setTableData] = useState<{ title: string; value: React.JSX.Element }[]>();
    const [jurisdiction, setJurisdiction] = useState<IJurisdiction>();
    const [filingType, setFilingType] = useState<IJurisdictionFilingType>();
    const [fieldDefinitions, setFieldDefinitions] = useState<IJurisdictionUIFieldDefinitionBase[]>();
    const [shouldOverrideCredentialsFailing, setShouldOverrideCredentialsFailing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useEffect(() => {
        if (taxProfileWithCredentialsPreloaded) {
            setTaxProfile(taxProfileWithCredentialsPreloaded);
        }
    }, [taxProfileWithCredentialsPreloaded]);

    useAsyncEffect(async () => {
        if (taxProfileWithCredentialsPreloaded) {
            return;
        }
        const tp = await getTaxProfileWithCredentials(taxProfileId).catch((e) => {
            d30ToastError("Problem getting tax profile.", e);
            return undefined;
        });
        setTaxProfile(tp);
    }, [taxProfileId]);

    useAsyncEffect(async () => {
        if (!taxProfile) {
            return;
        }
        setShouldOverrideCredentialsFailing(credentialsFailingOverrideStatus === "untested");

        const juris = await getJurisdictionByStateCode(taxProfile.state);
        setJurisdiction(juris);

        let info: IJurisdictionFilingType;
        if (taxProfile.jurisdictionFilingTypeId) {
            info = await getJurisdictionFilingType(taxProfile.jurisdictionFilingTypeId);
        } else {
            info = await getJurisdictionDefaultFilingType(taxProfile.state);
        }
        setFilingType(info);
    }, [taxProfile]);

    useEffect(() => {
        if (!taxProfile || !filingType) {
            return;
        }
        if (filingType.fields.length > 1) {
            if (isNil(taxProfile.jurisdictionUIFlavorGroupId)) {
                // When tax profile has not been set with a flavor group, default to first one
                setFieldDefinitions(filingType.fields[0].definitions);
            } else {
                const flavorDef = filingType.fields.find(
                    (field: IJurisdictionUIField) =>
                        field.jurisdictionFlavorGroupId === taxProfile.jurisdictionUIFlavorGroupId
                );
                setFieldDefinitions(flavorDef?.definitions);
            }
        } else {
            // Use the basic default field definitions for a filing type
            setFieldDefinitions(filingType.fields[0].definitions);
        }
    }, [filingType, taxProfile]);

    useEffect(() => {
        if (!taxProfile || !filingType) {
            return;
        }
        const data = [{ title: "State", value: <>{jurisdiction ? jurisdiction.fullName : ""}</> }];
        if (!taxProfile.filingMethod || taxProfile.filingMethod !== "take-give-back") {
            data.push({
                title: "Filing Link",
                value: <StateTaxSiteLink filingType={filingType} />,
            });

            if (!isEmpty(taxProfile.federalTaxId)) {
                const feinField = fieldDefinitions?.find((def) => def.jurisdictionFieldType.tag === "FEIN");
                data.push({
                    title: feinField ? feinField.label : "Dated info",
                    value: <>{taxProfile.federalTaxId ?? ""}</>,
                });
            }

            if (!isEmpty(taxProfile.stateTaxId)) {
                const stateTaxIdField = fieldDefinitions?.find(
                    (def) => def.jurisdictionFieldType.tag === "STATE_TAX_ID"
                );
                data.push({
                    title: stateTaxIdField ? stateTaxIdField.label : "Dated info",
                    value: <>{taxProfile.stateTaxId ?? ""}</>,
                });
            }

            if (!isEmpty(taxProfile.additionalStateMetadata)) {
                const additionalStateMetadataField = fieldDefinitions?.find(
                    (def) => def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA"
                );
                data.push({
                    title: additionalStateMetadataField ? additionalStateMetadataField.label : "Dated info",
                    value: <>{taxProfile.additionalStateMetadata ?? ""}</>,
                });
            }

            if (!isEmpty(taxProfile.additionalStateMetadata2)) {
                const additionalStateMetadataTwoField = fieldDefinitions?.find(
                    (def) => def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA_TWO"
                );
                data.push({
                    title: additionalStateMetadataTwoField ? additionalStateMetadataTwoField.label : "Dated info",
                    value: <>{taxProfile.additionalStateMetadata2 ?? ""}</>,
                });
            }

            if (!isEmpty(taxProfile.additionalStateMetadata3)) {
                const additionalStateMetadataThreeField = fieldDefinitions?.find(
                    (def) => def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA_THREE"
                );
                data.push({
                    title: additionalStateMetadataThreeField ? additionalStateMetadataThreeField.label : "Dated info",
                    value: <>{taxProfile.additionalStateMetadata3 ?? ""}</>,
                });
            }
        }

        data.push({ title: "Frequency", value: <>{FrequencyLabels[taxProfile.frequency]}</> });

        if (taxProfile.credentials.username) {
            const userNameField = fieldDefinitions?.find((def) => def.jurisdictionFieldType.tag === "USERNAME");
            data.push({
                title: userNameField ? userNameField.label : "Dated info",
                value: <>{taxProfile.credentials.username}</>,
            });
        }

        if (taxProfile.credentials.password) {
            const passwordField = fieldDefinitions?.find((def) => def.jurisdictionFieldType.tag === "PASSWORD");
            data.push({
                title: passwordField ? passwordField.label : "Dated info",
                value: <>{taxProfile.credentials.password}</>,
            });
        }

        if (taxProfile.credentials.pin) {
            const pinField = fieldDefinitions?.find((def) => def.jurisdictionFieldType.tag === "PIN");
            data.push({
                title: pinField ? pinField.label : "Dated info",
                value: <>{taxProfile.credentials.pin}</>,
            });
        }

        if (taxProfile.credentialsNote) {
            data.push({ title: "Credentials note", value: <>{taxProfile.credentialsNote}</> });
        }

        if (taxProfile.credentialsType !== "SIMPLE" && taxProfile.groupLoginCreds) {
            data.push({ title: "Group Login Used", value: <></> });
            data.push(
                { title: "Group Username", value: <>{taxProfile.groupLoginCreds.username ?? ""}</> },
                { title: "Group Password", value: <>{taxProfile.groupLoginCreds.password ?? ""}</> }
            );
            if (taxProfile.groupLoginCreds.pin) {
                data.push({ title: "Group Pin", value: <>{taxProfile.groupLoginCreds.pin}</> });
            }
        }

        setTableData(data);
    }, [fieldDefinitions, filingType, jurisdiction, taxProfile]);

    const columns = useMemo(() => {
        return [
            {
                Header: "",
                accessor: "title",
                disableSortBy: true,
            },
            {
                Header: "",
                accessor: "value",
                Cell: (data: any) => (
                    <>
                        {data.value}&nbsp;&nbsp;
                        {!!data.value &&
                            data.value.props &&
                            data.value.props.children &&
                            typeof data.value.props.children !== "object" && (
                                <CopyToClipboard text={data.value.props.children} onCopy={() => d30Toast("Copied")}>
                                    <FileCopyTwoToneIcon
                                        fontSize="small"
                                        color="primary"
                                        style={{ verticalAlign: "middle" }}
                                    />
                                </CopyToClipboard>
                            )}
                    </>
                ),
                disableSortBy: true,
            },
        ];
    }, []);

    if (
        !tableData ||
        !taxProfile ||
        !filingType ||
        isNil(internalGroupPolicyStates) ||
        isNil(partnerGroupPolicyStates)
    ) {
        return <Loading />;
    }

    return (
        <div data-testid={"tax-credentials-info"} style={{ marginRight: "10px" }}>
            <ReactTable
                columns={columns}
                data={tableData}
                refreshData={tableData}
                options={{
                    pageSize: tableData.length,
                    hideToolbar: true,
                }}
            />

            {!isReadOnly && (
                <div
                    style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                    }}>
                    {(taxProfile.credentialsType === "SIMPLE" || shouldOverrideCredentialsFailing) &&
                        (taxProfile.credentialsFailing === "failing" ||
                            taxProfile.credentialsFailing === "untested") && (
                            <Button
                                data-testid={"mark-good-button"}
                                disabled={!hasPermission(loginContext.permissions, "base_access") || isBusy}
                                startIcon={
                                    isBusy && (
                                        <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                                    )
                                }
                                variant="outlined"
                                onClick={async () => {
                                    if (isBusy) return;

                                    setIsBusy(true);
                                    markTaxProfileCredentialsAsFailing(accountId, taxProfile.id, "working")
                                        .then(async () => {
                                            if (isNil(taxProfile.jurisdictionFilingTypeId)) {
                                                await updateTaxProfileFilingType(
                                                    accountId,
                                                    taxProfile.id,
                                                    filingType?.id
                                                );
                                            }
                                            await setTaxProfileTypeVerificationStatus(accountId, taxProfile.id, true);
                                        })
                                        .then(() => {
                                            callback && callback();
                                        })
                                        .catch((e) =>
                                            d30ToastError("Problem marking tax profile credentials as working.", e)
                                        );
                                }}
                                size="small"
                                color="primary">
                                Mark good
                            </Button>
                        )}

                    {taxProfile.credentialsType === "SIMPLE" &&
                        (taxProfile.credentialsFailing === "working" || taxProfile.credentialsFailing === "untested") &&
                        !partner?.restrictOpsFailingCreds && (
                            <ConfirmFailingCredentials
                                accountId={accountId}
                                taxProfileState={taxProfile.state}
                                taxProfileId={taxProfile.id}
                                doneCallback={() => {
                                    callback && callback();
                                }}
                            />
                        )}

                    <SetCredentialsNote
                        taxProfile={taxProfile}
                        doneCallback={() => {
                            callback && callback();
                        }}
                    />
                </div>
            )}

            <div
                style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                }}>
                {!isReadOnly && (
                    <div>
                        {(taxProfile.credentialsType === "SIMPLE" || shouldOverrideCredentialsFailing) &&
                            taxProfile.credentialsFailing === "untested" && (
                                <Typography style={{ color: "orange" }}>Profile is untested</Typography>
                            )}
                        {taxProfile.credentialsType === "SIMPLE" && taxProfile.credentialsFailing === "failing" && (
                            <div style={{ display: "flex", flexFlow: "column wrap" }}>
                                <Typography style={{ color: "red" }}>Profile marked as failing</Typography>
                                {taxProfile.failingReason && (
                                    <Typography
                                        style={{
                                            color: "red",
                                        }}>{`${TaxProfileFailureReasonsDescriptions[taxProfile.failingReason]}`}</Typography>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {taxProfile.credentialsType === "SIMPLE" &&
            (partnerGroupPolicyStates.includes(taxProfile.state) ||
                internalGroupPolicyStates.includes(taxProfile.state)) &&
            (!taxProfile.filingMethod || taxProfile.filingMethod !== "take-give-back") ? (
                <div
                    style={{
                        marginTop: "5px",
                        textAlign: "left",
                        color: "red",
                    }}>
                    Please add to group login
                </div>
            ) : null}
        </div>
    );
}
