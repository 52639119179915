import { LinkButton, ReactTable, Select, useRefresh } from "@davo/portal-common";
import {
    initialCap,
    ManualSetAsideFailure,
    ManualSetAsideType,
    moneyFromCents,
    noop,
    toDisplayDateString,
} from "@davo/types";
import { DateTime } from "luxon";
import React, { useMemo, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { LocationAccounting } from "./LocationAccounting";
import { LocationManualTransactionsModal } from "./LocationManualTransactionsModal";
import { filingSessionFormat } from "./reconcile-remitted/Util";
import { getSetAsideFailures } from "./services";

const getYearMonthList = () => {
    const vals: string[] = [];

    for (let i = 0; i < 18; i++) {
        const date = DateTime.local().startOf("month").minus({ months: i });
        vals.push(date.toFormat(filingSessionFormat));
    }

    return vals;
};

const getTypeList = () => {
    return ["catchup", "discount", "first-funding", "reconcile"] as ManualSetAsideType[];
};

const dateFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>}</>;
};

export function SetAsideFailuresPane() {
    const [type, setType] = useState<ManualSetAsideType>();
    const [yearMonth, setYearMonth] = useState<string>();
    const [results, setResults] = useState<ManualSetAsideFailure[]>([]);
    const controllerRef = useRef<AbortController | undefined>();

    const [reRender] = useRefresh(noop);

    useAsyncEffect(async () => {
        setResults([]);

        if (controllerRef?.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();

        if (!yearMonth) {
            return;
        }

        const result = await getSetAsideFailures(yearMonth, type, controllerRef.current);
        setResults(result);
    }, [yearMonth, type]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Account",
                accessor: "accountId",
                Cell: (data: any) => (
                    <LinkButton
                        url={`/accounts/${data.cell.row.original.accountId}`}
                        label={data.cell.row.original.accountName}
                        labelMaxChars={35}
                        align={"left"}
                    />
                ),
            },
            {
                Header: "Timestamp",
                accessor: "timestamp",
                Cell: dateFormat,
            },
            {
                Header: "Period Day",
                accessor: "periodDay",
                Cell: dateFormat,
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: (data: any) => {
                    const msa: ManualSetAsideFailure = data.cell.row.original;
                    const isPush = msa.direction === "push";
                    const amount = msa.amount * (isPush ? -1 : 1);
                    return <div style={{ textAlign: "right" }}>${moneyFromCents(amount)}</div>;
                },
            },
            {
                Header: "Forte Status",
                accessor: "forteStatus",
            },
            {
                Header: "Type",
                accessor: "manualType",
            },
            {
                Header: "",
                id: "action",
                accessor: "amount",
                Cell: (data: any) => {
                    const msa: ManualSetAsideFailure = data.cell.row.original;
                    return (
                        <LocationAccounting
                            accountId={msa.accountId}
                            locationId={msa.locationId}
                            initialPeriodDay={msa.periodDay}
                        />
                    );
                },
            },
            {
                Header: "",
                id: "action2",
                accessor: "amount",
                Cell: (data: any) => {
                    const msa: ManualSetAsideFailure = data.cell.row.original;
                    return <LocationManualTransactionsModal locationId={msa.locationId} />;
                },
            },
            {
                Header: "",
                accessor: "name",
            },
        ];
    }, []);

    return (
        <>
            <Select<string> title="Year/Month" options={getYearMonthList()} value={yearMonth} onChange={setYearMonth} />

            <Select<ManualSetAsideType>
                title="Type"
                options={getTypeList()}
                value={type}
                onChange={setType}
                noneLabel="Any Type"
                label={(val) => initialCap(val)}
            />

            <ReactTable
                title="Rejected transactions"
                columns={columns}
                data={results}
                refreshData={reRender}
                options={{
                    pageSize: 10,
                    hiddenColumns: ["name"],
                }}
            />
        </>
    );
}
