import { IconButton } from "@mui/material";
import React from "react";
import pmImage from "./resources/postmark.png";

export function PostmarkSuppressionsLink() {
    const url = `https://account.postmarkapp.com/servers/5316258/streams/outbound/suppressions`;
    return (
        <div
            style={{
                display: "inline-block",
                verticalAlign: "0.6em",
            }}>
            <IconButton
                aria-label="new window"
                onClick={() => {
                    window.open(url, "_blank");
                }}
                size="large">
                <img src={pmImage} style={{ width: "22px" }} alt={"Postmark Suppressions"} />
            </IconButton>
        </div>
    );
}
