import { del, get, post } from "@davo/portal-common";
import { AppTypes, IPermission } from "@davo/types";

export async function getPermissions() {
    return get<IPermission[]>(`api/ops/permissions`, true);
}

export async function getPermissionsWithFilter(appFilter: AppTypes) {
    return get<IPermission[]>(`api/ops/permissions/app/${appFilter}`, true);
}

export async function getPermissionsForCurrentUser() {
    return get<IPermission[]>(`api/ops/permissions/current-user`, true);
}

export async function getPermissionsForUser(userId: string) {
    return get<IPermission[]>(`api/ops/permissions/user/${userId}`, true);
}

export async function createUserPermissions(userId: string, selectedPermissions: string[]) {
    return post(`api/ops/permissions/user/${userId}`, { selectedPermissions }, true);
}

export async function removeUserPermissions(userId: string, permissionTag: string) {
    return del(`api/ops/permissions/${permissionTag}/user/${userId}`, true);
}
