import { d30ToastError, useModalEditor } from "@davo/portal-common";
import { RelatedLocation } from "@davo/types";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import { Button, Tooltip } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { LocationExtractionWizard } from "./LocationExtractionWizard";

export interface IExtractLocationButton {
    id: { accountId?: string; invitationId?: string };
    relatedLocations: RelatedLocation[];
    locationId: string;
    locationName: string;
}

export const ExtractLocationButton: FunctionComponent<IExtractLocationButton> = ({
    id,
    relatedLocations,
    locationId,
    locationName,
}) => {
    const [mergeType, setMergeType] = useState<"Redeem" | "Extract">();

    const [showExtractionWizard, extractionWizardProps] = useModalEditor<string>();

    useEffect(() => {
        if ((id.accountId && id.invitationId) || (!id.accountId && !id.invitationId)) {
            d30ToastError(`Must pass either an invitationId or accountId as source to be merged`);
        } else if (id.accountId) {
            setMergeType("Extract");
        } else if (id.invitationId) {
            setMergeType("Redeem");
        }
    }, [id]);

    return (
        <div data-testid={"extractLocationButtonContainer"}>
            <Tooltip
                placement={"top"}
                title={
                    <div>
                        In order to preserve records, please ensure you are extracting all locations that share a bank
                        account, to your target account.
                    </div>
                }>
                <Button
                    data-testid={"extractLocationButton"}
                    variant="outlined"
                    color="warning"
                    size={"small"}
                    startIcon={<MergeTypeIcon />}
                    onClick={() => {
                        showExtractionWizard();
                    }}>
                    {mergeType} location
                </Button>
            </Tooltip>
            {extractionWizardProps.isDialogOpen && (
                <LocationExtractionWizard
                    relatedLocations={relatedLocations}
                    mergeType={mergeType}
                    id={id}
                    locationId={locationId}
                    locationName={locationName}
                    {...extractionWizardProps}
                />
            )}
        </div>
    );
};
