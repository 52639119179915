import { MultiSelect } from "@davo/portal-common";
import { finalizedPenaltyStatuses, PenaltyStatus, PenaltyStatuses, PenaltyStatusKeys } from "@davo/types";
import { Checkbox, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { PenaltyTable } from "./PenaltyTable";

export function PenaltiesPane() {
    const match = useMatch("/penalties/:penaltyId");
    const penaltyId = match?.params?.penaltyId;

    const [excludeOldPenalties, setExcludeOldPenalties] = useState<boolean>(true);
    const [statuses, setStatuses] = useState<PenaltyStatus[]>(
        PenaltyStatusKeys.filter((obj: string) => !finalizedPenaltyStatuses.includes(obj))
    );

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Stack direction="row">
                    <Stack direction="column">
                        <Typography>Statuses</Typography>
                        <MultiSelect<PenaltyStatus>
                            options={Object.keys(PenaltyStatuses).map((x) => x as PenaltyStatus)}
                            value={statuses}
                            label={(s) => {
                                return <Typography>{PenaltyStatuses[s]}</Typography>;
                            }}
                            onChange={(stats) => {
                                setStatuses(stats);
                            }}
                        />
                    </Stack>
                    <Stack direction="column">
                        <Typography>Exclude old penalties</Typography>
                        <Tooltip title={"Older than 60 days"}>
                            <Checkbox
                                checked={excludeOldPenalties}
                                onChange={() => setExcludeOldPenalties(!excludeOldPenalties)}
                            />
                        </Tooltip>
                    </Stack>
                </Stack>
            </div>
            <div style={{ width: "100%" }}>
                <PenaltyTable
                    statuses={statuses}
                    excludeOldPenalties={excludeOldPenalties}
                    penaltyId={penaltyId}
                    hideAccountColumns={false}
                    showWells={true}
                />
            </div>
        </>
    );
}
