import {
    DEFAULT_SEARCH_DELAY,
    MIN_SEARCH_CHAR_THRESHOLD,
    debounce,
    formatAccountSearchResults,
    getSelectTheme,
} from "@davo/portal-common";
import { RecentlyViewedAccounts } from "@davo/portal-common/lib/RecentlyViewedAccounts";
import { AccountSearchRecord } from "@davo/types";
import { Checkbox, FormControlLabel, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import useAsyncEffect from "use-async-effect";
import { AccountOwnerFilter } from "./AccountOwner";
import { getAllAccountsForSearchTerm, getRecentAccounts } from "./services";

export function OpsAccountSearch() {
    const navigate = useNavigate();
    const themeMUI: Theme = useTheme();
    const [recentlyViewed, setRecentlyViewed] = useState<string[]>();
    const [isFilterByOwner, setIsFilterByOwner] = useState<boolean>(false);
    const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
    const abortControllerRef = useRef<AbortController>();
    const formThemeColors = getSelectTheme(themeMUI);

    useAsyncEffect(async () => {
        setRecentlyViewed(await getRecentAccounts());
    }, []);

    const loadResults = async (input: string, callback: (options: AccountSearchRecord[]) => void) => {
        const trimmedValue = input && input.trimStart();

        if (trimmedValue.length >= MIN_SEARCH_CHAR_THRESHOLD) {
            abortControllerRef.current?.abort(`Abort request in favor of new search query "${trimmedValue}"`);
            abortControllerRef.current = new AbortController();

            const results = await getAllAccountsForSearchTerm<AccountSearchRecord>(
                {
                    searchTerm: trimmedValue,
                    advancedSearch: isAdvancedSearch,
                    excludeAccountId: undefined,
                    accountOwner: undefined,
                },
                abortControllerRef.current
            ).catch((e) => {
                if (e.name === "AbortError") {
                    console.debug(e.message); // eslint-disable-line no-console
                }
            });

            if (results && results.length === 1) {
                handleSelectionChange(results[0]);
            }
            if (results !== undefined) {
                abortControllerRef.current = undefined;
            }
            callback(results ?? []);
        } else {
            callback([]);
        }
    };

    const debouncedLoadResults = debounce(loadResults, DEFAULT_SEARCH_DELAY);

    const handleSelectionChange = (selectedOption: any) => {
        navigate(`/accounts/${selectedOption.id}`);
    };

    const MenuList = (props: any) => {
        return (
            <components.MenuList {...props} className={"accounts-search-results-list"}>
                {props.children}
                {props.options.length <= 8 ? null : (
                    <div style={{ textAlign: "center" }}>{`Showing first 8 results, narrow search`}</div>
                )}
            </components.MenuList>
        );
    };

    return (
        <>
            <div data-testid={"search-accounts-container"}>
                <h2>Search For Account</h2>
                <div style={{ display: "inline-flex", marginTop: "5px", marginBottom: "5px", marginLeft: "-10px" }}>
                    <Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isFilterByOwner}
                                    onChange={() => setIsFilterByOwner(!isFilterByOwner)}
                                    data-testid={"filter-by-owner"}
                                />
                            }
                            label="Filter By Account Owner"
                        />
                    </Typography>
                    {!isFilterByOwner && (
                        <Typography>
                            <Tooltip
                                title={`Allows searching by Account ID, Location ID, Address, External Location ID, and Federal/State Tax IDs`}
                                placement="right">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAdvancedSearch}
                                            onChange={() => setIsAdvancedSearch(!isAdvancedSearch)}
                                        />
                                    }
                                    label="Advanced Search"
                                />
                            </Tooltip>
                        </Typography>
                    )}
                </div>

                {!isFilterByOwner && (
                    <AsyncSelect
                        id={"opsAccountSearchContainer"}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...formThemeColors,
                            },
                        })}
                        components={{ MenuList }}
                        onChange={handleSelectionChange}
                        formatOptionLabel={(x) => {
                            return formatAccountSearchResults(x);
                        }}
                        placeholder={"Enter search term..."}
                        defaultOptions={false}
                        cacheOptions={false}
                        autoFocus
                        tabSelectsValue={false}
                        loadOptions={debouncedLoadResults}
                        closeMenuOnSelect={false}
                        noOptionsMessage={() => "No results"}
                    />
                )}
                <AccountOwnerFilter show={isFilterByOwner} />
            </div>
            {recentlyViewed && <RecentlyViewedAccounts recentlyViewed={recentlyViewed} />}
        </>
    );
}
