import { DisconnectData, OptionalString } from "@davo/types";
import { DateTime } from "luxon";
import { AddNoteModal } from "./AddNoteModal";
import { setDisconnectNote } from "./services";

export function DisconnectAddNoteModal({ d }: { d: DisconnectData }) {
    function toNoteable(data: DisconnectData) {
        return {
            id: data.locationId,
            note: data.disconnectNote,
            noteBy: data.disconnectNoteBy,
            noteByName: data.disconnectNoteByName,
            noteDate: data.disconnectNoteDate,
        };
    }

    const updater = async (
        id: string,
        note: OptionalString,
        noteBy: OptionalString,
        noteByName: OptionalString,
        noteDate: DateTime | undefined | null
    ) => {
        await setDisconnectNote(id, note);

        d.disconnectNote = note;
        d.disconnectNoteBy = noteBy;
        d.disconnectNoteByName = noteByName;
        d.disconnectNoteDate = noteDate;
    };
    return AddNoteModal({ noteable: toNoteable(d), updater });
}
