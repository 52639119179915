import {
    ConfirmationModal,
    d30ToastError,
    getJurisdictionByStateCode,
    getJurisdictionDefaultFilingType,
    getJurisdictionFilingType,
    getJurisdictionRateTypes,
    getTaxProfile,
    Header,
    ReactTable,
    renderRate,
    TextField,
    useModalEditor,
} from "@davo/portal-common";
import {
    IJurisdiction,
    IJurisdictionFilingType,
    IJurisdictionRateType,
    TaxProfileTaxRate,
    TaxRateInclusionType,
} from "@davo/types";
import { Autocomplete, Button, Checkbox } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { MarketplaceFacilitatorRules } from "./MarketplaceFacilitatorRules";
import { TaxProfileTaxRateLookup } from "./TaxProfileTaxRateLookup";

export interface ITaxProfileTaxRateVerificationFormData {
    accountId: string;
    taxProfileId: string;
    taxProfileTaxRates: TaxProfileTaxRate[];
    state: string;
    filingIdForContinue?: string;
    onSubmit: (data: TaxProfileTaxRate[]) => void;
    onCancel: () => void;
    isBusyVerifyingRates: boolean;
}

const colors = {
    unverified: "#FFAB2B",
    included: "#6DD230",
    precluded: "#FF4D97",
} as { [key: string]: string };

const _organizeRates = (taxRates: TaxProfileTaxRate[]) => {
    const unverifiedRates = taxRates
        .filter((t) => t.included === "unverified")
        .sort((a, b) => a.taxName.localeCompare(b.taxName));
    const excludedRates = taxRates
        .filter((t) => t.included === "precluded")
        .sort((a, b) => a.taxName.localeCompare(b.taxName));
    const includedRates = taxRates
        .filter((t) => t.included === "included")
        .sort((a, b) => a.taxName.localeCompare(b.taxName));
    return [...unverifiedRates, ...excludedRates, ...includedRates];
};

const _checkForInclusion = (rates: TaxProfileTaxRate[]) => {
    const res: TaxProfileTaxRate[] = [];
    let isDefaultIncluded = true;
    // if there is only one rate, we still want to include it by default, regardless of the feature flag
    if (rates.length > 1) {
        isDefaultIncluded = false;
    }
    for (const rate of rates) {
        if (rate.included === "unverified") {
            rate.included = isDefaultIncluded ? "included" : "precluded";
        }
        res.push(rate);
    }
    return res;
};

export const TaxProfileTaxRateVerificationForm = ({
    accountId,
    taxProfileId,
    taxProfileTaxRates,
    state,
    filingIdForContinue,
    onSubmit,
    onCancel,
    isBusyVerifyingRates,
}: ITaxProfileTaxRateVerificationFormData) => {
    const [originalRates, setOriginalRates] = useState<TaxProfileTaxRate[]>([]);
    const [rates, setRates] = useState<TaxProfileTaxRate[]>([]);
    const [jurisdiction, setJurisdiction] = useState<IJurisdiction>();
    const [filingType, setFilingType] = useState<IJurisdictionFilingType>();
    const [rateTagsForState, setRateTagsForState] = useState<IJurisdictionRateType[]>();

    useEffect(() => {
        const organizedRates = _organizeRates(taxProfileTaxRates);
        setOriginalRates(organizedRates);
        setRates(organizedRates);
    }, [taxProfileTaxRates]);

    useAsyncEffect(async () => {
        if (!accountId || !taxProfileId) {
            return;
        }
        const taxProfile = await getTaxProfile(accountId, taxProfileId);
        if (taxProfile?.jurisdictionFilingTypeId) {
            setFilingType(await getJurisdictionFilingType(taxProfile.jurisdictionFilingTypeId));
        } else {
            setFilingType(await getJurisdictionDefaultFilingType(taxProfile.state));
        }
    }, [accountId, taxProfileId]);

    useAsyncEffect(async () => {
        if (state) {
            setJurisdiction(await getJurisdictionByStateCode(state));
        } else {
            setRateTagsForState(undefined);
        }
    }, [state]);

    useAsyncEffect(async () => {
        if (jurisdiction) {
            const rateTypes = await getJurisdictionRateTypes(jurisdiction.id);
            setRateTagsForState(sortBy(rateTypes, ["name"]));
        }
    }, [jurisdiction]);

    const [showConfirmationModal, confirmationModalProps] = useModalEditor<string>((tar?: string) => {
        if (tar) {
            _doUpdate();
        }
    });

    // NOTE: see ConfirmationModal for an explanation of this pattern
    const _doUpdate = useCallback(() => {
        if (isBusyVerifyingRates) return;
        const res = _checkForInclusion(rates);
        onSubmit(res);
        setOriginalRates(rates);
    }, [isBusyVerifyingRates, onSubmit, rates]);

    const _doSave = useCallback(() => {
        if (isBusyVerifyingRates) return;
        // rates that are not explicitly included should be switched to included
        const res = _checkForInclusion(rates);
        if (
            filingType &&
            filingType.rateTagsRequired &&
            rateTagsForState &&
            rateTagsForState.some((rateType) => rateType.active) &&
            rates.some((x) => x.included === "included" && !x.jurisdictionRateTypeId)
        ) {
            d30ToastError("All included rates require a type in this state");
        } else {
            if (!res.some((x) => x.included === "included")) {
                showConfirmationModal(filingIdForContinue);
            } else {
                _doUpdate();
            }
        }
    }, [
        _doUpdate,
        filingIdForContinue,
        filingType,
        isBusyVerifyingRates,
        rateTagsForState,
        rates,
        showConfirmationModal,
    ]);

    const _doContinueWithoutSaving = useCallback(() => {
        setRates(originalRates);
        onCancel();
    }, [onCancel, originalRates]);

    const _doUpdateAll = useCallback(() => {
        let newList;
        if (rates.some((x) => x.included === "precluded")) {
            newList = rates.map((item) => {
                return {
                    ...item,
                    included: "included" as TaxRateInclusionType,
                };
            });
        } else {
            newList = rates.map((item) => {
                return {
                    ...item,
                    included: "precluded" as TaxRateInclusionType,
                };
            });
        }
        setRates(newList);
    }, [rates]);

    const _handleToggleIncluded = useCallback(
        (data: any) => {
            const newList = rates.map((item) => {
                if (
                    item.taxProfileId === data.taxProfileId &&
                    item.taxName === data.taxName &&
                    item.taxRate === data.taxRate &&
                    item.locationId === data.locationId
                ) {
                    return {
                        ...item,
                        included: (data.included === "included" ? "precluded" : "included") as TaxRateInclusionType,
                    };
                }
                return item;
            });
            setRates(newList);
        },
        [rates]
    );

    const columns = useMemo(() => {
        const _includedFormat = (data: any) => {
            if (data.value === "included") {
                return (
                    <div
                        style={{
                            borderRadius: "4px",
                            fontWeight: "bold",
                            color: colors["included".toLowerCase()],
                        }}>
                        Included
                    </div>
                );
            } else if (data.value === "unverified") {
                return (
                    <div
                        style={{
                            borderRadius: "4px",
                            fontWeight: "bold",
                            color: colors["unverified".toLowerCase()],
                        }}>
                        Precluded (Unverified)
                    </div>
                );
            } else {
                return (
                    <div
                        style={{
                            borderRadius: "4px",
                            fontWeight: "bold",
                            color: colors["precluded".toLowerCase()],
                        }}>
                        Not Included
                    </div>
                );
            }
        };

        const _renderActions = (data: any) => (
            <>
                <div style={{ marginTop: "4px", marginRight: "20px" }}>
                    <Checkbox
                        checked={data.cell.row.original.included === "included"}
                        style={{ padding: "2px", paddingTop: "4px" }}
                        onChange={() => {
                            _handleToggleIncluded(data.cell.row.original);
                        }}
                        indeterminate={data.cell.row.original.included === "unverified"}
                    />
                </div>
            </>
        );

        const _getValueForRow = (data: TaxProfileTaxRate) => {
            if (data.jurisdictionRateTypeId) {
                return rateTagsForState?.find((x) => x.id === data.jurisdictionRateTypeId);
            }
            return undefined;
        };

        const _handleUpdateJurisdictionRateType = (data: TaxProfileTaxRate, newValue: IJurisdictionRateType | null) => {
            const newList = rates.map((item) => {
                if (
                    item.taxProfileId === data.taxProfileId &&
                    item.taxName === data.taxName &&
                    item.taxRate === data.taxRate &&
                    item.locationId === data.locationId
                ) {
                    return {
                        ...item,
                        jurisdictionRateTypeId: newValue && !isNil(newValue) ? newValue.id : undefined,
                    };
                }
                return item;
            });
            setRates(newList);
        };

        const _renderJurisdictionRateTypeSelector = (data: TaxProfileTaxRate) => {
            if (rateTagsForState && !isEmpty(rateTagsForState)) {
                return (
                    <>
                        <div style={{ marginTop: "4px", marginRight: "20px" }}>
                            <Autocomplete
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            style={{ minWidth: "500px" }}
                                            data-testid={"rateTypeSelectorInputContainer"}
                                            inputProps={{
                                                ...params.inputProps,
                                                [`data-testid`]: "rateTypeSelectorInput",
                                            }}
                                            label={""}
                                            value={params.inputProps.value || undefined}
                                        />
                                    );
                                }}
                                data-testid={"rateTypeDropdown"}
                                options={rateTagsForState.filter(
                                    (rateType) =>
                                        rateType.active ||
                                        (!rateType.active && data.jurisdictionRateTypeId === rateType.id)
                                )}
                                value={_getValueForRow(data)}
                                getOptionLabel={(option) => (option && option.name ? option.name : "")}
                                onChange={(event, ba) => {
                                    if (["blur"].includes(event.type)) {
                                        return;
                                    }
                                    if (["click", "keydown"].includes(event.type)) {
                                        _handleUpdateJurisdictionRateType(data, ba);
                                    }
                                }}
                                disabled={false}
                                disablePortal={false}
                                autoSelect
                                id={"selectedRateTypeInput"}
                            />
                        </div>
                    </>
                );
            } else {
                return null;
            }
        };

        const cols = [];
        if (taxProfileTaxRates.some((tr) => tr.state)) {
            cols.push({
                Header: "State",
                accessor: "state",
                id: "state",
                Cell: (data: any) => (
                    <div style={{ marginTop: "4px", marginRight: "20px" }}>{data.cell.row.original.state}</div>
                ),
            });
        }
        cols.push({
            Header: "Tax Name",
            accessor: "taxName",
            id: "name",
            Cell: (data: any) => (
                <div style={{ marginTop: "4px", marginRight: "20px" }}>{data.cell.row.original.taxName}</div>
            ),
        });
        if (rateTagsForState && rateTagsForState.some((rateType) => rateType.active)) {
            cols.push({
                Header: "Rate Type",
                accessor: "jurisdictionRateTypeId",
                id: "rateType",
                Cell: (data: any) => {
                    return _renderJurisdictionRateTypeSelector(data.cell.row.original);
                },
            });
        }
        cols.push(
            {
                Header: "Tax Rate",
                accessor: "taxRate",
                Cell: (data: any) => {
                    return <>{renderRate(data.cell.row.original?.taxRate)}</>;
                },
            },
            { Header: "Included", accessor: "included", Cell: _includedFormat },
            { Header: "", accessor: "included", id: "actions", Cell: _renderActions }
        );
        return cols;
    }, [rates, _handleToggleIncluded, rateTagsForState, taxProfileTaxRates]);

    if (!filingType) {
        return null;
    }

    return (
        <div data-testid={"taxProfileTaxRateVerificationForm"}>
            <Header>Tax Rate Verification for: {filingType.name}</Header>
            <div className={"TPTRVerificationModal"} data-testid={"taxRateVerificationForm"}>
                <div style={{ backgroundColor: "rgba(255,255,255,.1)", padding: "4px", border: "1px solid" }}>
                    {jurisdiction ? (
                        <div style={{ marginTop: "10px", marginLeft: "30px" }}>
                            <MarketplaceFacilitatorRules
                                jurisdictionState={jurisdiction.abbreviatedName}
                                jurisdictionId={jurisdiction.id}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <ReactTable
                        columns={columns}
                        data={rates}
                        options={{
                            pageSize: rates && rates.length > 0 ? rates.length : 10000, // arbitrarily large number so that we don't have to cause a re-render, empty rows will not be shown
                            hideToolbar: true,
                        }}
                    />
                    {rates && rates.length > 1 && (
                        <Button
                            size="small"
                            onClick={_doUpdateAll}
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "10px", marginBottom: "10px" }}>
                            {"Select/Unselect All"}
                        </Button>
                    )}
                </div>

                <TaxProfileTaxRateLookup accountId={accountId} taxProfileId={taxProfileId} />

                {confirmationModalProps.isDialogOpen && (
                    <ConfirmationModal
                        message="Are you sure you want to remove all tax rates from this tax profile?"
                        title="Remove all tax rates?"
                        {...confirmationModalProps}
                        target={accountId}
                        zIndex={20000}
                    />
                )}
            </div>

            <div style={{ marginTop: "20px", float: "right" }}>
                <Button
                    onClick={_doContinueWithoutSaving}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "5px" }}>
                    {"Cancel"}
                </Button>
                <Button
                    data-testid={"saveRateVerificationFormBtn"}
                    disabled={isEmpty(rates) || isBusyVerifyingRates}
                    startIcon={
                        isBusyVerifyingRates && (
                            <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                        )
                    }
                    onClick={_doSave}
                    variant="contained"
                    color="secondary">
                    {"Save"}
                </Button>
            </div>
        </div>
    );
};
