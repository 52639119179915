import { IFilingRow, toDisplayDateTimeString } from "@davo/types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

interface IRenderReviewedIcon {
    ua: IFilingRow;
}

const LargeTooltip = withStyles(() => ({
    tooltip: {
        fontSize: "14px",
    },
}))(Tooltip);

export const RenderReviewedIcon: React.FunctionComponent<IRenderReviewedIcon> = ({ ua }) => {
    if (ua.reviewed) {
        return (
            <LargeTooltip
                title={[
                    <div key={`review_note_${ua.id}`}>{ua.reviewNote}</div>,
                    <div key={`review_name_${ua.id}`}>{`${ua.details.reviewedUser?.firstName} ${
                        ua.details.reviewedUser?.lastName
                    } @ ${toDisplayDateTimeString(ua.reviewed)}`}</div>,
                ]}
                placement="top">
                <VisibilityIcon style={{ fill: "grey" }} />
            </LargeTooltip>
        );
    } else {
        return null;
    }
};
