import { get, getArray, getOne, post, put } from "@davo/portal-common";
import {
    IEmailMessage,
    MetaSmsMessageDisplay,
    MetaUser,
    SmsMessageDisplay,
    User,
    toJSON,
    toPartial,
} from "@davo/types";

export async function getAllUsersForSearchTerm(searchTerm: string) {
    return post(`api/ops/users/search`, { searchTerm }, true);
}

export async function checkUserLockStatus(userId: string) {
    return get<boolean>(`api/ops/users/${userId}/lockstatus`, true);
}

export async function unlockUser(userId: string) {
    await put(`api/ops/users/${userId}/unlock`, {}, true);
}

export async function getUser(userId: string) {
    return getOne(`api/ops/users/${userId}/`, true, MetaUser);
}

export async function createOpsUser(
    firstName: string | undefined,
    lastName: string | undefined,
    email: string,
    phone: string | undefined
) {
    return post<User>(`api/ops/users/create`, { firstName, lastName, email, phone }, true);
}

export async function getAllUsers(): Promise<User[]> {
    return getArray("api/ops/users", true, MetaUser);
}

export async function updateUser(userId: string, update: Partial<User>) {
    return put(`api/ops/users/${userId}`, toJSON(toPartial(MetaUser), update), true);
}

export async function resumeUserEmail(userId: string) {
    return post<User>(`api/ops/users/${userId}/resume-email`, {}, true);
}

export async function resumeUserSMS(userId: string) {
    return post<User>(`api/ops/users/${userId}/resume-sms`, {}, true);
}

export async function getAllStaff(): Promise<User[]> {
    return getArray("api/ops/users/staff", true, MetaUser);
}

export async function getStaffWithFilingPermission(): Promise<User[]> {
    return getArray(`api/ops/users/staff/withPermission/filing`, true, MetaUser);
}

export async function getSmsForUser(userId: string): Promise<SmsMessageDisplay[]> {
    return getArray(`api/ops/users/sms/${userId}`, true, MetaSmsMessageDisplay);
}

export async function getSmsMessageHistory(opsId: string): Promise<SmsMessageDisplay[]> {
    return getArray(`api/ops/users/sms/${opsId}/history`, true, MetaSmsMessageDisplay);
}

export async function sendSmsMessage(
    recipientId: string,
    message: string,
    type: "user" | "invitation",
    shouldSendNow?: boolean
) {
    return post<any>(`api/ops/users/sms`, { recipientId, message, type, sendNow: shouldSendNow }, true);
}

export async function cancelSmsMessage(smsId: string): Promise<SmsMessageDisplay> {
    return post<SmsMessageDisplay>(`api/ops/users/sms/${smsId}/cancel`, {}, true);
}

export async function getEmailHistory(userId: string, month?: string, emailType?: string): Promise<IEmailMessage[]> {
    return post<IEmailMessage[]>(
        `api/ops/users/${userId}/email/history`,
        {
            month,
            emailType,
        },
        true
    );
}

export async function getEmailTypes(): Promise<string[]> {
    return get<string[]>(`api/ops/users/email/types`, true);
}
