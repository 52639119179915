import { d30ToastError, LinkButton, Loading, ReactTable } from "@davo/portal-common";
import { DisconnectData, truncateString } from "@davo/types";
import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DisconnectAddNoteModal } from "./DisconnectAddNote";
import { formatDisconnectedDate } from "./DisconnectsPane";
import { EditDisconnectReasonButton } from "./EditDisconnectReasonButton";
import { RelatedMidTrans } from "./RelatedMidTrans";
import { getDisconnectsWithPendingFunds } from "./services";

export function DisconnectsPendingFunds() {
    const [discountsPendingFunding, setDiscountsPendingFunding] = useState<DisconnectData[]>();

    const refreshPendingFunds = useCallback(() => {
        setDiscountsPendingFunding(undefined);

        getDisconnectsWithPendingFunds()
            .then((results) => setDiscountsPendingFunding(results))
            .catch((e) => d30ToastError("Error loading disconnects", e));
    }, []);

    useEffect(() => {
        refreshPendingFunds();
    }, [refreshPendingFunds]);

    const columnsPendingFunding = useMemo(() => {
        return [
            {
                Header: "Disconnected",
                accessor: "disconnectedDate",
                Cell: (data: any) => (
                    <>
                        {formatDisconnectedDate(data.value)}
                        <div style={{ whiteSpace: "nowrap" }}>
                            <DisconnectAddNoteModal d={data.row.original} />
                            <RelatedMidTrans potentialMidTrans={data.row.original.potentialMidTrans} />
                        </div>
                    </>
                ),
            },
            {
                Header: "Account",
                accessor: "accountName",
                Cell: (data: any) => (
                    <LinkButton
                        url={`/accounts/${data.row.original.accountId}`}
                        label={data.value}
                        labelMaxChars={35}
                        align={"left"}
                    />
                ),
            },
            {
                Header: "Location",
                accessor: "locationName",
                Cell: (data: any) => <Typography>{truncateString(data.value, 35)}</Typography>,
            },
            {
                Header: "Disconnect Reason",
                accessor: "disconnectReasonCode",
                Cell: (data: any) => {
                    return (
                        <EditDisconnectReasonButton
                            disconnectNote={data.row.original.disconnectReasonNote}
                            disconnectCode={data.row.original.disconnectReasonCode}
                            locationId={data.row.original.locationId}
                            onClose={refreshPendingFunds}
                        />
                    );
                },
            },
        ];
    }, [refreshPendingFunds]);

    return (
        <>
            {!discountsPendingFunding && <Loading />}
            {discountsPendingFunding && (
                <ReactTable
                    columns={columnsPendingFunding}
                    data={discountsPendingFunding}
                    refreshData={refreshPendingFunds}
                />
            )}
        </>
    );
}
