import { useGlobalOptional, useLoginContext } from "@davo/portal-common";
import { AutofilerQueueEntry, BusinessDay, FilingFrequency } from "@davo/types";
import { Button } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { fileAutofileRows, testAutofileRows } from "../services";
import { hasPermission } from "../util";
import { canFileAutofile, canTestAutofile, isBulkProcessing } from "./AutofileUtils";

export interface IFilingSlimmed {
    id: string;
    jurisdiction: string;
    frequency: FilingFrequency;
    canAutofile: boolean;
    prepayment?: boolean | null;
    filingDueDate: BusinessDay;
    hasUnsettled: boolean;
    bulkTaskId?: string | null;
}

export interface IAutofilerButtonsBulkType {
    filings?: IFilingSlimmed[];
    refreshFilings: () => void;
}

export function AutofilerButtonsMulti({ filings, refreshFilings }: IAutofilerButtonsBulkType) {
    const [autoFilerMap] = useGlobalOptional<{ [key: string]: AutofilerQueueEntry }>("autoFilerMap");
    const loginContext = useLoginContext();

    const [filingsToTest, setFilingsToTest] = useState<IFilingSlimmed[]>([]);
    const [filingsToFile, setFilingsToFile] = useState<IFilingSlimmed[]>([]);

    useEffect(() => {
        if (!filings || !autoFilerMap) {
            return;
        }
        const toTest = filings.filter(
            (filing) =>
                !isBulkProcessing(filing.bulkTaskId, autoFilerMap[filing.id]) &&
                canTestAutofile(filing.canAutofile, autoFilerMap[filing.id])
        );
        setFilingsToTest(toTest);
    }, [autoFilerMap, filings]);

    useEffect(() => {
        if (!filings || !autoFilerMap) {
            return;
        }
        const toFile = filings.filter(
            (filing) =>
                !isBulkProcessing(filing.bulkTaskId, autoFilerMap[filing.id]) &&
                canFileAutofile(filing, autoFilerMap[filing.id])
        );
        setFilingsToFile(toFile);
    }, [autoFilerMap, filings]);

    const _handleTestSelected = async () => {
        await testAutofileRows(filingsToTest.map((x: any) => x.id));
        refreshFilings();
    };

    const _handleFileSelected = async () => {
        await fileAutofileRows(filingsToFile.map((x: any) => x.id));
        refreshFilings();
    };

    if (!filings || !autoFilerMap || isEmpty(filings)) {
        return null;
    }

    return (
        <>
            {filingsToTest.length !== 0 && (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: "5px" }}
                    onClick={async () => {
                        await _handleTestSelected();
                    }}>
                    Test Selected Filings
                </Button>
            )}
            {hasPermission(loginContext.permissions, "can_autofile") && filingsToFile.length !== 0 && (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: "5px" }}
                    onClick={async () => {
                        await _handleFileSelected();
                    }}>
                    File Selected Filings
                </Button>
            )}
        </>
    );
}
