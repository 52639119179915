import { IFilingRow, initialCap } from "@davo/types";
import { Theme } from "@mui/material";
import React from "react";
import { colors, darkColors } from "./utils";

interface IRenderStatus {
    row: IFilingRow;
    theme: Theme;
}

export const RenderStatus: React.FunctionComponent<IRenderStatus> = ({ row, theme }) => {
    if (theme.palette.mode === "dark") {
        return (
            <div
                style={{
                    borderRadius: "4px",
                    textAlign: "left",
                    color: darkColors[row.status],
                    fontWeight: "bold",
                    padding: "7px",
                }}
                title={row.details.note ?? ""}>
                {initialCap(row.status)}
            </div>
        );
    } else {
        return (
            <div
                style={{
                    borderRadius: "4px",
                    textAlign: "left",
                    color: colors[row.status],
                    fontWeight: "bold",
                    padding: "7px",
                }}
                title={row.details.note ?? ""}>
                {initialCap(row.status)}
            </div>
        );
    }
};
