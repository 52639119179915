import { d30Toast, PaperComponent } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { sendTaxProfileCredentialsFailureNotification } from "./services";

const sendEmail = async (taxProfileId: string) => {
    await sendTaxProfileCredentialsFailureNotification(taxProfileId);
};

export function ResendFailedTaxCredentialsModal({ taxProfileId }: { taxProfileId: string }) {
    const [isShowing, setIsShowing] = useState<boolean>(false);

    const cancel = () => {
        setIsShowing(false);
    };

    const ok = async () => {
        await sendEmail(taxProfileId);
        d30Toast("Email sent");
        setIsShowing(false);
    };

    return (
        <div>
            <Button
                variant="contained"
                onClick={() => {
                    setIsShowing(true);
                }}
                size="small"
                color="primary">
                Resend
            </Button>

            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Resend Failed Tax Credentials Email</DialogTitle>
                    <DialogContent></DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="secondary" onClick={() => cancel()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => ok()}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
