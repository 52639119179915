import {
    DavoLink,
    LinkButton,
    Loading,
    ReactTable,
    d30ToastError,
    getUserPermissionsForAccount,
} from "@davo/portal-common";
import { User } from "@davo/types";
import ChatIcon from "@mui/icons-material/Chat";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HubSpotLink } from "./HubSpotLink";
import { getAllUsers } from "./services";
import { displayPhone } from "./util";

export function UsersTable({ accountId, showAll }: { accountId?: string; showAll: boolean }) {
    const [users, setUsers] = useState<User[]>();
    const theme = useTheme();
    const shouldHideColumn = useMediaQuery(theme.breakpoints.down("sm"));

    const refresh = useCallback(() => {
        setUsers(undefined);
        if (accountId) {
            getUserPermissionsForAccount(accountId)
                .then((results: User[]) => {
                    setUsers(results);
                })
                .catch((e: any) => d30ToastError("Problem getting users for account.", e));
        } else if (showAll) {
            getAllUsers()
                .then((results) => {
                    setUsers(results);
                })
                .catch((e) => d30ToastError("Problem getting users.", e));
        }
    }, [accountId, showAll]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const getHiddenColumns = useCallback(() => {
        if (!shouldHideColumn) {
            return ["lastName"];
        }
        return ["lastName", "type", "phone", "email"];
    }, [shouldHideColumn]);

    const columns = useMemo(() => {
        const renderName = (data: any) => {
            return (
                <>
                    <LinkButton
                        url={`/users/${data.cell.row.original.id}`}
                        label={`${data.cell.row.original.firstName ?? ""} ${data.cell.row.original.lastName ?? ""}`}
                        labelMaxChars={35}
                    />
                </>
            );
        };

        const renderSms = (data: any) => {
            if (!data.cell.row.original.doNotSms && data.cell.row.original.phone) {
                return (
                    <>
                        <DavoLink to={`/users/${data.cell.row.original.id}?sms=true`}>
                            <ChatIcon />
                        </DavoLink>
                    </>
                );
            } else {
                return null;
            }
        };

        const renderEmail = (data: any) => {
            return (
                <Typography color={"primary"}>
                    <a href={`mailto:${data.value}`} style={{ color: "inherit" }}>
                        {data.value}
                    </a>
                </Typography>
            );
        };

        return [
            {
                Header: "Name",
                accessor: "firstName",
                Cell: renderName,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: renderEmail,
            },
            {
                Header: "Phone",
                accessor: "phone",
                Cell: (x: any) => displayPhone(x.value),
            },
            {
                Header: "Last name",
                accessor: "lastName",
            },
            {
                Header: "",
                accessor: "id",
                Cell: (x: any) => <HubSpotLink type={"user"} davoId={x.value} />,
            },
            {
                Header: "SMS",
                Cell: renderSms,
            },
            {
                Header: "Type",
                accessor: "type",
            },
        ];
    }, []);

    if (!users) {
        return <Loading />;
    }

    return (
        <div style={{ width: "100%" }}>
            <ReactTable
                columns={columns}
                refreshData={refresh}
                options={{
                    pageSize: 10,
                    hiddenColumns: getHiddenColumns(),
                }}
                data={users}
            />
        </div>
    );
}
