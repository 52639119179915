import { LinkButton, NumberOnlyField, ReactTable, Select, TextField } from "@davo/portal-common";
import { FilingWithAccount, IJurisdiction, moneyFromCents, toDisplayDateString } from "@davo/types";
import { Button } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import { filingSearch } from "../services";
import { FilingAddWellsNoteModal } from "./FilingAddWellsNoteModal";
import { numberSorter } from "./Util";
import { WellsTransactionMatchFiling } from "./WellsTransactionMatchFiling";
import { WellsTransactionMatchIcon } from "./WellsTransactionMatchIcon";

const toCents = (val: number | undefined) => {
    return val ? val * 100 : val;
};

type FilingAndAmounts = FilingWithAccount & {
    remittedFormatted: string;
    remittedPart2Formatted: string;
    remittedPart3Formatted: string;
    remittedPart4Formatted: string;
    remittedPart5Formatted: string;
    outstandingBalance?: number;
    outstandingBalanceFormatted?: number;
    filedByName?: string;
};

export interface IFilingSearchType {
    states: IJurisdiction[];
}

export function FilingSearch({ states }: IFilingSearchType) {
    const [results, setResults] = useState<FilingAndAmounts[] | undefined>(undefined);

    const [fromAmount, setFromAmount] = useState<number>();
    const [toAmount, setToAmount] = useState<number>();
    const [fromDate, setFromDate] = useState<DateTime>(DateTime.now().minus({ month: 1 }));
    const [toDate, setToDate] = useState<DateTime>(DateTime.now());
    const [state, setState] = useState<IJurisdiction | undefined>();
    const [stateTaxId, setStateTaxId] = useState<string>();
    const [fein, setFein] = useState<string>();
    const [fromTaxDue, setFromTaxDue] = useState<number>();
    const [toTaxDue, setToTaxDue] = useState<number>();
    const [fromOutstandingBalance, setFromOutstandingBalance] = useState<number>();
    const [toOutstandingBalance, setToOutstandingBalance] = useState<number>();
    const [fromRemittedDirect, setFromRemittedDirect] = useState<number>();
    const [toRemittedDirect, setToRemittedDirect] = useState<number>();
    const [filer, setFiler] = useState<string | undefined>();

    const refreshResults = useCallback(async () => {
        const filings: any[] = (
            await filingSearch(
                fromDate,
                toDate,
                toCents(fromAmount),
                toCents(toAmount),
                state,
                fein,
                stateTaxId,
                toCents(fromTaxDue),
                toCents(toTaxDue),
                toCents(fromOutstandingBalance),
                toCents(toOutstandingBalance),
                toCents(fromRemittedDirect),
                toCents(toRemittedDirect),
                filer
            )
        ).map((f) => {
            return {
                ...f,
                remittedFormatted: moneyFromCents(f.remitted),
                remittedPart2Formatted: moneyFromCents(f.remittedPart2),
                remittedPart3Formatted: moneyFromCents(f.remittedPart3),
                remittedPart4Formatted: moneyFromCents(f.remittedPart4),
                remittedPart5Formatted: moneyFromCents(f.remittedPart5),
                remittedDirectFormatted: moneyFromCents(f.remittedDirect),
                outstandingBalanceFormatted: moneyFromCents(f.outstandingBalance),
                taxDueFormatted: moneyFromCents(f.taxDue),
            };
        });
        setResults(filings);
    }, [
        fein,
        filer,
        fromAmount,
        fromDate,
        fromOutstandingBalance,
        fromRemittedDirect,
        fromTaxDue,
        state,
        stateTaxId,
        toAmount,
        toDate,
        toOutstandingBalance,
        toRemittedDirect,
        toTaxDue,
    ]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Filed Date",
                accessor: "filedDate",
                Cell: (data: any) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                        <span>{toDisplayDateString(data.value)}</span>
                        <FilingAddWellsNoteModal filing={data.cell.row.original} />
                    </span>
                ),
            },
            {
                Header: "Filing",
                accessor: "accountName",
                Cell: (data: any) => (
                    <LinkButton
                        url={`/filings/${data.cell.row.original.id}`}
                        label={`${data.value}`}
                        labelMaxChars={35}
                        align={"left"}
                        target="_blank"
                        rel="noreferrer"
                    />
                ),
            },
            {
                Header: "Remitted",
                accessor: "remittedFormatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart1} onChange={refreshResults} />
                            {!filing.wfPart1 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remitted"}
                                    amount={filing.remitted}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remitted"),
            },
            {
                Header: "Remitted2",
                accessor: "remittedPart2Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart2} onChange={refreshResults} />
                            {!filing.wfPart2 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart2"}
                                    amount={filing.remittedPart2}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart2"),
            },
            {
                Header: "Remitted3",
                accessor: "remittedPart3Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart3} onChange={refreshResults} />
                            {!filing.wfPart3 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart3"}
                                    amount={filing.remittedPart3}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart3"),
            },
            {
                Header: "Remitted4",
                accessor: "remittedPart4Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart4} onChange={refreshResults} />
                            {!filing.wfPart4 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart4"}
                                    amount={filing.remittedPart4}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart4"),
            },
            {
                Header: "Remitted5",
                accessor: "remittedPart5Formatted",
                Cell: (data: any) => {
                    const filing = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right" }}>
                            ${data.value}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={filing.wfPart5} onChange={refreshResults} />
                            {!filing.wfPart5 && (
                                <WellsTransactionMatchFiling
                                    filingId={filing.id}
                                    field={"remittedPart5"}
                                    amount={filing.remittedPart5}
                                    onMatch={() => refreshResults()}
                                />
                            )}
                        </div>
                    );
                },
                sortType: numberSorter("remittedPart5"),
            },
            {
                Header: "Remitted Direct",
                accessor: "remittedDirectFormatted",
                sortType: numberSorter("remittedDirect"),
                Cell: (data: any) => {
                    return <div style={{ textAlign: "right" }}>{data.value}</div>;
                },
            },
            {
                Header: "State",
                accessor: "jurisdiction",
            },
            {
                Header: "Tax Due",
                accessor: "taxDueFormatted",
                sortType: numberSorter("taxDue"),
                Cell: (data: any) => {
                    return <div style={{ textAlign: "right" }}>{data.value}</div>;
                },
            },
            {
                Header: "Outstanding Balance",
                accessor: "outstandingBalanceFormatted",
                sortType: numberSorter("outstandingBalance"),
                Cell: (data: any) => {
                    return <div style={{ textAlign: "right" }}>{data.value}</div>;
                },
            },
            {
                Header: "Filer",
                accessor: "filedByName",
            },
        ];
    }, [refreshResults]);

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    label="From Date"
                    value={fromDate.toJSDate()}
                    closeOnSelect={true}
                    onChange={(date) => date && setFromDate(DateTime.fromJSDate(date))}
                    format="MM/dd/yyyy"
                    slotProps={{ textField: { style: { width: "100%", margin: "20px 0" } }, toolbar: { hidden: true } }}
                />

                <MobileDatePicker
                    label="To Date"
                    value={toDate.toJSDate()}
                    closeOnSelect={true}
                    onChange={(date) => date && setToDate(DateTime.fromJSDate(date))}
                    format="MM/dd/yyyy"
                    slotProps={{ textField: { style: { width: "100%", margin: "20px 0" } }, toolbar: { hidden: true } }}
                />
            </LocalizationProvider>
            <Select<IJurisdiction>
                title="State"
                options={states}
                value={state}
                onChange={setState}
                label={(s) => s?.fullName}
                noneLabel={"Any State"}
                showFullWidth={false}
            />

            <NumberOnlyField
                label={"From Remitted Amount"}
                value={fromAmount}
                onChange={setFromAmount}
                fullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <NumberOnlyField
                label={"To Remitted Amount"}
                value={toAmount}
                onChange={setToAmount}
                fullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <TextField
                label={"State Tax Id"}
                value={stateTaxId ?? ""}
                onChange={setStateTaxId}
                showFullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <TextField
                label={"FEIN"}
                value={fein ?? ""}
                onChange={setFein}
                showFullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <br />

            <NumberOnlyField label={"From Tax Due"} value={fromTaxDue} onChange={setFromTaxDue} fullWidth={false} />
            <NumberOnlyField
                label={"To Tax Due"}
                value={toTaxDue}
                onChange={setToTaxDue}
                fullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <br />

            <NumberOnlyField
                label={"From Outstanding Balance"}
                value={fromOutstandingBalance}
                onChange={setFromOutstandingBalance}
                fullWidth={false}
            />
            <NumberOnlyField
                label={"To Outstanding Balance"}
                value={toOutstandingBalance}
                onChange={setToOutstandingBalance}
                fullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <br />

            <NumberOnlyField
                label={"From Remitted Direct"}
                value={fromRemittedDirect}
                onChange={setFromRemittedDirect}
                fullWidth={false}
            />
            <NumberOnlyField
                label={"To Remitted Direct"}
                value={toRemittedDirect}
                onChange={setToRemittedDirect}
                fullWidth={false}
                style={{ marginLeft: "10px" }}
            />
            <br />

            <TextField label={"Filer"} value={filer ?? ""} onChange={setFiler} showFullWidth={false} />

            <br />
            <br />

            <Button variant="outlined" color="primary" onClick={refreshResults}>
                {"Search"}
            </Button>
            {results && (
                <>
                    <ReactTable
                        columns={columns}
                        data={results}
                        refreshData={refreshResults}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [10, 100],
                        }}
                    />
                </>
            )}
        </div>
    );
}
