import { d30Toast, TextField, useLoginContext, useModalEditor } from "@davo/portal-common";
import { Reconcile, toDisplayDateTimeString } from "@davo/types";
import NoteIcon from "@mui/icons-material/Note";
import NoteAddIcon from "@mui/icons-material/NoteOutlined";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { setReconcileNote } from "../services";

export function ReconcileNoteModal({ reconcile }: { reconcile: Reconcile }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const loginContext = useLoginContext();

    useEffect(() => {
        setNote(reconcile.note ?? "");
    }, [reconcile.note, isDialogOpen]);

    const persistNote = async () => {
        if (!loginContext.user) {
            return;
        }
        setIsBusy(true);
        try {
            await setReconcileNote(reconcile.filingId, reconcile.locationId, note);
            d30Toast("Note saved.");
            // NOTE: Update object as well so the UI updates w/o having to reretrieve the transaction
            reconcile.note = note;
            reconcile.noteBy = loginContext.user.id;
            reconcile.noteByName = `${loginContext.user.firstName ?? ""} ${loginContext.user.lastName ?? ""}`;
            reconcile.noteDate = DateTime.now();
            closeDialog();
        } finally {
            setIsBusy(false);
        }
    };

    const clearNote = async () => {
        setIsBusy(true);
        try {
            await setReconcileNote(reconcile.filingId, reconcile.locationId);
            d30Toast("Note removed.");
            // NOTE: Update object as well so the UI updates w/o having to reretrieve the transaction
            reconcile.note = undefined;
            reconcile.noteBy = undefined;
            reconcile.noteByName = undefined;
            reconcile.noteDate = undefined;
            closeDialog();
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <>
            <span
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    marginRight: "10px",
                }}>
                <Tooltip
                    title={
                        reconcile.note && reconcile.noteDate
                            ? `${reconcile.note} - ${reconcile.noteByName ?? ""} @ ${toDisplayDateTimeString(
                                  reconcile.noteDate
                              )}`
                            : "Add Note"
                    }>
                    <span>
                        <IconButton
                            aria-label="Add Note"
                            onClick={() => {
                                showDialog();
                            }}
                            size="large">
                            {!reconcile.note && <NoteAddIcon color="primary" />}
                            {reconcile.note && <NoteIcon color="primary" />}
                        </IconButton>
                    </span>
                </Tooltip>
            </span>

            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="responsive-dialog-title"> {reconcile.note ? "Update" : "Add"} Note</DialogTitle>
                    <DialogContent>
                        <Typography variant={"body1"}>
                            <TextField label={"Note"} value={note} onChange={setNote} isMultiline={true} />
                        </Typography>

                        {reconcile.note && reconcile.noteDate && (
                            <div>
                                Added by {reconcile.noteByName} @ {toDisplayDateTimeString(reconcile.noteDate)}{" "}
                            </div>
                        )}

                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" disabled={isBusy} onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={isBusy || !reconcile.note}
                            onClick={() => clearNote()}>
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !note}
                            onClick={() => persistNote()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
