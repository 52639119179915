import { LinkButton, Loading, ReactTable, Select, sortDate, useRefresh } from "@davo/portal-common";
import { AmendmentOpsPaneRecord, AmendmentReasons, noop, toDisplayDateString } from "@davo/types";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AmendmentAddAdditionalNoteModal } from "./AmendmentAddAdditionalNote";
import { getCompletedAmendments } from "./services";

const renderAccountName = (data: AmendmentOpsPaneRecord) => {
    return (
        <LinkButton
            url={`/accounts/${data.accountId}`}
            label={`${data.accountName}`}
            labelMaxChars={35}
            align={"left"}
            rel="noreferrer"
            target="_blank"
        />
    );
};

const renderFilingPeriodEnd = (data: AmendmentOpsPaneRecord) => {
    return (
        <LinkButton
            url={`/filings/${data.filingId}`}
            label={`${toDisplayDateString(data.filingPeriodEnd)}`}
            labelMaxChars={35}
            align={"left"}
            rel="noreferrer"
            target="_blank"
        />
    );
};

export function AmendmentsCompletedPane() {
    const [amendments, setAmendments] = useState<AmendmentOpsPaneRecord[]>();
    const [months, setMonths] = useState<string[]>();
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(undefined);

    const [reRender] = useRefresh(noop);

    useEffect(() => {
        const now = DateTime.local();
        const monthValues = [];
        const start = DateTime.fromISO("2019-10-01T12:00"); // Approx D30 start date
        const monthsDiff = now.diff(start, ["months"]);

        for (let x = 0; x < monthsDiff.months; ++x) {
            const monthStr = now.minus({ month: x }).toFormat("yyyy-MM");
            monthValues.push(monthStr);
        }
        setMonths(monthValues);
    }, []);

    useAsyncEffect(async () => {
        if (!selectedMonth) {
            return;
        }
        setAmendments(undefined);
        setAmendments(await getCompletedAmendments(selectedMonth));
    }, [selectedMonth]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Date Marked",
                accessor: "amendmentDate" as const,
                Cell: (data: any) => (
                    <>
                        {data.value && (
                            <>
                                <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>
                                <AmendmentAddAdditionalNoteModal amendment={data.cell.row.original} />
                            </>
                        )}
                    </>
                ),
            },
            {
                Header: "Reason",
                accessor: "amendmentReason",
                Cell: (data: any) => <div>{AmendmentReasons[data.value]}</div>,
            },
            {
                Header: "Name",
                accessor: "accountName",
                Cell: (data: any) => {
                    return renderAccountName(data.cell.row.original);
                },
            },
            {
                Header: "Period End",
                accessor: "filingPeriodEnd" as const,
                Cell: (data: any) => {
                    return renderFilingPeriodEnd(data.cell.row.original);
                },
                sortType: sortDate("filingPeriodEnd"),
            },
            {
                Header: "Jurisdiction",
                accessor: "jurisdiction" as const,
                Cell: (data: any) => <div>{data.value}</div>,
            },
            {
                Header: "Note",
                accessor: "amendmentNote",
                Cell: (data: any) => <div>{data.value}</div>,
            },
        ];
    }, []);

    if (!months) {
        return <Loading />;
    }
    return (
        <div>
            <Select<string>
                title="Amended-in-Month"
                options={months}
                value={selectedMonth}
                onChange={setSelectedMonth}
            />

            {amendments && (
                <>
                    <ReactTable
                        columns={columns}
                        data={amendments}
                        refreshData={reRender}
                        options={{
                            pageSize: 10,
                        }}
                    />
                </>
            )}
        </div>
    );
}
