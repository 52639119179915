import { Select } from "@davo/portal-common";
import React from "react";

interface ILocationDailyDetailModalSelect {
    months: string[];
    selectedMonth: string;

    setSelectedMonth(val: string | undefined): void;
}

export const LocationDailyDetailModalSelect: React.FunctionComponent<ILocationDailyDetailModalSelect> = ({
    months,
    selectedMonth,
    setSelectedMonth,
}) => <Select<string> title="Month" options={months} value={selectedMonth} onChange={setSelectedMonth} />;
