import {
    ConnectionStatus,
    d30Toast,
    d30ToastError,
    defaultConnectionStatus,
    LinkButton,
    Loading,
    ReactTable,
    Select,
    sortBoolean,
    sortDate,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import {
    INewBoarding,
    NewBoardingTypes,
    OnboardCommunicationStatus,
    OnboardCommunicationStatuses,
    OnboardCommunicationStatusTypes,
    toDisplayDateString,
    toDisplayDateTimeString,
    toDisplayDayMonthString,
} from "@davo/types";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import differenceWith from "lodash/differenceWith";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AssignStaffModal } from "./AssignStaffModal";
import { HubSpotLink } from "./HubSpotLink";
import { OnboardingNoteModal } from "./reconcile-remitted/OnboardingNoteModal";
import { RelatedMidTrans } from "./RelatedMidTrans";
import {
    getNewBoardings,
    markBoardingReviewed,
    updateOnboardingAssignee,
    updateOnboardingCommunicationStatus,
} from "./services";

export function NewBoardings({ newBoardingType, title }: { newBoardingType: NewBoardingTypes; title: string }) {
    const loginContext = useLoginContext();

    const [boardings, setBoardings] = useState<INewBoarding[]>();
    const [showOnboardingAssigneeModal, onboardingAssigneeModalProps] = useModalEditor<string | null>();
    const [locationsToAssign, setLocationsToAssign] = useState<string[]>([]);
    const [selectedTableRows, setSelectedTableRows] = useState<INewBoarding[]>([]);
    const toggleAllRowsSelectedHandlerRef = useRef<(value?: boolean) => void>();

    const refresh = useCallback(() => {
        getNewBoardings(newBoardingType)
            .then((results) => setBoardings(results))
            .catch((e) => d30ToastError("Problem loading", e));
        toggleAllRowsSelectedHandlerRef.current?.(false);
    }, [newBoardingType]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const doMarkReviewed = useCallback((locationId: string) => {
        markBoardingReviewed(locationId)
            .then(() => {
                d30Toast("Marked as reviewed");
            })
            .catch((e) => {
                d30ToastError("Problem marking as reviewed.", e);
            });
    }, []);

    const bulkAssignOnboardings = async (locationIds: string[], assigneeId: string | undefined) => {
        const assignsFn = locationIds.map((locationId) =>
            updateOnboardingAssignee(locationId, assigneeId, newBoardingType)
        );
        await Promise.all(assignsFn);
    };

    const columns = useMemo(() => {
        return [
            {
                Header: "Boarded",
                accessor: "boardingDate",
                sortType: sortDate("boardingDate"),
                Cell: (data: any) => {
                    return (
                        <>
                            {newBoardingType === "new" ? (
                                toDisplayDateTimeString(data.value)
                            ) : (
                                <Tooltip title={toDisplayDateTimeString(data.value)}>
                                    <span>{toDisplayDateString(data.value)}</span>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                Header: "Communication Status",
                accessor: "onboardingCommunicationStatus",
                Cell: (data: any) => {
                    return (
                        <Select<OnboardCommunicationStatus>
                            style={{ width: "150px" }}
                            options={OnboardCommunicationStatusTypes}
                            value={data.row.original.onboardingCommunicationStatus ?? "new"}
                            label={(status: OnboardCommunicationStatus) => OnboardCommunicationStatuses[status]}
                            onChange={(status) => {
                                if (status && status !== data.row.original.onboardingCommunicationStatus) {
                                    updateOnboardingCommunicationStatus(data.row.original.locationId, status)
                                        .then(() => {
                                            d30Toast("Connection Status updated");
                                        })
                                        .finally(() => refresh())
                                        .catch((e) => {
                                            d30ToastError("Problem updated connection status.", e);
                                        });
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: "Status",
                accessor: "locationId",
                disableSortBy: true,
                Cell: (data: any) => {
                    const accountId = data.row.original.accountId;
                    const locationId = data.row.original.locationId;

                    return (
                        <ConnectionStatus
                            accountId={accountId}
                            locationId={locationId}
                            hideMessage={false}
                            connectionStatusProvided={{
                                ...defaultConnectionStatus,
                                hasMissingBankInfo: data.row.original.status === "no-bank",
                                hasMissingTaxInfo: data.row.original.status === "no-tp",
                            }}
                        />
                    );
                },
            },
            {
                Header: "Boarding Note",
                accessor: "note",
                disableSortBy: true,
                Cell: (data: any) => <OnboardingNoteModal txn={data.row.original} />,
            },
            {
                Header: "Name",
                accessor: "locationName",
                Cell: (data: any) => {
                    const accountId = data.row.original.accountId;
                    const locationId = data.row.original.locationId;

                    return (
                        <div style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                            {!accountId && (
                                <>
                                    <LinkButton
                                        url={`/no-account/location/${locationId}`}
                                        label={data.row.original.locationName}
                                        labelMaxChars={25}
                                        align={"left"}
                                        style={{ float: "left", marginTop: "8px" }}
                                        rel="noreferrer"
                                        target="_blank"
                                    />
                                    {data.row.original.hubspotId && (
                                        <HubSpotLink
                                            type={"location"}
                                            davoId={locationId}
                                            hubSpotIdProvided={data.row.original.hubspotId}
                                        />
                                    )}
                                </>
                            )}
                            {accountId && (
                                <>
                                    <LinkButton
                                        url={`/accounts/${accountId}`}
                                        label={data.row.original.locationName}
                                        labelMaxChars={25}
                                        style={{ float: "left", marginTop: "8px" }}
                                        rel="noreferrer"
                                        target="_blank"
                                    />
                                    {data.row.original.hubspotId && (
                                        <HubSpotLink
                                            type={"account"}
                                            davoId={accountId}
                                            hubSpotIdProvided={data.row.original.hubspotId}
                                        />
                                    )}
                                </>
                            )}
                            <RelatedMidTrans potentialMidTrans={data.row.original.potentialMidTrans} />
                        </div>
                    );
                },
            },
            {
                Header: "Has Charged Tax?",
                accessor: "hasChargedTax",
                sortType: sortBoolean("hasChargedTax"),
                Cell: (data: any) => <>{data.row.original.hasChargedTax ? "Yes" : "No"}</>,
            },
            {
                Header: "Assignee",
                accessor: "onboardingAssignedUserName",
                sortDescFirst: true,
                Cell: (data: any) => (
                    <>
                        {!data.row.original.onboardingAssignedUserId && (
                            <div style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        updateOnboardingAssignee(
                                            data.row.original.locationId,
                                            loginContext.user?.id,
                                            newBoardingType
                                        )
                                            .finally(() => refresh())
                                            .catch((e) => d30ToastError(e.message, e));
                                    }}>
                                    Take
                                </Button>
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    style={{ marginLeft: "5px" }}
                                    onClick={() => {
                                        setLocationsToAssign([data.row.original.locationId]);
                                        showOnboardingAssigneeModal(undefined);
                                    }}>
                                    Assign
                                </Button>
                            </div>
                        )}
                        {data.row.original.onboardingAssignedUserId && (
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                    setLocationsToAssign([data.row.original.locationId]);
                                    showOnboardingAssigneeModal(data.row.original.onboardingAssignedUserId);
                                }}>
                                {data.row.original.onboardingAssignedUserName}
                            </Button>
                        )}
                    </>
                ),
            },
            {
                Header: "Last Reviewed",
                accessor: "onboardingReviewed",
                sortType: sortDate("onboardingReviewed"),
                Cell: (data: any) => {
                    const initialValue = data.value;
                    return (
                        <>
                            {initialValue ? (
                                <Tooltip title={toDisplayDateTimeString(initialValue)}>
                                    <span>{toDisplayDayMonthString(initialValue)}</span>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                            <Button
                                style={{ marginLeft: "10px" }}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    doMarkReviewed(data.row.original.locationId);
                                    // inline update to not refresh the page
                                    setBoardings((old) =>
                                        old?.map((row, index) => {
                                            if (index === data.row.index) {
                                                return {
                                                    ...old[data.row.index],
                                                    [data.column.id]: DateTime.utc(),
                                                };
                                            }
                                            return row;
                                        })
                                    );
                                }}>
                                Mark Reviewed
                            </Button>
                        </>
                    );
                },
            },
        ];
    }, [doMarkReviewed, showOnboardingAssigneeModal, loginContext.user?.id, newBoardingType, refresh]);

    const updateSelectedTableRows = React.useCallback(
        (newSelectedRows: INewBoarding[]) => {
            const hasChanges =
                !isEmpty(differenceWith(newSelectedRows, selectedTableRows, (a, b) => a.locationId === b.locationId)) ||
                newSelectedRows.length !== selectedTableRows.length;
            if (!hasChanges) {
                return;
            }
            setSelectedTableRows(newSelectedRows);
        },
        [setSelectedTableRows, selectedTableRows]
    );

    if (!boardings) {
        return <Loading />;
    }

    return (
        <>
            <div style={{ margin: "10px" }}>
                <h2>{title}</h2>
                <div
                    style={{
                        display: "flex",
                        visibility:
                            !isEmpty(selectedTableRows) && selectedTableRows.some((i) => !i.onboardingAssignedUserId)
                                ? "visible"
                                : "hidden",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: "4px",
                        marginBottom: "8px",
                    }}>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{ marginRight: "5px" }}
                        data-testid="assign-button"
                        disabled={isEmpty(selectedTableRows)}
                        onClick={() => {
                            setLocationsToAssign(
                                selectedTableRows.filter((i) => !i.onboardingAssignedUserId).map((i) => i.locationId)
                            );
                            showOnboardingAssigneeModal(undefined);
                        }}>
                        Assign
                    </Button>
                </div>
            </div>
            <ReactTable
                columns={columns}
                data={boardings}
                skipPageReset={true}
                refreshData={refresh}
                options={{
                    pageSize: 10,
                    hiddenColumns: newBoardingType !== "new" ? ["locationId"] : [],
                    showMultiSelection: {
                        onSelectionChange: updateSelectedTableRows,
                        toggleAllRowsSelectedFn: (fn: (value?: boolean) => void) => {
                            toggleAllRowsSelectedHandlerRef.current = fn;
                        },
                    },
                }}
            />
            {onboardingAssigneeModalProps.isDialogOpen && (
                <AssignStaffModal
                    shouldFilterByFilers={false}
                    {...onboardingAssigneeModalProps}
                    assignStaffUser={(_unused, selected) => {
                        if (isEmpty(locationsToAssign)) {
                            return;
                        }
                        bulkAssignOnboardings(locationsToAssign, selected?.id)
                            .then(() => {
                                refresh();
                            })
                            .catch((e) => {
                                d30ToastError(e.message, e);
                            });
                    }}
                />
            )}
        </>
    );
}
