import { get, post } from "@davo/portal-common";
import { IReferralLocationDetails, IReferralSource } from "@davo/types";

export async function getRefTrackingSources() {
    return get<IReferralSource[]>(`api/ops/referrals/sources/list`, true);
}

export async function recordReferralAsPaid(id: string) {
    return post(`api/ops/referrals/${id}/paid`, {}, true);
}

// yearMonth is in yyyy-MM format
export async function getReferrals(yearMonth: string, sources: string[]) {
    return post<IReferralLocationDetails[]>(`api/ops/referrals/list/${yearMonth}`, { sources }, true);
}

export async function updateReferralSource(id: string, description?: string, isInactive?: boolean) {
    return post(`api/ops/referrals/source/${id}`, { description, inactive: isInactive }, true);
}

export async function checkSourceNameAvailability(name: string) {
    return get(`api/ops/referrals/source/${name}`, true);
}

export async function addNewReferralSource(name: string, description: string) {
    return post(`api/ops/referrals/source/add/${name}`, { description }, true);
}
