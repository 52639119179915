import { d30ToastError } from "@davo/portal-common";
import { User } from "@davo/types";
import React, { useEffect, useState } from "react";
import { getUser } from "../services";

export interface IRenderAssignee {
    userId?: string;
}

export const RenderAssignee: React.FunctionComponent<IRenderAssignee> = ({ userId }) => {
    const [assignee, setAssignee] = useState<User>();
    useEffect(() => {
        userId &&
            getUser(userId)
                .then((u) => setAssignee(u))
                .catch((e) => d30ToastError("Problem getting user", e));
    }, [userId]);

    return <div data-testid={"assignee"}>{assignee ? `${assignee.firstName} ${assignee.lastName}` : "Unassigned"}</div>;
};
