import { PaperComponent, Select, useLoginContext } from "@davo/portal-common";
import { Partner } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { hasPermission } from "./util";

export function SetPartnerModal({
    partners,
    setPartnerCallback,
    currentPartner,
}: {
    partners?: Partner[];
    setPartnerCallback: (selected: Partner | undefined) => void;
    currentPartner?: Partner;
}) {
    const loginContext = useLoginContext();

    const [selected, setSelected] = useState<Partner>();
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleClick = () => {
        setPartnerCallback(selected);
        setIsShowing(false);
    };

    const show = () => {
        setIsBusy(false);
        if (partners && currentPartner && currentPartner.id) {
            setSelected(partners.find((p) => p.id === currentPartner.id));
        } else {
            setSelected(undefined);
        }
        setIsShowing(true);
    };

    return (
        <>
            {partners && partners.length && hasPermission(loginContext.permissions, "assign_partner") && (
                <>
                    <Button
                        variant="outlined"
                        style={{ padding: "7px 9px", margin: "5px" }}
                        disabled={isBusy}
                        onClick={show}
                        size="small"
                        color="primary">
                        Edit Partner
                    </Button>
                    {isShowing && (
                        <Dialog
                            open={true}
                            PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                            style={{ boxShadow: "none", margin: "5px 2px" }}
                            onClose={() => {
                                setIsShowing(false);
                            }}>
                            <DialogTitle id="draggable-dialog-title">Edit Partner</DialogTitle>
                            <DialogContent>
                                {partners.length > 0 && (
                                    <div>
                                        <Select<Partner>
                                            title="Partner"
                                            style={{ width: "300px" }}
                                            options={partners}
                                            label={(p: Partner) => p.name ?? "No partner name found"}
                                            noneLabel={"No Partner"}
                                            value={selected}
                                            onChange={setSelected}
                                        />
                                    </div>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => setIsShowing(false)}
                                    variant="outlined"
                                    color="primary"
                                    style={{ width: "100px" }}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => handleClick()}
                                    disabled={currentPartner?.id === selected?.id}
                                    variant="contained"
                                    color="primary"
                                    style={{ width: "150px" }}
                                    autoFocus>
                                    Assign
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </>
            )}
        </>
    );
}
