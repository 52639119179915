import { RenderAddress, useAddressVerificationContext, VerifyAddressAlert } from "@davo/portal-common";
import { Location } from "@davo/types";
import { Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import edit from "./resources/edit.png";

const useStyles = makeStyles({
    location_content: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
    },
    location_address: {
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    location_address_text: {
        margin: 0,
    },
    location_name: {
        display: "inline-block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "80%",
    },
    location_content_edit: {
        width: "7.5%",
        alignSelf: "flex-start",
        marginLeft: "5px",
        cursor: "pointer",
    },
});

export interface ILocationAddressProps {
    location: Location;
}

export function LocationAddress({ location }: ILocationAddressProps) {
    const classes = useStyles();
    const { avataxValidatedAddress, launchVerifyAddressModal, launchEditAddressModal } =
        useAddressVerificationContext();

    return (
        <div>
            <div data-testid={"locationNameHeader"}>
                <span className={classes.location_name}>{location.name}</span>
                <span>
                    <Tooltip title={"Edit Location"}>
                        <img
                            data-testid={"editLocationIcon"}
                            className={classes.location_content_edit}
                            onClick={() => launchEditAddressModal()}
                            src={edit}
                            alt={`Edit Icon for ${location.name}`}
                        />
                    </Tooltip>
                </span>
            </div>
            <RenderAddress
                data-testid={"locationAddress"}
                address1={location.address1}
                address2={location.address2}
                city={location.city}
                state={location.state}
                zip={location.zip}
                county={location.county}
                timezone={location.timezone}
            />
            {!location.validatedAddress && (
                <VerifyAddressAlert
                    isValidated={location.validatedAddress}
                    launchVerifyAddressModal={launchVerifyAddressModal}
                    hasAvataxValidatedAddress={!!avataxValidatedAddress}
                />
            )}
        </div>
    );
}
