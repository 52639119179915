import {
    d30Toast,
    d30ToastError,
    getLocations,
    getTaxProfiles,
    Select,
    TextField,
    useLoginContext,
} from "@davo/portal-common";
import {
    AccountRecord,
    getSMSTemplate,
    ISmsTemplates,
    IUnredeemedInvitation,
    LocationRecord,
    SmsMessageDisplay,
    SmsMessageReasonKeys,
    SmsReasonCodes,
    smsTemplates,
    SupportedLanguage,
    TaxProfile,
    toDisplayDateTimeString,
    User,
} from "@davo/types";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    IconButton,
    Typography,
    useTheme,
} from "@mui/material";
import { decode } from "html-entities";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import "./css/sms.css";
import { cancelSmsMessage, getSmsForUser, sendSmsMessage } from "./services";

export interface ISmsPaneType {
    accounts?: AccountRecord[];
    recipientObj: User | IUnredeemedInvitation;
}

export interface ISmsDetails {
    stateChoice: boolean;
    languageChoice: boolean;
}

export function SmsPane({ recipientObj, accounts }: ISmsPaneType) {
    const loginContext = useLoginContext();

    const [isUser, setIsUser] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [isDOR, setIsDOR] = useState<boolean>(false);
    const [chooseDetails, setChooseDetails] = useState<ISmsDetails>({
        stateChoice: false,
        languageChoice: false,
    });
    const [userSelectedJurisdiction, setUserSelectedJurisdiction] = useState<string | undefined>(undefined);
    const [userSelectedMessageReason, setUserSelectedMessageReason] = useState<SmsReasonCodes | undefined>(undefined);
    const [canSendNow, setCanSendNow] = useState<boolean>(false);
    const [smsTemplate, setSmsTemplate] = useState<ISmsTemplates | undefined>(undefined);
    const [smsMessages, setSmsMessages] = useState<SmsMessageDisplay[]>([]);
    const [userLocationStates, setUserLocationStates] = useState<string[]>([]);
    const [userSelectedLanguage, setUserSelectedLanguage] = useState<SupportedLanguage | undefined>(undefined);
    const [smsMessage, setSmsMessage] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const changeLanguage = (lang?: SupportedLanguage) => {
        if (!lang) {
            return;
        }
        setUserSelectedLanguage(lang);
        setChooseDetails({ ...chooseDetails });
    };

    useEffect(() => {
        if (Object.prototype.hasOwnProperty.call(recipientObj, "doNotSms")) {
            setIsUser(true);
        }
        if (recipientObj.phone) {
            getSmsMessages();
        }
    }, []);

    useEffect(() => {
        const locStates: string[] = [];
        accounts?.forEach(async (a: AccountRecord) => {
            const locations = await getLocations(a.id);
            locations.forEach((l: LocationRecord) => {
                if (l.state) {
                    locStates.push(l.state);
                }
            });
            const taxProfiles = await getTaxProfiles(a.id);
            taxProfiles.forEach((tp: TaxProfile) => {
                if (tp.state && !locStates.includes(tp.state)) {
                    locStates.push(tp.state);
                }
            });
            setUserLocationStates(Array.from(new Set(locStates)));
        });
    }, [accounts]);

    useEffect(() => {
        if (isNil(userSelectedMessageReason)) {
            setSmsMessage(undefined);
        } else {
            let template = undefined;
            try {
                template = getSMSTemplate(
                    userSelectedMessageReason,
                    userSelectedLanguage,
                    recipientObj as User,
                    userSelectedJurisdiction,
                    loginContext.user
                );
            } catch (error: any) {
                d30ToastError(error.message);
            }
            const hasStateChoice = userLocationStates.length > 1;
            setIsDOR(userSelectedMessageReason === "dor");
            setSmsTemplate(template);
            const details = {
                stateChoice: hasStateChoice,
                languageChoice: !isNil(template) && Object.keys(template).length > 3,
            };
            setChooseDetails(details);
            if (template) {
                const { displayTitle, reason, ...languages } = template;
                const language =
                    userSelectedLanguage && userSelectedLanguage in template
                        ? userSelectedLanguage
                        : Object.keys(languages)[0];
                setUserSelectedLanguage(language as SupportedLanguage);
                setSmsMessage(template[language as keyof (typeof smsTemplates)[typeof language]]);
            }
        }
    }, [
        recipientObj,
        loginContext.user,
        userSelectedMessageReason,
        userSelectedLanguage,
        userSelectedJurisdiction,
        userLocationStates,
    ]);

    useEffect(() => {
        let isDisabled = false;
        if (
            (userSelectedMessageReason && chooseDetails.stateChoice && userSelectedJurisdiction === undefined) ||
            (isDOR && !password)
        ) {
            isDisabled = true;
        }
        setIsButtonDisabled(isDisabled);
    }, [smsTemplate, password, userSelectedMessageReason, chooseDetails, isDOR]);

    const resetSmsPane = async () => {
        setSmsTemplate(undefined);
        setSmsMessage(undefined);
        setUserSelectedMessageReason(undefined);
        setPassword(undefined);
        setIsDOR(false);
        setUserSelectedMessageReason(undefined);
        getSmsMessages();
    };

    const getSmsMessages = () => {
        getSmsForUser(recipientObj.id)
            .then((smsMsg) => setSmsMessages(smsMsg))
            .catch((e) => d30ToastError("Problem getting sms message.", e));
    };

    const sendSms = async () => {
        if (!smsMessage) {
            d30ToastError("Message cannot be empty.");
        } else if (!password && userSelectedMessageReason === "dor") {
            d30ToastError("Password cannot be empty.");
        } else if (isNil(userSelectedLanguage) && !isNil(smsTemplate)) {
            d30ToastError("SMS language must be set.");
        } else {
            try {
                await sendSmsMessage(recipientObj.id, smsMessage, isUser ? "user" : "invitation", canSendNow);
                d30Toast(`SMS Sent!`);
                await resetSmsPane();
            } catch (e: any) {
                d30ToastError(`${e.message}`);
            }
        }
    };

    const replacePassword = () => {
        if (smsMessage && password && userSelectedMessageReason === "dor" && smsMessage.includes("NEW PASSWORD")) {
            const dorMsg = smsMessage.replace("NEW PASSWORD", password);
            setSmsMessage(dorMsg);
        }
    };
    const renderMms = (media: string[] | undefined | null) => {
        if (!media) {
            return null;
        }
        return (
            <>
                {media.length > 0 ? (
                    media.map((m) => (
                        <a href={m} target="_blank" rel="noopener noreferrer" key={m}>
                            <img src={m} alt={"image sent in"} />
                        </a>
                    ))
                ) : (
                    <em>
                        Image deleted: images older than 1 month are automatically deleted from S3 to protect user data.
                    </em>
                )}
            </>
        );
    };

    const changeTemplate = (id: SmsReasonCodes | undefined) => {
        setSmsTemplate(undefined);
        setUserSelectedMessageReason(id);
        setUserSelectedLanguage(undefined);
        setUserSelectedMessageReason(id);
    };
    const doNotSms = () => {
        if (isUser) {
            // @ts-ignore: ts does not think the properties exist
            // tslint:disable-next-line:no-bitwise
            return !!(recipientObj.doNotSms | recipientObj.smsUndeliverable);
        } else {
            return false;
        }
    };

    const renderRecipientName = () => {
        // @ts-ignore: object is either a user so we use first and last name, or an invitation so we use business name
        return Object.prototype.hasOwnProperty.call(recipientObj, "firstName") ||
            Object.prototype.hasOwnProperty.call(recipientObj, "lastName")
            ? // @ts-ignore: firstName not on IUnredeemedInvitation, check for this is immediately above
              `${recipientObj.firstName ? recipientObj.firstName?.toUpperCase() : ""} ${
                  // @ts-ignore: lastName not on IUnredeemedInvitation
                  recipientObj.lastName ? recipientObj.lastName.toUpperCase() : ""
              }`
            : // @ts-ignore: businessName not on User
              recipientObj.businessName;
    };

    const renderPrePopMessages = () => {
        return (
            <>
                <FormControl data-testid={"templateSelector"} style={{ float: "left", marginBottom: "20px" }}>
                    <Select<SmsReasonCodes>
                        noneLabel={"Blank Template"}
                        options={SmsMessageReasonKeys}
                        value={userSelectedMessageReason}
                        onChange={changeTemplate}
                        label={(reasonCode) => smsTemplates[reasonCode].displayTitle}
                    />
                    <FormHelperText>Message templates</FormHelperText>
                </FormControl>
            </>
        );
    };

    const renderStateSelection = (states: string[]) => {
        return (
            <>
                <FormControl data-testid={"stateSelector"} style={{ float: "left", margin: "0 20px 20px" }}>
                    <Select<string>
                        options={states}
                        value={userSelectedJurisdiction}
                        onChange={(e) => setUserSelectedJurisdiction(e)}
                    />
                    <FormHelperText>State</FormHelperText>
                </FormControl>
            </>
        );
    };

    const renderLanguageSelection = () => {
        if (smsTemplate) {
            const { displayTitle, reason, ...languages } = smsTemplate;
            return (
                <>
                    <FormControl data-testid={"languageSelector"} style={{ float: "left", margin: "0 20px 20px" }}>
                        <Select<string>
                            options={Object.keys(languages)}
                            value={userSelectedLanguage}
                            onChange={(x) => changeLanguage(x as SupportedLanguage)}
                            isDisabled={!isNil(smsTemplate) && Object.keys(languages).length === 1}
                        />
                        <FormHelperText>Choose Language</FormHelperText>
                    </FormControl>
                </>
            );
        }
    };

    const cancelSms = async (smsId: string) => {
        try {
            await cancelSmsMessage(smsId);
            d30Toast(`SMS cancelled!`);
        } catch (e: any) {
            d30ToastError(`${e.message}`);
        }
        await resetSmsPane();
    };

    const renderCancelSms = (smsId: string) => {
        return (
            <div style={{ float: "right", paddingLeft: "10px", marginTop: "-10px" }}>
                <IconButton color="error" onClick={async () => cancelSms(smsId)}>
                    <DeleteIcon />
                </IconButton>
            </div>
        );
    };

    return (
        <div className={`${theme.palette.mode}`}>
            <Accordion style={{ border: "none", boxShadow: "none" }} id="sms_panel" expanded={true}>
                {isUser && (
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        SMS
                    </AccordionSummary>
                )}
                <AccordionDetails style={{ display: "flex" }}>
                    {recipientObj.phone ? (
                        <div id="sms_wrapper" style={{ width: "80%", display: "block", margin: "0 auto" }}>
                            <div style={{ textAlign: "center", padding: "10px 0", fontSize: ".85em" }}>
                                Messages are automatically deleted after 30 days to protect user data.
                            </div>
                            {smsMessages.map((sms) => (
                                <div
                                    id={`sms_msg_${sms.id}`}
                                    className={
                                        `sms_msg ${sms.fromMerchant ? "merchant" : "ops"}` +
                                        `${sms.undeliverable ? " undeliverable" : ""}`
                                    }
                                    key={sms.id}>
                                    <ul className="sms_msg_list">
                                        <li className="subdued">
                                            {sms.fromMerchant
                                                ? renderRecipientName()
                                                : `DAVO (${sms.opsUserDisplay?.toUpperCase()})`}
                                            : {toDisplayDateTimeString(sms.created)}
                                        </li>
                                        {sms.undeliverable ? <li className="subdued">UNDELIVERABLE</li> : ""}
                                        {renderMms(sms.media)}
                                        <li>{sms.message ? decode(sms.message) : ""}</li>
                                        {sms.status ? (
                                            <li
                                                style={{
                                                    textAlign: "right",
                                                    marginTop: "10px",
                                                    marginRight: "5px",
                                                    padding: "unset",
                                                }}
                                                className="subdued">
                                                <em>{sms.status}</em>
                                                {sms.status === "enqueued" ? renderCancelSms(sms.id) : ""}
                                            </li>
                                        ) : (
                                            ""
                                        )}
                                    </ul>
                                </div>
                            ))}
                            <div id="sms_text_box">
                                {doNotSms() ? (
                                    <p style={{ textAlign: "center", padding: "10px" }}>
                                        User can not be reached by SMS.
                                    </p>
                                ) : (
                                    <>
                                        {userSelectedMessageReason === "dor" && (
                                            <div style={{ width: "20%" }}>
                                                <TextField
                                                    label="Fill New Password"
                                                    value={password ?? ""}
                                                    onBlur={replacePassword}
                                                    onChange={setPassword}
                                                />
                                            </div>
                                        )}
                                        <TextField
                                            data-testid={"smsMessageContainer"}
                                            inputProps={{
                                                [`data-testid`]: "smsMessageInput",
                                            }}
                                            label="Message"
                                            value={smsMessage ?? ""}
                                            onChange={setSmsMessage}
                                            isMultiline={true}
                                            rows={8}
                                        />
                                        <div style={{ display: "flex", flexDirection: "column", float: "right" }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={sendSms}
                                                style={{ float: "right", margin: "21px 20px 0" }}
                                                disabled={isButtonDisabled}>
                                                Send
                                            </Button>
                                            <Typography
                                                style={{
                                                    padding: "2px",
                                                    paddingTop: "4px",
                                                    marginTop: "14px",
                                                    color: "rgba(0,0,0,0.6)",
                                                    fontSize: "14px",
                                                }}>
                                                <Checkbox
                                                    checked={canSendNow}
                                                    onChange={() => setCanSendNow(!canSendNow)}
                                                />
                                                Deliver ASAP
                                            </Typography>
                                        </div>
                                        {isUser && renderPrePopMessages()}
                                        {renderLanguageSelection()}
                                        {chooseDetails.stateChoice && renderStateSelection(userLocationStates)}
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <p>User has not added their phone number.</p>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
