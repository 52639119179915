import { useLoginContext } from "@davo/portal-common";
import { IFilingRow } from "@davo/types";
import { Button } from "@mui/material";
import isNil from "lodash/isNil";
import React from "react";
import { hasPermission } from "../util";

export interface IRenderUserName {
    ua: IFilingRow;

    assignRow(ua: IFilingRow): Promise<void>;
}

export const RenderUserName: React.FunctionComponent<IRenderUserName> = ({ ua, assignRow }) => {
    const loginContext = useLoginContext();
    if (!isNil(ua.details.assignedUserId) || !isNil(ua.filedDate)) {
        const opsName = ["filed", "returned", "won't file"].includes(ua.status)
            ? `${ua.details.filedBy?.firstName} ${ua.details.filedBy?.lastName}`
            : `${ua.details.assignedUser?.firstName} ${ua.details.assignedUser?.lastName}`;
        return (
            <div
                style={{
                    maxWidth: "100px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}>
                {opsName}
            </div>
        );
    } else if (ua.details.canAutofile) {
        return (
            <div
                style={{
                    maxWidth: "100px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}>
                Automation
            </div>
        );
    } else {
        return (
            <Button
                variant="outlined"
                disabled={!hasPermission(loginContext.permissions, "can_file")}
                color="primary"
                size="small"
                onClick={() => assignRow(ua)}>
                Take
            </Button>
        );
    }
};
