import { LinkButton } from "@davo/portal-common";
import { IFilingRow } from "@davo/types";
import React from "react";

interface IRenderProfile {
    ua: IFilingRow;
    showAccountButton: boolean;
}

export const RenderProfile: React.FunctionComponent<IRenderProfile> = ({ ua, showAccountButton }) => {
    if (showAccountButton) {
        return (
            <LinkButton
                url={`/accounts/${ua.accountId}`}
                label={ua.profileName + " ~ " + ua.legalName}
                labelMaxChars={40}
                align={"left"}
            />
        );
    } else {
        return (
            <>
                {ua.profileName} ~ {ua.legalName}
            </>
        );
    }
};
