import {
    d30Toast,
    d30ToastError,
    DavoLink,
    Loading,
    PaperComponent,
    TextField,
    useLoginContext,
    useRefresh,
} from "@davo/portal-common";
import {
    DiscountReasons,
    IDeactivationStatus,
    ILocationNoAccount,
    PotentialMidTrans,
    toDisplayDateTimeString,
    validateEmail,
    validatePhone,
} from "@davo/types";
import ChatIcon from "@mui/icons-material/Chat";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { AccountRelatedLocationsModal } from "./AccountRelatedLocationsModal";
import { DeactivateLocationButton } from "./DeactivateLocationButton";
import { ExtractLocationButton } from "./ExtractLocationButton";
import { ForceDisconnectButton } from "./ForceDisconnectButton";
import { HubSpotLink } from "./HubSpotLink";
import { LocationDailyDetailModal } from "./LocationDailyDetailModal";
import { LocationSubscriptionsModal } from "./LocationSubscriptionsModal";
import { POSCredentialsButton } from "./POSCredentialsButton";
import { RelatedMidTrans } from "./RelatedMidTrans";
import { SetAwaitingDisconnectButton } from "./SetAwaitingDisconnectButton";
import { SmsPane } from "./SmsPane";
import edit from "./resources/edit.png";
import {
    canDeactivateLocations,
    getLocationNoAccount,
    getMidTransAccountsByLocationId,
    sendNoAccountSetupReminder,
    updateInvitationInfo,
} from "./services";
import { displayPhone } from "./util";

const useStyles = makeStyles({
    card: {
        minWidth: "100%",
        minHeight: "350px",
        margin: "5px",
    },
    invitation_content_edit: {
        width: "20px",
        alignSelf: "flex-start",
        marginLeft: "35px",
        cursor: "pointer",
    },
});

export function LocationNoAccountDetailPane({ locationId }: { locationId: string }) {
    const navigate = useNavigate();
    const classes = useStyles();
    const loginContext = useLoginContext();

    const [potentialMidTrans, setPotentialMidTrans] = useState<PotentialMidTrans[]>([]);
    const [location, setLocation] = useState<ILocationNoAccount | undefined>();
    const [showSmsModal, setSmsShowModal] = useState<boolean>(false);
    const [showInfoEditModal, setShowInfoEditModal] = useState<boolean>(false);
    const [deactivationStatus, setDeactivationStatus] = useState<IDeactivationStatus>();
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [didUpdate, setDidUpdate] = useState<boolean>(false);

    useAsyncEffect(async () => {
        setDidUpdate(false);
        const [loc, canDeactivateLocation, midTrans] = await Promise.all([
            getLocationNoAccount(locationId),
            canDeactivateLocations([locationId]),
            getMidTransAccountsByLocationId(locationId),
        ]);
        if (loc.accountId) {
            navigate(`/accounts/${loc.accountId}`);
        }
        setLocation(loc);
        setEmail(loc.invitation?.email ?? undefined);
        setPhone(loc.invitation?.phone ?? undefined);
        setDeactivationStatus(canDeactivateLocation[locationId]);
        setPotentialMidTrans(midTrans);
    }, [locationId, didUpdate]);

    const cancel = () => {
        setShowInfoEditModal(false);
        setEmail(location?.invitation?.email ?? undefined);
        setPhone(location?.invitation?.phone ?? undefined);
    };

    const doSubmit = () => {
        if (email && validateEmail(email)) {
            d30ToastError(`Error: invalid email`);
            return;
        }
        if (phone && validatePhone(phone)) {
            d30ToastError(`Error: invalid phone number`);
            return;
        }
        if (location?.invitation) {
            updateInvitationInfo(location?.invitation.id, email, phone)
                .then(() => {
                    setDidUpdate(true);
                    d30Toast(`Invitation updated`);
                })
                .catch(() => d30ToastError(`Error updating invitation`))
                .finally(cancel);
        }
    };

    const [refresh] = useRefresh(() => {
        getLocationNoAccount(locationId)
            .then((loc) => {
                setLocation(loc);
            })
            .catch((e) => d30ToastError("Problem getting location.", e));
    });

    if (!loginContext.user || !location) {
        return <Loading />;
    }

    const resend = () => {
        if (location.invitation.email) {
            sendNoAccountSetupReminder(location.invitation.id)
                .then(() => {
                    d30Toast(`Invitation resent to ${location.invitation.email}`);
                })
                .catch((e) => d30ToastError("Problem sending setup reminder.", e));
        }
    };

    const renderEmail = () => {
        if (location.invitation && location.invitation.email) {
            return (
                <>
                    <a href={`mailto:${location.invitation.email}`} style={{ color: "inherit" }}>
                        {location.invitation.email}
                    </a>
                </>
            );
        } else {
            return <div>no email</div>;
        }
    };

    const renderPhone = () => {
        if (location.invitation.phone && location.name) {
            return (
                <Typography component={"div"} style={{ paddingBottom: "30px" }}>
                    <>
                        <>
                            {displayPhone(location.invitation.phone)}
                            <span onClick={() => setSmsShowModal(true)}>
                                <ChatIcon style={{ marginLeft: "10px" }} />
                            </span>
                        </>
                        {showSmsModal && (
                            <Dialog
                                maxWidth="lg"
                                fullWidth={true}
                                open={true}
                                onClose={() => {
                                    setSmsShowModal(false);
                                }}>
                                <DialogTitle>
                                    SMS: {location.name}: {displayPhone(location.invitation.phone)}
                                </DialogTitle>
                                <DialogContent>
                                    <SmsPane recipientObj={location.invitation} />
                                </DialogContent>
                            </Dialog>
                        )}
                    </>
                </Typography>
            );
        } else {
            return <div>no phone</div>;
        }
    };

    const renderRelatedLocations = () => {
        if (location.invitation.relatedLocations && location.invitation.relatedLocations.length > 1) {
            return (
                <AccountRelatedLocationsModal
                    relatedLocations={location.invitation.relatedLocations}
                    displayButton={false}
                />
            );
        }
    };

    const renderDisconnectInfo = () => {
        const discountReasonCode = !isNil(location.disconnectReasonCode)
            ? `${DiscountReasons[location.disconnectReasonCode]}. `
            : "";
        const disconnectReasonNote = !isNil(location.disconnectReasonNote) ? `${location.disconnectReasonNote}. ` : "";
        const disconnectNote = !isNil(location.disconnectNote) ? `${location.disconnectNote}.` : "";
        return `${discountReasonCode}${disconnectReasonNote}${disconnectNote}`;
    };

    if (location.preAccount) {
        return (
            <Typography style={{ fontSize: "0.95em" }}>This is a pre-account this should not be available.</Typography>
        );
    }
    return (
        <div data-testid="location-no-account-detail-pane">
            <div style={{ display: "inline-flex", width: "100%", justifyContent: "space-between" }}>
                <Breadcrumbs
                    style={{ marginBottom: 10, marginLeft: 10 }}
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb">
                    <DavoLink to="/no-account">No Account Search</DavoLink>
                    <Typography color="textPrimary">{location.name}</Typography>
                </Breadcrumbs>
            </div>
            <Card className={classes.card}>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <CardHeader
                        title={location.name}
                        subheader={
                            <>
                                Location - No Account
                                <POSCredentialsButton locationId={location.id} locationPOS={location.posType} />
                                {location.created && (
                                    <Typography style={{ fontSize: "0.95em" }}>
                                        First connected: {toDisplayDateTimeString(location.created)}
                                    </Typography>
                                )}
                                {location.disconnected && (
                                    <div>
                                        <Typography style={{ fontSize: "0.95em" }}>
                                            Disconnected: {toDisplayDateTimeString(location.disconnected)}
                                        </Typography>
                                        {!isNil(location.disconnectReasonCode) && (
                                            <Typography style={{ fontSize: "0.95em" }}>
                                                Disconnect Info: {renderDisconnectInfo()}
                                            </Typography>
                                        )}
                                    </div>
                                )}
                            </>
                        }
                    />
                    <div style={{ padding: "16px", display: "flex", gap: 2, alignItems: "center" }}>
                        <HubSpotLink data-testid={"hubspot-link"} type={"location"} davoId={location.id} />
                        <RelatedMidTrans potentialMidTrans={potentialMidTrans} />
                    </div>
                </div>
                <CardContent>
                    <>
                        <Typography>{location.address1}</Typography>
                        <Typography>
                            {location.city}, {location.state} {location.zip}
                        </Typography>
                        {location.invitation ? (
                            <>
                                <Typography>
                                    {renderEmail()}
                                    <Tooltip title={"Edit Invitation"}>
                                        <img
                                            data-testid={"edit-invitation-icon"}
                                            className={classes.invitation_content_edit}
                                            onClick={() => setShowInfoEditModal(!showInfoEditModal)}
                                            src={edit}
                                            alt={`Edit Icon for ${location.name}`}
                                        />
                                    </Tooltip>
                                </Typography>
                                {renderPhone()}
                                {renderRelatedLocations()}
                                <>
                                    <Button
                                        onClick={resend}
                                        disabled={!location.invitation.email}
                                        variant="outlined"
                                        size="small"
                                        color="primary">
                                        Resend Invitation
                                    </Button>
                                    <LocationSubscriptionsModal
                                        locationId={location.id}
                                        posType={location.posType}
                                        accountId={"no-account"}
                                    />
                                    <LocationDailyDetailModal locationId={location.id} />
                                </>
                                <ForceDisconnectButton
                                    locationId={location.id}
                                    disconnected={location.disconnected}
                                    show={deactivationStatus?.canDisconnect ?? false}
                                    onClose={refresh}
                                />
                                <SetAwaitingDisconnectButton
                                    locationId={location.id}
                                    onClose={refresh}
                                    show={deactivationStatus?.showSetAwaitingDisconnect ?? false}
                                    isDisabled={deactivationStatus?.canSetAwaitingDisconnect ?? false}
                                    isAwaitingDisconnect={location.awaitingDisconnect}
                                />
                                <DeactivateLocationButton
                                    locationId={location.id}
                                    disconnectReasonCode={location.disconnectReasonCode ?? ""}
                                    disconnectReasonNote={location.disconnectReasonNote ?? ""}
                                    show={deactivationStatus?.showDeactivate ?? false}
                                    isDisabled={!location.invitation.canDeactivate}
                                    onClose={refresh}
                                    toolTip={!location.invitation.canDeactivate ? "Cannot be deactivated" : ""}
                                />
                                <ExtractLocationButton
                                    id={{ invitationId: location.invitation.id }}
                                    relatedLocations={location.invitation.relatedLocations || []}
                                    locationId={location.id}
                                    locationName={location.name}
                                />
                            </>
                        ) : (
                            <p style={{ color: "rgb(224, 60, 117)" }}>Could not find an invitation for this location</p>
                        )}
                    </>
                </CardContent>
            </Card>
            {showInfoEditModal && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    data-testid={"edit-invitation-modal"}
                    onClose={() => {
                        setShowInfoEditModal(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Edit Invitation</DialogTitle>
                    <DialogContent style={{ padding: "0px 24px" }} data-testid={"edit-invitation-form"}>
                        <TextField
                            label={"Email"}
                            value={email ?? ""}
                            onChange={setEmail}
                            isMultiline={true}
                            rows={2}
                        />
                        <TextField
                            label={"Phone"}
                            value={phone ?? ""}
                            onChange={setPhone}
                            isMultiline={true}
                            rows={2}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={doSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
