import { getBillingHistory, useModalEditor } from "@davo/portal-common";
import {
    IAchTransactionUpdate,
    IBillingInfo,
    getBillingStatus,
    initialCap,
    toDisplayDateTimeString,
} from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import orderBy from "lodash/orderBy";
import React, { CSSProperties, useState } from "react";
import useAsyncEffect from "use-async-effect";
import davoLogo from "./resources/davo_logo.png";

const thStyle: CSSProperties = {
    display: "table-cell",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgb(224, 224, 224)",
    textAlign: "left",
    padding: "14px 40px 14px 16px",
    fontWeight: "normal",
};

const rowStyle: CSSProperties = {
    display: "table-cell",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgb(224, 224, 224)",
    textAlign: "left",
    color: "rgb(152, 169, 188)",
    padding: "14px 40px 14px 16px",
};

interface IViewBillingHistoryModalType {
    accountId: string;
    info: IBillingInfo;
}

export function ViewBillingHistoryModal({ accountId, info }: IViewBillingHistoryModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [history, setHistory] = useState<IAchTransactionUpdate[]>([]);

    useAsyncEffect(async () => {
        if (isDialogOpen) {
            const hist: IAchTransactionUpdate[] = await getBillingHistory(accountId, info.id);
            setHistory(orderBy(hist, ["timestamp"], ["desc"]));
        }
    }, [info, isDialogOpen]);

    const shutdown = () => {
        closeDialog();
    };

    return (
        <>
            <Button variant="outlined" onClick={showDialog} size="small" color="primary" disabled={!info.achId}>
                History
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        shutdown();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Billing History</DialogTitle>
                    <DialogContent>
                        <div style={{ margin: "0 auto", textAlign: "center" }}>
                            <img src={davoLogo} alt="DAVO Logo" style={{ margin: "0 auto", width: "140px" }} />
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <td style={thStyle}>Status</td>
                                    <td style={thStyle}>Note</td>
                                    <td style={thStyle}>Date</td>
                                </tr>
                            </thead>

                            <tbody>
                                {history.map((historyEvent: IAchTransactionUpdate) => (
                                    <tr key={historyEvent.id}>
                                        <td style={rowStyle}>
                                            {initialCap(getBillingStatus(info, historyEvent.status))}
                                        </td>
                                        <td style={rowStyle}>{historyEvent.statusNotes}</td>
                                        <td style={rowStyle}>{toDisplayDateTimeString(historyEvent.timestamp)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => shutdown()} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
