import React from "react";

import { d30Toast } from "@davo/portal-common";
import { initialCap, SampleEmailType } from "@davo/types";
import { sendSampleEmail } from "./services";

const sendEmail = async (type: SampleEmailType) => {
    await sendSampleEmail(type);

    d30Toast("Email sent");
};

const stripAllSpaces = (str: string) => {
    return str.replace(/ /g, "");
};

const renderEmailTypes = (groupLabel: string, emailTypes: SampleEmailType[]) => {
    const results = [];
    for (const type of emailTypes) {
        let typeName: string = SampleEmailType[type];

        const groupLabelNoSpaces = stripAllSpaces(groupLabel.toLowerCase());
        if (typeName.toLowerCase().startsWith(groupLabelNoSpaces)) {
            // If the group has the same prefix as the type itself simplify by stripping out the prefix
            typeName = typeName.substring(groupLabelNoSpaces.length);
        }

        const description = initialCap(
            typeName
                .toString()
                .replace(/([A-Z])/g, " $1")
                .trim()
        );

        results.push(
            <li key={typeName} style={{ padding: "10px" }}>
                <span style={{ width: "310px", display: "inline-block", verticalAlign: "middle" }}>{description}</span>
                <button
                    style={{ verticalAlign: "middle" }}
                    onClick={async () => {
                        await sendEmail(type);
                    }}>
                    Send
                </button>
            </li>
        );
    }

    return results;
};

const renderSampleEmails = () => {
    const results: any = [];

    const grouped = [
        {
            label: "Invitations",
            emailTypes: [
                SampleEmailType.BusinessInvitation,
                SampleEmailType.UserInvitation,
                SampleEmailType.PartnerInvitation,
                SampleEmailType.AddOpsUser,
            ],
        },
        {
            label: "Daily Sales Tax Report",
            emailTypes: [
                SampleEmailType.DailySalesTaxReportSingleLocation,
                SampleEmailType.DailySalesTaxReportSingleLocationSalesNoTax,
                SampleEmailType.DailySalesTaxReportSingleLocationMissingBank,
                SampleEmailType.DailySalesTaxReportSingleLocationMissingTax,
                SampleEmailType.DailySalesTaxReportSingleLocationFailingTaxCredentials,
                SampleEmailType.DailySalesTaxReportSingleLocationUntestedTaxCredentials,
                SampleEmailType.DailySalesTaxReportSingleLocationMissingBankAndTax,
                SampleEmailType.DailySalesTaxReportSingleLocationBankAccountPaused,
                SampleEmailType.DailySalesTaxReportSingleLocationBankAccountUnpauseDate,
                SampleEmailType.DailySalesTaxReportSingleLocationBankAccountPausedMerchantPay,
                SampleEmailType.DailySalesTaxReportSingleLocationCloverNewPaymentBanner,
                SampleEmailType.DailySalesTaxReportMultipleLocations,
                SampleEmailType.DailySalesTaxReportMultipleLocationsMissingBank,
                SampleEmailType.DailySalesTaxReportMultipleLocationsMissingTax,
                SampleEmailType.DailySalesTaxReportMultipleLocationsFailingTaxCredentials,
                SampleEmailType.DailySalesTaxReportMultipleLocationsUntestedTaxCredentials,
                SampleEmailType.DailySalesTaxReportMultipleLocationsMissingBankAndTax,
                SampleEmailType.DailySalesTaxReportMultipleLocationsBankAccountPaused,
                SampleEmailType.DailySalesTaxReportMultipleLocationsBankAccountUnpauseDate,
                SampleEmailType.DailySalesTaxReportMultipleLocationsBankAccountPausedMerchantPay,
                SampleEmailType.DailySalesTaxReportMultipleLocationsCloverNewPaymentBanner,
            ],
        },
        {
            label: "Daily Summary",
            emailTypes: [
                SampleEmailType.DailySummaryFourLocationsTwoBanks,
                SampleEmailType.DailySummaryOneLocationShowingGroupLoginNYBanner,
                SampleEmailType.DailySummaryTwoLocationsNoBanks,
                SampleEmailType.DailySummaryTwoLocationsOneBank,
                SampleEmailType.DailySummaryTwoLocationsTwoBanks,
                SampleEmailType.DailySummaryTwoLocationsThreeBanks,
                SampleEmailType.DailySummaryNoLocationsTwoBanks,
                SampleEmailType.DailySummaryTenLocationsTwoBanks,
            ],
        },
        {
            label: "Monthly Tax Report",
            emailTypes: [SampleEmailType.NoMonthlyTax, SampleEmailType.NoMonthlySales],
        },
        {
            label: "No Account",
            emailTypes: [SampleEmailType.NoAccountSingleLocation, SampleEmailType.NoAccountMultipleLocations],
        },
        {
            label: "No Banking",
            emailTypes: [
                SampleEmailType.NoBankingSingleLocationMissingBank,
                SampleEmailType.NoBankingMultipleLocationsMissingBankAndTax,
                SampleEmailType.NoBankingSingleLocationAwaitingDeactivation,
                SampleEmailType.NoBankingMultipleLocationsAwaitingDeactivation,
            ],
        },
        { label: "No Tax Profile", emailTypes: [SampleEmailType.NoTaxProfileSetupReminder] },
        {
            label: "Filing Confirmation",
            emailTypes: [
                SampleEmailType.FilingConfirmationFullyPaid,
                SampleEmailType.FilingConfirmationPartialPayment,
                SampleEmailType.FilingConfirmationHasDiscount,
                SampleEmailType.FilingConfirmationHasMerchantNote,
                SampleEmailType.FilingConfirmationZeroPayment,
                SampleEmailType.FilingConfirmationNoSales,
                SampleEmailType.FilingConfirmationFullyPaidPrepayment,
                SampleEmailType.FilingConfirmationPartialPrepayment,
                SampleEmailType.FilingConfirmationZeroPrepayment,
                SampleEmailType.FilingConfirmationNewMerchantPartialPayment,
                SampleEmailType.FilingConfirmationWashingtonMerchant,
                SampleEmailType.FilingConfirmationPausedBankAccount,
            ],
        },
        {
            label: "Filing Returned",
            emailTypes: [SampleEmailType.FilingReturnedBasic],
        },
        {
            label: "Welcome / New Boarding",
            emailTypes: [
                SampleEmailType.NewBoardingWelcome,
                SampleEmailType.NewBoardingWelcomeQB,
                SampleEmailType.NewBoardingWelcomePartner,
                SampleEmailType.NewBoardingWelcomePartnerAndQB,
                SampleEmailType.NewBoardingWelcomeBeta,
            ],
        },
        {
            label: "Failing",
            emailTypes: [
                SampleEmailType.TaxProfileFailingCredsPassword,
                SampleEmailType.TaxProfileFailingCredsPasswordUpcoming,
                SampleEmailType.TaxProfileFailingCredsPasswordLate,
                SampleEmailType.TaxProfileFailingCredsPin,
                SampleEmailType.TaxProfileFailingCredsTwoFactor,
                SampleEmailType.TaxProfileFailingCredsRegistration,
                SampleEmailType.TaxProfileFailingCredsRegistrationState,
                SampleEmailType.TaxProfileFailingCredsRegistrationWebfile,
            ],
        },
        {
            label: "Billing",
            emailTypes: [
                SampleEmailType.BillingNotice,
                SampleEmailType.BillingNoticeNoCharge,
                SampleEmailType.BillingFailure,
                SampleEmailType.ManualSetAsideEmail,
                SampleEmailType.ManualSetAsideReturnEmail,
                SampleEmailType.BillingRefund,
                SampleEmailType.BankAccountPaused,
            ],
        },
        {
            label: "Internal",
            emailTypes: [
                SampleEmailType.BoardingNotification,
                SampleEmailType.DisconnectNotificationBoarded,
                SampleEmailType.DisconnectNotificationNotBoarded,
            ],
        },
    ];

    const allSampleEmailTypes = Object.keys(SampleEmailType)
        .filter((enumNameOrIndex) => isNaN(Number(enumNameOrIndex)))
        .map((val) => {
            return SampleEmailType[val as keyof typeof SampleEmailType];
        });

    const usedEmailTypes = new Set(
        grouped.flatMap((item) => {
            return item.emailTypes;
        })
    );

    const remainingEmailTypes = allSampleEmailTypes.filter((val) => {
        return !usedEmailTypes.has(val);
    });

    grouped.push({ label: "Other", emailTypes: remainingEmailTypes });

    for (const group of grouped) {
        results.push(
            <div key={group.label}>
                <div style={{ display: "inline-block", breakInside: "avoid-column" }}>
                    <h3>{group.label}</h3>
                    <div style={{ marginLeft: "40px" }}>{renderEmailTypes(group.label, group.emailTypes)}</div>
                </div>
            </div>
        );
    }

    return results;
};

export function SendSampleEmails() {
    React.useEffect(() => document.querySelectorAll("button")[0]?.focus(), []);
    return (
        <div>
            <h1>Send sample emails</h1>
            <ul style={{ columnCount: 3 }}>{renderSampleEmails()}</ul>
        </div>
    );
}
