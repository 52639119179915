import { d30ToastError, Loading, ReactTable } from "@davo/portal-common";
import { FilingUpdateAuditType, User } from "@davo/types";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getFilingHistoryAudit } from "./services";

export const FilingUpdateAudit = ({ filingId }: { filingId: string }) => {
    const [filingUpdateAudit, setfilingUpdateAudit] = useState<FilingUpdateAuditType & { user: User }[]>();

    const refresh = useCallback(() => {
        getFilingHistoryAudit(filingId)
            .then((results) => {
                setfilingUpdateAudit(results);
            })
            .catch((e) => d30ToastError("Problem loading data", e));
    }, [filingId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        return [
            {
                Header: "User",
                accessor: "user",
                disableSortBy: true,
                Cell: (data: any) => {
                    const u = data.cell.row.original.user;
                    return <>{`${u.firstName} ${u.lastName}`}</>;
                },
            },
            {
                Header: "Reason",
                id: "reason",
                accessor: "reason",
                disableSortBy: true,
                Cell: (data: any) => <>{data.cell.row.original.reason}</>,
            },
            {
                Header: "Created",
                id: "created",
                accessor: "ts",
                disableSortBy: true,
                Cell: (data: any) => {
                    const humanDate = DateTime.fromMillis(data.cell.row.original.created.ts)
                        .setZone("America/New_York")
                        .toLocaleString({
                            weekday: "short",
                            month: "short",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                        });
                    return <div>{humanDate}</div>;
                },
            },
        ];
    }, []);

    return (
        <>
            {!filingUpdateAudit ? (
                <Loading />
            ) : (
                <div>
                    <ReactTable
                        columns={columns}
                        data={filingUpdateAudit}
                        refreshData={refresh}
                        options={{
                            pageSize: 10,
                            headerRowStyle: { verticalAlign: "top" },
                        }}
                    />
                </div>
            )}
        </>
    );
};
