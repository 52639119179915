import {
    BodyText,
    d30Toast,
    d30ToastError,
    getBillingsForAccount,
    Loading,
    ReactTable,
    ViewBillingInvoiceModal,
} from "@davo/portal-common";
import { getBillingStatus, IBillingInfo, initialCap, Location, money, toDisplayDateTimeString } from "@davo/types";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import LoopIcon from "@mui/icons-material/Loop";
import { Button, Tooltip, Typography } from "@mui/material";
import has from "lodash/has";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row } from "react-table";
import { RebillModalButton } from "./RebillModalButton";
import { voidBilling } from "./services";
import { ViewBillingHistoryModal } from "./ViewBillingHistoryModal";
import { ViewBillingRefundModal } from "./ViewBillingRefundModal";

const colors = {
    pending: "#FFAB2B",
    posted: "#FFAB2B",
    settling: "#6DD230",
    funded: "#6DD230",
    settled: "#6DD230",
    rejected: "#FE4D97",
    voided: "#FE4D97",
    stopped: "#FE4D97",
    returned: "#FF4D97",
} as { [key: string]: string };

const amountFormat = (data: any) => {
    const factor = data.cell.row.original.isRefunded ? -1 : 1;
    const totalAmount = +data.cell.row.original.amount * factor + +data.cell.row.original.totalSalesTax;
    return totalAmount ? money(totalAmount / 100) : "0.00";
};

const renderType = (data: any) => {
    if (data.cell.row.original.pull === true) {
        return `Payment`;
    }

    return `Refund`;
};

const renderStatus = (data: any) => {
    const s = getBillingStatus(data.cell.row.original, data.value);

    if (!s) {
        return "--";
    }

    return (
        <>
            {!data.cell.row.original.achId && "Completed"}
            {data.cell.row.original.achStatusNotes && (
                <Tooltip title={data.cell.row.original.achStatusNotes} placement="top">
                    <div
                        style={{
                            float: "left",
                            borderRadius: "4px",
                            fontWeight: "bold",
                            color: colors[s && s.toLowerCase()],
                        }}>
                        {s && initialCap(s)}
                    </div>
                </Tooltip>
            )}
            {data.cell.row.original.refundReason && (
                <Tooltip title={`[Refunded] ${data.cell.row.original.refundReason}`} placement="top">
                    <div
                        style={{
                            float: "left",
                            fontSize: ".8em",
                            marginLeft: "10px",
                            marginTop: "-2px",
                            borderRadius: "4px",
                            color: "#5a5a5a",
                        }}>
                        <LoopIcon />
                    </div>
                </Tooltip>
            )}
        </>
    );
};

export function BillingsTable({
    accountId,
    cloverLocations,
    poyntLocations,
    isPartner,
}: {
    accountId?: string;
    cloverLocations: Location[];
    poyntLocations: Location[];
    isPartner: boolean;
}) {
    const [billings, setBillings] = useState<IBillingInfo[]>();
    const [relatedBillings, setRelatedBillings] = useState<{ [key: string]: IBillingInfo[] }>({});

    const refresh = useCallback(() => {
        if (!accountId) {
            return;
        }
        setBillings(undefined);
        setRelatedBillings({});

        const relatedBillingsByInvoice: { [key: string]: IBillingInfo[] } = {};

        getBillingsForAccount(accountId)
            .then((results) => {
                const filtered = [];

                for (const row of results) {
                    const invoiceId = row.billingInvoiceId!;
                    if (!has(relatedBillingsByInvoice, invoiceId)) {
                        // Filter results to just include the first row for each invoice
                        filtered.push(row);
                        relatedBillingsByInvoice[invoiceId] = [row];
                    } else {
                        relatedBillingsByInvoice[invoiceId].push(row);
                    }
                }

                setRelatedBillings(relatedBillingsByInvoice);
                setBillings(filtered);
            })
            .catch((e) => d30ToastError("Problem getting billings for account", e));
    }, [accountId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const doVoid = useCallback(
        (info: IBillingInfo) => {
            if (!accountId) {
                return;
            }
            if (info.achId) {
                voidBilling(accountId, info.achId)
                    .then(() => {
                        refresh();
                        d30Toast("Transaction voided");
                    })
                    .catch((e) => {
                        d30ToastError("Transaction could not be voided at this time", e);
                    });
            } else {
                d30ToastError("No transaction on original billing");
            }
        },
        [accountId, refresh]
    );

    const renderActions = useCallback(
        (data: any) => {
            if (!accountId) {
                return;
            }

            const row: IBillingInfo = data.cell.row.original;

            return (
                <>
                    <ViewBillingRefundModal accountId={accountId} info={row} cb={() => refresh()} />
                    <Button
                        style={{ margin: "10px" }}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        disabled={row.achStatus !== "pending" && row.achStatus !== "hold" && row.achStatus !== "posted"}
                        onClick={() => doVoid(row)}>
                        Void
                    </Button>

                    {row.canRebill && row.billingInvoiceId && row.invoice && (
                        <RebillModalButton
                            invoiceId={row.billingInvoiceId}
                            invoiceDate={row.invoice.date}
                            amount={row.amount}
                            onRebill={refresh}
                        />
                    )}
                </>
            );
        },
        [accountId, doVoid, refresh]
    );

    const subTableColumns = useMemo(() => {
        if (!accountId) {
            return [];
        }

        return [
            {
                Header: "Date",
                accessor: "created",
                Cell: (d: any) => toDisplayDateTimeString(d.value),
                disableSortBy: true,
            },
            {
                Header: "Status",
                accessor: "achStatus",
                Cell: (d: any) => <div style={{ textAlign: "left" }}>{renderStatus(d)}</div>,
            },
            {
                Header: "Type",
                accessor: "pull",
                Cell: (d: any) => <div style={{ textAlign: "left" }}>{renderType(d)}</div>,
            },
            {
                Header: "Amount",
                accessor: "amount",
                disableSortBy: true,
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{amountFormat(data)}</div>,
            },
            { Header: "", accessor: "achId", id: "actions", Cell: renderActions },
            {
                Header: "",
                accessor: "achId",
                id: "modals",
                Cell: (d: any) => {
                    const info: IBillingInfo = d.cell.row.original;
                    return (
                        <>
                            <ViewBillingHistoryModal info={info} accountId={accountId} />
                        </>
                    );
                },
            },
        ];
    }, [accountId, renderActions]);

    const renderSubTable = useCallback(
        (data: any) => {
            if (!accountId) {
                return;
            }
            const row: IBillingInfo = data.row.original;
            const related = relatedBillings[row.billingInvoiceId!];

            return (
                <div style={{ margin: "20px" }}>
                    <ReactTable
                        columns={subTableColumns}
                        refreshData={refresh}
                        data={related}
                        options={{
                            pageSize: related.length,
                            pageSizeOptions: [related.length],
                            hideToolbar: true,
                        }}
                    />
                </div>
            );
        },
        [accountId, refresh, relatedBillings, subTableColumns]
    );

    const cloverBillingStatus = useCallback(() => {
        const troubleLocations = cloverLocations.filter(
            (x) =>
                x.active &&
                x.cloverBillingStatus &&
                (x.cloverBillingStatus?.status === "INACTIVE" || x.cloverBillingStatus?.status === "LAPSED")
        );
        const result = [];
        if (troubleLocations.length === 0) {
            return null;
        }
        result.push(
            <div>
                <BodyText>The following Clover locations have billing issues to resolve:</BodyText>
            </div>
        );
        for (const location of troubleLocations) {
            result.push(
                <div>
                    <InfoIcon color="secondary" style={{ verticalAlign: "middle", marginRight: "10px" }} />
                    <Typography variant="body1" color="textSecondary" style={{ display: "inline-block" }}>
                        {location.name}: {location.cloverBillingStatus?.status}{" "}
                        {location.cloverBillingStatus?.daysLapsed} days
                    </Typography>
                </div>
            );
        }
        result.push(<br />);
        return result;
    }, [cloverLocations]);

    const billingsColumns = useMemo(() => {
        if (!accountId) {
            return [];
        }
        return [
            {
                Header: "",
                accessor: "id",
                Cell: ({ row }: { row: Row }) => {
                    const info: IBillingInfo = row.original as IBillingInfo;
                    const canExpand =
                        info && info.billingInvoiceId && relatedBillings[info.billingInvoiceId].length > 1;
                    if (!canExpand) {
                        return <></>;
                    }

                    return (
                        <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </span>
                    );
                },
            },
            {
                Header: "Period",
                accessor: "period",
                Cell: (data: any) => {
                    const dt = DateTime.fromFormat(data.value, "yyyy-MM");
                    return <>{dt > DateTime.utc() ? "Next" : dt.toFormat("MMM yyyy")}</>;
                },
                disableSortBy: true,
            },
            {
                Header: "Bank Account",
                accessor: "bank",
                disableSortBy: true,
                Cell: (data: any) => {
                    const { bank, accountStarred } = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "left" }}>
                            {bank ? (
                                <span>
                                    {bank} | {accountStarred}
                                </span>
                            ) : (
                                "-----"
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "Amount",
                accessor: "amount",
                disableSortBy: true,
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{amountFormat(data)}</div>,
            },
            {
                Header: "Status",
                accessor: "achStatus",
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{renderStatus(data)}</div>,
            },
            { Header: "", accessor: "achId", id: "actions", Cell: renderActions },
            {
                Header: "",
                accessor: "achId",
                id: "modals",
                Cell: (data: any) => {
                    const info: IBillingInfo = data.cell.row.original;
                    const invoiceId = info.billingInvoiceId;

                    return (
                        <>
                            {invoiceId && relatedBillings[invoiceId].length === 1 && (
                                <ViewBillingHistoryModal info={info} accountId={accountId} />
                            )}
                            <ViewBillingInvoiceModal info={info} accountId={accountId} />
                        </>
                    );
                },
            },
        ];
    }, [accountId, relatedBillings, renderActions]);

    if (!accountId || !billings) {
        return <Loading />;
    }
    return (
        <div style={{ width: "100%" }}>
            {cloverBillingStatus()}
            {cloverLocations.length > 0 && (
                <>
                    <Typography variant="body1" color="textSecondary" style={{ display: "inline-block" }}>
                        For Clover related billing use their{" "}
                    </Typography>{" "}
                    <a
                        target="_blank"
                        href={`https://www.clover.com/developer-home/QCRD5T0ZX1HSG/billing/transactions`}
                        style={{ color: "inherit" }}
                        rel="noreferrer">
                        Portal
                    </a>
                </>
            )}
            {poyntLocations.length > 0 && (
                <BodyText>See billing coordinator for more information related to Poynt billing questions</BodyText>
            )}
            {isPartner && (
                <BodyText>See billing coordinator for more information related to Partner billing questions</BodyText>
            )}
            <div>
                <ReactTable
                    columns={billingsColumns}
                    refreshData={refresh}
                    data={billings}
                    options={{
                        hideToolbar: true,
                    }}
                    renderRowSubComponent={renderSubTable}
                />
            </div>
        </div>
    );
}
