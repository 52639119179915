import { FilingStatus, Settlement } from "@davo/types";
import { DateTime } from "luxon";

export interface IClosingInfoType {
    status?: FilingStatus | null;
    taxDue?: number | null;
    taxDuePart1?: number | null;
    taxDuePart2?: number | null;
    taxDuePart3?: number | null;
    discount?: number | null;
    discountPart1?: number | null;
    discountPart2?: number | null;
    discountPart3?: number | null;
    fees?: number | null;
    remitted?: number | null;
    remittedPart2?: number | null;
    remittedPart3?: number | null;
    remittedPart4?: number | null;
    remittedPart5?: number | null;
    remittedDirect?: number | null;
    returned?: number | null;
    note?: string | null;
    merchantNote?: string | null;
    sendMerchantEmail?: boolean | null;
    updateReason?: string | null;
    duration?: number | null;
    reviewed?: DateTime | null;
    reviewedById?: string | null;
    reviewNote?: string | null;
    updateAsOf?: boolean | null;
}

export interface IFundingStatusType {
    tax: number;
    remitted: number;
    status: Settlement;
}

export const getMaxAvailable = (fundingStatus: IFundingStatusType): number => {
    if (fundingStatus) {
        return (
            +(fundingStatus.status.fundedIn ?? 0) +
            +(fundingStatus.status.transferred ?? 0) -
            +(fundingStatus.remitted ?? 0) +
            +(fundingStatus.status.pendingOut ?? 0) +
            +(fundingStatus.status.fundedOut ?? 0)
        );
    } else {
        return 0;
    }
};

export const statusLabel = {
    filed: "Filed",
    returned: "Return to Merchant",
    wontfile: "Will Not File",
    open: "Open",
    reviewed: "Reviewed",
} as { [key in FilingStatus]: string };

export const calculateAmountDue = (closingInfo: IClosingInfoType) => {
    return (
        (closingInfo.taxDuePart1 || 0) +
        (closingInfo.taxDuePart2 || 0) +
        (closingInfo.taxDuePart3 || 0) -
        (closingInfo.discountPart1 || 0) -
        (closingInfo.discountPart2 || 0) -
        (closingInfo.discountPart3 || 0) +
        (closingInfo.fees || 0)
    );
};
