import {
    d30Toast,
    d30ToastError,
    DavoLink,
    Loading,
    ReactTable,
    TextField,
    updateAccountEmailPreferences,
    updateAccountPermissions,
    updateUserSummaryPreferences,
    useLoginContext,
} from "@davo/portal-common";
import { AccountRecord, OptionalString, toDisplayDateString, User } from "@davo/types";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import isEqual from "lodash/isEqual";
import React, { useCallback, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AddUserPermissionsModal } from "./AddUserPermissionsModal";
import "./css/sms.css";
import { EmailHistoryPane } from "./EmailHistoryPane";
import { HubSpotLink } from "./HubSpotLink";
import { PostmarkLink } from "./PostmarkLink";
import { PostmarkSuppressionsLink } from "./PostmarkSuppressionsLink";
import {
    checkUserLockStatus,
    getAllAccountsForUser,
    resumeUserEmail,
    resumeUserSMS,
    sendPasswordResetEmail,
    unlockUser,
    updateUser,
} from "./services";
import { SmsHistoryPane } from "./SmsHistoryPane";
import { SmsPane } from "./SmsPane";
import { UserPermissionsPane } from "./UserPermissionsPane";
import { displayPhone, hasPermission } from "./util";

export function UserDetailPane({ userArg }: { userArg: User }) {
    const loginContext = useLoginContext();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<(AccountRecord & { hasActiveLocations: boolean })[]>([]);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [userDetail, setUserDetail] = useState<User>(userArg);
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [isAddUserPermissionsDialogOpen, setIsAddUserPermissionsDialogOpen] = useState<boolean>(false);
    const [update, setUpdate] = useState<number>(0);

    const fields = () => ({
        firstName: userDetail ? userDetail.firstName : userArg.firstName,
        lastName: userDetail ? userDetail.lastName : userArg.lastName,
        phone: userDetail ? userDetail.phone : userArg.phone,
        doNotSms: userDetail ? userDetail.doNotSms : userArg.doNotSms,
    });

    const [copy, setCopy] = useState<{
        firstName: OptionalString;
        lastName: OptionalString;
        phone: OptionalString;
        doNotSms: boolean;
    }>(fields());

    const isSame = isEqual(fields(), copy);

    useAsyncEffect(async () => {
        await getAccountsAndPermissions();
    }, [isEditing]);

    const doUpdate = useCallback(() => {
        if (isSame || isBusy) {
            return;
        }
        setIsBusy(() => true);
        updateUser(userDetail.id, { ...copy, id: userDetail.id })
            .then((u: User) => {
                setUserDetail(u);
                d30Toast("Profile updated!");
            })
            .catch((e) => d30ToastError("Could not update user!", e))
            .finally(() => {
                setIsEditing(false);
                setIsBusy(false);
            });
    }, [copy, isBusy, isSame, userDetail.id]);

    const getAccountsAndPermissions = useCallback(async () => {
        const a = await getAllAccountsForUser(userDetail.id);
        setAccounts(a);
        setIsLocked(await checkUserLockStatus(userDetail.id));
    }, [userDetail.id]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Name",
                accessor: "name",
                Cell: (data: any) => (
                    <DavoLink to={`/accounts/${data.row.original.id}`}>
                        {data.row.original.hasActiveLocations
                            ? data.row.original.name
                            : `${data.row.original.name} - Inactive`}
                    </DavoLink>
                ),
            },
            {
                Header: "Admin?",
                accessor: "role",
                Cell: (data: any) => {
                    const isOnlyAdmin = data.row.original.role === "admin" && data.row.original.numAdmins <= 1;

                    return (
                        <Tooltip title={isOnlyAdmin ? "At least one admin user is required." : ""}>
                            <div>
                                <Switch
                                    checked={data.row.original.role === "admin"}
                                    color="primary"
                                    disabled={isOnlyAdmin}
                                    onChange={() => {
                                        let newRole = "user";
                                        if (data.row.original.role === "user") {
                                            newRole = "admin";
                                        }

                                        updateAccountPermissions(userDetail.id, data.row.original.id, newRole)
                                            .then(() => getAccountsAndPermissions())
                                            .catch((e) =>
                                                d30ToastError(
                                                    "Could not update account permissions -- this may be the last admin for the account",
                                                    e
                                                )
                                            );
                                    }}
                                />
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                Header: "Receiving daily sales tax email",
                accessor: "emailOptOut",
                Cell: (data: any) => (
                    <Switch
                        checked={!data.row.original.emailOptOut}
                        color="primary"
                        onChange={async () => {
                            await updateAccountEmailPreferences(
                                userDetail.id,
                                data.row.original.id,
                                !data.row.original.emailOptOut
                            );
                            await getAccountsAndPermissions();
                        }}
                    />
                ),
            },
            {
                Header: "Receiving daily summary email",
                accessor: "summaryOptIn",
                Cell: (data: any) => (
                    <Switch
                        checked={data.row.original.summaryOptIn}
                        color="primary"
                        onChange={async () => {
                            await updateUserSummaryPreferences(
                                userDetail.id,
                                data.row.original.id,
                                !data.row.original.summaryOptIn
                            );
                        }}
                    />
                ),
            },
        ];
    }, [getAccountsAndPermissions, userDetail.id]);

    if (!loginContext.user) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumbs
                style={{ marginBottom: 10, marginLeft: 10 }}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb">
                <DavoLink to="/users">Users</DavoLink>
                <Typography color="textPrimary">{`${userDetail.firstName}...`}</Typography>
            </Breadcrumbs>
            <Card>
                <CardHeader
                    title={
                        <>
                            {`${userDetail.firstName ?? ""} ${userDetail.lastName ?? ""}`}
                            <HubSpotLink type={"user"} davoId={userDetail.id} />
                        </>
                    }
                    subheader={
                        <div>
                            Email: <a href={`mailto:${userDetail.email}`}>{userDetail.email}</a>
                            <PostmarkLink email={userDetail.email} /> <br />
                            Phone: {userDetail.phone ? displayPhone(userDetail.phone) : ""} <br />
                            SMS Opted Out: {userDetail.doNotSms ? "yes" : "no"} <br />
                            Locked: {isLocked ? "yes" : "no "}
                            <br />
                            {isLocked ? (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        style={{ marginTop: "10px" }}
                                        onClick={() => {
                                            unlockUser(userDetail.id)
                                                .then(() => checkUserLockStatus(userDetail.id))
                                                .then((isResult) => setIsLocked(isResult))
                                                .catch((e) => d30ToastError("Problem unlocking user", e));
                                        }}>
                                        Unlock
                                    </Button>{" "}
                                    <br />
                                </>
                            ) : (
                                ""
                            )}
                            Referral code: {userDetail.referralCode}
                            <br />
                            Email status:{" "}
                            {userDetail.emailUndeliverable ? (
                                <>
                                    Suspended as of {toDisplayDateString(userDetail.emailUndeliverable)}
                                    <PostmarkSuppressionsLink />
                                </>
                            ) : (
                                "Normal"
                            )}
                            <br />
                            {userDetail.emailUndeliverable && (
                                <div>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        style={{ marginTop: "10px", marginBottom: "10px" }}
                                        onClick={() => {
                                            setIsBusy(true);

                                            resumeUserEmail(userDetail.id)
                                                .then((u: User) => {
                                                    setUserDetail(u);
                                                    d30Toast(
                                                        `Success! We will resume sending emails to ${userDetail.email}.`
                                                    );
                                                })
                                                .catch((e) => d30ToastError("Could not update user!", e))
                                                .finally(() => {
                                                    setIsBusy(false);
                                                });
                                        }}>
                                        Resume sending emails
                                    </Button>
                                </div>
                            )}
                            SMS status:{" "}
                            {userDetail.smsUndeliverable
                                ? `Suspended as of ${toDisplayDateString(userDetail.smsUndeliverable)}`
                                : "Normal"}
                            <br />
                            {userDetail.smsUndeliverable && (
                                <div>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        style={{ marginTop: "10px", marginBottom: "10px" }}
                                        onClick={() => {
                                            setIsBusy(true);

                                            resumeUserSMS(userDetail.id)
                                                .then((u: User) => {
                                                    setUserDetail(u);
                                                    d30Toast(
                                                        `Success! We will resume sending text messages to ${userDetail.phone}.`
                                                    );
                                                })
                                                .catch((e) => d30ToastError("Could not update user!", e))
                                                .finally(() => setIsBusy(false));
                                        }}>
                                        Resume sending SMS
                                    </Button>
                                </div>
                            )}
                            {!["Superuser", "Admin"].includes(userDetail.type) && (
                                <Button
                                    variant="outlined"
                                    disabled={!userDetail.email}
                                    size="small"
                                    color="primary"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        sendPasswordResetEmail(userDetail.email)
                                            .then(() => {
                                                d30Toast("Reset email sent");
                                            })
                                            .catch(() => {
                                                d30ToastError("There was a problem resetting email");
                                            });
                                    }}
                                    style={{ marginTop: "10px", marginRight: "10px", textTransform: "uppercase" }}>
                                    Send Reset Email
                                </Button>
                            )}
                            {hasPermission(loginContext.permissions, "manage_user_permissions") && (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            setIsAddUserPermissionsDialogOpen(true);
                                        }}
                                        style={{ marginTop: "10px", textTransform: "uppercase" }}>
                                        Add Permissions
                                    </Button>
                                    {isAddUserPermissionsDialogOpen && (
                                        <AddUserPermissionsModal
                                            user={userDetail}
                                            setOpen={setIsAddUserPermissionsDialogOpen}
                                            refresh={() => setUpdate(() => update + 1)}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    }
                    action={
                        <IconButton aria-label="edit" onClick={() => setIsEditing(!isEditing)} size="large">
                            <EditIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    {isEditing && (
                        <div>
                            <Grid container direction="row" style={{ padding: "30px" }}>
                                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                                    <TextField
                                        label="First Name"
                                        value={copy.firstName ? copy.firstName : ""}
                                        onChange={(firstName) =>
                                            setCopy(() => {
                                                return { ...copy, firstName };
                                            })
                                        }
                                        onEnterPress={doUpdate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                                    <TextField
                                        label="Last Name"
                                        value={copy.lastName ? copy.lastName : ""}
                                        onChange={(lastName) =>
                                            setCopy(() => {
                                                return { ...copy, lastName };
                                            })
                                        }
                                        onEnterPress={doUpdate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                                    <TextField
                                        label="Phone"
                                        value={copy.phone ? copy.phone : ""}
                                        onChange={(phone) =>
                                            setCopy(() => {
                                                return { ...copy, phone };
                                            })
                                        }
                                        onEnterPress={doUpdate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                                    <Switch
                                        checked={copy.doNotSms}
                                        onChange={() =>
                                            setCopy(() => {
                                                return { ...copy, doNotSms: !copy.doNotSms };
                                            })
                                        }
                                    />
                                    &nbsp;&nbsp;SMS Opt Out
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", whiteSpace: "nowrap" }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginRight: "15px" }}
                                        onClick={() => {
                                            setCopy(fields());
                                            setIsEditing(false);
                                        }}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={doUpdate}>
                                        Save Changes
                                    </Button>
                                </Grid>
                            </Grid>
                            <div>To change email please invite new email address.</div>
                        </div>
                    )}
                    {!isEditing && accounts.length > 0 && (
                        <ReactTable
                            title="Accounts"
                            columns={columns}
                            data={accounts}
                            refreshData={getAccountsAndPermissions}
                            options={{
                                hideToolbar: true,
                            }}
                        />
                    )}
                </CardContent>
            </Card>
            {["Merchant"].includes(userDetail.type) && (
                <>
                    <Accordion
                        slotProps={{ transition: { unmountOnExit: true } }}
                        style={{ border: "none", boxShadow: "none" }}
                        defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                            Email History
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <EmailHistoryPane userDetail={userDetail} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        slotProps={{ transition: { unmountOnExit: true } }}
                        style={{ border: "none", boxShadow: "none" }}
                        defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                            SMS History
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <SmsHistoryPane userDetail={userDetail} />
                        </AccordionDetails>
                    </Accordion>
                    <SmsPane recipientObj={userDetail} accounts={accounts} />
                </>
            )}
            <UserPermissionsPane key={update} userArg={userDetail} />
        </div>
    );
}
