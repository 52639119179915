import {
    d30ToastError,
    getJurisdictionRateTypes,
    getJurisdictions,
    ReactTable,
    Select,
    sortBoolean,
} from "@davo/portal-common";
import { IJurisdiction, IJurisdictionRateType } from "@davo/types";
import { Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";

const fieldFormat = (data: any) => {
    return <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{data.value}</span>}</>;
};

export function JurisdictionRateTypePane() {
    const [states, setStates] = useState<IJurisdiction[] | undefined>();
    const [selectedState, setSelectedState] = useState<IJurisdiction | undefined>();
    const [rateTypesForState, setRateTypesForState] = useState<IJurisdictionRateType[]>();

    useAsyncEffect(async () => {
        setStates(await getJurisdictions());
    }, []);

    useEffect(() => {
        if (!selectedState) {
            return;
        }
        setRateTypesForState(undefined);
        getJurisdictionRateTypes(selectedState.id)
            .then((results) => setRateTypesForState(results))
            .catch((e) => d30ToastError("Problem getting jurisdiction rate types", e));
    }, [selectedState]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Name",
                accessor: "name",
                Cell: fieldFormat,
            },
            {
                Header: "Tag",
                accessor: "tag",
                Cell: fieldFormat,
            },
            {
                Header: "Tax Breakdown",
                accessor: "taxRateBreakdown",
                Cell: (data: any) => {
                    const trBreakdown = data.row.original.taxRateBreakdown;
                    if (!trBreakdown) {
                        return;
                    }
                    return (
                        <ul>
                            {Object.keys(trBreakdown).map((key, index) => (
                                <li key={index}>{`${key}: ${trBreakdown[key]}`}</li>
                            ))}
                        </ul>
                    );
                },
            },
            {
                Header: "Active",
                accessor: "active",
                sortType: sortBoolean("active"),
                Cell: (data: any) => <>{data.row.original.active ? "Yes" : "No"}</>,
            },
        ];
    }, []);

    if (!states) {
        return null;
    }
    return (
        <div>
            <Select<IJurisdiction>
                style={{ marginBottom: "20px", width: "300px" }}
                title="State"
                options={states}
                value={selectedState}
                noneLabel="Select a state..."
                label={(jurisdiction) => jurisdiction.fullName}
                onChange={(s) => {
                    setSelectedState(s);
                }}
                isRequired
            />

            {selectedState && !isEmpty(rateTypesForState) && (
                <ReactTable
                    columns={columns}
                    data={rateTypesForState ?? []}
                    options={{
                        pageSize: 10,
                        dataTestId: "jurisdictionRateTypesTable",
                    }}
                />
            )}

            {selectedState && isEmpty(rateTypesForState) && (
                <div data-testid={"noRateTypesFoundMessage"}>
                    <Typography style={{ marginTop: "20px" }} variant="body1">
                        No Rate Types Matching Search Criteria
                    </Typography>
                </div>
            )}
        </div>
    );
}
