import {
    AF_BULK_PROCESSING,
    AF_BULK_QUEUED,
    AF_ERRORED,
    AF_ERRORED_VALIDATION,
    AF_OPEN,
    AF_PROCESSING,
    AF_TESTED,
    AutofilerQueueEntry,
    hasMidMonthPaymentOrIsWeirdCA,
} from "@davo/types";
import isNil from "lodash/isNil";
import { IFilingSlimmed } from "./AutofilerButtonsMulti";

export const canTestAutofile = (canAutofile: boolean, filingEntry?: AutofilerQueueEntry) => {
    return (
        canAutofile &&
        // no filingEntry just means we have never tested, so allow it to be tested
        (!filingEntry || [AF_TESTED, AF_ERRORED, AF_ERRORED_VALIDATION].includes(filingEntry.state))
    );
};

export const canFileAutofile = (filing: IFilingSlimmed, filingEntry?: AutofilerQueueEntry) => {
    if (!filingEntry || !canTestAutofile(filing.canAutofile, filingEntry)) {
        return false;
    }
    // we allow for testing while funds are pending...but of course not filing unless it is a mid-month payment
    return (!filing.hasUnsettled || hasMidMonthPaymentOrIsWeirdCA(filing)) && filingEntry?.state === AF_TESTED;
};

export const isAutofilerProcessing = (filingEntry?: AutofilerQueueEntry) => {
    if (!filingEntry) {
        return false;
    }
    return [AF_BULK_QUEUED, AF_BULK_PROCESSING, AF_OPEN, AF_PROCESSING].includes(filingEntry.state);
};

export const isBulkProcessing = (bulkTaskId?: string | null, filingEntry?: AutofilerQueueEntry) => {
    return !isNil(bulkTaskId) || (filingEntry && [AF_BULK_QUEUED, AF_BULK_PROCESSING].includes(filingEntry.state));
};
