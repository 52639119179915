import { IWellsFargoTransactionInfo, moneyFromCents, toDisplayDateString } from "@davo/types";
import { Icon, Tooltip } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { CSSProperties, useState } from "react";
import { getWellsTransaction } from "../services";
import { WellsTransactionMatches } from "./WellsTransactionMatches";

export interface IWellsTransactionMatchIconType {
    wfTransactionId?: string | null;
    filingId?: string;
    onChange?: () => void;
}

const dtStyle: CSSProperties = {
    fontWeight: "bold",
    float: "left",
    marginRight: "0.8em",
    padding: "0.3em",
};

const ddStyle: CSSProperties = {
    clear: "both",
    padding: "0.3em",
    textAlign: "left",
};

async function getTxnInfo(id: string, filingId?: string, onChange?: () => void) {
    const txn: IWellsFargoTransactionInfo = await getWellsTransaction(id);
    const unmatchedAmount = txn.transactionAmount - (txn.amountMatched ?? 0);

    return (
        <>
            <dl>
                <dt style={dtStyle}>Transaction Amount</dt>
                <dd style={ddStyle}>${moneyFromCents(txn.transactionAmount)}</dd>

                <dt style={dtStyle}>Matched Transaction Amounts</dt>
                <dd style={ddStyle}>
                    <WellsTransactionMatches txn={txn} filingId={filingId} onChange={onChange} />
                </dd>

                {!isEmpty(txn.matchedEntities) && unmatchedAmount > 0 && (
                    <>
                        <dt style={dtStyle}>Unmatched Amount</dt>
                        <dd style={ddStyle}>${moneyFromCents(unmatchedAmount)}</dd>
                    </>
                )}

                <dt style={dtStyle}>Debit/Credit</dt>
                <dd style={ddStyle}>{txn.debitCreditIndicator}</dd>
                <dt style={dtStyle}>Posting date</dt>
                <dd style={ddStyle}>{toDisplayDateString(txn.postingDate, false)}</dd>
                <dt style={dtStyle}>Description</dt>
                <dd style={ddStyle}>{txn.transactionDescription}</dd>
                <dt style={dtStyle}>Check Number</dt>
                <dd style={ddStyle}>{txn.checkNumber}</dd>
                <dt style={dtStyle}>Bank Reference</dt>
                <dd style={ddStyle}>{txn.bankReference}</dd>
                <dt style={dtStyle}>State</dt>
                <dd style={ddStyle}>{txn.state}</dd>
            </dl>
        </>
    );
}

export function WellsTransactionMatchIcon({ wfTransactionId, filingId, onChange }: IWellsTransactionMatchIconType) {
    const [msg, setMsg] = useState<any>("Loading...");
    return (
        <div
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "10px",
                marginRight: "10px",
            }}>
            {wfTransactionId && (
                <Tooltip
                    title={msg}
                    style={{ marginLeft: "30px", marginRight: "10px" }}
                    onOpen={async () => {
                        setMsg(await getTxnInfo(wfTransactionId, filingId, onChange));
                    }}>
                    <Icon>check_circle</Icon>
                </Tooltip>
            )}
        </div>
    );
}
