import { BusinessDayPicker, d30Toast, d30ToastError, PaperComponent } from "@davo/portal-common";
import { BusinessDay } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { generateFilingForTaxProfile } from "./services";

export interface IGenerateFilingModal {
    taxProfileId: string;
    closeDialog: (target?: string) => void;
}

export function GenerateFilingModal({ taxProfileId, closeDialog }: IGenerateFilingModal) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [periodDay, setPeriodDay] = useState<BusinessDay>(BusinessDay.today());

    const shutdown = useCallback(() => closeDialog(), [closeDialog]);

    const generateFiling = useCallback(() => {
        setIsBusy(true);
        generateFilingForTaxProfile(taxProfileId, periodDay.toSQLDate())
            .then((res) => {
                if (res.message === 0) {
                    d30Toast("Filing(s) generated");
                } else {
                    d30ToastError("No filings were generated, filing may already exist for that period");
                }
            })
            .then(() => setIsBusy(false))
            .catch((e) => d30ToastError("Problem generating filing for tax profile.", e))
            .finally(shutdown);
    }, [periodDay, shutdown, taxProfileId]);

    const labelId = "draggable-dialog-title";
    return (
        <Dialog
            data-testid={"generateFilingModal"}
            PaperComponent={PaperComponent}
            aria-labelledby={labelId}
            fullScreen={makeFullScreen}
            open={true}
            maxWidth={"lg"}
            onClose={shutdown}>
            <DialogTitle id={labelId}>Generate Filing</DialogTitle>
            <DialogContent>
                <div style={{ marginBottom: "16px" }}>
                    <p>Choose a day within the period you want a filing generated for.</p>
                    <p>If a filing already exists for that period, no new filing will be generated.</p>
                    <p>If the filing is a prepayment, the subsuming filing will also be generated.</p>
                    <span style={{ fontStyle: "italic" }}>
                        Note: POS data will be acquired for the period of the filing, this may take several minutes.
                        <br />
                        If today is the 4th of the month, this data likely won&apos;t be available until the 5th.
                    </span>
                </div>
                <BusinessDayPicker label={"Period Day"} onChange={setPeriodDay} value={periodDay} />
            </DialogContent>
            <DialogActions>
                <Button onClick={shutdown} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button
                    data-testid={"generateFilingBtn"}
                    disabled={isBusy}
                    onClick={generateFiling}
                    variant="contained"
                    color="primary">
                    Generate
                </Button>
            </DialogActions>
        </Dialog>
    );
}
