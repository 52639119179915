import { ORPHAN_RELATED_ACCOUNT_NAME, PartialAccount } from "@davo/portal-common";
import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardHeader,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Switch,
    Typography,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import { MergeDestination } from "./Steps/ExtractionTypes";

export interface IConfirmExtractionDetails {
    mergeDestination: MergeDestination;
    mergeType: "Redeem" | "Extract" | undefined;
    locationName: string;
    locationId: string;
    sourceAccount: PartialAccount | undefined;
    shouldRequirePartnerConfirm: boolean;
    hasConfirmedPartnerBilling: boolean;
    setHasConfirmedPartnerBilling: (v: boolean) => void;
    newAccountName: string;
    selectedAccount: PartialAccount | undefined;
}

export const ConfirmExtractionDetails: FunctionComponent<IConfirmExtractionDetails> = ({
    shouldRequirePartnerConfirm,
    hasConfirmedPartnerBilling,
    setHasConfirmedPartnerBilling,
    mergeDestination,
    newAccountName,
    locationName,
    locationId,
    selectedAccount,
    sourceAccount,
    mergeType,
}) => {
    const transferListContent = (
        title: React.ReactNode,
        subHeader: React.ReactNode,
        items: { name: string; id: string }[]
    ) => {
        return (
            <Card>
                <CardHeader sx={{ px: 2, py: 1 }} title={title} subheader={subHeader} />
                <Divider />
                <List
                    sx={{
                        width: 200,
                        minHeight: 100,
                        bgcolor: "background.paper",
                        overflow: "auto",
                    }}
                    dense
                    component="div"
                    role="list">
                    {items.map(({ name, id: locId }, index) => {
                        const labelId = `transfer-list-item-${name}-label`;

                        return (
                            <ListItemButton
                                selected={locationId === locId}
                                key={locId ?? `${name}-${index}`}
                                role="listitem"
                                dense={true}>
                                <ListItemText id={labelId} primary={name} />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Card>
        );
    };

    return (
        <div>
            {shouldRequirePartnerConfirm && !hasConfirmedPartnerBilling && (
                <div style={{ margin: "12px auto", display: "block", width: "60%" }}>
                    <Alert
                        data-testid={"confirmPartnerBillingContainer"}
                        severity="warning"
                        action={
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            data-testid={"confirmPartnerMergeToggle"}
                                            checked={hasConfirmedPartnerBilling}
                                            onChange={(event, isChecked) => setHasConfirmedPartnerBilling(isChecked)}
                                        />
                                    }
                                    label="Confirm"
                                />
                            </FormGroup>
                        }>
                        <AlertTitle>This may have billing implications.</AlertTitle>
                        Are you sure you want to {mergeType?.toLowerCase() ?? "change"} this location to/from a partner
                        account?
                    </Alert>
                </div>
            )}

            {(!shouldRequirePartnerConfirm || hasConfirmedPartnerBilling) && (
                <div data-testid={"confirmExtractionDetails"}>
                    <Typography component={"h3"} variant={"h5"} gutterBottom style={{ textAlign: "center" }}>
                        Proceed with extraction?
                    </Typography>
                    <Grid container justifyContent="center" alignItems="center" style={{ margin: "32px auto 12px" }}>
                        <Grid item>
                            {transferListContent("Source", sourceAccount?.name ?? ORPHAN_RELATED_ACCOUNT_NAME, [
                                {
                                    name: locationName,
                                    id: locationId,
                                },
                            ])}
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    disableRipple
                                    disableFocusRipple
                                    disableElevation
                                    disableTouchRipple
                                    style={{ background: "transparent", cursor: "auto", fontSize: "32px" }}
                                    sx={{ my: 0.5 }}
                                    variant="text"
                                    size="large"
                                    aria-label={mergeDestination === "new" ? "write" : "append"}>
                                    {mergeDestination === "new" ? <>&gt;</> : <>&gt;&gt;</>}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{transferListContent("Target", selectedAccount?.name ?? newAccountName, [])}</Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};
