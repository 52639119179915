import { d30Toast, d30ToastError } from "@davo/portal-common";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { closeFilingsForDisconnectedLocation, getNumberOfOpenFilingsForDisconnectedLocation } from "./services";

export function CloseOpenFilingsButton({ locationId, onClose }: { locationId: string; onClose: () => void }) {
    useAsyncEffect(async () => {
        const num = await getNumberOfOpenFilingsForDisconnectedLocation(locationId);
        setNumberOfOpenFilings(num);
    }, [locationId]);

    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [numberOfOpenFilings, setNumberOfOpenFilings] = useState<number>(0);

    const show = () => {
        setIsShowing(true);
    };

    const doCloseOpenFilings = () => {
        setIsBusy(true);
        closeFilingsForDisconnectedLocation(locationId)
            .then(() => {
                d30Toast(`Open filings are closed.`);
            })
            .catch((e) => d30ToastError("Could not close open filings.", e))
            .finally(() => {
                setIsBusy(false);
                setIsShowing(false);
                onClose();
            });
    };

    return (
        <div style={{ marginTop: "2px", marginRight: "2px", marginBottom: "2px", whiteSpace: "nowrap" }}>
            <Button
                variant="outlined"
                onClick={show}
                size="small"
                color="secondary"
                disabled={isBusy || numberOfOpenFilings === 0}>
                Close open filings WNF
            </Button>
            {numberOfOpenFilings !== 0 && (
                <Tooltip title={"Does not close multi-location filings"} placement="top">
                    <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                </Tooltip>
            )}
            {isShowing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle>Close open filings?</DialogTitle>
                    <DialogContent>
                        <Typography>{`Note: This will close ${numberOfOpenFilings} filings as will not file.`}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="secondary" disabled={isBusy} onClick={doCloseOpenFilings}>
                            Close Open Filings
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
