import { Loading, PaperComponent, ReactTable, useModalEditor } from "@davo/portal-common";
import { Filing, FilingFrequency, LedgerReportLine, money, moneyFromCents, PeriodReport } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { FieldList } from "./FieldList";
import { FilingFrequencySelector } from "./FilingFrequencySelector";
import { getFilingAccounting, getFilingSubsumingAccounting } from "./services";

const AccountingLabels: { [key: string]: { label: string; description: string } } = {
    "owed-to-state": { label: "Owed to State", description: "Owed to state amount derived from POS data" },
    "pending-in": { label: "Pending In", description: "Transactions that have not yet cleared in our bank" },
    "pending-out": {
        label: "Pending Out",
        description: "Transactions that have not yet cleared in the merchant's bank",
    },
    "davo-balance": { label: "Available", description: "Amount of money available for immediate use" },
    "merchant-balance": {
        label: "Uncollected",
        description: "Difference between what is available and what the POS says is owed to the state",
    },
    remitted: {
        label: "Remitted",
        description: "Amount of money remitted to the state during this period",
    },
    "write-off": {
        label: "Written Off",
        description: "This money was written off as part of the reconcile process",
    },
    "returned-to-merchant": {
        label: "Returned",
        description: "Amount of money returned to the merchant during this period",
    },
    escheat: {
        label: "Marked for Escheatment",
        description: "This money is marked as property of the state via escheatment",
    },
    "returned-by-check": {
        label: "Returned by Check",
        description: "This money was returned directly to the merchant via paper check",
    },
    "inter-location-transfer": {
        label: "Transferred",
        description: "This money was transferred to another location on the same account",
    },
};

const getAccountLabel = (f: string) => {
    const l: string | undefined = AccountingLabels[f]?.label;
    return l ?? f;
};

const getAccountTooltip = (f: string) => {
    const d: string | undefined = AccountingLabels[f]?.description;
    return d ?? f;
};

export interface IFilingAccountingType {
    filing: Filing;
}

export function FilingAccounting({ filing }: IFilingAccountingType) {
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [filingPeriodAccounting, setFilingPeriodAccounting] = useState<PeriodReport | undefined>();
    const [subsumingBalance, setSubsumingBalance] = useState<PeriodReport | undefined>();
    const [frequency, setFrequency] = useState<FilingFrequency>();
    const [tableData, setTableData] = useState<LedgerReportLine[]>();

    useAsyncEffect(async () => {
        if (!isDialogOpen) {
            return;
        }
        const filingAccounting = await getFilingAccounting(filing.id, !isNil(frequency) ? frequency : filing.frequency);
        setFilingPeriodAccounting(filingAccounting);
        const subsumingAccounting = await getFilingSubsumingAccounting(filing.id);
        setSubsumingBalance(subsumingAccounting);
        const getTableData = () => {
            if (!filingAccounting || !filingAccounting.report) {
                return [];
            }
            return filingAccounting.report
                .filter((x) => x.account.startsWith("asset"))
                .sort((a, b) => a.account.localeCompare(b.account));
        };
        setTableData(getTableData());
    }, [isDialogOpen, frequency, filing]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Account",
                id: "account",
                accessor: "account",
                Cell: (data: any) => (
                    <Tooltip
                        style={{ cursor: "default" }}
                        title={getAccountTooltip(data.value.substring(+data.value.indexOf(":") + 1))}>
                        <span>{getAccountLabel(data.value.substring(+data.value.indexOf(":") + 1))}</span>
                    </Tooltip>
                ),
                disableSortBy: true,
            },
            {
                Header: "Balance",
                accessor: "balance",
                Cell: (data: any) => (
                    <>{money(((data.cell.row.original.account.startsWith("asset") ? -1 : 1) * data.value) / 100)}</>
                ),
                disableSortBy: true,
            },
        ];
    }, []);

    return (
        <>
            <Button
                data-testid="accountingButton"
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="primary"
                style={{ marginTop: "2px", marginBottom: "2px" }}>
                Accounting
            </Button>
            {isDialogOpen && !isNil(tableData) && (
                <Dialog
                    data-testid={"filingAccountingDialog"}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={closeDialog}>
                    <DialogTitle id="draggable-dialog-title">Filing Accounting</DialogTitle>
                    <DialogContent>
                        {filingPeriodAccounting && (
                            <>
                                <FilingFrequencySelector
                                    defaultFrequency={filing.frequency}
                                    filing={filing}
                                    onSelect={(filingFrequency: FilingFrequency) => {
                                        setFrequency(filingFrequency);
                                    }}
                                />
                                <Typography style={{ marginTop: "20px" }} variant={"h5"}>
                                    Period
                                </Typography>
                                <FieldList
                                    object={{
                                        due: filingPeriodAccounting.period.due.toString(),
                                        start: filingPeriodAccounting.period.start.toString(),
                                        end: filingPeriodAccounting.period.end.toString(),
                                        prepayment: filingPeriodAccounting.period.prepayment.toString(),
                                    }}
                                />
                                <Typography style={{ marginTop: "20px" }} variant={"h5"}>
                                    Accounting
                                </Typography>
                                <ReactTable
                                    columns={columns}
                                    data={tableData}
                                    options={{
                                        hideToolbar: true,
                                        pageSize: 15,
                                        pageSizeOptions: [15],
                                    }}
                                />
                                <br />
                                {subsumingBalance && (
                                    <>
                                        <Typography>{`Filing subsuming period balance`}</Typography>
                                        <Typography>{`${subsumingBalance.period.start} to ${
                                            subsumingBalance.period.end
                                        }: $${moneyFromCents(
                                            (subsumingBalance.report.filter(
                                                (a: LedgerReportLine) => a.account === "asset:davo-balance"
                                            )[0]?.balance ?? 0) * -1
                                        )}`}</Typography>
                                    </>
                                )}
                                {!subsumingBalance && (
                                    <>
                                        <Typography>{`Filing subsuming balance could not be loaded because this filing is not fully subsumed`}</Typography>
                                    </>
                                )}
                                <br />
                            </>
                        )}
                        {!filingPeriodAccounting && <Loading />}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={closeDialog}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
