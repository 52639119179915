import { CommonPortalConfigContext, LoadingWithLogo } from "@davo/portal-common";
import { emptyDefaultOpsPortalConfig, ICommonPortalConfigType, IOpsPortalConfigType } from "@davo/types";
import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getOpsConfig } from "../services";

export interface IOpsPortalConfigContext {
    opsPortalConfigInfo: IOpsPortalConfigType;
}

export const OpsPortalConfigContextDefaultValue: IOpsPortalConfigContext = {
    opsPortalConfigInfo: emptyDefaultOpsPortalConfig,
};
export const OpsPortalConfigContext = createContext(OpsPortalConfigContextDefaultValue);
export const useOpsPortalConfigContext = () => useContext(OpsPortalConfigContext);

export const OpsPortalConfigContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [opsPortalConfigInfo, setOpsPortalConfigInfo] = useState<IOpsPortalConfigType>();
    const [commonPortalConfigInfo, setCommonPortalConfigInfo] = useState<ICommonPortalConfigType>();

    useAsyncEffect(async () => {
        const config = await getOpsConfig();
        setOpsPortalConfigInfo(config);
        setCommonPortalConfigInfo(config);
    }, []);

    if (!commonPortalConfigInfo || !opsPortalConfigInfo) {
        return <LoadingWithLogo />;
    }

    return (
        <OpsPortalConfigContext.Provider
            value={{
                opsPortalConfigInfo,
            }}>
            <CommonPortalConfigContext.Provider
                value={{
                    commonPortalConfigInfo,
                }}>
                {children}
            </CommonPortalConfigContext.Provider>
        </OpsPortalConfigContext.Provider>
    );
};
