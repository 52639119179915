import { d30Toast, PaperComponent, TextField, useModalEditor } from "@davo/portal-common";
import { INotice } from "@davo/types";
import NoteIcon from "@mui/icons-material/Note";
import NoteAddIcon from "@mui/icons-material/NoteOutlined";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { setNoticeMerchantNote, setNoticeNote } from "./services";

export function NoticesAddNoteModal({ notice }: { notice: INotice }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const [merchantNote, setMerchantNote] = useState<string>("");

    useEffect(() => {
        if (isDialogOpen) {
            setNote(notice.noteInternal ?? "");
            setMerchantNote(notice.noteToMerchant ?? "");
        }
    }, [isDialogOpen, notice.noteInternal, notice.noteToMerchant]);

    const clearNote = async (isMerchant: boolean) => {
        setIsBusy(true);
        try {
            if (isMerchant) {
                await setNoticeMerchantNote(notice.id, "");
                notice.noteToMerchant = "";
            } else {
                await setNoticeNote(notice.id, "");
                notice.noteInternal = "";
            }
            d30Toast("Note removed.");
        } finally {
            setIsBusy(false);
        }
    };

    const saveNote = async (isMerchant: boolean) => {
        setIsBusy(true);
        try {
            if (isMerchant) {
                await setNoticeMerchantNote(notice.id, merchantNote);
                notice.noteToMerchant = merchantNote;
            } else {
                await setNoticeNote(notice.id, note);
                notice.noteInternal = note;
            }
            d30Toast("Note saved.");
        } finally {
            setIsBusy(false);
        }
    };

    const getNoteTooltip = () => {
        if (!notice.noteInternal && !notice.noteToMerchant) {
            return (
                <div>
                    <b>Add Notes</b>
                </div>
            );
        } else if (notice.noteInternal && !notice.noteToMerchant) {
            return (
                <div>
                    <b>Internal Note</b>: {notice.noteInternal}
                </div>
            );
        } else if (notice.noteToMerchant && !notice.noteInternal) {
            return (
                <div>
                    <b>Merchant Note</b>: {notice.noteToMerchant}
                </div>
            );
        } else {
            return (
                <>
                    <div>
                        <b>Internal Note</b>: {notice.noteInternal}
                    </div>
                    <div>
                        <b>Merchant Note</b>: {notice.noteToMerchant}
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <span
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    marginRight: "10px",
                }}>
                <Tooltip title={getNoteTooltip()}>
                    <span>
                        <IconButton
                            aria-label="Notes"
                            onClick={() => {
                                showDialog();
                            }}
                            size="large">
                            {!notice.noteInternal && !notice.noteToMerchant && <NoteAddIcon color="primary" />}
                            {(notice.noteInternal || notice.noteToMerchant) && <NoteIcon color="primary" />}
                        </IconButton>
                    </span>
                </Tooltip>
            </span>

            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        closeDialog();
                    }}>
                    <DialogTitle id="draggable-dialog-title">Notes</DialogTitle>
                    <DialogContent>
                        <Typography variant={"body1"}>
                            <TextField
                                label={"Internal Note"}
                                value={note}
                                onChange={setNote}
                                isMultiline={true}
                                rows={10}
                                inputProps={{ maxLength: 254 }}
                                isDisabled={notice.status?.includes("closed")}
                                style={{ minWidth: "400px" }}
                            />
                        </Typography>

                        <br />

                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={isBusy || !note || notice.status?.includes("closed")}
                                onClick={() => clearNote(false)}>
                                Clear
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={isBusy || !note || notice.status?.includes("closed")}
                                onClick={() => saveNote(false)}>
                                Save
                            </Button>
                        </Stack>

                        <br />

                        <Typography variant={"body1"}>
                            <TextField
                                label={"Merchant Note"}
                                value={merchantNote}
                                onChange={setMerchantNote}
                                isMultiline={true}
                                rows={10}
                                inputProps={{ maxLength: 254 }}
                                isDisabled={notice.status?.includes("closed")}
                                style={{ minWidth: "400px" }}
                            />
                        </Typography>

                        <br />

                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={isBusy || !merchantNote || notice.status?.includes("closed")}
                                onClick={() => clearNote(true)}>
                                Clear
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={isBusy || !merchantNote || notice.status?.includes("closed")}
                                onClick={() => saveNote(true)}>
                                Save
                            </Button>
                        </Stack>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" disabled={isBusy} onClick={() => closeDialog()}>
                            Back
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
