import { LinkButton } from "@davo/portal-common";
import { BusinessDay, FilingFrequency, FilingStatus } from "@davo/types";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import { Alert, Button, Tooltip } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { v4 as uuid } from "uuid";
import { getOverlappingFilingsForFiling } from "./services";

export interface IOverlappingFilingArgType {
    filingId: string;
}

interface IOverlappingFilingType {
    id: string;
    periodStart: BusinessDay;
    periodEnd: BusinessDay;
    frequency: FilingFrequency;
    status: FilingStatus;
    tpName: string;
    tpState: string;
    jurisdictionFilingTypeId?: string | null;
    jurisdictionFilingTypeName?: string | null;
}

export function OverlappingFilings({ filingId }: IOverlappingFilingArgType) {
    const [overlappingFilings, setOverlappingFilings] = useState<IOverlappingFilingType[] | undefined>(undefined);
    const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

    useAsyncEffect(async () => {
        setOverlappingFilings(await getOverlappingFilingsForFiling(filingId));
    }, [filingId]);

    const getLinkLabel = (data: IOverlappingFilingType) => {
        if (data.jurisdictionFilingTypeName) {
            return `${data.jurisdictionFilingTypeName} (${data.tpName})`;
        }
        return `${data.tpState} State Tax (${data.tpName})`;
    };

    const renderFilingLink = (data: IOverlappingFilingType) => {
        return (
            <LinkButton
                url={`/filings/${data.id}`}
                label={getLinkLabel(data)}
                labelMaxChars={35}
                align={"left"}
                rel="noreferrer"
                target="_blank"
            />
        );
    };

    const renderLine = (data: IOverlappingFilingType) => {
        return (
            <div key={uuid()} style={{ display: "flex", marginTop: "5px" }}>
                <div style={{ width: "24px", height: "24px" }}>
                    {data.status !== "open" && (
                        <Tooltip title={"Filed"}>
                            <CheckIcon style={{ width: "24px", height: "24px" }} />
                        </Tooltip>
                    )}
                </div>
                {renderFilingLink(data)}
                <Tooltip title={`${data.frequency.toUpperCase()}: ${data.periodStart} - ${data.periodEnd}`}>
                    <div style={{ textAlign: "right" }}>
                        <InfoIcon fontSize={"small"} color="primary" style={{ marginLeft: "5px" }} />
                    </div>
                </Tooltip>
            </div>
        );
    };

    const flipShowingMoreFlag = () => {
        if (overlappingFilings && overlappingFilings.length >= 4) {
            setIsShowingMore(!isShowingMore);
        } else {
            setIsShowingMore(false);
        }
    };

    if (!overlappingFilings) {
        return null;
    }
    return (
        <div>
            {!isEmpty(overlappingFilings) && (
                <div style={{ width: "100%" }}>
                    <Alert style={{ marginTop: "10px", width: "100%" }} severity="warning">
                        A related filing exists.
                        <div>
                            {overlappingFilings.slice(0, 3).map((f) => renderLine(f))}
                            {overlappingFilings.length >= 4 && (
                                <>
                                    More than three filings are related to this one
                                    <Button onClick={flipShowingMoreFlag}>
                                        {!isShowingMore ? `See More` : `See Less`}
                                    </Button>
                                </>
                            )}
                            {isShowingMore && overlappingFilings.slice(3).map((f) => renderLine(f))}
                        </div>
                    </Alert>
                </div>
            )}
        </div>
    );
}
