import { d30Toast, d30ToastError, useLoginContext } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { refreshLocationAccounting } from "./services";

export function RefreshLocationAccountingButton({ locationId, onClose }: { locationId: string; onClose?: () => void }) {
    const loginContext = useLoginContext();

    const [isShowing, setIsShowing] = useState<boolean>(false);

    const show = () => {
        setIsShowing(true);
    };

    const doRefresh = () => {
        setIsShowing(false);
        refreshLocationAccounting(locationId)
            .then(() => {
                d30Toast(`Location accounting refreshed`);
                if (onClose) {
                    onClose();
                }
            })
            .catch((e) => d30ToastError("Could not refresh", e));
    };

    if (!loginContext.user || loginContext.user.type !== "Superuser") {
        return null;
    }

    return (
        <div style={{ marginTop: "2px", marginRight: "2px" }}>
            <Button variant="outlined" onClick={show} size="small" color="secondary" style={{ marginBottom: "3px" }}>
                Refresh Accounting
            </Button>
            {isShowing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle>Refresh?</DialogTitle>
                    <DialogActions>
                        <Button variant="outlined" color="secondary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="secondary" onClick={doRefresh}>
                            Refresh Accounting
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
