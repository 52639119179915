import React from "react";
import { useMatch } from "react-router-dom";
import { FilingDetailPane } from "./FilingDetailPane";
import { FilingsTable } from "./FilingsTable";
import { FilingDetailPaneContextProvider } from "./context/FilingDetailPaneContext";

export function Filings() {
    const match = useMatch("/filings/:filingId");
    const filingId = match?.params?.filingId;

    if (filingId) {
        return (
            <FilingDetailPaneContextProvider filingId={filingId}>
                <FilingDetailPane />
            </FilingDetailPaneContextProvider>
        );
    }
    return (
        <FilingsTable
            showHistorical={false}
            includeFiled={false}
            showFilters={true}
            showAssign={true}
            showRecent={true}
            showAccountButton={true}
            showAutoFilerMultiEnqueue={true}
        />
    );
}
