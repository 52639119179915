import { d30Toast, d30ToastError, NumberField, Select, TextField, useLoginContext } from "@davo/portal-common";
import {
    BusinessDay,
    ManualSetAsideReturnTypeKeys,
    ManualSetAsideType,
    ManualSetAsideTypeKeys,
    ManualSetAsideTypes,
    money,
    noop,
} from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { manualSetAside, markFundsRetrievable } from "./services";
import { hasPermission } from "./util";

export const reconcileMerchantNoteTemplates: { [key: string]: string } = {
    "Over collected/Over funded": "End of period reconcile of over collected or over funded sales tax.",
    "Under collected/ Under funded": "End of period reconcile for under collected or under funded sales tax.",
    "Adjusted Taxable Sales": "End of period reconcile due to adjusted taxable sales.",
    Cancellation: "Return of held sales tax funds per your service cancellation.",
    "Merchant Request": "Return of held sales tax funds per merchant request.",
    "Direct Payment": "Return of collected sales tax funds due to payment made direct from merchant bank account.",
};

export const discountMerchantNoteTemplates: { [key: string]: string } = {
    "Timely Filing Discount": "Timely filing discount.",
};

export interface IManualSetAsideButtonType {
    periodDay: BusinessDay;
    isReturn?: boolean;
    accountId: string;
    locationId: string;
    balance: number;
    returnedToMerchantBalance?: number;
    refresh?: () => void;
    period?: { start: BusinessDay; end: BusinessDay };
}

export function ManualSetAsideButton({
    periodDay,
    isReturn = false,
    accountId,
    locationId,
    balance,
    returnedToMerchantBalance = undefined,
    refresh = noop,
    period = undefined,
}: IManualSetAsideButtonType) {
    const loginContext = useLoginContext();

    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [note, setNote] = useState<string | undefined>(undefined);
    const [merchantNote, setMerchantNote] = useState<string | undefined>(undefined);
    const [type, setType] = useState<ManualSetAsideType | undefined>(undefined);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [merchantNoteTemplate, setMerchantNoteTemplate] = useState<string | undefined>(undefined);

    const show = () => {
        setAmount(undefined);
        setNote(undefined);
        setMerchantNote(undefined);
        setType(undefined);
        setIsShowing(true);
    };

    const validateAmount = (amt: number | undefined) => {
        if (amt && Math.round(amt * 100) > balance) {
            return `Cannot ${isReturn ? "return" : "set aside"} more than ${money(balance / 100)}`;
        }
        return undefined;
    };

    const doSetAside = async () => {
        if (amount && type) {
            const amountScaled = Math.round(amount * 100);
            setIsBusy(true);
            if (!isNil(returnedToMerchantBalance)) {
                const merchantBalance = balance - returnedToMerchantBalance;
                if (amountScaled > merchantBalance) {
                    // if we need to use more than merchant balance, then mark the extra amount retrievable so we can go get it
                    await markFundsRetrievable(locationId, amountScaled - merchantBalance, periodDay);
                }
            }
            try {
                await manualSetAside(
                    isReturn,
                    accountId,
                    locationId,
                    (isReturn ? -1 : 1) * amountScaled,
                    type,
                    periodDay,
                    note,
                    merchantNote,
                    period
                );
                d30Toast(`${money(amount)} ${isReturn ? "return" : "set aside"} succeeded.`);
                refresh();
                setIsBusy(false);
                setIsShowing(false);
            } catch (e: any) {
                d30ToastError(e.message);
                setIsBusy(false);
            }
        }
    };

    const changeMerchantNote = (key: string | undefined) => {
        setMerchantNoteTemplate(key);
        let msg = "";

        if (type === "reconcile") {
            msg = key ? reconcileMerchantNoteTemplates[key] : "";
        } else if (type === "discount") {
            msg = key ? discountMerchantNoteTemplates[key] : "";
        }

        setMerchantNote(msg);
    };

    const changeType = (typeVal: ManualSetAsideType | undefined) => {
        setType(typeVal);
        setMerchantNoteTemplate(undefined);
        setMerchantNote(undefined);
    };

    const verb = isReturn ? "Return" : "Set Aside";
    return (
        <>
            <Tooltip
                placement="right"
                title={
                    balance > 0 &&
                    periodDay <= BusinessDay.today() &&
                    isReturn &&
                    !hasPermission(loginContext.permissions, "can_file")
                        ? "Manual Return requires can_file permission"
                        : ""
                }>
                <span>
                    <Button
                        disabled={
                            balance <= 0 ||
                            periodDay > BusinessDay.today() ||
                            (isReturn && !hasPermission(loginContext.permissions, "can_file"))
                        }
                        variant="outlined"
                        onClick={show}
                        size="small"
                        color="primary"
                        data-testid="manualSetAsideButton"
                        fullWidth={true}>
                        Manual {verb}
                    </Button>
                </span>
            </Tooltip>

            {isShowing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle>Manual {verb}</DialogTitle>
                    <DialogContent data-testid="manualSetAsideDialog">
                        <Typography>Maximum: {money(balance / 100)}</Typography>
                        {returnedToMerchantBalance && (
                            <Typography>
                                NOTE: This maximum includes returned to merchant as well as merchant balance funds
                            </Typography>
                        )}
                        <Select<ManualSetAsideType>
                            title="Type"
                            value={type}
                            onChange={changeType}
                            options={isReturn ? ManualSetAsideReturnTypeKeys : ManualSetAsideTypeKeys}
                            label={(typeId: ManualSetAsideType) => ManualSetAsideTypes[typeId]}
                            data-testid="type"
                        />
                        <NumberField
                            label={`Amount to ${verb}`}
                            value={amount}
                            onChange={setAmount}
                            validate={validateAmount}
                        />
                        <TextField label={"Note"} value={note ?? ""} onChange={setNote} />
                        <TextField
                            label={"Merchant Note"}
                            value={merchantNote ?? ""}
                            onChange={setMerchantNote}
                            data-testid="merchantNote"
                        />

                        {isReturn && (type === "reconcile" || type === "discount") && (
                            <Select<string>
                                noneLabel={"Blank Template"}
                                options={Object.keys(
                                    type === "reconcile"
                                        ? reconcileMerchantNoteTemplates
                                        : discountMerchantNoteTemplates
                                ).sort()}
                                value={merchantNoteTemplate}
                                onChange={changeMerchantNote}
                                data-testid="merchantNoteTemplate"
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isBusy || !amount || !type || !!validateAmount(amount)}
                            onClick={doSetAside}>
                            Do {verb}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
