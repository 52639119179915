import { d30Toast, d30ToastError, Select } from "@davo/portal-common";
import { ISubscriptionAndType, ISubscriptionType, moneyFromCents } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { addLocationSubscription, getAllActiveValidSubscriptionTypes } from "./services";

interface ISubscriptionChangeArgs {
    locationId: string;
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    currentSubscription?: ISubscriptionAndType;
    refreshParent: () => void;
}

export function SubscriptionChange({ locationId, currentSubscription, refreshParent }: ISubscriptionChangeArgs) {
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<ISubscriptionType>();
    const [activeSubscriptionTypes, setActiveSubscriptionTypes] = useState<ISubscriptionType[]>([]);
    const [isSubscriptionExpanded, setIsSubscriptionExpanded] = useState<boolean>(false);

    useAsyncEffect(async () => {
        await loadSubscriptionTypes();
    }, [locationId]);

    useEffect(() => {
        const currentSubscriptionTypes = activeSubscriptionTypes.find(
            (subscriptionType) => subscriptionType.tag === currentSubscription?.type?.tag
        );
        setSelectedSubscriptionType(currentSubscriptionTypes ? currentSubscriptionTypes : undefined);
    }, [currentSubscription, activeSubscriptionTypes]);

    const loadSubscriptionTypes = async () => {
        const allSubs = await getAllActiveValidSubscriptionTypes(locationId);
        setActiveSubscriptionTypes(allSubs);
    };

    const addSubscription = async () => {
        if (selectedSubscriptionType) {
            try {
                await addLocationSubscription(locationId, selectedSubscriptionType.id);
                d30Toast("Subscription Added!");
            } catch (err) {
                d30ToastError(`Failed to Add Subscription!: ${err}`);
            } finally {
                refreshParent();
            }
        }
    };

    const formatSub = (b: ISubscriptionType) => (
        <span>
            {b.description} - {moneyFromCents(b.charge)}
        </span>
    );

    return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Accordion
                style={{ marginBottom: "30px", width: "100%" }}
                expanded={isSubscriptionExpanded}
                onChange={() => {
                    setIsSubscriptionExpanded(!isSubscriptionExpanded);
                }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Subscription</AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <Typography variant={"body1"}>
                        <Select<ISubscriptionType>
                            options={activeSubscriptionTypes}
                            value={selectedSubscriptionType}
                            label={formatSub}
                            onChange={setSelectedSubscriptionType}
                        />
                        <Button
                            onClick={() => addSubscription()}
                            color="primary"
                            disabled={selectedSubscriptionType?.tag === currentSubscription?.type?.tag}
                            variant="contained">
                            Add Subscription
                        </Button>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
