import { BusinessDay, toDisplayDateString } from "@davo/types";
import React from "react";

interface IRenderFilingPeriodEnd {
    date: string | BusinessDay;
}

export const RenderFilingPeriodEnd: React.FunctionComponent<IRenderFilingPeriodEnd> = ({ date }) => (
    <>{toDisplayDateString(date)}</>
);
