import {
    BusinessDayPicker,
    TextField,
    d30Toast,
    d30ToastError,
    pauseBankAccount,
    setTaxProfileFilingMethod,
    unpauseBankAccount,
    useLoginContext,
} from "@davo/portal-common";
import { BankAccount, BusinessDay, Location, Partner, noop, toSQLDate } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { hasPermission } from "./util";

const getOneWeekFromToday = () => {
    return BusinessDay.fromSQLDate(toSQLDate(DateTime.utc().plus({ week: 1 })));
};

export interface IBankAccountPauseProps {
    location: Location;
    accountId: string;
    bankAccount: BankAccount;
    partner?: Partner;
    refresh?: () => void;
    isNachaRestricted: boolean;
}

export function BankAccountPause({
    location,
    accountId,
    bankAccount,
    partner,
    refresh = noop,
    isNachaRestricted,
}: IBankAccountPauseProps) {
    const loginContext = useLoginContext();
    const [isPausing, setIsPausing] = useState<boolean>(false);
    const [isResuming, setIsResuming] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [pausedMessage, setPausedMessage] = useState<string | undefined>(undefined);
    const [shouldEnableUnpauseDate, setShouldEnableUnpauseDate] = useState<boolean>(false);
    const [unpauseDate, setUnpauseDate] = useState<BusinessDay>(getOneWeekFromToday());

    const changeTaxProfileFilingMethod = async () => {
        await Promise.all(
            location.taxProfiles.map(async (tp) => {
                if (tp.filingMethod === "file-only" || tp.filingMethod === "merchant-pay") {
                    try {
                        await setTaxProfileFilingMethod(accountId, tp.id, undefined);
                    } catch (e: any) {
                        // setTaxProfileFilingMethod() will get caught in catch here
                        d30ToastError(e.message);
                    }
                }
            })
        );
        await unpause();
        setIsResuming(false);
        d30Toast("Bank Account Unpaused");
    };

    useEffect(() => {
        setIsBusy(false);
        setUnpauseDate(getOneWeekFromToday());
    }, [accountId, bankAccount]);

    const pause = async () => {
        if (!pausedMessage) {
            return;
        }

        setIsBusy(true);
        try {
            const dt = shouldEnableUnpauseDate ? unpauseDate.toDateTime() : undefined;
            await pauseBankAccount(accountId, bankAccount.id, pausedMessage, dt);
            d30Toast("Bank account paused");
            refresh();
        } finally {
            setIsBusy(false);
            setIsPausing(false);
        }
    };

    const unpause = async () => {
        setIsBusy(true);
        try {
            await unpauseBankAccount(accountId, bankAccount.id);
            refresh();
        } finally {
            setIsBusy(false);
            setIsPausing(false);
        }
    };

    if (!loginContext.user || !bankAccount) {
        return null;
    }

    return (
        <>
            {!bankAccount.paused && (
                <Tooltip
                    title={partner?.restrictBankPause ? "Partner restriction enabled on bank pause" : ""}
                    placement="top">
                    <span>
                        <Button
                            data-testid={"pauseBankAccountButton"}
                            disabled={isBusy || partner?.restrictBankPause}
                            variant="outlined"
                            onClick={() => {
                                setIsPausing(true);
                                setPausedMessage(undefined);
                            }}
                            size="small"
                            color="secondary">
                            Pause Bank Account
                        </Button>
                    </span>
                </Tooltip>
            )}

            {bankAccount.paused &&
                (!isNachaRestricted || hasPermission(loginContext.permissions, "nacha_lockout_override")) && (
                    <Button
                        data-testid={"resumeBankAccountButton"}
                        disabled={isBusy}
                        variant="outlined"
                        onClick={() => setIsResuming(true)}
                        size="small"
                        color="primary">
                        Resume Bank Account
                    </Button>
                )}

            {isPausing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsPausing(false);
                    }}>
                    <DialogTitle>Pause Bank Account</DialogTitle>
                    <DialogContent>
                        <TextField
                            data-testid={"pauseBankReason"}
                            label="Pause Reason"
                            value={pausedMessage ?? ""}
                            onChange={setPausedMessage}
                        />
                        <div style={{ textAlign: "center", fontSize: "10px", marginTop: "-10px" }}>
                            (This message is displayed to users)
                        </div>

                        {!isNachaRestricted && (
                            <div data-testid={"automaticallyUnPauseControls"}>
                                <br />
                                <Switch
                                    checked={shouldEnableUnpauseDate}
                                    onChange={(event: any, isChecked: boolean) => setShouldEnableUnpauseDate(isChecked)}
                                />
                                <span>&nbsp;&nbsp;Automatically Unpause</span>
                                <BusinessDayPicker
                                    value={unpauseDate}
                                    label={""}
                                    onChange={setUnpauseDate}
                                    isDisabled={!shouldEnableUnpauseDate}
                                    minDate={BusinessDay.today()}
                                />
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsPausing(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="secondary" onClick={pause} disabled={!pausedMessage}>
                            Pause Bank Account
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {isResuming && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsResuming(false);
                    }}>
                    <DialogTitle>Resume Set Asides?</DialogTitle>
                    <DialogContent>
                        <div data-testid={"resumeBankDialogContent"}>
                            Are you sure you want to resume daily set asides, and any catch up set asides?
                            <br /> Once the bank is unpaused, DAVO will initiate an ACH debit for the amount missed
                            since the bank was paused
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsResuming(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={changeTaxProfileFilingMethod}>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
