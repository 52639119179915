import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";

export type CustomTab = {
    label: string;
    value: string;
    content: React.JSX.Element;
};

interface TabPanelProps {
    children?: React.ReactNode;
    whatTabIsThis: string;
    whatTabIsSelected: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, whatTabIsSelected, whatTabIsThis, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={whatTabIsSelected !== whatTabIsThis}
            id={`simple-tabpanel-${whatTabIsThis}`}
            aria-labelledby={`simple-tab-${whatTabIsThis}`}
            {...other}>
            {whatTabIsSelected === whatTabIsThis && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

interface ICustomTabs<T> {
    mode: T;
    setMode: (newMode: T) => void;
    tabs: CustomTab[];
}

export const CustomTabs: React.FunctionComponent<ICustomTabs<any>> = ({ mode, setMode, tabs }) => {
    return (
        <>
            <Tabs
                value={mode}
                onChange={(e, newValue) => {
                    setMode(newValue);
                }}>
                {tabs.map((t) => (
                    <Tab key={t.value} value={t.value} label={t.label}></Tab>
                ))}
            </Tabs>
            {tabs.map((t) => {
                return (
                    <CustomTabPanel key={t.value} whatTabIsSelected={mode} whatTabIsThis={t.value}>
                        {t.content}
                    </CustomTabPanel>
                );
            })}
        </>
    );
};
