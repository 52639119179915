import { getSelectTheme } from "@davo/portal-common";
import { InvitationSearchRecord } from "@davo/types";
import { useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { getAllNoAccountLocationsForSearchTerm } from "./services";

export const NoAccountSearch = () => {
    const navigate = useNavigate();
    const themeMUI = useTheme();

    const formThemeColors = getSelectTheme(themeMUI);

    const loadResults = async (input: string) => {
        // start searching after the first two characters
        if (input && input.length > 1) {
            const results = await getAllNoAccountLocationsForSearchTerm(input);
            if (results.length === 1) {
                handleSelectionChange(results[0]);
            }
            return results;
        }

        return [];
    };

    const handleSelectionChange = (selectedOption: any) => {
        navigate(`/no-account/location/${selectedOption.locationId}`);
    };

    const formatResults = (i: InvitationSearchRecord) => {
        const email = i.email ? `: ${i.email}` : "";
        return `${i.businessName} (${i.posType})${email}`;
    };

    const MenuList = (props: any) => {
        return (
            <components.MenuList {...props}>
                {props.children}
                {props.options.length <= 8 ? (
                    <div />
                ) : (
                    <div style={{ textAlign: "center" }}>{`Showing first 8 results, narrow search`}</div>
                )}
            </components.MenuList>
        );
    };

    return (
        <div>
            <h2>Search For Location w/o Account</h2>
            <AsyncSelect
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...formThemeColors,
                    },
                })}
                components={{ MenuList }}
                onChange={handleSelectionChange}
                formatOptionLabel={formatResults}
                placeholder={"Enter search term..."}
                defaultOptions={false}
                cacheOptions={false}
                autoFocus
                loadOptions={loadResults}
                closeMenuOnSelect={false}
                noOptionsMessage={() => "No results"}
            />
        </div>
    );
};
