import { FormError, TextField } from "@davo/portal-common";
import { validateNotNull } from "@davo/types";
import { Box, FormLabel } from "@mui/material";
import React, { FunctionComponent } from "react";

export interface INewAccountForm {
    newAccountName: string;
    handleNewAccountNameChange: (val: string) => void;
    message?: string;
}

export const NewAccountForm: FunctionComponent<INewAccountForm> = ({
    handleNewAccountNameChange,
    newAccountName,
    message,
}) => {
    return (
        <Box data-testid={"newAccountForm"}>
            <FormLabel>Choose a name for the new account</FormLabel>
            <FormError message={message} />
            <TextField
                inputProps={{
                    [`data-testid`]: "accountName",
                }}
                value={newAccountName}
                validate={validateNotNull}
                onChange={handleNewAccountNameChange}
                label={"Account Name"}
                isRequired
            />
        </Box>
    );
};
