import { Loading } from "@davo/portal-common";
import { User } from "@davo/types";
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { UserDetailPane } from "./UserDetailPane";
import { UserSearchRender } from "./UserSearchRender";
import { getUser } from "./services";

export function Users() {
    const [user, setUser] = useState<User>();
    const match = useMatch("/users/:userId");
    const userId = match?.params?.userId;

    useAsyncEffect(async () => {
        if (!userId) {
            setUser(undefined);
        } else {
            const u = await getUser(userId);
            setUser(u);
        }
    }, [userId]);

    if (userId) {
        if (!user) {
            return <Loading />;
        }
        return <UserDetailPane userArg={user} />;
    } else {
        return <UserSearchRender />;
    }
}
