import { Select } from "@davo/portal-common";
import { Partner } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { PartnerGroupCredentials } from "./PartnerGroupCredentials";
import { getAllPartners } from "./services";

export function PartnersPane() {
    const [partner, setPartner] = useState<Partner | undefined>(undefined);
    const [partners, setPartners] = useState<Partner[] | undefined>(undefined);

    useAsyncEffect(async () => {
        const allPartners = await getAllPartners();
        setPartners(allPartners);
    }, []);

    if (isNil(partners) || isEmpty(partners)) {
        return null;
    }

    return (
        <div>
            {partners && (
                <Select<Partner>
                    title="Partner"
                    noneLabel={`Select a partner...`}
                    options={partners}
                    value={partner}
                    onChange={setPartner}
                    label={(s) => s.name}
                    isRequired
                />
            )}
            {partner && (
                <div style={{ marginTop: "30px", width: "100%" }}>
                    <Accordion slotProps={{ transition: { unmountOnExit: true } }} expanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Group Credentials</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <div style={{ width: "100%" }}>
                                <PartnerGroupCredentials partnerId={partner.id} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
        </div>
    );
}
