import { useModalEditor } from "@davo/portal-common";
import { dsoStartDate } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { DailyActivity } from "./DailyActivity";
import { LocationDailyDetailModalSelect } from "./LocationDailyDetailModalSelect";

interface IAccountDailyDetailModal {
    accountId?: string;
    locationId: string;
}

export function LocationDailyDetailModal({ accountId, locationId }: IAccountDailyDetailModal) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(undefined);
    const [months, setMonths] = useState<string[]>([]);
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();

    useAsyncEffect(() => {
        const now = DateTime.local();
        const monthValues = [];
        // Approx D20 start date
        const monthsDiff = now.diff(dsoStartDate, ["months"]);

        for (let x = 0; x < monthsDiff.months; ++x) {
            const monthStr = now.minus({ month: x }).toFormat("yyyy-MM");
            monthValues.push(monthStr);
        }
        setMonths(monthValues);
        setSelectedMonth(monthValues[0]);
    }, []);

    const shutdown = () => {
        closeDialog();
    };

    return (
        <div>
            <Button
                size="small"
                variant="outlined"
                color="primary"
                style={{ marginTop: "2px", marginBottom: "2px" }}
                onClick={() => {
                    showDialog(true);
                }}>
                Daily Activity
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        shutdown();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Daily Activity</DialogTitle>
                    <DialogContent>
                        {!!selectedMonth && months.length && (
                            <>
                                <LocationDailyDetailModalSelect
                                    setSelectedMonth={setSelectedMonth}
                                    selectedMonth={selectedMonth}
                                    months={months}
                                />
                                <DailyActivity locationInfo={{ locationId, accountId, selectedMonth }} />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => shutdown()} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
