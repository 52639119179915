import {
    DavoLink,
    ORPHAN_RELATED_ACCOUNT_ID,
    ORPHAN_RELATED_ACCOUNT_NAME,
    PaperComponent,
    PartialAccount,
    RelatedAccountListItem,
    useModalEditor,
} from "@davo/portal-common";
import { RelatedLocation } from "@davo/types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

export function AccountRelatedLocationsModal({
    displayButton,
    relatedLocations,
    onComplete,
}: {
    displayButton: boolean;
    relatedLocations: RelatedLocation[];
    onComplete?: (str: PartialAccount) => void;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [accountList, setAccountList] = useState<RelatedAccountListItem[]>([]);
    const shutdown = () => {
        closeDialog();
    };

    const handleClick = (obj: PartialAccount) => {
        if (onComplete) {
            onComplete(obj);
        }
        closeDialog();
    };

    useEffect(() => {
        const renderList: RelatedAccountListItem[] = [];
        const uniqueAccountIds = [...Array.from(new Set(relatedLocations.map((rl) => rl.accountId)))];
        uniqueAccountIds.forEach((a) => {
            const thisAccount = relatedLocations.find((rl) => rl.accountId === a);
            renderList.push({
                id: a ?? ORPHAN_RELATED_ACCOUNT_ID,
                name: thisAccount?.accountName ?? ORPHAN_RELATED_ACCOUNT_NAME,
                locs: relatedLocations.filter((rl) => rl.accountId === a),
            });
        });
        setAccountList(renderList);
    }, [relatedLocations]);

    const renderRelatedList = (locs: RelatedLocation[]) => (
        <ul style={{ listStyleType: "none", padding: "0 0 10px", overflow: "hidden", minWidth: "350px" }}>
            {locs
                .sort((a, b) => a.locationName.localeCompare(b.locationName))
                .map((l, idx) => (
                    <li key={`${l.locationName}-${idx}`} style={{ margin: "10px 10px 0", overflow: "hidden" }}>
                        <div style={{ float: "left" }}>{l.locationName}</div>
                        <div style={{ float: "right", margin: "-5px 10px 0" }}>
                            {l.active ? (
                                <div style={{ color: "#6DD230" }}>
                                    <CheckIcon />
                                </div>
                            ) : (
                                <div style={{ color: "#FE4D97" }}>
                                    <CloseIcon />
                                </div>
                            )}
                        </div>
                    </li>
                ))}
        </ul>
    );

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                size={"small"}
                onClick={() => showDialog(true)}
                style={{ marginTop: "-6px" }}>
                {accountList.length} related account{accountList.length > 1 ? "s" : ""}
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        shutdown();
                    }}>
                    <DialogTitle id="draggable-dialog-title">Related Accounts</DialogTitle>
                    <DialogContent>
                        {accountList.length > 0 ? (
                            accountList.map((a) => (
                                <div key={a.name}>
                                    <div style={{ marginTop: "10px" }}>
                                        {displayButton &&
                                            (a.id ? (
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    style={{
                                                        fontSize: "1rem",
                                                        fontWeight: "400",
                                                        lineHeight: "1.5",
                                                        textDecoration: "underline",
                                                    }}
                                                    onClick={() =>
                                                        handleClick({
                                                            id: a.id,
                                                            name: a.name,
                                                        })
                                                    }>
                                                    {a.name}
                                                </Button>
                                            ) : (
                                                <div
                                                    style={{
                                                        fontSize: "1rem",
                                                        fontWeight: "400",
                                                        lineHeight: "1.5",
                                                        textDecoration: "underline",
                                                    }}>
                                                    {a.name}
                                                </div>
                                            ))}
                                        {!displayButton &&
                                            (a.id ? (
                                                <DavoLink to={`/accounts/${a.id}`}>{a.name}</DavoLink>
                                            ) : (
                                                <div
                                                    style={{
                                                        fontSize: "1rem",
                                                        fontWeight: "400",
                                                        lineHeight: "1.5",
                                                        textDecoration: "underline",
                                                    }}>
                                                    {a.name}
                                                </div>
                                            ))}
                                    </div>
                                    {renderRelatedList(a.locs)}
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => shutdown()} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
