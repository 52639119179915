import { d30Toast, d30ToastError, PaperComponent, TextField } from "@davo/portal-common";
import { ACHTransactionStatus, IAchTransaction } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { changeACHStatus } from "./services";

interface IToggleAchButtonType {
    ach: IAchTransaction;
    onClose: () => void;
}

export function ToggleACHButton({ ach, onClose }: IToggleAchButtonType) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const isOnHold = ach.status === "hold";
    const isStopped = ach.status === "stopped";
    const [toggleReason, setToggleReason] = useState<string | undefined>("");
    const [newStatus, setNewStatus] = useState<ACHTransactionStatus | undefined>(undefined);

    const doSubmit = () => {
        if (!toggleReason || !newStatus) {
            return;
        }
        changeACHStatus(ach.id, newStatus, toggleReason)
            .then(() => {
                d30Toast(`Successfully changed ACH status`);
            })
            .catch((e) => d30ToastError("Problem changing ACH status.", e))
            .finally(() => {
                onClose();
            });
    };

    const getConfirmationButtonLabel = () => {
        if (!newStatus || newStatus === "hold") {
            return "HOLD";
        } else if (newStatus === "pending") {
            return "RELEASE";
        } else {
            return "STOP";
        }
    };

    const getConfirmationFieldLabel = () => {
        if (!newStatus || newStatus === "hold") {
            return "Reason for holding this transaction";
        } else if (newStatus === "pending") {
            return "Reason for releasing this transaction";
        } else {
            return "Reason for stopping this transaction";
        }
    };

    const getConfirmationButtonColor = () => {
        if (!newStatus || newStatus === "hold") {
            return "warning";
        } else if (newStatus === "pending") {
            return "success";
        } else {
            return "secondary";
        }
    };

    return (
        <div style={{ marginTop: "2px", marginRight: "2px" }}>
            {!isStopped && (
                <Button
                    variant="outlined"
                    onClick={() => {
                        setNewStatus(isOnHold ? "pending" : "hold");
                        setIsShowing(true);
                    }}
                    size="small"
                    color={isOnHold ? "success" : "warning"}
                    style={{ margin: "3px" }}>
                    {isOnHold ? "RELEASE" : "HOLD"}
                </Button>
            )}

            {isOnHold && (
                <Button
                    variant="outlined"
                    onClick={() => {
                        setNewStatus("stopped");
                        setIsShowing(true);
                    }}
                    size="small"
                    color={"secondary"}
                    style={{ margin: "3px" }}>
                    {"STOP"}
                </Button>
            )}

            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle id="draggable-dialog-title">Change Transaction Status</DialogTitle>
                    <DialogContent style={{ padding: "0px 24px" }}>
                        <TextField
                            label={getConfirmationFieldLabel()}
                            value={toggleReason ?? ""}
                            onChange={setToggleReason}
                            isMultiline={true}
                            rows={2}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color={getConfirmationButtonColor()}
                            disabled={!toggleReason}
                            onClick={doSubmit}>
                            {getConfirmationButtonLabel()}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
