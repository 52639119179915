import { d30Toast, d30ToastError, TextField } from "@davo/portal-common";
import { OptionalString, TaxProfile } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { setCredentialsNote } from "./services";

export interface ISetCredentialsNote {
    taxProfile: TaxProfile;
    doneCallback?: () => void;
}

export const SetCredentialsNote: FunctionComponent<ISetCredentialsNote> = ({ taxProfile, doneCallback }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isSettingCredentialsNote, setIsSettingCredentialsNote] = useState<boolean>(false);
    const [newCredentialsNote, setNewCredentialsNote] = useState<OptionalString>(undefined);

    useEffect(() => {
        setNewCredentialsNote(taxProfile.credentialsNote);
    }, [taxProfile]);

    const cancel = () => {
        setIsDialogOpen(false);

        // reset filing note back to original value
        setNewCredentialsNote(taxProfile.credentialsNote);
    };

    const doSave = () => {
        setCredentialsNote(taxProfile.id, newCredentialsNote)
            .then(() => {
                setIsDialogOpen(false);
                d30Toast("Reoccurring Credentials Note Change Successful");
            })
            .catch((e) => {
                d30ToastError("Problem setting credentials note.", e);
            })
            .finally(() => {
                setIsSettingCredentialsNote(false);
                if (doneCallback) {
                    doneCallback();
                }
            });
    };

    return (
        <span data-testid={"setCredentialsNote"}>
            <Button
                data-testid={"openSetCredentialsNoteDialogBtn"}
                variant="outlined"
                style={{ margin: "0px 0px 0px 10px" }}
                onClick={() => {
                    setIsDialogOpen(true);
                }}
                size="small">
                Edit Credentials Note
            </Button>

            {isDialogOpen && (
                <Dialog
                    data-testid={"setCredentialsNoteDialog"}
                    open={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    PaperProps={{
                        component: "form",
                        onSubmit: (event: FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            if (isSettingCredentialsNote) return;
                            setIsSettingCredentialsNote(true);
                            doSave();
                        },
                    }}
                    onClose={cancel}>
                    <DialogTitle>Set Credentials Note</DialogTitle>
                    <DialogContent>
                        <TextField
                            inputProps={{
                                ["data-testid"]: "setCredentialsNoteInput",
                            }}
                            label="Credentials Note"
                            value={newCredentialsNote ?? ""}
                            onChange={setNewCredentialsNote}
                            isMultiline={true}
                            rows={4}
                        />
                        <div style={{ textAlign: "center", fontSize: "10px", marginTop: "-10px" }}>
                            (Previous Credentials notes are saved in Account Log)
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isSettingCredentialsNote}
                            variant="outlined"
                            data-testid={"cancelDialogBtn"}
                            color="primary"
                            onClick={cancel}>
                            Cancel
                        </Button>
                        <Button
                            startIcon={
                                isSettingCredentialsNote && (
                                    <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                                )
                            }
                            disabled={isSettingCredentialsNote}
                            data-testid={"saveCredentialsNoteBtn"}
                            variant="contained"
                            type={"submit"}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </span>
    );
};
