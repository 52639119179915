import {
    d30Toast,
    d30ToastError,
    downloadNoticeFile,
    LinkButton,
    MultiSelect,
    ReactTable,
    Select,
} from "@davo/portal-common";
import {
    FilingPenalty,
    initialCap,
    INotice,
    moneyFromCents,
    noop,
    NoticeStatus,
    NoticeStatuses,
    NoticeType,
    NoticeTypes,
    toDisplayDateString,
} from "@davo/types";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownload";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoteAddIcon from "@mui/icons-material/NoteOutlined";
import { Button, Card, CardHeader, Grid, IconButton, Link, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { colors } from "./filing/utils";
import { NoticesAddNoteModal } from "./NoticesAddNoteModal";
import { getNoticesForFilter, setNoticeStatus } from "./services";

export function OpsNoticesTable() {
    const theme = useTheme();

    const [statuses, setStatuses] = useState<NoticeStatus[]>(["new" as NoticeStatus]);
    const [types, setTypes] = useState<NoticeType[]>(["discrepancy" as NoticeType, "other" as NoticeType]);
    const [notices, setNotices] = useState<
        (INotice & {
            accountId: string;
            accountName: string;
            createdByName: string;
            state: string;
            downloadReady: boolean;
            penalty?: FilingPenalty;
        })[]
    >();

    const refresh = useCallback(() => {
        setNotices(undefined);
        if (statuses.length > 0 && types.length > 0) {
            getNoticesForFilter(statuses, types)
                .then((results) => {
                    results.sort((x, y) => {
                        if (x.status === y.status) {
                            return x.updated < y.updated ? -1 : 1;
                        } else if (x.status === "new") {
                            return -1;
                        } else if (x.status?.includes("open") && y.status?.includes("closed")) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                    setNotices(results);
                })
                .catch((e) => d30ToastError("Problem getting open notices.", e));
        }
    }, [statuses, types]);

    useEffect(() => {
        refresh();
    }, [refresh, statuses, types]);

    const getDetailPaneData = useCallback((data: any) => {
        return (
            <div style={{ padding: "32px" }}>
                <Grid container spacing={2} rowSpacing={4}>
                    <Grid item container spacing={2} alignContent="flex-start">
                        <Grid item xs={12} md={6}>
                            <Stack direction="row" spacing={2}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ marginRight: "20px" }}>
                                        Type: {initialCap(data.row.original.type)}
                                    </div>
                                    {data.row.original.status !== "closed-invalid-file" &&
                                        data.row.original.downloadReady && (
                                            <Link
                                                download
                                                target="_blank"
                                                onClick={async () => {
                                                    await downloadNoticeFile(data.row.original);
                                                }}>
                                                <Button
                                                    style={{ marginRight: "20px", cursor: "pointer" }}
                                                    variant="outlined"
                                                    startIcon={<CloudDownloadTwoToneIcon color="primary" />}>
                                                    Download File
                                                </Button>
                                            </Link>
                                        )}
                                    {!data.row.original.downloadReady && (
                                        <Button
                                            style={{ marginRight: "20px", cursor: "pointer" }}
                                            variant="outlined"
                                            disabled={true}
                                            startIcon={<CloudDownloadTwoToneIcon color="primary" />}>
                                            File Processing
                                        </Button>
                                    )}

                                    {data.row.original.noteFromMerchant && (
                                        <Grid item xs={12} md={4}>
                                            <Stack direction="row" spacing={0}>
                                                <Typography>Merchant Context:</Typography>
                                                <Tooltip title={data.row.original.noteFromMerchant}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="Context"
                                                            onClick={() => {
                                                                noop();
                                                            }}
                                                            size="large">
                                                            <NoteAddIcon color="primary" />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </Stack>
                                        </Grid>
                                    )}
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Stack direction="row" spacing={2}>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(data.row.original.taxDue)}
                                            subheader={"Tax Due"}
                                        />
                                    </Card>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(data.row.original.penalties)}
                                            subheader={"Penalties"}
                                        />
                                    </Card>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(data.row.original.interest)}
                                            subheader={"Interest"}
                                        />
                                    </Card>
                                </Stack>
                            </Grid>
                            {data.row.original.penalty && (
                                <Grid item xs={12} md={4}>
                                    <LinkButton
                                        url={`/penalties/${data.row.original.penalty.id}`}
                                        label={`See associated penalty`}
                                        labelMaxChars={35}
                                        align={"left"}
                                        rel="noreferrer"
                                        target="_blank"
                                    />
                                </Grid>
                            )}
                            {!data.row.original.penalty && (
                                <Grid item xs={12} md={4}>
                                    <Typography>No associated penalty</Typography>
                                    <LinkButton
                                        url={`/penalties`}
                                        label={`Penalties`}
                                        labelMaxChars={35}
                                        align={"left"}
                                        rel="noreferrer"
                                        target="_blank"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }, []);

    const columns = useMemo(() => {
        const getStatusColor = (status?: NoticeStatus | null) => {
            if (!status) {
                return;
            }
            if (status === "new") {
                return colors["ready"];
            } else if (status.includes("open")) {
                return colors["pending"];
            } else if (theme.palette.mode === "dark") {
                return "white";
            } else {
                return "black";
            }
        };

        const handleStatusClicked = async (notice: INotice, newStatus?: NoticeStatus) => {
            if (!newStatus || notice.status === newStatus) {
                return;
            }
            if (newStatus === "closed-cancelled") {
                d30ToastError(
                    "Cancelling a notice is reserved for the merchant, you can do this from the Merchant Portal if you really need to."
                );
                return;
            }
            if ((newStatus.includes("closed") || newStatus === "open-waiting-on-customer") && !notice.noteToMerchant) {
                d30ToastError("Merchant note is required to transition to selected status.");
                return;
            }
            await setNoticeStatus(notice.id, newStatus);
            refresh();
            d30Toast("Status changed successfully");
        };

        const renderStatusButton = (data: INotice) => {
            return (
                <div style={{ marginTop: "4px", marginRight: "20px" }}>
                    <Select<NoticeStatus>
                        style={{ minWidth: "400px", color: getStatusColor(data.status) }}
                        options={Object.keys(NoticeStatuses).map((x) => x as NoticeStatus)}
                        label={(u) => initialCap(u)}
                        value={data.status}
                        onChange={(newStatus: NoticeStatus | undefined) => {
                            handleStatusClicked(data, newStatus)
                                .then(() => {
                                    noop();
                                })
                                .catch((e) => d30ToastError("Problem setting status.", e));
                        }}
                    />
                </div>
            );
        };

        return [
            {
                id: "expander", // Make sure expand columns have an ID
                Cell: (data: any) => {
                    return (
                        <span
                            {...data.row.getToggleRowExpandedProps({
                                style: {
                                    paddingLeft: "8px",
                                },
                            })}
                            className={"mui-expand-toggle-container"}>
                            {data.row.isExpanded ? (
                                <span className={"expandLess"}>
                                    <ExpandLessIcon />
                                </span>
                            ) : (
                                <span className={"expandMore"}>
                                    <ExpandMoreIcon />
                                </span>
                            )}
                        </span>
                    );
                },
                disableSortBy: true,
            },
            {
                Header: "Created",
                id: "created",
                accessor: "created",
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>
                        {toDisplayDateString(data.cell.row.original.created.toSQLDate())}
                    </div>
                ),
            },
            {
                Header: "Last Updated",
                id: "updated",
                accessor: "updated",
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>
                        {toDisplayDateString(data.cell.row.original.updated.toSQLDate())}
                    </div>
                ),
            },
            {
                Header: "Status",
                id: "status",
                accessor: "status",
                Cell: (data: any) => {
                    return renderStatusButton(data.cell.row.original);
                },
            },
            {
                Header: "Period",
                id: "period",
                accessor: "period",
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>
                        {toDisplayDateString(DateTime.fromSQL(data.row.original.periodStart))}-
                        {toDisplayDateString(DateTime.fromSQL(data.row.original.periodEnd))}
                    </div>
                ),
            },
            {
                Header: "State",
                id: "state",
                accessor: "state",
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{data.cell.row.original.state}</div>,
            },
            {
                Header: "Account",
                id: "accountName",
                accessor: "accountName",
                Cell: (data: any) => {
                    return (
                        <LinkButton
                            url={`/accounts/${data.row.original.accountId}`}
                            label={`${data.row.original.accountName}`}
                            labelMaxChars={35}
                            align={"left"}
                            rel="noreferrer"
                            target="_blank"
                        />
                    );
                },
            },
            {
                Header: "Notes",
                id: "note",
                accessor: "noteInternal",
                Cell: (data: any) => <NoticesAddNoteModal notice={data.cell.row.original} />,
            },
            {
                Header: "Uploaded By",
                id: "createdByName",
                accessor: "createdByName",
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{data.cell.row.original.createdByName}</div>,
            },
        ];
    }, [refresh, theme.palette.mode]);

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Stack direction="row">
                    <Stack direction="column">
                        <MultiSelect<NoticeStatus>
                            options={Object.keys(NoticeStatuses).map((x) => x as NoticeStatus)}
                            value={statuses}
                            label={(s) => {
                                return <Typography>{initialCap(s)}</Typography>;
                            }}
                            onChange={(stats) => {
                                setStatuses(stats);
                            }}
                            title={"Statuses"}
                        />
                    </Stack>
                    <Stack direction="column">
                        <MultiSelect<NoticeType>
                            options={Object.keys(NoticeTypes) as NoticeType[]}
                            value={types}
                            label={(t) => {
                                return <Typography>{NoticeTypes[t]}</Typography>;
                            }}
                            onChange={(tys) => {
                                setTypes(tys);
                            }}
                            title={"Types"}
                        />
                    </Stack>
                </Stack>
            </div>

            <ReactTable
                columns={columns}
                data={notices ?? []}
                options={{
                    hideToolbar: false,
                    pageSize: 25,
                }}
                renderRowSubComponent={getDetailPaneData}
                refreshData={refresh}
            />
        </>
    );
}
