import {
    d30Toast,
    d30ToastError,
    getLocationToTaxProfiles,
    PaperComponent,
    Select,
    setStateExternalLocation,
    TextField,
    useRefresh,
} from "@davo/portal-common";
import { Location, LocationToTaxProfile, noop, Partner, TaxProfile } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { TaxProfileFilingTypeRender } from "./TaxProfileFilingTypeRender";
import { getLocationLabel } from "./util";

export interface ILocationExternalIdentifierModal {
    closeDialog: (target?: string) => void;
    accountId: string;
    locations: Location[];
    taxProfile: TaxProfile;
    partner?: Partner;
    refresh?: () => void;
}

export function LocationExternalIdentifierModal({
    closeDialog,
    accountId,
    locations,
    taxProfile,
    partner,
    refresh = noop,
}: ILocationExternalIdentifierModal) {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [externalStateLocationId, setExternalStateLocationId] = useState<string | null | undefined>(undefined);
    const [locationToTaxProfileMapping, setLocationToTaxProfileMapping] = useState<LocationToTaxProfile[]>();

    useRefresh(() => {
        const locationIds = locations.map((l) => l.id);
        getLocationToTaxProfiles(accountId, taxProfile.id, locationIds)
            .then((results) => {
                setLocationToTaxProfileMapping(results);
            })
            .catch((e) => d30ToastError("Problem getting location.", e));
    });

    useAsyncEffect(() => {
        if (locations && locations.length === 1) {
            handleLocationChange(locations[0]);
        }
    }, []);

    const handleLocationChange = (selectedOption: Location | undefined) => {
        setLocation(selectedOption);
        setExternalStateLocationId(undefined);
        if (selectedOption && locationToTaxProfileMapping) {
            const mapping = locationToTaxProfileMapping.find(
                (l) => l.accountLocationId === selectedOption.id && l.accountTaxProfileId === taxProfile.id
            );
            setExternalStateLocationId(mapping?.externalStateLocationId);
        }
    };

    const persistExternalLocation = async () => {
        if (!location || !externalStateLocationId) {
            return;
        }

        setIsBusy(true);
        try {
            await setStateExternalLocation(accountId, location.id, taxProfile.id, externalStateLocationId);
            locationToTaxProfileMapping?.forEach((mapping) => {
                if (mapping.accountLocationId === location.id && mapping.accountTaxProfileId === taxProfile.id) {
                    mapping.externalStateLocationId = externalStateLocationId;
                }
            });
            d30Toast("State External Location ID Assignment Successful.");
        } finally {
            setIsBusy(false);
        }
    };

    const shutdown = (result?: string) => {
        setExternalStateLocationId(undefined);
        setLocation(undefined);

        refresh();
        closeDialog(result);
    };

    return (
        <Dialog
            open={true}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            style={{ boxShadow: "none", margin: "5px 2px" }}
            onClose={() => shutdown()}>
            <DialogTitle id="draggable-dialog-title">Assign State External Location ID</DialogTitle>
            <DialogContent>
                <div>
                    <Typography style={{ textDecoration: "underline" }}> Tax Profile: </Typography>
                    <Typography>Name: {taxProfile.name}</Typography>
                    {taxProfile.legalName && <Typography>Legal Name: {taxProfile.legalName}</Typography>}
                    <Typography>State: {taxProfile.state}</Typography>
                    {taxProfile.stateTaxId && <Typography>State Tax ID: {taxProfile.stateTaxId}</Typography>}
                    <Typography>Frequency: {taxProfile.frequency}</Typography>
                    {taxProfile.jurisdictionFilingTypeId && (
                        <TaxProfileFilingTypeRender id={taxProfile.jurisdictionFilingTypeId} />
                    )}
                    {taxProfile.federalTaxId && <Typography>Federal Tax ID: {taxProfile.federalTaxId}</Typography>}
                    {taxProfile.filingMethod && <Typography>Filing Method: {taxProfile.filingMethod}</Typography>}

                    {locations.length > 1 ? (
                        <div>
                            <Select<Location>
                                noneLabel={"Select a location"}
                                value={location}
                                onChange={(value: Location | undefined) => {
                                    handleLocationChange(value);
                                }}
                                options={locations}
                                label={(l: Location) => getLocationLabel(l, partner)}
                                style={{ width: "400px" }}
                            />
                        </div>
                    ) : (
                        locations.length > 0 && (
                            <div style={{ marginTop: "15px", marginBottom: "15px", width: "400px" }}>
                                <Typography style={{ textDecoration: "underline" }}> Location: </Typography>
                                <Typography>Name: {locations[0].name} </Typography>
                                <Typography>
                                    Address: {locations[0].address1}, {locations[0].city} {locations[0].state}{" "}
                                    {locations[0].zip}{" "}
                                </Typography>
                            </div>
                        )
                    )}
                    <TextField
                        label="State Location Id"
                        value={externalStateLocationId ?? ""}
                        onChange={setExternalStateLocationId}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <div>
                    <Button onClick={() => shutdown()} variant="outlined" color="primary" style={{ width: "100px" }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => persistExternalLocation()}
                        disabled={isBusy || !location || !externalStateLocationId}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "5px", marginRight: "18px", width: "100px" }}>
                        Save
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
