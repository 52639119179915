import { Select, useModalEditor } from "@davo/portal-common";
import { LocationRecord, noop } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Theme,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { setMidReplaceBy } from "./services";

const useStyles = makeStyles((theme: Theme) => ({
    dropdownItem: {
        display: "flex",
        flexDirection: "column",
    },
    textSecondary: {
        fontSize: "0.8rem",
        color: theme.palette.mode === "dark" ? "rgb(152, 169, 188)" : "rgb(119, 140, 162)",
        maxWidth: "340px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export interface ILocationMidChangeModal {
    location: LocationRecord;
    allAccountLocations: LocationRecord[] | undefined;
}

type ReplacementOptions = "replacer" | "replacee";

export function LocationMidChangeModal({ location, allAccountLocations }: ILocationMidChangeModal) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor(noop);
    const [replaceByType, setReplaceByType] = useState<ReplacementOptions>();
    const [canReplaceByType, setCanReplaceByType] = useState<boolean>();
    const [otherLocationId, setOtherLocationId] = useState<string>();
    const [selectOptions, setSelectOptions] = useState<string[]>([]);
    const [selectNonLabel, setSelectNonLabel] = useState<string>();
    const [showButtonDialogText, setShowButtonDialogText] = useState<string>();
    const [isReady, setIsReady] = useState<boolean>();
    const handleMergeDestinationChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) =>
        setReplaceByType(value);

    const shutdown = () => {
        if (closeDialog) {
            closeDialog();
        }
    };

    useAsyncEffect(async () => {
        // prep modal for next showing
        defaultControls();
        validate();
        generateSelectNonLabel();
    }, [isDialogOpen]);

    useAsyncEffect(async () => {
        defaultControls();
        validate();
        generateSelectNonLabel();
        generateShowDialogButtonText();
    }, [location]);

    useAsyncEffect(async () => {
        validate();
        generateSelectNonLabel();
        generateShowDialogButtonText();
    }, [replaceByType, otherLocationId]);

    useAsyncEffect(async () => {
        if (!isNil(allAccountLocations) && !isEmpty(allAccountLocations)) {
            const listOfAccounts = allAccountLocations?.filter(
                (_location) => _location.id !== location.id && _location.replacedByLocationId !== location.id
            );
            setSelectOptions(sortBy(listOfAccounts, ["name", "address1", "id"]).map((_location) => _location.id));
        } else {
            setSelectOptions([]);
        }
        generateShowDialogButtonText();
    }, [allAccountLocations]);

    const defaultControls = () => {
        if (isNil(location.replacedByLocationId)) {
            setReplaceByType(location.active ? "replacer" : "replacee");
            setOtherLocationId(undefined);
            setCanReplaceByType(true);
        } else {
            setReplaceByType("replacee");
            setOtherLocationId(location.replacedByLocationId);
            setCanReplaceByType(false);
        }
    };

    const generateShowDialogButtonText = () => {
        if (!isDialogOpen) {
            if (isNil(otherLocationId)) {
                setShowButtonDialogText("Set Mid Change");
            } else {
                const _location = allAccountLocations?.find((_innerLocation) => _innerLocation.id === otherLocationId);
                if (isNil(_location)) {
                    setShowButtonDialogText("Mid change set, unknown");
                } else {
                    setShowButtonDialogText(`Mid change set, ${_location.name}`);
                }
            }
        }
    };

    const generateSelectNonLabel = () => {
        if (isNil(otherLocationId)) {
            setSelectNonLabel("Select the other location");
        } else {
            setSelectNonLabel("Remove selected location");
        }
    };

    const renderSelectItemLabelContent = (locationRecord: LocationRecord | undefined): React.ReactNode => {
        if (!locationRecord) {
            return <span>Unknown</span>;
        }
        return (
            <>
                <span>{locationRecord.name}</span>
                {!isEmpty(locationRecord.address1) && (
                    <Tooltip title={<div>{locationRecord.address1}</div>}>
                        <span className={classes.textSecondary}>{locationRecord.address1}</span>
                    </Tooltip>
                )}
            </>
        );
    };

    const renderSelectItemLabel = (locationId: string) => {
        const _location = allAccountLocations?.find((_innerLocation) => _innerLocation.id === locationId);
        return (
            <div
                key={locationId}
                className={classes.dropdownItem}
                data-testid={`choose-replacement-location-item-${locationId}`}>
                {renderSelectItemLabelContent(_location)}
            </div>
        );
    };

    const validate = () => {
        if (replaceByType === "replacee") {
            setIsReady(true);
        } else {
            setIsReady(!isNil(otherLocationId));
        }
    };

    const doCancel = () => {
        shutdown();
    };

    const recordMidChange = async () => {
        if (replaceByType === "replacee") {
            await setMidReplaceBy(location.id, otherLocationId);
            location.replacedByLocationId = otherLocationId;
        } else {
            if (otherLocationId) {
                await setMidReplaceBy(otherLocationId, location.id);
            } else {
                return; // in this case we shouldn't have reached this spot but we need the safety net.
            }
        }
        shutdown();
    };

    if (!location) {
        return null;
    }

    return (
        <div style={{ display: "inline-block" }}>
            {(!isEmpty(selectOptions) || !isNil(otherLocationId)) && (
                <Button
                    data-testid={"show-mid-change-dialog"}
                    variant="outlined"
                    onClick={showDialog}
                    size="small"
                    color="primary"
                    style={{ marginTop: "3px" }}
                    disabled={isEmpty(selectOptions)}>
                    {showButtonDialogText}
                </Button>
            )}
            {isDialogOpen && (
                <Dialog
                    data-testid={"choose-replacement-dialog"}
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        shutdown();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Record Mid Change</DialogTitle>
                    <DialogContent>
                        <div>
                            <FormControl disabled={!canReplaceByType}>
                                <RadioGroup
                                    name="replacement-radio-group"
                                    data-testid={"choose-replacement-type"}
                                    value={replaceByType}
                                    onChange={handleMergeDestinationChange}>
                                    <FormControlLabel value="replacer" control={<Radio />} label="Is Replacing" />
                                    <FormControlLabel value="replacee" control={<Radio />} label="Replaced By" />
                                </RadioGroup>
                            </FormControl>
                            <Select<string>
                                title="Location"
                                data-testid={"choose-replacement-location-id"}
                                value={otherLocationId}
                                onChange={(locationId) => {
                                    setOtherLocationId(locationId);
                                }}
                                noneLabel={selectNonLabel}
                                options={selectOptions}
                                label={renderSelectItemLabel}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <>
                            <Button
                                variant="outlined"
                                onClick={doCancel}
                                color="primary"
                                style={{ marginBottom: "2px", marginTop: "2px", marginRight: "2px" }}>
                                Cancel
                            </Button>

                            <Button
                                data-testid={"choose-replacement-save-button"}
                                variant="contained"
                                onClick={recordMidChange}
                                color="primary"
                                style={{ marginBottom: "2px", marginTop: "2px" }}
                                title={`Set the mid change`}
                                disabled={!isReady}>
                                Save
                            </Button>
                        </>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
