import {
    d30Toast,
    d30ToastError,
    NumberOnlyField,
    Select,
    TextField,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import {
    BusinessDay,
    IAccountDiscount,
    initialCap,
    moneyFromCents,
    toDisplayDateString,
    toDollars,
    toPennies,
} from "@davo/types";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isInteger from "lodash/isInteger";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { addAccountDiscount, canAddAccountDiscount, getAccountDiscount, removeAccountDiscount } from "./services";
import { hasPermission } from "./util";

interface IAccountDiscountModal {
    accountId?: string;
}

export function AccountDiscountModal({ accountId }: IAccountDiscountModal) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const loginContext = useLoginContext();
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();

    const shutdown = () => {
        closeDialog();
    };

    const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const discountReasonList = ["Beta Customer", "Other"];
    const typeList = ["flat", "percentage"];
    const [discount, setDiscount] = useState<IAccountDiscount>();
    const [isContinuous, setIsContinuous] = useState<boolean>(false);
    const [discountMonths, setDiscountMonths] = useState<number | undefined>(undefined);
    const [discountReason, setDiscountReason] = useState<string | undefined>(undefined);
    const [discountType, setDiscountType] = useState<string | undefined>(undefined);
    const [discountAmount, setDiscountAmount] = useState<number | undefined>(undefined);
    const [discountReasonOther, setDiscountReasonOther] = useState<string>("");
    const [canAddDiscount, setCanAddDiscount] = useState<boolean | undefined>();
    const [hasCanDiscountPermission, setHasCanDiscountPermission] = useState<boolean>(false);

    const loadFeeDiscount = async () => {
        if (!accountId) {
            return;
        }

        setDiscount(await getAccountDiscount(accountId, BusinessDay.today()));
        setDiscountMonths(undefined);
        setDiscountReason(undefined);
        setDiscountAmount(undefined);
        setDiscountType(undefined);
        setIsContinuous(false);
        setDiscountReasonOther("");
        const canAdd = await canAddAccountDiscount(accountId);
        setCanAddDiscount(canAdd);
        setHasCanDiscountPermission(hasPermission(loginContext.permissions, "can_discount_subscription"));
    };

    useAsyncEffect(async () => {
        await loadFeeDiscount();
    }, [accountId, loginContext.permissions]);

    if (!accountId) {
        return null;
    }

    const canSubmitDiscountFee = () => {
        return (
            (!!discountMonths || isContinuous) &&
            !!discountReason &&
            ((discountReason === "Other" && !!discountReasonOther) || discountReason !== "Other") &&
            discountAmount
        );
    };

    const doAddDiscount = async () => {
        const reason = (discountReason === "Other" ? `Other - ${discountReasonOther}` : discountReason) ?? "";
        const result: { discounted: boolean; message: string } = await addAccountDiscount(
            accountId,
            reason,
            discountType ?? "flat",
            discountAmount ?? 0,
            discountMonths
        );

        if (result.discounted) {
            d30Toast("Account discount added.");

            await loadFeeDiscount();
        } else {
            d30ToastError(result.message);
        }

        await loadFeeDiscount();
    };

    const doRemoveDiscount = async () => {
        await removeAccountDiscount(accountId);

        d30Toast("Account discount removed.");
        shutdown();
        await loadFeeDiscount();
    };

    const validateAmount = (amt: number | undefined) => {
        if (amt && discountType) {
            if (discountType === "flat" && amt < 1) {
                return `Flat discount cannot be less than .01`;
            } else if (discountType === "percentage") {
                if (amt > 100 || amt < 1) {
                    return `Discount percentage must be between 1 and 100 inclusive`;
                } else if (!isInteger(amt)) {
                    return `Discount percentage must be a whole number`;
                }
            }
        }
        return undefined;
    };

    const doCancel = () => {
        shutdown();
    };

    const setFormatAmountLabel = () => {
        if (discount) {
            return discount.type === "flat" ? `$${moneyFromCents(discount.amount)}` : `${discount.amount}%`;
        }
        return undefined;
    };

    return (
        <div style={{ display: "inline-block" }}>
            {hasCanDiscountPermission && (
                <Button variant="outlined" style={{ margin: "5px" }} onClick={showDialog} color="primary">
                    Account Discount
                </Button>
            )}
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        shutdown();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Account Discount</DialogTitle>
                    <DialogContent>
                        <div>
                            <div>
                                {!canAddDiscount && !isNil(canAddDiscount) && (
                                    <div>
                                        One or more location discounts exist for this account. Please remove them before
                                        adding a discount to the account.
                                    </div>
                                )}

                                {canAddDiscount && discount && (
                                    <div style={{ marginBottom: "10px" }}>
                                        <div>
                                            {discount.discountedUntil && (
                                                <span title={discount.discountReason}>
                                                    Subscription discounted until{" "}
                                                    {toDisplayDateString(discount.discountedUntil, false)}
                                                </span>
                                            )}
                                            {!discount.discountedUntil && (
                                                <span title={discount.discountReason}>
                                                    Subscription Discounted Continuously
                                                </span>
                                            )}

                                            <IconButton
                                                aria-label="Remove discount"
                                                onClick={() => doRemoveDiscount()}
                                                title={"Remove discount"}
                                                size="large">
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                        <span title={discount.type}>{`Type: ${initialCap(discount.type)}`}</span>
                                        <br />
                                        <span
                                            title={discount.amount.toString()}>{`Amount: ${setFormatAmountLabel()}`}</span>
                                        <br />
                                        <span
                                            title={
                                                discount.discountReason
                                            }>{`Reason: ${discount.discountReason}`}</span>
                                        <br />
                                        {isNil(discount.discountedUntil) ? (
                                            <span title={"noend"}>{`This discount will never expire.`}</span>
                                        ) : (
                                            <span
                                                title={discount.discountedUntil.toString()}>{`End: ${discount.discountedUntil}`}</span>
                                        )}
                                        <br />
                                        <span
                                            title={discount.created.toString()}>{`Created: ${discount.created}`}</span>
                                        <br />
                                        {isNil(discount.user?.id) ? (
                                            <span title={"unknownUser"}>{`By: unknown`}</span>
                                        ) : (
                                            <span title={discount.user.id}>
                                                {`By: ${discount.user.firstName} ${discount.user.lastName} (${discount.user.email})`}
                                            </span>
                                        )}
                                        <br />
                                    </div>
                                )}

                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    {canAddDiscount && !discount && (
                                        <span>
                                            <Typography variant={"body1"}>Add New Discount</Typography>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            title={"Continuous"}
                                                            checked={isContinuous}
                                                            onChange={() => {
                                                                setIsContinuous(!isContinuous);
                                                                setDiscountMonths(undefined);
                                                            }}
                                                        />
                                                    }
                                                    label="Continuous"
                                                />
                                            </FormGroup>

                                            {!isContinuous && (
                                                <Select<number>
                                                    title="How Many Months"
                                                    isDisabled={isContinuous}
                                                    options={monthList}
                                                    value={discountMonths}
                                                    onChange={setDiscountMonths}
                                                    label={(s) => `${s} month${s > 1 ? "s" : ""}`}
                                                />
                                            )}
                                            <Select<string>
                                                title="Reason"
                                                options={discountReasonList}
                                                value={discountReason}
                                                onChange={setDiscountReason}
                                                isDisabled={!discountMonths && !isContinuous}
                                            />
                                            {discountReason === "Other" && (
                                                <TextField
                                                    label={"Other"}
                                                    value={discountReasonOther}
                                                    onChange={setDiscountReasonOther}
                                                    isMultiline={true}
                                                    rows={4}
                                                />
                                            )}
                                            <Select<string>
                                                title="Type"
                                                options={typeList}
                                                value={discountType}
                                                onChange={(value) => {
                                                    setDiscountType(value);
                                                }}
                                                isDisabled={!discountReason}
                                                label={(s) => initialCap(s)}
                                            />
                                            <NumberOnlyField
                                                disabled={!discountType}
                                                label={`Amount`}
                                                value={
                                                    discountType && discountType === "flat"
                                                        ? toDollars(discountAmount)
                                                        : discountAmount
                                                }
                                                onChange={(e) => {
                                                    discountType && discountType === "flat"
                                                        ? setDiscountAmount(toPennies(e))
                                                        : setDiscountAmount(e);
                                                }}
                                                validate={validateAmount}
                                            />
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <>
                            {(discount || !canAddDiscount) && (
                                <Button
                                    variant="contained"
                                    onClick={doCancel}
                                    color="primary"
                                    style={{ marginBottom: "2px", marginTop: "2px" }}>
                                    Ok
                                </Button>
                            )}

                            {!discount && canAddDiscount && (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={doCancel}
                                        color="primary"
                                        style={{ marginBottom: "2px", marginTop: "2px", marginRight: "2px" }}>
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="contained"
                                        onClick={doAddDiscount}
                                        color="primary"
                                        style={{ marginBottom: "2px", marginTop: "2px" }}
                                        disabled={!canSubmitDiscountFee()}
                                        title={`Discount the next ${discountMonths} subscription fee(s)`}>
                                        Save
                                    </Button>
                                </>
                            )}
                        </>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
