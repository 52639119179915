import { ORPHAN_RELATED_ACCOUNT_ID, PartialAccount, ReactTable, RelatedAccountListItem } from "@davo/portal-common";
import { RelatedLocation } from "@davo/types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Badge, Button, Chip, Tooltip, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { CellProps } from "react-table";

export interface IRelatedAccounts {
    relatedLocations: RelatedLocation[];
    selectedAccount: PartialAccount | undefined;
    handleSelectAccount: (account: PartialAccount) => void;
}

export const RelatedAccounts: FunctionComponent<IRelatedAccounts> = ({
    relatedLocations,
    selectedAccount,
    handleSelectAccount,
}) => {
    const [accountList, setAccountList] = useState<RelatedAccountListItem[]>();

    useEffect(() => {
        const renderList: RelatedAccountListItem[] = [];
        const uniqueAccountIds = [...Array.from(new Set(relatedLocations.map((rl) => rl.accountId)))];

        uniqueAccountIds.forEach((a) => {
            const thisAccount = relatedLocations.find((rl) => rl.accountId === a);
            renderList.push({
                id: a ?? ORPHAN_RELATED_ACCOUNT_ID,
                name: thisAccount?.accountName ?? "NO ACCOUNT",
                locs: relatedLocations.filter((rl) => rl.accountId === a),
            });
        });
        setAccountList(renderList);
    }, [relatedLocations]);

    const columns = useMemo(() => {
        return [
            {
                id: "expander",
                accessor: "expander",
                Cell: ({ row }: CellProps<{}, any>) => {
                    return (
                        <div {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? (
                                <ExpandMoreIcon />
                            ) : (
                                <ExpandLessIcon style={{ transform: "rotate(90deg)" }} />
                            )}
                        </div>
                    );
                },
                disableSortBy: true,
            },
            {
                id: "acctName",
                Header: "Account Name",
                Cell: ({ row }: CellProps<{}, any>) => {
                    return (row.original as RelatedAccountListItem).name;
                },
                disableSortBy: true,
            },
            {
                id: "acctLocations",
                Header: "Locations",
                Cell: ({ row }: CellProps<{}, any>) => {
                    const accountLocations = (row.original as RelatedAccountListItem).locs;
                    return (
                        <div>
                            <Tooltip
                                title={
                                    <>
                                        {accountLocations.length} location
                                        {accountLocations.length > 1 && "s"}
                                    </>
                                }
                                placement={"top"}>
                                <Badge badgeContent={accountLocations.length} max={999} color="primary">
                                    <LocationOnIcon color="action" />
                                </Badge>
                            </Tooltip>
                        </div>
                    );
                },
                disableSortBy: true,
            },
            {
                id: "selectAccount",
                Cell: ({ row }: CellProps<{}, any>) => {
                    const account = row.original as RelatedAccountListItem;
                    return (
                        <Button
                            endIcon={
                                selectedAccount?.id === account.id ? (
                                    <RadioButtonCheckedIcon />
                                ) : (
                                    <RadioButtonUncheckedIcon />
                                )
                            }
                            onClick={async () => {
                                handleSelectAccount({
                                    id: account.id,
                                    name: account.name,
                                });
                            }}>
                            Select
                        </Button>
                    );
                },
                disableSortBy: true,
            },
        ];
    }, [handleSelectAccount, selectedAccount?.id]);

    return relatedLocations && relatedLocations.length > 0 ? (
        <div data-testid={"relatedAccounts"}>
            {accountList && accountList.length > 0 && (
                <>
                    <Typography gutterBottom variant={"subtitle1"}>
                        Related Accounts ({accountList.length})
                    </Typography>
                    <ReactTable
                        columns={columns}
                        options={{
                            hideToolbar: true,
                        }}
                        renderRowSubComponent={({ row }: CellProps<{}, any>) => {
                            return (
                                <div>
                                    {(row.original as RelatedAccountListItem).locs.map((l, idx) => (
                                        <Chip
                                            style={{ margin: "3px" }}
                                            variant="outlined"
                                            size={"small"}
                                            key={`${l.locationName}-${idx}`}
                                            label={l.locationName}
                                            icon={
                                                l.active ? (
                                                    <Tooltip title={"Active"} placement={"top"}>
                                                        <CheckIcon style={{ color: "#6DD230" }} />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title={"Inactive"} placement={"top"}>
                                                        <CloseIcon style={{ color: "#FE4D97" }} />
                                                    </Tooltip>
                                                )
                                            }
                                        />
                                    ))}
                                </div>
                            );
                        }}
                        data={accountList}
                    />
                </>
            )}
        </div>
    ) : null;
};
