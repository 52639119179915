import {
    d30Toast,
    d30ToastError,
    Loading,
    Select,
    TaxProfileEdit,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import { CredentialsType, initialCap, Location, Partner, SupplementalDataRequirements, TaxProfile } from "@davo/types";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    ListItemText,
    MenuItem,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { GenerateFilingModal } from "./GenerateFilingModal";
import { ManualFilingReasonModal } from "./ManualFilingReasonModal";
import {
    getAccount,
    getStatesWithActiveGroupLogins,
    setEarlyFilingRequired,
    setGroupLogin,
    setManualReconcileRequired,
    setTaxRateType,
    updateRequireAdditionalData,
} from "./services";
import { SetFilingNote } from "./SetFilingNote";
import { TaxProfileFilingMethodButton } from "./TaxProfileFilingMethodButton";
import { hasPermission } from "./util";

export function TaxProfileSettingsButton({
    taxProfile,
    accountId,
    location,
    partner,
    callback,
}: {
    taxProfile: TaxProfile;
    accountId: string;
    location?: Location;
    partner?: Partner;
    callback?: () => void;
}) {
    const loginContext = useLoginContext();

    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [showRequirements, setShowRequirements] = useState<boolean>(false);
    const [showTaxProfileEdit, taxProfileEditProps] = useModalEditor<string[]>(() => {
        if (callback) {
            callback();
        }
    });
    const theme = useTheme();
    const [showGenerateFiling, generateFilingProps] = useModalEditor<string>();
    const [partnerGroupPolicyStates, setPartnerGroupPolicyStates] = useState<string[]>([]);
    const [internalGroupPolicyStates, setInternalGroupPolicyStates] = useState<string[]>([]);
    const [credentialTypeOptions, setCredentialTypeOptions] = useState<CredentialsType[]>(["SIMPLE"]);

    useAsyncEffect(async () => {
        if (!isShowing) {
            return;
        }

        const _internalGroupPolicyStates = await getStatesWithActiveGroupLogins();
        setInternalGroupPolicyStates(_internalGroupPolicyStates);

        const account = await getAccount(accountId);
        let _partnerGroupPolicyStates: string[] = [];
        if (account.partnerId) {
            _partnerGroupPolicyStates = await getStatesWithActiveGroupLogins(account.partnerId);
            setPartnerGroupPolicyStates(_partnerGroupPolicyStates);
        } else {
            setPartnerGroupPolicyStates(_partnerGroupPolicyStates);
        }

        const options: CredentialsType[] = ["SIMPLE"];
        if (_internalGroupPolicyStates.includes(taxProfile.state)) {
            options.push("INTERNAL_GROUP");
        }
        if (_partnerGroupPolicyStates.includes(taxProfile.state)) {
            options.push("PARTNER_GROUP");
        }
        setCredentialTypeOptions(options);
    }, [isShowing, accountId]);

    const close = () => {
        if (callback) {
            callback();
        }
        setIsShowing(false);
    };

    const changeGroupLogin = (groupLogin: any) => {
        setGroupLogin(taxProfile.id, groupLogin)
            .then(() => {
                if (callback) {
                    callback();
                }
                d30Toast("Group login settings updated");
            })
            .catch((e) => d30ToastError("Problem changing group login.", e));
    };

    const changeRequireAdditionalData = (additionalData: SupplementalDataRequirements) => {
        updateRequireAdditionalData(taxProfile.id, additionalData)
            .then(() => {
                if (callback) {
                    callback();
                }
                d30Toast("Tax profile settings updated");
            })
            .catch((e) => d30ToastError("Problem updating tax profile settings.", e));
    };

    const changeEarlyFilingRequired = (additionalData: any) => {
        setEarlyFilingRequired(taxProfile.id, additionalData)
            .then(() => {
                if (callback) {
                    callback();
                }
                d30Toast("Tax profile settings updated");
            })
            .catch((e) => d30ToastError("Problem updating tax profile settings.", e));
    };

    const changeManualReconcileRequired = (additionalData: any) => {
        setManualReconcileRequired(taxProfile.id, additionalData)
            .then(() => {
                if (callback) {
                    callback();
                }
                d30Toast("Tax profile settings updated");
            })
            .catch((e) => d30ToastError("Problem updating tax profile settings.", e));
    };

    const hasGroupLogins = (state: string) => {
        return partnerGroupPolicyStates.includes(state) || internalGroupPolicyStates.includes(state);
    };

    return (
        <div style={{ marginRight: "10px" }}>
            <Button
                variant="outlined"
                color="primary"
                size={"small"}
                style={{ marginTop: "2px", marginBottom: "2px" }}
                onClick={() => setIsShowing(!isShowing)}>
                Tax Profile Settings
            </Button>
            {isShowing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        close();
                    }}>
                    <DialogTitle>Tax Profile Settings</DialogTitle>
                    <DialogContent>
                        {!taxProfile ? (
                            <Loading />
                        ) : (
                            <>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => showTaxProfileEdit([taxProfile.id])}>
                                    Edit Tax Profile
                                </Button>
                                <br />
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    style={{ margin: "5px 0" }}
                                    onClick={() => showGenerateFiling(taxProfile.id)}>
                                    Generate Filing
                                </Button>
                                <TaxProfileFilingMethodButton
                                    isDisabled={false}
                                    taxProfileId={taxProfile.id}
                                    taxProfileFilingMethodArg={taxProfile.filingMethod}
                                    location={location}
                                    partner={partner}
                                    accountId={accountId}
                                    callback={() => {
                                        try {
                                            if (callback) {
                                                callback();
                                            }
                                            d30Toast("Filing Method Change Successful");
                                        } catch (e: any) {
                                            d30ToastError(e.message);
                                        }
                                    }}
                                />

                                {hasPermission(loginContext.permissions, "can_file") && (
                                    <ManualFilingReasonModal
                                        taxProfileId={taxProfile.id}
                                        isManual={taxProfile.manualFiling}
                                        callback={() => {
                                            try {
                                                if (callback) {
                                                    callback();
                                                }
                                                d30Toast("Manual Filing Change Successful");
                                            } catch (e: any) {
                                                d30ToastError(e.message);
                                            }
                                        }}
                                    />
                                )}

                                <div style={{ margin: "5px 0" }}>
                                    <SetFilingNote
                                        taxProfile={taxProfile}
                                        refresh={() => {
                                            if (callback) {
                                                callback();
                                            }
                                        }}
                                    />
                                </div>
                                <FormControl sx={{ s: 1, width: 300, boxShadow: "unset", margin: "unset" }}>
                                    <Accordion
                                        expanded={showRequirements}
                                        onChange={() => setShowRequirements(!showRequirements)}
                                        sx={{
                                            boxShadow: "unset",
                                            backgroundColor: theme.palette.mode === "dark" ? "#383838" : "primary",
                                        }}>
                                        <AccordionSummary sx={{ fontSize: "14px", padding: "unset" }}>
                                            <Typography>
                                                <span
                                                    style={{
                                                        padding: "17px",
                                                    }}>
                                                    Optional Requirements
                                                </span>
                                                {!showRequirements ? (
                                                    <ExpandMoreOutlinedIcon
                                                        color="primary"
                                                        style={{
                                                            verticalAlign: "middle",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                ) : (
                                                    <ExpandLessOutlinedIcon
                                                        color="primary"
                                                        style={{
                                                            verticalAlign: "middle",
                                                        }}
                                                    />
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Select<CredentialsType>
                                                title="Credentials"
                                                isDisabled={
                                                    // should we remove a tax profile, this will keep the
                                                    // control from being disabled allowing our users to fix
                                                    // the issue.
                                                    !hasGroupLogins(taxProfile.state) &&
                                                    taxProfile.credentialsType === "SIMPLE"
                                                }
                                                options={credentialTypeOptions}
                                                value={taxProfile.credentialsType}
                                                onChange={(value) => {
                                                    if (value) {
                                                        changeGroupLogin(value);
                                                    }
                                                }}
                                                label={(value) => {
                                                    switch (value) {
                                                        case "SIMPLE":
                                                            return "Basic Tax Profile Creds";

                                                        case "PARTNER_GROUP":
                                                            return "Partner Group Login";

                                                        case "INTERNAL_GROUP":
                                                            return "DAVO Group Login";

                                                        default:
                                                            return "Unknown";
                                                    }
                                                }}
                                            />
                                            <Tooltip
                                                title={partner?.restrictSalesData ? "Partner provides data" : ""}
                                                key="2"
                                                placement="top">
                                                <MenuItem key={2} value={2}>
                                                    <Checkbox
                                                        key={2}
                                                        checked={
                                                            (taxProfile && taxProfile.requireAdditionalSalesData) ??
                                                            false
                                                        }
                                                        style={{ padding: "2px", paddingTop: "4px" }}
                                                        disabled={partner?.restrictSalesData ?? false}
                                                        onChange={(e) =>
                                                            changeRequireAdditionalData({
                                                                requireAdditionalSalesData: e.target.checked,
                                                                requireUseData:
                                                                    (taxProfile && taxProfile.requireUseData) ?? false,
                                                            })
                                                        }
                                                    />
                                                    <ListItemText primary={"Require additional sales data"} />
                                                </MenuItem>
                                            </Tooltip>

                                            {hasPermission(loginContext.permissions, "manage_use_tax") && (
                                                <Tooltip
                                                    title={partner?.restrictUseData ? "Partner provides data" : ""}
                                                    key={3}
                                                    placement="top">
                                                    <MenuItem key={3} value={3}>
                                                        <Checkbox
                                                            key={3}
                                                            checked={taxProfile.requireUseData}
                                                            style={{ padding: "2px", paddingTop: "4px" }}
                                                            disabled={partner?.restrictUseData ?? false}
                                                            onChange={(e) =>
                                                                changeRequireAdditionalData({
                                                                    requireAdditionalSalesData:
                                                                        (taxProfile &&
                                                                            taxProfile.requireAdditionalSalesData) ??
                                                                        false,
                                                                    requireUseData: e.target.checked,
                                                                })
                                                            }
                                                        />
                                                        <ListItemText primary={"Require use tax data"} />
                                                    </MenuItem>
                                                </Tooltip>
                                            )}

                                            <Tooltip
                                                title={
                                                    !hasPermission(loginContext.permissions, "can_file")
                                                        ? "This ability is restricted to users with can_file permission."
                                                        : "This will change the due date of future filings generated for this tax profile to the 10th"
                                                }
                                                key={5}
                                                placement="top">
                                                <MenuItem key={5} value={5}>
                                                    <Checkbox
                                                        disabled={!hasPermission(loginContext.permissions, "can_file")}
                                                        key={5}
                                                        checked={taxProfile.earlyFilingRequired}
                                                        style={{ padding: "2px", paddingTop: "4px" }}
                                                        onChange={(e) => changeEarlyFilingRequired(e.target.checked)}
                                                    />
                                                    <ListItemText primary={"Require early filing"} />
                                                </MenuItem>
                                            </Tooltip>

                                            <Tooltip
                                                title={
                                                    "Filings for this tax profile will not show in the reconcile pane"
                                                }
                                                key={6}
                                                placement="top">
                                                <MenuItem key={6} value={6}>
                                                    <Checkbox
                                                        key={6}
                                                        checked={taxProfile.manualReconcileRequired}
                                                        style={{ padding: "2px", paddingTop: "4px" }}
                                                        onChange={(e) =>
                                                            changeManualReconcileRequired(e.target.checked)
                                                        }
                                                    />
                                                    <ListItemText primary={"Require manual reconcile"} />
                                                </MenuItem>
                                            </Tooltip>
                                        </AccordionDetails>
                                    </Accordion>
                                </FormControl>

                                <div>
                                    <Tooltip
                                        title={"Filings for this tax profile will default to the chosen type"}
                                        placement="top">
                                        <Typography variant="body2">
                                            Tax Rate Type:
                                            <Switch
                                                checked={taxProfile.taxRateType === "distinct"}
                                                onChange={() => {
                                                    setTaxRateType(
                                                        taxProfile.id,
                                                        taxProfile.taxRateType === "distinct"
                                                            ? "overlapping"
                                                            : "distinct"
                                                    )
                                                        .then(() => {
                                                            if (callback) {
                                                                callback();
                                                            }
                                                            d30Toast("Tax profile settings updated");
                                                        })
                                                        .catch((e) =>
                                                            d30ToastError("Problem setting tax rate type.", e)
                                                        );
                                                }}
                                            />
                                            &nbsp;&nbsp;{initialCap(taxProfile.taxRateType)}
                                        </Typography>
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowRequirements(false);
                                close();
                            }}>
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {taxProfileEditProps.isDialogOpen && <TaxProfileEdit accountId={accountId} {...taxProfileEditProps} />}
            {taxProfile && generateFilingProps.isDialogOpen && (
                <GenerateFilingModal taxProfileId={taxProfile.id} {...generateFilingProps} />
            )}
        </div>
    );
}
