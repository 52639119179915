import { IconButton } from "@mui/material";
import React from "react";
import pmImage from "./resources/postmark.png";

export interface IPostmark {
    email: string;
}

export function PostmarkLink({ email }: IPostmark) {
    const url = `https://account.postmarkapp.com/servers/5316258/streams/outbound/events?f%5Bevent_types%5D%5B%5D=MTAEvent&f%5Bquery%5D=${encodeURIComponent(
        email
    )}`;
    return (
        <div
            style={{
                display: "inline-block",
                verticalAlign: "0.6em",
            }}>
            <IconButton
                aria-label="Open Postmark app in new window"
                onClick={() => {
                    window.open(url, "_blank");
                }}
                size="large">
                <img src={pmImage} style={{ width: "22px" }} alt={"Postmark App Icon"} data-testid={"postmark_link"} />
            </IconButton>
        </div>
    );
}
