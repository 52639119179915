import { get, post, put } from "@davo/portal-common";
import { BillingEventType, CloverBillingStatus, ISubscriptionType, MissingError } from "@davo/types";

export async function rebillInvoice(invoiceId: string) {
    return post(`api/ops/billings/retry-billing/${invoiceId}`, {}, true);
}

export async function getBillingsForInvoice(invoiceId: string) {
    return get(`api/ops/billings/billings-for-invoice/${invoiceId}`, true);
}

export async function getCloverBillingStatus(locationId: string) {
    try {
        return await get<CloverBillingStatus | undefined>(`api/ops/billings/clover-status/${locationId}`, true);
    } catch (e: any) {
        if (e instanceof MissingError) {
            return undefined;
        }
        throw e;
    }
}

export const voidBilling = async (accountId: string, achId: string) => {
    return put(`api/ops/billings/${achId}/void`, {}, true);
};

export const refundBilling = async (accountId: string, achId: string, amount: number, reason?: string) => {
    return put(`api/ops/billings/${achId}/refund`, { amount, reason }, true);
};

export const billForExtraFilings = async (locationId: string, numberOfMonthsToBillFor: number) => {
    return post(`api/ops/billings/bill-extra-filings`, { locationId, numberOfMonthsToBillFor }, true);
};

export async function getStandardSubscriptionCharge(): Promise<ISubscriptionType> {
    return get(`api/ops/billings/standard-subscription-type`, true);
}

export const addCustomBillingEvent = async (
    locationId: string,
    amountInPennies: number,
    description: string,
    type: BillingEventType
) => {
    return post(`api/ops/billings/bill-custom-amount`, { locationId, amountInPennies, description, type }, true);
};
