import {
    getJurisdictionByStateCode,
    getJurisdictionRateTypes,
    getLocationToTaxProfiles,
    ReactTable8,
    Select,
} from "@davo/portal-common";
import {
    IFilteredBreakoutType,
    IJurisdictionRateType,
    LocationRecord,
    LocationToTaxProfile,
    moneyFromCents,
    percent,
    TaxProfileTaxRate,
    TaxRateInfo,
    toDisplayDateString,
    unstackTaxRates,
    User,
} from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useFilingDetailPaneContext } from "../context/FilingDetailPaneContext";
import { MarketplaceFacilitatorRules } from "../MarketplaceFacilitatorRules";
import { getUser } from "../services";
import { TaxBreakoutLocationDisplay } from "./TaxBreakoutLocationDisplay";

export function TaxBreakout() {
    const {
        filing,
        locationsIncludedInFiling,
        refresh,
        isEditingFiling,
        taxProfileTaxRates,
        dailyDetails,
        taxBreakoutLocationSelected,
        setTaxBreakoutLocationSelected,
        filteredBreakout,
        jurisdictionConfig,
    } = useFilingDetailPaneContext();

    const [rateTypes, setRateTypes] = useState<IJurisdictionRateType[] | undefined>(undefined);
    const [rateVerificationUser, setRateVerificationUser] = useState<User | undefined>(undefined);
    const [locationToTaxProfileMapping, setLocationToTaxProfileMapping] = useState<LocationToTaxProfile[]>();
    const [unstackedRates, setUnstackedRates] = useState<TaxRateInfo[]>([]);

    useAsyncEffect(async () => {
        const j = await getJurisdictionByStateCode(filing.taxProfile.state);
        const types = (await getJurisdictionRateTypes(j.id)).filter((rt) => rt.active);
        if (filing.taxProfile.ratesVerifiedBy) {
            setRateVerificationUser(await getUser(filing.taxProfile.ratesVerifiedBy));
        }
        setRateTypes(types);
    }, []);

    useAsyncEffect(async () => {
        if (locationsIncludedInFiling) {
            const locationIds = locationsIncludedInFiling.map((l) => l.id);
            setLocationToTaxProfileMapping(
                await getLocationToTaxProfiles(filing.taxProfile.account.id, filing.taxProfile.id, locationIds)
            );
        }
    }, [locationsIncludedInFiling]);

    const getBreakoutForIncludedRates = useCallback(() => {
        if (!taxProfileTaxRates) {
            return;
        }
        const breakoutFiltered = {} as IFilteredBreakoutType;
        for (const detail of dailyDetails) {
            for (const t of detail.taxes) {
                const tptr = taxProfileTaxRates.find((x: TaxProfileTaxRate) => {
                    // custom tax rates are aggregates so the rates may not be the same
                    return (
                        x.taxId === t.taxId &&
                        (x.taxRate === t.taxRate ||
                            (t.taxId.startsWith("custom-tax:") && x.taxId.startsWith("custom-tax:"))) &&
                        (x.included === "included" || x.included === "unverified")
                    );
                });
                if (tptr !== undefined) {
                    const type = rateTypes?.find((x) => x.id === tptr.jurisdictionRateTypeId);
                    breakoutFiltered[t.taxId] = breakoutFiltered[t.taxId] ?? {
                        id: t.taxId,
                        location: detail.locationId,
                        state: t.state,
                        name: t.taxName,
                        sales: 0,
                        returns: 0,
                        tax: 0,
                        rateTypeId: tptr.jurisdictionRateTypeId,
                        rateTypeTag: type?.tag,
                        rateTypeTaxBreakDown: type?.taxRateBreakdown,
                        rate: t.taxRate,
                    };
                    breakoutFiltered[t.taxId].sales += t.sales;
                    breakoutFiltered[t.taxId].returns += t.refunds;
                    breakoutFiltered[t.taxId].tax += t.tax;
                }
            }
        }
        return breakoutFiltered;
    }, [dailyDetails, rateTypes, taxProfileTaxRates]);

    useEffect(() => {
        if (!rateTypes) {
            return;
        }
        const breakout: TaxRateInfo[] = taxBreakoutLocationSelected
            ? (Object.values(filteredBreakout) ?? [])
            : Object.values(getBreakoutForIncludedRates() ?? []);
        if (rateTypes.length > 0) {
            const unstackedIncludingZero: IFilteredBreakoutType = unstackTaxRates(
                breakout.filter((x) => x.rateTypeTag && x.rateTypeTaxBreakDown),
                rateTypes
            );
            const unstacked = [] as TaxRateInfo[];
            Object.keys(unstackedIncludingZero).forEach((x) => {
                if (unstackedIncludingZero[x].sales !== 0 || unstackedIncludingZero[x].tax !== 0) {
                    unstacked.push(unstackedIncludingZero[x]);
                }
            });
            for (const b of breakout.filter((x) => !x.rateTypeTag || !x.rateTypeTaxBreakDown)) {
                unstacked.push(b);
            }
            setUnstackedRates(unstacked);
        } else {
            setUnstackedRates(breakout);
        }
    }, [filteredBreakout, getBreakoutForIncludedRates, rateTypes, taxBreakoutLocationSelected]);

    const columns = useMemo(() => {
        const renderJurisdictionTaxRateTypeName = (d: TaxRateInfo) => {
            if (rateTypes) {
                const j = rateTypes.find((x) => x.id === d.rateTypeId);
                if (j) {
                    return <>{j.name}</>;
                }
            }
            return null;
        };
        const columnHelper = createColumnHelper<TaxRateInfo>();

        const cols = [];
        if (unstackedRates.some((x: TaxRateInfo) => x.state)) {
            cols.push(
                columnHelper.accessor("state", {
                    header: "State",
                    cell: (data) => {
                        return <>{data.getValue()}</>;
                    },
                })
            );
        }

        cols.push(
            columnHelper.accessor("name", {
                header: "Tax Name",
            })
        );

        cols.push(
            columnHelper.accessor("rate", {
                header: "Tax Rate",
                cell: (data) => {
                    return <>{!isNil(data.getValue()) ? percent(data.getValue() / 1e7) : ""}</>;
                },
            })
        );

        cols.push(
            columnHelper.accessor("rateTypeId", {
                header: "Tax Rate Type",
                id: "jurisdictionRateType",
                cell: (data) => renderJurisdictionTaxRateTypeName(data.row.original),
            })
        );

        cols.push(
            columnHelper.accessor("sales", {
                header: "Sales",
                cell: (data) => <div style={{ textAlign: "right" }}>${moneyFromCents(data.getValue())}</div>,
                meta: {
                    rightAlign: true,
                },
            }),
            columnHelper.accessor("returns", {
                header: "Returns",
                cell: (data) => <div style={{ textAlign: "right" }}>${moneyFromCents(-data.getValue())}</div>,
                meta: {
                    rightAlign: true,
                },
            }),
            columnHelper.accessor("sales", {
                header: "Net Sales",
                id: "netSales",
                cell: (data) => (
                    <div style={{ textAlign: "right" }}>
                        ${moneyFromCents(+data.getValue() + +data.cell.row.original.returns)}
                    </div>
                ),
                meta: {
                    rightAlign: true,
                },
            })
        );
        cols.push(
            columnHelper.accessor("tax", {
                header: "Tax",
                id: "tax",
                meta: {
                    rightAlign: true,
                },
                cell: (data) => <div style={{ textAlign: "right" }}>${moneyFromCents(data.cell.row.original.tax)}</div>,
            })
        );
        return cols;
    }, [rateTypes, unstackedRates]);

    return (
        <Accordion
            slotProps={{ transition: { unmountOnExit: true } }}
            style={{ border: "none", boxShadow: "none" }}
            defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                {filing.status !== "open" && !isEditingFiling
                    ? "Tax Breakout (when filing was closed)"
                    : "Tax Breakout"}
            </AccordionSummary>

            {(filing.status === "open" || isEditingFiling) &&
                taxProfileTaxRates &&
                taxProfileTaxRates.filter((x) => x.included === "unverified").length > 0 && (
                    <div style={{ marginLeft: "20px", marginBottom: "15px" }}>
                        <InfoIcon color="secondary" style={{ verticalAlign: "middle", marginRight: "10px" }} />
                        {`${taxProfileTaxRates.filter((x) => x.included === "unverified").length} tax rate${
                            taxProfileTaxRates.filter((x) => x.included === "unverified").length > 1 ? "s" : ""
                        } not verified`}
                    </div>
                )}
            {(filing.status === "open" || isEditingFiling) &&
                taxProfileTaxRates &&
                taxProfileTaxRates.filter((x) => x.included === "precluded").length > 0 && (
                    <div style={{ marginBottom: "5px" }}>
                        <InfoIcon
                            color="primary"
                            style={{ verticalAlign: "middle", marginLeft: "20px", marginRight: "10px" }}
                        />
                        {`${taxProfileTaxRates.filter((x) => x.included === "precluded").length} tax rate${
                            taxProfileTaxRates.filter((x) => x.included === "precluded").length > 1 ? "s" : ""
                        } not included`}
                    </div>
                )}

            {jurisdictionConfig?.jurisdictionId ? (
                <div style={{ marginTop: "10px", marginLeft: "30px" }}>
                    <MarketplaceFacilitatorRules
                        jurisdictionState={filing.taxProfile.state}
                        jurisdictionId={jurisdictionConfig?.jurisdictionId}
                    />
                </div>
            ) : (
                ""
            )}
            {locationsIncludedInFiling.length > 1 ? (
                <div style={{ marginLeft: "25px", marginTop: "10px", width: "96%" }}>
                    <Select<LocationRecord>
                        noneLabel={`All ${locationsIncludedInFiling.length} locations`}
                        value={taxBreakoutLocationSelected}
                        onChange={setTaxBreakoutLocationSelected}
                        options={locationsIncludedInFiling}
                        label={(l) => (l.beta ? `BETA: ${l.name}` : l.name)}
                    />
                </div>
            ) : (
                locationsIncludedInFiling.length > 0 && (
                    <div style={{ marginTop: "5px", marginLeft: "30px" }}>
                        <Typography>
                            {locationsIncludedInFiling[0].beta
                                ? `BETA:  ${locationsIncludedInFiling[0].name}`
                                : locationsIncludedInFiling[0].name}
                        </Typography>
                        <br />
                        <TaxBreakoutLocationDisplay
                            location={locationsIncludedInFiling[0]}
                            taxProfileId={filing.taxProfile.id}
                            locationToTaxProfileMapping={locationToTaxProfileMapping}
                        />
                    </div>
                )
            )}

            {taxBreakoutLocationSelected && (
                <div style={{ marginTop: "5px", marginLeft: "30px" }}>
                    <TaxBreakoutLocationDisplay
                        location={taxBreakoutLocationSelected}
                        taxProfileId={filing.taxProfile.id}
                        locationToTaxProfileMapping={locationToTaxProfileMapping}
                    />
                </div>
            )}

            <AccordionDetails style={{ display: "flex", marginTop: "10px" }}>
                <ReactTable8<TaxRateInfo>
                    columns={columns}
                    data={unstackedRates}
                    refreshData={refresh}
                    options={{
                        pageSize: 100,
                        hideToolbar: true,
                    }}
                />
            </AccordionDetails>

            {rateVerificationUser && filing.taxProfile.ratesVerified && (
                <div style={{ float: "right", fontWeight: "bold", color: "#6b6666" }}>
                    <span>
                        <em>
                            <sup>
                                {`Rates last verified by ${rateVerificationUser.firstName} ${
                                    rateVerificationUser.lastName
                                } - ${toDisplayDateString(filing.taxProfile.ratesVerified)}`}
                            </sup>
                        </em>
                    </span>
                </div>
            )}
        </Accordion>
    );
}
