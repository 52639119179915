import { Loading, ReactTable } from "@davo/portal-common";
import { ReturnedByCheck, toDisplayDateString, toDollars } from "@davo/types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useCallback, useMemo, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getUnmatchedReturnedByCheck } from "../services";
import { ReturnedByCheckWellsAddNoteModal } from "./ReturnedByCheckAddWellsNoteModal";
import { WellsTransactionMatchIcon } from "./WellsTransactionMatchIcon";
import { WellsTransactionMatchReturnedByCheck } from "./WellsTransactionMatchReturnedByCheck";

export function UnmatchedReturnedByCheck() {
    const [results, setResults] = useState<ReturnedByCheck[] | undefined>(undefined);
    const controllerRef = useRef<AbortController | undefined>();

    const refresh = useCallback(async () => {
        if (controllerRef?.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();

        const theResults = await getUnmatchedReturnedByCheck(controllerRef.current);
        setResults(theResults);
        controllerRef.current = undefined;
    }, []);

    useAsyncEffect(async () => {
        await refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Period",
                id: "periodDay",
                accessor: "periodDay",
                Cell: (data: any) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                        {toDisplayDateString(data.value)}
                        <ReturnedByCheckWellsAddNoteModal outflow={data.cell.row.original} />
                    </span>
                ),
            },
            {
                Header: "Amount",
                id: "amount",
                accessor: "amount",
                Cell: (data: any) => {
                    const outflow = data.cell.row.original;
                    return (
                        <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                            {toDollars(data.value)}{" "}
                            <WellsTransactionMatchIcon wfTransactionId={outflow.wellsTxn} onChange={refresh} />
                            {!outflow.wellsTxn && (
                                <WellsTransactionMatchReturnedByCheck
                                    outflowId={outflow.id}
                                    amount={outflow.amount}
                                    onMatch={refresh}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "Created By",
                id: "createdByFirstName",
                accessor: "createdByFirstName",
                Cell: (data: any) => <>{data.value}</>,
            },
            {
                Header: "Created",
                id: "created",
                accessor: "created",
                Cell: (data: any) => (
                    <>{data.value && <span style={{ whiteSpace: "nowrap" }}>{toDisplayDateString(data.value)}</span>}</>
                ),
            },
            {
                Header: "Note",
                id: "description",
                accessor: "description",
                Cell: (data: any) => <>{data.value}</>,
            },
        ];
    }, [refresh]);

    return (
        <>
            {isNil(results) && <Loading />}

            {!isNil(results) && isEmpty(results) && <div>No results found</div>}

            {!isNil(results) && !isEmpty(results) && (
                <ReactTable
                    columns={columns}
                    data={results}
                    refreshData={refresh}
                    options={{
                        pageSize: 10,
                    }}
                />
            )}
        </>
    );
}
