import { getJurisdictions } from "@davo/portal-common";
import { IJurisdiction, IWellsFargoTransaction } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { PenaltyTable } from "../PenaltyTable";
import { getWellsTransactionsWithoutState } from "../services";
import { FilingSearch } from "./FilingSearch";
import { UnmatchedFilings } from "./UnmatchedFilings";
import { UnmatchedReturnedByCheck } from "./UnmatchedReturnedByCheck";
import { WellsTransactionSearch } from "./WellsTransactionSearch";
import { WellsTransactionsWithUnknownStates } from "./WellsTransactionsWithUnknownStates";
import { WellsUnmatchedTransactions } from "./WellsUnmatchedTransactions";

export function ReconcileFilingRemittedPane() {
    const [txnsUnknownStates, setTxnsUnknownStates] = useState<IWellsFargoTransaction[]>([] as any);
    const [states, setStates] = useState<IJurisdiction[]>([]);

    useAsyncEffect(async () => {
        const [unkStatesVal, statesval] = await Promise.all([getWellsTransactionsWithoutState(), getJurisdictions()]);

        setTxnsUnknownStates(unkStatesVal);
        const nonState: IJurisdiction = {
            id: "NA",
            abbreviatedName: "NA",
            fullName: "Non-State Transaction",
            type: "STATE",
        };
        setStates([nonState, ...statesval]);
    }, []);

    return (
        <div>
            {!isEmpty(txnsUnknownStates) && (
                <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    style={{ border: "none", boxShadow: "none", width: "100%" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        Wells Transactions With Unknown State
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                            <WellsTransactionsWithUnknownStates txns={txnsUnknownStates} />
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Unmatched Wells Transactions
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <WellsUnmatchedTransactions states={states} />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Unmatched Filings
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <UnmatchedFilings states={states} />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Unmatched Returned By Check
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <UnmatchedReturnedByCheck />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Unmatched Completed Penalties
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <PenaltyTable
                            statuses={["completed"]}
                            excludeOldPenalties={true}
                            hideAccountColumns={false}
                            excludeWellsMatched={true}
                            isDavoResponsibleOnly={true}
                            showWells={true}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Transaction Search
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <WellsTransactionSearch states={states} />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ border: "none", boxShadow: "none", width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Filing Search
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <FilingSearch states={states} />
                    </div>
                </AccordionDetails>
            </Accordion>

            <br />
        </div>
    );
}
