import { d30Toast, d30ToastError, getLocationEnhanced, runDisconnectAutomation } from "@davo/portal-common";
import { DisconnectReasonCodes } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";

export function DisconnectAutomationButton({
    accountId,
    locationId,
    reason,
    onClose,
}: {
    accountId: string;
    locationId: string;
    reason: DisconnectReasonCodes;
    onClose: () => void;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [hasTaxProfiles, setHasTaxProfiles] = useState<boolean>(true);

    useAsyncEffect(async () => {
        setHasTaxProfiles((await getLocationEnhanced(accountId, locationId)).taxProfiles.length > 0);
    }, [locationId]);

    const show = () => {
        setIsShowing(true);
    };

    const doDisconnectAutomation = () => {
        setIsBusy(true);
        runDisconnectAutomation(locationId, reason)
            .then(() => {
                d30Toast(`Disconnect automation complete, location marked inactive`);
            })
            .catch((e: any) => d30ToastError(`Could not run disconnect automation: ${e.message}`))
            .finally(() => {
                setIsBusy(false);
                setIsShowing(false);
                onClose();
            });
    };

    return (
        <div style={{ marginTop: "2px", marginRight: "2px", marginBottom: "2px", whiteSpace: "nowrap" }}>
            <Button
                variant="outlined"
                onClick={show}
                size="small"
                color="secondary"
                disabled={isBusy || (hasTaxProfiles && !["CPA", "SELF", "DELINQUENT"].includes(reason))}>
                Return Funds and WNF Filings
            </Button>
            {isShowing && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle>Return funding and close open filings?</DialogTitle>
                    <DialogContent>
                        <Typography>{`Note: This will both attempt to return funds and close filings as will not file.`}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={isBusy}
                            onClick={doDisconnectAutomation}>
                            Run Automation
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
